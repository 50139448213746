
/*
 * This file is used for all steps of the /createNewUserEntity page.
 * This page will require user to have role: 'FinancialAdvisor' for the API calls.
 */

import React, { Component } from 'react';
import swal from 'sweetalert2';
import { withRouter } from 'react-router-dom';
import RequestService from './api/RequestService';
import { currentYear } from './utils/ApplicationConstants';
import { getImpersonateID  } from "./utils/General";

class CreateNewUserEntity extends Component {
	constructor(props) {
		super(props);

		this.state = {
			// Set initial state to -1 so it doesn't show the form and then forward.
			RegistrationStep: -1
		};

		this.submitStep1 = this.submitStep1.bind(this);
		this.clearFieldError = this.clearFieldError.bind(this);
	}


	async componentDidMount() {
		let isFA = await this.getUserRoles();

		if (!isFA) {
			window.location = '/app/investorDashboard';
		}
		else {
			this.setState({ RegistrationStep: 0 });
		}

		this.addFormatListener();
		this.focusFirstField();
	}


	async getUserRoles() {
		let isFA = false;
		const impersonateID = await getImpersonateID();
		const { data } = await RequestService.get(`/api/getUserRoles/${impersonateID}`);

		data.forEach(roleData => {
			if (roleData.Role === 'FinancialAdvisor') {
				isFA = true;
			}
		});

		return isFA;
	}


	async submitStep1(e) {
		e.preventDefault();
		let ret = true;

		if (this.refs.fname.value.trim() === '' || this.refs.lname.value.trim() === '' || this.refs.email.value.trim() === '' || this.refs.phoneNumber.value.trim() === '') {
			if (this.refs.fname.value.trim() === '') {
				this.refs.fname.classList.add('cnue-form-warning');
			}

			if (this.refs.lname.value.trim() === '') {
				this.refs.lname.classList.add('cnue-form-warning');
			}

			if (this.refs.email.value.trim() === '') {
				this.refs.email.classList.add('cnue-form-warning');
			}

			if (this.refs.phoneNumber.value.trim() === '') {
				this.refs.phoneNumber.classList.add('cnue-form-warning');
			}

			ret = false;
		}
		else if (this.refs.email.value.trim() !== '') {
			let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/;

			if (emailRegex.test(this.refs.email.value) === false) {
				this.refs.email.classList.add('cnue-form-warning');
				ret = false;
			}
			else if (emailRegex.test(this.refs.email.value) === true) {
				this.refs.email.classList.remove('cnue-form-warning');
			}
		}

		if (!this.refs.termsCB1.checked) {
			this.refs.tc1_error.innerHTML = 'You must agree to all of the terms and conditions.';
			ret = false;
		}

		let [validForm, formBody2] = await this.validateAccreditationData();
		if (!validForm) {
			ret = false;
		}

		if (ret) {
			this.refs.submit1.setAttribute('disabled', 'disabled');
			this.refs.submit1.innerHTML = 'Please Wait ... ';

			let formBody = [];
			formBody.push("fname=" + encodeURIComponent(this.refs.fname.value));
			formBody.push("lname=" + encodeURIComponent(this.refs.lname.value));
			formBody.push("email=" + encodeURIComponent(this.refs.email.value.toLowerCase()));
			formBody.push('phoneNumber=' + encodeURIComponent(this.refs.phoneNumber.value));
			formBody.push("Verification1=" + encodeURIComponent(`I agree to the Terms of Service, Privacy Policy, Investor Agreement, and the Electronic Consent & Delivery Agreement.`));

			formBody.push(...formBody2);	// Adds arrays: formBody2 to formBody.
			formBody = formBody.join("&");

			try {
				const { data } = await RequestService.post({
					url: '/api/createNewUserEntity1',
					data: formBody,
					credential: {
						// credentials: 'include' // Don't forget to specify this if you need cookies passed to controller -- DO NOT REMOVE COMMENT FOR NOW (AALOK)
						credentials: 'same-origin' // Don't forget to specify this if you need cookies passed to controller, this is more secure. -- DO NOT REMOVE COMMENT FOR NOW (AALOK)
					},
				});

				if (data.code === 200) {
					window.scrollTo(0, 0);

					this.props.history.push({
						pathname: '/app/VerifyDwolla',
						state: {
							EntityID: data.EntityID
						}
					});
				}
				else if (data.code === 406) {
					swal('Email Already Registered', '', 'error');
					this.refs.submit1.removeAttribute('disabled');
					this.refs.submit1.innerHTML = 'Continue';
				}
				else {
					swal('Please contact investor relations.', '', 'error');
					this.refs.submit1.setAttribute('disabled', 'disabled');
					this.refs.submit1.innerHTML = 'Please contact investor relations.';
				}
			}
			catch (err) {
				console.log(err);
			}
		}
	}



	async validateAccreditationData() {
		let formComplete = true;

		let riskTolerance = this.refs.riskTolerance.value;
		let maritalStatus1 = this.refs.maritalStatus1.checked;
		let maritalStatus2 = this.refs.maritalStatus2.checked;
		let incomeThisYear = this.refs.incomeThisYear.value;
		let incomeLastYear = this.refs.incomeLastYear.value;
		let incomeTwoYearsBack = this.refs.incomeTwoYearsBack.value;
		let netWorth = this.refs.netWorth.value;
		let currInvest1 = this.refs.currInvest1.checked;
		let currInvest2 = this.refs.currInvest2.checked;
		let investTypes = this.refs.investTypes.value;

		let formBody = [];

		if (riskTolerance !== '0') {
			formBody.push('riskTolerance=' + riskTolerance);
		}
		else {
			this.refs.riskTolerance.classList.add('cnue-form-warning');
			formComplete = false;
		}

		if (maritalStatus1 || maritalStatus2) {
			if (maritalStatus1) {
				formBody.push(`maritalStatus=single`);
			}
			if (maritalStatus2) {
				formBody.push(`maritalStatus=married`);
			}
		}
		else {
			this.refs.error_maritalStatus.innerHTML = 'Please select one.';
			formComplete = false;
		}

		if (incomeThisYear !== '0') {
			formBody.push('incomeThisYear=' + encodeURIComponent(incomeThisYear));
		}
		else {
			this.refs.incomeThisYear.classList.add('cnue-form-warning');
			formComplete = false;
		}

		if (incomeLastYear !== '0') {
			formBody.push('incomeLastYear=' + encodeURIComponent(incomeLastYear));
		}
		else {
			this.refs.incomeLastYear.classList.add('cnue-form-warning');
			formComplete = false;
		}

		if (incomeTwoYearsBack !== '0') {
			formBody.push('incomeTwoYearsBack=' + encodeURIComponent(incomeTwoYearsBack));
		}
		else {
			this.refs.incomeTwoYearsBack.classList.add('cnue-form-warning');
			formComplete = false;
		}

		if (netWorth !== '0') {
			formBody.push('netWorth=' + encodeURIComponent(netWorth));
		}
		else {
			this.refs.netWorth.classList.add('cnue-form-warning');
			formComplete = false;
		}

		if (currInvest1 || currInvest2) {
			if (currInvest1) {
				if (investTypes !== '0') {
					formBody.push('currInvest=' + investTypes);
				}
				else {
					this.refs.investTypes.classList.add('cnue-form-warning');
					formComplete = false;
				}
			}
			if (currInvest2) {
				formBody.push(`currInvest=No`);
			}
		}
		else {
			this.refs.error_currInvest.innerHTML = 'Please select one.';
			formComplete = false;
		}

		return [formComplete, formBody];
	}


	addFormatListener() {
		if (this.state.RegistrationStep === 0) {
			this.refs.email.addEventListener('blur', function (e) {
				let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/;

				if (emailRegex.test(this.value) === false) {
					this.classList.add('cnue-form-warning');
				}
				else if (emailRegex.test(this.value) === true) {
					this.classList.remove('cnue-form-warning');
				}
			});

			this.refs.phoneNumber.addEventListener('blur', function (e) {
				let x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
				e.target.value = !x[2] ? x[1] : x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '');
			});

			this.refs.phoneNumber.addEventListener('input', function (e) {
				let x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
				e.target.value = !x[2] ? x[1] : x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '');
				this.classList.remove('cnue-form-warning');
			});
		}
	}


	focusFirstField() {
		if (this.state.RegistrationStep === 0) {
			document.getElementById('fname').focus();
		}
	}


	clearFieldError(field) {
		if (field === 'fname') {
			this.refs.fname.classList.remove('cnue-form-warning');
		}

		if (field === 'lname') {
			this.refs.lname.classList.remove('cnue-form-warning');
		}

		if (field === 'email') {
			this.refs.email.classList.remove('cnue-form-warning');
		}

		if (field === 'phoneNumber') {
			this.refs.phoneNumber.classList.remove('cnue-form-warning');
		}

		if (field === 'termsCB1') {
			this.refs.tc1_error.innerHTML = '';
		}

		if (field === 'riskTolerance') {
			this.refs.riskTolerance.classList.remove('cnue-form-warning');
		}

		if (field === 'maritalStatus') {
			this.refs.error_maritalStatus.innerHTML = '';
		}

		if (field === 'incomeThisYear') {
			this.refs.incomeThisYear.classList.remove('cnue-form-warning');
		}

		if (field === 'incomeLastYear') {
			this.refs.incomeLastYear.classList.remove('cnue-form-warning');
		}

		if (field === 'incomeTwoYearsBack') {
			this.refs.incomeTwoYearsBack.classList.remove('cnue-form-warning');
		}

		if (field === 'netWorth') {
			this.refs.netWorth.classList.remove('cnue-form-warning');
		}

		if (field === 'currInvest') {
			this.refs.error_currInvest.innerHTML = '';
			if (this.refs.currInvest1.checked) {
				this.refs.investTypesDiv.style = 'display: block';
			}
			else if (this.refs.currInvest2.checked) {
				this.refs.investTypesDiv.style = 'display: none';
			}
		}

		if (field === 'investTypes') {
			this.refs.investTypes.classList.remove('cnue-form-warning');
		}
	}


	renderRegistrationStep0 () {
		return (
			<div>
				<div style={{overflow: 'hidden'}}>
					<div className="col-xs-12 padding-20px paddingLeft-30px paddingRight-30px imghide">
						<div className="Mainstep">
							<div className="container">
								<div className="row" style={{ margin: 0 }}>
									<ul className="breadcrumb">
										<li className="completed1"><a href="javascript:" className="cursorDefault">Create Client</a></li>
										<li><a href="javascript:" className="cursorDefault">Verify Client</a></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div className="col-sm-12 paddingLeft-30px">
						<div className="panel panel-primary" style={{ border: 0, borderRadius: 0, boxShadow: 'none' }}>
							<div>
								<p className="" style={{ fontSize: 24, textAlign: 'left' }}>Enter Your Client's Information:</p>
							</div>

							<div className="panel-body">
								<form name="form1">
									<div className="row">
										<div className="col-xs-6 paddingBottom-30px">
											<div className="form-group">
												<label htmlFor="fname" className="input-label">Legal First Name <span className="red">*</span></label>
												<input id="fname" ref="fname" className="form-control input" type="text" data-validation="required"
													   placeholder="Legal First Name"
													   onChange={() => this.clearFieldError('fname')}
												/>
											</div>
										</div>
										<div className="col-xs-6 paddingBottom-30px">
											<div className="form-group">
												<label htmlFor="lname" className="input-label">Legal Last Name <span className="red">*</span></label>
												<input id="lname" ref="lname" className="form-control input" type="text" data-validation="required"
													   placeholder="Legal Last Name"
													   onChange={() => this.clearFieldError('lname')}
												/>
											</div>
										</div>
									</div>

									<div className="row">
										<div className="col-xs-6 paddingBottom-30px">
											<div className="form-group">
												<label htmlFor="email" className="input-label">Email <span className="red">*</span></label>
												<input id="email" ref="email" className="form-control input" type="email" data-validation="required email"
													   placeholder="Email"
													   onChange={() => this.clearFieldError('email')}
												/>
											</div>
										</div>
										<div className="col-xs-6 paddingBottom-30px">
											<div className="form-group">
												<label htmlFor="phoneNumber" className="input-label">Mobile Number <span className="red">*</span></label>
												<input id="phoneNumber" ref="phoneNumber" name="phoneNumber" className="form-control input" type="text"
													   data-validation="required" placeholder="phone number"
													   onChange={() => this.clearFieldError('phoneNumber')}
												/>
											</div>
										</div>
									</div>

									<div>
										<p className="" style={{ fontSize: 20, textAlign: 'left' }}>Securities regulation require that we learn more about your client's investment background before giving full access to investment opportunities.</p>
									</div>

									<div className="row">
										<div className="col-xs-12 paddingBottom-30px">
											<div className="form-group">
												<div className="check_tc">
													<label htmlFor="riskTolerance" className="input-label">Your Client's Risk Tolerance <span className="red">*</span></label>
													<br />
													<select ref="riskTolerance" id="riskTolerance" className="form-control input cnue-select-option"
															onChange={() => this.clearFieldError('riskTolerance')}
															style={{width: 250}}
													>
														<option value="0">Select One</option>
														<option value="High Risk, High Reward">High Risk, High Reward</option>
														<option value="Medium Risk, Medium Reward">Medium Risk, Medium Reward</option>
														<option value="Low Risk, Low Reward">Low Risk, Low Reward</option>
													</select>
												</div>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-xs-12" style={{ width: '100%' }}>
											<div className="form-group">
												<div className="check_tc" style={{marginBottom: 30}}>
													<label htmlFor="maritalStatus1" className="input-label">Your Client's Accreditation <span className="red">*</span></label>
													<br />
													<div className="col-xs-6" style={{ width: 120 }}>
														<label className="custom-container" style={{ float: 'left' }}>Individual
															<input type="radio" ref="maritalStatus1" name="maritalStatusRadio" id="maritalStatus1"
																   onChange={() => this.clearFieldError('maritalStatus')}
															/>
															<span className="checkmark checkmark-radio" />
														</label>
													</div>
													<div className="col-xs-6" style={{ width: 150 }}>
														<label className="custom-container paddingLeft-30px" style={{ float: 'left' }}>Joint (Married)
															<input type="radio" ref="maritalStatus2" name="maritalStatusRadio" id="maritalStatus2"
																   onChange={() => this.clearFieldError('maritalStatus')}
															/>
															<span className="checkmark checkmark-radio" />
														</label>
													</div>
													<span id="error_maritalStatus" ref="error_maritalStatus" className="red" style={{ float: 'left' }} />
												</div>
											</div>
										</div>
									</div>
									<div className="check_tc">
										<label>
											<select ref="incomeThisYear" id="incomeThisYear" className="form-control input cnue-select-option"
													onChange={() => this.clearFieldError('incomeThisYear')} style={{ width: 250 }}
											>
												<option value="0">Select {currentYear()} Expected Income</option>
												<option value="Less than $100,000">Less than $100,000</option>
												<option value="$100,000 - $200,000">$100,000 - $200,000</option>
												<option value="$200,000 - $300,000">$200,000 - $300,000</option>
												<option value="$300,000 - $500,000">$300,000 - $500,000</option>
												<option value="$500,000 - $1 Million">$500,000 - $1 Million</option>
												<option value="$1 Million +">$1 Million +</option>
											</select>
										</label>
										<br/>
										<label>
											<select ref="incomeLastYear" id="incomeLastYear" className="form-control input cnue-select-option"
													onChange={() => this.clearFieldError('incomeLastYear')} style={{width: 250}}
											>
												<option value="0">Select {currentYear() - 1} Income</option>
												<option value="Less than $100,000">Less than $100,000</option>
												<option value="$100,000 - $200,000">$100,000 - $200,000</option>
												<option value="$200,000 - $300,000">$200,000 - $300,000</option>
												<option value="$300,000 - $500,000">$300,000 - $500,000</option>
												<option value="$500,000 - $1 Million">$500,000 - $1 Million</option>
												<option value="$1 Million +">$1 Million +</option>
											</select>
										</label>
										<br/>
										<label>
											<select ref="incomeTwoYearsBack" id="incomeTwoYearsBack" className="form-control input cnue-select-option"
													onChange={() => this.clearFieldError('incomeTwoYearsBack')} style={{width: 250}}
											>
												<option value="0">Select {currentYear() - 2} Income</option>
												<option value="Less than $100,000">Less than $100,000</option>
												<option value="$100,000 - $200,000">$100,000 - $200,000</option>
												<option value="$200,000 - $300,000">$200,000 - $300,000</option>
												<option value="$300,000 - $500,000">$300,000 - $500,000</option>
												<option value="$500,000 - $1 Million">$500,000 - $1 Million</option>
												<option value="$1 Million +">$1 Million +</option>
											</select>
										</label>
									</div>
									<br/>

									<div className="check_tc">
										<label htmlFor="netWorth" className="input-label">Your Client's Net Worth <span className="red">*</span></label><br/>
										<select ref="netWorth" id="netWorth" className="form-control input cnue-select-option"
												onChange={() => this.clearFieldError('netWorth')}
												style={{width: 250}}
										>
											<option value="0">Select Net Worth</option>
											<option value="Less than $200,000">Less than $200,000</option>
											<option value="$200,000 - $1 Million">$200,000 - $1 Million</option>
											<option value="$1 Million - $3 Million">$1 Million - $3 Million</option>
											<option value="$3 Million - $5 Million">$3 Million - $5 Million</option>
											<option value="$5 Million - $10 Million">$5 Million - $10 Million</option>
											<option value="$10 Million +">$10 Million +</option>
										</select>
									</div>
									<br/>

									<div className="row">
										<div className="col-xs-12 paddingBottom-30px">
											<div className="form-group">
												<label htmlFor="currInvest1" className="input-label">Is your client currently invested in direct Oil and Gas assets? <span className="red">*</span></label>
												<div className="form-group">
													<div className="check_tc" style={{marginBottom: 30}}>
														<div className="col-xs-6" style={{ width: 120 }}>
															<label className="custom-container" style={{ float: 'left' }}>Yes
																<input type="radio" ref="currInvest1" name="currInvest" id="currInvest1"
																	   onChange={() => this.clearFieldError('currInvest')}
																/>
																<span className="checkmark checkmark-radio" />
															</label>
														</div>
														<div className="col-xs-6" style={{ width: 150 }}>
															<label className="custom-container paddingLeft-30px" style={{ float: 'left' }}>No
																<input type="radio" ref="currInvest2" name="currInvest" id="currInvest2"
																	   onChange={() => this.clearFieldError('currInvest')}
																/>
																<span className="checkmark checkmark-radio" />
															</label>
														</div>
														<span id="error_currInvest" ref="error_currInvest" style={{ color: '#ff0000', float: 'left' }}> </span>
													</div>
													<div ref="investTypesDiv" id="investTypesDiv" className="check_tc" style={{display: 'none'}}>
														<label htmlFor="investTypes" className="input-label">What types of investments? <span className="red">*</span></label>
														<br/>
														<select ref="investTypes" id="investTypes" className="form-control verify-form-control input" onChange={() => this.clearFieldError('investTypes')}
																style={{width: 250}}
														>
															<option value="0">Select One</option>
															<option value="Minerals">Minerals</option>
															<option value="Working Interest">Working Interest</option>
															<option value="Other">Other</option>
														</select>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className="row">
										<div className="col-xs-12" style={{ width: '100%' }}>
											<div className="form-group">
												<div className="check_tc" style={{marginTop: 10}}>
													<label className="custom-container">Your client agrees to the <a href="https://www.energyfunders.com/terms-of-service/" target="_blank" rel="noopener noreferrer">Terms of Service</a>, <a href="https://www.energyfunders.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>, <a href="https://www.energyfunders.com/investor-agreement/" target="_blank" rel="noopener noreferrer">Investor Agreement</a>,
														and the <a href="https://www.energyfunders.com/electronic-consent/" target="_blank" rel="noopener noreferrer">Electronic Consent & Delivery Agreement</a>. <span className="red">*</span>
														<input type="checkbox" required ref="termsCB1" name="termsCB1" id="termsCB1" onChange={() => this.clearFieldError('termsCB1')}/>
														<span className="checkmark"/>
													</label>
													<span id="tc1_error" ref="tc1_error" className="red" />
												</div>
											</div>
										</div>
									</div>

									<div className="row">
										<div className="col-xs-4">
											<div className="form-group">
												<button id="submit1" ref="submit1" onClick={this.submitStep1} value="submit" className="btn center" style={{ height: 50, width: 250, backgroundColor: "#5bb462", color: '#fff' }}>
													Continue
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}



	render() {
		if (this.state.RegistrationStep === 0) {
			return this.renderRegistrationStep0();
		}
		else {
			return(
				<div>No Data.</div>
			)
		}
	}
}

export default withRouter(CreateNewUserEntity);
