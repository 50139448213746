import React from "react";
import './PastInvestmentsStyles.css'


const PastInvestments = (props) => {
    return (
        <div className="right_col no-padding past-investment-container">
            <div className="past-investment-header">
                <div className="past-investment-triangle">
                </div>
                <div className="past-investment-title">
                    <span>Past Investments</span>
                </div>
            </div>
            {props.children}
        </div>
    );
};

export default PastInvestments;
