import PropTypes from 'prop-types'
import React from 'react';
import './OfferingsStyles.css';
import {PROJECT_REG_CF, PROJECT_REG_D} from "../../../../../utils/ApplicationConstants";
import Icon from "../../../IconComponent/IconComponent";
import CategoryDropdown from "./CategoryDropdown";


const OfferingsHeader = (props) => {
    return (
        <div className="offerings-header-grid">
            <div className="offerings-header-title">
                <span>Current Offerings</span>
            </div>

            <CategoryDropdown categoryList={props.projectCategoryList}
                              onClickHandler={props.projectCategoryOnClickHandler}
                              selectedCategory={props.selectedProjectCategory}
			/>

			{/* {(() => {
				if (props.isAccreditedUser || props.wasAccreditedUser) {
					return (
						<div className="dropdown offerings-header-regulation-selector">
							<button className="btn btn-primary btn-lg dropdown-toggle"
									type="button" id="dropdownMenu1"
									data-toggle="dropdown"
									aria-haspopup="true"
									aria-expanded="true">
								<span className="pull-left">{props.selectedProjectType === PROJECT_REG_D ? 'Regulation D' : 'Regulation CF'}</span>
								<Icon iconName="caret-down" extraClasses="white-text pull-right"/>
							</button>
							<ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
								<li className="btn-default"
									onClick={() => props.onProjectTypeClickHandler(PROJECT_REG_D)}>
									<a>Regulation D</a>
								</li>
								<li className="btn-default"
									onClick={() => props.onProjectTypeClickHandler(PROJECT_REG_CF)}>
									<a>Regulation CF</a>
								</li>
							</ul>
						</div>
					);
				}
			})()} */}


            {/* <div className="checkbox offerings-header-active-check">
                <input id="offerings-active"
                       type="checkbox"
                       name="activeOfferingsChecked"
                       className="offerings-header-checkbox"
                       onChange={props.onCheckboxClickedHandler}
                       checked={props.activeOfferingsChecked} />
                <label htmlFor="offerings-active">Active</label>
            </div> */}

            {/* <div className="checkbox offerings-header-past-check">
                <input id="offerings-past"
                       type="checkbox"
                       name="pastOfferingsChecked"
                       className="offerings-header-checkbox"
                       onChange={props.onCheckboxClickedHandler}
                       checked={props.pastOfferingsChecked} />
                <label htmlFor="offerings-past" className="pull-right">Past</label>
            </div> */}
        </div>
    );
};


OfferingsHeader.propTypes = {
	activeOfferingsChecked: PropTypes.bool.isRequired,
	onCheckboxClickedHandler: PropTypes.func.isRequired,
	onProjectTypeClickHandler: PropTypes.func.isRequired,
	pastOfferingsChecked: PropTypes.bool.isRequired,
	isAccreditedUser: PropTypes.bool.isRequired,
	selectedProjectType: PropTypes.string.isRequired,
	projectCategoryList: PropTypes.array.isRequired,
	projectCategoryOnClickHandler: PropTypes.func.isRequired,
	selectedProjectCategory: PropTypes.any.isRequired,
};

OfferingsHeader.defaultProps = {
	activeOfferingsChecked: true,
	onCheckboxClickedHandler: () => null,
	onProjectTypeClickHandler: () => null,
	pastOfferingsChecked: false,
	isAccreditedUser: false,
	selectedProjectType: PropTypes.string.isRequired,
	projectCategoryList: [],
	projectCategoryOnClickHandler: () => null,
	selectedProjectCategory: null,
};


export default OfferingsHeader;
