import React from "react";

export default function NewUserEarningsDashboard(props) {
    const EarningsBg ='/img/earnings-banner-bg.jpg'
	return (
		<div className="new-user-dashboard" style={{backgroundImage: `url(${EarningsBg})`}} >
			<h1>Welcome to Your Investor Earnings!</h1>
            <p>In the future, this page will show your Earnings, Distributions, and Current Investment information.</p>
		</div>
	);
}
