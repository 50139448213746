import React, { Component } from 'react';
import {
    Link
} from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import store from '../../index'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Modal,
    ModalHeader,
    ModalTitle,
    ModalClose,
    ModalBody,
    ModalFooter
  } from 'react-bootstrap';
  import getDate from '../../utils/ConvertDate'
import { PROJECT_REG_CF, PROJECT_REG_D } from '../../utils/ApplicationConstants';


class ApproveProjects extends Component {
    constructor(props){
        super(props);
        this.state={
            show: false,
            show1: false,
            totalProjects: [],
            allProjects: [],
            projects: [],
            pageNumber: 1,
            totalCount: 0,
            perPage: 10,
            activePage: 1,

            totalProjects_CF:[],
            allProjects_CF: [],
            projects_CF: [],
            pageNumber_CF: 1,
            totalCount_CF: 0,
            perPage_CF: 10,
            activePage_CF: 1,

            viewData_RegD:[],
            viewData_RegCF:[],
            id:props.session.id,
            isLoaded:false,
            errorOnLoad:false
        };

        this.getprojects=this.getprojects.bind(this);
        this.onProjectSelect=this.onProjectSelect.bind(this);
        this.onProjectSelect2=this.onProjectSelect2.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleCFPageChange = this.handleCFPageChange.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleCFSearchChange = this.handleCFSearchChange.bind(this);
        this.getDat = this.getDat.bind(this);
        this.onRegDApprove = this.onRegDApprove.bind(this);
        this.onRegCFApprove = this.onRegCFApprove.bind(this);
        this.onUnpublish = this.onUnpublish.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleCloseD = this.handleCloseD.bind(this);

    }
    handleClose() {
        this.setState({ show: false });
      }
      handleCloseD() {
        this.setState({ show1: false });
      }

    onProjectSelect(id){
        console.log(id, "project Id");
        fetch('/api/getnonappproject_RegD/' + (id)+'/'+this.state.id+'/'+this.props.session.marketing, {
            method: 'get'
        })
        .then((response) => {
            return response.json()
        })
        .then((data) => {
            console.log(data, "check front data");
            this.setState({
                viewData_RegD: data[0],
                isLoaded: true,
                errorOnLoad: false,
                show: !this.state.show,
            });
        })
        .catch((err) => {
            this.setState({
                errorOnLoad: true
            })

        })
    }
    onProjectSelect2(id){
        console.log(id, "project Id");
        fetch('/api/getnonappproject_RegCF/' + (id)+'/'+this.state.id+'/'+this.props.session.marketing, {
            method: 'get'
        })
        .then((response) => {
            return response.json()
        })
        .then((data) => {
            console.log(data, "check front data");
            this.setState({
                viewData_RegCF: data[0],
                isLoaded: true,
                errorOnLoad: false,
                show1: !this.state.show1,
            });
        })
        .catch((err) => {
            this.setState({
                errorOnLoad: true
            })

        })
    }

    onUnpublish(e,id,type) {
        e.preventDefault();
        console.log(id,type)
        fetch('/api/unpublish/'+type+'/'+id+'/'+this.state.id+'/'+this.props.session.marketing, {
            method: 'get'
        })
        .then((response) => {
            return response.json()
        })
        .then((data) => {
            this.getprojects();
        })
    }

    onReject(id,type) {
        fetch('/api/rejectprojects/'+type+'/'+id+'/'+this.state.id+'/'+this.props.session.marketing, {
            method: 'get'
        })
        .then((response) => {
            return response.json()
        })
        .then((data) => {
            this.getprojects();
        })
    }
    onRevert(id, type) {
        fetch('/api/revertprojects/' + type + '/' + id+'/'+this.state.id+'/'+this.props.session.marketing, {
            method: 'get'
        })
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                this.getprojects();
            })
    }

    getprojects(){
        fetch('/api/getprojects/'+this.state.id+'/'+this.props.session.marketing,{
            method:'get'
        })
            .then((response)=>{
                return response.json()
            })
            .then((data)=>{
                console.log(data,"data")
                var RegD_Projects = data.reg_d;
                var RegCF_Projects = data.reg_cf;
                this.setState({
                    totalProjects: RegD_Projects,
                    allProjects: RegD_Projects,
                    projects: RegD_Projects.slice(0, 10),
                    pageNumber: 1,
                    totalCount: RegD_Projects.length,
                    perPage: 10,
                    activePage: 1,

                    totalProjects_CF: RegCF_Projects,
                    allProjects_CF: RegCF_Projects,
                    projects_CF: RegCF_Projects.slice(0, 10),
                    pageNumber_CF: 1,
                    totalCount_CF: RegCF_Projects.length,
                    perPage_CF: 10,
                    activePage_CF: 1,
                    isLoaded:true,
                    err:{},
                    errorOnLoad:false,
                });
                console.log(this.state,"state")
            })
            .catch((err)=>{
                this.setState({
                    err:err,
                    errorOnLoad:true
                })
            })
    }

    handlePageChange(pageNumber) {
        var prev = ((pageNumber) - 1) * 10;
        var next = prev + 10;
        this.setState({
            activePage: pageNumber,
            projects: this.state.allProjects.slice(prev, next)
        });
    }
    handleCFPageChange(pageNumber) {
        var prev = ((pageNumber) - 1) * 10;
        var next = prev + 10;
        this.setState({
            activePage_CF: pageNumber,
            projects_CF: this.state.allProjects_CF.slice(prev, next)
        });
    }

    getDat(toSearch, objects) {
        return new Promise((resolve, reject) => {
            let dat = objects.filter(function (object) {
                let searchStatus = 0;
                for (const key in object) {
                    if (object.hasOwnProperty(key)) {
                        var patt = new RegExp(toSearch);
                        if (patt.test(object[key])) {
                            searchStatus = 1;
                        }
                    }
                }
                if (searchStatus) {
                    return true;
                }
            });
            resolve(dat);
        });
    }
    componentWillMount(){
        window.scrollTo(0, 0);
    }
    handleSearchChange(e) {
        let toSearch = e.target.value;
        if (toSearch == "" || toSearch == undefined) {
            this.setState({
                allProjects: this.state.totalProjects,
                projects: this.state.totalProjects.slice(0, 10),
                pageNumber: 1,
                totalCount: this.state.totalProjects.length,
                perPage: 10,
                activePage: 1
            })
        } else {
            let objects = this.state.totalProjects;
            this.getDat(toSearch, objects).then((dat) => {
                console.log(dat);
                this.setState({
                    allProjects: dat,
                    projects: dat.slice(0, 10),
                    pageNumber: 1,
                    totalCount: dat.length,
                    perPage: 10,
                    activePage: 1
                })
            }
            );
        }

    }

    handleCFSearchChange(e) {
        let toSearch = e.target.value;
        if (toSearch == "" || toSearch == undefined) {
            this.setState({
                allProjects_CF: this.state.totalProjects_CF,
                projects_CF: this.state.totalProjects_CF.slice(0, 10),
                pageNumber_CF: 1,
                totalCount_CF: this.state.totalProjects_CF.length,
                perPage_CF: 10,
                activePage_CF: 1
            })
        } else {
            let objects = this.state.totalProjects_CF;
            this.getDat(toSearch, objects).then((dat) => {
                console.log(dat);
                this.setState({
                    allProjects_CF: dat,
                    projects_CF: dat.slice(0, 10),
                    pageNumber_CF: 1,
                    totalCount_CF: dat.length,
                    perPage_CF: 10,
                    activePage_CF: 1
                })
            }
            );
        }

    }

    onRegDApprove(projectId) {
        this.props.history.push({
            pathname: '/app/project/approve',
            state: {
                projectId,
                projectType: PROJECT_REG_D,
            },
        });
    }

    onRegCFApprove(projectId) {
        this.props.history.push({
            pathname: '/app/project/approve',
            state: {
                projectId,
                projectType: PROJECT_REG_CF,
            },
        });
    }

    componentDidMount(){
        this.getprojects();
    }

    render() {
        return(
            <div className="container body">
                <div className="main_container">
                    <div className="right_col" role="main" style={{minHeight: 3753}}>
                        <div className>
                            <div className="page-title">
                                <div className="title_left">
                                    <h3>Approve projects</h3>
                                </div>
                            </div>
                            <div className="clearfix" />
                            <div className="row">
                                <div>
                                    <ul className="nav nav-pills nav-justified" style={{ padding: '0 10px', marginBottom: 20 }}>
                                        <li className="active"><a data-toggle="pill" href="#home">EnergyFunders Black (Reg D)</a></li>
                                        <li><a data-toggle="pill" href="#menu1">EnergyFunders Marketplace (Reg CF)</a></li>
                                    </ul>
                                    <div className="tab-content">
                                        <div id="home" className="tab-pane fade in active">
                                            <div className="col-md-12 col-sm-12 col-xs-12">
                                                <div className="x_panel">
                                                    <div className="x_title">
                                                        <div className="col-md-8"></div>
                                                        <div className="col-md-4">
                                                            <input className="pull-right" type="text" onChange={this.handleSearchChange} placeholder="Search here" />
                                                        </div>
                                                        <div className="clearfix" />
                                                    </div>
                                                    <div className="x_content">
                                                        <div id="datatable_wrapper" className="dataTables_wrapper form-inline dt-bootstrap no-footer">

                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                <div className="table-responsive">
                                                                    <table className="table table-striped table-bordered dataTable no-footer">
                                                                        <thead>
                                                                            <tr className="headings">
                                                                                <th className="column-title">Project ID</th>
                                                                                <th className="column-title">Project Name</th>
                                                                                {/* <th className="column-title">TotalPurchasePrice</th>
                                                                                <th className="column-title">FundingPeriod</th> */}
                                                                                <th className="column-title">Operating Company</th>
                                                                                <th className="column-title">Company Name</th>
                                                                                <th className="column-title">Created Date</th>
                                                                                <th className="column-title">Contact Name</th>
                                                                                <th className="column-title">Contact Email</th>
                                                                                <th className="column-title">View</th>
                                                                                <th className="column-title">Action</th>
                                                                                <th className="column-title">Approved by</th>
                                                                            </tr>
                                                                        </thead>
                                                                        {(() => {
                                                                         switch (this.state.projects.length<1) {
                                                                          case false: return (
                                                                        <tbody>
                                                                            {
                                                                                this.state.projects.map((project) => (
                                                                                    <tr key={project.ProjectID} className="even pointer">
                                                                                        <td>{project.ProjectID}</td>
                                                                                        <td>{project.ProjectName}</td>
                                                                                        {/* <td>{project.TotalPurchasePrice}</td>
                                                                                        <td>{project.FundingPeriod}</td> */}
                                                                                        <td>{project.OperatingCompany}</td>
                                                                                        <td>{project.CompanyName}</td>
                                                                                        <td>{getDate.getDate(project.CreatedDate)}</td>
                                                                                        <td>{project.ContactName}</td>
                                                                                        <td>{project.ContactEmail}</td>
                                                                                        <td><a href="#" className="btn btn-info" onClick={() => this.onProjectSelect(project.ProjectID)}>view</a></td>
                                                                                        {(() => {
                                                                                            if(!project.Deleted) {
                                                                                                switch (project.ProjectStatus) {
                                                                                                    case 0:
                                                                                                        if(project.savedStatus) {
                                                                                                            return (<td><button className="btn-sm btn-primary" onClick={() => this.onRegDApprove(project.ProjectID,1)}>Saved</button>
                                                                                                                        <button className="btn-sm btn-danger" onClick={() => this.onReject(project.ProjectID,1)}>Reject</button></td>);
                                                                                                        } else {
                                                                                                            return (<td><button className="btn-sm btn-primary" onClick={() => this.onRegDApprove(project.ProjectID,0)}>Approve</button>
                                                                                                                        <button className="btn-sm btn-danger" onClick={() => this.onReject(project.ProjectID,1)}>Reject</button></td>);
                                                                                                        }
                                                                                                    case 1:return (<td><button className="btn-sm btn-success" disabled={true}>Approved</button>
                                                                                                                       <button className="btn-sm btn-danger">Remove</button></td>);

                                                                                                    default: return (<td><button className="btn-sm btn-primary" type="button" onClick={() => this.onRegDApprove(project.ProjectID,0)}>Approve</button>
                                                                                                                        <button className="btn-sm btn-danger" onClick={() => this.onReject(project.ProjectID, 1)}>Reject</button></td>);
                                                                                                }
                                                                                            } else {
                                                                                                return (<td><button className="btn-sm btn-danger" onClick={() => this.onRevert(project.ProjectID, 1)}>Rejected</button></td>);
                                                                                            }
                                                                                        })()}

                                                                                        {(() => {
                                                                                            if (project.ProjectStatus == 1) {
                                                                                                return (<td>{project.FirstName}{project.LastName}</td>);
                                                                                            } else {
                                                                                                return (<td></td>);
                                                                                            }
                                                                                        })()}
                                                                                    </tr>
                                                                                ))
                                                                            }
                                                                        </tbody>
                                                                          );
                                                                          case true: return (
                                                                            <tbody>
                                                                              <tr  className="even pointer">
                                                                                <td colSpan="7" className="text-center">Nothing to show</td>
                                                                              </tr>
                                                                            </tbody>
                                                                                  );
                                                                                }
                                                                        })()}
                                                                    </table>
                                                                    </div>
                                                                </div>
                                                                <div className="pull-right">
                                                                    <Pagination
                                                                        firstPageText={<i className='glyphicon glyphicon-chevron-left' />}
                                                                        lastPageText={<i className='glyphicon glyphicon-chevron-right' />}
                                                                        prevPageText={<i className='glyphicon glyphicon-menu-left' />}
                                                                        nextPageText={<i className='glyphicon glyphicon-menu-right' />}
                                                                        activePage={this.state.activePage}
                                                                        itemsCountPerPage={this.state.perPage}
                                                                        totalItemsCount={this.state.totalCount}
                                                                        onChange={this.handlePageChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="menu1" className="tab-pane fade">
                                            <div className="col-md-12 col-sm-12 col-xs-12">
                                                <div className="x_panel">
                                                    <div className="x_title">
                                                        <div className="col-md-8"></div>
                                                        <div className="col-md-4">
                                                            <input className="pull-right" type="text" onChange={this.handleCFSearchChange} placeholder="Search here" />
                                                        </div>
                                                        <div className="clearfix" />
                                                    </div>
                                                    <div className="x_content">
                                                        <div id="datatable_wrapper" className="dataTables_wrapper form-inline dt-bootstrap no-footer">

                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                <div className="table-responsive">
                                                                    <table className="table table-striped table-bordered">
                                                                        <thead>
                                                                            <tr className="headings">
                                                                                <th className="column-title">Projec tName</th>
                                                                                {/* <th className="column-title">TotalPurchasePrice</th>
                                                                                <th className="column-title">FundingPeriod</th> */}
                                                                                <th className="column-title">Company Name</th>
                                                                                <th className="column-title">Company Status</th>
                                                                                <th className="column-title">Created Date</th>
                                                                                <th className="column-title">Contact Name</th>
                                                                                <th className="column-title">Contact Email</th>
                                                                                <th className="column-title">View</th>
                                                                                <th className="column-title">Action</th>
                                                                                <th className="column-title">Approved by</th>
                                                                            </tr>
                                                                        </thead>
                                                                        {(() => {
                                                                         switch (this.state.projects_CF.length<1) {
                                                                         case false: return (
                                                                        <tbody>
                                                                            {
                                                                                this.state.projects_CF.map((project) => (
                                                                                    <tr key={project.ProjectID} className="even pointer">
                                                                                        <td>{project.ProjectName}</td>
                                                                                        {/* <td>{project.TotalPurchasePrice}</td>
                                                                                        <td>{project.FundingPeriod}</td> */}
                                                                                        <td>{project.CompanyName}</td>
                                                                                        <td>{project.CompanyStatus}</td>
                                                                                        <td>{getDate.getDate(project.CreatedDate)}</td>
                                                                                        <td>{project.ContactName}</td>
                                                                                        <td>{project.ContactEmail}</td>
                                                                                        <td><a href="#" className="btn btn-info" onClick={() => this.onProjectSelect2(project.ProjectID)}>view</a></td>

                                                                                        {(() => {
                                                                                            if(!project.Deleted) {
                                                                                                switch (project.ProjectStatus) {
                                                                                                    case 0:
                                                                                                        if(project.savedStatus) {
                                                                                                            return (<td><button className="btn-sm btn-primary" onClick={() => this.onRegCFApprove(project.ProjectID,1)}>Saved</button>
                                                                                                                        <button className="btn-sm btn-danger" onClick={() => this.onReject(project.ProjectID,2)}>Reject</button></td>);
                                                                                                        } else {
                                                                                                            return (<td><button className="btn-sm btn-primary" onClick={() => this.onRegCFApprove(project.ProjectID,0)}>Approve</button>
                                                                                                                        <button className="btn-sm btn-danger" onClick={() => this.onReject(project.ProjectID,2)}>Reject</button></td>);
                                                                                                        }


                                                                                                    case 1:return (<td><button className="btn-sm btn-success" disabled={true}>Approved</button>
                                                                                                                       <button className="btn-sm btn-danger" onClick={(e) => this.onUnpublish(e,project.ProjectID,2)}>Remove</button></td>);

                                                                                                    default: return (<td><button className="btn-sm btn-primary" type="button" onClick={() => this.onRegCFApprove(project.ProjectID,0)}>Approve</button>
                                                                                                                        <button className="btn-sm btn-danger" onClick={() => this.onReject(project.ProjectID, 2)}>Reject</button></td>);
                                                                                                }
                                                                                            } else {
                                                                                                return (<td><button className="btn-sm btn-danger" onClick={() => this.onRevert(project.ProjectID, 2)}>Rejected</button></td>);
                                                                                            }
                                                                                        })()}

                                                                                        {(() => {
                                                                                            if (project.ProjectStatus == 1) {
                                                                                                return (<td>{project.FirstName}{project.LastName}</td>);
                                                                                            } else {
                                                                                                return (<td></td>);
                                                                                            }
                                                                                        })()}
                                                                                    </tr>
                                                                                ))
                                                                            }
                                                                        </tbody>
                                                                         );
                                                                         case true: return (
                                                                            <tbody>
                                                                              <tr  className="even pointer">
                                                                                <td colSpan="7" className="text-center">Nothing to show</td>
                                                                              </tr>
                                                                            </tbody>
                                                                                  );
                                                                                }
                                                                        })()}
                                                                    </table>
                                                                    </div>
                                                                </div>
                                                                <div className="pull-right">
                                                                    <Pagination
                                                                        firstPageText={<i className='glyphicon glyphicon-chevron-left' />}
                                                                        lastPageText={<i className='glyphicon glyphicon-chevron-right' />}
                                                                        prevPageText={<i className='glyphicon glyphicon-menu-left' />}
                                                                        nextPageText={<i className='glyphicon glyphicon-menu-right' />}
                                                                        activePage={this.state.activePage_CF}
                                                                        itemsCountPerPage={this.state.perPage_CF}
                                                                        totalItemsCount={this.state.totalCount_CF}
                                                                        onChange={this.handleCFPageChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
<Modal show={this.state.show} onHide={this.handleClose}>
<Modal.Header closeButton>
    <Modal.Title></Modal.Title>
</Modal.Header>
<Modal.Body>
<div className="">
    <div className="panel-body">
        <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="x_panel">
                    <div className="x_title">
                        <h4>Project Details</h4>
                    </div>
                    <div className="x_content" id="target">
                    <div className="table-responsive">
                        <table className="table table-striped jambo_table bulk_action">
                            <thead>
                                <tr>
                                    <th>Project Field</th>
                                    <th>Details </th>
                                </tr>
                            </thead>
                            {(() => {
                                switch (this.state.viewData_RegD.length<1) {
                                case false: return (
                            <tbody>
                                <tr className="even pointer">
                                    <td className=" ">Project Name:</td>
                                    <td className=" ">{this.state.viewData_RegD.ProjectName}</td>
                                </tr>
                                <tr className="even pointer">
                                    <td className=" ">Operating Company</td>
                                    <td className=" ">{this.state.viewData_RegD.OperatingCompany}</td>
                                </tr>
                                <tr className="even pointer">
                                    <td className=" ">Company Name</td>
                                    <td className=" ">{this.state.viewData_RegD.CompanyName}</td>
                                </tr>
                                <tr className="even pointer">
                                    <td className=" ">Mailing Address</td>
                                    <td className=" ">{this.state.viewData_RegD.MailingAddress}</td>
                                </tr>
                                <tr className="even pointer">
                                    <td className=" ">Contact Name</td>
                                    <td className=" ">{this.state.viewData_RegD.ContactName}</td>
                                </tr>
                                <tr className="even pointer">
                                    <td className=" ">Contact Position</td>
                                    <td className=" ">{this.state.viewData_RegD.ContactPosition}</td>
                                </tr>
                                <tr className="even pointer">
                                    <td className=" ">Contact Phone Number</td>
                                    <td className=" ">{this.state.viewData_RegD.ContactPhoneNumber}</td>
                                </tr>
                                <tr className="even pointer">
                                    <td className=" ">Contact Fax Number</td>
                                    <td className=" ">{this.state.viewData_RegD.ContactFaxNumber}</td>
                                </tr>
                                <tr className="even pointer">
                                    <td className=" ">Contact Email</td>
                                    <td className=" ">{this.state.viewData_RegD.ContactEmail}</td>
                                </tr>
                                <tr className="even pointer">
                                    <td className=" ">Reworking Plan</td>
                                    <td className=" ">{this.state.viewData_RegD.ReworkingPlan}</td>
                                </tr>
                                <tr className="even pointer">
                                    <td className=" ">Project Risk</td>
                                    <td className=" ">{this.state.viewData_RegD.ProjectRisk}</td>
                                </tr>
                                <tr className="even pointer">
                                    <td className=" ">Revenue Projections</td>
                                    <td className=" ">{this.state.viewData_RegD.RevenueProjections}</td>
                                </tr>
                                <tr className="even pointer">
                                    <td className=" ">Working Interest Description</td>
                                    <td className=" ">{this.state.viewData_RegD.WorkingInterestDescription}</td>
                                </tr>
                                <tr className="even pointer">
                                    <td className=" ">Prepare Lease Title</td>
                                    <td className=" ">{this.state.viewData_RegD.PrepareLeaseTitle}</td>
                                </tr>
                                <tr className="even pointer">
                                    <td className=" ">Principals List</td>
                                    <td className=" ">{this.state.viewData_RegD.PrincipalsList}</td>
                                </tr>
                                <tr className="even pointer">
                                    <td className=" ">Does Have Permits</td>
                                    <td className=" ">{this.state.viewData_RegD.DoesHavePermits}</td>
                                </tr>
                            </tbody>
                                );
                                case true: return (
                                    <tbody>
                                      <tr  className="even pointer">
                                        <td colSpan="7" className="text-center">Nothing to show</td>
                                      </tr>
                                    </tbody>
                                          );
                                        }
                                })()}
                        </table>
                        </div>

                        {(() => {
                            if (!this.state.viewData_RegD.ProjectStatus) {
                                return (<div style={{ display: 'block',minHeight:100 }}>
                                    <button className="btn btn-success aa" data-dismiss="modal" type="Submit" onClick={() => this.onRegDApprove(this.state.viewData_RegD.ProjectID)}>
                                    Approve</button>
                                </div>
                                    );
                            }
                        })()}

                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
</Modal.Body>
<Modal.Footer>

</Modal.Footer>
</Modal>



<Modal show={this.state.show1} onHide={this.handleCloseD}>
<Modal.Header closeButton>
    <Modal.Title></Modal.Title>
</Modal.Header>
<Modal.Body>
    <div className="">
        <div className="panel-body">
            <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                    <div className="x_panel">
                        <div className="x_title">
                            <h4>Project Details</h4>
                        </div>
                        <div className="x_content" id="target">
                            <div className="table-responsive">
                                <table className="table table-striped jambo_table bulk_action">
                                    <thead>
                                        <tr>
                                            <th>Project Field</th>
                                            <th>Details </th>
                                        </tr>
                                    </thead>
                                    {(() => {
                                    switch (this.state.viewData_RegCF.length<1) {
                                    case false: return (
                                    <tbody>
                                        <tr className="even pointer">
                                            <td className=" ">Project Name:</td>
                                            <td className=" ">{this.state.viewData_RegCF.ProjectName}</td>
                                        </tr>
                                        <tr className="even pointer">
                                            <td className=" ">Company Name</td>
                                            <td className=" ">{this.state.viewData_RegCF.CompanyName}</td>
                                        </tr>
                                        <tr className="even pointer">
                                            <td className=" ">Estimated Net Revenue</td>
                                            <td className=" ">{this.state.viewData_RegCF.EstimatedNetRevenue}</td>
                                        </tr>
                                        <tr className="even pointer">
                                            <td className=" ">Estimated Total Revenue</td>
                                            <td className=" ">{this.state.viewData_RegCF.EstimatedTotalRevenue}</td>
                                        </tr>
                                        <tr className="even pointer">
                                            <td className=" ">Estimated No of Customers</td>
                                            <td className=" ">{this.state.viewData_RegCF.EstimatedCustomers}</td>
                                        </tr>
                                        <tr className="even pointer">
                                            <td className=" ">Current Investment</td>
                                            <td className=" ">{this.state.viewData_RegCF.CurrentInvestment}</td>
                                        </tr>
                                        <tr className="even pointer">
                                            <td className=" ">Contact Name</td>
                                            <td className=" ">{this.state.viewData_RegCF.ContactName}</td>
                                        </tr>
                                        <tr className="even pointer">
                                            <td className=" ">Contact Position</td>
                                            <td className=" ">{this.state.viewData_RegCF.ContactPosition}</td>
                                        </tr>
                                        <tr className="even pointer">
                                            <td className=" ">Contact Address</td>
                                            <td className=" ">{this.state.viewData_RegCF.ContactAddress}</td>
                                        </tr>
                                        <tr className="even pointer">
                                            <td className=" ">Contact Phone Number</td>
                                            <td className=" ">{this.state.viewData_RegCF.ContactPhoneNumber}</td>
                                        </tr>
                                        <tr className="even pointer">
                                            <td className=" ">Contact Fax Number</td>
                                            <td className=" ">{this.state.viewData_RegCF.ContactFaxNumber}</td>
                                        </tr>
                                        <tr className="even pointer">
                                            <td className=" ">Contact Email</td>
                                            <td className=" ">{this.state.viewData_RegCF.ContactEmail}</td>
                                        </tr>
                                        <tr className="even pointer">
                                            <td className=" ">Project Details</td>
                                            <td className=" ">{this.state.viewData_RegCF.ProjectDetails}</td>
                                        </tr>
                                        <tr className="even pointer">
                                            <td className=" ">Created Date</td>
                                            <td className=" ">{getDate.getDate(this.state.viewData_RegCF.CreatedDate)}</td>
                                        </tr>
                                        <tr className="even pointer">
                                            <td className=" ">Milestone</td>
                                            <td className=" ">{this.state.viewData_RegCF.Milestone}</td>
                                        </tr>
                                        <tr className="even pointer">
                                            <td className=" ">Company Status</td>
                                            <td className=" ">{this.state.viewData_RegCF.CompanyStatus}</td>
                                        </tr>
                                        <tr className="even pointer">
                                            <td className=" ">Next Five Year Plans</td>
                                            <td className=" ">{this.state.viewData_RegCF.NextFiveYearPlans}</td>
                                        </tr>
                                        <tr className="even pointer">
                                            <td className=" ">Project Category</td>
                                            <td className=" ">{this.state.viewData_RegCF.ProjectCategory}</td>
                                        </tr>
                                        <tr className="even pointer">
                                            <td className=" ">Principals List</td>
                                            <td className=" ">{this.state.viewData_RegCF.PrincipalsList}</td>
                                        </tr>
                                        <tr className="even pointer">
                                            <td className=" ">EnergyFunders source</td>
                                            <td className=" ">{this.state.viewData_RegCF.EFsource}</td>
                                        </tr>
                                    </tbody>
                                    );
                                    case true: return (
                                        <tbody>
                                        <tr  className="even pointer">
                                            <td colSpan="7" className="text-center">Nothing to show</td>
                                        </tr>
                                        </tbody>
                                            );
                                            }
                                    })()}
                                </table>
                            </div>

                                {(() => {
                                    if (!this.state.viewData_RegCF.ProjectStatus) {
                                        return (<div style={{ display: 'block',minHeight:100 }}>
                                            <button className="btn btn-success aa" data-dismiss="modal" type="Submit" onClick={() => this.onRegCFApprove(this.state.viewData_RegCF.ProjectID)}>
                                            Approve</button>
                                        </div>
                                            );
                                    }
                                })()}

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</Modal.Body>
<Modal.Footer>

</Modal.Footer>
</Modal>



            </div>


        );
    }
}


const { object, bool } = PropTypes;

ApproveProjects.propTypes = {
    session: object.isRequired,
    authenticated: bool.isRequired,
    checked: bool.isRequired
};

const mapState = ({ session }) => ({
    session: session.user,
    checked: session.checked,
    authenticated: session.authenticated
});

export default withRouter(connect(mapState)(ApproveProjects));
