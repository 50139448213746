import * as Actions from '../actions/actions';
import initialState from '../../../../reducers/appInitialState';
import { updateRequestCounter } from '../../../../utils/General';

export default (state = initialState.investorDashboard, action) => {
    const { minus, plus } = updateRequestCounter(state, 'IDOnFlightRequest');

    switch (action.type) {
    case Actions.INVESTOR_DASHBOARD_END_REQ:
        return {
            ...state,
            IDOnFlightRequest: minus,
        };
    case Actions.INVESTOR_DASHBOARD_START_REQ:
        return {
            ...state,
            IDOnFlightRequest: plus,
        };
    case Actions.INVESTOR_DASHBOARD_INV_REQ_SUCCESS:
        return {
            ...state,
            IDOnFlightRequest: minus,
            ...action.data,
        };
        case Actions.INVESTOR_TAB_ACTIVE:
            return {
                ...state,
                activeTab:action.data
            };
            case Actions.W9_FORM_VERIGY:
               return {
                    ...state,
                    w9Verified:action.data.w9Verified,
                    EntityID:action.data.EntityID
                };
    default:
        return { ...state };
    }
};
