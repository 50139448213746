import PropTypes from 'prop-types'
import React from 'react';
import {Modal, Panel} from 'react-bootstrap';
import {
    PROJECT_DETAIL_ICONS,
    PROJECT_REG_CF,
    PROJECT_REG_D,
    PROJECT_UPDATE_CREATE
} from "../../../../../utils/ApplicationConstants";
import Icon from "../../../IconComponent/IconComponent";
import SingleLineInputComponent from '../../../InputComponents/SingleLineInputComponent/SingleLineInputComponent'
import MultilineInputComponent from '../../../InputComponents/MultilineInputComponent/MultilineInputComponent'
import {getUUID} from "../../../../../utils/General";
import IntInputComponent from "../../../InputComponents/IntInputComponent/IntInputComponent";

class AddHighlightComponent extends React.Component {
    DEFAULT_STATE_VALUES = {
        highlightImage: '',
        isHighlightPanelExpanded: true,

        highlightOrder: 0,
        highlightTitle: '',
        highlightDescription: '',
        isInputPanelExpanded: true,
    };

    constructor(props) {
        super(props);

        this.state = {...this.DEFAULT_STATE_VALUES};
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const _highlightId = prevProps.highlightId;
        const {highlightId} = this.props;

        if (_highlightId !== highlightId) {
            this.setState({
                highlightImage: this.props.selectedHighlight.highlightImageName,
                highlightOrder: this.props.selectedHighlight.highlightOrder,
                highlightTitle: this.props.selectedHighlight.highlightName,
                highlightDescription: this.props.selectedHighlight.highlightDescription,
            });
        }
    }

    renderImagePanel = () => {
        const {isHighlightPanelExpanded} = this.state;

        return (
            <Panel expanded={isHighlightPanelExpanded}>
                <Panel.Heading>
                    <Panel.Title>
                        <div className="container pointer no-margin"
                             onClick={() => this.togglePanel('isHighlightPanelExpanded')}>
                            <span className="pull-left">Select an icon for the highlight</span>
                            <Icon iconName={isHighlightPanelExpanded ? "minus" : "plus"} extraClasses="pull-right"/>
                        </div>
                    </Panel.Title>
                </Panel.Heading>
                <Panel.Collapse>
                    <Panel.Body>
                        {this.renderImageIcon()}
                    </Panel.Body>
                </Panel.Collapse>
            </Panel>
        );
    };

    renderImageIcon = () => {
        const {highlightImage} = this.state;
        return PROJECT_DETAIL_ICONS.map(icon => {
            const {imageIcon, imageName} = icon;
            const isSelected = highlightImage === imageIcon ? 'selected' : '';

            return (
                <div className={`col-xs-6 col-sm-4 col-md-4 pointer highlight-container-image-container ${isSelected}`}
                     onClick={() => this.selectIcon(imageIcon)}
                     key={imageIcon}>
                    <Icon iconName={imageIcon}
                          extraClasses="highlight-container-image-container-image-size"/>
                    <section className="capitalize-text">
                        {imageName}
                    </section>
                </div>
            );
        });
    };

    selectIcon = (highlightImage) => {
        this.setState({highlightImage});
    };

    renderInputPanel = () => {
        const {isInputPanelExpanded} = this.state;

        return (
            <Panel expanded={isInputPanelExpanded}>
                <Panel.Heading>
                    <Panel.Title>
                        <div className="container pointer no-margin"
                             onClick={() => this.togglePanel('isInputPanelExpanded')}>
                            <span className="pull-left">Add the title and description</span>
                            <Icon iconName={isInputPanelExpanded ? "minus" : "plus"} extraClasses="pull-right"/>
                        </div>
                    </Panel.Title>
                </Panel.Heading>
                <Panel.Collapse>
                    <Panel.Body>
                        <form onSubmit={this.onSubmitData}>
                            <IntInputComponent elementValue={this.state.highlightOrder}
                                               inputName="highlightOrder"
                                               inputTitle="Highlight order"
                                               onInputUpdate={this.onInputUpdate}/>

                            <SingleLineInputComponent elementValue={this.state.highlightTitle}
                                                      onInputUpdate={this.onInputUpdate}
                                                      inputTitle="Highlight title"
                                                      inputName="highlightTitle"
                                                      maxInputLength={50}/>

                            <MultilineInputComponent elementValue={this.state.highlightDescription}
                                                     onInputUpdate={this.onInputUpdate}
                                                     inputTitle="Highlight description"
                                                     inputName="highlightDescription"
                                                     maxInputLength={100}
                                                     rows={2}/>

                            <br/>

                            <div className="pull-right">
                                <button type="reset"
                                        onClick={this.closeModal}
                                        className="btn btn-danger btn-sm capitalize-text">
                                    cancel
                                </button>
                                <button type="submit"
                                        disabled={this.disableSubmitButton()}
                                        className="btn btn-primary btn-sm capitalize-text">
                                    save
                                </button>
                            </div>
                        </form>
                    </Panel.Body>
                </Panel.Collapse>
            </Panel>
        );
    };

    onSubmitData = (event) => {
        event.preventDefault();
        const {actionType, onAddNewHighlight, onUpdateHighlight} = this.props;
        let method = actionType === PROJECT_UPDATE_CREATE ? onAddNewHighlight : onUpdateHighlight;

        method(this.createSubmitObject());
        this.setState(this.DEFAULT_STATE_VALUES, this.closeModal);
    };

    togglePanel = (panelName) => {
        this.setState({[panelName]: !this.state[panelName]})
    };

    onInputUpdate = (event) => {
        const {name, value} = event.target;
        this.setState({[name]: value});
    };

    createSubmitObject = () => {
        const {projectType, projectId, actionType, highlightId} = this.props;
        const {
            highlightImage: highlightImageName, highlightTitle: highlightName,
            highlightDescription, highlightOrder
        } = this.state;

        const projectRegDI = projectType === PROJECT_REG_D ? projectId : 0;
        const projectRegCfId = projectType === PROJECT_REG_CF ? projectId : 0;
        const _highlightId = actionType === PROJECT_UPDATE_CREATE ? getUUID() : highlightId;

        return {
            projectRegDI,
            highlightName,
            projectRegCfId,
            highlightOrder,
            highlightImageName,
            highlightDescription,
            highlightId: _highlightId,
        };
    };

    closeModal = () => {
        this.setState(this.DEFAULT_STATE_VALUES);
        this.props.onCloseModal();
    };

    disableSubmitButton = () => {
        const {highlightImage, highlightTitle, highlightDescription} = this.state;
        const isCorrectImageSelected = highlightImage !== '';
        const isCorrectHighlightDescription = highlightDescription !== '';
        const isCorrectHighlightTitle = highlightTitle !== '';

        return (!isCorrectHighlightDescription || !isCorrectHighlightTitle || !isCorrectImageSelected)
    };

    render = () => {
        return (
            <Modal show={this.props.isModalOpen}
                   onHide={this.closeModal}
                   className="no-padding">
                <Modal.Header closeButton>
                    <Modal.Title className="capitalize-text">
                        Add Highlight
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="col-xs-12 col-sm-3 col-md-4"/>
                        <div className="col-xs-12 col-sm-6 col-md-4">
                            {this.renderImagePanel()}
                            {this.renderInputPanel()}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };
}

AddHighlightComponent.propTypes = {
    actionType: PropTypes.string.isRequired,
    highlightId: PropTypes.number.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    onAddNewHighlight: PropTypes.func.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    onUpdateHighlight: PropTypes.func.isRequired,
    projectId: PropTypes.number.isRequired,
    projectType: PropTypes.string.isRequired,
    selectedHighlight: PropTypes.object.isRequired,
};

AddHighlightComponent.defaultProps = {
    actionType: '',
    highlightId: 0,
    isModalOpen: false,
    onAddNewHighlight: () => null,
    onCloseModal: () => null,
    onUpdateHighlight: () => null,
    projectId: 0,
    projectType: '',
    selectedHighlight: {},
};

export default AddHighlightComponent;
