import NotificationService from '../../../../utils/NotificationService';
import RequestService from '../../../../api/RequestService';
import swal from 'sweetalert2';
import { getImpersonateID  } from "../../../../utils/General";
export const NE_END_REQUEST = 'NE_END_REQUEST';
export const NE_START_REQUEST = 'NE_START_REQUEST';
export const NE_DWOLLA_CATEGORIES_SUCCESS_REQ = 'NE_DWOLLA_CATEGORIES_SUCCESS_REQ';

const startReq = () => ({ type: NE_START_REQUEST });
const endReq = () => ({ type: NE_END_REQUEST });
const dwollaCategories = data => ({
    type: NE_DWOLLA_CATEGORIES_SUCCESS_REQ,
    data,
});

export const createNewEntity = (isAdmin, investorId, payload, clean = () => null) => async (dispatch) => {
    const impersonateID = await getImpersonateID();
    const adminUrl = `/api/admin/investor/${investorId}/entity`;
    const investorUrl = `/api/investor/entity/${impersonateID}`;
    const url = isAdmin ? adminUrl : investorUrl;

    try {
        dispatch(startReq());

        const { data } = await RequestService.post({
            url,
            data: payload,
        });

        NotificationService.success();
        clean(data.createdEntityId);
    } catch (error) {
        console.log('Error creating new entity', error);
        NotificationService.error(
            'Error creating new entity',
            10000,
        );
    } finally {
        dispatch(endReq());
    }
};

export const getDwollaBusinessCategories = () => async (dispatch) => {
    const url = '/api/dwolla/business-classification';

    try {
        dispatch(startReq());
        const { data } = await RequestService.get(url);

        dispatch(dwollaCategories(data));
    } catch (error) {
        dispatch(endReq());
    }
};

export const verifyEntityEntity = (isAdmin, investorId, entityId, requestData, cleanUp, uploadDocuments = () => null) => async (dispatch) => {

  const impersonateID = await getImpersonateID();
    const adminUrl = `/api/admin/investor/${investorId}/entity/${entityId}/verify`;
    const investorUrl = `/api/investor/entity/${entityId}/verify?impersonateID=${impersonateID}`;
    const url = isAdmin ? adminUrl : investorUrl;

    try {
        dispatch(startReq());
        const { data } = await RequestService.post({
            url,
            data: requestData,
        });

        if (data.verificationStatus === 'verified' && data.beneficialOwnerStatus === 'verified') {
            swal('Entity Verified Successfully', '', 'success');
        }
        else if (data.verificationStatus === 'retry') {
            swal('Entity Verification Failed', 'Please double check your data and try again.', 'info');
        }
        else if (data.verificationStatus === 'document') {
            swal('Entity Documents Required', 'Please upload supporting documents.', 'info');
            uploadDocuments(data.docs);
            return;
        }
        else if (data.verificationStatus === 'suspended') {
            swal('Please contact Investor Relations', '', 'error');
        }
        else {
            swal('Please contact Investor Relations', '', 'error');
        }

        dispatch(endReq());
        cleanUp();
    } catch (error) {
        dispatch(endReq());
        console.log('Error verifying the new entity: ERROR: ', error);
        NotificationService.error(
            'Error verifying the new entity. Please contact Investor Relations.',
            15000,
        );
        cleanUp();
    }
};

export const uploadEntityVerificationDocument = (isAdmin, investorId, entityId, payload, cleanUp = () => null) => async (dispatch) => {
    const adminUrl = `/api/admin/investor/${investorId}/entity/${entityId}/verify/docs`;
    const investorUrl = `/api/investor/entity/${entityId}/verify/docs`;
    const url = isAdmin ? adminUrl : investorUrl;

    // console.log('ppp', payload);

    try {
        await RequestService.post({
            url,
            data: payload,
        });

        NotificationService.success();
        dispatch(endReq());
        cleanUp();
    } catch (error) {
        dispatch(endReq());
        NotificationService.error(
            'Error uploading the files to Dwolla',
            10000,
        );
        console.log('Error uploading the files to Dwolla');
    }
};
