import React from 'react';
import Icon from '../IconComponent/IconComponent';

const PreviewElementComponent = (props) => {
    const {file, deleteFile, target} = props;

    let html = null;
    if (file) {
        html = (<tr>
            <td>{file.name}</td>
            <td>{((file.size/1024) / 1000).toFixed(2)}MB</td>
            <td>{file.type}</td>
            <td>
                <button type="button"
                        className="btn btn-danger"
                        onClick={() => deleteFile(file, target)}>
                    <Icon iconName="trash-o"/>
                </button>
            </td>
        </tr>)
    }

    return html;
};

export default PreviewElementComponent;
