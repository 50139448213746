import PropTypes from 'prop-types'
import React from 'react';
import CKEditor from "react-ckeditor-component";
import ToolbarConfig from "../../../admin/ProjectUpdate/components/CkEditorConfig";
import BaseComponent from "../utils/BaseComponent";

class RichTextEditorComponent extends BaseComponent {
    onEditorChange = (event) => {
        const {onChange, name} = this.props;
        let value = event.editor.getData();

        onChange({target: {name, value}});
    };

    setContentAfterLoad = () => {
        const {value, name} = this.props;
        const {id} = this.refs[name].editorInstance;
        const instances = window.CKEDITOR.instances;
        const result = Object.keys(instances)
            .map(key => instances[key])
            .filter(instance => instance.id === id)[0];
        result.document.$.getElementsByTagName("body")[0].innerHTML = value;
    };

    render = () => {
        const {name, reachTextTitle, value, editorHeight} = this.props;

        return (
            <div className="form-group">
                <label className="input-label">{reachTextTitle}{this.getRequired()}</label>
                <CKEditor name={name} ref={name}
                          content={value}
                          config={{
                              height: editorHeight,
                          }}
                          events={{
                              "change": this.onEditorChange,
                              "instanceReady": this.setContentAfterLoad,
                          }}/>
            </div>
        );
    };
}

RichTextEditorComponent.propTypes = {
    editorHeight: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    reachTextTitle: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    isRequired: PropTypes.bool,
};

RichTextEditorComponent.defaultProps = {
    editorHeight: 500,
    name: '',
    onChange: () => null,
    reachTextTitle: '',
    value: '',
    isRequired: false,
};

export default RichTextEditorComponent;
