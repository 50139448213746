// This is the component for /resendmail

import React, { Component } from "react";
import NavBar from "./components/shared/NavigationManager/NavigationBar/NavBarNoAuth";
import FooterFirst from "./components/shared/NavigationManager/Footer/FooterFirst";
import swal from "sweetalert2";
import {Link, withRouter } from "react-router-dom";
import RequestService from "./api/RequestService";
import Stepper from "react-stepper-horizontal";
import NavSignUp from "./components/shared/NavigationManager/NavigationBar/NavSignUp";
class User2 extends Component {
	constructor(props) {
		super(props);

		this.state = {
			steps: [
				{ title: "Create Account" },
				{ title: "Investment Profile" },
				{ title: "Terms & Conditions" },
			],
			currentStep: 2,
			UserID:
				props.location.state && props.location.state.UserID
					? props.location.state.UserID
					: 0,
			PWHash:
				props.location.state && props.location.state.PWHash
					? props.location.state.PWHash
					: "",
			Email:
				props.location.state && props.location.state.Email
					? props.location.state.Email
					: "",
			AutoSend:
				props.location.state && props.location.state.AutoSend
					? props.location.state.AutoSend
					: 0,
			isLoaded: false,
			errorOnLoad: false,
		};

		this.resendVerificationEmail = this.resendVerificationEmail.bind(this);
	}

	async resendVerificationEmail() {
		if (this.state.Email !== "") {
			try {
				await RequestService.post({
					url: "/api/resendVerificationEmail",
					data: "email=" + this.state.Email,
					headers: {
						Authorization: JSON.stringify({
							id: this.state.UserID,
							marketing: this.state.PWHash,
							isRegistering: true,
						}),
					},
				});

				// swal("Email Sent", "", "success");
                swal({
                    title: 'Email Sent',
                    text: "",
                    type: 'success',
                    heightAuto:false,
                })
			} catch (err) {
				console.log(err);
			}
		} else {
			this.props.history.push({
				pathname: "/login",
			});
		}
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		if (this.state.AutoSend === 1) {
			this.resendVerificationEmail();
			this.setState({
				AutoSend: 0,
			});
		}
	}

	render() {
		const pathimg = "/img/email.png";
		const pathname = window.location.pathname.toLocaleLowerCase();

		if (pathname.match("/resendmail")) {
			document.body.classList.add("reg-wrapper");
		}
		const { steps, currentStep } = this.state;
		return (
			<>
				{/* <NavBar isMarketplace={this.props.isMarketplace} /> */}
                <div className="d-flex flex-column ht-100">
				<NavSignUp />
				<div className="reg-verified bg">
					<div
						style={{ backgroundImage: `url(${pathimg})` }}
						className="img-email"
					></div>
					<h1>Thank you! Please Check Your Email Account
						<br /> Now to 
						<span> Verify Your Email Address.</span>
					</h1>
					<p className="text-center">We just emailed you a verification link. Please check your email now and<br/> click that link in order to continue setting up your account.</p>
						{/* <p> We've just sent an email to <b>{this.state.Email}</b>. Please click the link in the email to verify your account and get started.<br/>
							</p> */}
					<button  id="resend" ref="resend" onClick={this.resendVerificationEmail} value="submit" className="btn  margin-top-10px" style={{ height: 50,paddingLeft:40,paddingRight:40 }}>Resend Verification Email</button>
					<p className="text-center opacity-60 font-bold">If you did not receive a verification email, please check your spam folder.<br/> If needed, ask us to 
						<Link to="/" className="text-underline "> resend the email.</Link>
					</p>
				</div>
			</div>
			</>
		);
	}
}

export default withRouter(User2);
