import axios from 'axios';
import {HTTP_ERR_BAD_REQUEST} from "../../../../utils/ApplicationConstants";
import NotificationService from "../../../../utils/NotificationService";
import RequestService from "../../../../api/RequestService";

export const EXTERNAL_IMAGE_REQUEST_START = 'EXTERNAL_IMAGE_REQUEST_START';
export const EXTERNAL_IMAGE_REQUEST_END = 'EXTERNAL_IMAGE_REQUEST_END';
export const EXTERNAL_IMAGE_UPLOAD_SUCCESS = 'EXTERNAL_IMAGE_UPLOAD_SUCCESS';

const startRequest = () => ({
    type: EXTERNAL_IMAGE_REQUEST_START,
});

const endRequest = () => ({
    type: EXTERNAL_IMAGE_REQUEST_END,
});

export const imageRequestSuccess = (data) => ({
    type: EXTERNAL_IMAGE_UPLOAD_SUCCESS,
    data,
});

export function uploadExternalFilesToS3(formData, userData, limit) {
    return async (dispatch) => {
        dispatch(startRequest());
        const URL = `/api/u/${userData.id}/h/${userData.marketing}/upload-images`;
        const response = await axios.post(URL, formData);
        const {data, status} = response;

        dispatch(endRequest());
        if (status >= HTTP_ERR_BAD_REQUEST) {
            NotificationService.error('Error uploading file to the server', 6000);
            return;
        }

        dispatch(getFilesFromDb(1, limit));
        NotificationService.success('Files uploaded correctly!');
    };
}

export function getFilesFromDb(page, limit) {
    return async (dispatch) => {
        dispatch(startRequest());

        const {data, status} = await RequestService.post({
            url: '/api/files',
            data: {page, limit}
        });

        if (status >= HTTP_ERR_BAD_REQUEST) {
            NotificationService.error('Error uploading file to the server', 6000);
            dispatch(endRequest());
            return;
        }

        dispatch(imageRequestSuccess(data));
    };
}

export function deleteFileFromDB(mediaId, limit) {
    return async (dispatch) => {
        const url = `/api/files/${mediaId}`;

        dispatch(startRequest());

        const {data} = await RequestService.delete(url);
        const {status} = data;

        dispatch(endRequest());

        if (status >= HTTP_ERR_BAD_REQUEST) {
            NotificationService.error("Error deleting the File", 6000);
            return;
        }

        NotificationService.success();
        dispatch(getFilesFromDb(1, limit));
    };
}

export function performSearch(searchTerm, limit, page) {
    return async (dispatch) => {
        const data = {searchTerm, page, limit};
        const url = `/api/files/search`;

        dispatch(startRequest());

        const {data: result, status} = await RequestService.post({url, data});

        if (status >= HTTP_ERR_BAD_REQUEST) {
            NotificationService.error("Error executing the search", 6000);
            dispatch(endRequest());
            return;
        }

        dispatch(imageRequestSuccess(result));
    };
}
