import PropTypes from 'prop-types'
import React from 'react';
import {Panel} from 'react-bootstrap';
import Icon from "../IconComponent/IconComponent";

class CollapsiblePanelComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isInputPanelExpanded: props.isExpanded,
        }
    }

    togglePanel = (panelName) => {
        this.setState({[panelName]: !this.state[panelName]})
    };

    render = () => {
        const {panelTitle} = this.props;
        const {isInputPanelExpanded} = this.state;

        return (
            <Panel expanded={isInputPanelExpanded}>
                <Panel.Heading>
                    <Panel.Title>
                        <div className="container pointer no-margin"
                             onClick={() => this.togglePanel('isInputPanelExpanded')}>
                            <span className="pull-left">{panelTitle}</span>
                            <Icon iconName={isInputPanelExpanded ? "minus" : "plus"} extraClasses="pull-right"/>
                        </div>
                    </Panel.Title>
                </Panel.Heading>
                <Panel.Collapse>
                    <Panel.Body>
                        {this.props.children}
                    </Panel.Body>
                </Panel.Collapse>
            </Panel>
        );
    };
}

CollapsiblePanelComponent.propTypes = {
    children: PropTypes.any.isRequired,
    isExpanded: PropTypes.bool.isRequired,
    panelTitle: PropTypes.string.isRequired
};

CollapsiblePanelComponent.defaultProps = {
    children: null,
    isExpanded: false,
    panelTitle: ''
};

export default CollapsiblePanelComponent;
