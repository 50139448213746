import React, { Component } from 'react';
import swal from 'sweetalert2';
import store from './index'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import ScrollToTop from 'react-scroll-up';
import decode from 'unescape';
// import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import scrollToComponent from 'react-scroll-to-component';
import getDate from './utils/ConvertDate'
import RequestService from './api/RequestService';


export function Comment(props) {

	return(
		<div className="c-topic-comments">
			{
				Object.values(props.data).map((comment, i) => {
					return (
						<div key={i} className="c-topic-comment" style={{ border: '1px solid #ccc', padding: '10px', marginBottom: '20px' }}>
							<p className="c-topic-comment__author" style={{ height: '50px', lineHeight: '50px' }}>
								{/* <img src="" className="c-topic-comment__author-photo" /> */}

								{(() => {
									if (comment.UserID === props.id) {
										return (<span style={{ fontSize:'20px' }}>{comment.FirstName} {comment.LastName} (Fundraiser)</span>);
									}
									else {
										return (
											<span style={{ fontSize:'20px' }}>
												{comment.FirstName} {comment.LastName} {(comment.mainStatus === 1) ? '' : '(Awaiting Approval)'}
											</span>
										);
									}
								})()}
								<span className="pull-right c-discussion__created-at" style={{ fontSize:'20px' }}>{comment.CommentDateTime.slice(0,10)}</span>
							</p>
							<p className="c-topic-comment__comment" style={{ marginBottom: '20px', lineHeight: '20px',fontSize:'20px' }}>{comment.CommentText}</p>

						</div>
					)
				})
			}
		</div>
	);
}



class SelectedDealCF extends Component {
	constructor(props){
		super(props);
		this.state={
			id:props.session.id,
			ProjectInvestmentID:props.location.state.id,
			FundraiserID:0,
			CurrentFunding:"",
			NoOfInvestors:"",
			EntityID:"",
			IsAccreditated: 0,
			Deal:[],
			Notify:[],
			investmentdocs:[],
			ViewNotificationsCF:[],
			pwhash:props.session.marketing,
			commentData:[],
			confidentCheck:0,
			show: false,
			show1: false,
			isLoaded:false,
			errorOnLoad:false
		};
		this.getNotificationcf=this.getNotificationcf.bind(this);
		this.getProject=this.getProject.bind(this);
		this.addToWatchlist=this.addToWatchlist.bind(this);
		this.onInvest=this.onInvest.bind(this);
		this.handleScroll=this.handleScroll.bind(this);
		this.getComments=this.getComments.bind(this);
		this.getCurrentFundingCF=this.getCurrentFundingCF.bind(this);
		this.handleCloseCF = this.handleCloseCF.bind(this);
		this.getFundriserId=this.getFundriserId.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleShow = this.handleShow.bind(this);
		this.checkConfident=this.checkConfident.bind(this);
		this.getNofOfInvestors=this.getNofOfInvestors.bind(this);
		this.getinvestmentdocs=this.getinvestmentdocs.bind(this);
	}


	getinvestmentdocs() {
		fetch(`/api/getregcfdocs/${this.props.location.state.id}/${this.state.id}/${this.state.pwhash}`, {
			method:'get'
		})
			.then((response)=>{
				return response.json()
			})
			.then((data)=>{
				if (data.code === 200) {
					this.setState({
						investmentdocs:data.data,
						isLoaded:true,
						err:{},
						errorOnLoad:false,
					});
				}
			})
			.catch((err)=>{
				this.setState({
					err:err,
					errorOnLoad:true
				})
			})
	}
	handleCloseCF() {
		this.setState({ show1: false });
	}
	handleClose() {
		this.setState({ show: false });
	}

	handleShow() {
		this.setState({ show: true });
	}
	checkConfident() {
		alert('hi');
	}
	getFundriserId(){
		fetch('/api/getFunderId/'+this.state.ProjectInvestmentID+'/'+this.state.id+'/'+this.state.pwhash,{
			method:'get'
		})
			.then((response)=>{
				return response.json()
			})
			.then((data)=>{

				this.setState({
					FundraiserID:data.data[0].UserID,
					isLoaded:true,
					err:{},
					errorOnLoad:false,
				});
			})
			.catch((err)=>{
				this.setState({
					err:err,
					errorOnLoad:true
				})
			})
	}

	getComments(){
		fetch('/api/getComments/'+this.state.ProjectInvestmentID+"/"+this.state.id+'/'+this.state.pwhash,{
			method:'get',

		})
			.then((response)=>{
				return response.json()
			})
			.then((data)=>{
				this.setState({
					commentData:data.data,
					isLoaded:true,
					err:{},
					errorOnLoad:false,
				});
			})
			.catch((err)=>{
				this.setState({
					err:err,
					errorOnLoad:true
				})
			})
	}

	commentSubmit(e) {
		e.preventDefault();

		/* let current = {};
		current[Object.values(this.state.commentData).length] = {
			FirstName: 'krishna',
			CommentText: this.refs.commentbox.value
		} */
		let formBody = [];

		formBody.push("CommentText=" + encodeURIComponent(this.refs.commentbox.value));
		formBody.push("UserId=" + encodeURIComponent(this.state.id));

		formBody = formBody.join("&");

		//do fetch call
		fetch('/api/projectComments/'+this.state.ProjectInvestmentID,{
			method:'post',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/x-www-form-urlencoded'
			},
			body: formBody
		})
			.then((response)=>response.json())
			.then((status)=>{
				if(status.code===200){
					this.setState({
						commentData:status.data
					})
					swal('comment added Successful', '', 'success')
				}
			})
			.catch((err)=>{
				swal('Error to comment', err, 'error');
			})

		this.refs.commentbox.value = '';
		/* this.setState({
			commentData: Object.assign(current, this.state.commentData)
		}) */
	}
	getCurrentFundingCF() {
		fetch(`/api/getonecurrentfundingCF/${this.props.location.state.id}/${this.state.id}/${this.state.pwhash}`, {
			method:'get'
		})
			.then((response)=>{
				return response.json()
			})
			.then((data)=>{
				let Funding = Math.round(data[0].CurrentFunding,0);
				this.setState({
					CurrentFunding:Funding,
					isLoaded:true,
					err:{},
					errorOnLoad:false,
				});
			})
			.catch((err)=>{
				this.setState({
					err:err,
					errorOnLoad:true
				})
			})
	}
	getNofOfInvestors(){
		fetch(`/api/getnoofonvestors/${this.props.location.state.id}/${this.state.id}/${this.state.pwhash}`, {
			method:'get'
		})
			.then((response)=>{
				return response.json()
			})
			.then((data)=>{
				this.setState({
					NoOfInvestors:data.length,
					isLoaded:true,
					err:{},
					errorOnLoad:false,
				});
			})
			.catch((err)=>{
				this.setState({
					err:err,
					errorOnLoad:true
				})
			})
	}


	handleScroll() {
		if (window.innerWidth >= 700) {
			let MainContainer = document.getElementById("mainContainer");
			let element = document.getElementById("fundingstatus");
			if (window.pageYOffset <= 200 && MainContainer.scrollHeight - 2000) {
				element.style.cssText += "position: relative;";
			}
			else {
				element.style.cssText += "position: fixed;";
			}
		}
	}


	async onInvest() {
		let date1 = new Date();
		let date2 = new Date(this.state.Deal.InvestmentStartDate);
		let diff = date1.getTime() - date2.getTime();
		let days = Math.ceil(Math.abs(diff / (1000 * 60 * 60 * 24)));

		// For Aalok - temporary Hardcoded for now.
		/*if (this.state.ProjectInvestmentID == 4) {
			window.location = 'https://info.energyfunders.com/wellhub';
		}
		else */
		if (this.state.CurrentFunding === this.state.Deal.FundingGoalMax) {
			swal("Investment Full", "", "info")
				.then(() => {
					this.props.history.push("/app/Allinvestments")
				})
		}
		else if(days > parseInt(this.state.Deal.FundPeriod)) {
			swal("Funding Closed", "", "info")
				.then(() => {
					this.props.history.push("/app/Allinvestments")
				})
		}
		else if(diff < 0) {
			swal(days+ " Days Left To Start Investment", "", "info")
				.then(() => {
					this.props.history.push("/app/Allinvestments")
				})
		}
		else {
            try {
                const { data } = await RequestService.get(`/api/getaccentity/${1}`);
                if (data.dwollaURL !== '' && data.dwollaStatus === 'verified' && data.FundingSVerified === 1 && data.EducationCheck === 1) {
                    this.props.history.push({
                        pathname: '/app/invest',
                        state: { ProjectType: 'RegCF', ProjectID: this.state.Deal.ProjectID }
                    })
                }
                else if (data.dwollaURL === '' || data.dwollaStatus !== 'verified') {
                    swal({
                        title: 'You need to be a verified investor first.',
                        text: "Do you want to do it now?",
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes'
                    }).then((result) => {
                        if (result.value) {
                            this.props.history.push({
                                pathname: '/app/VerifyDwolla',
                                state: { EntityID:this.state.EntityID }
                            })
                        }
                    })
                }
                else if (data.FundingSVerified !== 1) {
                    swal({
                        title: 'Please create/verify your Funding Source',
                        text: "Do you want to do it now?",
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes'
                    }).then((result) => {
                        if (result.value) {
                            this.props.history.push({
                                pathname: '/app/setupprofile3',
                                state: { EntityID:this.state.EntityID }
                            })
                        }
                    })
                }
                else if (data.EducationCheck !== 1) {
                    swal({
                        title: 'Please Finish up the education Page',
                        text: "Do you want to do it now?",
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes'
                    }).then((result) => {
                        if (result.value) {
                            this.props.history.push("/app/educationpage")
                        }
                    })
                }
            } catch (err) {
                console.log(err);
                this.setState({
                    err:err,
                    errorOnLoad:true
                })
            }
		}
	}


	addToWatchlist() {
		let formBody = [];
		formBody.push("ProjectInvestmentID=" + encodeURIComponent(this.state.ProjectInvestmentID));

		formBody = formBody.join("&");
		fetch(`/api/addtolistCF/${this.state.id}/${this.state.pwhash}`, {
			method: 'post',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/x-www-form-urlencoded'
			},
			body: formBody
		})
			.then((response) => response.json())
			.then((status) => {
				if (status.code === 406) {
					swal("Project Already in your watchlist", " ", "error");
				}
				else if(status.code === 200){
					swal("Project Added to watchlist", " ", "success")
				}
				else {
					swal("Error occured", " ", "error")
				}
			})
			.catch((err) => {
				swal("Error to Add", " ", "error");
			})
	}


	getProject() {
		fetch(`/api/getprojectinvestmentCF/${this.state.ProjectInvestmentID}/${this.state.id}/${this.state.pwhash}`, {
			method:'get'
		})
			.then((response)=>{
				return response.json()
			})
			.then(async(data) => {
				this.setState({
					/*
					Deal: Object.keys(await data[0]).reduce((previous, current) => {
						let fieldList = ['FundingGoalMin', 'FundingGoalMax', 'MinimumInvestment'];
						if (fieldList.includes(current)) {
							previous[current] = data[0][current].toLocaleString('en-us');
						}
						else {
							previous[current] = data[0][current];
						}
						return previous;
					}, {}),
					*/
					Deal: await data[0],
					isLoaded:true,
					err:{},
					errorOnLoad:false,
				});
			})
			.catch((err)=>{
				this.setState({
					err:err,
					errorOnLoad:true
				})

			})
	}
	getNotificationcf(){
		fetch(`/api/getnotificationdealcf/${this.state.ProjectInvestmentID}/${this.state.id}/${this.state.pwhash}`, {
			method:'get'
		})
			.then((response)=>{
				return response.json()
			})
			.then((data)=>{
				this.setState({
					Notify:data,
					isLoaded:true,
					err:{},
					errorOnLoad:false,
				});

			})
			.catch((err)=>{
				this.setState({
					err:err,
					errorOnLoad:true
				})
			})
	}


	onViewCF(id) {
		fetch(`/api/getNotificationregD/${id}/${this.state.id}/${this.props.session.marketing}`, {
			method:'get'
		})
			.then((response)=>{
				return response.json()
			})
			.then((data)=>{
				this.setState({
					ViewNotificationsCF:data.rows[0],
					show1: !this.state.show1,
					isLoaded:true,
					err:{},
					errorOnLoad:false,

				});
			})
			.catch((err)=>{
				this.setState({
					err:err,
					errorOnLoad:true
				})

			})

	}


	async componentDidMount() {
		window.scrollTo(0, 0);
		window.addEventListener('scroll', this.handleScroll);

		await this.setState({
			ProjectInvestmentID:this.props.location.state.id
		});
		this.getCurrentFundingCF();
		this.getNofOfInvestors();
		this.getFundriserId();
		store.subscribe(() => {
			let currentState = store.getState();
			this.setState({
				id: currentState.session.user.id
			});
		});

		this.getProject();
		this.getNotificationcf();
		this.getComments();
		this.getinvestmentdocs();
	}


	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}


	render() {
		return(

			<div className="container body">
				<div className="main_container">
					<div className="right_col" role="main">
						<div className="Mainstep">
							<div id="mainContainer" className="container">
								<div className="js-offering-content-header ">
									<div className="row u-margin-bottom-plus-lg">
										<div className="col-sm-12 col-md-12 col-lg-12 hidden-xs">
											<hr />
										</div>
									</div>
									<br/>
									<div className=" col-xs-12 col-sm-12 col-md-12 col-lg-12">
										<div className="row">
											<div className=" col-sm-5 col-md-4 col-lg-3">
												{/* <p>
                            <img style={{maxHeight:100}} src="/img/some-logo.png" />
                        </p>  */}

												<nav id="fundingstatus" className=" c-offering-detail-nav " style={{top:15}}>
													<div >
														<h3 className="t-uppercase u-margin-top-none">
                                							<b>${this.state.CurrentFunding.toLocaleString('en-us')}</b> raised{/*<br/><b>{this.state.NoOfInvestors}</b> investors*/}
														</h3>
														<h4 className="t-weight-normal u-margin-none"> Funding Goal: { (() => {
															if(this.state.Deal.FundingGoalMax > 0) {
																/*return(this.state.Deal.FundingGoalMin === this.state.Deal.FundingGoalMax ? `$${this.state.Deal.FundingGoalMax.toLocaleString('en-us')}` : `$${this.state.Deal.FundingGoalMin.toLocaleString('en-us')} to $${this.state.Deal.FundingGoalMax.toLocaleString('en-us')}`);*/
																return(this.state.Deal.FundingGoalMin === this.state.Deal.FundingGoalMax ? `$${this.state.Deal.FundingGoalMax.toLocaleString('en-us')}` : `$${this.state.Deal.FundingGoalMin.toLocaleString('en-us')}`);
															}
														} )() }</h4>
														<hr className="u-margin-bottom-plus-md" />
														<p className="u-margin-bottom-none"> </p>
														<p className="t-uppercase t-light-gray">
															<time-remaining end-date="2018-04-03 02:00:18" initial-class="c-snapshot-item" />
														</p>
													</div>
													<ul className="nav list-unstyled">
														<li className="sidenav__item">
															<a onClick={() => scrollToComponent(this.projectSummery, { offset: 0, align: 'top', duration: 1500})} style={{cursor: "pointer"}}>Project Summary</a>
														</li>
														<li className="sidenav__item">
															<a onClick={() => scrollToComponent(this.projectDescription, { offset: 0, align: 'top', duration: 1500})} style={{cursor: "pointer"}}>Project Description</a>
														</li>
														<li className="sidenav__item">
															<a onClick={() => scrollToComponent(this.companyContactDetails, { offset: 0, align: 'top', duration: 1500})} style={{cursor: "pointer"}}>Company Contact Details</a>
														</li>
														<li className="sidenav__item">
															<a onClick={() => scrollToComponent(this.notifications, { offset: 0, align: 'top', duration: 1500})} style={{cursor: "pointer"}}>Notifications</a>
														</li>
													</ul>
													<hr />
													<p>Minimum Investment:
														<b>${this.state.Deal.MinimumInvestment}</b>
													</p>
													<div onClick={this.onInvest} className="c-btn c-btn--orange c-btn__fixed-md" style={{width: '100%'}}> Make an Investment </div>
												</nav>
											</div>
											<div className="col-xs-12 col-sm-7 col-md-8 col-lg-9" style={{padding: '0 25px 0 15px'}}>
												<div id="offering_media" className="c-content-section">
													{/*<h2>{this.state.Deal.InvestmentName}</h2>*/}
													<section ref={(section) => { this.projectSummery = section; }}>
														<div id="project-summary" className=" c-content-section">
															{/*<h3>Project summary</h3>*/}
															{
																(() => {

																	return(
																		<div className="Container" dangerouslySetInnerHTML={{
																			__html:
																				decode(this.state.Deal.ProjectSummary)
																		}}></div>);
																	/* return (
																		<div>
																		{decode(this.state.Deal.DetailedDescription)}
																		</div>
																	); */
																})()

															}
														</div>
													</section>
													<ul className="nav nav-tabs c-offering-content-media-nav" role="tablist">
														<li role="presentation" className="c-offering-content-media-nav__tab active">
															<a className="c-offering-content-media-nav__link" aria-controls="embed_video_desktop" role="tab" data-toggle="tab">
																<b>Video</b>
															</a>
														</li>

													</ul>
													<div className="tab-content c-offering-content-media">
														<div role="tabpanel" className="tab-pane active" id="embed_video_desktop">
															<div className="embed-responsive embed-responsive-16by9">
																{/* <img style={{width:'100%'}} src="/img/no-video.png" className="img-responsive"/> */}
																<iframe src={this.state.Deal.VideoURL} title="Wistia video player" allowTransparency="true" frameBorder={0} scrolling="no" className="wistia_embed" name="wistia_embed" allowFullScreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen width="100%" height="100%" />
																{/* <iframe src="https://www.youtube.com/embed/L7s5w2kmaZY?seo=false&videoFoam=true" title="Wistia video player" allowTransparency="true" frameBorder={0} scrolling="no" className="wistia_embed" name="wistia_embed" allowFullScreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen width="100%" height="100%" /> */}
																{/* <iframe src="https://fast.wistia.net/embed/iframe/jzkvbls3eo?seo=false&videoFoam=true" title="Wistia video player" allowTransparency="true" frameBorder={0} scrolling="no" className="wistia_embed" name="wistia_embed" allowFullScreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen width="100%" height="100%" /> */}
																{/* <iframe className="embed-responsive-item" webkitallowfullscreen mozallowfullscreen allowFullScreen src="/img/novideo.jpg" />  */}
															</div>
														</div>

													</div>
												</div>
												<ul className="text-right list-inline u-margin-top-plus-md">
													{(this.state.Deal.FacebookLink !== '')
														? <li>
															<a href={this.state.Deal.FacebookLink}
															   className="c-social-circle-link c-social-circle-link__medium-gray c-social-circle-link__sm"
															   target="_blank"
															   rel="noopener noreferrer"
															>
																<i className="fa fa-facebook fa-2x">
																	<span className="sr-only">Facebook</span>
																</i>
															</a>
														</li>
														: ''
													}

													{
														(() => {
															if (this.state.Deal.TwitterLink !== '') {
																return (
																	<li>
																		<a href={this.state.Deal.TwitterLink}
																		   className="c-social-circle-link c-social-circle-link__medium-gray c-social-circle-link__sm"
																		   target="_blank"
																		   rel="noopener noreferrer"
																		>
																			<i className="fa fa-twitter fa-2x">
																				<span className="sr-only">Twitter</span>
																			</i>
																		</a>
																	</li>
																);
															}
														})()
													}
													{
														(() => {
															if (this.state.Deal.LinkedInLink !== '') {
																return (
																	<li>
																		<a href={this.state.Deal.LinkedInLink}
																		   className="c-social-circle-link c-social-circle-link__medium-gray c-social-circle-link__sm"
																		   target="_blank"
																		   rel="noopener noreferrer"
																		>
																			<i className="fa fa-linkedin-square fa-2x">
																				<span className="sr-only">LinkedIn</span>
																			</i>
																		</a>
																	</li>
																);
															}
														})()
													}
													{
														(() => {
															if (this.state.Deal.WebsiteLink !== '') {
																return(
																	<li>
																		<a href={this.state.Deal.WebsiteLink}
																		   className="c-social-circle-link c-social-circle-link__medium-gray c-social-circle-link__sm"
																		   target="_blank"
																		   rel="noopener noreferrer"
																		>
																			<i className="fa fa-link fa-2x">
																				<span className="sr-only">Website</span>
																			</i>
																		</a>
																	</li>
																);
															}
														})()
													}
												</ul>
											</div>
										</div>
										<div className="hidden-xs col-sm-5 col-md-4 col-lg-3"> </div>
										<div className="col-xs-12 col-sm-7 col-md-8 col-lg-9">
											<div className="well">
												<h3>Investment Terms</h3>
												<p><strong>Legal Issuer Name:&nbsp;</strong>{this.state.Deal.LegalIssuerName}</p>
												<p><strong>Minimum Funding Goal:&nbsp;</strong>${ (() => { if(this.state.Deal.FundingGoalMin > 0) return(this.state.Deal.FundingGoalMin.toLocaleString('en-us')); })() }</p>
												<p><strong>Maximum Funding Goal:&nbsp;</strong>${ (() => { if(this.state.Deal.FundingGoalMax > 0) return(this.state.Deal.FundingGoalMax.toLocaleString('en-us')); })() }</p>
												<p><strong>Project Publish Date:&nbsp;</strong>{getDate.getDate(this.state.Deal.ProjectPublishDate)}</p>
												<p><strong>Investment Start Date:&nbsp;</strong>{getDate.getDate(this.state.Deal.InvestmentStartDate)}</p>
											</div>
										</div>
									</div>
								</div>
								<div className="modal fade" id="showShortUrl" tabIndex={-1} role="dialog" aria-labelledby="ShowShortUrl">
									<div className="modal-dialog modal-sm" role="document">
										<div className="modal-content">
											<div className="modal-body">
												<p>Copy the link, then click OK.</p>
												<form>
													<div className="form-group">
														<input className="js-auto-select-me form-control" type="text" defaultValue="https://mv1.vc/apex" /> </div>
												</form>
											</div>
											<div className="modal-footer">
												<a className="t-neutral" href="javascript:void(0);" data-dismiss="modal">Cancel</a>
												<a className="u-margin-left-plus-md t-secondary" href="javascript:void(0);" data-dismiss="modal">OK</a>
											</div>
										</div>
									</div>
								</div>
								<div>
									<div className="row">
										<hr />
										<div className="hidden-xs col-sm-5 col-md-4 col-lg-3">

										</div>
										<div className="col-xs-12 col-sm-7 col-md-8 col-lg-9" style={{padding: '0 35px 0 25px'}}>
											<div className="js-offering-content-wrapper">
												<section ref={(section) => { this.projectDescription = section; }}>
													<div id="project-description" className="c-content-section" style={{'margin-top': '20px'}}>
														<h3>Project Description</h3>
														{
															(() => {

																return(
																	<div className="Container" dangerouslySetInnerHTML={{
																		__html:
																			decode(this.state.Deal.FormCfullDescription)
																	}}></div>);
																/* return (
																	<div>
																	{decode(this.state.Deal.DetailedDescription)}
																	</div>
																); */
															})()
														}
													</div>
												</section>
												<section ref={(section) => { this.companyContactDetails = section; }}>
													<div id="companycontactdetails" className=" c-content-section">
														<h3>company contact details</h3>
														<p>{this.state.Deal.CompanyContactDetails}</p>
													</div>
												</section>
												<section ref={(section) => { this.companyDocuments = section; }}>
													<div id="offering_investment_docs" className=" c-content-section">
														<h3>Investment Documents</h3>
														<ul className="list-group c-offering-investment-docs">

															{
																(() =>{
																	if(this.state.investmentdocs.length>0) {
																		return(
																			this.state.investmentdocs.map((note,i)=>(
																				<li className="list-group-item c-offering-investment-docs__item">
																					<a target="_blank" href={note.Document} rel="noopener noreferrer">
																						<i className="fa fa-lg fa-fw fa-file-text" />{note.Document.slice(note.Document.lastIndexOf("/")+1,note.Document.length)}</a>
																				</li>
																			))
																		);
																	}
																	else{
																		return(<div>No Documents</div>)
																	}
																})()

															}
														</ul>
													</div>
												</section>
												<section ref={(section) => { this.notifications = section; }}>
													<div id="comments" className="c-content-section">
														<div id="endnotes" className=" u-xs-overflow-hidden  c-content-section">
															<h3>Notifications</h3>

															<table className="table table-striped jambo_table bulk_action" id="sample" style={{marginTop:5}}>
																<thead>
																<tr>
																	<th className="" >Date</th>
																	<th className="">Subject</th>

																</tr>
																</thead>
																<tbody>
																{
																	(() =>{
																		if(this.state.Notify) {
																			return(
																				this.state.Notify.map((note,i)=>(
																					<tr key={i} className="even pointer">
																						<td className=" ">{getDate.getDate(note.CreatedDate)}</td>
																						<td className=" "><a href="javascript:void(0);" onClick={(e)=>this.onViewCF(note.NotificationID)}>{note.Subject}</a></td>


																					</tr>

																				))
																			);
																		}
																	})()

																}
																</tbody>
															</table>
														</div>
													</div>
												</section>
												<Modal show={this.state.show1} onHide={this.handleCloseCF}>
													<Modal.Header closeButton>
														<Modal.Title></Modal.Title>
													</Modal.Header>
													<Modal.Body>
														<div className="container" >
															<div className="row">
																<div className="">
																	<div className="panel-body">
																		<div className="row">
																			<div className='col-md-2 col-sm-2 col-xs-2'></div>
																			<div className="col-lg-8 col-md-12 col-sm-12 col-xs-12" style={{minHeight:"250px"}}>
																				<div style={{border: '1px solid gray'}}>
																					<div className="nav_menu " style={{backgroundColor: '#5bb462', color:'#fff'}}>
																						<div className="" style={{float: 'left'}}>{this.state.ViewNotificationsCF.ProjectName}</div>
																						<div className="" style={{float: 'right'}}>{getDate.getDate(this.state.ViewNotificationsCF.CreatedDate)}</div>
																					</div>
																					<div className="portlet-body warning_detail">
																						<div className="title">
																							<strong>Subject:</strong>
																							<span >{this.state.ViewNotificationsCF.Subject}</span>
																						</div>
																						<div className="content">
																							{
																								(() => {

																									return(
																										<div className="Container" dangerouslySetInnerHTML={{
																											__html:
																												decode(this.state.ViewNotificationsCF.NotificationSmallText)
																										}}></div>);
																									/* return (
																										<div>
																										{decode(this.state.ViewNotificationsCF.NotificationSmallText)}
																										</div>
																									); */
																								})()

																							}
																							{
																								(() => {

																									return(
																										<div className="Container" dangerouslySetInnerHTML={{
																											__html:
																												decode(this.state.ViewNotificationsCF.NotificationText)
																										}}></div>);
																									/* return (
																										<div>
																										{decode(this.state.Deal.DetailedDescription)}
																										</div>
																									); */
																								})()

																							}

																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</Modal.Body>
													<Modal.Footer>

													</Modal.Footer>
												</Modal>
											</div>
										</div>
									</div>
									<div className="row">

										<div className="hidden-xs col-sm-5 col-md-4 col-lg-3"></div>
										<div className="col-xs-12 col-sm-7 col-md-8 col-lg-9" style={{padding: '0 35px 40px 25px'}}>
											<div id="discussion-accordion" aria-multiselectable="true" className="c-discussion  panel-group" token="m78ouGzUfMkWG5NcNAT3sisB7sMYZLXZe3PA5aes">
												<div className="panel panel-default">
													<div role="tab" id="discussion_category_166" className="panel-heading c-discussion-header">
														<h4 className="panel-title  c-discussion__name">
															Comments
														</h4>
													</div>
													<div id="discussion_collapse_166" role="tabpanel" aria-labelledby="discussion_category_166" className="panel-collapse collapse in">
														<div className="c-topic panel-body">

															<div style={{marginBottom:20}}>
																<div className="c-topic-comments">
																	<div className="c-topic-comment" style={{ border: '1px solid #ccc', padding: '10px' }}>

																		<textarea name="" id="" ref='commentbox' style={{ marginBottom:10,height:75,width: '100%' }}></textarea>
																		<button className="btn-primary btn" type="button" onClick={(e) => this.commentSubmit(e)}>Send</button>

																	</div>
																</div>
															</div>
															{
																(() => {
																	if(Object.values(this.state.commentData).length) {
																		return(<div><Comment data={this.state.commentData} id={this.state.FundraiserID} props={this.props} /></div>);
																	}
																})()
															}
														</div>
													</div>
												</div>

											</div>
										</div>
									</div>

								</div>

							</div>
						</div>
					</div>
				</div>
				{/* <ScrollToTop showUnder={160}>
  <span><img style={{maxHeight:50}} src="/img/up.png"/></span>
</ScrollToTop> */}
				<div>
					<Modal show={this.state.show}>
						<Modal.Header>
							<Modal.Title>Confidentiality Agreement</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div>
								<strong>This agreement appears only once per project. Please read carefully and agree to continue.</strong><br /><br />
								<div>
									The following page and files accessed on such page constitute Confidential Information, and any unauthorized disclosure or use of such Confidential Information may cause irreparable harm the parties which own such Confidential Information. By accessing this page and/or the files available for download, you agree to treat all documentary, image, data, and other Confidential Information confidentially and not to, and to cause any spouse or co-investor who accesses such information through or from you, not to disclose to others; use for any purpose other than to decide whether to make an investment in the project set forth on the page; use for any purpose that is detrimental or adverse to EnergyFunders, the operator, or other parties involved in the project set forth on the page; or otherwise disclose any such information.
									<br /><br />
									You further agree not make any copies of the Confidential Information other than for your personal files relating to your investment in the project listed on the page; directly or indirectly, attempt to reverse-engineer; decipher; use such Confidential Information in any way related to other projects, prospects, or properties; or take other similar actions with respect to the Confidential Information.
									<br /><br />
									The above restrictions on Confidential Information shall not apply to Confidential Information that: (i) is or becomes publicly known through no wrongful act of you; or (ii) is, at the time of disclosure already known to you without restriction on disclosure.
									<br /><br />
									The Confidential Information is typically received from project parties or other sources and is provided "AS IS" and without any express or implied representations or warranties regarding accuracy, operability, use or fitness for a particular purpose.
								</div>
								<hr/>
								<div>
									<form>
										Please Type Your Name Here:&nbsp;&nbsp;
										<input type="text" ref="signedName" style={{width: 200}}/>
										<br /><br />
										<input type="submit" onClick={this.onSubmit} defaultValue="I Agree" />
										<br /><br />
										<input type="button" onClick={() => {
											this.props.history.push({
												pathname: '/app/Allinvestments'
											})
										}
										} defaultValue="Go Back" />
									</form>
								</div>
							</div>
						</Modal.Body>
						{/* <Modal.Footer>
            <Button onClick={this.handleClose}>Close</Button>
          </Modal.Footer> */}
					</Modal>
				</div>
			</div>
		);
	}
}

const { object, bool } = PropTypes;

SelectedDealCF.propTypes = {
	session: object.isRequired,
	authenticated: bool.isRequired,
	checked: bool.isRequired
};

const mapState = ({ session }) => ({
	session: session.user,
	checked: session.checked,
	authenticated: session.authenticated
});

export default withRouter(connect(mapState)(SelectedDealCF));

