import React from "react";
import ReactPlayer from "react-player";
import { sanitizeExcerpt } from "../../../utils/data/wordpressPages";

export default function SeeYourFund(props) {
	const { seeYourFundCollection,userInvestedProjectsIDs } = props;
	const userInvestedProjects =seeYourFundCollection
	?.filter((item) =>
		userInvestedProjectsIDs?.some((elem) =>
			item?.ProjectedIDs.toString()?.includes(elem)
		)
	)
	return (

		<div>
				{ userInvestedProjects?.length > 0 &&
			<><h1>See Your Fund For Yourself</h1>
			<ul className="list-unstyled well-video-wrapper" style={{ display: 'flow-root' }}>
				{userInvestedProjects?.map((item) => {
						return (
							<li>
							{item?.videoURL?.toLowerCase() ==="false" ? <img src= {item?.imgURL}/>  :<div className="player-wrapper">
									<ReactPlayer
										className="reactplayer"
										url={item?.videoURL}
										playing={true}
										playsinline={true}
										controls={true}
										muted
									/>
								</div>}	
								<h5>{item.title}</h5>
								<div
									dangerouslySetInnerHTML={{
										__html: sanitizeExcerpt(
											item?.content,
											true
										),
									}}
								/>
							</li>
						);
					})}
			</ul></>
		}
		</div>
	);
}
