import sweetAlert from 'sweetalert2';

class NotificationService {
    static success(message, timer = null, showConfirmButton = false) {
        return NotificationService.notify(message, 'success', timer, showConfirmButton);
    }

    static error(message, timer = null) {
        return NotificationService.notify(message, 'error', timer, true);
    }

    static warning(message, timer = null) {
        return NotificationService.notify(message, 'warning', timer, true);
    }

    static info(message, timer = null) {
        return NotificationService.notify(message, 'info', timer);
    }

    static notify(title, type, timer, showConfirmButton = false) {
        const _timer = timer || 2000;

        return sweetAlert({
            type,
            title,
            timer: _timer,
            showConfirmButton,
        });
    }

    static custom(config) {
        return sweetAlert(config);
    }
}

export default NotificationService;
