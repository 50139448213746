import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux';
import {Col, Collapse, Row} from 'react-bootstrap';
import {ContainerComponent} from "../ContainerComponent/ContainerComponent";
import {withRouter} from 'react-router-dom';
import {getUUID} from "../../../utils/General"
import * as ExternalImageActions from './actions/actions';
import {bindActionCreators} from 'redux'
import * as clipboard from 'clipboard-polyfill';
import NotificationService from "../../../utils/NotificationService";
import Icon from "../IconComponent/IconComponent";
import SelectedFilesTableElement from "./components/SelectedFilesTableElement";
import Pagination from 'react-js-pagination';
import SingleLineInputComponent from '../InputComponents/SingleLineInputComponent/SingleLineInputComponent'


class ExternalImageContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isSearch: false,
            imageList: [],
            searchTerm: '',
            selectedFileList: [],

            // PAGINATION
            itemsPerPage: 10,
            selectedPage: 1,
            totalItems: 0,
        };

        this.refExternalFileInput = React.createRef();
    }



    componentDidMount() {
        const {getFilesFromDb} = this.props;
        const {itemsPerPage, selectedPage} = this.state;

        getFilesFromDb(selectedPage, itemsPerPage);
    };



    componentDidUpdate(prevProps) {
        const prevPropsUrls = JSON.stringify(prevProps.urlList.files);
        const currentPropsUrls = JSON.stringify(this.props.urlList.files);

        const _elements = prevProps.urlList.elements;
        const elements = this.props.urlList.elements;

        const _page = prevProps.urlList.page;
        const page = this.props.urlList.page;

        if (currentPropsUrls !== prevPropsUrls)
            this.setImageUrls();

        if (_elements !== elements)
            this.setState({totalItems: this.props.urlList.elements});

        if (_page !== page)
            this.setState({selectedPage: this.props.urlList.page});
    }



    componentWillUnmount() {
        const {imageRequestSuccess: propsReset} = this.props;
        propsReset({files: []});
    }



    setImageUrls = () => {
        const urlList = this.props.urlList.files
            .map(({mediaId, location}) => ({mediaId, location}));

        this.setState({
            imageList: urlList,
            selectedFileList: [],
        });
    };



    onSaveFilesClickHandler = () => {
        const {uploadExternalFilesToS3, user} = this.props;
        const mappedFields = [];

        const formData = new FormData();
        this.state.selectedFileList.forEach(file => {
            mappedFields.push({name: file.name.split(' ').join('_'), desc: file.description});
            formData.append('files', file)
        });

        formData.append('descriptions', JSON.stringify(mappedFields));
        uploadExternalFilesToS3(formData, user, this.state.itemsPerPage);
    };



    onImageInputChange = (event) => {
        const _fileList = [...this.state.selectedFileList];

        const {name, files} = event.target;
        const fileList = Object.keys(files)
            .map(key => files[key])
            .map(file => {
                file.description = '';
                return file;
            });

        this.setState({[name]: _fileList.concat(fileList)},
            () => this.refExternalFileInput.current.value = null);
    };



    onDescriptionUpdated = (file, newDescription) => {
        const files = [...this.state.selectedFileList];
        const fileIndex = this.state.selectedFileList.indexOf(file);

        files[fileIndex].description = newDescription;

        this.setState({selectedFileList: files});
    };

    removeFile = (file) => {
        const fileList = [...this.state.selectedFileList];
        const fileIndex = this.state.selectedFileList.indexOf(file);

        fileList.splice(fileIndex, 1);

        this.setState({selectedFileList: fileList});
    };

    removeStoreUrl = (mediaId) => {
        const {deleteFileFromDB} = this.props;
        const {itemsPerPage} = this.state;

        deleteFileFromDB(mediaId, itemsPerPage);
    };

    copyToClipboard = (url) => {
        clipboard.writeText(url.location)
            .then(() => NotificationService.success("Copied to clipboard"))
            .catch(() => NotificationService.error("Error copying to clipboard"));
    };

    onCloseModal = () => {
        this.props.closeModal();
        this.setState({
            selectedFileList: [],
        });
    };

    onPageRequest = (nextPage) => {
        const {getFilesFromDb, performSearch} = this.props;
        const {itemsPerPage, isSearch, searchTerm} = this.state;

        if (isSearch) {
            performSearch(searchTerm, itemsPerPage, nextPage);
        } else {
            getFilesFromDb(nextPage, itemsPerPage);
        }
    };

    onSearchRequest = (page = 1) => {
        const {performSearch} = this.props;
        const {searchTerm, itemsPerPage} = this.state;

        performSearch(searchTerm, itemsPerPage, page);
        this.setState({isSearch: true});
    };

    onInputChange = (event) => {
        const {name, value} = event.target;

        this.setState({[name]: value});
    };

    onClearSearch = () => {
        this.setState({isSearch: false},
            () => this.onPageRequest(1));
    };

    // RENDER MANAGER
    renderUrls = () => {
        const {imageList} = this.state;
        return imageList.map((image) => (
            <Col xs={6} sm={6} md={4} lg={3}
                 style={{marginTop: 15, textAlign: 'center'}}
                 key={getUUID()}>
                <div>
                    <img src={image.location}
                         key={getUUID()}
                         style={{width: '150px', height: '150px'}}
                         className="img-thumbnail"/>
                </div>
                <div className="btn-group btn-group-sm"
                     style={{padding: 5}}
                     role="group">
                    <button className="btn btn-sm btn-danger"
                            onClick={() => this.removeStoreUrl(image.mediaId)}
                            type="button">
                        Delete
                    </button>
                    <button className="btn btn-sm btn-primary"
                            onClick={() => this.copyToClipboard(image)}
                            type="button">
                        Copy URL
                    </button>
                </div>
            </Col>
        ));
    };

    renderSelectedImages = () => {
        const {selectedFileList} = this.state;

        if (!selectedFileList || !selectedFileList.length) return null;

        return (
            <div className="col-xs-12">
                <div className="table-responsive">
                    <table className="table table-hover table-striped">
                        <thead>
                        <tr>
                            <th>File name</th>
                            <th>File Description</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.renderSelectedFilesTableItems()}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    };

    renderSelectedFilesTableItems = () => {
        const {selectedFileList} = this.state;
        return selectedFileList.map(file => (
            <SelectedFilesTableElement key={file.key}
                                       onRemoveFile={this.removeFile}
                                       onDescriptionUpdate={this.onDescriptionUpdated}
                                       file={file}/>
        ))
    };

    renderSearchBar = () => {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-xs-2 center-media-action-buttons">
                        <label htmlFor="pxr_imageExternalUrl"
                               className="btn btn-default capitalize-text no-margin">
                            <Icon iconName="plus"/> add files
                        </label>
                        <input id="pxr_imageExternalUrl" ref={this.refExternalFileInput}
                               name="selectedFileList" type="file" style={{display: "none"}}
                               accept="image/*" multiple className="btn btn-default btn-sm"
                               onChange={this.onImageInputChange}/>
                    </div>
                    <div className="col-xs-8">
                        <SingleLineInputComponent elementValue={this.state.searchTerm}
                                                  inputName="searchTerm"
                                                  maxInputLength={null}
                                                  placeholder="Search Term"
                                                  onInputUpdate={this.onInputChange}/>
                    </div>
                    <div className="col-xs-2 center-media-action-buttons">
                        <button type="button"
                                onClick={() => this.onSearchRequest()}
                                className="btn btn-primary no-margin capitalize-text">
                            <Icon iconName="search" extraClasses="white-text"/> Search
                        </button>
                        {this.state.isSearch &&
                        <button type="button"
                                onClick={this.onClearSearch}
                                className="btn btn-default no-margin capitalize-text">
                            <Icon iconName="times"/> Clear
                        </button>}
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const canRenderSelectedFiles = (this.state.selectedFileList.length > 0);

        return (
            <Collapse in={this.props.openModal}
                      onHide={this.onCloseModal}>
                <div>
                    <div className="modal-body">
                        <ContainerComponent>
                            <Col xs={12} sm={12} md={2} lg={2}/>
                            <Col xs={12} sm={12} md={8} lg={8}>
                                {this.renderSearchBar()}
                                <Row>
                                    {canRenderSelectedFiles &&
                                    <h3>Selected Files</h3>}
                                    {this.renderSelectedImages()}
                                </Row>
                                {canRenderSelectedFiles &&
                                <Row style={{textAlign: 'center'}}>
                                    <button className="btn btn-primary"
                                            disabled={!canRenderSelectedFiles}
                                            onClick={this.onSaveFilesClickHandler}
                                            type="button">
                                        Upload Images
                                    </button>
                                </Row>}
                                {this.state.imageList.length > 0 &&
                                <Row style={{maxHeight: '500px', overflow: 'auto'}}>
                                    <h3>Stored URLs</h3>
                                    {this.renderUrls()}
                                </Row>}
                                <Row>
                                    <Pagination firstPageText={<i className='glyphicon glyphicon-chevron-left'/>}
                                                lastPageText={<i className='glyphicon glyphicon-chevron-right'/>}
                                                prevPageText={<i className='glyphicon glyphicon-menu-left'/>}
                                                nextPageText={<i className='glyphicon glyphicon-menu-right'/>}
                                                activePage={this.state.selectedPage}
                                                itemsCountPerPage={this.state.itemsPerPage}
                                                totalItemsCount={this.state.totalItems}
                                                onChange={this.onPageRequest}/>
                                </Row>
                            </Col>
                        </ContainerComponent>
                    </div>
                    <div className="modal-footer">
                        <ContainerComponent>
                            <Row>
                                <Col xs={12} sm={12} md={2} lg={2}/>
                                <Col xs={12} sm={12} md={8} lg={8}>
                                    <button className="btn btn-danger pull-right"
                                            onClick={this.onCloseModal}
                                            type="button">
                                        Close Image Manager
                                    </button>
                                </Col>
                            </Row>
                        </ContainerComponent>
                    </div>
                </div>
            </Collapse>
        );
    };
}

ExternalImageContainer.propTypes = {
    closeModal: PropTypes.func.isRequired,
    onFlightRequest: PropTypes.number,
    openModal: PropTypes.bool.isRequired,
    uploadExternalFilesToS3: PropTypes.func.isRequired,
    urlList: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    getFilesFromDb: PropTypes.func.isRequired,
    imageRequestSuccess: PropTypes.func.isRequired,
};

ExternalImageContainer.defaultProps = {
    closeModal: () => null
};

const mapDispatchToProps = (dispatch) => {
    return {...bindActionCreators(ExternalImageActions, dispatch)};
};

const mapStateToProps = (state) => {
    return {
        ...state.externalImages,
        user: state.session.user,
    };
};

const connectedComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(ExternalImageContainer);

export default withRouter(connectedComponent);

