import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    imageLocation: PropTypes.string.isRequired,
};

const defaultProps = {
    imageLocation: '',
};

const ViewerImageSectionComponent = (props) => {
    const { imageLocation } = props;
    const isPermittedUrl = imageLocation.split('/')
        .pop() !== 'No file selected';
    const showImage = (imageLocation && imageLocation.length !== 0);

    return (
        <tr style={{ border: 'none' }}>
            {showImage && isPermittedUrl && (
                <td colSpan={2}>
                    <section style={{
                        backgroundColor: '#857e88',
                        width: '100%',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                    }}
                    >
                        <img
                            alt=""
                            src={imageLocation}
                            style={{
                                width: '100%',
                                height: 'auto',
                            }}
                        />
                    </section>
                </td>
            )}
        </tr>
    );
};

ViewerImageSectionComponent.propTypes = propTypes;
ViewerImageSectionComponent.defaultProps = defaultProps;

export default ViewerImageSectionComponent;
