import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as Action from './actions/actions';
import { connect } from 'react-redux';
import ReactLoaderSpinner from 'react-loader-spinner';
import swal from 'sweetalert2';

import './styles/ProjectDetailsContainerComponent.styles.css';
import './styles/HighlightComponent.styles.css';
import './styles/ProjectDetailCardComponent.styles.css';
import './styles/ProjectDetailsTableComponent.styles.css';
import SignCAComponent from './components/SignCAComponent/SignCAComponent';
import RequestService from '../../../api/RequestService';
import { PROJECT_REG_CF, PROJECT_REG_D } from '../../../utils/ApplicationConstants';
import ProjectDetailsComponent from '../ProjectDetailsComponent/ProjectDetailsComponent';
import { minutesToMilliSec } from '../../../utils/General';
import HelloSignW9 from "../../../utils/HelloSignW9";
import moment from 'moment';


export class ProjectDetailsContainerComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            projectType: '',
            investmentId: 0,
            investorCASign: '',
            isLoggedInUser: true,
        };

        this.showGoBackButton = true;
        this.reloadCommentsInterval = null;
    }



    componentDidMount() {
        const { getProjectDetails, verifySignedCA, location, getAllProjectComments } = this.props;
        const { id: investmentId, projectType } = location.state;

        getProjectDetails(investmentId, projectType);

        if (projectType === PROJECT_REG_D) {
            verifySignedCA(investmentId);
        }

        if (projectType === PROJECT_REG_CF) {
            getAllProjectComments(investmentId, true);
        }

        this.setState({
            investmentId,
            projectType,
        });

		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});

        window.addEventListener('scroll', this.showFloatingInvestButtonOnScroll);

        this.setCommentListAutoReload();
    }



    componentDidUpdate(prevProps) {
        const selectUpdate = JSON.stringify(prevProps.selectUpdate);
        const _selectUpdate = JSON.stringify(this.props.selectUpdate);

        if (selectUpdate !== _selectUpdate) {
            this.setState({
                selectUpdate: this.props.selectUpdate,
                isUpdateModalOpen: true,
            });
        }
    }



    componentWillUnmount() {
        const { setDefaultSignCAData } = this.props;

        setDefaultSignCAData();
        window.removeEventListener('scroll', this.showFloatingInvestButtonOnScroll);

		clearInterval(this.reloadCommentsInterval);
        this.reloadCommentsInterval = null;
    }



    goToPreviousPage = () => {
        const { goBack } = this.props.history;

        goBack();
    };

    onInputChange = (event) => {
        const { name, value } = event.target;

        this.setState({ [name]: value });
    };

    performInvestorCASign = () => {
        const { performCASign } = this.props;
        const { investmentId, investorCASign } = this.state;

        performCASign(investmentId, investorCASign);
    };



    onInvest = () => {
        const { projectType } = this.state;
        if (projectType === PROJECT_REG_D) {
            this.performRegDProjectInvestment();
        } else if (projectType === PROJECT_REG_CF) {
            this.performRegCfProjectInvestment();
        }
    };



    /*
     * This method must be re-written to make it much more easy to
     * understand and modify.
     */
    performRegDProjectInvestment = async () => {
        const _fundingGoalMax = this.strToInt(this.props.projectDetails.fundingGoalMax);
        const _currentFunding = this.strToInt(this.props.projectDetails.currentFunding);

		const momentStartDate = moment(this.props.projectDetails.investmentStartDate, 'YYYY-MM-DD HH:mm:ss');
		let momentEndDate = momentStartDate.add(this.props.projectDetails.fundingPeriod, 'days').endOf('day');

        if ((_currentFunding >= _fundingGoalMax)) {
            swal('Investment Full', '', 'info');
        }
        else if (momentEndDate.diff(moment()) <= 0) {
            swal('Funding Closed', '', 'info');
        }
        else if (moment(this.props.projectDetails.investmentStartDate).diff(moment(), 'seconds') > 0) {
            swal('Investment starts ' + moment(this.props.projectDetails.investmentStartDate).fromNow(), '', 'info');
        }
        else {
            try {
                const { data } = await RequestService.get('/api/checkInfoBeforeInvesting');

                if (data.dwollaURL !== '' && data.dwollaStatus === 'verified' && data.FundingSourceVerified === 1 && data.status === 2) {
                    // && data.data[0].w8n9formVerify === 1
                    this.props.history.push({
                        pathname: '/app/invest',
                        state: {
                            ProjectType: 'RegD',
                            ProjectID: this.state.investmentId,
							IsCashCall: this.props.projectDetails.cashCall
                        }
                    });
                }
                else if (data.dwollaURL === '' && data.dwollaStatus === '') {
                    swal({
                        title: 'You need to be a verified investor first.',
                        text: 'Do you want to do it now?',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#5bb462',
                        cancelButtonColor: '#bababa',
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'No'
                    })
                        .then((result) => {
                            if (result.value) {
                                this.props.history.push({
                                    pathname: '/app/VerifyDwolla'
                                });
                            }
                        });
                }
                else if (data.dwollaStatus !== 'verified') {
                    swal('Please contact Investor Relations for more details.', 'You are unable to invest at this time.', 'error');
                }
                else if (data.FundingSourceVerified !== 1) {
                    swal({
                        title: 'Please create/verify your Funding Source',
                        text: 'Do you want to do it now?',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes'
                    })
                        .then((result) => {
                            if (result.value) {
                                this.props.history.push({
                                    pathname: '/app/setupprofile3'
                                });
                            }
                        });
                }
                // else if (data.data[0].w8n9formVerify === 0) {
                //     swal({
                //         title: 'You must complete your personal W9.',
                //         text: "Do you want to do it now?",
                //         type: 'warning',
                //         showCancelButton: true,
                //         confirmButtonColor: '#3085d6',
                //         cancelButtonColor: '#d33',
                //         confirmButtonText: 'Yes'
                //     }).then((result) => {
                //         if (result.value) {
                //             HelloSignW9({call: 'signW9Personal', EntityID: data.data[0].EntityID});
                //         }
                //     });
                // }
                else if (data.days > 0) {
                    swal('Please wait ' + data.days + ' more days to invest, or contact us.', '', 'info');
                }
            }
            catch (err) {
                console.log(err);
            }
        }
    };



    /*
     * This method must be re-written to make it much more easy to
     * understand and modify
     */
    performRegCfProjectInvestment = async () => {
        let date1 = new Date();
        let date2 = new Date(this.props.projectDetails.investmentStartDate);
        let diff = date1.getTime() - date2.getTime();
        let days = Math.ceil(Math.abs(diff / (1000 * 60 * 60 * 24)));

        const _fundingGoalMax = this.strToInt(this.props.projectDetails.fundingGoalMax);
        const _currentFunding = this.strToInt(this.props.projectDetails.currentFunding);

        if (_currentFunding === _fundingGoalMax) {
            swal('Investment Full', '', 'info')
                .then((value) => {
                    this.props.history.push('/app/Allinvestments');
                });
        } else if (days > parseInt(this.props.projectDetails.fundingPeriod)) {
            swal('Funding Closed', '', 'info')
                .then((value) => {
                    this.props.history.push('/app/Allinvestments');
                });
        } else if (diff < 0) {
            swal(days + ' Days Left To Start Investment', '', 'info')
                .then((value) => {
                    this.props.history.push('/app/Allinvestments');
                });
        } else {
            try {
                const { data } = await RequestService.get(`/api/getaccentity/${1}`);

                if (data.dwollaURL !== '' && data.dwollaStatus === 'verified' && data.FundingSVerified === 1 && data.EducationCheck === 1) {
                    this.props.history.push({
                        pathname: '/app/invest',
                        state: {
                            ProjectType: 'RegCF',
                            ProjectID: this.state.investmentId
                        }
                    });
                } else if (data.dwollaURL === '' || data.dwollaStatus !== 'verified') {
                    swal({
                        title: 'You need to be a verified investor first.',
                        text: 'Do you want to do it now?',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes'
                    })
                        .then((result) => {
                            if (result.value) {
                                this.props.history.push({
                                    pathname: '/app/VerifyDwolla'
                                });
                            }
                        });
                } else if (data.FundingSVerified !== 1) {
                    swal({
                        title: 'Please create/verify your Funding Source',
                        text: 'Do you want to do it now?',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes'
                    })
                        .then((result) => {
                            if (result.value) {
                                this.props.history.push({
                                    pathname: '/app/setupprofile3'
                                });
                            }
                        });
                } else if (data.EducationCheck !== 1) {
                    swal({
                        title: 'Please Finish up the education Page',
                        text: 'Do you want to do it now?',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes'
                    })
                        .then((result) => {
                            if (result.value) {
                                this.props.history.push('/app/educationpage');
                            }
                        });
                }
            } catch (err) {
                console.log(err);
                this.setState({
                    err: err,
                    errorOnLoad: true,
                });
            }
        }
    };

    strToInt = (str) => {
        if (typeof str === 'string') {
            return parseInt(str.replace(/,/g, ''));
        }

        return str;
    };

    showFloatingInvestButtonOnScroll = () => {
        const highlightsTitle = document.getElementById('projectDetailsHighlights');

        if (highlightsTitle) {
            const { top } = highlightsTitle.getBoundingClientRect();

            let element = document.getElementById('floatingInvestButton');

            if (top <= 100) {
                element.style.cssText += 'position: fixed; display: block';
            } else {
                element.style.cssText += 'position: relative; display: none;';
            }
        }
    };

    isNotSignedUser = () => {
        const { projectType } = this.state;
        const { isSigned } = this.props;

        if (projectType === PROJECT_REG_CF) {
            return false;
        }

        return (isSigned === '_');
    };

    hideTheProjectDetailsResult = () => {
        return false;
    };

    closeUpdateModal = () => {
        this.setState({
            isUpdateModalOpen: false,
            selectUpdate: {}
        });
    };

    getUpdateById = (updateId) => {
        const { getUpdateById } = this.props;

        getUpdateById(updateId);
    };

    onGetCommentReplies = (commentId, commentList, position) => {
        const { investmentId, isLoggedInUser } = this.state;
        const { getCommentReplies } = this.props;

        getCommentReplies(
            investmentId, commentId, commentList,
            position, isLoggedInUser,
        );
    };

    onAddNewComment = (commentText, parentCommentId, cleanUp) => {
        const { addNewPost } = this.props;
        const { investmentId, isLoggedInUser } = this.state;

        addNewPost(
            commentText, investmentId,
            parentCommentId, isLoggedInUser, cleanUp,
        );
    };

    setCommentListAutoReload = () => {
        this.reloadCommentsInterval = setInterval(
            () => this.onRefreshCommentList(),
            minutesToMilliSec(5),
        );
    };



    onRefreshCommentList = () => {
        const { location, getAllProjectComments } = this.props;
        const { id: investmentId } = location.state;
        const { isLoggedInUser } = this.state;

        getAllProjectComments(investmentId, isLoggedInUser);
    };





    render = () => {
        const { isSigned, regCfCommentList } = this.props;
        const { projectType, isLoggedInUser } = this.state;
        const loadingScreen = (
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh'
            }}>
                <ReactLoaderSpinner
					type="ThreeDots"
					color="#5bb462"
					height="100"
					width="100"
				/>
            </div>
        );
        const signPopUp = (
            <SignCAComponent goBack={this.goToPreviousPage}
                             onInputChange={this.onInputChange}
                             performCASign={this.performInvestorCASign}/>
        );

        if (this.hideTheProjectDetailsResult()) {
            return (
                <div style={{
                    display: 'flex',
                    height: '100vh',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}>
                    <h2>The project cannot be accessed</h2>
                    <h3>
                        <button className="btn btn-link btn-lg upper-case-text"
                                onClick={this.onInvest}
                                type="button">
                            You must log in
                        </button>
                    </h3>
                </div>
            );
        } else if (this.isNotSignedUser()) {
            return loadingScreen;
        } else if ((isSigned === 0) && (projectType === PROJECT_REG_D)) {
            return signPopUp;
        }

        return (
            <ProjectDetailsComponent projectDetails={this.props.projectDetails}
                                     projectHighlights={this.props.projectHighlights}
                                     projectTabs={this.props.projectTabs}
                                     projectCardClick={this.onInvest}
                                     showGoBackButton={this.showGoBackButton}
                                     projectNotifications={this.props.projectNotifications}
                                     projectFiles={this.props.projectFiles}
                                     getUpdateById={this.getUpdateById}
                                     isUpdateModalOpen={this.state.isUpdateModalOpen}
                                     closeUpdateModal={this.closeUpdateModal}
                                     selectUpdate={this.state.selectUpdate}
                                     goToPreviousPage={this.goToPreviousPage}
                                     regCfCommentList={regCfCommentList}
                                     projectType={projectType}
                                     onSaveNewComment={this.onAddNewComment}
                                     isLoggedInUser={isLoggedInUser}
                                     onRefreshCommentList={this.onRefreshCommentList}
                                     getCommentReplies={this.onGetCommentReplies}/>
        );
    };
}

ProjectDetailsContainerComponent.propTypes = {
    getProjectDetails: PropTypes.func.isRequired,
    getCommentReplies: PropTypes.func.isRequired,
    addNewPost: PropTypes.func.isRequired,
    getAllProjectComments: PropTypes.func.isRequired,
    history: PropTypes.any,
    isSigned: PropTypes.any,
    location: PropTypes.object.isRequired,
    performCASign: PropTypes.any,
    projectDetails: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    projectHighlights: PropTypes.array.isRequired,
    regCfCommentList: PropTypes.array.isRequired,
    projectTabs: PropTypes.array.isRequired,
};

ProjectDetailsContainerComponent.defaultProps = {
    getProjectDetails: () => null,
    getCommentReplies: () => null,
    getAllProjectComments: () => null,
    addNewPost: () => null,
    location: {},
    projectDetails: {},
    user: {},
    projectHighlights: [],
    projectTabs: [],
    regCfCommentList: [],
};

const mapStateToProp = (_state) => ({
    ..._state.projectDetails,
    user: _state.session.user
});
const mapDispatchToProp = (_dispatch) => ({ ...bindActionCreators(Action, _dispatch) });

const connectedComponent = connect(
    mapStateToProp,
    mapDispatchToProp
)(ProjectDetailsContainerComponent);

export default withRouter(connectedComponent);
