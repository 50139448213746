import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as AllInvestmentActions from "./actions/actions";
import RequestService from "../../../api/RequestService";
import store from "../../../index";
import { PROJECT_REG_D } from "../../../utils/ApplicationConstants";

import MembershipTableComponent from "../MembershipTableComponent/MembershipTableComponent";
class InvestorMembershipsContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			activeOfferingsChecked: true,
			pastOfferingsChecked: true,
			selectedProjectType: PROJECT_REG_D,

			id: props.user.id,

			memberships: [],
		};
	}
	componentDidMount() {
		store.subscribe(() => {
			let currentState = store.getState();
			this.setState({
				id: currentState.session.user.id,
			});
		});

		this.getInvestormemberships();
	}

	async getInvestormemberships() {
		const url = "/api/investor/memberships/all";
		try {
			const { data } = await RequestService.get(url);
			this.setState({
				...this.state,
				memberships: data,
			});
		} catch (error) {
			console.log("memberships", error);
		}
	}

	renderRegDProjects(activeOffering, pastOfferings) {
		const { isAccreditedUser, wasAccreditedUser } = this.props;
		const {
			itemsPerPage,

			role,
		} = this.props;
		const { memberships } = this.state;
		console.log("memberships", memberships);
		if (
			this.state.selectedProjectType === PROJECT_REG_D &&
			(isAccreditedUser || wasAccreditedUser)
		) {
			return (
				<div>
					<MembershipTableComponent
						memberships={memberships}
						itemsPerPage={itemsPerPage}
						role={role}
					/>
				</div>
			);
		}
		return null;
	}

	render() {
		const { pastOfferingsChecked, activeOfferingsChecked } = this.state;

		return (
			<div className="container filter-wrap">
				{this.renderRegDProjects(
					activeOfferingsChecked,
					pastOfferingsChecked
				)}
			</div>
		);
	}
}
InvestorMembershipsContainer.propTypes = {
	isAccreditedUser: PropTypes.bool.isRequired,

	user: PropTypes.any,
	investorId: PropTypes.number.isRequired,
};
InvestorMembershipsContainer.defaultProps = {};
const mapDispatchToProps = (dispatch) => {
	const allInvestmentActions = bindActionCreators(
		AllInvestmentActions,
		dispatch
	);
	return { ...allInvestmentActions };
};
const mapStateToProps = (_state) => {
	return {
		..._state.investorDashboard,
		investorId: _state.session.user.id,
		..._state.allInvestments,
		user: _state.session.user,
		location: _state.location,
	};
};
const connectedComponent = connect(
	mapStateToProps,
	mapDispatchToProps
)(InvestorMembershipsContainer);
export default withRouter(connectedComponent);
