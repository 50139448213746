import PropTypes from 'prop-types';
import React from 'react';
import { getHash, getUUID } from '../../../../../utils/General';
import MultilineInputComponent from '../../../InputComponents/MultilineInputComponent/MultilineInputComponent';
import Icon from '../../../IconComponent/IconComponent';
import CommentElement from './CommentElement';

class CommentsTabComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            commentText: '',
        };
    }

    onInputChange = (event) => {
        const { name, value } = event.target;

        this.setState({ [name]: value });
    };

    isDisabled = () => {
        const { commentText } = this.state;

        return (!commentText || commentText === '');
    };

    onCancel = () => {
        this.setState({ commentText: '' });
    };

    onSaveComment = (parentCommentId = 0) => {
        const { onSaveNewComment } = this.props;
        const { commentText } = this.state;

        onSaveNewComment(commentText, parentCommentId, this.onCancel);
    };

    // renderManager
    renderComments = () => {
        const {
            regCfCommentList, getCommentReplies, onSaveNewComment, isLoggedInUser,
        } = this.props;

        return regCfCommentList
            .map((comment, position) => (
                <CommentElement
                    key={getHash(comment)}
                    comment={comment}
                    getCommentReplies={getCommentReplies}
                    position={position}
                    onReply={onSaveNewComment}
                    isLoggedInUser={isLoggedInUser}
                />
            ));
    };

    render = () => {
        const { isLoggedInUser, onRefreshCommentList } = this.props;

        return (
            <div className="container">
                {isLoggedInUser && (
                    <React.Fragment>
                        <div className="col-xs-12">
                            <MultilineInputComponent
                                rows={4}
                                inputName="commentText"
                                inputTitle="Add a comment"
                                placeholder="Please add your comment here"
                                elementValue={this.state.commentText}
                                onInputUpdate={this.onInputChange}
                                maxInputLength={null}
                                isRequired
                            />
                        </div>
                        <div className="col-xs-12">
                            <div className="pull-right">
                                <button
                                    className="btn btn-default capitalize-text"
                                    disabled={this.isDisabled()}
                                    onClick={this.onCancel}
                                    type="button"
                                >
                                    <Icon iconName="times"/>
                                    {' '}
                                    cancel
                                </button>
                                <button
                                    className="btn btn-primary capitalize-text"
                                    onClick={() => this.onSaveComment()}
                                    disabled={this.isDisabled()}
                                    type="button"
                                >
                                    <Icon iconName="paper-plane" extraClasses="white-text"/>
                                    {' '}
                                    send
                                </button>
                            </div>
                        </div>
                    </React.Fragment>
                )}
                <fieldset className="col-xs-12">
                    <legend>
                        <h3 className="heading-text">
                            All comments
                            {' '}
                            <button
                                className="btn btn-default btn-sm"
                                onClick={onRefreshCommentList}
                                type="button"
                            >
                                <Icon iconName="refresh"/>
                            </button>
                        </h3>
                    </legend>
                    <div className="list-group">
                        {this.renderComments()}
                    </div>
                </fieldset>
            </div>
        );
    };
}

CommentsTabComponent.propTypes = {
    getCommentReplies: PropTypes.func,
    onRefreshCommentList: PropTypes.func,
    onSaveNewComment: PropTypes.func,
    regCfCommentList: PropTypes.array.isRequired,
    isLoggedInUser: PropTypes.bool.isRequired,
    tabId: PropTypes.any,
    tabName: PropTypes.string,
    tabOrder: PropTypes.number,
};

CommentsTabComponent.defaultProps = {
    getCommentReplies: () => null,
    onSaveNewComment: () => null,
    onRefreshCommentList: () => null,
    regCfCommentList: [],
    tabId: getUUID(),
    tabName: 'Comments',
    tabOrder: 2000,
    isLoggedInUser: true,
};

export default CommentsTabComponent;
