
import React from 'react';
import { APP_VERSION, currentYear } from '../../../../../utils/ApplicationConstants';
import Icon from '../../../IconComponent/IconComponent';
import './FooterCopyright.css';


const FooterCopyright = (props) => {
    /*
     * This gets the final value correctly, because FooterCopyright is a FUNCTION and not a React COMPONENT.
     * A Component would only get the 'props' ONCE, and then not get the updated 'props' from the parent.
     * A function DOES get the updated 'props' from the parent.
     */

    // console.log('props = ', props.IsAccredited);

	// Enable Talk.to if IS/WAS accredited only.
	if (props.IsAccredited > 0 && window.Tawk_API && typeof window.Tawk_API.showWidget === 'function') {
		setTimeout(function() {
			window.Tawk_API.showWidget();
		}, 1000);
	}



    function renderAccreditedFooterCopyright() {
        return (
            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <div className="col-sm-offset-4 col-sm-2">
                    <a href="https://www.linkedin.com/company/energyfunders/" target="_blank" rel="noopener noreferrer">
                        <Icon iconName="linkedin" extraClasses="footer-copyright--link-img" />
                    </a>
                </div>
                <div className="col-sm-2">
                    <a href="https://twitter.com/EnergyFunders" target="_blank" rel="noopener noreferrer">
                        <Icon iconName="twitter" extraClasses="footer-copyright--link-img" />
                    </a>
                </div>
                <div className="col-sm-2">
                    <a href="https://www.facebook.com/EnergyFunders/" target="_blank" rel="noopener noreferrer">
                        <Icon iconName="facebook" extraClasses="footer-copyright--link-img" />
                    </a>
                </div>
                <div className="col-sm-2">
                    <a href="https://www.youtube.com/channel/UCfQV-LmXBPgy8eyN4hwOsfQ" target="_blank" rel="noopener noreferrer">
                        <Icon iconName="youtube" extraClasses="footer-copyright--link-img" />
                    </a>
                </div>
            </div>
        );
    }



    function renderNonAccreditedFooterCopyright() {
        return null;
    }



    return (
        <div className="row footer-copyright--background">
            <div className="col-xs-12 col-sm-12 col-md-8 col-lg-9">
                <div className="col-sm-3">
                    <a href="https://www.energyfunders.com/" target="_blank" rel="noopener noreferrer">
                        <figure className="footer-copyright--figure">
                            <img className="footer-copyright--figure-image"
                                 src="/img/ef-footer-logo.png"
                                 alt="EF_Email_Logo"
							/>
                        </figure>
                    </a>
                </div>
                <div className="col-sm-9 footer-copyright--text">
               
                </div>
            </div>

			{props.IsAccredited > 0 ? renderAccreditedFooterCopyright() : renderNonAccreditedFooterCopyright()}
            <div className="col-md-12 footer-terms text-center text-lg-start mt-5">
							<a href="https://www.energyfunders.com/disclosure"  target="_blank" rel="noopener noreferrer">Disclosure</a>

							<span>|</span>

							<a href="https://www.energyfunders.com/privacy-policy"  target="_blank" rel="noopener noreferrer">Privacy Policy</a>

							<span>|</span>

							<a href="https://www.energyfunders.com/terms-of-service"  target="_blank" rel="noopener noreferrer">Terms of Service</a>

							<span>|</span>

							<a href="https://www.energyfunders.com/electronic-consent"  target="_blank" rel="noopener noreferrer">Electronic Consent</a>

							<span>|</span>

							<a href="https://www.energyfunders.com/investor-agreement"  target="_blank" rel="noopener noreferrer">
								{" "}
								Investor Agreement
							</a>

							<span>|</span>

							<a href="https://www.energyfunders.com/esg-policy"  target="_blank" rel="noopener noreferrer">ESG Policy</a>

							<span>|</span>

							<a href="https://www.energyfunders.com/privacy-notice"  target="_blank" rel="noopener noreferrer"> Privacy Notice</a>
						</div>
                        <div className="col-md-12 footer-copyright--text"><p>
						© 2013-{currentYear()} EnergyFunders, LLC. &nbsp; All
						rights reserved. &nbsp; {APP_VERSION}
					</p>
				</div>
        </div>
    );
};


export default FooterCopyright;
