import {HTTP_ERR_BAD_REQUEST} from "../../../../utils/ApplicationConstants";
import NotificationService from "../../../../utils/NotificationService";
import RequestService from "../../../../api/RequestService";

const SIX_SECONDS = 6000;

export const RROC_END = 'RROC_END';
export const RROC_START = 'RROC_START';
export const RROC_ALL_PROJECTS = 'RROC_ALL_PROJECTS';
export const RROC_ALL_RETURNS_OF_CAPITAL = 'RROC_ALL_RETURNS_OF_CAPITAL';
export const RROC_VERIFY_RETURNS_OF_CAPITAL = 'RROC_VERIFY_RETURNS_OF_CAPITAL';

function startRequest() {
    return {type: RROC_START};
}

function endRequest() {
    return {type: RROC_END};
}

function allProjectsRequestSuccess(data) {
    return {type: RROC_ALL_PROJECTS, data};
}

function allReturnsOfCapitalRequestSuccess(data) {
    return {type: RROC_ALL_PROJECTS, data};
}

function verifyRoCRequestSuccess(data) {
    return {type: RROC_VERIFY_RETURNS_OF_CAPITAL, data};
}

export function getAllProjects(userData) {
    return async (dispatch) => {
        const {id, marketing} = userData;
        const URL = `/api/getProjectList`;

        dispatch(startRequest());
        const {data, status} = await RequestService.get(URL);
        const {code, reg_cf, reg_d} = data;

        if (code >= HTTP_ERR_BAD_REQUEST || status >= HTTP_ERR_BAD_REQUEST) {
            NotificationService.error('Error Extracting the list of projects', SIX_SECONDS);
            dispatch(endRequest());
            return;
        }

        dispatch(allProjectsRequestSuccess({
            regDProjects: reg_d,
            regCfProjects: reg_cf,
        }));
    };
}

export function storeReturnedCapital(reqBody, projectInvestmentId, reset = () => null) {
    return async (dispatch) => {
        const URL = `/api/project/${projectInvestmentId}/return-of-capital`;

        dispatch(startRequest());
        const {status} = await RequestService.post({
            url: URL,
            data: reqBody
        });

        if (status >= HTTP_ERR_BAD_REQUEST) {
            NotificationService.error("Error Storing the Returned Capital in the DB", SIX_SECONDS);
            dispatch(endRequest());
            return;
        }

        dispatch(endRequest());
        NotificationService.success("Returned Capital Saved Correctly", SIX_SECONDS);
        reset();
    }
}

export function getAllRecordedReturnsOfCapital() {
    return async (dispatch) => {
        const URL = `/api/project/all/returns-of-capital`;

        dispatch(startRequest());
        const {data, status} = await RequestService.get(URL);

        if (status >= HTTP_ERR_BAD_REQUEST) {
            NotificationService.error("Error Extracting The Stored Returns Of Capital");
            dispatch(endRequest());
            return;
        }

        dispatch(allReturnsOfCapitalRequestSuccess(data));
    };
}


export function verifyReturnsOfCapital(returnId, projectType) {
    return async (dispatch) => {
        const URL = `/api/project/${projectType}/distribute/roc/${returnId}/verify-return`;

        dispatch(startRequest());
        const {data, status} = await RequestService.get(URL);

        if (status >= HTTP_ERR_BAD_REQUEST) {
            NotificationService.error("Error Extracting the Return List Verification", SIX_SECONDS);
            dispatch(verifyRoCRequestSuccess({investorReturn: []}));
            return;
        }

        const {investorReturn, internalNotes} = data;
        dispatch(verifyRoCRequestSuccess({investorReturn, internalNotes}));
    };
}

export function performDistribution(returnId, projectType) {
    return async (dispatch) => {
        const url = `/api/project/${projectType}/distribute/roc/${returnId}/dis-type/1`;

        dispatch(startRequest());
        const {status} = await RequestService.post({url, data: {}});

        if (status >= HTTP_ERR_BAD_REQUEST) {
            NotificationService.error("Error When Performing The Distribution of the Returned Capital: ReturnCapitalId", SIX_SECONDS);
            dispatch(endRequest());
            return;
        }

        dispatch(endRequest());
        dispatch(getAllRecordedReturnsOfCapital());
        NotificationService.success("Distribution finalized correctly", SIX_SECONDS);
    };
}

export function getDistributionResult(returnId) {
    return async (dispatch) => {
        const URL = `/api/project/distributed/roc/${returnId}`;

        dispatch(startRequest());
        const {data, status} = await RequestService.get(URL);

        if (status >= HTTP_ERR_BAD_REQUEST) {
            NotificationService.error("Error Extracting the Distribution return for this project", SIX_SECONDS);
            dispatch(verifyRoCRequestSuccess({investorReturn: []}));
            return;
        }

        dispatch(verifyRoCRequestSuccess({investorReturn: data.investorRoC, internalNotes: data.internalNotes}));
    }
}
