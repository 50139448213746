/**
 * Created by goutham on 9/13/2017.
 */
import React, {Component} from 'react';
import swal from 'sweetalert2';
import store from '../../index'
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import Pagination from 'react-js-pagination';
import getDate from '../../utils/ConvertDate'

class Fundraiserdealcard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Projects_RegD: [],
            Funders: [],
            totalFunders: [],
            allFunders: [],
            pageNumber: 1,
            totalCount: 0,
            perPage: 10,
            activePage: 1,

            Projects_RegCF: [],
            FundersCF: [],
            totalFundersCF: [],
            allFundersCF: [],
            pageNumberCF: 1,
            totalCountCF: 0,
            perPageCF: 10,
            activePageCF: 1,
            id: props.session.id,
            isLoaded: false,
            errorOnLoad: false
        };

        this.getSavedProjects = this.getSavedProjects.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.fundProjects = this.fundProjects.bind(this);
        this.fundProjectsCF = this.fundProjectsCF.bind(this);
        this.onAddProject = this.onAddProject.bind(this);
        this.onDeleteD = this.onDeleteD.bind(this);
        this.onDeleteCF = this.onDeleteCF.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handlePageChangeCF = this.handlePageChangeCF.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleSearchChangeCF = this.handleSearchChangeCF.bind(this);
    }

    componentWillMount() {
        window.scrollTo(0, 0);
    }

    fundProjects() {
        fetch('/api/funderProjects/' + this.state.id + '/' + this.props.session.marketing, {
            method: 'get'
        })
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                this.setState({
                    totalFunders: data,
                    allFunders: data,
                    Funders: data.slice(0, 10),
                    pageNumber: 1,
                    totalCount: data.length,
                    perPage: 10,
                    activePage: 1,
                    isLoaded: true,
                    err: {},
                    errorOnLoad: false,
                });
            })
            .catch((err) => {
                this.setState({
                    err: err,
                    errorOnLoad: true
                })
            })
    }

    fundProjectsCF() {
        fetch('/api/funderProjectsCF/' + this.state.id + '/' + this.props.session.marketing, {
            method: 'get'
        })
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                this.setState({
                    totalFundersCF: data,
                    allFundersCF: data,
                    FundersCF: data.slice(0, 10),
                    pageNumberCF: 1,
                    totalCountCF: data.length,
                    perPageCF: 10,
                    activePageCF: 1,
                    isLoaded: true,
                    err: {},
                    errorOnLoad: false,
                });
            })
            .catch((err) => {
                this.setState({
                    err: err,
                    errorOnLoad: true
                })
            })
    }

    onDeleteD(url) {
        swal({
            title: 'Are you sure you want to delete this project?',
            text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        })
            .then((result) => {
                if (result.value) {
                    fetch('/api/MyProjdeleteregd/' + url + '/' + this.state.id + '/' + this.props.session.marketing, {
                        method: 'post',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    })
                        .then((response) => response.json())
                        .then((status) => {
                            if (status.code === 200) {
                                swal(" Deleted", " ", "success")
                                    .then((value) => {
                                        this.fundProjects();
                                    });
                            } else if (status.code === 400) {
                                alert("Error occured");
                            }
                        })
                        .catch((err) => {
                            alert("Error: " + JSON.stringify(err))
                        })
                }
            })
    }


    onDeleteCF(url) {
        swal({
            title: 'Are you sure you want to delete this project?',
            text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        })
            .then((result) => {
                if (result.value) {
                    fetch('/api/MyProjdeleteregcf/' + url + '/' + this.state.id + '/' + this.props.session.marketing, {
                        method: 'post',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    })
                        .then((response) => response.json())
                        .then((status) => {
                            if (status.code === 200) {
                                swal(" Deleted", "", "success")
                                    .then((value) => {
                                        this.fundProjectsCF();
                                    });
                            } else if (status.code === 400) {
                                alert("Error occured");
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                            alert("Error: " + JSON.stringify(err))
                        })
                }
            })
    }

    getDat(toSearch, objects) {
        return new Promise((resolve, reject) => {
            let dat = objects.filter(function (object) {
                let searchStatus = 0;
                for (const key in object) {
                    if (object.hasOwnProperty(key)) {
                        var patt = new RegExp(toSearch);
                        if (patt.test(object[key])) {
                            searchStatus = 1;
                        }
                    }
                }
                if (searchStatus) {
                    return true;
                }
            });
            resolve(dat);
        });
    }

    handlePageChange(pageNumber) {
        var prev = ((pageNumber) - 1) * 10;
        var next = prev + 10;
        this.setState({
            activePage: pageNumber,
            Funders: this.state.allFunders.slice(prev, next)
        });
    }

    handlePageChangeCF(pageNumber) {
        var prev = ((pageNumber) - 1) * 10;
        var next = prev + 10;
        this.setState({
            activePageCF: pageNumber,
            FundersCF: this.state.allFundersCF.slice(prev, next)
        });
    }

    handleSearchChange(e) {
        let toSearch = e.target.value;
        if (toSearch == "" || toSearch == undefined) {
            this.setState({
                allFunders: this.state.totalFunders,
                Funders: this.state.totalFunders.slice(0, 10),
                pageNumber: 1,
                totalCount: this.state.totalFunders.length,
                perPage: 10,
                activePage: 1
            })
        } else {
            let objects = this.state.totalFunders;
            this.getDat(toSearch, objects).then((dat) => {
                    this.setState({
                        allFunders: dat,
                        Funders: dat.slice(0, 10),
                        pageNumber: 1,
                        totalCount: dat.length,
                        perPage: 10,
                        activePage: 1
                    })
                }
            );
        }

    }

    handleSearchChangeCF(e) {
        let toSearch = e.target.value;
        if (toSearch == "" || toSearch == undefined) {
            this.setState({
                allFundersCF: this.state.totalFundersCF,
                FundersCF: this.state.totalFundersCF.slice(0, 10),
                pageNumberCF: 1,
                totalCountCF: this.state.totalFundersCF.length,
                perPageCF: 10,
                activePageCF: 1
            })
        } else {
            let objects = this.state.totalFundersCF;
            this.getDat(toSearch, objects).then((dat) => {
                    this.setState({
                        allFundersCF: dat,
                        FundersCF: dat.slice(0, 10),
                        pageNumberCF: 1,
                        totalCountCF: dat.length,
                        perPageCF: 10,
                        activePageCF: 1
                    })
                }
            );
        }

    }

    onAddProject() {
        fetch('/api/rolecheck/' + this.state.id + '/' + this.props.session.marketing, {
            method: 'get'
        })
            .then((response) => {
                return response.json()
            })
            .then((status) => {
                if (status.code === 200) {
                    swal({
                        title: "Please Select Project Type:",
                        showCancelButton: true,
                        confirmButtonText: "Regulation D",
                        cancelButtonText: "Regulation CF",
                        closeOnConfirm: false,
                        closeOnCancel: false,
                        showLoaderOnConfirm: true
                    }).then((isConfirm) => {
                        if (isConfirm.value === true) {
                            this.props.history.push('/app/AdminaddProject_D')
                        } else {
                            this.props.history.push('/app/AdminaddProject_CF')
                        }
                    })


                } else if (status.code === 402) {
                    swal("Access Denied", " ", "error")
                } else if (status.code === 500) {
                    swal("Access Denied", " ", "error")
                }
            })
            .catch((err) => {
                alert("Error: " + JSON.stringify(err))
            })
    }

    onSubmit(id) {
        console.log(id)
        this.props.history.push({
            pathname: '/app/AdminaddProject_D',
            state: {ProjectID: id}
        })
    }

    onSubmitCF(id) {
        this.props.history.push({
            pathname: '/app/AdminaddProject_CF',
            state: {ProjectID: id}
        })
    }

    getSavedProjects() {
        fetch('/api/getsavedprojects/' + this.state.id + '/' + this.props.session.marketing, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        })
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                var RegD_Projects = data.reg_d;
                var RegCF_Projects = data.reg_cf;
                if (data) {
                    if (data.code === 200) {
                        this.setState({
                            Projects_RegD: RegD_Projects,
                            Projects_RegCF: RegCF_Projects,
                            isLoaded: true,
                            err: {},
                            errorOnLoad: false,

                            totalProjects: RegD_Projects,
                            allProjects: RegD_Projects,
                            Projects_RegD: RegD_Projects.slice(0, 10),
                            pageNumber: 1,
                            totalCount: RegD_Projects.length,
                            perPage: 10,
                            activePage: 1,

                            totalProjects_CF: RegCF_Projects,
                            allProjects_CF: RegCF_Projects,
                            Projects_RegCF: RegCF_Projects.slice(0, 10),
                            pageNumber_CF: 1,
                            totalCount_CF: RegCF_Projects.length,
                            perPage_CF: 10,
                            activePage_CF: 1,
                            isLoaded: true,
                            err: {},
                        });
                    } else if (data.code === 404) {
                        this.setState({
                            Projects_RegD: [],
                            Projects_RegCF: [],
                            isLoaded: true,
                            err: {},
                            errorOnLoad: false,
                        });

                    }
                } else {
                    this.setState({
                        Projects_RegD: [],
                        Projects_RegCF: [],
                        isLoaded: true,
                        err: {},
                        errorOnLoad: false,
                    });
                }


            })
            .catch((err) => {
                alert("Error getting saved projects" + JSON.stringify(err))
            })
    }


    async componentWillMount() {
        window.scrollTo(0, 0);
        await this.getSavedProjects();
    }

    componentDidMount() {
        this.fundProjects();
        this.fundProjectsCF();
    }


    render() {

        return (
            <div className="container body">
                <div className="main_container">
                    {/* page content */}
                    <div className="right_col" role="main">
                        <div className="col-md-3 col-sm-3 col-xs-3">
                            <button onClick={this.onAddProject} className="fa fa-plus-circle btn btn-primary space"
                                    style={{width: 200, 'margin-left': '-15px'}}>&nbsp;&nbsp;Submit a New
                                Project&nbsp;&nbsp;</button>
                        </div>
                        <div className="col-md-9 col-sm-9 col-xs-9">
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="x_panel">
                                    <div className="x_title">
                                        <h4 className='up'>My projects </h4>

                                        <div className="clearfix"/>
                                    </div>
                                    <div className="row">
                                        <div>
                                            <ul className="nav nav-pills nav-justified"
                                                style={{padding: '0 10px', marginBottom: 20}}>

                                                <li className="active"><a data-toggle="pill" href="#home">EnergyFunders
                                                    Black (Reg D)</a></li>
                                                <li><a data-toggle="pill" href="#menu1">EnergyFunders Marketplace (Reg
                                                    CF)</a></li>
                                            </ul>
                                            <div className="tab-content">
                                                <div id="home" className="tab-pane fade in active">
                                                    <div className="x_content">
                                                        <div className="col-md-8" style={{marginBottom: 20}}></div>
                                                        <div className="col-md-4" style={{marginBottom: 20}}>
                                                            <input className="pull-right" type="text"
                                                                   onChange={this.handleSearchChange}
                                                                   placeholder="Search here"/>
                                                        </div>
                                                        <div className="clearfix"/>
                                                        <div className="table-responsive">
                                                            <table className="table table-striped jambo_table ">
                                                                <thead className="thead-inverse">
                                                                <tr className="headings">
                                                                    <th className="column-title">Project Name</th>
                                                                    <th className="column-title">Date Created</th>
                                                                    <th className="column-title">Status</th>
                                                                    <th className="column-title">Actions</th>
                                                                </tr>
                                                                </thead>
                                                                {(() => {
                                                                    switch (this.state.Funders.length < 1) {
                                                                        case false:
                                                                            return (
                                                                                <tbody>
                                                                                {
                                                                                    this.state.Funders.map((funder) => (
                                                                                        <tr key={funder.ProjectID}
                                                                                            className="even pointer">
                                                                                            <td className=" ">{funder.ProjectName}</td>
                                                                                            <td className=" ">{getDate.getDate(funder.CreatedDate)}</td>
                                                                                            {(() => {
                                                                                                switch (funder.ProjectStatus) {
                                                                                                    case 0  :
                                                                                                        switch (funder.saved) {
                                                                                                            case 0  :
                                                                                                                return (
                                                                                                                    <td>
                                                                                                                        <button
                                                                                                                            id="submit"
                                                                                                                            type="submit"
                                                                                                                            className="btn btn-danger center">Pending
                                                                                                                        </button>
                                                                                                                    </td>);
                                                                                                            case 1  :
                                                                                                                return (
                                                                                                                    <td>
                                                                                                                        <button
                                                                                                                            id="submit"
                                                                                                                            type="submit"
                                                                                                                            className="btn btn-success center">Saved
                                                                                                                        </button>
                                                                                                                    </td>);
                                                                                                            default :
                                                                                                                return (
                                                                                                                    <td>
                                                                                                                        <button
                                                                                                                            id="submit"
                                                                                                                            type="submit"
                                                                                                                            className="btn btn-info center">default
                                                                                                                        </button>
                                                                                                                    </td>);
                                                                                                        }
                                                                                                    case 1  :
                                                                                                        return (<td>
                                                                                                            <button
                                                                                                                id="submit"
                                                                                                                type="submit"
                                                                                                                className="btn btn-success center">Approved
                                                                                                            </button>
                                                                                                        </td>);
                                                                                                    default :
                                                                                                        return (<td>
                                                                                                            <button
                                                                                                                id="submit"
                                                                                                                type="submit"
                                                                                                                className="btn btn-info center">default
                                                                                                            </button>
                                                                                                        </td>);
                                                                                                }
                                                                                            })()}


                                                                                            {(() => {
                                                                                                switch (funder.saved) {
                                                                                                    case 1  :
                                                                                                        return (<td>
                                                                                                            <button
                                                                                                                id="submit"
                                                                                                                type="submit"
                                                                                                                onClick={() => this.onSubmit(funder.ProjectID)}
                                                                                                                className="btn btn-primary center">Submit
                                                                                                                Project
                                                                                                            </button>
                                                                                                        </td>);
                                                                                                    case 0  :
                                                                                                        return (<td>
                                                                                                            <button
                                                                                                                id="submit"
                                                                                                                type="submit"
                                                                                                                onClick={() => this.onDeleteD(funder.ProjectID)}
                                                                                                                className="btn btn-primary center">Remove
                                                                                                            </button>
                                                                                                        </td>);
                                                                                                    default :
                                                                                                        return (<td>
                                                                                                            <button
                                                                                                                id="submit"
                                                                                                                type="submit"
                                                                                                                className="btn btn-info center">default
                                                                                                            </button>
                                                                                                        </td>);
                                                                                                }
                                                                                            })()}
                                                                                        </tr>
                                                                                    ))
                                                                                }
                                                                                </tbody>
                                                                            );
                                                                        case true:
                                                                            return (
                                                                                <tbody>
                                                                                <tr className="even pointer">
                                                                                    <td colSpan="6"
                                                                                        className="text-center">Nothing
                                                                                        to show
                                                                                    </td>
                                                                                </tr>
                                                                                </tbody>
                                                                            );
                                                                    }
                                                                })()}
                                                            </table>
                                                        </div>
                                                        <div className="pull-right">
                                                            <Pagination
                                                                firstPageText={<i
                                                                    className='glyphicon glyphicon-chevron-left'/>}
                                                                lastPageText={<i
                                                                    className='glyphicon glyphicon-chevron-right'/>}
                                                                prevPageText={<i
                                                                    className='glyphicon glyphicon-menu-left'/>}
                                                                nextPageText={<i
                                                                    className='glyphicon glyphicon-menu-right'/>}
                                                                activePage={this.state.activePage}
                                                                itemsCountPerPage={this.state.perPage}
                                                                totalItemsCount={this.state.totalCount}
                                                                onChange={this.handlePageChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="menu1" className="tab-pane fade">
                                                    <div className="x_content">
                                                        <div className="col-md-8" style={{marginBottom: 20}}></div>
                                                        <div className="col-md-4" style={{marginBottom: 20}}>
                                                            <input className="pull-right" type="text"
                                                                   onChange={this.handleSearchChangeCF}
                                                                   placeholder="Search here"/>
                                                        </div>
                                                        <div className="clearfix"/>
                                                        <div className="table-responsive">
                                                            <table className="table table-striped jambo_table ">
                                                                <thead className="thead-inverse">
                                                                <tr className="headings">
                                                                    <th className="column-title">Project Name</th>
                                                                    <th className="column-title">Date Created</th>
                                                                    <th className="column-title">Status</th>
                                                                    <th className="column-title">Actions</th>
                                                                </tr>
                                                                </thead>
                                                                {(() => {
                                                                    switch (this.state.FundersCF.length < 1) {
                                                                        case false:
                                                                            return (
                                                                                <tbody>
                                                                                {
                                                                                    this.state.FundersCF.map((funder) => (
                                                                                        <tr key={funder.ProjectID}
                                                                                            className="even pointer">
                                                                                            <td className=" ">{funder.ProjectName}</td>
                                                                                            <td className=" ">{getDate.getDate(funder.CreatedDate)}</td>
                                                                                            {(() => {
                                                                                                switch (funder.ProjectStatus) {
                                                                                                    case 0  :
                                                                                                        switch (funder.saved) {
                                                                                                            case 0  :
                                                                                                                return (
                                                                                                                    <td>
                                                                                                                        <button
                                                                                                                            id="submit"
                                                                                                                            type="submit"
                                                                                                                            className="btn btn-danger center">Pending
                                                                                                                        </button>
                                                                                                                    </td>);
                                                                                                            case 1  :
                                                                                                                return (
                                                                                                                    <td>
                                                                                                                        <button
                                                                                                                            id="submit"
                                                                                                                            type="submit"
                                                                                                                            className="btn btn-success center">Saved
                                                                                                                        </button>
                                                                                                                    </td>);
                                                                                                            default :
                                                                                                                return (
                                                                                                                    <td>
                                                                                                                        <button
                                                                                                                            id="submit"
                                                                                                                            type="submit"
                                                                                                                            className="btn btn-info center">default
                                                                                                                        </button>
                                                                                                                    </td>);
                                                                                                        }
                                                                                                    case 1  :
                                                                                                        return (<td>
                                                                                                            <button
                                                                                                                id="submit"
                                                                                                                type="submit"
                                                                                                                className="btn btn-success center">Approved
                                                                                                            </button>
                                                                                                        </td>);
                                                                                                    default :
                                                                                                        return (<td>
                                                                                                            <button
                                                                                                                id="submit"
                                                                                                                type="submit"
                                                                                                                className="btn btn-info center">default
                                                                                                            </button>
                                                                                                        </td>);
                                                                                                }
                                                                                            })()}


                                                                                            {(() => {
                                                                                                switch (funder.saved) {
                                                                                                    case 1  :
                                                                                                        return (<td>
                                                                                                            <button
                                                                                                                id="submit"
                                                                                                                type="submit"
                                                                                                                onClick={() => this.onSubmitCF(funder.ProjectID)}
                                                                                                                className="btn btn-primary center">Submit
                                                                                                                Project
                                                                                                            </button>
                                                                                                        </td>);
                                                                                                    case 0  :
                                                                                                        return (<td>
                                                                                                            <button
                                                                                                                id="submit"
                                                                                                                type="submit"
                                                                                                                onClick={() => this.onDeleteCF(funder.ProjectID)}
                                                                                                                className="btn btn-primary center">Remove
                                                                                                            </button>
                                                                                                        </td>);
                                                                                                    default :
                                                                                                        return (<td>
                                                                                                            <button
                                                                                                                id="submit"
                                                                                                                type="submit"
                                                                                                                className="btn btn-info center">default
                                                                                                            </button>
                                                                                                        </td>);
                                                                                                }
                                                                                            })()}
                                                                                        </tr>
                                                                                    ))
                                                                                }
                                                                                </tbody>
                                                                            );
                                                                        case true:
                                                                            return (
                                                                                <tbody>
                                                                                <tr className="even pointer">
                                                                                    <td colSpan="6"
                                                                                        className="text-center">Nothing
                                                                                        to show
                                                                                    </td>
                                                                                </tr>
                                                                                </tbody>
                                                                            );
                                                                    }
                                                                })()}
                                                            </table>
                                                        </div>
                                                        <div className="pull-right">
                                                            <Pagination
                                                                firstPageText={<i
                                                                    className='glyphicon glyphicon-chevron-left'/>}
                                                                lastPageText={<i
                                                                    className='glyphicon glyphicon-chevron-right'/>}
                                                                prevPageText={<i
                                                                    className='glyphicon glyphicon-menu-left'/>}
                                                                nextPageText={<i
                                                                    className='glyphicon glyphicon-menu-right'/>}
                                                                activePage={this.state.activePageCF}
                                                                itemsCountPerPage={this.state.perPageCF}
                                                                totalItemsCount={this.state.totalCountCF}
                                                                onChange={this.handlePageChangeCF}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const {object, bool} = PropTypes;

Fundraiserdealcard.propTypes = {
    session: object.isRequired,
    authenticated: bool.isRequired,
    checked: bool.isRequired
};

const mapState = ({session}) => ({
    session: session.user,
    checked: session.checked,
    authenticated: session.authenticated
});

export default withRouter(connect(mapState)(Fundraiserdealcard));
