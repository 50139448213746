// import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { ProjectDetailsContainerComponent } from './ProjectDetailsContainerComponent';
import * as Action from './actions/actions';
import { PROJECT_REG_CF, PROJECT_REG_D } from '../../../utils/ApplicationConstants';


class PublicProjectDetailsComponent extends ProjectDetailsContainerComponent {
    PUBLIC_PROJECT_REG_TYPE = '506C';

    projectType = '';

    componentDidMount() {
        const { getPublicProject, match, getAllProjectComments } = this.props;
        const { projectId, projectType } = match.params;

        this.scrollToTop();
        getPublicProject(projectId, projectType);

        this.setState({
            investmentId: projectId,
            isLoggedInUser: false,
            projectType,
        });

        this.showGoBackButton = false;
        this.projectType = projectType;
        window.addEventListener('scroll', this.showFloatingInvestButtonOnScroll);

        if (projectType === PROJECT_REG_CF) {
            getAllProjectComments(projectId, false);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.showFloatingInvestButtonOnScroll);
    }

    isNotSignedUser = () => false;

    onInvest = () => {
        window.location = 'https://app.energyfunders.com/login';
    };

    hideTheProjectDetailsResult = () => {
        const { projectDetails } = this.props;

        return ((projectDetails.templateVersion !== 2)
            || ((projectDetails.regulationType !== this.PUBLIC_PROJECT_REG_TYPE)
                && this.projectType === PROJECT_REG_D));
    };

    onRefreshCommentList = () => {
        const { match, getAllProjectComments } = this.props;
        const { projectId: investmentId } = match.params;
        const { isLoggedInUser } = this.state;

        getAllProjectComments(investmentId, isLoggedInUser);
    };
}

const mapStateToProp = _state => ({ ..._state.projectDetails });
const mapDispatchToProp = _dispatch => ({ ...bindActionCreators(Action, _dispatch) });

const connectedComponent = connect(
    mapStateToProp,
    mapDispatchToProp,
)(PublicProjectDetailsComponent);

export default withRouter(connectedComponent);
