import PropTypes from 'prop-types'
import React from 'react';
import './OfferingsStyles.css';
import {PROJECT_REG_CF, PROJECT_REG_D,PROJECT_REG_ALL} from "../../../../../utils/ApplicationConstants";
import Icon from "../../../IconComponent/IconComponent";
import CreatableSelect from 'react-select/creatable';
  class OverviewOfferingsHeader extends React.Component {
	constructor(props) {
		super(props);
	}
	handleChange = selectedOption => {
this.props.onProjectTypeClickHandler(selectedOption)
	
	
	  };
	render() {
		console.log(`selected projects:`, this.props.selectedProject);
    return (
	
        <div>
	{(() => {
				if (this.props.isAccreditedUser ||this.props.wasAccreditedUser) {
					return (
						<div className="dropdown category-filter">
						<CreatableSelect className="cus-search"
							isClearable
        value={this.props.selectedProject}
        onChange={ this.handleChange }
	
        options={this.props.totalProjects}
      />
						</div>
					);
				}
			})()}

 </div>
    );
		}

};


OverviewOfferingsHeader.propTypes = {
	activeOfferingsChecked: PropTypes.bool.isRequired,
	onCheckboxClickedHandler: PropTypes.func.isRequired,
	onProjectTypeClickHandler: PropTypes.func.isRequired,
	pastOfferingsChecked: PropTypes.bool.isRequired,
	isAccreditedUser: PropTypes.bool.isRequired,
	selectedProjectType: PropTypes.string.isRequired,
	projectCategoryList: PropTypes.array.isRequired,
	projectCategoryOnClickHandler: PropTypes.func.isRequired,
	selectedProjectCategory: PropTypes.any.isRequired,
};

OverviewOfferingsHeader.defaultProps = {
	activeOfferingsChecked: true,
	onCheckboxClickedHandler: () => null,
	onProjectTypeClickHandler: () => null,
	pastOfferingsChecked: false,
	isAccreditedUser: false,
	selectedProjectType: PropTypes.string.isRequired,
	projectCategoryList: [],
	projectCategoryOnClickHandler: () => null,
	selectedProjectCategory: null,
};


export default OverviewOfferingsHeader;
