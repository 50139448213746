export default [
    {
        name: 'document',
        groups: ['mode', 'document', 'doctools'],
        items: [
            'Save', 'NewPage', 'Preview', 'Print', '-',
            'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo',
            'Redo', '-', 'Find', 'Replace', '-', 'SelectAll', '-',
            'Scayt', 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript',
            'Superscript', '-', 'CopyFormatting', 'RemoveFormat', '-',
            'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent',
            '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft',
            'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-',
            'BidiLtr', 'BidiRtl', '-', 'Language', 'Link', 'Unlink',
            'Anchor', '-', 'Image', 'Table', 'HorizontalRule', 'Smiley',
            'SpecialChar', 'PageBreak', 'Iframe',
            '-', 'TextColor', 'BGColor', 'Maximize', 'ShowBlocks',
            '-', 'Styles', 'Format', 'Font', 'FontSize'
        ],
    },
];
