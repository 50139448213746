import React, { Component } from "react";
import Chart from "react-apexcharts";
import moment from "moment";
class ProjectsChart extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		const { numberOfInvestments } = this.props;

		const investmentProjects = numberOfInvestments.map(
			(pjt) => pjt.ProjectName
		);
		console.log("investmentProjects", investmentProjects);
		const investmentAmounts = numberOfInvestments.map((pjt) =>
			parseFloat(pjt.InvestmentAmount)
		);
		console.log("InvestmentAmount", investmentAmounts);
		const series = investmentAmounts;
		// const options = {
		// 	chart: {
		// 		type: 'pie',

		// 	},
		// 	colors: ["#551d71", "#22ce0e"],
		// 	labels: investmentProjects,

		// };
		const options = {
			chart: {
				type: "donut",
			},
			plotOptions: {
                pie: {
                  startAngle: -90,
                  endAngle: 270
                }
              },
              dataLabels: {
                enabled: true
              },
              fill: {
                type: 'gradient',
              },
			colors: ["#aae4ae", "#5bb462","#98d09c","#86b78a", "#73ce7a","#7fdd86"],
			legend: {
				position: "bottom",
			},
			labels: investmentProjects,
		};

		return (
			<>
				<Chart
					options={options}
					series={series}
					type="donut"
					height={340}
				/>
			</>
		);
	}
}
export default ProjectsChart;
