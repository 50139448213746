import React, { Component } from 'react';
import swal from 'sweetalert2';
import Pagination from 'react-js-pagination';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import getDate from '../../../utils/ConvertDate';
import InvestorDetailsInvestmentsEarningsModal from './InvestorDetailsModalComponent/InvestorDetailsViewerModal';
import RequestService from '../../../api/RequestService';
import AddEntity from './AddEntity';
import { getUUID } from '../../../utils/General';
import Icon from '../../shared/IconComponent/IconComponent';
import NewEntityContainerComponent from '../../shared/NewEntityContainerComponent/NewEntityContainerComponent';
import InvestorTrackingContainerComponent from '../../shared/InvestorTrackingContainerComponent/InvestorTrackingContainerComponent';
import SignedCAViewerContainerComponent from './components/SignedCAViewerContainerComponent';


class InvestorContent extends Component {
	ITEMS_PER_PAGE = 10;

    constructor(props) {
        super(props);

        this.state = {
            id: props.session.id,
            isLoaded: false,
            errorOnLoad: false,

            InvestorDetailsInvestmentsEarningsModal: null,

            totalRecords: [],
            Records: [],
            Users: [],
            pageNumber: 1,
            totalCount: 0,
            perPage: this.ITEMS_PER_PAGE,
            activePage: 1,
            addEntityModal: null,

            investorTrackingModal: null,
            investorCATrackingModal: null,
            isAdmin:false,
        };

        this.handlePageChange = this.handlePageChange.bind(this);
		this.handleChangeItemsPerPage = this.handleChangeItemsPerPage.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.getDat = this.getDat.bind(this);
        this.handleCloseProfileModal = this.handleCloseProfileModal.bind(this);

        this.getInvestorsList = this.getInvestorsList.bind(this);
        this.verifyInvestorEmail = this.verifyInvestorEmail.bind(this);
        this.override30DayWait = this.override30DayWait.bind(this);
        this.onDeleteInvestor = this.onDeleteInvestor.bind(this);
        this.onUndeleteInvestor = this.onUndeleteInvestor.bind(this);
        this.addRoleToUser = this.addRoleToUser.bind(this);
    }



	componentDidMount() {
		window.scrollTo(0, 0);
		this.getInvestorsList();
        this.getRoles();
	}



	handlePageChange(pageNumber) {
		const { perPage } = this.state;

        const prev = ((pageNumber) - 1) * perPage;
        const next = prev + perPage;

        this.setState({
            activePage: pageNumber,
            Users: this.state.Records.slice(prev, next),
        });

		document.getElementById('EarningsTableComponent').scrollIntoView();
    }



	handleChangeItemsPerPage() {
		this.setState({
			perPage: Number(this.refs.selectItemsPerPage.value),	// Must be typecast.
			activePage: 1
		}, () => this.handlePageChange(1));
	}



	getDat(toSearch, objects) {
        return new Promise((resolve, reject) => {
            let dat = objects.filter(function (object) {
                let searchStatus = 0;
                let patt = new RegExp(toSearch, 'i');

                for (const key in object) {
                    if (object.hasOwnProperty(key)) {
                        if (patt.test(object[key])) {
                            searchStatus = 1;
                        }
                    }
                }
                if (patt.test(`${object["FirstName"]} ${object["LastName"]}`)) {
                    searchStatus = 1;
                } else if (patt.test(`${object["LastName"]} ${object["FirstName"]}`)) {
                    searchStatus = 1;
                } else if (patt.test(`${object["LastName"]}, ${object["FirstName"]}`)) {
                    searchStatus = 1;
                } else if (patt.test(`${object["LastName"]},${object["FirstName"]}`)) {
                    searchStatus = 1;
                } 

                if (searchStatus) {
                    return true;
                }
            });

            resolve(dat);
        });
    }



    handleSearchChange(e) {
        const toSearch = e.target.value;

        if (toSearch === '' || toSearch === undefined) {
            this.setState({
                Records: this.state.totalRecords,
                Users: this.state.totalRecords.slice(0, this.state.perPage),
                pageNumber: 1,
                totalCount: this.state.totalRecords.length,
                perPage: this.state.perPage,
                activePage: 1,
            });
        }
        else {
            const objects = this.state.totalRecords;

            this.getDat(toSearch, objects)
                .then((dat) => {
                    this.setState({
                        Records: dat,
                        Users: dat.slice(0, this.state.perPage),
                        pageNumber: 1,
                        totalCount: dat.length,
                        perPage: this.state.perPage,
                        activePage: 1,
                    });
                });
        }
    }



    async getInvestorsList(activePage = 1) {
        try {
            const { data } = await RequestService.get('/api/getInvestorsList');

            if (data.code === 200) {
                const from = (activePage - 1) * this.ITEMS_PER_PAGE;
                const to = from + this.ITEMS_PER_PAGE;

                this.setState({
                    totalRecords: data.rows,
                    Records: data.rows,
                    Users: data.rows.slice(from, to),
                    pageNumber: 1,
                    totalCount: data.rows.length,
                    perPage: this.ITEMS_PER_PAGE,
                    activePage,
                    isLoaded: true,
                    err: {},
                    errorOnLoad: false,
                });
            } else {
                alert('Could not get list of investors.');
            }
        } catch (err) {
            console.log(err);
        }
    }



    async verifyInvestorEmail(id) {
        const result = await swal({
            title: 'Do you want to manually verify this investor\'s email?',
            text: '',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Mark Email Verified',
        });

        if (result.value) {
            try {
                const { data: status } = await RequestService.post(`/api/verifyInvestorEmail/${id}`);

                if (status.code === 200) {
                    swal('Email Verified!', '', 'success');
                    this.getInvestorsList(this.state.activePage);
                } else {
                    alert('Could not verify email.');
                }
            } catch (err) {
                console.log(err);
            }
        }
    }



    async override30DayWait(id) {
        const result = await swal({
            title: 'Do you want to override the 30 day waiting period?',
            text: '',
            type: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Override',
        });

        if (result.value) {
            try {
                const { data: status } = await RequestService.post(`/api/override30DayWait/${id}`);

                if (status.code === 200) {
                    swal('Done!', '', 'success');
                } else {
                    alert('Could not override the 30 day waiting period.');
                }
            } catch (err) {
                console.log(err);
            }
        }
    }



    async onDeleteInvestor(id) {
        const result = await swal({
            title: 'Are you sure you want to Delete this User?',
            text: 'The user won\'t be able to login anymore.',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Delete',
        });

        if (result.value) {
            try {
                const { data: status } = await RequestService.post(`/api/deleteInvestor/${id}`);

                if (status.code === 200) {
                    swal('User Deleted', '', 'success');
                    this.getInvestorsList(this.state.activePage);
                } else {
                    alert('Could not delete investor.');
                }
            } catch (err) {
                console.log(err);
            }
        }
    }



    async onUndeleteInvestor(id) {
        const result = await swal({
            title: 'Are you sure you want to Un-delete this User?',
            text: 'You are restoring access to this user.',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Un-delete',
        });

        if (result.value) {
            try {
                const { data: status } = await RequestService.post(`/api/undeleteInvestor/${id}`);

                if (status.code === 200) {
                    swal('User Undeleted', '', 'success');
                    this.getInvestorsList(this.state.activePage);
                } else {
                    alert('Could not undelete this user.');
                }
            } catch (err) {
                console.log(err);
            }
        }
    }



    addRoleToUser(UserID) {
        this.props.history.push({
            pathname: '/app/Addrole',
            state: {
                RoleUserID: UserID,
            },
        });
    }



    getAllInvestorInfo(UserID) {
        this.setState({
            InvestorDetailsInvestmentsEarningsModal: <InvestorDetailsInvestmentsEarningsModal
                key={getUUID()}
                id={this.state.id}
                UserID={UserID}
                handleCloseProfileModal={this.handleCloseProfileModal}
            />,
        });
    }



    handleCloseProfileModal() {
        this.setState({
            InvestorDetailsInvestmentsEarningsModal: null,
        });
    }



    onAddEntityClickHandler = (userId, userName) => {
        this.setState({
            addEntityModal: <AddEntity
                userId={userId}
                openModal
                userName={userName}
                closeModal={this.onCloseAddEntityModal}
            />,
        });
    };



    onCloseAddEntityModal = () => {
        this.setState({ addEntityModal: null });
    };



    onShowInvestorTracking = (investorId) => {
        const investorTrackingModal = (
            <InvestorTrackingContainerComponent
                closeModal={this.closeInvestorTrackingModal}
                investorId={investorId}
                key={getUUID()}
            />
        );

        this.setState({ investorTrackingModal });
    };

    onSelectUserID =(investorId) => {
     if(this.state.isAdmin)
        localStorage.setItem("investorId", JSON.stringify(investorId));
       this.props.history.push('investors');
     }

    closeInvestorTrackingModal = () => this.setState({ investorTrackingModal: null });



    onShowInvestorCATracking = (investorId) => {
        const investorCATrackingModal = (
            <SignedCAViewerContainerComponent
                onCloseModal={this.closeInvestorCATrackingModal}
                investorId={investorId}
                key={getUUID()}
            />
        );

        this.setState({ investorCATrackingModal });
    };



    closeInvestorCATrackingModal = () => this.setState({ investorCATrackingModal: null });

    getRoles(){
      
        fetch('/api/getrole/'+this.state.id+'/'+ +this.state.id+'/' +  this.props.session.marketing,{
            method:'get'
        })
            .then((response)=>{
                return response.json()
            })
            .then((data)=>{
            const roles = data.map(item => item.Role);
          
            if(roles.includes("SuperAdmin") || roles.includes("Admin")){
                this.setState({
                             
                    isAdmin: true,
               
                });
            }
          
            })
        
    }

    render() {
        console.log("this.state.MainRole",this.state.MainRole);

        return (
            <div className="container body">
                <div className="main_container">
                    <div className="right_col" role="main">
                        <div className="">
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <div className="x_panel">
                                        <div className="x_title" id="EarningsTableComponent">
                                            <h4 className="up col-md-8">Investors</h4>
                                            <div className="col-md-4">
                                                <input
                                                    className="pull-right"
                                                    type="text"
                                                    onChange={this.handleSearchChange}
                                                    placeholder="Search here"
                                                />
                                            </div>
                                            <div className="clearfix" />
                                        </div>
                                        <div className="x_content">
                                            <div className="table-responsive">
                                                <table className="table table-striped jambo_table bulk_action">
                                                    <thead className="thead-inverse">
                                                        <tr className="headings">
                                                            <th className="column-title">User ID</th>
                                                            <th className="column-title">Actions</th>
                                                            <th className="column-title">First Name</th>
                                                            <th className="column-title">Last Name</th>
                                                            <th className="column-title">Email</th>
                                                            <th className="column-title">Created</th>
                                                            <th className="column-title">Email Verified</th>
                                                            <th className="column-title">Delete</th>
                                                            <th className="column-title">Add Role</th>
                                                            <th className="column-title">Accreditation Status</th>
                                                            <th className="column-title">30 Day Wait</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.Users.map(user => (
                                                                <tr key={user.UserID} className="even pointer">
                                                                    <td>
                                                                        <a onClick={() => this.getAllInvestorInfo(user.UserID)}>
                                                                            {user.UserID}
                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        <div className="dropdown">
                                                                            <button
                                                                                type="button"
                                                                                data-toggle="dropdown"
                                                                                className="btn btn-primary dropdown-toggle"
                                                                            >
                                                                                Actions
                                                                                {' '}
                                                                                <Icon
                                                                                    iconName="chevron-down"
                                                                                    extraClasses="white-text"
                                                                                />
                                                                            </button>
                                                                            <ul className="dropdown-menu">
                                                                                <li>
                                                                                    <a onClick={() => this.getAllInvestorInfo(user.UserID)}>
                                                                                        View Investor Info
                                                                                    </a>
                                                                                </li>
                                                                                <li>
                                                                                    <a onClick={() => this.onShowInvestorCATracking(user.UserID)}>
                                                                                        View Signed CA List
                                                                                    </a>
                                                                                </li>
                                                                                <li>
                                                                                    <a onClick={() => this.onShowInvestorTracking(user.UserID)}>
                                                                                        View Investor Tracking
                                                                                    </a>
                                                                                </li>
                                                                                <li>
                                                                                    <a onClick={() => this.onSelectUserID(user.UserID)}>
                                                                                    Impersonate
                                                                                    </a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <a onClick={() => this.getAllInvestorInfo(user.UserID)}>
                                                                            {user.FirstName}
                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        <a onClick={() => this.getAllInvestorInfo(user.UserID)}>
                                                                            {user.LastName}
                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        <a onClick={() => this.getAllInvestorInfo(user.UserID)}>
                                                                            {user.Email}
                                                                        </a>
                                                                    </td>
                                                                    <td>{getDate.getDate(user.CreatedDate)}</td>
                                                                    {(() => {
                                                                        if (user.EmailVerified) {
                                                                            return (
                                                                                <td>
																					<span style={{color: 'green'}}>Verified</span>
                                                                                </td>
                                                                            );
                                                                        }
                                                                        return (
                                                                            <td>
                                                                                <button
                                                                                    id="submit"
                                                                                    onClick={() => this.verifyInvestorEmail(user.UserID)}
                                                                                    type="submit"
                                                                                    className="btn btn-danger center"
                                                                                >
                                                                                Not Verified
                                                                                </button>
                                                                            </td>
                                                                        );
                                                                    })()}
                                                                    {(() => {
                                                                        if (user.Deleted) {
                                                                            return (
                                                                                <td>
                                                                                    <button
                                                                                        id="submit"
                                                                                        type="button"
                                                                                        onClick={() => this.onUndeleteInvestor(user.UserID)}
                                                                                        className="btn btn-success center"
                                                                                    >
                                                                                    Deleted
                                                                                    </button>
                                                                                </td>
                                                                            );
                                                                        }
                                                                        return (
                                                                            <td>
                                                                                <button
                                                                                    id="submit"
                                                                                    type="button"
                                                                                    onClick={() => this.onDeleteInvestor(user.UserID)}
                                                                                    className="btn btn-danger center"
                                                                                >
                                                                                Delete
                                                                                </button>
                                                                            </td>
                                                                        );
                                                                    })()}
                                                                    <td>
																		{user.Roles}
																		<br/>
                                                                        <a
                                                                            id="submit"
                                                                            type="submit"
                                                                            onClick={() => this.addRoleToUser(user.UserID)}
                                                                        >
                                                                        Add role
                                                                        </a>
                                                                    </td>
                                                                    {(() => {
                                                                        if (user.IsAccreditated) {
                                                                            return (
                                                                                <td>
																					<span style={{color: 'green'}}>Accredited</span>
                                                                                </td>
                                                                            );
                                                                        }
                                                                        return (
                                                                            <td>
																				<span style={{color: 'red'}}>Non-Accredited</span>
                                                                            </td>
                                                                        );
                                                                    })()}
                                                                    {(() => {
                                                                        if (user['30DayWait']) {
                                                                            return (
                                                                                <td>
                                                                                    <button
                                                                                        id="submit"
                                                                                        type="button"
                                                                                        className="btn btn-success center"
                                                                                        onClick={() => this.override30DayWait(user.UserID)}
                                                                                    >
                                                                                    Override 30 day wait
                                                                                    </button>
                                                                                </td>
                                                                            );
                                                                        }
                                                                        return (<td>30 Day Wait Overridden</td>);
                                                                    })()}
                                                              
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="pull-right">
                                                <Pagination
                                                    firstPageText={<i className="glyphicon glyphicon-chevron-left" />}
                                                    lastPageText={<i className="glyphicon glyphicon-chevron-right" />}
                                                    prevPageText={<i className="glyphicon glyphicon-menu-left" />}
                                                    nextPageText={<i className="glyphicon glyphicon-menu-right" />}
                                                    activePage={this.state.activePage}
                                                    itemsCountPerPage={this.state.perPage}
                                                    totalItemsCount={this.state.totalCount}
                                                    onChange={this.handlePageChange}
                                                />
												<div className="pull-left" style={{margin: '0 10px 0 0'}}>
													<select ref="selectItemsPerPage" style={{margin: '20px 0', padding: '0 10px', height: 32}} onChange={this.handleChangeItemsPerPage}>
														<option>10</option>
														<option>20</option>
														<option>50</option>
													</select>
												</div>
                                            </div>
                                        </div>
                                    </div>

                                    {this.state.InvestorDetailsInvestmentsEarningsModal}
                                    {this.state.investorCATrackingModal}
                                    {this.state.investorTrackingModal}
                                    {this.state.addEntityModal}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


InvestorContent.propTypes = {
    session: PropTypes.object.isRequired,
    authenticated: PropTypes.bool.isRequired,
    checked: PropTypes.bool.isRequired,
};

const mapState = ({ session }) => ({
    session: session.user,
    checked: session.checked,
    authenticated: session.authenticated,
});

export default withRouter(connect(mapState)(InvestorContent));
