import PropTypes from 'prop-types';
import React from 'react';
import SingleLineInputComponent from '../../InputComponents/SingleLineInputComponent/SingleLineInputComponent';
import SelectComponent from '../../InputComponents/SelectComponent/SelectComponent';
import GooglePlacesComponent from '../../InputComponents/GooglePlacesComponent/GooglePlacesComponent';
import { ENTITY_TYPES_LIST_WITH_PERSONAL, ENTITY_TYPES_LIST_NO_PERSONAL } from '../../../../utils/ApplicationConstants';
import HideComponent from '../../HideComponent/HideComponent';


const FirstStep = (props) => {
    const {
        entityType, entityName, address, city, address2,
        state, zipCode, phone, country, onInputChange,
        onSetSelectedAddressInfo, isRocketDollarIRA, isFinancialAdvisor, isOtherIRA
    } = props;


    return (
        <div className="container">
            <div className="col-xs-12">
                <div className="col-xs-12">
                    <fieldset>
                        <legend>Entity Info</legend>

                        <SelectComponent
                            xs={12}
                            name="entityType"
                            onChange={onInputChange}
                            options={isFinancialAdvisor ? ENTITY_TYPES_LIST_WITH_PERSONAL : ENTITY_TYPES_LIST_NO_PERSONAL}
                            selectTitle="Entity type"
                            value={entityType}
                            isRequired
                        />

                        <HideComponent hide={isRocketDollarIRA}>
                            <div className="col-xs-12">
                                <SingleLineInputComponent
                                    onInputUpdate={onInputChange}
                                    elementValue={entityName}
                                    inputTitle="Full Entity Name"
                                    inputName="entityName"
                                    maxInputLength={null}
                                    isRequired
                                />
                            </div>
                        </HideComponent>
                    </fieldset>
                </div>
                {/* */}
                <HideComponent hide={isRocketDollarIRA} > 
                    <div className="col-xs-12">
                        <fieldset>
                            <legend>Contact</legend>

                            <div className="col-xs-12 col-sm-6">
                                <GooglePlacesComponent
                                    inputName="address"
                                    inputTitle="Address"
                                    elementValue={address}
                                    onInputChange={onInputChange}
                                    onPlaceSelect={onSetSelectedAddressInfo}
                                    placeholder="Physical Address (No PO Boxes)"
                                    isRequired
                                />
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <SingleLineInputComponent
                                    maxInputLength={null}
                                    onInputUpdate={onInputChange}
                                    elementValue={address2}
                                    inputName="address2"
                                    inputTitle="Address 2"
                                    placeholder="Apartment, floor, suite, bldg. # of business’ physical address."
                                />
                            </div>
                            <div className="col-xs-12 col-sm-4">
                                <SingleLineInputComponent
                                    maxInputLength={null}
                                    onInputUpdate={onInputChange}
                                    elementValue={city}
                                    inputName="city"
                                    inputTitle="City"
                                    placeholder="City"
                                    isRequired
                                />
                            </div>
                            <div className="col-xs-12 col-sm-4">
                                <SingleLineInputComponent
                                    maxInputLength={null}
                                    onInputUpdate={onInputChange}
                                    elementValue={state}
                                    inputName="state"
                                    inputTitle="State"
                                    placeholder="State"
                                    isRequired
                                />
                            </div>
                            <div className="col-xs-12 col-sm-4">
                                <SingleLineInputComponent
                                    maxInputLength={null}
                                    onInputUpdate={onInputChange}
                                    elementValue={zipCode}
                                    inputName="zipCode"
                                    inputTitle="Zip Code"
                                    placeholder="Zip Code"
                                    isRequired
                                />
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <SingleLineInputComponent
                                    maxInputLength={null}
                                    onInputUpdate={onInputChange}
                                    elementValue={phone}
                                    inputName="phone"
                                    inputTitle="Phone"
                                    placeholder="phone number"
                                    isRequired
                                />
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <SingleLineInputComponent
                                    maxInputLength={null}
                                    onInputUpdate={onInputChange}
                                    elementValue={country}
                                    inputName="country"
                                    inputTitle="Country"
                                    placeholder="Country"
                                    isRequired
                                />
                            </div>
                        </fieldset>
                    </div>
                </HideComponent>
            </div>
        </div>
    );
};

FirstStep.propTypes = {
    address: PropTypes.string.isRequired,
    address2: PropTypes.string,
    city: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    entityName: PropTypes.string.isRequired,
    entityType: PropTypes.string.isRequired,
    isRocketDollarIRA: PropTypes.bool.isRequired,
    isOtherIRA: PropTypes.bool.isRequired,
    onInputChange: PropTypes.func.isRequired,
    onSetSelectedAddressInfo: PropTypes.func.isRequired,
    phone: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zipCode: PropTypes.any,
};

FirstStep.defaultProps = {
    address: '',
    address2: '',
    city: '',
    country: '',
    entityName: '',
    entityType: '',
    isRocketDollarIRA: false,
    isOtherIRA: false,
    onInputChange: () => null,
    onSetSelectedAddressInfo: () => null,
    phone: '',
    state: '',
};

export default FirstStep;
