import React from "react";
import PropTypes from 'prop-types'
import {Collapse, Fade} from "react-bootstrap";

const propTypes = {
    daysAsPrivateUpdate: PropTypes.any.isRequired,
    isPrivateForEver: PropTypes.bool.isRequired,
    onChangeHandler: PropTypes.func.isRequired,
    onCheckboxSelected: PropTypes.func.isRequired,
    updateType: PropTypes.any.isRequired
};

const ProjectUpdateTypeEditor = (props) => {
    const isPublic = (parseInt(props.updateType) === -1);
    const isPrivate = (parseInt(props.updateType) === 0);
    const isPrivateForSomeTime = !props.isPrivateForEver;

    return (
        <div className="form-group">
            <label>Update Type</label>
            <div className="radio">
                <label>
                    <input value={-1}
                           type="radio"
                           name="updateType"
                           onChange={props.onChangeHandler}
                           checked={isPublic}/>
                    Public Update
                </label>
            </div>
            <div className="radio">
                <label>
                    <input value={0}
                           type="radio"
                           name="updateType"
                           onChange={props.onChangeHandler}
                           checked={isPrivate}/>
                    Private Update
                </label>
                &nbsp;
                <Fade in={isPrivate}>
                    <label>
                        <input value={true}
                               type="checkbox"
                               name="isPrivateForEver"
                               checked={props.isPrivateForEver}
                               onChange={props.onCheckboxSelected}/>
                        &nbsp;
                        Always Private
                    </label>
                </Fade>
            </div>
            <Collapse in={isPrivate && isPrivateForSomeTime}>
                <div className="form-group">
                    <label>Number of days as private <span className="red-text">(Must Be a Positive Integer Greater Than 0)</span></label>
                    <input min={1}
                           type="number"
                           name="daysAsPrivateUpdate"
                           className="form-control form-input"
                           value={props.daysAsPrivateUpdate}
                           onChange={props.onChangeHandler}/>
                </div>
            </Collapse>
        </div>
    );
};

ProjectUpdateTypeEditor.propTypes = propTypes;

export default ProjectUpdateTypeEditor;

