/*
 * This is the "Admin Menu" - "Dashboard" Page.
 */


import React, {Component} from 'react';
import store from '../../../index'
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Totalusers from '../../shared/TotalUsersComponent/Totalusers';
import Averagetime from '../../shared/AverageTimeComponent/Averagetime';
import Totalinvestors from './Totalinvestors';
import Totalfundraisers from './Totalfundraisers';
import Totalcollections from '../../shared/TotalCollectionsComponent/Totalcollections';
import Totalconnections from '../../shared/TotalConnectionsComponent/Totalconnections';
import Pagination from 'react-js-pagination';
import getDate from '../../../utils/ConvertDate'
import RequestService from '../../../api/RequestService';


class Content extends Component {
    constructor(props) {
        super(props);

        this.state = {
            Users: [],
            Entities: [],
            id: props.session.id,
            URL: '',
            projects: [],

            totalRecords: [],
            Records: [],
            pageNumber: 1,
            totalCount: 0,
            perPage: 10,
            activePage: 1,

            totalRecords1: [],
            Records1: [],
            pageNumber1: 1,
            totalCount1: 0,
            perPage1: 10,
            activePage1: 1,

            totalRecords2: [],
            Records2: [],
            pageNumber2: 1,
            totalCount2: 0,
            perPage2: 10,
            activePage2: 1
        };

        this.dashBoardFirst = this.dashBoardFirst.bind(this);
        this.getUsers = this.getUsers.bind(this);
        this.getprojects = this.getprojects.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handlePageChange2 = this.handlePageChange2.bind(this);
        this.handlePageChange1 = this.handlePageChange1.bind(this);

        this.callTestFunction = this.callTestFunction.bind(this);
    }



    componentDidMount() {
        window.scrollTo(0, 0);

        store.subscribe(() => {
            let currentState = store.getState();

            this.setState({
                id: currentState.session.user.id
            });
        });

        this.dashBoardFirst();
        this.getprojects();
        this.getUsers();
    }



    getprojects() {
        //fetch call to get approved projects
        fetch(`/api/getapprovedprojects/${this.state.id}/${this.props.session.marketing}`, {
            method:'get'
        })
        .then((response)=>{
            return response.json()
        })
        .then((data)=>{
            for(let i=0;i<data.length;i++){
                    this.setState({
                        totalRecords1:this.state.projects.concat(data[i]),
                        Records1:this.state.projects.concat(data[i]),
                        projects:this.state.projects.concat(data[i]).slice(0, 10),
                        pageNumber1: 1,
                        totalCount1:this.state.projects.concat(data[i]).length,
                        perPage1: 10,
                        activePage1: 1
                    });
            }
            localStorage.setItem("ProjectName", data[0].ProjectName);
        })
        .catch((err)=>{
            this.setState({
                err:err
            })
        })
    }


    async dashBoardFirst() {
        // fetch call to get company earnings/projectinvestments
        try {
            const { data } = await RequestService.get(`/api/dashBoardFirst`);

            this.setState({
                totalRecords: data,
                Records: data,
                Entities: data.slice(0, 10),
                pageNumber: 1,
                totalCount: data.length,
                perPage: 10,
                activePage: 1
            });
        }
        catch (err) {
            console.log(err);
        }
    }



    async getUsers() {
        //fetch call to get online users
		try {
			const { data } = await RequestService.get(`/api/getOnlineUsers`);

			if (data.length > 0) {
				this.setState({
					totalRecords2: data,
					Records2: data,
					Users: data.slice(0, 10),
					pageNumber2: 1,
					totalCount2: data.length,
					perPage2: 10,
					activePage2: 1
				});
			}
		}
		catch (err) {
			console.log(err);
		}
    }



    handlePageChange(pageNumber) {
        let prev = ((pageNumber) - 1) * 10;
        let next = prev + 10;

        this.setState({
            activePage: pageNumber,
            Entities: this.state.Records.slice(prev, next)
        });
    }



    handlePageChange1(pageNumber1) {
        let prev = ((pageNumber1) - 1) * 10;
        let next = prev + 10;

        this.setState({
            activePage1: pageNumber1,
            projects: this.state.Records1.slice(prev, next)
        });
    }



    handlePageChange2(pageNumber2) {
        let prev = ((pageNumber2) - 1) * 10;
        let next = prev + 10;

        this.setState({
            activePage2: pageNumber2,
            Users: this.state.Records2.slice(prev, next)
        });
    }



    async callTestFunction() {
        // const { data } = await RequestService.get('/api/getSheetTest');
        // const { data } = await RequestService.post('/api/testFunction');
        // console.log('data = ', data);

        // this.setState({
        //     showGoogleSheetsModal: true,
        //     googleSheetsModalHTML: data.asdf
        // });
    }



    render() {
        return (
            <div className="container body">
              <div className="main_container">
                  <div className="right_col back" role="main">
                    <div className="container_fluid">
                        <div className="row tile_count">
                          <Totalusers id={this.props.session.id} pwhash={this.props.session.marketing}/>
                          <Averagetime id={this.props.session.id} pwhash={this.props.session.marketing}/>
                          <Totalinvestors id={this.props.session.id} pwhash={this.props.session.marketing}/>
                          <Totalfundraisers id={this.props.session.id} pwhash={this.props.session.marketing}/>
                          <Totalcollections id={this.props.session.id} pwhash={this.props.session.marketing}/>
                          <Totalconnections id={this.props.session.id} pwhash={this.props.session.marketing}/>
                        </div>
                        {/*<div>*/}
                        {/*    <button onClick={this.callTestFunction}>Test Button</button>*/}
                        {/*</div>*/}
                    </div>
                  </div>
                  {/* page content */}
                <div className="right_col" role="main">
                  <div className='' style={{minHeight:810}}>
                    <div className="clearfix" />
                    <div className="row">
                      <div className="clearfix" />
                      <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="x_panel">
                          <div className="x_title">
                            <h4 className='up'>Most Recent EnergyFunders Earnings</h4>
                            <div className="clearfix" />
                          </div>
                          <div className="x_content">
                            <div className="table-responsive">
                              <table className="table table-striped jambo_table bulk_action">
                                <thead className="thead-inverse">
                                <tr className="headings">
                                  <th className="column-title">Project Name</th>
                                  <th className="column-title">Earning Amount </th>
                                  <th className="column-title">Earning Date</th>
                                  <th className="column-title">EnergyFunders Carry </th>
                                </tr>
                                </thead>
                                {(() => {
                                    if (this.state.Entities.length > 0) {
                                        return (
                                            <tbody>
                                            {
                                                this.state.Entities.map((entity)=>(
                                                    <tr key={entity.ProjectID_RegD} className="even pointer">
                                                        <td className=" ">{entity.ProjectName}</td>
                                                        <td className=" ">$ {Math.round(entity.EarningAmount)}</td>
                                                        <td className=" ">{getDate.getDate(entity.EarningDate)}</td>
                                                        <td className=" ">{entity.efCarry.slice(0,2)}%</td>
                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        );
                                    }
                                    else {
                                        return (
                                            <tbody>
                                                <tr className="even pointer">
                                                    <td colSpan="3" className="text-center">Nothing to Show</td>
                                                </tr>
                                            </tbody>
                                        );
                                    }
                              })()}

                              </table>
                                <div className="pull-right">
                                    <Pagination
                                        firstPageText={<i className='glyphicon glyphicon-chevron-left' />}
                                        lastPageText={<i className='glyphicon glyphicon-chevron-right' />}
                                        prevPageText={<i className='glyphicon glyphicon-menu-left' />}
                                        nextPageText={<i className='glyphicon glyphicon-menu-right' />}
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={this.state.perPage}
                                        totalItemsCount={this.state.totalCount}
                                        onChange={this.handlePageChange}
                                    />
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="x_panel">
                          <div className="x_title">
                            <h4>Most Recent Investment Vehicles</h4>

                            <div className="clearfix" />
                          </div>
                          <div className="x_content">
                          <div className="table-responsive">
                            <table className="table table-striped jambo_table bulk_action">
                              <thead>
                              <tr>
                                <th>Project Name</th>
                                <th>Funding Period</th>
                                <th>Entity</th>
                                <th>Project Status</th>
                              </tr>
                              </thead>
                                {(() => {
                                    if (this.state.projects.length > 0) {
                                        return (
                                            <tbody>
                                            {
                                                this.state.projects.map((project) => (
                                                    <tr key={project.ProjectID_RegD} className="even pointer">
                                                        <td className=" ">{project.ProjectName}</td>
                                                        <td className=" ">{project.FundPeriod}</td>
                                                        <td className=" ">{project.Entity}</td>
                                                        <td className=" ">{project.ProjectStatus}</td>
                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        );
                                    }
                                    else {
                                        return (
                                            <tbody>
                                            <tr className="even pointer">
                                                <td colSpan="3" className="text-center">Nothing to show</td>
                                            </tr>
                                            </tbody>
                                        );
                                    }
                                })()}
                            </table>
                            <div className="pull-right">
                                <Pagination
                                    firstPageText={<i className='glyphicon glyphicon-chevron-left' />}
                                    lastPageText={<i className='glyphicon glyphicon-chevron-right' />}
                                    prevPageText={<i className='glyphicon glyphicon-menu-left' />}
                                    nextPageText={<i className='glyphicon glyphicon-menu-right' />}
                                    activePage={this.state.activePage1}
                                    itemsCountPerPage={this.state.perPage1}
                                    totalItemsCount={this.state.totalCount1}
                                    onChange={this.handlePageChange1}
                                />
                            </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="x_panel">
                          <div className="x_title">
                              <h4>Online Users</h4>

                            <div className="clearfix" />
                          </div>
                          <div className="x_content">
                            <div className="table-responsive">
                            <table className="table table-striped jambo_table bulk_action">

                              <thead>
                              <tr>
                                  <th>First Name</th>
                                  <th>Last Name</th>
                                  <th>Email</th>
                                  <th>View</th>
                              </tr>
                              </thead>
                              {(() => {
                                  if (this.state.Users.length > 0) {
                                      return (
                                          <tbody>
                                          {
                                              this.state.Users.map((user)=>(
                                                  <tr key={user.UserID} className="even pointer">
                                                      <td className=" ">{user.FirstName}</td>
                                                      <td className=" ">{user.LastName}</td>
                                                      <td className=" ">{user.Email}</td>
                                                      <td className=" ">View</td>
                                                  </tr>
                                              ))
                                          }
                                          </tbody>
                                      );
                                  }
                                  else {
                                      return (
                                          <tbody>
                                          <tr className="even pointer">
                                              <td colSpan="3" className="text-center">Nothing to show</td>
                                          </tr>
                                          </tbody>
                                      );
                                  }
                               })()}
                            </table>

                                <div className="pull-right">
                                    <Pagination
                                        firstPageText={<i className='glyphicon glyphicon-chevron-left' />}
                                        lastPageText={<i className='glyphicon glyphicon-chevron-right' />}
                                        prevPageText={<i className='glyphicon glyphicon-menu-left' />}
                                        nextPageText={<i className='glyphicon glyphicon-menu-right' />}
                                        activePage={this.state.activePage2}
                                        itemsCountPerPage={this.state.perPage2}
                                        totalItemsCount={this.state.totalCount2}
                                        onChange={this.handlePageChange2}
                                    />
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="clearfix" />
                    </div>
                  </div>
                </div>
                  {/* /page content */}
              </div>
            </div>
        );
    }
}


const { object, bool } = PropTypes;

Content.propTypes = {
    session: object.isRequired,
    authenticated: bool.isRequired,
    checked: bool.isRequired
};

const mapState = ({ session }) => ({
    session: session.user,
    checked: session.checked,
    authenticated: session.authenticated
});

export default connect(mapState)(Content);
