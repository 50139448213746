/**
 * Created by goutham on 9/13/2017.
 */
import React, {Component} from 'react';
import store from './index'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import getDate from './utils/ConvertDate'

class Watchlist extends Component {
    constructor(props){
        super(props);
        this.state={
            URL:"",
            user:[],
            id:props.session.id,
            watchlist:[],
            projects:[],
            isLoaded:false,
            errorOnLoad:false
        };
        this.getprojects=this.getprojects.bind(this);
        this.onDealClick=this.onDealClick.bind(this);
        this.getProjectID=this.getProjectID.bind(this);
    }
    componentWillMount(){
        window.scrollTo(0, 0);
    }
    getProjectID(){
        fetch('/api/getwatchlist/'+this.state.id+'/'+this.props.session.marketing,{
            method:'get'
        })
            .then((response)=>{
                return response.json()
            })
            .then((data)=>{
                this.setState({
                    watchlist:data,
                    isLoaded:true,
                    err:{},
                    errorOnLoad:false,
                });
                this.getprojects();

            })
            .catch((err)=>{
                this.setState({
                    err:err,
                    errorOnLoad:true
                })

            })
    }
    onDealClick(id){
        localStorage.setItem("DealID",id);
        this.props.history.push("/app/deal");
    }

    getprojects(){
        for(let i=0;i<this.state.watchlist.length;i++) {
            fetch('/api/getproject/' + this.state.watchlist[i].ProjectID_RegD+'/'+this.state.id+'/'+this.props.session.marketing, {
                method: 'get'
            })
                .then((response) => {
                    return response.json()

                })
                .then((data) => {
                    this.setState({
                        projects: this.state.projects.concat(data),
                        isLoaded: true,
                        err: {},
                        errorOnLoad: false,
                    });
                })
                .catch((err) => {
                    this.setState({
                        err: err,
                        errorOnLoad: true
                    })

                })
        }

    }
    componentDidMount(){
        store.subscribe(() => {
            var currentState=store.getState();
            this.setState({
                id:currentState.session.user.id
            });
        });
        this.getProjectID();
    }
    render() {
        return (
            <div className="container body">
              <div className="main_container">
                  {/* page content */}
                <div className="right_col" role="main">
                <div>
                   <ul className="nav nav-pills nav-justified" style={{ padding: '0 10px', marginBottom: 20 }}>
                       <li className="active"><a data-toggle="pill" href="#home">EnergyFunders Marketplace (Reg CF)</a></li>
                       <li><a data-toggle="pill" href="#menu1">EnergyFunders Black (Reg D)</a></li>
                   </ul>
                   <div className="tab-content">
                       <div id="home" className="tab-pane fade in active">

                       </div>
                       <div id="menu1" className="tab-pane fade">
 <div className="col-md-10 col-sm-10 col-xs-8">
                  </div>
                  <div className="row">
                      {
                          this.state.projects.map((project)=>(
                              <span style={{cursor:'pointer'}}><div key={project.ProjectInvestmentID} onClick={()=>this.onDealClick(project.ProjectInvestmentID)} className="col-xs-6 col-md-3 col-sm-4 col-lg-3">
                                <div className="project project-radius project-primary">
                                    <div className="shape">
                                        <div className="shape-text">
                                            New
                                        </div>
                                    </div>
                                    <img className="topimage" height="175" width="100%" src="/img/image.jpg" alt=""/>

                                    <div className="project-content">
                                        <h3 className="lead">
                                            {project.ProjectName}
                                        </h3>
                                        <p>
                                          Company : {project.OperatedBy}
                                          <br/>Opens On : {getDate.getDate(project.DateInvestmentStart)}
                                        </p>
                                        <b>${project.currentFunding}/${project.FundingGoal}</b>
                                         <div className="progress">
                                             <div className="progress-bar" role="progressbar" aria-valuenow={70} aria-valuemin={0} aria-valuemax={100} style={{width: ""+(project.currentFunding/project.FundingGoal)*100+"%"}}></div>
                                         </div>
                                        <div>{Math.round((project.currentFunding/project.FundingGoal)*100)}%</div><br/>
                                    </div>
                                </div>
                            </div></span>
                          ))
                      }
                  </div>
                       </div>
                   </div>
               </div>

                </div>
              </div>
            </div>
        )
    }
}

const { object, bool } = PropTypes;

Watchlist.propTypes = {
    session: object.isRequired,
    authenticated: bool.isRequired,
    checked: bool.isRequired
};

const mapState = ({ session }) => ({
    session: session.user,
    checked: session.checked,
    authenticated: session.authenticated
});

export default withRouter(connect(mapState)(Watchlist));
