import React, {Component} from 'react';
import {withRouter} from 'react-router-dom'
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Pagination from 'react-js-pagination';
import getDate from '../../utils/ConvertDate';
import ProjectUpdateViewer from "../shared/projectUpdateViewerComponent/ProjectUpdateViewer";
import RequestService from "../../api/RequestService";
import { getApolloClient } from "../../utils/apollo-client";
import {QUERY_ALL_PAGES,sanitizeExcerpt} from "../../utils/data/wordpressPages";
import { getImpersonateID  } from "../../utils/General";
import Card from '../shared/NewProjectUpdateComponent/Card';
import {HTTP_ERR_BAD_REQUEST} from "../../utils/ApplicationConstants";
import GPLPVotings from './GPLPVotings';
import { withParallelMarketsHOC } from './WithParallelMarketsHOC';
import moment from 'moment';

import { Button, Modal } from "react-bootstrap";
class Investornotifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            show1: false,
            NotificationsCF: [],
            NotificationsD: [],
            ViewNotificationsCF: [],
            ViewNotificationsD: [],
            totalRecords: [],
            Records: [],
            pageNumber: 1,
            totalCount: 0,
            perPage: 10,
            activePage: 1,
            id: props.session.id,
            IsAccredited: 0,
			WasAccredited: 0,
            isLoaded: false,
            errorOnLoad: false,
            landing:false,
            voteResponse: [],
            allPages:[ 
                {title:'',
                excerpt:'',
                slug:'',
                date:'',
                author:'',
                categories:'',
                isSticky : false,
                featuredImage:''}],
                projectUpdates:[],
                fundsWell:[],
                bitcoinMines:[],
                projectMap:[],
                allProjectsD:[],
                seeYourFund:[],
                fullUserName:"",
                // noticeModal:false,
                
               
        };
        this.getRegCF = this.getRegCF.bind(this);
        this.getRegD = this.getRegD.bind(this);
        this.onViewCF = this.onViewCF.bind(this);
        this.onViewD = this.onViewD.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleCloseD = this.handleCloseD.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
       
    };


    async componentDidMount() {
        window.scrollTo(0, 0);
        this.getRegCF();
        this.getRegD();
        this.checkAccreditation();
        this.getAllPages();
        this.handlePageUrl();
        this.getMyInvestments();
        this.getUserName();
        this.getVoteResponse();
        // setTimeout(() => this.setState({ noticeModal:true }), 3000);
    }


    handleClose() {
        this.setState({show: false});
    }

    handleCloseD() {
        this.setState({show1: false});
    }

    handlePageChange(pageNumber) {
        let prev = ((pageNumber) - 1) * 10;
        let next = prev + 10;
        this.setState({
            activePage: pageNumber,
            NotificationsD: this.state.Records.slice(prev, next)
        });

    }
      mapPostData(post = {}) {
        const data = { ...post };
      
        // Clean up the author object to avoid someone having to look an extra
        // level deeper into the node
      
        if (data.author) {
          data.author = {
            ...data.author.node,
          };
        }
      
        // The URL by default that comes from Gravatar / WordPress is not a secure
        // URL. This ends up redirecting to https, but it gives mixed content warnings
        // as the HTML shows it as http. Replace the url to avoid those warnings
        // and provide a secure URL by default
      
    
      
        // Clean up the categories to make them more easy to access
      
        if (data.categories) {
          data.categories = data.categories.edges.map(({ node }) => {
            return {
              ...node,
            };
          });
        }
      
        // Clean up the featured image to make them more easy to access
      
        if (data.featuredImage) {
          data.featuredImage = data.featuredImage.node;
        }
      
        return data;
      }
    async  getAllPages() {
        const apolloClient = getApolloClient();
      
        const data = await apolloClient.query({
          query: QUERY_ALL_PAGES,
        });
      
        const pages = data?.data.pages?.edges.map(({ node = {} }) => node);

        const  allPages = Array.isArray(pages) && pages.map(this.mapPostData);
   const projectUpdates  =  allPages?.filter(item=> item?.excerpt?.includes("Fund"));
 const fundsWell  =  allPages?.filter(item=> item?.excerpt?.includes("Well"));
    const bitcoinMines  =  allPages?.filter(item=> item?.excerpt?.includes("Bitcoin"));
    const projectMap  =  allPages?.filter(item=> item?.excerpt?.includes("Projectmap"));
const seeYourFund = allPages?.filter(item=> item?.excerpt?.includes("SeeYourFund"));

this.setState({projectUpdates:projectUpdates,fundsWell:fundsWell,bitcoinMines:bitcoinMines,projectMap:projectMap,seeYourFund:seeYourFund});
      }

    getRegCF() {
        fetch('/api/getInvNotificationCF/' + this.state.id + '/' + this.props.session.marketing, {
            method: 'get'
        })
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                this.setState({
                    NotificationsCF: data.rows,
                    isLoaded: true,
                    err: {},
                    errorOnLoad: false,

                });
            })
            .catch((err) => {
                this.setState({
                    err: err,
                    errorOnLoad: true
                })

            })
    }

    getRegD() {
        fetch('/api/getInvNotificationD/' + this.state.id + '/' + this.props.session.marketing, {
            method: 'get'
        })
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                this.setState({
                    totalRecords: data.rows,
                    Records: data.rows,
                    NotificationsD: data.rows.slice(0, 10),
                    pageNumber: 1,
                    totalCount: data.rows.length,
                    perPage: 10,
                    activePage: 1,
                    isLoaded: true,
                    err: {},
                    errorOnLoad: false,

                });
            })
            .catch((err) => {
                this.setState({
                    err: err,
                    errorOnLoad: true
                })

            })
    }

    onViewCF(id, e) {
        e.target.style.color = '#ccc';
        fetch('/api/getInvNotificationCFbyid/' + id + '/' + this.state.id + '/' + this.props.session.marketing, {
            method: 'get'
        })
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                this.setState({
                    ViewNotificationsCF: data.rows[0],
                    show: !this.state.show,
                    isLoaded: true,
                    err: {},
                    errorOnLoad: false,
                });
            })
            .catch((err) => {
                this.setState({
                    err: err,
                    errorOnLoad: true
                })

            })
    }

    onViewD(id, e) {
        // e.target.style.color = '#ccc';
        document.getElementById(`update${id}`).style.color = '#ccc';
        fetch('/api/getInvNotificationDbyid/' + id + '/' + this.state.id + '/' + this.props.session.marketing, {
            method: 'get'
        })
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                const response = data.rows[0] || {};
                this.setState({
                    ViewNotificationsD: response,
                    show1: !this.state.show1,
                    isLoaded: true,
                    err: {},
                    errorOnLoad: false,

                });
            })
            .catch((err) => {
                this.setState({
                    err: err,
                    errorOnLoad: true
                })

            })
    }

    async checkAccreditation() {
        try {
            const impersonateID = await getImpersonateID();

            const { data } = await RequestService.get(`/api/checkAccreditation/${impersonateID}`);
            if (data.code === 200) {
				this.setState({
					IsAccredited: data.AccreditationStatus,
					WasAccredited: data.PriorAccredStatus
				});
            }
            else {
                console.log(data)
            }
        } catch (err) {
            console.log(err);
            this.setState({
                errorOnLoad: true
            })
        }
    }
    async getMyInvestments() {
        const impersonateID = await getImpersonateID();
		const { data } = await RequestService.get(`/api/myInvestments/${impersonateID}`);
		if (data.code === 200) {
           this.setState({
				allProjectsD: data.reg_d,
			
			});
			
		}
	}
handlePageUrl(){
    const pathname = window.location.pathname.toLocaleLowerCase();
    let landing ;
 if(pathname?.match("app/usernotifications")){
landing =true;}
else landing  = false;
this.setState({landing:landing})
 
}
getUserName = async () => {
    const { session } = this.props;
    const URL = `/api/getusername/${session.id}/${session.marketing}`;
    const {data, status} = await RequestService.get(URL);

    if (status >= HTTP_ERR_BAD_REQUEST) {
        console.error('Error extracting user information in request');
        return;
    }

    const {FirstName, LastName} = data[0];
    this.setState({fullUserName: `${FirstName} ${LastName}`});
};
getVoteResponse = async () => {
    const impersonateID = await getImpersonateID();
		const {data } = await RequestService.get({
			url: `/api/getMergeGPLPVotings/${impersonateID}`,
		});
	   if(data.code ===200){
	
console.log("ddddd",data?.result);
this.setState({voteResponse: data.result});
		return data.result;
		}
};


    render() {
        const EarningsBg ='/img/earnings-banner-bg.jpg';
  const {projectUpdates,fundsWell,bitcoinMines,landing,projectMap,allProjectsD,seeYourFund,fullUserName} =this.state;
//   const closeNoticeModal = () => {
//     this.setState({ noticeModal:false });
//   } 
  const customStyles ={
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "34%",
      padding: "20px 20px 20px 20px",
      overFlow: "auto",
      maxHeight: "100vh",
      background:"white",
      border:0
  
    },
  
  }
        return (
            <div className="container fund-overview pt-4" style={{backgroundImage:`url(${EarningsBg})`}}>
           <div className="col-xs-12 col-md-12">
                <div className="p-3 px-md-5">
                    <div className="prj-update mb-0">
                        <div className="welcome-user pull-left"><span>Welcome, {fullUserName},</span> 
                        {/* { this.state?.voteResponse?.length > 0 ? `You voted ${this.state?.voteResponse[0]?.response} for the request Merge Yield Fund I LP and GP on ${moment(this.state?.voteResponse[0]?.SubmittedDate).format('MM-DD-YYYY')}` : ' to Your EnergyFunders Investor Platform'}  */}
                        </div>
                  	<h1 className="text-white clearfix mb-4 pb-3">Newsfeed</h1>
				</div>
                    <div className="white-bg rounded-top">
                        <div>
                          
                            <Card projectUpdates={projectUpdates} fundsWell={fundsWell} bitcoinMines ={bitcoinMines} projectMap={projectMap} landing={landing} allProjectsD={allProjectsD} seeYourFund={seeYourFund} />
                            <div className="clearfix"/>
                        </div>
                    </div>
                </div>

                {/*PROJECT REG D VIEWER*/}
                <ProjectUpdateViewer showModal={this.state.show1}
                                     closeModal={this.handleCloseD}
                                     projectName={this.state.ViewNotificationsD.ProjectName}
                                     creationDate={this.state.ViewNotificationsD.CreatedDate}
                                     subject={this.state.ViewNotificationsD.Subject}
                                     smallText={this.state.ViewNotificationsD.NotificationSmallText}
                                     longText={this.state.ViewNotificationsD.NotificationText}
                                     coverImage={this.state.ViewNotificationsD.CoverImage}/>

                {/*PROJECT REG CF VIEWER*/}
                <ProjectUpdateViewer showModal={this.state.show}
                                     closeModal={this.handleClose}
                                     projectName={this.state.ViewNotificationsCF.ProjectName}
                                     creationDate={this.state.ViewNotificationsCF.CreatedDate}
                                     subject={this.state.ViewNotificationsCF.Subject}
                                     smallText={this.state.ViewNotificationsCF.NotificationSmallText}
                                     longText={this.state.ViewNotificationsCF.NotificationText}
                                     coverImage={this.state.ViewNotificationsCF.CoverImage}/>

            </div>
            {/* <GPLPVotings getVoteResponse={this.getVoteResponse}/> */}
            {/* <Modal
            onHide={closeNoticeModal}
					show={this.state.noticeModal}
					style={{ width: '40%', margin: '0 auto', top:"25%" }} className="gplp-voting-modal"
				>

						<Modal.Body>
                        <div>
          <b style={{textAlign:'center'}}>IMPORTANT NOTICE:</b> 
          <p>
          Due to payment processing lag times, if you would like your investment to count toward the 2022 tax year, please WIRE YOUR INVESTMENT to us. If you invest via our online portal at this time, the funds may not reach us by year end. Please call us at <a href="tel:7138633297" className='text-green text-decoration-underline'>713-863-3297</a> or email at <a href="mailto: info@energyfunders.com" className='text-green text-decoration-underline'>info@energyfunders.com</a> for details on how to wire your investment.
          </p>
            
        </div>
						</Modal.Body>
						<Modal.Footer>
						<Button onClick={closeNoticeModal}>Close</Button>
						</Modal.Footer>
					</Modal> */}
            </div>
        );
    }
}


const {object, bool} = PropTypes;

Investornotifications.propTypes = {
    session: object.isRequired,
    authenticated: bool.isRequired,
    checked: bool.isRequired
};

const mapState = ({session}) => ({
    session: session.user,
    checked: session.checked,
    authenticated: session.authenticated
});

export default withRouter(withParallelMarketsHOC(connect(mapState)(Investornotifications)));
