import React, {Component,useState} from 'react';
import { NavLink, BrowserRouter as Router, } from 'react-router-dom'
import Icon from '../../IconComponent/IconComponent';
import ImgIcon from '../../IconComponent/img'
import PropTypes from 'prop-types';

const MenuLink = (props) => {
	const {route, iconName,iconHoverName, title, showIcon,pathname} = props;
	const [BgImg, setBgImg] = useState(iconName);
	let { extraClasses} = props;
    extraClasses = extraClasses || '';
	var isActive = pathname === route;
	
	React.useEffect(() => {
		if(isActive){
		setBgImg(iconHoverName)
		}
		else{
			setBgImg(iconName)
		}
	  }, [isActive]);
	return (
		<li>
	<NavLink to={route} onMouseOver={() => !isActive && setBgImg(iconHoverName)}
					onMouseOut={()=>!isActive && setBgImg(iconName)} className='leftIcon'>
	{/* <img src ={isActive ? ImgIcon[iconHoverName] : ImgIcon[iconName]}  */}
					<img src ={ImgIcon[BgImg]} style={ iconName == 'imgIconProjectUpdates'?{ paddingRight:16,paddingLeft:6 } : iconName == 'imgIconEarnings' ?
					 { paddingLeft:1 } : iconName == 'imgIconDocument' ? {paddingLeft:2} : null}    />
				{/* {!showIcon &&
				<div className="row center-block" style={{display: 'inline-flex'}}>
					<Icon iconName={iconName}/>
				</div>} */}
				{/* {showIcon && <span><Icon iconName={iconName} iconHoverName ={iconHoverName}/> {title}</span>} */}
				{showIcon && <span style={ iconName == 'imgIconEarnings' ? {paddingLeft:1} : iconName == 'imgIconDocument' ? {paddingLeft:2} : null } >{title}</span>}
			</NavLink>
		</li>
	);
};



class MenuList extends Component {
	render() {
		var pathname = this.context.router.route.location.pathname;
	let {linkList, showIcon,adminView} = this.props;
		linkList = linkList || [];
	
		return (
			<ul className="nav side-menu">
				{linkList.dashboard?.map((link, index) => <MenuLink key={index} {...link} showIcon={showIcon} pathname ={pathname}/>) }
					{/* {!adminView && <h2>Your Fund</h2>}
				{linkList.yourFund?.map((link, index) => <MenuLink key={index} {...link} showIcon={showIcon} pathname ={pathname}/>)} */}
				{/* {!adminView && <h2>Your Account</h2>} */}
				{linkList.yourAccount?.map((link, index) => <MenuLink key={index} {...link} showIcon={showIcon} pathname ={pathname}/>)}
			{adminView && linkList.admin?.map((link, index) => <MenuLink key={index} {...link} showIcon={showIcon} pathname ={pathname}/>)}
			</ul>
		);
	};
}
MenuList.contextTypes = {
    router: PropTypes.object
};
export default MenuList
