import PropTypes from 'prop-types'
import React from 'react';
import {Modal} from 'react-bootstrap';
import RequestService from "../../../api/RequestService";
import {HTTP_ERR_BAD_REQUEST, HTTP_SUCCESS_CREATED} from "../../../utils/ApplicationConstants";
import NotificationService from "../../../utils/NotificationService";


class AddEntity extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            form: {
                entityName: '',
                entityType: '',
                address: '',
                city: '',
                state: '',
                zipCode: '',
                country: '',
                phone: '',
            }
        };

        this.refEntityName = React.createRef();
        this.refEntityType = React.createRef();
        this.refAddress = React.createRef();
        this.refCity = React.createRef();
        this.refState = React.createRef();
        this.refZipCode = React.createRef();
        this.refCountry = React.createRef();
        this.refPhone = React.createRef();
    }



    onCancelClickHandler = () => {
        this.cleanInputs();
        this.props.closeModal();
    };



    cleanInputs = () => {
        this.refEntityName.current.value = null;
        this.refEntityType.current.value = null;
        this.refAddress.current.value = null;
        this.refCity.current.value = null;
        this.refState.current.value = null;
        this.refZipCode.current.value = null;
        this.refCountry.current.value = null;
        this.refPhone.current.value = null;
    };



    onInputChangeHandler = (event) => {
        const {form} = this.state;
        const {name, value} = event.target;

        form[name] = value;

        this.setState({form});
    };



    onSaveClickHandler = async () => {
        const {form: data} = this.state;
        const {userId} = this.props;
        const url = `/api/user/${userId}/entity`;
        const {status} = await RequestService.post({url, data});

        if (status >= HTTP_ERR_BAD_REQUEST) {
            NotificationService.error("Error creating new entity for user ID: " + userId);
        } else if (status === HTTP_SUCCESS_CREATED) {
            NotificationService.success("New Entity Created Correctly");
            this.onCancelClickHandler();
        }
    };



    render() {
        return (
            <Modal show={this.props.openModal}
                   onHide={this.onCancelClickHandler}
                   className="no-padding">
                <Modal.Header>
                    <Modal.Title className="capitalize-text">
                        Create a new Entity for {this.props.userName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="col-xs-12 col-sm-12 col-md-3"/>
                        <div className="col-xs-12 col-sm-12 col-md-6">
                            <form>
                                <div className="form-group">
                                    <label>Entity Name:</label>
                                    <input type="text"
                                           name="entityName"
                                           onChange={this.onInputChangeHandler}
                                           ref={this.refEntityName} />
                                </div>
                                <div className="form-group">
                                    <label>Entity Type:</label>
                                    <input type="text"
                                           name="entityType"
                                           onChange={this.onInputChangeHandler}
                                           ref={this.refEntityType} />
                                </div>
                                <div className="form-group">
                                    <label>Address:</label>
                                    <input type="text"
                                           name="address"
                                           onChange={this.onInputChangeHandler}
                                           ref={this.refAddress} />
                                </div>
                                <div className="form-group">
                                    <label>City:</label>
                                    <input type="text"
                                           name="city"
                                           onChange={this.onInputChangeHandler}
                                           ref={this.refCity }/>
                                </div>
                                <div className="form-group">
                                    <label>State:</label>
                                    <input type="text"
                                           name="state"
                                           onChange={this.onInputChangeHandler}
                                           ref={this.refState} />
                                </div>
                                <div className="form-group">
                                    <label>ZipCode:</label>
                                    <input type="text"
                                           name="zipCode"
                                           onChange={this.onInputChangeHandler}
                                           ref={this.refZipCode} />
                                </div>
                                <div className="form-group">
                                    <label>Country:</label>
                                    <input type="text"
                                           name="country"
                                           onChange={this.onInputChangeHandler}
                                           ref={this.refCountry} />
                                </div>
                                <div className="form-group">
                                    <label>Phone:</label>
                                    <input type="text"
                                           name="phone"
                                           onChange={this.onInputChangeHandler}
                                           ref={this.refPhone} />
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="container">
                        <div className="col-xs-12 col-sm-12 col-md-3"/>
                        <div className="col-xs-12 col-sm-12 col-md-6">
                            <div className="pull-left">
                                <button className="btn btn-danger capitalize-text"
                                        onClick={this.onCancelClickHandler}>
                                    cancel
                                </button>
                                <button className="btn btn-primary capitalize-text"
                                        onClick={this.onSaveClickHandler}>
                                    save
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    };
}


AddEntity.propTypes = {
    closeModal: PropTypes.func.isRequired,
    openModal: PropTypes.bool.isRequired,
    userId: PropTypes.number.isRequired,
    userName: PropTypes.string.isRequired
};

AddEntity.defaultProps = {
    closeModal: () => null,
    openModal: false,
    userId: 0,
    userName: ''
};

export default AddEntity;
