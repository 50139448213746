/**
 * Created by goutham on 9/13/2017.
 */
import React, {Component} from 'react';
import store from './index'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import swal from 'sweetalert2';
import { withRouter } from 'react-router-dom';
import RequestService from './api/RequestService';


class EntityAcc extends Component {
    constructor(props){
        super(props);
        this.state={
             form: {
                    empName:'',
                    empPosition:'',
                    whyInvesting:'',
                    verification1:'',
                    verification2:'',
                    verification3:'',
                    two_back_year_income:-1,
                    last_year_income:-1,
                    projected_current_year_income:-1,
                    net_worth:-1,
                    length_employement:-1,
                    invest:-1,
                },
                id:props.session.id,
                marital_status: "",
                representative: 0,
                isLoaded:false,
                errorOnLoad:false,
                EntityID:props.location.state.EntityID
        }        
        this.onSubmit=this.onSubmit.bind(this);
        this.setmaritalS = this.setmaritalS.bind(this);
        this.representativeCheck = this.representativeCheck.bind(this);
        this.fullFormValidation = this.fullFormValidation.bind(this);
    }

    representativeCheck(e) {
        this.setState({
            representative: (e.target.checked) ? 1 : 0
        });

    }
    async onSubmit(e) {
        e.preventDefault();
        if(this.refs.Tbincome.value !=-1 || this.refs.Lyincome.value !=-1||this.refs.Pcyincome.value !=-1||this.refs.nworth.value !=-1||this.refs.loemployment.value == -1){
        let checkFields = 1;
        checkFields = this.fullFormValidation(); 
        console.log(checkFields, 'Initial check');
           if(checkFields) {
          console.log('Please check all fields');
        } else {
        if (document.getElementById('verification1').checked && document.getElementById('verification2').checked && document.getElementById('verification3').checked) {
            var formBody = [];
            formBody.push("UserID=" + this.state.id);
            formBody.push("EntityID=" + this.state.EntityID);
            formBody.push("employerName=" + encodeURIComponent(this.refs.eName.value));
            formBody.push("employeePosition=" + encodeURIComponent(this.refs.ePosition.value));
            formBody.push("marital_status=" + encodeURIComponent(this.state.marital_status));
            formBody.push("Two_back_year_income=" + encodeURIComponent(this.refs.Tbincome.value));
            formBody.push("Last_year_income=" + encodeURIComponent(this.refs.Lyincome.value));
            formBody.push("Projected_current_year_income=" + encodeURIComponent(this.refs.Pcyincome.value));
            formBody.push("net_worth=" + encodeURIComponent(this.refs.nworth.value));
            formBody.push("last_year_net_worth=" + encodeURIComponent(""));
            formBody.push("length_of_employment=" + encodeURIComponent(this.refs.loemployment.value));
            formBody.push("representative=" + encodeURIComponent(this.state.representative));
            formBody.push("estimatedInvestment=" + encodeURIComponent(this.refs.estimatedInvestment.value));
            formBody.push("investmentsFor=" + encodeURIComponent(this.refs.investmentsFor.value));
            formBody.push("verification_1=" + encodeURIComponent(this.refs.verification_1.value));
            formBody.push("verification_2=" + encodeURIComponent(this.refs.verification_2.value));
            formBody.push("verification_3=" + encodeURIComponent(this.refs.verification_3.value));
            formBody.push("typeCheck=2");
            formBody = formBody.join("&");

            try {
                const { data: status } = await RequestService.post({
                    url: '/api/Addaccreditedentites',
                    data: formBody,
                });
                if (status.code === 200) {
                    swal(status.success,"","success")
                        .then((next) => {
                            if (next) {
                                this.props.history.push({
                                    pathname: '/app/investorDashboard',
                                    state: { EntityID:this.state.EntityID }
                                  })
                            }
                        })
                }
                else if (status.code === 300) {
                    swal("Welcome to EnergyFunders.","","success")
                        .then((next) => {
                            if (next) {
                                this.props.history.push({
                                    pathname: '/app/investorDashboard',
                                    state: { EntityID:this.state.EntityID }
                                  })
                            }
                        })
                }
                else if (status.code === 400) {
                    swal("Error", "", "error")
                        .then(() => {
                            this.props.history.push({
                                pathname: '/app/setupprofile2'
                              })
                        });
                }
            } catch (err) {
                console.log(err);
            }
        } else {
            alert('Please check all terms');
        }
    }
        }
        else{
            swal("Please Select Your Income.","","success");
        }

    }
    setmaritalS(e) {
        this.setState({
            marital_status: e.target.value
        })
    }
 async inputChange(e) {
     let property = e.target.id;
     let value = e.target.value;
     let error = '';
     let prev = await this.state.form;
     prev[property] = await value;
     await this.setState({
       form : prev
     })
     error = this.inputValidation(property, value);    
     document.getElementById('error_'+property).innerHTML = error;
   }
   inputValidation(property, value) {
     let error = '';
     switch (property) {        
       case 'City':
         if (value < 0) {
           error = 'Please select City';
         }
         break;
       default:
         if (value == '' || value < 0) {
           error = 'This field is Required';
         }
         break;
     }  
     return error;
   }
   fullFormValidation() {
      let checkFields = 0;
      let object = this.state.form;
      for (const key in object) {
        if (object.hasOwnProperty(key)) {
          let error = this.inputValidation(key, object[key]);
          document.getElementById('error_' + key).innerHTML = error;
          if(error != '') {
            checkFields = 1;
          }          
        }
      }
      return checkFields;
    }
    componentWillMount(){
        window.scrollTo(0, 0);
        store.subscribe(() => {
            var currentState=store.getState();
            this.setState({
                id:currentState.session.user.id
            });
        });
    }


    render() {
        const { marital_status } = this.state;
        return (
            <div className="container body">
                <div className="main_container">
                    <div className="right_col" role="main">
                        <div className="Mainstep">
                            <div className="container">
                                <div className="row">
                                    <ul className="breadcrumb">
                                        <li className="completed1"><a href="#">Entity</a></li>
                                        <li className="completed1"><a href="#">Accredited Entity</a></li>
                                        <li ><a href="#">Dwolla</a></li>
                                        <li ><a href="#">Step4</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <br/>
                        <div className="stepContainer"><div id="step-1" className="content" style={{display: 'block'}}>
                            <div className="panel panel-primary">
                                <div className="l-main">
                                    <div className="l-main-h">
                                        {/*  Accreditation form */}
                                        <section id className="l-section">
                                            <div className="l-subsection">
                                                <div className="l-subsection-h">
                                                    <div className="l-subsection-hh g-html i-cf" style={{ marginTop: 40 }}>
                                                        <form id="accreditation_form" className="ef_form" >
                                                            <div style={{ maxWidth: 750, marginBottom: 45 }}>
                                                                <h2>Accreditation</h2>
                                                                <p>The following form is necessary to comply with securities regulation. Please help us comply with the law by completing the form below.</p>
                                                            </div>
                                                            <div className="w-form contacts_form" style={{ maxWidth: 'none' }}>{/* contacts_form */}
                                                                <hr />
                                                                <div className="g-cols ef_form_first_row">
                                                                    <div className=" col-md-4 col-sm-4 col-xs-12">
                                                                        <h5 className="highlight" style={{ marginBottom: 10 }}>Income</h5>
                                                                        <div id className="g-form-row">
                                                                            <div className="accreditation_inline">
                                                                                <input type="radio" name="marital_status" id="marital_status" value={"single"} ref="mstatus" checked={marital_status == "single"} onClick={this.setmaritalS} />Single
                                                        </div>
                                                                            <div className="accreditation_inline">
                                                                                <input type="radio" name="marital_status" id="marital_status" value={"married"} ref="mstatus" checked={marital_status == "married"} onClick={this.setmaritalS} />Joint Spouse
                                                        </div>
                                                         <span id="error_marital_status" className="text-danger" />
                                                                        </div>
                                                                        <div id className="g-form-row">
                                                                            <select name="two_back_year_income" ref="Tbincome" id="two_back_year_income" onBlur = {(e) => this.inputChange(e)} onChange={(e) => this.inputChange(e)}>
                                                                                <option value="-1">2016 Income</option>
                                                                                <option value="Less Than $200,000">Less Than $200,000</option>
                                                                                <option value="From $200,000 to $300,000">From $200,000 to $300,000</option>
                                                                                <option value="Greater Than $300,000">Greater Than $300,000</option>
                                                                            </select>
                                                                            <span id="error_two_back_year_income" className="text-danger" />
                                                                        </div>
                                                                        <div id className="g-form-row">
                                                                            <select name="last_year_income" ref="Lyincome" id="last_year_income" onBlur = {(e) => this.inputChange(e)} onChange={(e) => this.inputChange(e)}>
                                                                                <option value="-1">2017 Income</option>
                                                                                <option value="Less Than $200,000">Less Than $200,000</option>
                                                                                <option value="From $200,000 to $300,000">From $200,000 to $300,000</option>
                                                                                <option value="Greater Than $300,000">Greater Than $300,000</option>
                                                                            </select>
                                                                            <span id="error_last_year_income" className="text-danger" />
                                                                        </div>
                                                                        <div id className="g-form-row">
                                                                            <select name="projected_current_year_income" ref="Pcyincome" id="projected_current_year_income" onBlur = {(e) => this.inputChange(e)} onChange={(e) => this.inputChange(e)}>
                                                                                <option value="-1">Projected 2018 Income</option>
                                                                                <option value="Less Than $200,000">Less Than $200,000</option>
                                                                                <option value="From $200,000 to $300,000">From $200,000 to $300,000</option>
                                                                                <option value="Greater Than $300,000">Greater Than $300,000</option>
                                                                            </select>
                                                                            <span id="error_projected_current_year_income" className="text-danger" />
                                                                        </div>
                                                                        <br />
                                                                        <h5 className="highlight" style={{ marginBottom: 10 }}>Net Worth <span style={{ fontSize: 12 }}>(Excluding Primary Residence)</span></h5>
                                                                        <div id className="g-form-row">
                                                                            <select name="net_worth" ref="nworth" id="net_worth" onBlur = {(e) => this.inputChange(e)} onChange={(e) => this.inputChange(e)}>
                                                                                <option value="-1">Select Net Worth</option>
                                                                                <option value="Less Than $500,000">Less Than $500,000</option>
                                                                                <option value="From $500,000 to $999,999">From $500,000 to $999,999</option>
                                                                                <option value="From $1,000,000 to $1,500,000">From $1,000,000 to $1,500,000</option>
                                                                                <option value="From $1,500,000 to $2,500,000">From $1,500,000 to $2,500,000</option>
                                                                                <option value="From $2,500,000 to $5,000,000">From $2,500,000 to $5,000,000</option>
                                                                                <option value="Greater Than $5,000,000">Greater Than $5,000,000</option>
                                                                            </select>
                                                                             <span id="error_net_worth" className="text-danger" />
                                                                        </div>
                                                                        <p />
                                                                        <hr />
                                                                    </div>
                                                                    <div className=" col-md-4 col-sm-4 col-xs-12 accreditation_middle_col">
                                                                        <h5 className="highlight">Employment</h5>
                                                                        <div id className="g-form-row">
                                                                            <input type="text" id="empName" ref="eName" name="empName" placeholder="Employer Name" onBlur = {(e) => this.inputChange(e)} onChange={(e) => this.inputChange(e)} />
                                                                            <span id="error_empName" className="text-danger" />
                                                                        </div>
                                                                        <div id className="g-form-row">
                                                                            <input type="text" id="empPosition" ref="ePosition" name="empPosition" placeholder="Current Position" onBlur = {(e) => this.inputChange(e)} onChange={(e) => this.inputChange(e)}/>
                                                                            <span id="error_empPosition" className="text-danger" />
                                                                        </div>
                                                                        <div id className="g-form-row">
                                                                            <select name="length_of_employment" id="length_employement" ref="loemployment"  onBlur = {(e) => this.inputChange(e)} onChange={(e) => this.inputChange(e)}>
                                                                                <option value="-1">Length of Employment</option>
                                                                                <option value="0-1 Years">0-1 Years</option>
                                                                                <option value="2 Years">2 Years</option>
                                                                                <option value="3 Years">3 Years</option>
                                                                                <option value="4 Years">4 Years</option>
                                                                                <option value="5 Years">5 Years</option>
                                                                                <option value="More Than 5 Years">More Than 5 Years</option>
                                                                            </select>
                                                                             <span id="error_length_employement" className="text-danger" />
                                                                        </div>
                                                                        <hr />
                                                                    </div>
                                                                    <div className=" col-md-4 col-sm-4 col-xs-12">
                                                                        <h5 className="highlight">I am <span> (Optional)</span></h5>
                                                                        <div id className="g-form-row">
                                                                            <div className="ef_form_checkbox_stack">
                                                                                <input type="checkbox" name="representative" onChange={this.representativeCheck} defaultValue="check" id="agree" />A representative of a bank, insurance company, registered investment company, business development company or small business investment company, interested in investment opportunities.
                                                                                       <span id="error_agree" className="text-danger" />
                                                        </div>

                                                                        </div>
                                                                        <hr />
                                                                    </div>
                                                                </div>
                                                                <h5 className="highlight">Investing</h5>
                                                                <div id className="g-form-row">
                                                                    <div id="invest_amount" className="g-form-row">
                                                                        <div className="g-form-row-label">
                                                                            <label htmlFor="email" className="g-form-row-label-h">I'd like to invest around (estimate)</label>
                                                                        </div>
                                                                        <select ref="estimatedInvestment" id="invest" name="initial_investment_estimate" onBlur = {(e) => this.inputChange(e)} onChange={(e) => this.inputChange(e)}>
                                                                            <option value="-1">Select</option>
                                                                            <option value="100">$100</option>
                                                                            <option value="500">$500</option>
                                                                            <option value="1,000">$1,000</option>
                                                                            <option value="5,000">$5,000</option>
                                                                            <option value="10,000">$10,000</option>
                                                                            <option value="25,000">$25,000</option>
                                                                            <option value="50,000">$50,000</option>
                                                                            <option value="50,000">Over $50,000</option>
                                                                            <option value="Help me figure it out.">Help me figure it out.</option>
                                                                        </select>
                                                                         <span id="error_invest" className="text-danger" />
                                                                    </div>
                                                                    <div className="ef_form_checkbox_stack">
                                                                        What are you looking for in your investments? <input type="text" ref="investmentsFor" name="whyInvesting" id="whyInvesting" onBlur = {(e) => this.inputChange(e)} onChange={(e) => this.inputChange(e)} />
                                                                         <span id="error_whyInvesting" className="text-danger" />
                                                                    </div>
                                                                </div>
                                                                {/*<hr />*/}
                                                                <h5 className="highlight">Verification</h5>
                                                                <div id className="g-form-row">
                                                                    <div className="ef_form_checkbox_stack">
                                                                        <input id="verification1" type="checkbox" ref="verification_1" name="electronic_consent" value="I consent to receiving electronic delivery of all documents." onBlur = {(e) => this.inputChange(e)} onChange={(e) => this.inputChange(e)}/>I consent to receiving electronic delivery of all documents.
                                                                     <span id="error_verification1" className="text-danger" />
                                                                    </div>
                                                                    <div className="ef_form_checkbox_stack">
                                                                        <input id="verification2" type="checkbox" ref="verification_2" name="verification_1" value="I am over 18 years old and have sufficient knowledge in investing and finance to effectively evaluate the risks of investments in oil and gas.  I understand I could lose my entire investment and represent that I can bear such loss." onBlur = {(e) => this.inputChange(e)} onChange={(e) => this.inputChange(e)}/>I am over 18 years old and have sufficient knowledge in investing and finance to effectively evaluate the risks of investments in oil and gas.  I understand I could lose my entire investment and represent that I can bear such loss.
                                                                 <span id="error_verification2" className="text-danger" />
                                                                </div>
                                                                    <div className="ef_form_checkbox_stack">
                                                                        <input id="verification3" type="checkbox" ref="verification_3" name="verification_2" value="  I declare under penalty of perjury and warrant that the above information is accurate and truthful, and represent that EnergyFunders can rely on the above information (a) to determine  whether I am, or the entity I represent is, an Accredited Investor, and (b) to meet its compliance obligations under federal securities laws and SEC regulations." onBlur = {(e) => this.inputChange(e)} onChange={(e) => this.inputChange(e)}/>
                                                                        I declare under penalty of perjury and warrant that the above information is accurate and truthful, and represent that EnergyFunders can rely on the above information (a) to determine  whether I am, or the entity I represent is, an Accredited Investor (as defined <a href="http://www.sec.gov/answers/accred.htm" target="_blank">here</a>), and (b) to meet its compliance obligations under federal securities laws and SEC regulations.
                                                                <span id="error_verification3" className="text-danger" />
                                                                </div>
                                                                </div>
                                                                <hr />
                                                            </div>{/*end contacts_form */}
                                                            <div className="g-form-row">
                                                                <div className="g-form-row-label" />
                                                                <div className="g-form-row-field">
                                                                    <button id="message_send" type="submit" name="submit" onClick={this.onSubmit} value="Submit" className="g-btn type_secondary"><span>Next</span></button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>{/*  End Accreditation form */}
                                    </div>
                                </div>
                                
                                    
                            </div>
                        </div>

                        </div></div>
                </div></div>



        )
    }
}

const { object, bool } = PropTypes;

EntityAcc.propTypes = {
    session: object.isRequired,
    authenticated: bool.isRequired,
    checked: bool.isRequired
};

const mapState = ({ session }) => ({
    session: session.user,
    checked: session.checked,
    authenticated: session.authenticated
});

export default withRouter(connect(mapState)(EntityAcc));
