import React, { Component } from "react";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as AllInvestmentActions from "../shared/AllInvestmentsContainerComponent/actions/actions";
import { PROJECT_REG_D } from "../../utils/ApplicationConstants";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import "./css/investmentOpportunities.css";
import { ReactSVG } from "react-svg";
import OurTeam from "../shared/OurTeam/OurTeam";
import { Bitcoin_Project_ID } from '../../utils/General';
class BitcoinDiscoveryFund extends Component {
	constructor(props) {
		super(props);
		this.state = {
			projects: [],
			selectedProjectCategory: "all",
		};
	}

	componentDidMount() {
		const { getAllInvestments } = this.props;
		getAllInvestments(this.state.selectedProjectCategory);
		window.scrollTo(0,0);
	}

	onSeeDetailsClickHandler = () => {
		const { push } = this.props.history;
		const { activeRegDInvestments } = this.props.allInvestments;
		const pushStateObj = {
			state: {
				id: activeRegDInvestments[0]?.projectID,
				projectType: PROJECT_REG_D,
			},
			pathname: "/app/project/details",
		};
		push(pushStateObj);
	};
	render() {
		const agreement = "/img/investmentopportunities/agreement.png";
		const bitcoinSummary =
			"/img/investmentopportunities/bitcoin-summary.png";
		const bitcoinFinancialProjections =
			"/img/investmentopportunities/bitcoin-financial-projections.png";
		const documentIcon = "/img/document.svg";
		const ProjectUpdatesIcon = "/img/investmentopportunities/oilwell.svg";
		const btc = "/img/investmentopportunities/btc.svg";
		return (
			<div className="container body">
				<div className="main_container">
					<div
						className="right_col invest-opportunities-detail"
						role="main"
					>
						<div className="col-md-12 col-sm-12 col-xs-12 px-0">
							<h2>Bitcoin Discovery Fund</h2>
						</div>

						<div className="col-md-12 col-sm-12 col-xs-12 px-0">
							<div className="col-md-8 v-line pr-md-5 pl-0">
								<p>
									As an investor in the Bitcoin Discovery
									Fund, you will become a fractional owner of
									bitcoin mines, allowing you to accumulate
									bitcoin at potentially below-market rates.
									This fund operates bitcoin mines that will
									regularly discover new bitcoin. This bitcoin
									is then distributed periodically to all
									investors in the Bitcoin Discovery Fund.<br/><br/> The
									Fund deploys mobile, modular mining units
									located at natural gas wellsites; we convert
									wellhead natural gas into electricity for
									powering our mines.<br/><br/> Our mobile mining
									approach benefits from cost-advantaged
									electricity, produced from natural gas
									sourced directly at the wellhead. This
									enables higher profit margins, while also
									providing a stable source of electricity
									supply, independent of the grid.<br/><br/> This fund
									offers regular payouts (in either USD or
									BTC, you choose), plus potential protection
									from inflation and high IRRs.
								</p>
							</div>
							<div className="col-md-4 pl-md-5 px-0">
								<div className="pl-md-5 w-75">
									<div className="mt-0">
										<h3>What Are You Investing In?</h3>
										<div className="progress">
											<div className="progress-bar d-flex flex-row align-items-center justify-content-center fill bold text-left py-3 px-3">
											<ReactSVG src="/img/investmentopportunities/btc.svg"  />
											<span className="pl-4">
													100% Bitcoin
												</span>
											</div>
										</div>
									</div>
									<div className="mt-5">
										<h3>Ready to Invest?</h3>
										<button
											className="btn btn-primary btn-green px-3 py-4 w-100 border-0 progress"
											onClick={ () =>   this.props.history.push({
												pathname: '/app/invest',
												state: {
													ProjectType: 'RegD',
													ProjectID: Bitcoin_Project_ID,
													
												}
											})
												
											}
										>
											Start Investing Now
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-12 col-sm-12 col-xs-12 px-0 mt-5">
							<h3>The Highlights</h3>
							<ul className="highlights list-unstyled d-flex">
								<li>
									<ReactSVG src="/img/time.svg" />
									<span>3-5 Years</span> Target Fund Life
								</li>
								<li>
									<ReactSVG src="/img/piechart.svg" />
									<span>Periodic</span> Payments
								</li>
								<li>
									<ReactSVG src="/img/risklevel.svg" />
									<span>High</span> Risk Level
								</li>
								{/* <li>
									<ReactSVG src="/img/investmentopportunities/chart.svg" />
									<span></span>
								</li> */}
								<li>
									<ReactSVG src="/img/money.svg" />
									<span>$5,000</span> Minimum Investment
								</li>

								<li>
									<ReactSVG src="/img/investmentopportunities/diversification.svg" />
									<span>Diversification</span> Diversify Your Portfolio with Crypto
								</li>
								<li>
									<ReactSVG src="/img/investmentopportunities/screen.svg" />
									<span>Investor Platform </span> See Well
									Videos, Pics &amp; Details on Your Funds
								</li>
								<li>
									<ReactSVG src="/img/investmentopportunities/handshake.svg" />
									<span>Potential Liquidity </span> Via tZERO
									Trading Platform
								</li>
								<li>
									<ReactSVG src="/img/investmentopportunities/flexible-payout.svg" />
									<span>Flexibility</span> Choose to Receive Payouts in BTC or USD.
								</li>
								<li>
									<ReactSVG src="/img/investmentopportunities/certificate.svg" />
									<span>Technical Expertise</span> A Strong Vetting Process
								</li>
							</ul>
						</div>
						<div className="col-md-12 col-sm-12 col-xs-12 px-0 mt-5 list">
							<div className="col-md-6">
								<h3>Why Invest in Bitcoin Mining?</h3>
								<ul>
									<li>
										Investing in bitcoin offers a way to
										earn potentially high returns while
										supporting the future of technology
										while enjoying the potential for
										passive-income tax deductions.
									</li>
									<li>
										Blockchain technology underlying bitcoin
										is hailed as a potential gamechanger for
										many industries, offering new economic
										activities that were not possible
										before.
									</li>
									<li>
										Companies and countries around the world
										continue to accept bitcoin as a valid
										currency and payment method, with
										credibility growing daily.
									</li>
									<li>
										Bitcoin is a digital currency with high
										market demand and excellent liquidity
										due to worldwide 24/7 trading platforms.
									</li>
								</ul>
							</div>
							<div className="col-md-6">
								<h3>What is Bitcoin Mining?</h3>
								<ul>
									<li>
										Bitcoin mining is the process by which
										new bitcoin are entered into
										circulation. There is an ultimate fixed
										total of 21 million bitcoin available
										globally.
									</li>
									<li>
										Mining is performed using hardware that
										solves extremely complex math problems.
										If a computer finds a solution to the
										math problem, it is awarded a block of
										bitcoins, which creates instant
										financial value.
									</li>
									<li>
										EnergyFunders runs bitcoin mines by
										using natural gas directly from the
										wellhead at our gas wells. This gas is
										routed to a generator at the well site
										which powers the bitcoin mine, as these
										mines require massive electrical power.
									</li>
									<li>
										This off-grid source of inexpensive
										electricity insulates EnergyFunders
										against power outages, higher energy
										prices, and reliance on the electrical
										grid.
									</li>
								</ul>
							</div>
						</div>
						<div className="col-md-12 col-sm-12 col-xs-12 px-0 mt-5 fee-detail">
							<h3>The Fees</h3>
							<div className="col-md-7">
								<table className="table table-striped jambo_table fee-table">
									<thead>
										<tr>
											<th>
												If the Capital Contribution is…
											</th>
											<th>
												The Origination Fee Shall be…
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>Less than $100,000</td>
											<td>
												3.00% of the Capital
												Contribution
											</td>
										</tr>
										<tr>
											<td>
												At least $100,000 but less than
												$250,000
											</td>
											<td>
												2.50% of the Capital
												Contribution
											</td>
										</tr>
										<tr>
											<td>
												At least $250,000 but less than
												$500,000
											</td>
											<td>
												1.75% of the Capital
												Contribution
											</td>
										</tr>
										<tr>
											<td>
												Equal to or greater than
												$500,000
											</td>
											<td>
												1.00% of the Capital
												Contribution
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="col-md-5">
								<h4 className="mt-0">
									Assets Under Management Fee{" "}
								</h4>
								<p>
									2% assets under management fee calculated
									annually and charged monthly over the course
									of the year.
								</p>
								<h4 className="mt-4">Distributions</h4>
								<p>
									All the profits of the company, after fees
									and other expenses, will be distributed as
									follows: First, to Investors until each
									Investor has received an internal rate of
									return of 15%. And second, 80% to Investors
									and 20% to the Manager.
								</p>
							</div>
						</div>
						<div className="col-md-12 col-sm-12 col-xs-12 px-0 mt-5 documents">
							<h3>The Documents</h3>
							<ul className="list-unstyled">
								<li>
									<div
										className="img-wrapper"
										style={{
											backgroundImage: `url(${agreement})`,
										}}
									></div>
									<h3>LLC Agreement</h3>
									<div className="mt-4">
										<a href="/documents/bitcoindiscoveryfund/LLC-Agreement-Bitcoin-Discovery-Fund-I-LLC-Final.pdf" target="_blank">
											<ReactSVG src={documentIcon} />
											View The Document
										</a>
									</div>
								</li>
								<li>
									<div
										className="img-wrapper"
										style={{
											backgroundImage: `url(${agreement})`,
										}}
									></div>
									<h3>Disclosure Document</h3>
									<div className="mt-4">
									<a href="/documents/bitcoindiscoveryfund/disclosure-document-bitcoinDiscovery-fund-i-llc-final.pdf" target="_blank">
											<ReactSVG src={documentIcon} />
											View The Document
										</a>
									</div>
								</li>
								<li>
									<div
										className="img-wrapper"
										style={{
											backgroundImage: `url(${agreement})`,
										}}
									></div>
									<h3>Investment Agreement</h3>
									<div className="mt-4">
									<a href="/documents/bitcoindiscoveryfund/Investment-Agreement-Bitcoin-Discovery-Fund-I-LLC-Final.pdf" target="_blank">
											<ReactSVG src={documentIcon} />
											View The Document
										</a>
									</div>
								</li>
								<li>
									<div
										className="img-wrapper"
										style={{
											backgroundImage: `url(${bitcoinSummary})`,
										}}
									></div>
									<h3>Bitcoin Discovery Fund Summary PDF</h3>
									<div className="mt-4">
									<a href="/documents/bitcoindiscoveryfund/Bitcoin-Discovery-Fund-Overview.pdf" target="_blank">
											<ReactSVG src={documentIcon} />
											View The Document
										</a>
									</div>
								</li>
								<li>
									<div
										className="img-wrapper"
										style={{
											backgroundImage: `url(${bitcoinFinancialProjections})`,
										}}
									></div>
									<h3>
										Bitcoin Discovery Fund Financial
										Projections
									</h3>
									<div className="mt-4">
									<a href="/documents/bitcoindiscoveryfund/Bitcoin-Discovery-Financial-Projectionsv5_Artboard2.pdf" target="_blank">
											<ReactSVG src={documentIcon} />
											View The Document
										</a>
									</div>
								</li>
							</ul>
						</div>
						{/* <OurTeam /> */}
						<div className="col-md-12 col-sm-12 col-xs-12  my-5 wildcat-footer ">
							<div className="pull-left d-flex align-items-center left-sec">
								<ReactSVG src={btc} />{" "}
								<span>Bitcoin Discovery Fund</span>
							</div>
							<div className="pull-right rt-sec">
								<button
									className="btn btn-primary  px-5 py-4 ml-3 font-bold"
									onClick={ () =>   this.props.history.push({
										pathname: '/app/invest',
										state: {
											ProjectType: 'RegD',
											ProjectID: Bitcoin_Project_ID,
											
										}
									})
										
									}
								>
									Start Investing Today
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const { object, bool } = PropTypes;

BitcoinDiscoveryFund.propTypes = {
	session: object.isRequired,
	allInvestments: object.isRequired,
};
const mapDispatchToProps = (dispatch) => {
	const allInvestmentActions = bindActionCreators(
		AllInvestmentActions,
		dispatch
	);
	return { ...allInvestmentActions };
};
const mapStateToProps = (_state) => ({
	session: _state.session.user,
	allInvestments: _state.allInvestments,
});

const connectedComponent = connect(
	mapStateToProps,
	mapDispatchToProps
)(BitcoinDiscoveryFund);

export default withRouter(connectedComponent);
