import React, { Component } from "react";
import Pagination from 'react-js-pagination';
import moment from 'moment';
import HelloSign from 'hellosign-embedded';
import Icon from "../IconComponent/IconComponent";
import RequestService from "../../../api/RequestService";
import {BiRefresh} from 'react-icons/bi'
import swal from 'sweetalert';

window.HelloSign = HelloSign;

export default class ContractDocuments extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activePage: 1,
            pageItems: [],
            width: window.innerWidth, height: window.innerHeight,
            refreshingDoc:''
        };
    }
    updateDimensions = () => {

        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };
    componentDidMount() {
        const { setResetMethod } = this.props;
        const { activePage } = this.state;

        this.onPageChange(activePage);

        setResetMethod(this.onPageChange);

        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    componentDidUpdate(prevProps) {
        const { activePage } = this.state;
        const { contractFileList } = this.props;
        const strProjectFileList = JSON.stringify(contractFileList);
        const _projectFileList = JSON.stringify(prevProps.contractFileList);

        if (strProjectFileList !== _projectFileList) {
            this.onPageChange(activePage);

        }

    }

    onPageChange = (activePage) => {
        const { contractFileList,showInvestorData } = this.props;
        const start = (activePage - 1) * this.props.ITEMS_PER_PAGE;
        const end = (start + this.props.ITEMS_PER_PAGE);
        const slice = showInvestorData ? contractFileList?.filter(item => (item?.event_type != 'W8/W9 Form') && (Number(item?.InvestmentAmount) > 0) )?.slice(start, end) : contractFileList?.filter(item => (item?.event_type == 'W8/W9 Form') ||((item?.event_type != 'W8/W9 Form') && (Number(item?.InvestmentAmount) > 0)) )?.slice(start, end) ;
        this.setState({
            pageItems: slice,
            activePage,
        });
    };

    render() {
        const { activePage, pageItems } = this.state;
        const { contractFileList, showInvestorData,getAllContractFiles } = this.props;
        const projectListSize = contractFileList?.length;
        const signContract = async(signature_request_id,adminSignatureId) => {
            // console.log(signature_request_id,"--",adminSignatureId);
            
        const Url = `/api/getSignUrl/${adminSignatureId}`;
        const  result  = await RequestService.get(Url);
        // console.log("signing url status:",result.data);

        if(result.data.code === 200) {
        console.log("Inside If on open hello sign");
            HelloSign.init(result.data?.data?.ClientID);
            HelloSign.open({
                url: result.data?.data?.embedded?.sign_url,
                skipDomainVerification: result?.data?.data?.skipDomainVerification,
                allowCancel: true,
                uxVersion: 2
            });
        }else{
			alert('Document already signed');
            console.log("Can't get signing url data:",result);
        }
        }
        const refresDoccument = async(signature_request_id,userId) => {
            this.setState({refreshingDoc:signature_request_id});
            const {data} = await RequestService.get(`/api/checkAndUpdateContract/${signature_request_id}/${userId}`);
            console.log("dd",data?.data);
            if(data?.data == 1){
                console.log("nothing changes");
                this.setState({refreshingDoc:""});
                swal("Already Up-to-date", "", "success");
            }else{
               await getAllContractFiles();
               this.setState({refreshingDoc:""});
               swal("Form Updated Successfully", "", "success")

            }
        }
        // console.log('contractFileList', pageItems);

        return <>
            {this.state.width > 767 ? <>
                <div className="table-responsive" id={this.props.gotoLink}>
                    <table className="table table-striped jambo_table">
                        <thead>
                            <tr>
                           { showInvestorData ? (<><th>Inv ID</th>
                            <th>Investor</th> </>) : null }
                                <th>Investment</th>
                                <th>Created At</th>
                                {showInvestorData ? <>
                                <th>Completed</th><th>Third-Party Accredited</th>
                                <th>Accreditation Expires On</th> </> : null }
                                <th>Download Files</th>
                            </tr>
                        </thead>
                        <tbody>



                            {pageItems?.map(doc => {
                                // console.log("doc",doc);
                                const expiryDays = (date)=> {
                                    let date_string = date?.toString();
                                    var expiration = moment(date_string).format("YYYY-MM-DD");
                                    var current_date = moment().format("YYYY-MM-DD");
                                    var days = moment(expiration).diff(current_date, 'days');
                                    console.log("remaining days",days);
                                    return days;
                                  }
                                  let adminSignatures;
                                  try {
                                    const parsedData = JSON.parse(doc?.callback_data)
                                    adminSignatures = parsedData.signature_request?.signatures[1];
                                  } catch(err) {
                                    console.log("error parsing ", doc?.callback_data, err)
                                  }
                                //   console.log("admin signatures",adminSignatures);
                                return (
                                    <tr key={doc?.signature_request_id} style={ (doc?.is_complete != 1 && doc.IsThirdPartyAccredited != 1 || (doc?.tpAccreditationExpiresAt && expiryDays( doc?.tpAccreditationExpiresAt ) < 0)) ? {color:"red"} : doc?.is_complete != 1 ? {color:"cornflowerblue"} : null}>
                                       { showInvestorData ? <> <td style={{width:'auto'}}>{`${ doc?.UserID }`}</td>
                                        <td>{`${ doc.FirstName } ${ doc.LastName }`} <br></br> {doc.Email} </td> </> : null}
                                        <td>{`${doc?.title}`}<br></br> {doc?.event_type != 'W8/W9 Form' ? <><b>${Number(doc?.InvestmentAmount).toLocaleString()}</b> by {doc?.Entity}<br></br>{doc?.ProjectName == 'Wildcat Pioneer Fund I LLC' ? 'Drilling Fund I LLC' : doc?.ProjectName}</> : null}</td>
                                        <td>{`${moment(doc?.created_at).format('MM-DD-YYYY')}`}</td>
                                        {showInvestorData ? <>
                                        <td>{doc?.is_complete == 1 ? 'Yes' : 'No'}</td>
                                        <td>{ doc?.IsThirdPartyAccredited == 1 ? `Yes` : `No` }</td>
                                        <td>{ doc?.tpAccreditationExpiresAt ? `${moment(doc?.tpAccreditationExpiresAt).format('MM-DD-YYYY')}` : ''  }</td> </> : null }
                                        <td>
                                        {showInvestorData ? (
                                    <div className="dropdown">
                                        <button
                                            className="btn btn-primary btn-sm no-margin dropdown-toggle"
                                            type="button"
                                            data-toggle="dropdown"
                                        >
                                            <Icon iconName="chevron-down" extraClasses="white-text" />
                                            {' '}
                                            Actions
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li>
                                            <a
                                                className="capitalize-text cursor-pointer"
                                                href={`${doc?.file_url}`}
                                                target="_blank"
                                            > Download
                                            </a>
                                            </li>

                                            <React.Fragment>
                                                <li style = { doc?.is_complete != 1 && adminSignatures && adminSignatures?.status_code == 'awaiting_signature' ? null : {display: 'none'}  }>
                                                    <a
                                                        className="capitalize-text"
                                                        onClick={() => signContract(doc?.signature_request_id, adminSignatures?.signature_id)}
                                                    >
                                                        Sign
                                                    </a>
                                                </li>
                                            </React.Fragment>

                                        </ul>
                                        {doc?.is_complete != 1 && adminSignatures && adminSignatures?.status_code == 'awaiting_signature' && <a className="btn " onClick={() => refresDoccument(doc?.signature_request_id, doc?.UserID)}> <BiRefresh className={this.state.refreshingDoc == doc?.signature_request_id ? "fa-spin" : ''} size='1.6em' /> </a> }
                                    </div>) : <a
                                                className="capitalize-text cursor-pointer"
                                                href={`${doc?.file_url}`}
                                                target="_blank"
                                            > Download
                                            </a>}
                                            </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table> </div>
            </> :
                pageItems?.map(doc => {
                    return (
                        <div className="files-card p-4 mb-4">
                        <ul className="list-unstyled">
                        <li>{`${ doc.UserID }  ${ doc.FirstName } ${ doc.LastName }`}</li>
                            <li>{`${doc?.title}`}</li>
                            <li>{`${moment(doc?.created_at).format('MM-DD-YYYY')}`}</li>
                            <li> <a
                                className="capitalize-text cursor-pointer"
                                href={`${doc?.file_url}`}
                                target="_blank"
                            > Download
                            </a></li>
                        </ul>
                        </div>
                    )
                }
                )}

            <div className="text-right">
                <Pagination
                    firstPageText={<i className="glyphicon glyphicon-chevron-left" />}
                    lastPageText={<i className="glyphicon glyphicon-chevron-right" />}
                    prevPageText={<i className="glyphicon glyphicon-menu-left" />}
                    nextPageText={<i className="glyphicon glyphicon-menu-right" />}
                    activePage={activePage}
                    itemsCountPerPage={this.props.ITEMS_PER_PAGE}
                    totalItemsCount={projectListSize}
                    onChange={this.onPageChange}
                />
            </div>



        </>;
    }
}
