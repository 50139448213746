
export const login = (user) => {
	let rand = "";
	let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

	for (let i = 32; i > 0; --i) {
		rand += chars[Math.round(Math.random() * (chars.length - 1))];
	}

	const response = {
		token: rand,
		data: {
			id: user.id,
			role: user.role
		}
	};

	return new Promise(resolve => setTimeout(resolve(response), 1000));
};

export const logout = () => {
	return new Promise(resolve => setTimeout(resolve, 1000));
};
