import PropTypes from 'prop-types'
import React from "react";
import Icon from "../IconComponent/IconComponent";
import {
    ALL_INVESTMENTS_CARD_IRR,
    ALL_INVESTMENTS_CARD_PAYBACK,
    ALL_INVESTMENTS_CARD_ROI,
    ALL_INVESTMENTS_PROJECTED_METRICS,
    PROJECT_REG_CF,
    PROJECT_REG_D,
} from "../../../utils/ApplicationConstants";
import InfoComponent from "./InfoComponent";
import SelectComponent from "../InputComponents/SelectComponent/SelectComponent";
import SingleLineInputComponent from "../InputComponents/SingleLineInputComponent/SingleLineInputComponent";
import MultilineInputComponent from "../InputComponents/MultilineInputComponent/MultilineInputComponent";


const propTypes = {
    category: PropTypes.string.isRequired,
    companyLogo: PropTypes.string.isRequired,
    companyName: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    irr: PropTypes.any.isRequired,
    location: PropTypes.string.isRequired,
    payback: PropTypes.any.isRequired,
    projectName: PropTypes.string.isRequired,
    roi: PropTypes.any.isRequired,
    status: PropTypes.string.isRequired,
    projectType: PropTypes.oneOf([PROJECT_REG_D, PROJECT_REG_CF]),
    onSeeDetails: PropTypes.func.isRequired,
    projectID: PropTypes.number.isRequired,
    version: PropTypes.number.isRequired,
    isProjectDetails: PropTypes.bool,
    isEditMode: PropTypes.bool.isRequired,
    onInputChange: PropTypes.func.isRequired,
    allCategories: PropTypes.array.isRequired,
    allStatuses: PropTypes.array.isRequired,
    isRegCf: PropTypes.bool,
};

const defaultProps = {
    category: '',
    companyLogo: 'https://www.energyfunders.com/wp/wp-content/uploads/2018/08/ef-logo.png',
    companyName: '',
    description: '',
    irr: 0,
    location: '',
    payback: 0,
    projectName: '',
    roi: 0,
    status: 'Commercial',
    projectType: PROJECT_REG_D,
    onSeeDetails: () => null,
    projectID: 0,
    version: 1,
    isProjectDetails: false,
    isEditMode: false,
    onInputChange: () => null,
    allCategories: [],
    allStatuses: [],
    isRegCf: false,
};

const renderStimationData = (displayData, title, information, isEditMode) => {
    if (isEditMode) return null;

    return (
        <div className={`grid-description-container-${title.toLowerCase()}`}>
            <p className="high-text margin-10-px purple-text">
                {title} <InfoComponent children={information}/>
            </p>
            <p className="upper-case-text under-text">{displayData}</p>
        </div>
    );
};


class CardDescriptionComponent extends React.Component {
    EVENT_TYPE = 'resize';

    constructor(props) {
        super(props);

        this.state = {
            isTablet: false,
        };
    }

    componentDidMount() {
        window.addEventListener(this.EVENT_TYPE, this.defineDeviceType);
        this.defineDeviceType();
    }

    componentWillUnmount() {
        window.removeEventListener(this.EVENT_TYPE, this.defineDeviceType);
    }

    defineDeviceType = () => {
        const deviceWidth = document.body.clientWidth;
        const isTablet = (deviceWidth <= 1430 && deviceWidth >= 800);

        this.setState({isTablet});
    };

    setNumberAsThousands = (number) => {
        if (!number)
            return 0;

        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    renderCompanyLogoImage = (companyLogo) => (
        <div className="grid-description-container-image"
             style={{backgroundImage: `url('${companyLogo}')`}}>
        </div>
    );


    render() {
        const {props} = this;
        const {isTablet} = this.state;
        const {
            category, projectName, companyName, location, companyLogo, description, status,
            roi, irr, payback, isProjectDetails, isEditMode, allCategories, allStatuses,
            isRegCf, isPastProject
        } = props;
        const element = isTablet && (!companyLogo || companyLogo === '') ? null : this.renderCompanyLogoImage(companyLogo);

        // This is the invest button for current and past projects.  Hidden for past projects.
        const investButton = (
            <div className="grid-description-container-details">
                <button className="btn btn-primary btn-lg margin-10-px upper-case-text"
                        onClick={() => props.onSeeDetails(props.projectID, props.projectType, props.version)}
                        style={{padding: '15px 40px', backgroundColor: "#5bb462"}}
						id={isProjectDetails ? 'mainInvestButton' : 'seeDetailsButton'}
				>
					{isProjectDetails ? 'Invest' : 'See Details'}
                </button>
            </div>
        );

        const statusInfo = (
            <InfoComponent>
                {ALL_INVESTMENTS_PROJECTED_METRICS}
            </InfoComponent>
        );

        return [
            (<div className="grid-description-container-category">
                {!isEditMode && <span>{category} - {status}</span>}
                {isEditMode &&
                <SelectComponent name="category"
                                 selectTitle="Category"
                                 value={category}
                                 onChange={this.props.onInputChange}
                                 options={allCategories}/>}
            </div>),
            (<div className="grid-description-container-title upper-case-text pointer"
                  onClick={() => props.onSeeDetails(props.projectID, props.projectType, props.version)}>
                {!isProjectDetails && !isEditMode && <span>{projectName}</span>}
            </div>),
            (<div className="grid-description-container-operated-by upper-case-text">
                {!isEditMode && <span>OPERATED BY {companyName}</span>}
                {isEditMode && <SingleLineInputComponent elementValue={companyName}
                                                         onInputUpdate={this.props.onInputChange}
                                                         inputTitle="Operated by"
                                                         inputName="operatedBy"
                                                         maxInputLength={100}/>}
            </div>),
            (<div className="grid-description-container-location">
                {!isEditMode && <span><Icon iconName="map-marker" extraClasses="purple-text"/> {location}</span>}
                {isEditMode && !isRegCf && <SingleLineInputComponent elementValue={location}
                                                         onInputUpdate={this.props.onInputChange}
                                                         inputTitle="Location"
                                                         inputName="location"
                                                         maxInputLength={255}/>}
            </div>),
            element,
            (<div className="grid-description-container-data">
                {!isEditMode && description}
                {isEditMode && <MultilineInputComponent elementValue={description}
                                                        onInputUpdate={this.props.onInputChange}
                                                        inputTitle="Card template description"
                                                        inputName="dealCardDescription"
                                                        maxInputLength={500}
                                                        rows={5}/>}
            </div>),
            (<div className="grid-description-container-status">
                {roi > 0 && irr > 0 && payback > 0 && !isEditMode && <span><strong>Projected Metrics:</strong>{statusInfo}</span>}
                {isEditMode && <SelectComponent xs={12}
                                                onChange={this.props.onInputChange}
                                                selectTitle="Status"
                                                options={allStatuses}
                                                value={status}
                                                name="status"/>}
            </div>),

            (roi > 0 ? renderStimationData(`${this.setNumberAsThousands(roi)}x`, 'ROI', ALL_INVESTMENTS_CARD_ROI, isEditMode) : ''),
            (irr > 0 ? renderStimationData(`${irr ? irr : 0}%`, 'IRR', ALL_INVESTMENTS_CARD_IRR, isEditMode) : ''),
            (payback > 0 ? renderStimationData(`${payback ? payback : 0} Months`, 'Payback', ALL_INVESTMENTS_CARD_PAYBACK, isEditMode) : ''),

			// This is the invest button for current and past projects.  Hidden for past projects.
            isEditMode ? null : (isPastProject ? null : investButton)
        ];
    };
}

CardDescriptionComponent.propTypes = propTypes;
CardDescriptionComponent.defaultProps = defaultProps;

export default CardDescriptionComponent;

