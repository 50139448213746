import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import React from "react";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import moment from "moment";
import CollapsiblePanelComponent from "../CollapsiblePanelComponent/CollapsiblePanelComponent";
import { formatMoney, getUUID, ROI } from "../../../utils/General";
import RequestService from "../../../api/RequestService";
import { withRouter } from "react-router-dom";
import InvestorEarningsChart from "../../investor/InvestorDashboardContainerComponent/InvestorEarningsChart";

class InvestmentsTableComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			activePage: 1,
			itemsPerPage: props.itemsPerPage,
			totalItems: 0,
			selectedItemsList: [],
			pastRegDInvestments: [],
			projectDetails: [],
			showModal: false,
			selectedInvestorInvestment: [],
		};
		this.handlePageChange = this.handlePageChange.bind(this);
		this.handleChangeItemsPerPage =
			this.handleChangeItemsPerPage.bind(this);
	}
	componentDidMount() {
		const { itemsPerPage } = this.state;
		const { investorInvestmentsList } = this.props;
		let page = 1;
		const prev = (page - 1) * itemsPerPage;
		const next = prev + itemsPerPage;
		this.setState({
			activePage: page,
			selectedItemsList: investorInvestmentsList.slice(prev, next),
			totalItems: investorInvestmentsList.length,
		});
	}
	componentDidUpdate(prevProps) {
		const { investorInvestmentsList } = this.props;
		const { activePage } = this.state;
		const _investorEarningsList = JSON.stringify(
			prevProps.investorInvestmentsList
		);
		const strInvestorEarningsList = JSON.stringify(investorInvestmentsList);
		if (_investorEarningsList !== strInvestorEarningsList) {
			this.setState({ totalItems: investorInvestmentsList.length }, () =>
				this.handlePageChange(activePage, false)
			);
		}
	}
	handlePageChange(page, scrollUp) {
		const { itemsPerPage } = this.state;
		const { investorInvestmentsList } = this.props;
		const prev = (page - 1) * itemsPerPage;
		const next = prev + itemsPerPage;
		this.setState({
			activePage: page,
			selectedItemsList: investorInvestmentsList.slice(prev, next),
		});
		if (scrollUp) {
			//document.getElementById('InvestmentsTableComponent').scrollIntoView();
		}
	}
	handleChangeItemsPerPage() {
		this.setState(
			{
				itemsPerPage: Number(this.refs.selectItemsPerPage.value), // Must be typecast.
				activePage: 1,
			},
			() => this.handlePageChange(1, true)
		);
	}

	render() {
		const { activePage, itemsPerPage, totalItems, selectedItemsList } =
			this.state;
			// const refundedData = selectedItemsList?.find(i => i?.Refunded === 1);
		return (
			<div id="InvestmentsComponent">
				<h2>{this.props?.title}</h2>
				{/* <p>As of {moment().format("MM/DD/YYYY h:mm A")}</p> */}
				<div className="col-xs-12 p-0">
					<fieldset>
						<div className="table-responsive">
							<table className="table table-striped jambo_table">
								<thead>
									<tr className="no-text-wrap">
									  <th>Investor</th>
										<th>Project</th>
										<th>Invested</th>
										<th>Returned</th>
										<th>Investment Date</th>
										<th>Notes</th>
										{/* {!!refundedData && <th>Refunded</th>} */}
										{/* {!!deletedOrRefunded && <th>Deleted</th>} */}
									</tr>
								</thead>
								<tbody>
									{(() => {
										return selectedItemsList.map((p) => (
											<tr key={getUUID()}
											style={{textDecoration: (p?.Refunded === 1 || p?.Deleted === 1) ? 'line-through' : ''}}>
												<td>
														{p.Entity}
												</td>
												<td>
													{p.ProjectID_RegD === 0
														? p.InvestmentName
														: p.ProjectName}
												</td>
												<td>
													$
													{formatMoney(
														p.InvestmentAmount
													)}
												</td>
												<td>
													$
													{formatMoney(
														p.ReturnAmount
													)}
												</td>
												<td>
													{new Date(p.InvestmentDate).toLocaleDateString()}
												</td>
												<td>{p.PaymentStatusNotes}</td>
												{/* {!!refundedData && <td>{p?.Refunded === 1 ? "Yes" : "No"}</td>} */}
												{/* {!!deletedOrRefunded && <td>{p?.Deleted === 1 ? "Yes" : "No"}</td>} */}
											</tr>
										));
									})()}
								</tbody>
							</table>
						</div>
					</fieldset>
				</div>
				<div className="col-xs-12">
					<div className="pull-right">
						<Pagination
							firstPageText={
								<i className="glyphicon glyphicon-chevron-left" />
							}
							lastPageText={
								<i className="glyphicon glyphicon-chevron-right" />
							}
							prevPageText={
								<i className="glyphicon glyphicon-menu-left" />
							}
							nextPageText={
								<i className="glyphicon glyphicon-menu-right" />
							}
							activePage={activePage}
							itemsCountPerPage={itemsPerPage}
							totalItemsCount={totalItems}
							onChange={this.handlePageChange}
						/>
						<div
							className="pull-left"
							style={{ margin: "0 10px 0 0" }}
						>
							<select
								ref="selectItemsPerPage"
								style={{
									margin: "20px 0",
									padding: "0 10px",
									height: 32,
								}}
								onChange={this.handleChangeItemsPerPage}
							>
								<option>5</option>
								<option>10</option>
								<option>20</option>
								<option>50</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
InvestmentsTableComponent.propTypes = {
	investorInvestmentsList: PropTypes.array.isRequired,
	totalPaymentsRegCf: PropTypes.any.isRequired,
	totalPaymentsRegD: PropTypes.any.isRequired,
	itemsPerPage: PropTypes.number.isRequired,
};
InvestmentsTableComponent.defaultProps = {
	investorInvestmentsList: [],
	totalPaymentsRegCf: 0,
	totalPaymentsRegD: 0,
	itemsPerPage: 10,
};

export default withRouter(InvestmentsTableComponent);
