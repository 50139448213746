import React, { Component } from 'react';
import swal from 'sweetalert2';
import store from './index'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import RequestService from "./api/RequestService";
import { getImpersonateID  } from "./utils/General";

class UploadDwollaDoc extends Component {
	constructor(props) {
		super(props);

		this.state = {
			id: props.session.id,
			File: '',
			date: '',
			imageuploaded: '',
			isLoaded: false,
			errorOnLoad: false
		};

		this.onChange = this.onChange.bind(this);
		this.uploadImageFile = this.uploadImageFile.bind(this);
	}


	onChange(e) {
		this.setState({ File: e.target.files[0] })
	}


	//This function is to upload the image which comes from the URL
	async uploadImageFile() {
		if (this.refs.uploadtype.value === 'idCard' || this.refs.uploadtype.value === 'license' ||
			this.refs.uploadtype.value === 'passport' || this.refs.uploadtype.value === 'other') {

			document.getElementById('submitDocument').setAttribute('disabled', 'disabled');
			let file1 = this.state.File;
			let fileType = this.refs.uploadtype.value;

			if (typeof file1 === 'object') {
				const formData = new FormData();
				formData.append('data', file1);
				formData.append('fileType', fileType);

				let headers = Object.assign({},
					// { 'content-type': 'application/json' },
					{ 'Content-Type': 'multipart/form-data' }
				);

				Object.keys(headers)
					.forEach(function (k) {
						if (k.toLowerCase() === 'content-type') delete headers[k]
					});
					const impersonateID = await getImpersonateID();
					
				 const { data } = await RequestService.post({
					url: `/api/uploadimage/${this.state.id}/${this.props.session.marketing}?impersonateID=${impersonateID}`,
					headers: headers,
					data: formData
				});

				if (data.status === 200) {
					this.setState({
						imageuploaded: 'uploaded'
					});

					swal("Document Uploaded Successfully.", "", "success")
						.then((value) => {
							this.props.history.push("/app/Allinvestments")
						});
				}
				else {
					swal("Error Uploading Document.", `Contact Customer Relations.<br>${data.msg}`, "error");
					document.getElementById('submitDocument').removeAttribute('disabled');
				}
			}
			else {
				swal("Please select a Document to upload from your device.", "", "info");
				document.getElementById('submitDocument').removeAttribute('disabled');
			}
		}
		else {
			swal("Please select Document type to upload", "", "info");
			document.getElementById('submitDocument').removeAttribute('disabled');
		}
}

	componentDidMount() {
		window.scrollTo(0, 0);
		store.subscribe(() => {
			let currentState = store.getState();
			this.setState({
				id: currentState.session.user.id
			});
		});
	}


	render() {
		return(
			<div className="container-fluid Viewentity">
				<div style={{background:"white"}}>
					<div className="col-md-12 col-xs-12 col-sm-12">
						<div className="panel panel-primary" style={{borderColor:'#5bb462'}} >
							<div className="panel-heading usernavmenu" style={{borderColor:'#5bb462'}}>
								<h3 className="panel-title tcenter">Become a Verified Investor</h3>
							</div>
							<div className="panel-body">
								<div className="tcenter" style={{'font-size': '2.4em'}}>We use <a href="https://www.dwolla.com" target="_blank" rel="noopener noreferrer">Dwolla</a> payments platform for all payments.</div><div className="tcenter" style={{'font-size': '1.4em'}}> This information is only used to verify your status, and is not stored in our servers.</div>
								<br />
								<form>
									<div className="col-md-2 col-sm-2"> </div>
									<div className="col-md-8 col-sm-8">
										<div className="col-md-6 col-sm-6">
											<div className="form-group">
												<label htmlFor="uploadtype">Please Select any one of the Documents mentioned below to Upload :</label>
												<select  className=" form-control" ref="uploadtype" id="uploadtype" >
													<option value={-1}>Select Document Type</option>
													<option value={"idCard"}>Photo ID</option>
													<option value={"license"}>License</option>
													<option value={"passport"}>Passport ID Page</option>
													<option value={"other"}>Other</option>
												</select>
												<span id="error_uploadtype" className="text-danger" />
											</div>
										</div>
										<div className="col-md-6 col-sm-6">
											<div className="pt">
												<h5>Please choose the file to upload :</h5>
												<input id="myInput" type="file" onChange={(e)=>this.onChange(e)} />{this.state.imageuploaded ? <h4 id="error_nwells" className="text-success" >Uploaded!</h4>:null}
											</div>
											<br/>
										</div>
										<div className="tcenter">
											<button type="button" id="submitDocument" onClick={this.uploadImageFile} className="fa fa-upload btn usernavmenu tcolor"> Start Upload</button>
										</div>
									</div>
									<div className="col-md-2 col-sm-2"> </div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const { object, bool } = PropTypes;

UploadDwollaDoc.propTypes = {
	session: object.isRequired,
	authenticated: bool.isRequired,
	checked: bool.isRequired
};

const mapState = ({ session }) => ({
	session: session.user,
	checked: session.checked,
	authenticated: session.authenticated
});

export default withRouter(connect(mapState)(UploadDwollaDoc));
