// THIS FILE *IS* BEING USED.

import React, { Component } from 'react';
import swal from 'sweetalert';
import { sessionService } from 'redux-react-session';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter, Link, NavLink, useLocation } from 'react-router-dom';
import Icon from "../IconComponent/IconComponent";
import RequestService from '../../../api/RequestService';
import { HTTP_ERR_BAD_REQUEST } from "../../../utils/ApplicationConstants";
import Adminusertopbar from "../NavigationManager/UserNotifications/UserNotifications";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { ReactSVG } from "react-svg";
import * as AllInvestmentActions from '../AllInvestmentsContainerComponent/actions/actions';
import { FiSettings } from "react-icons/fi";
import { MdArrowDropDown } from "react-icons/md";

import { bindActionCreators } from 'redux';
import { formatPageTitle, getImpersonateID } from '../../../utils/General';

class Adminnewheader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            User: [],
            alerts: 1,
            id: props.session.id,
            IsAccredited: 0,
            isLoaded: false,
            errorOnLoad: false,
            fullUserName: '',
            impersonatefullUserName: '',
            isAdminView: false,
            Role: [],
            MainRole: this.props.role,
            activepageTitle: '',
            investorId: '',
            isImpersonateView: false,
            headless: false,

        };

        this.onlogout = this.onlogout.bind(this);
        this.onCreateDwolla = this.onCreateDwolla.bind(this);
        this.cancelImpersonateView = this.cancelImpersonateView.bind(this)
    }


    componentDidMount() {
        this.getUserName();
        this.checkAccreditation();
        this.getDetails();
        this.setPageTitle();
        this.setImpersonateView();
        this.setHeadless();

    }

    componentDidUpdate(prevProps) {
        // let impersonatefullUserName;
        let activepageTitle;
        let isImpersonateView = false;
        let investorId = JSON.parse(localStorage.getItem('investorId'));
        if (investorId) {
            isImpersonateView = true;

        }
        //    if(!this.state.impersonatefullUserName){
        //     impersonatefullUserName =  this.getImpersonateUserName();
        //    }

        if (this.props.location !== prevProps.location) {
            activepageTitle = formatPageTitle(this.props.location);
            this.setState({ isImpersonateView: isImpersonateView, activepageTitle: activepageTitle, investorId: investorId });
        }

    }


    setImpersonateView() {
        let isImpersonateView = false;
        let investorId = JSON.parse(localStorage.getItem('investorId'));
        if (investorId) {
            isImpersonateView = true;
            this.setState({ isImpersonateView: isImpersonateView });
        }

    }
    setHeadless() {
        const query = this.props?.location?.search;
        if (query.search('headless=true') == 1) {
            this.setState({ headless: true });
        }
    }
    setPageTitle() {

        const location = this.props?.location;
        const activepageTitle = formatPageTitle(location);
        this.setState({ activepageTitle: activepageTitle })
    }

    onCreateDwolla() {
        this.props.history.push({
            pathname: '/app/setupprofile3'
        })
    }


    async checkAccreditation() {
        const impersonateID = await getImpersonateID();
        const { data } = await RequestService.get(`/api/checkAccreditation/${impersonateID}`);

        if (data.code === 200) {
            this.setState({
                IsAccredited: data.AccreditationStatus	// Not using WasAccredited
            });
        }
        else {
            // console.log(data);
        }
    }


    onlogout() {
        swal({
            title: 'Are you sure you want to Logout?',
            icon: 'warning',
            className: 'primary-button-color',
            buttons: ['Cancel', 'Yes'],
            dangerMode: false,
        })
            .then(async (willLogout) => {
                if (willLogout) {
                    const { data } = await RequestService.post({
                        url: '/api/logout',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    });

                    if (data.code === 200 && data.acc) {
                        localStorage.clear();
                        sessionService.deleteSession();
                        sessionService.deleteUser();
                        window.location = '/login';
                    }
                    else {
                        sessionService.deleteSession();
                        localStorage.clear();
                        sessionService.deleteUser();
                        window.location = '/marketplace/login';
                    }
                }
            });
    }

    // getImpersonateUserName = async () => {

    //     let  investorId =JSON.parse(localStorage.getItem('investorId'));
    //     const { session } = this.props;
    //     if(investorId){ const URL = `/api/getusername/${investorId}/${session.marketing}`;
    //     const {data, status} = await RequestService.get(URL);
    //     if (status >= HTTP_ERR_BAD_REQUEST) {
    //         console.error('Error extracting user information in request');
    //         return;
    //     }

    //     const {FirstName, LastName} = data[0];

    //   const impersonatefullUserName = `${FirstName} ${LastName}`

    //     return impersonatefullUserName;

    // }




    // };
    getUserName = async () => {
        const { session } = this.props;
        const URL = `/api/getusername/${session.id}/${session.marketing}`;
        const { data, status } = await RequestService.get(URL);

        if (status >= HTTP_ERR_BAD_REQUEST) {
            console.error('Error extracting user information in request');
            return;
        }

        const { FirstName, LastName } = data[0];
        this.setState({ fullUserName: `${FirstName} ${LastName}` });
    };

    getDetails() {


        fetch('/api/getrole/' + this.props.id + '/' + this.props.id + '/' + this.props.session.marketing, {
            method: 'get'
        })
            .then((response) => response.json())
            .then((data) => {
                this.setState({
                    Role: data,

                });

                for (let i = 0; i < this.state.Role.length; i++) {
                    if (this.state.Role[i].Role === "Admin") {
                        this.setState({
                            MainRole: "Admin",

                        });
                        break;
                    }
                    else if (this.state.Role[i].Role === "SuperAdmin") {
                        this.setState({
                            MainRole: "SuperAdmin",

                        });
                        break;
                    }
                    else {
                        this.setState({
                            MainRole: "Investor",

                        });
                    }
                }
            })
            .catch((err) => {

                console.log('err', err);
            })
    }

    setUserView = (view) => {
        const { setAdminView } = this.props;
        if (view === "investorview") {
            this.setState({ isAdminView: false })
            setAdminView(false)
        }
        else if (view === "adminview") {
            this.setState({ isAdminView: true });
            setAdminView(true)
        }


    };

    switchUserView() {
        return (
            this.state.MainRole === "SuperAdmin" && this.state.isAdminView ?
                <li onClick={() => this.setUserView("investorview")}>
                    <button className="btn-sm btn-default btn-dropdown full-width"><Link to="/app/UserNotifications" style={{ color: "#000", textDecoration: "none" }}>Investor view</Link></button>
                </li> : <li onClick={() => this.setUserView("adminview")}>
                    <button className="btn-sm btn-default btn-dropdown full-width"><Link to="/app/UserNotifications" style={{ color: "#000", textDecoration: "none" }}>Admin View</Link></button>
                </li>

        )
    }
    cancelImpersonateView() {

        localStorage.removeItem('investorId');
        localStorage.removeItem('isAdminView');
        this.setState({ isImpersonateView: !this.state.isImpersonateView, impersonatefullUserName: '' });
    }
    switchImpersonateView() {
        return (
            this.state.MainRole === "SuperAdmin" && this.state.isAdminView ?
                <><li onClick={() => this.setUserView("investorview")}>
                    <button className="btn-sm btn-default btn-dropdown full-width"><Link to="/app/UserNotifications" style={{ color: "#000", textDecoration: "none" }}>Impersonate View </Link></button>
                </li><li onClick={() => this.setUserView("adminview")}>
                        <button className="btn-sm btn-default btn-dropdown full-width" onClick={this.cancelImpersonateView}><a style={{ color: "#000", textDecoration: "none" }}>End Impersonate View</a></button>
                    </li></> :
                <><li onClick={() => this.setUserView("adminview")}>
                    <button className="btn-sm btn-default btn-dropdown full-width"><Link to="/app/UserNotifications" style={{ color: "#000", textDecoration: "none" }}>Admin View</Link></button>
                </li> <li onClick={() => this.setUserView("adminview")}>
                        <button className="btn-sm btn-default btn-dropdown full-width" onClick={this.cancelImpersonateView}><a style={{ color: "#000", textDecoration: "none" }}>End Impersonate View</a></button>
                    </li></>

        )
    }


    renderAccreditedMenu = () => {
        const efLogo = "/img/ef-logo.svg";

        return (
            <React.Fragment>
                <div className="navbar-header logo-wrap" style={{ margin: 0 }}>
                    <button type="button" className="navbar-toggle collapsed" data-toggle="collapse"
                        data-target="#app-main--header-mobile" aria-expanded="false" aria-controls="navbar">
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                    </button>
                    <div className="navbar-brand logo" style={{ padding: 6 }}>

                        <NavLink
                            to="/app/UserNotifications" className="cursor-pointer" >
                            <ReactSVG src={efLogo} alt="Logo" />
                        </NavLink>
                    </div>
                    <div
                        className="collapse-sidebar-big-screen pointer pull-left"
                        onClick={this.props.sidebarActionHandler}
                    >
                        {this.props.sideAction ? <IoIosArrowBack size={18} color="#ababab" /> : <IoIosArrowForward size={18} color="#ababab" />}

                    </div>
                </div>

                <div className="pull-left active-page-title text-center text-md-left">{this.state?.activepageTitle}</div>
                <div id="app-main--header-mobile" className="navbar-collapse collapse">
                    <ul className="nav navbar-nav navbar-right d-flex mobScroll">

                        {
                            this.state.MainRole === "SuperAdmin" && this.state.isImpersonateView ? <li className='viewMin'>
                                <span className="white-text pull-right">
                                    <span className="dropdown d-flex position-relative">
                                        <span className="cursor-pointer font-bold dropdown-toggle d-flex"
                                            data-toggle="dropdown"
                                            style={{ border: 'none', margin: 0, padding: 0, color: "#000", textDecoration: "none" }}>
                                            <span className='d-flex align-items-center position-relative'>
                                                {this.state.MainRole === "SuperAdmin" && this.state.isAdminView ? "Admin View" : "Impersonate View"} {this.state.MainRole === "SuperAdmin" && <MdArrowDropDown size={24} />}

                                            </span>
                                        </span>
                                        {this.state.MainRole === "SuperAdmin" && <ul className="dropdown-menu pull-right dropdownmobtop">
                                            {this.switchImpersonateView()}

                                        </ul>}
                                    </span>
                                </span>
                            </li> : this.state.MainRole === "SuperAdmin" ?
                                <li className='viewMin'>
                                    <span className="white-text pull-right">
                                        <span className="dropdown d-flex position-relative">
                                            <span className="cursor-pointer font-bold dropdown-toggle d-flex"
                                                data-toggle="dropdown"
                                                style={{ border: 'none', margin: 0, padding: 0, color: "#000", textDecoration: "none" }}>
                                                <span className='d-flex align-items-center position-relative'>
                                                    {this.state.MainRole === "SuperAdmin" && this.state.isAdminView ? "Admin View" : "Investor View"} {this.state.MainRole === "SuperAdmin" && <MdArrowDropDown size={24} />}

                                                </span>
                                            </span>
                                            {this.state.MainRole === "SuperAdmin" && <ul className="dropdown-menu pull-right">
                                                {this.switchUserView()}

                                            </ul>}
                                        </span>
                                    </span>
                                </li> : ""}

                        <li>
                            <Link to="/app/Profile">
                                <FiSettings size={21} color="#738189" />
                            </Link>
                        </li>
                        <li>	<a>


                            <Adminusertopbar {...this.props} />

                        </a></li>
                        <li>
                            <div className="profile-pic">{this.state.fullUserName.slice(0, 1)}</div>
                            <div className="profileName">{this.state.fullUserName}</div>
                        </li>
                        <li onClick={this.onlogout} className='mobsignout'>
                            <a>
                                Sign Out
                            </a>
                        </li>
                    </ul>
                </div>
            </React.Fragment>
        );
    };


    renderNonAccreditedMenu = () => {


        const efLogo = "/img/ef-logo.svg";
        return (
            <React.Fragment>
                <div className="navbar-header" style={{ margin: 0 }}>
                    <button type="button" className="navbar-toggle collapsed" data-toggle="collapse"
                        data-target="#app-main--header-mobile" aria-expanded="false" aria-controls="navbar">
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                    </button>
                    <div className="navbar-brand" style={{ padding: 0 }}>
                        <NavLink
                            to="/app/UserNotifications" className="cursor-pointer" >
                            <ReactSVG src={efLogo} alt="Logo" />
                        </NavLink>
                    </div>
                </div>
                <div id="app-main--header-mobile" className="navbar-collapse collapse">
                    <ul className="nav navbar-nav navbar-right text-uppercase">
                        {/* <li>
							<Link to="/app/investorDashboard">
								Dashboard
							</Link>
                        </li> */}
                        {/* <li>
                            <a href="https://www.efmarketplace.com/education" target="_blank" rel="noopener noreferrer">
                                Education
                            </a>
                        </li> */}
                        {/* <li>
							<Link to="/app/support">
								Support
							</Link>
						</li> */}

                        <li>
                            <Link to="/app/Profile">
                                <FiSettings size={21} color="#738189" />
                            </Link>
                        </li>
                        <li>	<a>
                            <Adminusertopbar {...this.props} />

                        </a></li>
                        <li><div className="profile-pic">{this.state.fullUserName.slice(0, 1)}</div>
                            <div>{this.state.fullUserName}</div></li>

                        <li onClick={this.onlogout}>
                            <a>
                                Sign Out
                            </a>
                        </li>
                    </ul>
                </div>
            </React.Fragment>
        );
    };



    render() {

        return (
            !this.state.headless ? <nav className="navbar app-main--navbar-custom">
                <div className="container-fluid">
                    {/* {this.state.IsAccredited > 0 ? this.renderAccreditedMenu() : this.renderNonAccreditedMenu()} */}
                    {this.renderAccreditedMenu()}
                </div>
            </nav> : <></>
        );
    }
}


const { object, bool } = PropTypes;

Adminnewheader.propTypes = {
    session: object.isRequired,

    authenticated: bool.isRequired,
    checked: bool.isRequired,
    setAdminView: PropTypes.func.isRequired,
};


const mapStateToProps = ({ session }) => ({
    session: session.user,
    checked: session.checked,
    authenticated: session.authenticated
});



const mapDispatchToProps = (dispatch) => {
    const allInvestmentActions = bindActionCreators(AllInvestmentActions, dispatch);
    return { ...allInvestmentActions };
};


const connectedComponent = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Adminnewheader);

export default withRouter(connectedComponent);
