import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter,Link } from "react-router-dom";
import EarningsOfferingsHeader from "./components/OfferingsTitle/EarningsOfferingsTitle";
import { bindActionCreators } from "redux";
import * as AllInvestmentActions from "./actions/actions";
import RequestService from "../../../api/RequestService";
import store from "../../../index";
import {
	PROJECT_REG_CF,
	PROJECT_REG_D,
	PROJECT_REG_ALL,ALL_PROJECTS
} from "../../../utils/ApplicationConstants";

import InvestorEarningsChart from "../../investor/InvestorDashboardContainerComponent/InvestorEarningsChart";
import InvestmentChart from "../../investor/InvestorDashboardContainerComponent/InvestmentChart";
import InvestmentStackedChart from "../../investor/InvestorDashboardContainerComponent/InvestmentStackedChart";
import EarningsDescription from "../../investor/InvestorDashboardContainerComponent/EarningsDescription";
import EarningTableComponent from "../EarningsTableComponent/EarningTableComponent";

import InvestmentsTableComponent from "../InvestmentsTableComponent/InvestmentsTableComponent";
import ProjectsChart from "../../investor/InvestorDashboardContainerComponent/ProjectsChart";
import { formatMoney, ROI,getStatusFromCode,getImpersonateID  } from "../../../utils/General";
import OtherDocuments from "./OtherDocuments";
import DistributionTableComponent from "../DistributionTableComponent/DistributionTableComponent";
import InvestorMembershipsContainer from './InvestorMembershipContainer'
import InvestorCumulativeEarningsChart from "../../investor/InvestorDashboardContainerComponent/InvestorCumulativeEarningsChart";

class InvestorEarningsContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			ROI: 0,
			// totalEarningsRegD:0,
			activeOfferingsChecked: true,
			pastOfferingsChecked: true,
			selectedProjectType: PROJECT_REG_D,
			selectedProjectCategory: "all",
			id: props.user.id,
			users: [],
			totalProjects: [],
			allProjects_cf: [],
			pageNumber: 1,
			totalCount: 0,
			perPage: 10,
			activePage: 1,
			allProjectsD: [],
			usersD: [],
			pageNumberD: 1,
			totalCountD: 0,
			perPageD: 10,
			activePageD: 1,
			docsD: [],
			docsCF: [],
			tia_d: 0,
			tia_cf: 0,
			troc_d: 0,
			troc_cf: 0,
			modalTitle: "",
			docDataForModal: [],
			showDocsModal: false,
			showDocFrameModal: false,
			docFrameURL: "",
			modalDocTitle: "",
			selectedProject: ALL_PROJECTS,
			investorEarnings:[],
			investorInvestments:[]
		};
	}
	componentDidMount() {
		store.subscribe(() => {
			let currentState = store.getState();
			this.setState({
				id: currentState.session.user.id,
			});
		});
	
		this.getMyInvestments();
		const { getAllInvestments, getAllProjectCategories } = this.props;
		getAllInvestments(this.state.selectedProjectCategory);
		getAllProjectCategories();
		const { investorId, getAllUserInvestments,investorEarnings,investorInvestments } = this.props;
		getAllUserInvestments(investorId);
		this.allProjectsList();
		this.setState({

		investorEarnings:investorEarnings,
		investorInvestments:investorInvestments
		})
	}
	//earning table not updating fix
	componentDidUpdate(prevProps) {
		const { investorEarnings} = this.props;
		const _investorEarnings = JSON.stringify(
			prevProps?.investorEarnings
		);
		const strinvestorEarnings = JSON.stringify(investorEarnings);
		if (_investorEarnings !== strinvestorEarnings) {
			this.setState({
				investorEarnings:investorEarnings
				})
		}
	}
	async getMyInvestments() {
		const impersonateID = await getImpersonateID();
		const { data } = await RequestService.get(`/api/myInvestments/${impersonateID}`);
		if (data.code === 200) {
			this.setState({
				users: data.reg_cf.slice(0, 10),
				allProjects_cf: data.reg_cf,
				docsCF: data.docsCF,
				tia_d: data.tia_d,
				tia_cf: data.tia_cf,
				troc_d: data.troc_d,
				troc_cf: data.troc_cf,
				totalCount: data.reg_cf?.length,
				allProjectsD: data.reg_d,
				usersD: data.reg_d.slice(0, 10),
				docsD: data.docsD,
				pageNumberD: 1,
				totalCountD: data.reg_d?.length,
				activePageD: 1,
			});
		}
	
	}
	allProjectsList = () => {
		const { investorInvestments } = this.props;
		let totalProjects = investorInvestments.map((item) => {
			return { value: item.ProjectID_RegD, label: item.ProjectName };
		});
		const uniqueTotalProjects = [
			...new Map(
				totalProjects?.map((item) => [item.value, item])
			).values(),
		];
	this.setState({
			...this.state,
			totalProjects: uniqueTotalProjects,
		});
	};
	selectedProject = (ID) => {

        const {investorEarnings,investorInvestments} = this.props;
		const{allProjectsD} = this.state;
let selectInvestorEarnings = ID ==='allprojects' ? investorEarnings :  investorEarnings?.filter(item => item?.ProjectID_RegD === ID );
let selectInvestorInvestments = ID ==='allprojects' ? investorInvestments : investorInvestments?.filter(item => item?.ProjectID_RegD === ID );  

let selectAllProjectsD = ID === 'allprojects' ? allProjectsD : allProjectsD?.filter(item => item?.ProjectID === ID );   

this.setState({ investorEarnings:selectInvestorEarnings,investorInvestments:selectInvestorInvestments,allProjectsD:selectAllProjectsD });
 
 };


	onActivePastCheckedClickHandler = (event) => {
		const { name, checked } = event.target;
		this.setState({ [name]: checked });
	};
	onProjectTypeChanged = async(project) => {
		let projectID =project?.value;
		const {getDashboardData} =this.props;
	if(projectID==="allprojects"){
		projectID =0;
		}
	 await(getDashboardData(projectID));
	this.selectedProject(project?.value);
		this.setState({ selectedProject: project });

	};
	incrementViewCount = (projectId, projectType) => {
		const { incrementViewCount, user } = this.props;
		incrementViewCount(user, projectType, projectId);
	};
	onSeeDetailsClickHandler = (projectId, projectType, version) => {
		if (version === 1) this.redirectToDealPage(projectType, projectId);
		else if (version === 2)
			this.redirectToProjectDetailsPage(projectType, projectId);
	};
	redirectToProjectDetailsPage = (projectType, projectId) => {
		const { push } = this.props.history;

		const pushStateObj = {
			state: {
				id: projectId,
				projectType: this.state.selectedProjectType,
			},
			pathname: "/app/project/details",
		};
		this.incrementViewCount(projectId, projectType);
		push(pushStateObj);
	};
	redirectToDealPage = (projectType, projectId) => {
		const { push } = this.props.history;
		const obj = {
			state: {
				id: projectId,
				projectType: this.state.selectedProjectType,
			},
		};
		if (projectType === PROJECT_REG_D) obj.pathname = "/app/deal";
		else if (projectType === PROJECT_REG_CF) obj.pathname = "/app/dealCF";
		this.incrementViewCount(projectId, projectType);
		push(obj);
	};
	onProjectCategorySelected = (projectCategoryId) => {
		const { getAllInvestments } = this.props;
		this.setState({ selectedProjectCategory: projectCategoryId });
		getAllInvestments(projectCategoryId);
	};


	renderRegDProjects(activeOffering, pastOfferings) {
	
		const {wnVerified,
			activeRegDInvestments,
			pastRegDInvestments,
			isAccreditedUser,
			wasAccreditedUser,
			showPastInvestments,
			loadingComplete,
		} = this.props;
		const {
			investmentLabels,
			investmentAmounts,
			cumulativeEarnings,
			distributionEarningChartData,
			cumulativeEarningsChartData,
			totalPaymentsRegD,
			itemsPerPage,
			role,
			cumulativeEarningsTimeSeries,
			cumulativeInvestmentTimeSeries,
			cumulativeEarningsAmount
		} = this.props;
		let regDInvestments = `${formatMoney(
			this.state.tia_d - this.state.troc_d
		)}`;
		let investment = Number(this.state.tia_d - this.state.troc_d);
		let totalReturnsRegD = formatMoney(Number(cumulativeEarningsAmount));
	const {investorEarnings,investorInvestments,allProjectsD,selectedProject} =this.state;
	let ROIAmount = `${formatMoney(
		ROI(investment, Number(cumulativeEarningsAmount))
	)}`;
	const calculateCumulativeAmount = cumulativeEarnings?.slice(1).map((v, i) => v - cumulativeEarnings[i]);
	const cumulativeDistributions = [...cumulativeEarnings?.slice(0,1),...calculateCumulativeAmount];
		if (
			this.state.selectedProjectType === PROJECT_REG_D 
			// &&
			// (isAccreditedUser || wasAccreditedUser)
		) {
			return (<div className="col-xs-12 col-md-12">
				<div className="p-3 px-md-5">
					<EarningsDescription
					investorEarnings={investorEarnings}
						investorInvestments={investorInvestments} selectedProject ={selectedProject} history={this.props.history}
					/>
					{/* <div className="white-bg p-3 rounded-top mb-5 clear">
						<InvestmentStackedChart
							investmentLabels={investmentLabels}
							cumulativeEarnings={cumulativeEarnings}
							cumulativeEarningsTimeSeries={
								cumulativeEarningsTimeSeries
							}
							cumulativeInvestmentTimeSeries={
								cumulativeInvestmentTimeSeries
							}
							numberOfInvestments={investorInvestments}
						/>{" "}
					</div> */}
					<div className="container mb-5 pb-4 distributions">
					<h2 className="text-white">Your Distributions</h2>
						<div className="col-md-6 col-xs-12">
						<div className="white-bg rounded  border-light">
						<h3>Distributions Over Time</h3>
							<div className="p-0 pb-0">
								<InvestorEarningsChart
									title=""
									investmentLabels={investmentLabels}
									distributionEarningChartData={distributionEarningChartData}
									cumulativeEarningsChartData={cumulativeEarningsChartData}
									investmentAmounts={investmentAmounts}
									cumulativeEarnings={cumulativeEarnings}
									cumulativeDistributions ={cumulativeDistributions}
								/>
								</div>
							</div>
						</div>
						<div className="col-md-6 col-xs-12">
						<div className="white-bg rounded  border-light">
							<h3>Cumulative  Distributions Over Time</h3>
							<div className="p-0 pb-0">
								<InvestorCumulativeEarningsChart
									title=""
									investmentLabels={investmentLabels}
									cumulativeEarningsChartData={cumulativeEarningsChartData}
									investmentAmounts={investmentAmounts}
									cumulativeEarnings={cumulativeEarnings}
									cumulativeDistributions ={cumulativeDistributions}
								/>
								</div>
							</div>

						</div>
					</div>
					<div className="container mb-5">
					<h2>Original Investments</h2>
					<DistributionTableComponent
						investorInvestmentsList={investorInvestments}
						itemsPerPage={itemsPerPage}
						role="Investor"
						
					/>
					</div>
					<div className="container mb-5">
					<h2>My Earnings</h2>
					<EarningTableComponent
						investorEarnings={investorEarnings}
						totalPaymentsRegD={totalPaymentsRegD}
						itemsPerPage={itemsPerPage}
						investorInvestments={investorInvestments}
						role={role}
						title=""
					/>
					</div>
		
					<div className="container mb-5">
					<h2>Transactions</h2>
					<InvestmentsTableComponent
						investorInvestmentsList={investorInvestments}
						itemsPerPage={this.props.itemsPerPage}
						role="Investor"
					/>
					</div>
					{/* <div className="container mb-5">
					<h2>Membership</h2>
					<InvestorMembershipsContainer/>
					</div> */}
					{/* <OtherDocuments earnings={true}/> */}
				</div>
				</div>
			);
		}
		return null;
	}

	render() {
		const { pastOfferingsChecked, activeOfferingsChecked } = this.state;
		const { isAccreditedUser, wasAccreditedUser } = this.props;
		return (
			<div className="container filter-wrap">
				<div className="white-bg">
					<EarningsOfferingsHeader
						totalProjects={this.state.totalProjects}
						selectedProject={this.state.selectedProject}
						onProjectTypeClickHandler={this.onProjectTypeChanged}
						activeOfferingsChecked={activeOfferingsChecked}
						pastOfferingsChecked={pastOfferingsChecked}
						isAccreditedUser={isAccreditedUser}
						wasAccreditedUser={wasAccreditedUser}
						selectedProjectType={this.state.selectedProjectType}
						projectCategoryList={this.props.projectCategories}
						projectCategoryOnClickHandler={
							this.onProjectCategorySelected
						}
						selectedProjectCategory={
							this.state.selectedProjectCategory
						}
					/>
				</div>
				{this.renderRegDProjects(
					activeOfferingsChecked,
					pastOfferingsChecked
				)}
		</div>
		);
	}
}
InvestorEarningsContainer.propTypes = {
	activeRegCfInvestments: PropTypes.array.isRequired,
	activeRegDInvestments: PropTypes.array.isRequired,
	addToWatchList: PropTypes.func.isRequired,
	getAllInvestments: PropTypes.func.isRequired,
	getAllProjectCategories: PropTypes.func.isRequired,
	history: PropTypes.any,
	incrementViewCount: PropTypes.func.isRequired,
	isAccreditedUser: PropTypes.bool.isRequired,
	pastRegCfInvestments: PropTypes.array.isRequired,
	pastRegDInvestments: PropTypes.array.isRequired,
	projectCategories: PropTypes.array.isRequired,
	showPastInvestments: PropTypes.bool.isRequired,
	user: PropTypes.any,
	investorId: PropTypes.number.isRequired,
	investorInvestments: PropTypes.array.isRequired,
	totalInvestmentRegCF: PropTypes.any.isRequired,
	totalInvestmentRegD: PropTypes.any.isRequired,
	totalPaymentsRegCF: PropTypes.any.isRequired,
	totalPaymentsRegD: PropTypes.any.isRequired,
	getAllUserInvestments: PropTypes.func.isRequired,
};
InvestorEarningsContainer.defaultProps = {
	showPastInvestments: true,
	getAllUserInvestments: () => null,
};
const mapDispatchToProps = (dispatch) => {
	const allInvestmentActions = bindActionCreators(
		AllInvestmentActions,
		dispatch
	);
	return { ...allInvestmentActions };
};
const mapStateToProps = (_state) => {
	return {
		..._state.investorDashboard,
		investorId: _state.session.user.id,
		..._state.allInvestments,
		user: _state.session.user,
		location: _state.location,
		projectCategories: _state.allInvestments.projectCategories,
	};
};
const connectedComponent = connect(
	mapStateToProps,
	mapDispatchToProps
)(InvestorEarningsContainer);
export default withRouter(connectedComponent);
