import React, { Component } from 'react';
import RequestService from '../../../api/RequestService';

class Totalfundraisers extends Component {
    constructor(props)
    {
        super(props);
        this.state={
            funder:[]
        }
    }
    async componentDidMount()
    {
        try {
            const { data } = await RequestService.get('/api/funders');
            this.setState({
                funder: data.rows
            });
        } catch (err) {
            console.log(err);
        }
    }

    render() {
        return(
            <div className="col-md-2 col-sm-4 col-xs-12 tile_stats_count">
                <span className="count_top"><i className="fa fa-user"></i> Total Fundraisers</span>
                <div className="count">{this.state.funder.length}</div>
            </div>
        );
    }
}

export default Totalfundraisers;
