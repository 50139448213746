
import React, {Component} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import getDate from '../../utils/ConvertDate'
import RequestService from '../../api/RequestService';
import {PROJECT_REG_D} from "../../utils/ApplicationConstants";
import {getImpersonateID} from "../../utils/General";

class PreviewProjects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.session.id,
            URL: '',
            Roles: [],
            MainRole: 'Investor',
            IsAccreditated: 0,
            CurrentFunding: [],
            Cashcallprojects: [],

            allAprovedprojectsCF: [],
            AprovedprojectsCF: [],
            CurrentFundingCF: [],
            pageNumber: 1,
            totalCount: 0,
            perPage: 10,
            activePage: 1,

            allProjectsD: [],
            Aprovedprojects:[],
            pageNumberD: 1,
            totalCountD: 0,
            perPageD: 10,
            activePageD: 1,
        };

        this.getApprovedprojects = this.getApprovedprojects.bind(this);
        this.getApprovedprojectsCF = this.getApprovedprojectsCF.bind(this);
        // this.getCashcallprojects = this.getCashcallprojects.bind(this);
        this.onDealClick = this.onDealClick.bind(this);
        this.onPreviewClick = this.onPreviewClick.bind(this);
        this.onDealClickCF = this.onDealClickCF.bind(this);
        this.getCurrentFunding = this.getCurrentFunding.bind(this);
        this.getCurrentFundingCF = this.getCurrentFundingCF.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handlePageChangeD = this.handlePageChangeD.bind(this);
    }



    async componentDidMount() {
		window.scrollTo(0, 0);

        try {
            const impersonateID = await getImpersonateID();

            const { data } = await RequestService.get(`/api/checkAccreditation/${impersonateID}`);
            if (data.code === 200) {
				this.setState({
					IsAccredited: data.AccreditationStatus,
					WasAccredited: data.PriorAccredStatus
				});
            }
            else {
                // console.log(data);
            }
        }
        catch (err) {
            console.log(err);
        }

        this.getCurrentFunding();
        this.getCurrentFundingCF();
        this.getApprovedprojects();
        this.getApprovedprojectsCF();
        // this.getCashcallprojects();
    }



    getCurrentFunding() {
        fetch(`/api/getcurrentfunding/${this.state.id}/${this.props.session.marketing}`, {
            method:'get'
        })
            .then((response)=>{
                return response.json()
            })
            .then((data) => {
                this.setState({
                    CurrentFunding: data
                });
            })
            .catch((err) => {

            })
    }



    getCurrentFundingCF() {
        fetch(`/api/getcurrentfundingCF/${this.state.id}/${this.props.session.marketing}`, {
            method:'get'
        })
            .then((response) => {
                return response.json()
            })
            .then((data)=>{
                this.setState({
                    CurrentFundingCF: data
                });
            })
            .catch((err) => {

            })
    }



	onPreviewClick(id, version) {
		if (version === 1) {
			this.props.history.push({
				pathname: '/app/deal',
				state: { id: id, projectType: 'RegD' }
			});
        }
		else if (version === 2) {
			this.props.history.push({
				pathname: '/app/project/details',
				state: { id: id , projectType: PROJECT_REG_D }
			});
        }
    }



    onDealClick(id) {
        this.props.history.push({
            pathname: '/app/investmentdetails',
            state: { ProjectID: id , ProjectType:"RegD" }
        })
    }



    onDealClickCF(id, type, e) {
        this.props.history.push({
            pathname: '/app/investmentdetails',
            state: { ProjectID: id , ProjectType:"RegCF" }
          })
    }



    // getCashcallprojects(){
    //     fetch('/api/getprojectinvestments',{
    //         method:'get'
    //     })
    //         .then((response)=>{
    //             return response.json()
    //         })
    //         .then((data)=>{
    //             this.setState({
    //                 Cashcallprojects:data
    //             });
    //         })
    //         .catch((err)=>{
	//
    //         })
    // }



    getApprovedprojects() {
        fetch(`/api/getapprovedprojects/${this.state.id}/${this.props.session.marketing}`, {
            method:'get'
        })
            .then((response)=>{
                return response.json()
            })
            .then((data)=>{
                this.setState({
                    Aprovedprojects:data.slice(0,4),
                    allProjectsD:data,
                    pageNumberD: 1,
                    totalCountD: data.length,
                    perPageD: 4,
                    activePageD: 1
                });
            })
            .catch((err)=>{

            })
    }



    getApprovedprojectsCF() {
        fetch(`/api/getapprovedprojectsCF/${this.state.id}/${this.props.session.marketing}`, {
            method:'get'
        })
            .then((response)=>{
                return response.json()
            })
            .then((data)=>{
                this.setState({
                    AprovedprojectsCF:data.slice(0,2),
                    allAprovedprojectsCF:data,
                    pageNumber: 1,
                    totalCount: data.length,
                    perPage: 2,
                    activePage: 1
                });
            })
            .catch((err)=>{

            })
    }



    handlePageChange(pageNumber) {
        let prev = ((pageNumber) - 1) * 2;
        let next = prev + 2;

        this.setState({
            activePage: pageNumber,
            AprovedprojectsCF: this.state.allAprovedprojectsCF.slice(prev, next)
        });
    }



    handlePageChangeD(pageNumberD) {
        let prev = ((pageNumberD) - 1) * 4;
        let next = prev + 4;

        this.setState({
            activePageD: pageNumberD,
            Aprovedprojects: this.state.allProjectsD.slice(prev, next)
        });
    }



    render() {
        return (
            <div className="container body">
                <div className="main_container">
                    {/* page content */}
                    <div className="right_col" role="main">
                        <div className="col-md-10 col-sm-10 col-xs-8">
                        </div>
                        <div className="col-md-2 col-sm-2 col-xs-4">

                        </div>
                        <div className="row" style={{margin:0}}>
                            {(() => {
                                switch (this.state.Aprovedprojects.length) {
                                    case 0  : return (<h3>No Active Investment Opportunities</h3>);
                                    case 1  : return (<h3>Active Investment Opportunities</h3>);
                                    default : return (<h3>Active Investment Opportunities</h3>);
                                }
                            })()}
                            <div>
                            <ul className="nav nav-pills nav-justified" style={{ padding: '0 10px', marginBottom: 20 }}>
                                <li className="active"><a data-toggle="pill" href="#home">EnergyFunders Black (Reg D)</a></li>
                                <li><a data-toggle="pill" href="#menu1">EnergyFunders Marketplace (Reg CF)</a></li>

                            </ul>
                                <div className="tab-content">
                                    <div id="home" className="tab-pane fade in active">
										{
											this.state.Aprovedprojects.map((project)=>(
												<span style={{cursor:'pointer'}}>
													<div className="col-xs-12 col-md-3 col-sm-6 col-lg-3">
														<div className="project project-radius project-primary">
															<div className="shape">
																<div className="shape-text">
																	New
																</div>
															</div>
															<img className="topimage" height="175" width="100%" src="/img/image.jpg" alt=""/>

															<div className="project-content">

																<h3 className="lead">
																	{(() => {
																		switch (project.IsCashCall) {
																			case 0  : return (project.ProjectName);
																			case 1  : return (project.ProjectName)+" - Cash Call";
																			default : return (project.ProjectName);
																		}
																	})()}

																</h3>
																<p>
																	Company : {project.OperatedBy}
																	<br/>Opens On : {getDate.getDate(project.DateInvestmentStart)}
																</p>
																<b>
																	{(() => {
																		let obj =  this.state.CurrentFunding.find(x => x.ProjectID === project.ProjectID);

																		switch (project.IsCashCall) {
																			case 0  : return (
																				`$${((obj) ? Math.round(obj.CurrentFunding) : 0)} / $${(project.FundingGoalMax)}`
																			);
																			case 1 : return (
																				`$${(project.ccCurrentFunding)} / $${(project.Amount)}`
																			);
																			default : return (
																				`$${project.currentFunding} / $${project.FundingGoal}`
																			);
																		}
																	})()}</b>
																{(() => {
																	let obj =  this.state.CurrentFunding.find(x => x.ProjectID === project.ProjectID);

																	switch (project.IsCashCall) {
																		case 0  : return (<div className="progress" style={{width:'100%'}}>
																			<div className="progress-bar" role="progressbar" aria-valuenow={70} aria-valuemin={0} aria-valuemax={100} style={{width: ""+(((obj) ? Math.round(obj.CurrentFunding) : 0)/project.FundingGoalMax)*100+"%"}} />
																		</div>);
																		case 1  : return (<div className="progress" style={{width:'100%'}}>
																			<div className="progress-bar" role="progressbar" aria-valuenow={70} aria-valuemin={0} aria-valuemax={100} style={{width: ""+(project.ccCurrentFunding/project.Amount)*100+"%"}} />
																		</div>);
																		default : return (<div className="progress" style={{width:'100%'}}>
																			<div className="progress-bar" role="progressbar" aria-valuenow={70} aria-valuemin={0} aria-valuemax={100} style={{width: ""+(project.currentFunding/project.FundingGoalMax)*100+"%"}} />
																		</div>);
																	}
																})()}


																<div> {(() => {
																	let obj =  this.state.CurrentFunding.find(x => x.ProjectID === project.ProjectID);

																	switch (project.IsCashCall) {
																		case 0  : return (Math.round((((obj) ? Math.round(obj.CurrentFunding) : 0)/project.FundingGoalMax)*100)+'%');
																		case 1  : return (Math.round((project.ccCurrentFunding/project.Amount)*100) + '%');
																		default : return (Math.round((project.currentFunding/project.FundingGoal)*100) + '%');
																	}
																})()}</div><br/>
																				{/*<button className="btn btn-primary" onClick={(e) => this.onDealClick(project.ProjectID)} style={{ width: '100%' }}>Finalize Investment</button>*/}
																				<button className="btn btn-primary" onClick={(e) => this.onPreviewClick(project.ProjectID, project.TemplateVersion)} style={{ width: '100%' }}>View Preview</button>

															</div>
														</div>
													</div>
												</span>
											))
										}
										<div className="pull-right" style={{clear:'both'}}>
											<Pagination
												firstPageText={<i className='glyphicon glyphicon-chevron-left' />}
												lastPageText={<i className='glyphicon glyphicon-chevron-right' />}
												prevPageText={<i className='glyphicon glyphicon-menu-left' />}
												nextPageText={<i className='glyphicon glyphicon-menu-right' />}
												activePage={this.state.activePageD}
												itemsCountPerPage={this.state.perPageD}
												totalItemsCount={this.state.totalCountD}
												onChange={this.handlePageChangeD}
											/>
										</div>



									</div>
                                    <div id="menu1" className="tab-pane fade">
                                        {
                                            this.state.AprovedprojectsCF.map((project) => (
                                                <span style={{ cursor: 'pointer' }}>
                                                    <div className="col-xs-12 col-md-3 col-sm-6 col-lg-3">
                                                        <div className="project project-radius project-primary">
                                                            <div className="shape">
                                                                <div className="shape-text">
                                                                    New
                                                                </div>
                                                            </div>
                                                            <img className="topimage" height="175" width="100%" src="/img/image.jpg" alt="" />

                                                            <div className="project-content">

                                                                <h3 className="lead">
                                                                    {(() => {
                                                                        switch (project.IsCashCall) {
                                                                            case 0: return (project.InvestmentName);
                                                                            case 1: return (project.InvestmentName) + " - Cash Call";
                                                                            default: return (project.InvestmentName);
                                                                        }
                                                                    })()}
                                                                </h3>
                                                                <p>
                                                                     MinimumInvestment : {project.MinimumInvestment}
                                                                    <br />Opens On : {getDate.getDate(project.InvestmentStartDate)}
                                                                </p>
                                                                <b>
                                                                    {(() => {
                                                                        let obj =  this.state.CurrentFundingCF.find(x => x.ProjectID === project.ProjectID);

                                                                        switch (project.IsCashCall) {
                                                                            case 0: return (
                                                                            	`$${((obj) ? Math.round(obj.CurrentFunding) : 0)} / $${project.FundingGoalMax}`
																			);
                                                                            case 1: return (
                                                                            	`$${project.ccCurrentFunding} / $${project.Amount}`
																			);
                                                                            default: return (
                                                                            	`$${project.currentFunding} / $${project.FundingGoal}`
																			);
                                                                        }
                                                                    })()}
                                                                </b>
                                                                {(() => {
                                                                	let obj =  this.state.CurrentFundingCF.find(x => x.ProjectID === project.ProjectID);

                                                                    switch (project.IsCashCall) {
                                                                        case 0: return (<div className="progress" style={{ width: '100%' }}>
                                                                            <div className="progress-bar" role="progressbar" aria-valuenow={70} aria-valuemin={0} aria-valuemax={100} style={{ width: "" + (((obj) ? Math.round(obj.CurrentFunding) : 0) / project.FundingGoalMax) * 100 + "%" }} />
                                                                        </div>);
                                                                        case 1: return (<div className="progress" style={{ width: '100%' }}>
                                                                            <div className="progress-bar" role="progressbar" aria-valuenow={70} aria-valuemin={0} aria-valuemax={100} style={{ width: "" + (project.ccCurrentFunding / project.Amount) * 100 + "%" }} />
                                                                        </div>);
                                                                        default: return (<div className="progress" style={{ width: '100%' }}>
                                                                            <div className="progress-bar" role="progressbar" aria-valuenow={70} aria-valuemin={0} aria-valuemax={100} style={{ width: "" + (project.currentFunding / project.FundingGoal) * 100 + "%" }} />
                                                                        </div>);
                                                                    }
                                                                })()}
                                                                    <div> {(() => {
                                                                    	let obj = this.state.CurrentFundingCF.find(x => x.ProjectID === project.ProjectID);

                                                                        switch (project.IsCashCall) {
                                                                            case 0: return (Math.round((((obj) ? Math.round(obj.CurrentFunding) : 0) / project.FundingGoalMax) * 100) + '%');
                                                                            case 1: return (Math.round((project.ccCurrentFunding / project.Amount) * 100) + '%');
                                                                            default: return (Math.round((project.currentFunding / project.FundingGoal) * 100) + '%');
                                                                        }
                                                                    })()} </div><br />
                                                                        <button className="btn btn-primary" onClick={(e) => this.onDealClickCF(project.ProjectID)} style={{ width: '100%' }}>Finalize Investment</button>
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                    </span>
                                                                ))
                                                            }
                                                             <div className="pull-right" style={{clear:'both'}}>
                                                                <Pagination
                                                                    firstPageText={<i className='glyphicon glyphicon-chevron-left' />}
                                                                    lastPageText={<i className='glyphicon glyphicon-chevron-right' />}
                                                                    prevPageText={<i className='glyphicon glyphicon-menu-left' />}
                                                                    nextPageText={<i className='glyphicon glyphicon-menu-right' />}
                                                                    activePage={this.state.activePage}
                                                                    itemsCountPerPage={this.state.perPage}
                                                                    totalItemsCount={this.state.totalCount}
                                                                    onChange={this.handlePageChange}
                                                                />
                                                            </div>
                                                        </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const { object, bool } = PropTypes;

PreviewProjects.propTypes = {
    session: object.isRequired,
    authenticated: bool.isRequired,
    checked: bool.isRequired
};

const mapState = ({ session }) => ({
    session: session.user,
    checked: session.checked,
    authenticated: session.authenticated
});

export default withRouter(connect(mapState)(PreviewProjects));
