import initialState from '../../../../reducers/appInitialState';
import * as ROCActions from '../actions/actions';
import {updateRequestCounter} from "../../../../utils/General";

export default function (state = initialState.returnOfCapital, action) {
    const {plus, minus} = updateRequestCounter(state, 'ROCOnLFightReq');

    switch (action.type) {
        case ROCActions.RROC_START:
            return {...state, ROCOnLFightReq: plus};
        case ROCActions.RROC_END:
            return {...state, ROCOnLFightReq: minus};
        case ROCActions.RROC_ALL_PROJECTS:
            return {...state, ...action.data, ROCOnLFightReq: minus};
        case ROCActions.RROC_ALL_RETURNS_OF_CAPITAL:
            return {...state, ...action.data, ROCOnLFightReq: minus};
        case ROCActions.RROC_VERIFY_RETURNS_OF_CAPITAL:
            return {...state, ...action.data, ROCOnLFightReq: minus};
        default:
            return {...state};
    }
}
