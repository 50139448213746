import React, { Component } from "react";
import Chart from "react-apexcharts";

import moment from "moment";
import { formatChartMoney, formatChartLabel } from "../../../utils/General";
class InvestmentChart extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		const {
			numberOfInvestments,
			investmentLabels,
			cumulativeEarnings,
			cumulativeEarningsTimeSeries,
			cumulativeInvestmentTimeSeries,
		} = this.props;
		const investmentSeries = cumulativeInvestmentTimeSeries?.map((i) => ({
			x: i.x,
			y: parseFloat(i.y.toFixed(0)),
		}));
		const earningsSeries = cumulativeEarningsTimeSeries.map((i) => ({
			x: i.x,
			y: parseFloat(i.y.toFixed(0)),
		}));

		const formatinvestment = investmentSeries.map((item) => {
			return {
				s: moment(item.x).format("YYYY-MM"),
				x: moment(item.x).format("YYYY-MMMM"),
				y: item.y,
				z: "",
			};
		});

		let combinedData = [...formatinvestment];

		for (let index = 0; index < earningsSeries.length; index++) {
			let isFound = combinedData.find(
				(c) => c.s == moment(earningsSeries[index].x).format("YYYY-MM")
			);
			if (isFound) {
				isFound.z = earningsSeries[index].y;
			} else {
				combinedData.push({
					s: moment(earningsSeries[index].x).format("YYYY-MM"),
					x: moment(earningsSeries[index].x).format("YYYY-MMMM"),
					y: "",
					z: earningsSeries[index].y,
				});
			}
		}

		combinedData.sort((a, b) => (a.s < b.s ? -1 : a.s === b.s ? 0 : 1));

		//Default previous value, since this is a cumulative chart
		for (let index = 1; index < combinedData.length; index++) {
			if (!combinedData[index].y)
				combinedData[index].y = combinedData[index - 1].y;
			if (!combinedData[index].z)
				combinedData[index].z = combinedData[index - 1].z;
		}

		//Remove duplicates
		const all = [...combinedData];
		combinedData = [];
		if (all.length) combinedData.push(all[0]);
		for (let index = 1; index < all.length; index++) {
			if (all[index].s == all[index - 1].s) {
				combinedData[combinedData.length - 1] = all[index];
			} else {
				combinedData.push(all[index]);
			}
		}

		const newCombinedData = [];
		if (combinedData.length > 0) {
			newCombinedData.push(combinedData[0]);

			for (let index = 1; index < combinedData.length; index++) {
				let nextMonth = moment(combinedData[index - 1].s)
					.add(1, "M")
					.format("YYYY-MM");
				if (nextMonth == combinedData[index].s)
					newCombinedData.push({ ...combinedData[index] });
				else {
					do {
						let previousData = { ...combinedData[index - 1] };
						previousData.s = nextMonth;
						previousData.x = moment(nextMonth).format("YYYY-MMMM");
						newCombinedData.push(previousData);
						nextMonth = moment(nextMonth)
							.add(1, "M")
							.format("YYYY-MM");
					} while (nextMonth != combinedData[index].s);
					newCombinedData.push({ ...combinedData[index] });
				}
			}
		}

	

		const setColumnWidth = () => {
			const seriesLength = combinedData.map((i) => i.y)?.length;
			if (seriesLength > 15) {
				return "70%";
			} else if (seriesLength > 10 && seriesLength <= 15) {
				return "60%";
			} else if (seriesLength > 5 && seriesLength <= 10) {
				return "50%";
			} else if (seriesLength > 2 && seriesLength <= 5) {
				return "30%";
			} else if (seriesLength <= 2) {
				return "5%";
			}
		};
		const series = [
			{
				name: "Amount Invested",
				data: newCombinedData?.map((i) => i?.y),
			},

			{
				name: "Cumulative Distributions",
				data: newCombinedData?.map((i) => i?.z),
			},
		];
		const options = {
			tooltip: {
				x: {
					formatter: function (val, index) {
						return moment(val).format("YYYY-MMMM");
					},
				},
				y: {
					formatter: function (val, index) {
						return formatChartMoney(val);
					},
				},
			},
			chart: {
				type: "bar",
				height: 350,
				stacked: true,
				// stackType: "100%",
				fontFamily: "Montserrat, Arial, sans-serif",
				toolbar: {
					show: false,
				},
			},
			colors: ["#182f3b", "#5bb462"],

			plotOptions: {
				bar: {
					columnWidth: setColumnWidth(),
				},
			},
			markers: {
				size: 4,
			},
			xaxis: {
				type: "datetime",
				// labels: {
				// 	formatter: function (val) {
				// 		return moment(val).format("YYYY MMMM");
				// 	},
				// },
			},
			title: {
				text: "",
			},
			dataLabels: {
				enabled: false,
			},
			labels: newCombinedData?.map((i) => i?.x),

			yaxis: [
				{
					labels: {
						formatter: function (val, index) {
							return formatChartLabel(val);
						},
					},
					title: {
						text: "Amount Invested",
					},
				},
			],
		};

		return (
			<>
				<h3 style={{ borderBottom: "1px", margin: 0, padding: "10px" }}>
					Amount Invested{" "}
					<span
						style={{
							fontSize: "12px",
							display: "block",
							fontWeight: 400,
							marginTop: "5px",
							lineHeight: "18px",
						}}
					>
						Includes your original investment, plus reinvestments held by the Fund in your name, but does not include cash distributions.
					</span>
				</h3>
				<Chart
					options={options}
					series={series}
					type="bar"
					height={340}
				/>
			</>
		);
	}
}
export default InvestmentChart;
