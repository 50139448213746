import PropTypes from 'prop-types'
import React from 'react';
import Icon from "../../../IconComponent/IconComponent";
import {Nav, Navbar, NavItem} from 'react-bootstrap';

class ProjectDetailTabsHeader extends React.Component {
    moveBack = () => {
        const selectedTabIndex = this.getIndex();

        let newTabIndex = (selectedTabIndex - 1);
        if (newTabIndex < 0) newTabIndex = 0;

        this.selectTabOnMove(newTabIndex);
    };

    moveForward = () => {
        const menuItemsLength = (this.props.menuItems.length - 1);
        const selectedTabIndex = this.getIndex();

        let newTabIndex = (selectedTabIndex + 1);
        if (newTabIndex > menuItemsLength) newTabIndex = menuItemsLength;

        this.selectTabOnMove(newTabIndex);
    };

    getIndex = () => {
        const {selectedTab, menuItems} = this.props;
        const selectedTabObj = menuItems.find(item => item.id === selectedTab);

        return menuItems.indexOf(selectedTabObj);
    };

    selectTabOnMove = (newTabIndex) => {
        const {menuItems} = this.props;
        const newSelectedTab = menuItems[newTabIndex].id;

        this.props.onTabSelected(newSelectedTab);
    };

    renderMenuItems = () => {
        const {menuItems, selectedTab} = this.props;

        return menuItems.map(item => (
            <NavItem key={item.id}
                     onClick={() => this.props.onTabSelected(item.id)}
                     className={selectedTab === item.id ? 'active' : null}>
                {item.title}
            </NavItem>
        ));
    };

    render = () => {
        return (
            <Navbar className="project-details-table-header">
                <Nav>
                    {this.renderMenuItems()}
                </Nav>
                <Nav pullRight>
                    <NavItem className="navigation-button">
                        <button className="btn btn-link"
                                onClick={this.moveBack}>
                            <Icon iconName="chevron-left"/>
                        </button>
                        <button className="btn btn-link"
                                onClick={this.moveForward}>
                            <Icon iconName="chevron-right"/>
                        </button>
                    </NavItem>
                </Nav>
            </Navbar>
        );
    };
}

ProjectDetailTabsHeader.propTypes = {
    menuItems: PropTypes.array.isRequired,
    onTabSelected: PropTypes.func.isRequired,
    selectedTab: PropTypes.any.isRequired
};

ProjectDetailTabsHeader.defaultProps = {
    menuItems: [],
    onTabSelected: () => null,
    selectedTab: 0
};

export default ProjectDetailTabsHeader;
