import React, { Component } from 'react';
import swal from 'sweetalert';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import RequestService from "./api/RequestService";


class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            isLoaded: false,
            errorOnLoad: false
        };

        // console.log('match.params.id = ', this.props.match.params.id);
    }



    componentDidMount() {
        window.scrollTo(0, 0);
    }



    onSubmit = async () => {
        if (this.refs.pass1.value !== this.refs.pass2.value) {
            swal('Passwords do not match', '', 'error');
        }
        else {
            try{
            let formBody = [];
            formBody.push("pass=" + encodeURIComponent(this.refs.pass1.value));
            formBody = formBody.join("&");

            const {data: status} = await RequestService.post({
                url: `/api/resetPassword/${this.props.match.params.id}`,
                data: formBody,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            });

            if (status.code === 200) {
                swal("Password Changed", " ", "success")
                    .then(() => {
                        if (this.props.isMarketplace) {
                            this.props.history.push("/marketplace/login");
                        }
                        else {
                            this.props.history.push("/login");
                        }
                    });
            } else if (status.code === 400) {
                swal(status.message, " ", "error");
            } else {
                swal(status.message, " ", "error");
            }
        }catch(error){
            console.log("error on resetting password", error);
            swal(error?.message, " ", "error");
        }
        }
    };



    render() {
        return (
            <div className="main_container">
                {/* page content */}
                <div className="right_col" role="main">
                    <div className="container">
                        <div className="row">
                            {/* panel preview */}
                            <div className="forgot_style" >
                                <center><h3>Please enter your new Password</h3></center>
                                <div className="panel panel-default">
                                    <div className="panel-body form-horizontal payment-form">
                                        <br/>
                                        <br/>
                                        <div className="form-group">
                                            <label htmlFor="concept" className="col-sm-4 control-label">Password:</label>
                                            <div className="col-sm-8">
                                                <input type="password" className="form-control" id="concept" name="concept" ref="pass1"/>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="concept" className="col-sm-4 control-label">Confirm Password:</label>
                                            <div className="col-sm-8">
                                                <input type="password" className="form-control" id="concept" name="concept" ref="pass2"/>
                                            </div>
                                        </div>
                                        <br/>
                                        <center><button type="submit" onClick={this.onSubmit} className="btn btn-info" style={{width: '20%'}}>Submit</button></center>
                                    </div>
                                </div>
                            </div> {/* / panel preview */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const { object, bool } = PropTypes;

ResetPassword.propTypes = {
    session: object.isRequired,
    authenticated: bool.isRequired,
    checked: bool.isRequired
};

const mapState = ({ session }) => ({
    session: session.user,
    checked: session.checked,
    authenticated: session.authenticated
});

export default withRouter(connect(mapState)(ResetPassword));
