import React from "react";
import PropTypes from "prop-types";
import {Modal, Col, Row} from "react-bootstrap";
import ProjectUpdateTypeEditor from "./ProjectUpdateTypeEditor";

const propType = {
    closeModal: PropTypes.func.isRequired,
    daysAsPrivateUpdate: PropTypes.number.isRequired,
    isPrivateForEver: PropTypes.bool.isRequired,
    openModal: PropTypes.bool.isRequired,
    onChangeHandler: PropTypes.func.isRequired,
    onCheckboxSelected: PropTypes.func.isRequired,
    onSaveChanges: PropTypes.func.isRequired,
    subject: PropTypes.string.isRequired,
    updateType: PropTypes.number.isRequired,
};

const defaultFunc = () => null;
const defaultProps = {
    closeModal: defaultFunc,
    daysAsPrivateUpdate: -1,
    isPrivateForEver: true,
    openModal: false,
    onChangeHandler: defaultFunc,
    onCheckboxSelected: defaultFunc,
    onSaveChanges: defaultFunc,
    subject: "",
    updateType: -1,
};

const ProjectUpdateDisplayStatusEditor = (props) => {
    let {
        closeModal, daysAsPrivateUpdate, openModal, onChangeHandler,
        onCheckboxSelected, subject, isPrivateForEver, updateType, onSaveChanges,
    } = props;

    return (
        <Modal show={openModal}
               onHide={closeModal}
               style={{padding: "0 !important"}}>
            <Modal.Header>
                <h2>Update Display Status For:</h2>
                <h3>{subject}</h3>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={12} sm={12} md={4}/>
                    <Col xs={12} sm={12} md={4}>
                        <ProjectUpdateTypeEditor daysAsPrivateUpdate={daysAsPrivateUpdate}
                                                 isPrivateForEver={isPrivateForEver}
                                                 onChangeHandler={onChangeHandler}
                                                 onCheckboxSelected={onCheckboxSelected}
                                                 updateType={updateType}/>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-primary btn-sm pull-right"
                        onClick={onSaveChanges}
                        type="button">
                    Save
                </button>
                <button className="btn btn-danger btn-sm pull-right"
                        onClick={closeModal}
                        type="button">
                    Cancel
                </button>
            </Modal.Footer>
        </Modal>
    );
};

ProjectUpdateDisplayStatusEditor.propTypes = propType;
ProjectUpdateDisplayStatusEditor.defaultProps = defaultProps;

export default ProjectUpdateDisplayStatusEditor;

