/*
 * This file is used as menu item under Fundraiser -> My Fund Raises (not currently in proper use).
 */

import React, { Component } from 'react';
import store from '../../index'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Pagination from 'react-js-pagination';
import getDate from '../../utils/ConvertDate';
import {CSVLink, CSVDownload} from 'react-csv';
import {
    Modal,
    ModalHeader,
    ModalTitle,
    ModalClose,
    ModalBody,
    ModalFooter
  } from 'react-bootstrap';

class Userinvestments extends Component {
    constructor(props){
        super(props);
        this.state={
            fileName:"",
            Invester:[],
            totalProjects: [],
            allProjects: [],
            pageNumber: 1,
            totalCount: 0,
            perPage: 10,
            activePage: 1,

            show: false,
            Views:[],
            InvesterCF: [],
            totalProjectsCF: [],
            allProjectsCF: [],
            pageNumberCF: 1,
            totalCountCF: 0,
            perPageCF: 10,
            activePageCF: 1,

            id:props.session.id,
            isLoaded:false,
            errorOnLoad:false
        };
        this.userinv=this.userinv.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handlePageChangeCF = this.handlePageChangeCF.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }
    handleClose() {
        this.setState({ show: false });
    }
    componentWillMount(){
        window.scrollTo(0, 0);
        this.getRoles();
    }
    handlePageChange(pageNumber) {
        var prev = ((pageNumber) - 1) * 10;
        var next = prev + 10;
        this.setState({
            activePage: pageNumber,
            Invester: this.state.allProjects.slice(prev, next)
        });
    }
    handlePageChangeCF(pageNumber) {
        var prev = ((pageNumber) - 1) * 10;
        var next = prev + 10;
        this.setState({
            activePage: pageNumber,
            InvesterCF: this.state.allProjectsCF.slice(prev, next)
        });
    }
    userinv(){
        fetch('/api/fundUserinvestments/'+this.state.id+'/'+this.props.session.marketing,{
            method:'get'
        })

            .then((response)=>{
                return response.json()

            })
            .then((data)=>{
                this.setState({

                    Invester:data.reg_d,
                    totalProjects: data.reg_d,
                    allProjects: data.reg_d,
                    pageNumber: 1,
                    totalCount: data.reg_d.length,
                    perPage: 10,
                    activePage: 1,

                    InvesterCF: data.reg_cf.slice(0, 10),
                    totalProjectsCF: data.reg_cf,
                    allProjectsCF: data.reg_cf,
                    pageNumberCF: 1,
                    totalCountCF: data.reg_cf.length,
                    perPageCF: 10,
                    activePageCF: 1,

                    Roles: [],
                    MainRole: "Investor",
                    isLoaded:true,
                    err:{},
                    errorOnLoad:false,

                });
            })
            .catch((err)=>{
                this.setState({
                    err:err,
                    errorOnLoad:true
                })

            })
    }

    getRoles() {
        fetch('/api/getrole/' + this.state.id+'/'+this.state.id+'/'+this.props.session.marketing, {
            method: 'get'
        })
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                for (var i = 0; i < data.length; i++) {
                    if (data[i].Role === "SuperAdmin") {
                        this.setState({
                            Roles: data,
                            MainRole: "SuperAdmin",
                            isLoaded: true,
                            err: {},
                            errorOnLoad: false
                        });
                        break;
                    }
                    else if (data[i].Role === "Admin") {
                        this.setState({
                            Roles: data,
                            MainRole: "Admin",
                            isLoaded: true,
                            err: {},
                            errorOnLoad: false
                        });
                        break;
                    }
                    else if (data[i].Role === "FundRaiser") {
                        this.setState({
                            Roles: data,
                            MainRole: "FundRaiser",
                            isLoaded: true,
                            err: {},
                            errorOnLoad: false
                        });
                        break;
                    } else {
                        this.setState({
                            Roles: data,
                            MainRole: "Investor",
                            isLoaded: true,
                            err: {},
                            errorOnLoad: false
                        });
                        break;
                    }
                }
            })
            .catch((err) => {
                this.setState({
                    err: err,
                    errorOnLoad: true
                })
            })
    }


    onView(id,name){

        fetch('/api/getUserInvestmentforProject/'+id,{
            method:'get'
        })
            .then((response)=>{
                return response.json()

            })
            .then((data)=>{
                this.setState({
                    Views:data,
                    fileName:name,
                    isLoaded:true,
                    err:{},
                    errorOnLoad:false,
                    show: !this.state.show,
                });



            })
            .catch((err)=>{
                this.setState({
                    err:err,
                    errorOnLoad:true
                })

            })
    }


    componentDidMount(){
        store.subscribe(() => {
            var currentState=store.getState();
            this.setState({
                id:currentState.session.user.id
            });
        });
        this.userinv();
    }
    render() {
         let IDs = [];
        return(
            <div className="container body">
                <div className="main_container">
                    {/* page content */}
                    <div className="right_col" role="main">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                        </div>

                        <div className="row">
                            <div>

                                    {
                                        (() => {
                                            if(this.state.MainRole !== "FundRaiser") {
                                                return(
                                                    <ul className="nav nav-pills nav-justified" style={{ padding: '0 10px', marginBottom: 20 }}>
                                                        <li className="active"><a data-toggle="pill" href="#menu1">EnergyFunders Black (Reg D)</a></li>
                                                        <li><a data-toggle="pill" href="#home">EnergyFunders Marketplace (Reg CF)</a></li>
                                                    </ul>
                                                );
                                            }

                                        })()
                                    }
                                <div className="tab-content">
                                    <div id="home" className="tab-pane fade">
                                        <div className="col-md-12 col-sm-12 col-xs-12">
                                            <div className="x_panel">
                                                <div className="x_title">
                                                    <h4 className='up'>User Investments </h4>
                                                    <div className="clearfix" />
                                                </div>
                                                <div className="x_content">

                                                    <div className="table-responsive">
                                                        <table className="table table-striped jambo_table ">
                                                            <thead className="thead-inverse">
                                                                <tr className="headings">

                                                                    <th className="column-title">Investment</th>
                                                                    <th className="column-title">Funding Goal Max</th>
                                                                    <th className="column-title">Funding Goal Min</th>
                                                                    <th className="column-title">Project Type</th>
                                                                    <th className="column-title">Min. Inv.</th>
                                                                    <th className="column-title">Details</th>



                                                                </tr>
                                                            </thead>
                                                            {(() => {
                                                            switch (this.state.InvesterCF.length<1) {
                                                            case false: return (

                                                            <tbody>

                                                                {

                                                                     this.state.InvesterCF.map((inv) => {

                                                                         if(!IDs.includes(inv.ProjectID)){
                                                                             IDs.push(inv.ProjectID);
                                                                             return(
                                                                        <tr key={inv.UserID} className="even pointer">
                                                                                  <td className=" ">{inv.InvestmentName}</td>
                                                                            <td className=" ">{inv.FundingGoalMax}</td>
                                                                            <td className=" ">{inv.FundingGoalMin}</td>
                                                                            <td className=" ">{inv.ProjectCategory}</td>
                                                                            {/* <td className=" ">Reg CF</td> */}
                                                                            <td className=" ">{inv.MinimumInvestment}</td>
                                                                            <td className=" "><a href="javascript:void(0);" onClick={()=>this.onView(inv.ProjectID,inv.InvestmentName)}>View Investor List</a></td>



                                                                            {/* <td><a className="btn btn-primary" href={inv.ContractFilePath} download>Download</a></td> */}

                                                                        </tr>
                                                                             )
                                                                         }
                                                                     })

                                                                }

                                                            </tbody>
                                                            );
                                                            case true: return (
                                                                <tbody>
                                                                  <tr  className="even pointer">
                                                                    <td colSpan="5" className="text-center">Nothing to show</td>
                                                                  </tr>
                                                                </tbody>
                                                                      );
                                                                    }
                                                            })()}
                                                        </table>
                                                        <div className="pull-right">
                                                            <Pagination
                                                                firstPageText={<i className='glyphicon glyphicon-chevron-left' />}
                                                                lastPageText={<i className='glyphicon glyphicon-chevron-right' />}
                                                                prevPageText={<i className='glyphicon glyphicon-menu-left' />}
                                                                nextPageText={<i className='glyphicon glyphicon-menu-right' />}
                                                                activePage={this.state.activePageCF}
                                                                itemsCountPerPage={this.state.perPageCF}
                                                                totalItemsCount={this.state.totalCountCF}
                                                                onChange={this.handlePageChangeCF}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                                <Modal show={this.state.show} onHide={this.handleClose}>
                                                <Modal.Header closeButton>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <div className="x_panel">
                                                        <div className="x_title">
                                                            <h4 className='up'>Investor List</h4>
                                                            <div className="clearfix" />
                                                        </div>
                                                        <div className="x_content">

                                                            <div className="table-responsive">
                                                                <table className="table table-striped jambo_table ">
                                                                    <thead className="thead-inverse">
                                                                        <tr className="headings">

                                                                            <th className="column-title">Name</th>
                                                                            <th className="column-title">Email</th>
                                                                            <th className="column-title">Investment Amount</th>
                                                                            <th className="column-title">Investment Date</th>
                                                                            <th className="column-title">Payment status</th>
                                                                            <th className="column-title">Phone Number</th>
                                                                            <th className="column-title">Address</th>
                                                                            <th className="column-title">City</th>
                                                                            <th className="column-title">State</th>
                                                                            <th className="column-title">ZipCode</th>
                                                                            <th className="column-title">Country</th>
                                                                            <th className="column-title"></th>



                                                                        </tr>
                                                                    </thead>
                                                                    {(() => {
                                                                    switch (this.state.Views.length<1) {
                                                                    case false: return (

                                                                    <tbody>

                                                                        {
                                                                            this.state.Views.map((inv) => (
                                                                                <tr key={inv.UserInvestmentID} className="even pointer">
                                                                                    <td className=" ">{inv.FirstName} {inv.LastName}</td>
                                                                                    <td className=" ">{inv.Email}</td>
                                                                                    <td className=" ">{inv.InvestmentAmount}</td>
                                                                                    <td className=" ">{getDate.getDate(inv.InvestmentDate)}</td>

                                                                                    {(() => {
                                                                                        switch (inv.PaymentStatus) {
                                                                                            case 0  : return (<td>Pending</td>);
                                                                                            case 1  : return (<td>Processed</td>);
                                                                                            default : return (<td>Pending</td>);
                                                                                        }
                                                                                    })()}
                                                                                    <td className=" ">{inv.Phone}</td>
                                                                                    <td className=" ">{inv.Address}</td>
                                                                                    <td className=" ">{inv.City}</td>
                                                                                    <td className=" ">{inv.State}</td>
                                                                                    <td className=" ">{inv.ZipCode}</td>
                                                                                    <td className=" ">{inv.Country}</td>
                                                                                    {/* <td><a className="btn btn-primary" href={inv.ContractFilePath} download>Download</a></td> */}

                                                                                </tr>
                                                                            ))

                                                                        }

                                                                    </tbody>


                                                                    );
                                                                    case true: return (
                                                                        <tbody>
                                                                        <tr  className="even pointer">
                                                                            <td colSpan="5" className="text-center">Nothing to show</td>
                                                                        </tr>
                                                                        </tbody>
                                                                            );
                                                                            }
                                                                    })()}
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </Modal.Body>
                                                <div className="row">

                                                    <CSVLink data={this.state.Views} filename={this.state.fileName+".csv"}>  <div className="btn btn-primary col-md-4 col-md-offset-4"><span style={{color:"white"}}>Download As CSV</span>  </div></CSVLink>

                                                    <br/>
                                                </div>
                                                <Modal.Footer>
                                                </Modal.Footer>
                                                </Modal>
                                        </div>
                                    </div>
                                    <div id="menu1" className="tab-pane fade in active">
                                        <div className="col-md-12 col-sm-12 col-xs-12">
                                            <div className="x_panel">
                                                <div className="x_title">
                                                    <h4 className='up'>User Investments </h4>
                                                    <div className="clearfix" />
                                                </div>
                                                <div className="x_content">

                                        <div className="table-responsive">
                                            <table className="table table-striped jambo_table ">
                                                <thead className="thead-inverse">
                                                <tr className="headings">

                                                    <th className="column-title">Investment</th>
                                                    <th className="column-title">Funding Goal Max</th>
                                                    <th className="column-title">Funding Goal Min</th>
                                                    <th className="column-title">Project Type</th>
                                                    <th className="column-title">Min. Inv.</th>


                                                </tr>
                                                </thead>
                                                {(() => {
                                                switch (this.state.Invester.length<1) {
                                                case false: return (
                                                <tbody>

                                                {

                                                        this.state.Invester.map((inv) => {
                                                            if(!IDs.includes(inv.ProjectID)){
                                                                IDs.push(inv.ProjectID);
                                                                return(
                                                                <tr key={inv.ProjectID} className="even pointer">
                                                            <td className=" ">{inv.ProjectName}</td>
                                                                    <td className=" ">{inv.FundingGoalMax}</td>
                                                                    <td className=" ">{inv.FundingGoalmin}</td>
                                                                    {/* <td className=" ">{inv.ProjectCategory}</td> */}
                                                                    <td className=" ">Reg D</td>
                                                                    <td className=" ">{inv.MinInvestment}</td>
                                                                    {/* <td><a className="btn btn-primary" href={inv.ContractFilePath} download>Download</a></td> */}

                                                        </tr>
                                                                )
                                                            }
                                                        })

                                                }
                                                </tbody>
                                                );
                                                case true: return (
                                                <tbody>
                                                <tr  className="even pointer">
                                                    <td colSpan="5" className="text-center">Nothing to show</td>
                                                </tr>
                                                </tbody>
                                                    );
                                                    }
                                                 })()}


                                                        </table>
                                                        {/* <div className="pull-right">
                                                            <Pagination
                                                                firstPageText={<i className='glyphicon glyphicon-chevron-left' />}
                                                                lastPageText={<i className='glyphicon glyphicon-chevron-right' />}
                                                                prevPageText={<i className='glyphicon glyphicon-menu-left' />}
                                                                nextPageText={<i className='glyphicon glyphicon-menu-right' />}
                                                                activePage={this.state.activePage}
                                                                itemsCountPerPage={this.state.perPage}
                                                                totalItemsCount={this.state.totalCount}
                                                                onChange={this.handlePageChange}
                                                            />
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


        );
    }
}

const { object, bool } = PropTypes;

Userinvestments.propTypes = {
    session: object.isRequired,
    authenticated: bool.isRequired,
    checked: bool.isRequired
};

const mapState = ({ session }) => ({
    session: session.user,
    checked: session.checked,
    authenticated: session.authenticated
});

export default connect(mapState)(Userinvestments);
