import initialState from '../../../../reducers/appInitialState';
import * as Actions from '../actions/actions';
import { updateRequestCounter } from '../../../../utils/General';

export default (state = initialState.investorFiles, action) => {
    const { plus, minus } = updateRequestCounter(state, 'IFOnFlightRequest');

    switch (action.type) {
        case Actions.INVESTOR_FILES_START_REQUEST:
            return {
                ...state,
                IFOnFlightRequest: plus,
            };
        case Actions.INVESTOR_FILES_END_REQUEST:
            return {
                ...state,
                IFOnFlightRequest: minus,
            };
        case Actions.INVESTOR_FILES_GET_FILE_SUCCESS:
            return {
                ...state,
                IFOnFlightRequest: minus,
                investorFilesList: action.data,
            };
        case Actions.INVESTOR_CONTRACT_FILES_GET_FILE_SUCCESS:
            return {
                ...state,
                IFOnFlightRequest: minus,
                investorContractFilesList: action.data,
            };
        case Actions.INVESTOR_FILES_GET_INVESTOR_FILE_TYPES_SUCCESS:
            return {
                ...state,
                ...action.data,
                IFOnFlightRequest: minus,
            };
        case Actions.INVESTOR_FILES_GET_ALL_PROJECTS_SUCCESS:
            return {
                ...state,
                IFOnFlightRequest: minus,
                allProjects: action.data,
            };
        case Actions.ALL_PROJECT_FILES:
            return {
                ...state,
                IDMUOnFlightRequest: minus,
                allProjectFiles: action.data,
            };
        default:
            return { ...state };
    }
};
