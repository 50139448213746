import RequestService from '../../../../api/RequestService';
import { HTTP_ERR_BAD_REQUEST } from '../../../../utils/ApplicationConstants';
import NotificationService from '../../../../utils/NotificationService';

export const PROJECT_STATUS_START_REQUEST = 'PROJECT_STATUS_START_REQUEST';
export const PROJECT_STATUS_END_REQUEST = 'PROJECT_STATUS_END_REQUEST';
export const PROJECT_STATUS_GET_ALL_SUCCESS = 'PROJECT_STATUS_GET_ALL_SUCCESS';

const endRequest = () => ({ type: PROJECT_STATUS_END_REQUEST });
const startRequest = () => ({ type: PROJECT_STATUS_START_REQUEST });
const getAllSuccess = data => ({
    type: PROJECT_STATUS_GET_ALL_SUCCESS,
    data,
});

export const createNewStatus = data => async (dispatch) => {
    dispatch(startRequest());

    const { status } = await RequestService.post({
        url: '/api/admin/project/status',
        data,
    });

    if (status >= HTTP_ERR_BAD_REQUEST) {
        NotificationService.error('Error creating the new project status', 6000);
        dispatch(endRequest());
        return;
    }

    NotificationService.success();
    dispatch(getAllStatuses());
};

export const getAllStatuses = () => async (dispatch) => {
    dispatch(startRequest());

    const { data, status } = await RequestService.get('/api/admin/project/status/all');

    if (status >= HTTP_ERR_BAD_REQUEST) {
        NotificationService.error('', 6000);
        dispatch(endRequest());
        return;
    }

    dispatch(getAllSuccess(data));
};
