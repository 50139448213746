/**
 * this component code was extracted from the following web page:
 * SRC: https://reactjsexample.com/simple-donut-chart-with-react-js/
 * */

import React from 'react';
import PropTypes from 'prop-types';
import './DonutCharStyle.css';

const propTypes = {
    fundedIdentifier: PropTypes.number,
    valuelabel: PropTypes.string,
    size: PropTypes.number,
    strokewidth: PropTypes.number,
    collected: PropTypes.string,
    text: PropTypes.any.isRequired,
    minValue: PropTypes.number,
    investorCount: PropTypes.number,
};

const defaultProps = {
    fundedIdentifier: 0,
    valuelabel: 'Completed',
    size: 116,
    strokewidth: 26,
    text: 0,
    minValue: 0,
    investorCount: 0
};

class DonutChart extends React.Component {
    render() {
        const TOP_SPACE = 20;
        const halfSize = (this.props.size * 0.5);
        const radius = halfSize - (this.props.strokewidth * 0.5);

        const trackStyle = {strokeWidth: this.props.strokewidth};
        const rotateValue = 'rotate(-90 ' + halfSize + ',' + halfSize + ')';
        const circumference = 2 * Math.PI * radius;

        const strokeValue = ((this.props.fundedIdentifier * circumference) / 100);
        const dashValue = (strokeValue + ' ' + circumference);
        const indicatorStyle = {strokeWidth: this.props.strokewidth, strokeDasharray: dashValue};

        const strokeValue1 = ((this.props.minValue * circumference) / 100);
        const dashValue1 = (strokeValue1 + ' ' + circumference);
        const indicatorStyle1 = {strokeWidth: this.props.strokewidth, strokeDasharray: dashValue1};

        return (
            <svg className="donutchart" x={0} y={0}>
                <circle r={radius}
                        cx={halfSize}
                        cy={halfSize}
                        transform={rotateValue}
                        style={trackStyle}
                        className="donutchart-track"/>

                <circle r={radius}
                        cx={halfSize}
                        cy={halfSize}
                        transform={rotateValue}
                        style={indicatorStyle1}
                        className="donutchart-indicator-min"/>

                <circle r={radius}
                        cx={halfSize}
                        cy={halfSize}
                        transform={rotateValue}
                        style={indicatorStyle}
                        className="donutchart-indicator"/>

                <text className="donutchart-text"
                      x={halfSize}
                      y={halfSize - 20}
                      style={{textAnchor: 'middle'}}>
                    <tspan className="donutchart-text-val">
                        {this.props.text}%
                    </tspan>
                    <tspan className="donutchart-text-label"
                           x={halfSize}
                           y={halfSize+ 10}>
                        {this.props.valuelabel}
                    </tspan>
                    <tspan className="donutchart-text-label"
                           x={halfSize}
                           y={halfSize + 30}>
                        {this.props.investorCount}
                    </tspan>
                </text>

                <text className="donutchart-text"
                      x={halfSize}
                      y={halfSize + 60}
                      style={{textAnchor: 'middle'}}>
                    <tspan className="donutchart-text-recollection-of">
                        {this.props.collected}
                    </tspan>
                </text>
            </svg>
        );
    }
}

DonutChart.propTypes = propTypes;
DonutChart.defaultProps = defaultProps;

export default DonutChart;
