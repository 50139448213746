import React, { Component } from 'react';
import store from '../../index'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Pagination from 'react-js-pagination';
import getDate from '../../utils/ConvertDate'
import RequestService from '../../api/RequestService';
import { formatMoney } from '../../utils/General';


class Companyearnings extends Component {
    constructor(props){
        super(props);

        this.state = {
			id: props.session.id,

			Earnings: [],
			totalProjects: [],
			allProjects: [],
			pageNumber: 1,
			totalCount: 0,
			perPage: 10,
			activePage: 1,
			totalEarningsRegD: 0,

			Earningscf: [],
			totalProjectscf: [],
			allProjectscf: [],
			pageNumbercf: 1,
			totalCountcf: 0,
			perPagecf: 10,
			activePagecf: 1,
			totalEarningsRegCF: 0
        };

        this.handlePageChange = this.handlePageChange.bind(this);
        this.handlePageChangeCF = this.handlePageChangeCF.bind(this);
    }



    componentDidMount() {
        window.scrollTo(0, 0);

		store.subscribe(() => {
			let currentState = store.getState();

			this.setState({
				id: currentState.session.user.id
			});
		});

		this.getCompanyEarnings();
    }



    async getCompanyEarnings() {
		const { data } = await RequestService.get(`/api/getCompanyEarnings`);

		if (data.code === 200) {
			this.setState({
				Earnings:data.reg_d.slice(0, 10),
				totalProjects: data.reg_d,
				allProjects: data.reg_d,
				pageNumber: 1,
				totalCount: data.reg_d.length,
				perPage: 10,
				activePage: 1,
				totalEarningsRegD: data.totalEarningsRegD,

				Earningscf: data.reg_cf.slice(0, 10),
				totalProjectscf: data.reg_cf,
				allProjectscf: data.reg_cf,
				pageNumbercf: 1,
				totalCountcf: data.reg_cf.length,
				perPagecf: 10,
				activePagecf: 1,
				totalEarningsRegCF: data.totalEarningsRegCF
			});
		}
    }



     handlePageChange(pageNumber) {
        let prev = ((pageNumber) - 1) * 10;
        let next = prev + 10;

        this.setState({
            activePage: pageNumber,
            Earnings: this.state.allProjects.slice(prev, next)
        });
    }



    handlePageChangeCF(pageNumber) {
        let prev = ((pageNumber) - 1) * 10;
        let next = prev + 10;

        this.setState({
            activePagecf: pageNumber,
            Earningscf: this.state.allProjectscf.slice(prev, next)
        });
    }



    renderRegD() {
    	return(
			<div className="col-md-12 col-sm-12 col-xs-12">
				<div className="x_panel">
					<div className="x_title">
						<h4 className='up'>Total Company Earnings: ${formatMoney(this.state.totalEarningsRegD)}</h4>
						<div className="clearfix" />
					</div>
					<div className="x_content">
						<div className="table-responsive">
							<table className="table table-striped jambo_table ">
								<thead className="thead-inverse">
								<tr className="headings">
									<th className="column-title">Payment ID</th>
									<th className="column-title">Earning Date</th>
									<th className="column-title">Project Name</th>
									<th className="column-title">Entity</th>
									<th className="column-title">Earning Amount</th>
								</tr>
								</thead>

								{(() => {
									if (this.state.Earnings.length > 0) {
										return (
											<tbody>
											{
												this.state.Earnings.map((inv)=>(
													<tr key={inv.EarningId} className="even pointer">
														<td className=" ">{inv.PaymentID}</td>
														<td className=" ">{getDate.getDate(inv.EarningDate)}</td>
														<td className=" ">{inv.ProjectName}</td>
														<td className=" ">{inv.Entity}</td>
														<td className=" ">${formatMoney(inv.EarningAmount)}</td>
													</tr>
												))
											}
											</tbody>
										);
									}
									else {
										return (
											<tbody>
											<	tr className="even pointer">
													<td colSpan="5" className="text-center">Nothing to Show</td>
												</tr>
											</tbody>
										);
									}
								})()}
							</table>

							<div className="pull-right">
								<Pagination
									firstPageText={<i className='glyphicon glyphicon-chevron-left' />}
									lastPageText={<i className='glyphicon glyphicon-chevron-right' />}
									prevPageText={<i className='glyphicon glyphicon-menu-left' />}
									nextPageText={<i className='glyphicon glyphicon-menu-right' />}
									activePage={this.state.activePage}
									itemsCountPerPage={this.state.perPage}
									totalItemsCount={this.state.totalCount}
									onChange={this.handlePageChange}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}



    renderRegCF() {
    	return (
			<div className="col-md-12 col-sm-12 col-xs-12">
				<div className="x_panel">
					<div className="x_title">
						<h4 className='up'>Total Company Earnings: ${formatMoney(this.state.totalEarningsRegCF)}</h4>
						<div className="clearfix" />
					</div>
					<div className="x_content">
						<div className="table-responsive">
							<table className="table table-striped jambo_table ">
								<thead className="thead-inverse">
								<tr className="headings">
									<th className="column-title">Payment ID</th>
									<th className="column-title">Earning Date</th>
									<th className="column-title">Project Name</th>
									<th className="column-title">Earning Amount </th>
								</tr>
								</thead>
								{(() => {
									if (this.state.Earningscf.length > 0) {
										return (
											<tbody>
											{
												this.state.Earningscf.map((inv) => (
													<tr key={inv.EarningId} className="even pointer">
														<td className=" ">{inv.PaymentID}</td>
														<td className=" ">{getDate.getDate(inv.EarningDate)}</td>
														<td className=" ">{inv.InvestmentName}</td>
														<td className=" ">${formatMoney(inv.EarningAmount)}</td>
													</tr>
												))
											}
											</tbody>
										);
									}
									else {
										return (
											<tbody>
												<tr className="even pointer">
													<td colSpan="4" className="text-center">Nothing to Show</td>
												</tr>
											</tbody>
										);
									}
								})()}
							</table>

							<div className="pull-right">
								<Pagination
									firstPageText={<i className='glyphicon glyphicon-chevron-left' />}
									lastPageText={<i className='glyphicon glyphicon-chevron-right' />}
									prevPageText={<i className='glyphicon glyphicon-menu-left' />}
									nextPageText={<i className='glyphicon glyphicon-menu-right' />}
									activePage={this.state.activePagecf}
									itemsCountPerPage={this.state.perPagecf}
									totalItemsCount={this.state.totalCountcf}
									onChange={this.handlePageChangeCF}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}



	render() {
		return (
			<div className="container body">
				<div className="main_container">
					{/* page content */}
					<div className="right_col" role="main">
						<div className="page-title">
							<div className="title_left">
								<h3>Company Earnings</h3>
							</div>
						</div>
						<div className="clearfix" />
						<div className="row">
							<div>
								<ul className="nav nav-pills nav-justified" style={{ padding: '0 10px', marginBottom: 20 }}>
									<li className="active"><a data-toggle="pill" href="#menu1">EnergyFunders Black (Reg D)</a></li>
									<li><a data-toggle="pill" href="#home">EnergyFunders Marketplace (Reg CF)</a></li>
								</ul>

								<div className="tab-content">
									<div id="home" className="tab-pane fade">
										{this.renderRegCF()}
									</div>
									<div id="menu1" className="tab-pane fade in active">
										{this.renderRegD()}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const { object, bool } = PropTypes;

Companyearnings.propTypes = {
    session: object.isRequired,
    authenticated: bool.isRequired,
    checked: bool.isRequired
};

const mapState = ({ session }) => ({
    session: session.user,
    checked: session.checked,
    authenticated: session.authenticated
});

export default connect(mapState)(Companyearnings);
