import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';

import RequestService from '../../api/RequestService';



function AdminTransferFunds() {
	const formRef = useRef(null);



	useEffect(() => {
		window.scrollTo(0, 0);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);	// [] Means componentDidMount (once).



	async function submitTransferFunds(e) {
		e.preventDefault();

		let source = formRef.current['source'].value.trim();
		let dest = formRef.current['dest'].value.trim();
		let amount = formRef.current['amount'].value.replace(/\D/g, '').trim();

		if (source && dest && amount) {
			const { data } = await RequestService.post({
				url: `/api/superadmin/transferfunds`,
				body: {
					source, dest, amount
				}
			});

			console.log(data);
		}
		else {
			Swal.fire('Please Complete All Fields', '', 'error');
		}

	}



	return (
		<div>
			<br/>
			<div className="page-title">
				<div className="title_left">
					<h3>Transfer Funds Page</h3>
				</div>
			</div>
			<form ref={formRef}>
				<div className="col-md-6 paddingLeft-30px">
					<div className="panel panel-primary" style={{ border: 0, borderRadius: 0, boxShadow: 'none' }}>
						<div className="row">
							<div className="col-xs-12 paddingBottom-30px">
								<div className="form-group">
									<label htmlFor="fname">Source ID *</label>
									<input name="source" id="source" className="signup-form-control form-control" type="text" data-validation="required"
										   placeholder="Source ID"
									/>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-xs-12 paddingBottom-30px">
								<div className="form-group">
									<label htmlFor="lname">Destination ID *</label>
									<input name="dest" id="dest" className="signup-form-control form-control" type="text" data-validation="required"
										   placeholder="Destination ID"
									/>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-xs-12 paddingBottom-30px">
								<div className="form-group">
									<label htmlFor="lname">Amount *</label>
									<input name="amount" id="amount" className="signup-form-control form-control" type="text" data-validation="required"
										   placeholder="Amount"
									/>
								</div>
							</div>
						</div>
						<button id="submit1" onClick={submitTransferFunds} value="submit" className="btn center" style={{ height: 50, width: 250, backgroundColor: "#5bb462", color: '#fff' }}>
							Transfer Funds
						</button>
					</div>
				</div>
			</form>
		</div>
	);
}



const mapState = ({session}) => ({
	session: session.user,
	checked: session.checked,
	authenticated: session.authenticated
});

export default withRouter(connect(mapState)(AdminTransferFunds));
