
import React, { Component } from 'react';
import swal from 'sweetalert';
import { withRouter } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import RequestService from '../../api/RequestService';
import RecordEarnings from './RecordEarnings';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { formatMoney } from '../../utils/General';
import { BiBitcoin } from 'react-icons/bi';
import {FaEdit} from 'react-icons/fa';



class Distribution extends Component {
    constructor(props) {
        super(props);

        this.state = {
            projectName: '',
            id: props.session.id,
            ProjectsRegD: [],
            ProjectsRegCF: [],
            investorEarnings: [],
			investorEarningsCSV: [],
            companyEarnings: [],
            companyEarningsInBitcoin: [],
            internalNotes: '',
            paymentID: 0,
            showDistributionModal: false,
            isSimulation: true,
            projectTitle: '',
            projectEntity: '',
            bitcoinTransfer: 0,
            addCheckModal: false,
            isLoaded: false,
            errorOnLoad: false,

            totalProjects: [],
            allProjects: [],
            pageNumber: 1,
            totalCount: 0,
            perPage: 10,
            activePage: 1,
            selectedUser:{},

            totalProjectsCF: [],
            allProjectsCF: [],
            pageNumberCF: 1,
            totalCountCF: 0,
            perPageCF: 10,
            activePageCF: 1,
            selectProject:{
                projectId: 0,
                bitcoinPayment:0
            },

            openRecordEarningsModal: false,
        };

        this.getPayoutsToDistribute = this.getPayoutsToDistribute.bind(this);
        this.calculateDistribution = this.calculateDistribution.bind(this);
        this.confirmDistribution = this.confirmDistribution.bind(this);
        this.getViewDistribution = this.getViewDistribution.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleCFPageChange = this.handleCFPageChange.bind(this);
        this.handleCloseDistributionModal = this.handleCloseDistributionModal.bind(this);
    }


    handleCloseDistributionModal(paymentID) {
        this.setState({showDistributionModal: false});
        this.state.isSimulation && document.getElementById('submit' + paymentID).removeAttribute('disabled');
    }


    handlePageChange(pageNumber) {
        let prev = ((pageNumber) - 1) * 10;
        let next = prev + 10;
        this.setState({
            activePage: pageNumber,
            ProjectsRegD: this.state.allProjects.slice(prev, next)
        });
    }


    handleCFPageChange(pageNumber) {
        let prev = ((pageNumber) - 1) * 10;
        let next = prev + 10;
        this.setState({
            activePageCF: pageNumber,
            ProjectsRegCF: this.state.allProjectsCF.slice(prev, next)
        });
    }


    async calculateDistribution(paymentID, projectName, projectEntity, BitcoinPayment) {
        document.getElementById('submit' + paymentID).setAttribute('disabled', 'disabled');

        try {
            let responseData;
            if(BitcoinPayment == 1){
                const {data} = await RequestService.post(`/api/distributeEarningsBitcoin/${paymentID}/0`);
                responseData = data
            }else{
            const {data} = await RequestService.post(`/api/distributeEarnings/${paymentID}/0`);
            responseData = data
        }
            if (responseData.code === 200) {
                this.setState({
                    paymentID: paymentID,
                    investorEarnings: responseData.investorEarnings,
                    companyEarnings: responseData.companyEarnings,
                    companyEarningsInBitcoin: responseData.companyBitcoinEarnings,
                    internalNotes: responseData.internalNotes,
                    projectName: projectName,
                    projectEntity: projectEntity,
                    bitcoinTransfer: responseData?.bitcoinTransfer,
                    isLoaded: true,
                    err: {},
                    errorOnLoad: false,
                    showDistributionModal: !this.state.showDistributionModal,
                    isSimulation: true
                });
            } else {
                alert('Could not run simulation of distribute funds. Check error logs.');
            }
        } catch (err) {
            console.log(err);
        }
    }


    async confirmDistribution(paymentID,BitcoinPayment) {
        document.getElementById('submitConfirm' + paymentID).setAttribute('disabled', 'disabled');

        try {
            let responseData;
            if(BitcoinPayment == 1){
            const {data: status} = await RequestService.post(`/api/distributeEarningsBitcoin/${paymentID}/1`);
            responseData = status
            }else{
                const {data: status} = await RequestService.post(`/api/distributeEarnings/${paymentID}/1`);
                responseData = status
            }
            if (responseData.code === 200) {
                swal("Distributed Successfully", "", "success")
                    .then(this.getPayoutsToDistribute());

                await this.setState({
                    showDistributionModal: false,
                    isSimulation: true
                });
            } else {
                alert('Could not distribute funds. Check error logs.');
            }
        } catch (err) {
            console.log(err);
        }
    }


    onCFSubmit(id) {
        // This is INCOMPLETE!
        fetch('/api/companyEarningsCF/' + id + "/" + this.state.id + '/' + this.props.session.marketing, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        })
            .then((response) => response.json())
            .then((status) => {
                if (status.code === 200) {
                    swal("Distributed Successfully", "", "success");
                    this.getPayoutsToDistribute();
                } else if (status.code === 400) {
                    alert(status.message);
                }
            })
            .catch((err) => {
                alert("Error: " + JSON.stringify(err))
            })
    }


    async getPayoutsToDistribute() {
        try {
            const {data} = await RequestService.get('/api/getPayoutsToDistribute');

            if (data.code === 200) {
                this.setState({
                    totalProjects: data.reg_d,
                    allProjects: data.reg_d,
                    ProjectsRegD: data.reg_d.slice(0, 10),
                    pageNumber: 1,
                    totalCount: data.reg_d.length,
                    perPage: 10,
                    activePage: 1,

                    totalProjectsCF: data.reg_cf,
                    allProjectsCF: data.reg_cf,
                    ProjectsRegCF: data.reg_cf.slice(0, 10),
                    pageNumberCF: 1,
                    totalCountCF: data.reg_cf.length,
                    perPageCF: 10,
                    activePageCF: 1,

                    isLoaded: true,
                    err: {},
                    errorOnLoad: false,
                });
            } else {
                alert('Could not get list of distributions to payout. Please try again later.');
            }
        } catch (err) {
            console.log(err);
        }
    }


    async getViewDistribution(id, projectName, projectEntity, BitcoinPayment) {
        try {
            let responseData;
            if(BitcoinPayment == 1){
                const {data} = await RequestService.get(`/api/getSingleBitcoinDistributionInfo/${id}`);
                responseData = data;
            }else{
                const {data} = await RequestService.get(`/api/getSingleDistributionInfo/${id}`);
                responseData = data;
            }

            if (responseData.code === 200) {
                this.setState({
                    investorEarnings: responseData.investorEarnings,
					investorEarningsCSV: responseData.investorEarnings,
                    companyEarnings: responseData.companyEarnings,
                    companyEarningsInBitcoin: responseData.companyBitcoinEarnings,
                    internalNotes: responseData.internalNotes,
                    projectName: projectName,
                    projectEntity: projectEntity,
                    bitcoinTransfer: responseData?.bitcoinTransfer,
                    isLoaded: true,
                    err: {},
                    errorOnLoad: false,
                    showDistributionModal: true,
                    isSimulation: false
                });
                this.setState({selectProject:{projectId:id, BitcoinPayment:BitcoinPayment}});
            } else {
                alert('Could not get distribution information.');
            }
        } catch (err) {
            console.log(err);
        }
    }


    openRecordEarningsModal = () => {
        this.setState({openRecordEarningsModal: true});
    };

    closeRecordEarningsModal = () => {
        this.setState({openRecordEarningsModal: false});
    };


    componentDidMount() {
        window.scrollTo(0, 0);
        this.getPayoutsToDistribute();
    }

submitChequeNumber = async() => {
    console.log(`payment type ${this.refs?.paymentType?.value} `);
    console.log(`payment number ${this.refs?.chequeNumber?.value}`);
    console.log(`payment Status ${this.refs?.paymentStatus?.value}`);
    console.log("selectedUser", this.state.selectedUser);
    let chequeNumber = this.refs?.chequeNumber?.value;
    if(this.state?.selectedUser?.InvestorEarningID > 0 && chequeNumber?.length > 0){
    let formBody = [];
			formBody.push(`paymentMethod=${encodeURIComponent(this.refs?.paymentType?.value)}`);
			formBody.push(`chequeNumber=${encodeURIComponent(this.refs?.chequeNumber?.value)}`);
			formBody.push(`paymentStatus=${encodeURIComponent(this.refs?.chequeNumber?.value?.length > 0 ? 1 : 0)}`);
			formBody.push(`investorEarningsId=${encodeURIComponent(this?.state?.selectedUser?.InvestorEarningID)}`);
			formBody = formBody.join('&');

			const { data } = await RequestService.post({
				url: `/api/updateInvestorEarning`,
				data: formBody
			});

			if (data.code === 200) {
                this.getViewDistribution(this.state.selectProject?.projectId, this.state?.projectName, this.state?.projectEntity, this.state?.selectProject?.bitcoinPayment);
				swal({
					title: 'Data Saved',
					type: 'success',
				});

			}
			else {
				swal({
					title: 'There was an error',
					text: 'Data did not save.',
					type: 'warning',
				});
			}
            this.handleCloseAddChequeModal();
    }
}
handleCloseAddChequeModal() {
    
    this.setState({addCheckModal:false});
    this.setState({selectedUser:{}});
}
openAddChequeNumberModal = (user) => {

    this.setState({addCheckModal:true});
    this.setState({selectedUser:user});
}
    render() {
        return (
            <div>
                <div className="container body">
                    <div className="main_container">
                        <div className="right_col" role="main" style={{minHeight: 3753}}>
                            <div className>
                                <div className="page-title">
                                    <div className="pull-left">
                                        <div className="title_left">
                                            <h3>Distribute Earnings</h3>
                                        </div>
                                    </div>
                                    <div className="pull-right">
                                        <button className="btn btn-primary btn-sm capitalize-text"
                                                onClick={this.openRecordEarningsModal}>
                                            Record new earnings
                                        </button>
                                    </div>
                                </div>
                                <div className="clearfix"/>
                                <div className="row">
                                    {/* tabs */}
                                    <div>
                                        <ul className="nav nav-pills nav-justified"
                                            style={{padding: '0 10px', marginBottom: 20}}>
                                            <li className="active"><a data-toggle="pill" href="#home">EnergyFunders
                                                Black (Reg D)</a></li>
                                            <li><a data-toggle="pill" href="#menu1">EnergyFunders Marketplace (Reg
                                                CF)</a></li>
                                        </ul>
                                        <div className="tab-content">
                                            <div id="home" className="tab-pane fade in active">
                                                <div className="col-md-12 col-sm-12 col-xs-12">
                                                    <div className="x_panel">
                                                        <div className="x_title">
                                                            <div className="clearfix"/>
                                                        </div>
                                                        <div className="x_content">
                                                            <div id="datatable_wrapper"
                                                                 className="dataTables_wrapper form-inline dt-bootstrap no-footer">
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <div className="col-md-8"/>
                                                                        <table
                                                                            className="table table-striped table-bordered dataTable no-footer">
                                                                            <thead>
                                                                            <tr className="headings">
                                                                                <th className="column-title">Payment ID</th>
                                                                                <th className="column-title">Date</th>
                                                                                <th className="column-title">Project Name / Notes</th>
                                                                                <th className="column-title">Entity</th>
                                                                                <th className="column-title">Payment Amount</th>
                                                                                <th className="column-title no-link last">
                                                                                    <span className="nobr">Details</span>
                                                                                </th>
                                                                                <th className="column-title">View</th>
                                                                                <th className="bulk-actions"
                                                                                    colSpan={7}>
                                                                                    <a className="antoo" style={{
                                                                                        color: '#fff',
                                                                                        fontWeight: 500
                                                                                    }}>Bulk Actions ( <span
                                                                                        className="action-cnt"> </span> ) <i
                                                                                        className="fa fa-chevron-down"/></a>
                                                                                </th>
                                                                            </tr>
                                                                            </thead>
                                                                            {(() => {
                                                                                if (this.state.ProjectsRegD.length > 0) {
                                                                                    return (
                                                                                        <tbody>
                                                                                        {
                                                                                            this.state.ProjectsRegD.map((project) => (
                                                                                                <tr key={project.PaymentID}
                                                                                                    className="even pointer">
                                                                                                        {console.log("project",project)}
																									<td>{project.PaymentID}</td>
																									<td>{moment(project.DateEntered).format('MMM DD, YYYY')}<br/>{moment(project.DateEntered).format('hh:mm A')}</td>
                                                                                                    <td><strong>{project.ProjectName}</strong><br/><br/><em>Notes:</em> <div dangerouslySetInnerHTML={{ __html: project.InternalNotes }} /></td>
                                                                                                    <td>{project.Entity}</td>
                                                                                                    <td> <span style={{paddingLeft:'1.2px'}}>{ formatMoney(project.PaymentAmount) && formatMoney(project.PaymentAmount) != 0 ? <span> $ {formatMoney(project.PaymentAmount)} </span> : <span>-</span> }</span> <br></br>
                                                                                                    {project?.BitcoinPayment == 1 ? <span> <BiBitcoin size='1.2em' style={{marginBottom:'-2.5px'}} /> {project?.BitcoinAmount} </span> : null } </td>
                                                                                                    {(() => {
                                                                                                        if (project.Distributed > 0) {
                                                                                                            return (
                                                                                                                <React.Fragment>
                                                                                                                    <td>Distributed</td>
                                                                                                                    <td>
                                                                                                                        <a href="javascript:void(0);">
                                                                                                                            <button
                                                                                                                                type="submit"
                                                                                                                                className="btn btn-primary center"
                                                                                                                                onClick={() => this.getViewDistribution(project.PaymentID, project.ProjectName, project.Entity, project?.BitcoinPayment)}>View
                                                                                                                            </button>
                                                                                                                        </a>
                                                                                                                    </td>
                                                                                                                </React.Fragment>
                                                                                                            );
                                                                                                        }
                                                                                                        else {
                                                                                                            return (
                                                                                                                <React.Fragment>
                                                                                                                    <td>
                                                                                                                        <button
                                                                                                                            id={'submit' + project.PaymentID}
                                                                                                                            type="submit"
                                                                                                                            onClick={() => this.calculateDistribution(project.PaymentID, project.ProjectName, project.Entity,project?.BitcoinPayment)}
                                                                                                                            className="btn btn-primary center">Distribute
                                                                                                                        </button>
                                                                                                                    </td>
                                                                                                                    <td/>
                                                                                                                </React.Fragment>
                                                                                                            );
                                                                                                        }
                                                                                                    })()}
                                                                                                </tr>
                                                                                            ))
                                                                                        }
                                                                                        </tbody>
                                                                                    );
                                                                                } else {
                                                                                    return (
                                                                                        <tbody>
                                                                                        <tr className="even pointer">
                                                                                            <td colSpan="8" className="text-center">Nothing to Show</td>
                                                                                        </tr>
                                                                                        </tbody>
                                                                                    );
                                                                                }
                                                                            })()}
                                                                        </table>
                                                                    </div>
                                                                    <div className="pull-right">
                                                                        <Pagination
                                                                            firstPageText={<i
                                                                                className='glyphicon glyphicon-chevron-left'/>}
                                                                            lastPageText={<i
                                                                                className='glyphicon glyphicon-chevron-right'/>}
                                                                            prevPageText={<i
                                                                                className='glyphicon glyphicon-menu-left'/>}
                                                                            nextPageText={<i
                                                                                className='glyphicon glyphicon-menu-right'/>}
                                                                            activePage={this.state.activePage}
                                                                            itemsCountPerPage={this.state.perPage}
                                                                            totalItemsCount={this.state.totalCount}
                                                                            onChange={this.handlePageChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="menu1" className="tab-pane fade">
                                                <div className="col-md-12 col-sm-12 col-xs-12">
                                                    <div className="x_panel">
                                                        <div className="x_title">
                                                            <div className="clearfix"/>
                                                        </div>
                                                        <div className="x_content">
                                                            <div id="datatable_wrapper"
                                                                 className="dataTables_wrapper form-inline dt-bootstrap no-footer">
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <table
                                                                            className="table table-striped table-bordered dataTable no-footer">
                                                                            <thead>
                                                                            <tr className="headings">
                                                                                <th className="column-title">Project
                                                                                    Name
                                                                                </th>
                                                                                <th className="column-title">Payment
                                                                                    Amount
                                                                                </th>
                                                                                <th className="column-title no-link last">
                                                                                    <span
                                                                                        className="nobr">Details</span>
                                                                                </th>
                                                                                <th className="column-title">View</th>
                                                                                <th className="bulk-actions"
                                                                                    colSpan={7}>
                                                                                    <a className="antoo" style={{
                                                                                        color: '#fff',
                                                                                        fontWeight: 500
                                                                                    }}>Bulk Actions ( <span
                                                                                        className="action-cnt"> </span> ) <i
                                                                                        className="fa fa-chevron-down"/></a>
                                                                                </th>
                                                                            </tr>
                                                                            </thead>
                                                                            {(() => {
                                                                                if (this.state.ProjectsRegCF.length > 0) {
                                                                                    return (
                                                                                        <tbody>
                                                                                        {
                                                                                            this.state.ProjectsRegCF.map((project) => (
                                                                                                <tr key={project.PaymentID}
                                                                                                    className="even pointer">
                                                                                                    <td>{project.InvestmentName}</td>
                                                                                                    <td>{project.PaymentAmount}</td>
                                                                                                    {(() => {
                                                                                                        if (project.Distributed > 0) {
                                                                                                            return (
                                                                                                                <React.Fragment>
                                                                                                                    <td>Distributed</td>
                                                                                                                    <td>
                                                                                                                        <a href="#"
                                                                                                                           data-toggle="modal"
                                                                                                                           data-target="#myModal">
                                                                                                                            <button
                                                                                                                                type="submit"
                                                                                                                                className="btn btn-primary center"
                                                                                                                                onClick={() => this.getViewDistribution(project.PaymentID, project.InvestmentName, project.Entity)}>View
                                                                                                                            </button>
                                                                                                                        </a>
                                                                                                                    </td>
                                                                                                                </React.Fragment>
                                                                                                            );
                                                                                                        } else {
                                                                                                            return (
                                                                                                                <React.Fragment>
                                                                                                                    <td>
                                                                                                                        <button
                                                                                                                            id={'submit' + project.PaymentID}
                                                                                                                            type="submit"
                                                                                                                            onClick={() => this.onCFSubmit(project.PaymentID)}
                                                                                                                            className="btn btn-primary center">Distribute
                                                                                                                        </button>
                                                                                                                    </td>
                                                                                                                    <td></td>
                                                                                                                </React.Fragment>
                                                                                                            );
                                                                                                        }
                                                                                                    })()}
                                                                                                </tr>
                                                                                            ))
                                                                                        }
                                                                                        </tbody>
                                                                                    );
                                                                                } else {
                                                                                    return (
                                                                                        <tbody>
                                                                                        <tr className="even pointer">
                                                                                            <td colSpan="4"
                                                                                                className="text-center">Nothing
                                                                                                to show
                                                                                            </td>
                                                                                        </tr>
                                                                                        </tbody>
                                                                                    );
                                                                                }
                                                                            })()}
                                                                        </table>
                                                                    </div>
                                                                    <div className="pull-right">
                                                                        <Pagination
                                                                            firstPageText={<i
                                                                                className='glyphicon glyphicon-chevron-left'/>}
                                                                            lastPageText={<i
                                                                                className='glyphicon glyphicon-chevron-right'/>}
                                                                            prevPageText={<i
                                                                                className='glyphicon glyphicon-menu-left'/>}
                                                                            nextPageText={<i
                                                                                className='glyphicon glyphicon-menu-right'/>}
                                                                            activePage={this.state.activePageCF}
                                                                            itemsCountPerPage={this.state.perPageCF}
                                                                            totalItemsCount={this.state.totalCountCF}
                                                                            onChange={this.handleCFPageChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal show={this.state.showDistributionModal}
                       onHide={() => this.handleCloseDistributionModal(this.state.paymentID)}>
                    <Modal.Header closeButton>
                        <h4>Payment Details For: {this.state.projectName} ({this.state.projectEntity})</h4>
                    </Modal.Header>
                    <Modal.Body>
                        <Modal show={this.state.addCheckModal} onHide={() =>this.handleCloseAddChequeModal()} style={{ width:'50%', textAlign:'center' , left:'25%' }}>
                            <Modal.Header closeButton>
                                <h4>Payment for {this.state?.selectedUser?.FirstName} {this.state?.selectedUser?.LastName}</h4>
                                {/* <span style={{float: 'right'}} onClick={() =>this.handleCloseAddChequeModal()}  > X </span> */}
                            </Modal.Header>
                            <Modal.Body>
                            <div className="form-group">
                                            <label htmlFor="paymentType"
                                                   className="col-sm-3 col-md-3 col-xs-12 pt-2 control-label">
                                                Payment Type:
                                            </label>
                                            <p className="col-sm-9 col-md-9 col-xs-12">
                                                <input className="form-control" id="paymentType" ref="paymentType"
                                                        name="paymentType" disabled={true}
                                                        value={'CHECK'} />
                                            </p>
                                            <label htmlFor="chequeNumber"
                                                   className="col-sm-3 col-md-3 col-xs-12 pt-3 control-label">
                                                Check Number:
                                            </label>
                                            <div className="col-sm-9 col-md-9 col-xs-12">
                                            <input type="text" className="form-control" id="chequeNumber"
                                            defaultValue={this.state?.selectedUser?.ChequeNumber}
                                                       name="chequeNumber" ref="chequeNumber" placeholder="Enter Cheque Number"
													   style={{border: '1px solid #ccc', borderRadius: 0, backgroundColor: 'white'}}
												/>
                                            </div>
                                            <label htmlFor="paymentStatus"
                                                   className="col-sm-3 col-md-3 col-xs-12 pt-4 control-label">
                                                Payment Status:
                                            </label>
                                            <p className="col-sm-9 col-md-9 pt-3 col-xs-12">
                                                <select className="form-control" id="paymentStatus" ref="paymentStatus"
                                                        name="paymentStatus" disabled={true} >
                                                    {/* <option value={0}>Unpaid</option>
                                                    <option value={3}>Processing</option> */}
                                                    <option value={1}>Paid</option>
                                                </select>
                                            </p>
                                        </div>
                                <button className="btn btn-primary btn-sm capitalize-text mt-3 px-3"onClick={() => this.submitChequeNumber() }>Save</button>
                            </Modal.Body>
                        </Modal>
                        <div className="container">
                            <div className="row">
                                <div className="panel-body">
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 col-xs-12">
                                            <div className="x_panel">
                                                <div className="x_title">
                                                    <h4>Company Earnings</h4>
                                                    <div className="clearfix"/>
                                                </div>
                                                <div className="x_content" id="target">
                                                    <div className="table-responsive" style={{overflowX: 'visible'}}>
                                                        <table
                                                            className="table table-striped jambo_table bulk_action text-center">
                                                            <thead>
                                                            <tr>
                                                                <th colSpan={2} className="text-center">Company Earnings Amount</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                                {/* {console.log("amount", this.state.companyEarnings)} */}
                                                            {this.state.bitcoinTransfer == 1 ? <tr>
                                                                <td>In Amount {formatMoney(this.state.companyEarnings) ? <span> ${formatMoney(this.state.companyEarnings)}</span> : <span>$ 0.00</span>}</td>
                                                                <td>In Bitcoin <BiBitcoin size='1.2em' style={{marginBottom:'-2.5px'}} />{this.state.companyEarningsInBitcoin}</td>
                                                            </tr> : <tr>
                                                                <td>{ formatMoney(this.state.companyEarnings) ? <span> $ {formatMoney(this.state.companyEarnings)}</span> : <span>$ 0.00</span>}</td>
                                                            </tr>}
                                                            </tbody>
                                                        </table>
                                                    </div>
													<div className="table-responsive" style={{overflowX: 'visible'}}>
														<table
															className="table table-striped jambo_table bulk_action text-center">
															<thead>
															<tr>
																<th className="text-center">Payment Notes</th>
															</tr>
															</thead>
															<tbody>
															<tr>
																<td><div dangerouslySetInnerHTML={{ __html: this.state.internalNotes }} /></td>
															</tr>
															</tbody>
														</table>
													</div>
                                                </div>
                                            </div>

                                            <div className="x_panel">
                                                <div className="x_title">
                                                    <h4>Investor Earnings</h4>
                                                    <div className="clearfix"/>
                                                </div>
                                                <div className="x_content" id="target">
                                                    <div className="table-responsive" style={{overflowX: 'visible'}}>
                                                        <table className="table table-striped jambo_table bulk_action">
                                                            <thead>
                                                            <tr>
																<th> </th>
                                                                <th>UserID</th>
                                                                <th>First Name</th>
                                                                <th>Last Name</th>
                                                                <th>Entity</th>
                                                                <th>Email</th>
                                                                <th>Earnings</th>
                                                                {this.state?.bitcoinTransfer == 1 ? <th>Bitcoin Earnings</th> : null }
                                                                {this.state?.bitcoinTransfer == 1 ? <th>Bitcoin Wallet</th> : null }
                                                                <th>Payment Method</th>
                                                                <th>Paid</th>
                                                            </tr>
                                                            </thead>
                                                            {(() => {
                                                                if (this.state.investorEarnings.length > 0) {
                                                                    return (
                                                                        <tbody>
                                                                        {
                                                                            this.state.investorEarnings.map((user, index) => (
                                                                                <tr key={user.UserID}
                                                                                    className="even pointer">
																					<td>{index + 1}</td>
                                                                                    <td className=" ">{user.UserID}</td>
                                                                                    <td className=" ">{user.FirstName}</td>
                                                                                    <td className=" ">{user.LastName}</td>
                                                                                    <td className=" ">{user.Entity}</td>
                                                                                    <td className=" ">{user.Email}</td>
                                                                                    <td className=" ">{ formatMoney(user.EarningAmount) && formatMoney(user.EarningAmount) != 0 ? <span> $ {formatMoney(user.EarningAmount)}</span> : <span className="">-</span> }</td>
                                                                {this.state?.bitcoinTransfer == 1 ? <td className="pl-2 "> <BiBitcoin size='1.2em' style={{marginBottom:'-2.5px'}} />  {user?.BitcoinEarningAmount}</td> : null }
                                                                {this.state?.bitcoinTransfer == 1 ? <td className=" ">  {user?.BitcoinWalet == 5 ? user?.BitcoinWalletAddress : 'Not verified'}</td> : null }
                                                                                    <td className=" ">{user.PaymentMethod == '' ? <button className="btn btn-primary btn-sm capitalize-text px-3"onClick={() => this.openAddChequeNumberModal(user)} >Add</button> : user.PaymentMethod == 'CHEQUE' ?
                                                                                    <><div>{user.PaymentMethod}  <span className="btn-dropdown btn" style={{marginTop: '-3px'}} onClick={() => this.openAddChequeNumberModal(user)} > <FaEdit size={19} /> </span> <br></br>{user.ChequeNumber}</div></>: user.PaymentMethod  }</td>
                                                                                    <td className=" ">{ user.PaymentStatus === 1 ? 'Paid' : user.PaymentStatus == 3 ? 'Processing' : 'Unpaid'}</td>
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                        </tbody>
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <tbody>
                                                                        <tr className="even pointer">
                                                                            <td colSpan="3"
                                                                                className="text-center">Nothing to show or there was an error.  Please contact Technical Support!
                                                                            </td>
                                                                        </tr>
                                                                        </tbody>
                                                                    );
                                                                }
                                                            })()}
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!this.state.isSimulation &&
                        <div className="row">
                            <CSVLink data={this.state.investorEarningsCSV}
                                     filename={`${this.state.projectEntity.replace(/\s|-/g, '_')}-${this.state.projectName.replace(/\s|-/g, '_')}-earnings.csv`}>
                                <div className="btn btn-primary col-md-4 col-md-offset-4">
                                    <span style={{color: "white"}}>Download As CSV</span>
                                </div>
                            </CSVLink>
                            <br/>
                        </div>}
                    </Modal.Body>
                    <Modal.Footer>
                        {(() => {
                            if (this.state.isSimulation) {
                                return (
                                    <button className="btn btn-info left" id={'submitConfirm' + this.state.paymentID}
                                            onClick={() => this.confirmDistribution(this.state.paymentID, this.state?.bitcoinTransfer)}>Distribute</button>
                                );
                            }
                        })()}
                        <button className="btn btn-info left"
                                onClick={() => this.handleCloseDistributionModal(this.state.paymentID)}>Close
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.openRecordEarningsModal}
                       onHide={this.closeRecordEarningsModal}>
                    <Modal.Header closeButton>
                        <Modal.Title className="capitalize-text">
                            Record new earnings
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <RecordEarnings updateRowsOnSuccess={this.getPayoutsToDistribute}
                                        closeModal={this.closeRecordEarningsModal}/>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}


const {object, bool} = PropTypes;

Distribution.propTypes = {
    session: object.isRequired,
    authenticated: bool.isRequired,
    checked: bool.isRequired
};


const mapState = ({session}) => ({
    session: session.user,
    checked: session.checked,
    authenticated: session.authenticated
});

export default withRouter(connect(mapState)(Distribution));
