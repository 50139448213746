import RequestService from '../../../../api/RequestService';
import NotificationService from '../../../../utils/NotificationService';
import initialState from '../../../../reducers/appInitialState';
import {getImpersonateID} from '../../../../utils/General'
const MESSAGE_DISPLAY_TIME = 10000;

export const INVESTOR_DASHBOARD_START_REQ = 'INVESTOR_DASHBOARD_START_REQ';
export const INVESTOR_DASHBOARD_END_REQ = 'INVESTOR_DASHBOARD_END_REQ';
export const INVESTOR_DASHBOARD_INV_REQ_SUCCESS = 'INVESTOR_DASHBOARD_INV_REQ_SUCCESS';
export const INVESTOR_TAB_ACTIVE = 'INVESTOR_TAB_ACTIVE';
export const W9_FORM_VERIGY ='W9_FORM_VERIGY'
const endReq = () => ({ type: INVESTOR_DASHBOARD_END_REQ });
const startReq = () => ({ type: INVESTOR_DASHBOARD_START_REQ });

const investorEarningsSuccessReq = data => ({
    type: INVESTOR_DASHBOARD_INV_REQ_SUCCESS,
    data,
});

const getActiveInvestorTab =data => ({
    type:INVESTOR_TAB_ACTIVE,
    data
})
const getw9FormVerify =data => ({
    type:W9_FORM_VERIGY,
    data
})



export const getInvestorEarnings = () => async (dispatch) => {
    
    try {
        dispatch(startReq());
        const impersonateID = await getImpersonateID();
        const { data } = await RequestService.get(`/api/investor/dashboard/earnings/${impersonateID}`);

        let responseObj = {
            investorEarnings: [],
            totalPaymentsRegD: 0,
            totalPaymentsRegCF: 0,
        };

        if (data.code === 200) {
            responseObj = {
                investorEarnings: data.investorPayments,
                totalPaymentsRegD: data.totalPaymentsRegD,
                totalPaymentsRegCF: data.totalPaymentsRegCF,
            };

            dispatch(investorEarningsSuccessReq(responseObj));
        }
        else {
            dispatch(investorEarningsSuccessReq(responseObj));
        }
    } catch (err) {
        // console.log('ppp', 'Error extracting the earnings of the user', err);
        NotificationService.error('Error requesting the investors information', MESSAGE_DISPLAY_TIME);
        dispatch(endReq());
    }
};


export const getAllUserInvestments = () => async (dispatch) => {
    
    try {
        dispatch(startReq());
       const impersonateID = await getImpersonateID();
        const { data } = await RequestService.get(`/api/investor/dashboard/investments?impersonateID=${impersonateID}`)

        let responseObj = {
            investorInvestments: [],
            totalInvestmentRegD: 0,
            totalInvestmentRegCF: 0,
			TotalReturnAmountRegD: 0
        };

        if (data.code === 200) {
            responseObj = {
                investorInvestments: data.investorInvestments,
                totalInvestmentRegD: data.totalInvestmentRegD,
                totalInvestmentRegCF: data.totalInvestmentRegCF,
				TotalReturnAmountRegD: data.TotalReturnAmountRegD
            };

            dispatch(investorEarningsSuccessReq(responseObj));
        }
        else {
            dispatch(investorEarningsSuccessReq(responseObj));
        }
    } catch (err) {
        // console.log('ppp', 'Error extracting the investments of the user', err);
        NotificationService.error('Error requesting the investors information', MESSAGE_DISPLAY_TIME);
        dispatch(endReq());
    }
};


export const getDashboardData = (projectID = 0) => async (dispatch) => {
   
    try {
        dispatch(startReq());
        const impersonateID = await getImpersonateID();
        const { data } = await RequestService.get(`/api/investor/dashboard/statistics/all/${impersonateID}?projectID=${projectID}`);

        dispatch(investorEarningsSuccessReq(data));
    } catch (err) {
        // console.log('ppp', 'Error extracting the investments of the user', err);
        NotificationService.error('Error requesting the investors information', MESSAGE_DISPLAY_TIME);
        dispatch(endReq());
    }
};


export const resetDashboardData = () => investorEarningsSuccessReq(initialState.investorDashboard);

export const activeInvestorTab = (data) => getActiveInvestorTab(data);

export const w9FormVerify = () =>  async (dispatch) => {
    
    try {
        dispatch(startReq());
    	const { data } = await RequestService.get(
            "/api/checkInfoBeforeInvesting"
        );
        if (data.data[0].w8n9formVerify === 0) {
            dispatch(getw9FormVerify({w9Verified:false,EntityID:data.data[0].EntityID}));
        }
       else if (data.data[0].w8n9formVerify === 1) {
            dispatch(getw9FormVerify({w9Verified:true,EntityID:data.data[0].EntityID}));
        }
      
    } catch (err) {
        NotificationService.error('Error requesting the investors information', MESSAGE_DISPLAY_TIME);
        dispatch(endReq());
    }
};
