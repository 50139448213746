/*
 * This is the "Investor Menu" - "My Earnings" Page.
 */

import React, {Component} from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import store from '../../index'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Pagination from 'react-js-pagination';
import RequestService from '../../api/RequestService';
import Icon from '../shared/IconComponent/IconComponent';
import { formatMoney, getImpersonateID } from '../../utils/General';


class Earnings2 extends Component {
    constructor(props){
        super(props);

        this.state = {
            Earnings: [],
            id: props.session.id,
            totalProjects: [],
            allProjects: [],
            pageNumber: 1,
            totalCount: 0,
            perPage: 10,
            activePage: 1,

            EarningsCF: [],
            totalProjectsCF: [],
            allProjectsCF: [],
            pageNumberCF: 1,
            totalCountCF: 0,
            perPageCF: 10,
            activePageCF: 1,
			IsAccredited: 0,
			WasAccredited: 0,
			totalEarningsRegD: 0
        };

        this.handlePageChange = this.handlePageChange.bind(this);
        this.handlePageChangeCF = this.handlePageChangeCF.bind(this);
    }



	componentDidMount() {
		window.scrollTo(0, 0);
		this.checkAccreditation();

		store.subscribe(() => {
			let currentState = store.getState();

			this.setState({
				id: currentState.session.user.id
			});
		});

		this.getEarnings();
	}



	async checkAccreditation() {
        try {
					const impersonateID = await getImpersonateID();

            const { data } = await RequestService.get(`/api/checkAccreditation/${getImpersonateID}`);

			if (data.code === 200) {
				this.setState({
					IsAccredited: data.AccreditationStatus,
					WasAccredited: data.PriorAccredStatus
				});
			}
            else {
                // console.log(data);
            }
        }
        catch (err) {
            console.log(err);
        }
    }



	async getEarnings() {
        const { data } = await RequestService.get(`/api/getInvestorEarnings`);

        if (data.code === 200) {
            this.setState({
                Earnings: data.reg_d.slice(0,10),
                totalProjects: data.reg_d,
                allProjects: data.reg_d,
                pageNumber: 1,
                totalCount: data.reg_d?.length,
				totalEarningsRegD: data.totalEarningsRegD,
                perPage: 10,
                activePage: 1,

                EarningsCF: data.reg_cf.slice(0,10),
                totalProjectsCF: data.reg_cf,
                allProjectsCF: data.reg_cf,
                pageNumberCF: 1,
                totalCountCF: data.reg_cf?.length,
                perPageCF: 10,
                activePageCF: 1
            });
        }
        else {
            alert(`Could Not Get Earnings.`);
        }
    }



	handlePageChange(pageNumber) {
		let prev = ((pageNumber) - 1) * 10;
		let next = prev + 10;

		this.setState({
			activePage: pageNumber,
			Earnings: this.state.allProjects.slice(prev, next)
		});
	}



	handlePageChangeCF(pageNumber) {
		let prev = ((pageNumber) - 1) * 10;
		let next = prev + 10;

		this.setState({
			activePageCF: pageNumber,
			EarningsCF: this.state.allProjectsCF.slice(prev, next)
		});
	}



    renderRegD() {
		return(
			<div className="col-md-12 col-sm-12 col-xs-12">
				<div className="x_panel">
					<div className="x_title">
						<h4 className='up'>Total Earnings: ${formatMoney(this.state.totalEarningsRegD)}</h4>
						<div className="clearfix"/>
					</div>
					<div className="x_content">
						<div className="table-responsive">
							<table className="table table-striped jambo_table ">
								<thead className="thead-inverse">
								<tr className="headings">
									<th className="column-title">Date</th>
									<th className="column-title">My Entity</th>
									<th className="column-title">Project Name</th>
									<th className="column-title">Project Entity</th>
									<th className="column-title">Earnings</th>
									<th className="column-title">Notes</th>
								</tr>
								</thead>

								{(() => {
									if (this.state?.Earnings?.length > 0) {
										return (
											<tbody>
											{
												this.state.Earnings.map((earning) => (
													<tr key={earning.InvestorEarningID} className="even pointer">
														<td className=" ">{earning.EarningDate.slice(0, 10)}</td>
														<td className=" ">{earning.Entity}</td>
														<td className=" ">{earning.ProjectName}</td>
														<td className=" ">{earning.piEntity}</td>
														<td className=" ">${earning.EarningAmount}</td>
														<td className=" ">
															<OverlayTrigger
																placement="top"
																overlay={
																	<Tooltip id={String(earning.InvestorEarningID)}>
																		<div style={{
																			padding: 5,
																			fontSize: '1.2em'
																		}} dangerouslySetInnerHTML={{__html: earning.InternalNotes}} />
																	</Tooltip>}>
																<a><Icon iconName="info-circle" extraClasses="card-info" /></a>
															</OverlayTrigger>
														</td>
													</tr>
												))
											}
											</tbody>
										);
									}
									else {
										return (
											<tbody>
												<tr className="even pointer">
													<td colSpan="6" className="text-center">Nothing to Show</td>
												</tr>
											</tbody>
										);
									}
								})()}
							</table>

							<div className="pull-right">
								<Pagination
									firstPageText={<i className='glyphicon glyphicon-chevron-left'/>}
									lastPageText={<i className='glyphicon glyphicon-chevron-right'/>}
									prevPageText={<i className='glyphicon glyphicon-menu-left'/>}
									nextPageText={<i className='glyphicon glyphicon-menu-right'/>}
									activePage={this.state.activePage}
									itemsCountPerPage={this.state.perPage}
									totalItemsCount={this.state.totalCount}
									onChange={this.handlePageChange}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}



	renderRegCF() {
		return(
			<div className="col-md-12 col-sm-12 col-xs-12">
				<div className="x_panel">
					<div className="x_title">
						<div className="clearfix"/>
					</div>
					<div className="x_content">

						<div className="table-responsive">
							<table className="table table-striped jambo_table ">
								<thead className="thead-inverse">
								<tr className="headings">
									<th className="column-title">Date</th>
									<th className="column-title">Name of Investment</th>
									<th className="column-title">Earnings</th>
									<th className="column-title">Notes</th>
								</tr>
								</thead>
								{(() => {
									if (this.state?.EarningsCF?.length > 0) {
										return (
											<tbody>
											{
												this.state.EarningsCF.map((earning) => (
													<tr key={earning.InvestorEarningID} className="even pointer">
														<td className=" ">{earning.EarningDate.slice(0, 10)}</td>
														<td className=" ">{earning.InvestmentName}</td>
														<td className=" ">${earning.EarningAmount}</td>
														<td className=" ">
															<OverlayTrigger
																placement="top"
																overlay={
																	<Tooltip id={String(earning.InvestorEarningID)}>
																		<div style={{
																			padding: 5,
																			fontSize: '1.2em'
																		}}>{earning.InternalNotes}</div>
																	</Tooltip>}>
																<a><Icon iconName="info-circle" extraClasses="card-info" /></a>
															</OverlayTrigger>
														</td>
													</tr>
												))
											}
											</tbody>
										);
									}
									else {
										return (
											<tbody>
												<tr className="even pointer">
													<td colSpan="4" className="text-center">Nothing to Show</td>
												</tr>
											</tbody>
										);
									}
								})()}
							</table>

							<div className="pull-right">
								<Pagination
									firstPageText={<i className='glyphicon glyphicon-chevron-left'/>}
									lastPageText={<i className='glyphicon glyphicon-chevron-right'/>}
									prevPageText={<i className='glyphicon glyphicon-menu-left'/>}
									nextPageText={<i className='glyphicon glyphicon-menu-right'/>}
									activePage={this.state.activePageCF}
									itemsCountPerPage={this.state.perPageCF}
									totalItemsCount={this.state.totalCountCF}
									onChange={this.handlePageChangeCF}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}



    render() {
        return (
            <div className="container body">
                <div className="main_container">
                    {/* page content */}
                    <div className="right_col" role="main">
						<div className="page-title">
							<div className="title_left">
								<h3>My Earnings</h3>
							</div>
						</div>
						<div className="clearfix" />
                        <div className="row">
							{(() => {
								if (this.state.IsAccredited === 1 || this.state.WasAccredited === 1) {
									return (
										<div>
											<ul className="nav nav-pills nav-justified" style={{ padding: '0 10px', marginBottom: 20 }}>
												<li className="active"><a data-toggle="pill" href="#menu1">EnergyFunders Black (Reg D)</a></li>
												<li><a data-toggle="pill" href="#home">EnergyFunders Marketplace (Reg CF)</a></li>
											</ul>

											<div className="tab-content">
												<div id="home" className="tab-pane fade">
													{this.renderRegCF()}
												</div>
												<div id="menu1" className="tab-pane fade in active">
													{this.renderRegD()}
												</div>
											</div>
										</div>
									)
								}
								else {
									return (
										<div>
											{this.renderRegCF()}
										</div>
									)
								}
							})()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const { object, bool } = PropTypes;

Earnings2.propTypes = {
    session: object.isRequired,
    authenticated: bool.isRequired,
    checked: bool.isRequired
};

const mapState = ({ session }) => ({
    session: session.user,
    checked: session.checked,
    authenticated: session.authenticated
});

export default connect(mapState)(Earnings2);
