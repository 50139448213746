import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";
import NavSignUp from "./components/shared/NavigationManager/NavigationBar/NavSignUp";
import RequestService from "./api/RequestService";

class RegVerified extends Component {
	constructor(props) {
		super(props);

		this.state = {
			allProjectsD:[]
		};
	}

	componentDidMount() {
this.getMyInvestments();
	}
	async getMyInvestments() {
   
		const { data } = await RequestService.get(`/api/myInvestments`);
		if (data.code === 200) {
           this.setState({
				allProjectsD: data.reg_d,
			
			});
			
		}
	}
render() {
	const {allProjectsD} = this.state;
		const imgVerified = "/img/reg-verified.png";
		const pathname = window.location.pathname.toLocaleLowerCase();
		
		if (pathname.match("/regverified")) {
			document.body.classList.add("reg-wrapper");
		}

		return (
			<div className="d-flex flex-column ht-100">
				<NavSignUp />
				<div className="reg-verified bg">
					<div
						style={{ backgroundImage: `url(${imgVerified})` }}
						className="img-verified"
					></div>
					<h1>
						{/* You Are Verified!  */}
						Welcome To Your
						<br />
						{/* <span> Exclusive</span>  */}
						Private Market Energy Deals
					</h1>
					<button className="btn" onClick={()=> this.props.history.push("/app/VerifyDwolla")} style={{ height: 50,paddingLeft:40,paddingRight:40 }}>
						Connect Funds &amp; Invest Now
					</button>
					<p>
					{allProjectsD.length > 0 ?	<Link to="/app/UserNotifications">Skip for now. I’ll do it later in the Investor Platform.</Link> :	<Link to="/app/welcome">Skip for now. I’ll do it later in the Investor Platform.</Link> }
					</p>
				</div>
			</div>
		);
	}
}



export default withRouter(RegVerified);

