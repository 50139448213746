import PropTypes from "prop-types";
import React from "react";
import SingleLineInputComponent from "../../InputComponents/SingleLineInputComponent/SingleLineInputComponent";
import SelectComponent from "../../InputComponents/SelectComponent/SelectComponent";
import { ACCOUNNT_TYPES } from "../../../../utils/ApplicationConstants";
import HideComponent from "../../HideComponent/HideComponent";
import CheckboxInputComponent from "../../InputComponents/CheckboxInputComponent/CheckboxInputComponent";

const AddBank = (props) => {
	const {
		Entity,
		entityType,
		entityName,
		onInputChange,
		isRocketDollarIRA,
		bankName,
		accountName,
		accountNumber,
		accountType,
		dwollaCusID,
		isFundingSourceVerified,
		isPrimary,
		achAddenda
	} = props;

	const entityList = Entity?.map((item) => ({
		id: item?.EntityID,
		title: item?.EntityName,
	}));
	return (
		<div className="container">
			<div className="col-xs-12">
				<div className="col-xs-12">
					<fieldset>
						<legend>Entity Info</legend>

						<SelectComponent
							xs={12}
							name="entityType"
							onChange={onInputChange}
							options={entityList}
							selectTitle="Entity"
							value={entityType}
							isRequired
						/>
					</fieldset>
				</div>

				<HideComponent hide={isRocketDollarIRA}>
					<div className="col-xs-12">
						<fieldset>
							<legend>Bank Info</legend>

							<div className="col-xs-12 col-sm-4">
								<SingleLineInputComponent
									maxInputLength={null}
									onInputUpdate={onInputChange}
									elementValue={bankName}
									inputName="bankName"
									inputTitle="Bank Name"
									placeholder="Bank Name"
									
								/>
							</div>
							<div className="col-xs-12 col-sm-4">
								<SingleLineInputComponent
									maxInputLength={null}
									onInputUpdate={onInputChange}
									elementValue={accountName}
									inputName="accountName"
									inputTitle="Account Name"
									placeholder="Account Name"
								
								/>
							</div>
							<div className="col-xs-12 col-sm-4">
								<SingleLineInputComponent
									maxInputLength={14}
									onInputUpdate={onInputChange}
									elementValue={accountNumber}
									inputName="accountNumber"
									inputTitle="Account Number"
									placeholder="Account Number"
								
								/>
							</div>
							<div className="col-xs-12 col-sm-4">
								<SelectComponent
									xs={12}
									name="accountType"
									onChange={onInputChange}
									options={ACCOUNNT_TYPES}
									selectTitle="Account type"
									value={accountType}
									isRequired
								/>
							</div>
							<div className="col-xs-12 col-sm-4">
								<SingleLineInputComponent
									maxInputLength={null}
									onInputUpdate={onInputChange}
									elementValue={dwollaCusID}
									inputName="dwollaCusID"
									inputTitle="Dwolla Bank Account ID"
									placeholder="Dwolla Bank Account ID"
									isRequired
								/>
							</div>
						<div className="col-xs-12 col-sm-4">
								<SingleLineInputComponent
									maxInputLength={null}
									onInputUpdate={onInputChange}
									elementValue={achAddenda}
									inputName="achAddenda"
									inputTitle="Ach Addenda"
									placeholder="Ach Addenda"
								
								/>
							</div>
							<div className="col-xs-12 col-sm-3">
								<CheckboxInputComponent
									checkTitle=" Funding Source Verified"
									onClick={onInputChange}
									checked={isFundingSourceVerified}
									name="isFundingSourceVerified"
								/>
							</div>
							<div className="col-xs-12 col-sm-3">
								<CheckboxInputComponent
									checkTitle="Is Primary"
									onClick={onInputChange}
									checked={isPrimary}
									name="isPrimary"
								/>
							</div>
						</fieldset>
					</div>
				</HideComponent>
			</div>
		</div>
	);
};

AddBank.propTypes = {
	entityName: PropTypes.string.isRequired,
	entityType: PropTypes.string.isRequired,

	onInputChange: PropTypes.func.isRequired,
};

AddBank.defaultProps = {
	entityName: "",
	entityType: "",

	onInputChange: () => null,
};

export default AddBank;
