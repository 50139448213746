import PropTypes from 'prop-types'
import React from 'react';
import Icon from "../IconComponent/IconComponent";
import DonutChart from "../DonutChartComponent/DonutChart";
import {PROJECT_REG_D} from "../../../utils/ApplicationConstants";
import { computeFundPercentage } from '../../../utils/General';
import IntInputComponent from '../InputComponents/IntInputComponent/IntInputComponent';
import moment from 'moment';


const CardFundProgress = (props) => {

	const computeDaysLeft = (isEditMode, dateInvestmentStart, fundPeriod) => {
		const momentStartDate = moment(dateInvestmentStart, 'YYYY-MM-DD HH:mm:ss');
		const dayDiff = moment().diff(momentStartDate, 'days');
		const result = fundPeriod - dayDiff;
		let momentEndDate = momentStartDate.add(fundPeriod, 'days').endOf('day');

		if (moment().isBefore(moment(dateInvestmentStart))) {
			return(
				<div className="grid-recollection-container-days-left">
					<p className="high-text margin-10-px purple-text">Opens {moment(dateInvestmentStart).fromNow()}</p>
				</div>
			)
		}
		if (result < 0) {
			return (
				<div className="grid-recollection-container-days-left">
					<p className="high-text margin-10-px purple-text">Closed</p>
				</div>
			);
		}
		if (result === 0) {
			if (!isEditMode) {
				let diff = momentEndDate.diff(moment());

				if (diff <= 0) {
					return(
						<div className="grid-recollection-container-days-left">
							<p id="investmentTimeRemaining" className="high-text margin-10-px purple-text">Closed</p>
						</div>
					)
				}
				else {
					return (
						<div className="grid-recollection-container-days-left">
							{/* <p className="high-text margin-10-px purple-text">Closes {moment.utc(momentEndDate).fromNow()}</p> */}
                            <p className="high-text margin-10-px purple-text">Now Open</p>
						</div>
					);
				}

				// This code doesn't work when you click "Invest", it causes error:
				/*
				x = setInterval(function() {
					let diff = momentEndDate.diff(moment());

					if (diff <= 0) {
						clearInterval(x);
						document.getElementById('investmentTimeRemaining').innerHTML = 'Closed';
					}
					else {
						document.getElementById('investmentTimeRemaining').innerHTML = moment.utc(diff).format('HH:mm:ss') + ' Left';
					}
				}, 1000);
				*/
			}
		}
		else {
			return (
				<div className="grid-recollection-container-days-left">
					{/* <p className="high-text margin-10-px purple-text">Closes {moment.utc(momentEndDate).fromNow()}</p> */}
                    <p className="high-text margin-10-px purple-text">Now Open</p>
				</div>
			);
		}
	};


    const {
        amountRecollected, goalAmount, investorCount, onInputChange,
        fundPeriod, investmentStartDate, minGoalAmount, isEditMode,
    } = props;

    const {
        recollectionPercentage, minGoalIdentifier, fundedIdentifier, minGoal, maxGoal
    } = computeFundPercentage(amountRecollected, minGoalAmount, goalAmount);

    const editorClass = isEditMode ? 'grid-recollection-container-days-left editing' :
        'grid-recollection-container-days-left';

    const _amountRecollected = amountRecollected ? parseFloat(amountRecollected) : 0;
    const daysLeft = investmentStartDate ? computeDaysLeft(isEditMode, investmentStartDate, fundPeriod) : null;

    const daysLeftInput = (
        <div className={editorClass}>
            <div className="col-xs-12">
                <IntInputComponent inputName="fundingPeriod"
                                   elementValue={parseFloat(fundPeriod)}
                                   onInputUpdate={onInputChange}
                                   inputTitle="Fund Period"
				/>
            </div>
        </div>
    );

    return [
        (<div className="grid-recollection-container-social">
            <Icon iconName="eye"
                  extraClasses="purple-text card-icon"
                  onClick={() => {
                      props.addToWatchlist(props.projectType, props.projectId)
                  }}/>
        </div>),

        (<div className={`grid-recollection-container-chart ${isEditMode ? 'editing' : ''}`}>
            <svg viewBox="0 0 300 300" className="grid-recollection-chart">
                {/*<DonutChart size={300}
                            strokewidth={17}
                            fundedIdentifier={fundedIdentifier}
                            value={fundedIdentifier}
                            text={recollectionPercentage}
                            minValue={minGoalIdentifier}
                            investorCount={`${investorCount} ${investorCount === 1 ? 'Investor' : 'Investors'}`}
                            valuelabel={`$${_amountRecollected.toLocaleString()} Funded`}
                            collected={`Min $${minGoal >= 1000000 ? minGoal / 1000000 + 'M' : minGoal / 1000 + 'K'} to
                            				$${maxGoal >= 1000000 ? maxGoal / 1000000 + 'M' : maxGoal / 1000 + 'K'} Max`}
				/>*/}
            </svg>
        </div>),

        !isEditMode ? daysLeft : daysLeftInput,
    ];
};

CardFundProgress.propTypes = {
    amountRecollected: PropTypes.number.isRequired,
    fundPeriod: PropTypes.number.isRequired,
    goalAmount: PropTypes.number.isRequired,
    investmentStartDate: PropTypes.string.isRequired,
    addToWatchlist: PropTypes.func.isRequired,
    projectType: PropTypes.string.isRequired,
    projectId: PropTypes.any.isRequired,
    minGoalAmount: PropTypes.number.isRequired,
    investorCount: PropTypes.number.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    onInputChange: PropTypes.func.isRequired,
};

CardFundProgress.defaultProps = {
    amountRecollected: 0,
    fundPeriod: 0,
    goalAmount: 0,
    minGoalAmount: 0,
    investmentStartDate: null,
    addToWatchlist: () => null,
    projectType: PROJECT_REG_D,
    projectId: 0,
    investorCount: 0,
    isEditMode: false,
    onInputChange: () => null,
};

export default CardFundProgress;
