import initialState from '../../../../reducers/appInitialState';
import { updateRequestCounter } from '../../../../utils/General';
import * as Actions from '../actions/actions';

export default (state = initialState.investorMassFileUpload, action) => {
    const { plus, minus } = updateRequestCounter(state, 'IDMUOnFlightRequest');

    switch (action.type) {
    case Actions.MASS_FILE_UPLOAD_REQ_END:
        return {
            ...state,
            IDMUOnFlightRequest: minus,
        };
    case Actions.MASS_FILE_UPLOAD_REQ_START:
        return {
            ...state,
            IDMUOnFlightRequest: plus,
        };
    case Actions.GET_ALL_INVESTOR_CONTRACT_FILES_SUCCESS:
            return {
                ...state,
                IDMUOnFlightRequest: plus,
                AllInvestorContractFilesList: action.data,
        };
    case Actions.MASS_FILE_UPLOAD_ALL_FILES_SUCCESS:
        return {
            ...state,
            IDMUOnFlightRequest: minus,
            ...action.data,
        };
    default:
        return { ...state };
    }
};
