import PropTypes from 'prop-types'
import React from 'react';
import ReactLoaderSpinner from 'react-loader-spinner';
import Card from "../../../ProjectCardComponent/Card";
import {getUUID} from '../../../../../utils/General'
import PastInvestments from "../PastInvestments/PastInvestments";
import PastInvestmentCard from "../PastInvestments/PastInvestmentsCard/PastInvestmentCard";

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './custom-slick-buttons.css'
import './InvestmentViewerStyles.css';
import InvestmentVideoPlayer from "./InvestmentVideoPlayer";
import {PROJECT_REG_CF, PROJECT_REG_D} from "../../../../../utils/ApplicationConstants";


class InvestmentViewer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeProjects: [],
            canSeeMore: false,
            openVideo: false,
            selectedVideoUrl: '',
            selectedTitle: '',
            selectedProjectType: '',
        };
    }



	componentDidMount() {
		this.addElementsToArray();
	}



	componentDidUpdate(prevProps, prevState) {
		const activeProjects = JSON.stringify(this.props.activeProjects);
		const _activeProjects = JSON.stringify(prevProps.activeProjects);
		const selectedType = this.props.selectProjectType;
		const _selectedType = prevState.selectedProjectType;

		if (activeProjects !== _activeProjects && selectedType !== _selectedType) {
			this.setState({selectedProjectType: this.props.selectProjectType},
				() => this.addElementsToArray(true));
		}
	}



	openVideo = (videoUrl, title) => {
        this.setState({
            selectedVideoUrl: videoUrl,
            selectedTitle: title,
            openVideo: true,
        });
    };



    closeVideo = () => {
        this.setState({
            openVideo: false,
        });
    };



    addElementsToArray = async (resetList = false) => {
        const numberOfElements = 3;
        let propsActiveProjects = this.props.activeProjects;
        let stateActiveProjects = [];

        if (!resetList) {
            stateActiveProjects = [...this.state.activeProjects];
        }

        const shownElements = stateActiveProjects.length;
        const nextElements = (shownElements + numberOfElements);
        let nextObjs = propsActiveProjects.slice(shownElements, nextElements);
        const canSeeMore = (propsActiveProjects.length > nextElements);

        nextObjs = nextObjs.map(el => {
            el.key = getUUID();
            return el;
        });

        this.setState({
            activeProjects: stateActiveProjects.concat(nextObjs),
            canSeeMore,
        });
    };



    renderPastInvestmentCards() {
        const {pastRegDInvestments} = this.props;
        return pastRegDInvestments.map(el => (
            <PastInvestmentCard key={getUUID()}
                                cardImage={el.image}
                                maxGoal={el.fundingGoalMax}
                                minGoal={el.fundingGoalMin}
                                moneyRaised={el.moneyRaised}
                                numberOfInvestors={el.investorCount}
                                projectName={el.projectName}
                                status={el.status}
                                video={el.video}
                                openVideo={this.openVideo}
                                projectID={el.projectID}
                                projectType={this.props.projectType}
                                onSeeDetails={this.props.onSeeDetails}
                                version={el.templateVersion}
			/>
        ));
    };




    renderActiveInvestmentCards() {
        const {activeProjects} = this.state;

        return activeProjects.map(el => (
            <Card key={el.key}
                  cardImage={el.image}
                  category={el.category}
                  companyLogo={el.companyLogo}
                  companyName={el.operatedBy}
                  fundPeriod={el.fundingPeriod}
                  description={el.description}
                  goal={el.fundingGoalMax}
                  minGoal={el.fundingGoalMin}
                  irr={el.irr}
                  location={el.location}
                  payback={el.payback}
                  projectName={el.projectName}
                  recollected={parseFloat(el.currentFunding)}
                  roi={el.roi}
                  ror={el.ror}
                  status={el.status}
                  investmentStartDate={el.investmentStartDate}
                  onSeeDetails={this.props.onSeeDetails}
                  projectType={this.props.projectType}
                  projectID={el.projectID}
                  addToWatchlist={this.props.addToWatchlist}
                  version={el.templateVersion}
                  investorCount={el.investorCount}
			/>
        ));
    };



    renderPastInvestmentsSection(settings) {
        const { showPastInvestments } = this.props;

        if (!showPastInvestments) return null;

        return (
            <PastInvestments>
                <Slider {...settings}>
                    {this.renderPastInvestmentCards()}
                </Slider>
            </PastInvestments>
        );
    };



    rendersActiveInvestmentSection() {
        return (
            <div className="right_col card-container">
                {this.renderActiveInvestmentCards()}

				{!this.props.loadingComplete &&
				<div style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					height: '25vh'
				}}>
					<ReactLoaderSpinner
						type="ThreeDots"
						color="#5bb462"
						height="100"
						width="100"
					/>
				</div>}

                {this.props.activeProjects.length === 0 && this.props.isAccredited && this.props.loadingComplete &&
                	<h3 className="purple-text"
						style={{textAlign: 'center', display: 'flex', justifyContent: 'center', alignContent: 'center', height: '25vh', alignItems: 'center'}}>
						There are no active projects currently. Please check back shortly.
                	</h3>
                }

                {this.props.activeProjects.length === 0 && !this.props.isAccredited && this.props.loadingComplete &&
					<h3 className="purple-text"
						style={{textAlign: 'center', display: 'flex', justifyContent: 'center', alignContent: 'center', height: '25vh', alignItems: 'center'}}>
						We currently do not have any offerings for Non-Accredited investors. Our team is hard at work
						reviewing potential opportunities. Once a new opportunity is available for investment, you will
						be notified. Thank you.
					</h3>
                }

                <div className="card-button-container">
                    {this.state.canSeeMore &&
                    <button className="btn btn-default btn-lg card-button-load-more"
                            onClick={() => this.addElementsToArray()}
                            type="button">
                        Load More
                    </button>}
                </div>
            </div>
        );
    };



    render() {
        const settings = {
            infinite: false,
            dots: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 3,
            initialSlide: 0,
            lazyLoad: true,
            centerPadding: 30,
            responsive: [
                {
                    breakpoint: 1600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        infinite: false,
                        centerPadding: 30,
                        dots: true
                    }
                },
                {
                    breakpoint: 960,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1,
                        centerPadding: 30,
                        centerMode: true,
                        infinite: false,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1,
                        centerPadding: 0,
                        centerMode: true,
                        infinite: false,
                    }
                },
            ]
        };

        return (
            <div>
                {this.props.showActiveOfferings &&
                this.rendersActiveInvestmentSection()}

                {/* {this.props.showPastOfferings &&
                this.renderPastInvestmentsSection(settings)} */}

                <InvestmentVideoPlayer closeVideo={this.closeVideo}
                                       openVideo={this.state.openVideo}
                                       title={this.state.selectedTitle}
                                       videoUrl={this.state.selectedVideoUrl}/>
            </div>
        );
    };
}


InvestmentViewer.propTypes = {
    activeProjects: PropTypes.array.isRequired,
    pastRegDInvestments: PropTypes.array.isRequired,
    showActiveOfferings: PropTypes.bool.isRequired,
    showPastOfferings: PropTypes.bool.isRequired,
    projectType: PropTypes.oneOf([PROJECT_REG_D, PROJECT_REG_CF]),
    onSeeDetails: PropTypes.func.isRequired,
    selectProjectType: PropTypes.any.isRequired,
    addToWatchlist: PropTypes.func.isRequired,
    isAccredited: PropTypes.bool.isRequired,
    showPastInvestments: PropTypes.bool.isRequired,
};

InvestmentViewer.defaultProps = {
    activeProjects: [],
    pastRegDInvestments: [],
    showActiveOfferings: true,
    showPastOfferings: false,
    projectType: PROJECT_REG_D,
    onSeeDetails: () => null,
    selectProjectType: null,
    addToWatchlist: () => null,
    isAccredited: false,
    showPastInvestments: true,
};

export default InvestmentViewer;
