import PropTypes from 'prop-types';
import React from 'react';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import BaseComponent from '../utils/BaseComponent';
import NotificationService from '../../../../utils/NotificationService';

class GooglePlacesComponent extends BaseComponent {
    GOOGLE_PLACES_URL = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyA6XN8SYUzQB3jwytnZhDapuimyCXes3Pw&libraries=places';

    constructor(props) {
        super(props);

        this.state = {
            status: 'waiting',
            scriptTag: null,
        };

        this.refGooglePlacesResult = React.createRef();
    }

    componentDidMount = async () => {
        try {
            await this.loadScript();
            this.setState({ status: 'done' });
        } catch (e) {
            console.log('Error loading GOOGLE PLACES DEPENDENCY');
            this.setState({ status: 'error' });
        }
    };

    componentWillUnmount() {
        const { scriptTag } = this.state;

        document.body.removeChild(scriptTag);
    }

    loadScript = () => new Promise((resolve, reject) => {
        const script = document.createElement('script');

        script.src = this.GOOGLE_PLACES_URL;
        script.addEventListener('load', () => resolve());
        script.addEventListener('error', () => reject());

        this.setState({ scriptTag: script });
        document.body.appendChild(script);
    });

    onChange = (value) => {
        const { inputName, onInputChange } = this.props;

        onInputChange({
            target: {
                name: inputName,
                value,
            },
        });
    };

    onSelectAddress = async (address) => {
        try {
            const { onPlaceSelect } = this.props;
            const _address = await geocodeByAddress(address);
            const { address_components: result, formatted_address: fullAddress } = _address[0];

            const items = Object.keys(result)
                .map(key => result[key])
                .map((item) => {
                    const { types, long_name, short_name } = item; // eslint-disable-line
                    const [type] = types;

                    return {
                        [type]: {
                            long: long_name,
                            short: short_name,
                        },
                    };
                })
                .reduce((acc, item) => ({
                    ...acc,
                    ...item,
                }), { fullAddress });

            onPlaceSelect(items);
        } catch (error) {
            console.log(error);
            NotificationService.error(
                'Error selecting the address',
                10000,
            );
        }
    };

    showAddressList = () => {
        const el = this.refGooglePlacesResult.current;

        el.classList.remove('no-display');
    };

    hideAddressList = () => {
        const el = this.refGooglePlacesResult.current;

        el.classList.add('no-display');
    };

    render = () => {
        const {
            inputTitle, inputName, placeholder, elementValue,
        } = this.props;
        const { status } = this.state;

        if (status !== 'done') {
            return null;
        }

        return (
            <PlacesAutocomplete
                value={elementValue}
                onChange={this.onChange}
                onSelect={this.onSelectAddress}
            >
                {(info) => {
                    const {
                        getInputProps, suggestions,
                        getSuggestionItemProps, loading,
                    } = info;

                    return (
                        <div className="form-group">
                            {inputTitle
                            && (
                                <label
                                    htmlFor={inputName}
                                    className="input-label"
                                >
                                    {inputTitle}
                                    {this.getRequired()}
                                </label>
                            )}
                            <input
                                id={inputName}
                                onChange={this.onInputChange}
                                onFocus={this.showAddressList}
                                onBlur={this.hideAddressList}
                                className="form-control input"
                                name={inputName}
                                type="text"
                                placeholder={placeholder}
                                value={elementValue}


                                {...getInputProps({
                                    id: inputName,
                                    className: 'form-control input',
                                    name: inputName,
                                    type: 'text',
                                    placeholder,
                                })}


                            />
                            <span id={`error_${inputName}`} className="text-danger"/>

                            <div
                                ref={this.refGooglePlacesResult}
                                className="autocomplete-dropdown-container"
                            >
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion) => {
                                    const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                        ? {
                                            backgroundColor: '#fafafa',
                                            cursor: 'pointer',
                                        }
                                        : {
                                            backgroundColor: '#ffffff',
                                            cursor: 'pointer',
                                        };

                                    return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                                className,
                                                style,
                                            })}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                }}
            </PlacesAutocomplete>
        );
    };
}

GooglePlacesComponent.propTypes = {
    elementValue: PropTypes.string.isRequired,
    inputName: PropTypes.string.isRequired,
    inputTitle: PropTypes.string,
    isRequired: PropTypes.bool.isRequired,
    onInputChange: PropTypes.func,
    onPlaceSelect: PropTypes.func,
    placeholder: PropTypes.func,
    value: PropTypes.any,
};

GooglePlacesComponent.defaultProps = {
    elementValue: '',
    inputName: '',
    inputTitle: '',
    onInputChange: () => null,
    onPlaceSelect: () => null,
    placeholder: '',
};

export default GooglePlacesComponent;
