import NotificationService from '../../../../utils/NotificationService';
import RequestService from '../../../../api/RequestService';
import {getImpersonateID} from '../../../../utils/General';
export const EMH_START_REQUEST = 'EMH_START_REQUEST';
export const EMH_END_REQUEST = 'EMH_END_REQUEST';
export const EMH_GET_INVESTOR_EMAILS_SUCCESS = 'EMH_GET_INVESTOR_EMAILS_SUCCESS';

const startRequest = () => ({ type: EMH_START_REQUEST });
const endRequest = () => ({ type: EMH_END_REQUEST });
const getEmailsSuccess = data => ({
    type: EMH_GET_INVESTOR_EMAILS_SUCCESS,
    data,
});

export const resetEmailList = getEmailsSuccess;

export const getInvestorEmails = () => async (dispatch) => {
    const impersonateID = await getImpersonateID();
    const url = `/api/investor/email-history/all/${impersonateID}`;
 

    try {
        dispatch(startRequest());
        const { data } = await RequestService.get(url);

        dispatch(getEmailsSuccess(data));
    } catch (error) {
        console.log('Error getting the email history');
        NotificationService.error(
            'Error getting the E-mail history',
            10000,
        );
        dispatch(endRequest());
    }
};

export const getInvestorEmailsForAdmin = (investorId) => async (dispatch) => {
    const url = `/api/admin/investor/${investorId}/email-history/all`;

    try {
        dispatch(startRequest());
        const { data } = await RequestService.get(url);

        dispatch(getEmailsSuccess(data));
    } catch (error) {
        console.log('Error getting the email history');
        NotificationService.error(
            'Error getting the E-mail history',
            10000,
        );
        dispatch(endRequest());
    }
};

export const updateViewStatus = selectedEmail => async (dispatch) => {
    const url = '/api/investor/email-history';
    const { historyId } = selectedEmail;

    try {
        dispatch(startRequest());
        await RequestService.put({
            url,
            data: { historyId },
        });

        dispatch(endRequest());
        dispatch(getInvestorEmails());
    } catch (error) {
        console.log('Error getting the email history');
        NotificationService.error(
            'Error getting the E-mail history',
            10000,
        );
        dispatch(endRequest());
    }
};
