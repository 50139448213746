import React, { Component } from 'react';
import swal from 'sweetalert2';
import Pagination from 'react-js-pagination';
import { withRouter } from 'react-router-dom';
import store from '../../index'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import getDate from '../../utils/ConvertDate'

class Approvecomments extends Component {
    constructor(props){
        super(props);
        this.state={
            Comments:[],
            totalProjects: [],
            allProjects: [],
            pageNumber: 1,
            totalCount: 0,
            perPage: 10,
            activePage: 1,
            id:props.session.id,
            isLoaded:false,
            errorOnLoad:false
        };


        this.getComments = this.getComments.bind(this);
       this.handlePageChange = this.handlePageChange.bind(this);


    }

    getComments(){
        fetch('/api/getApproveComment'+'/'+this.state.id+'/'+this.props.session.marketing,{
            method:'get'
        })

            .then((response)=>{
                return response.json()

            })
            .then((data)=>{
                this.setState({
                    totalRecords: data.data,
                    Records: data.data,
                    Comments: data.data.slice(0, 10),
                    pageNumber: 1,
                    totalCount: data.data.length,
                    perPage: 10,
                    activePage: 1,
                    isLoaded:true,
                    err:{},
                    errorOnLoad:false,

                });
            })
            .catch((err)=>{
                this.setState({
                    err:err,
                    errorOnLoad:true
                })

            })
    }

    onApprove(url){

        fetch('/api/commApprove/' + url+'/'+this.state.id+'/'+this.props.session.marketing, {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        })
          .then((response) => response.json())
          .then((status) => {
            if (status.code === 200) {
              this.getComments();
            }
            else if (status.code === 400) {
              console.log(status.err, 'modal error');
            }
          })
          .catch((err) => {
            console.log(err, 'modal error2');
          })
      }

    onunApprove(url){

        fetch('/api/communApprove/' + url+'/'+this.state.id+'/'+this.props.session.marketing, {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        })
          .then((response) => response.json())
          .then((status) => {
            if (status.code === 200) {
              this.getComments();
            }
            else if (status.code === 400) {
              console.log(status.err, 'modal error');
            }
          })
          .catch((err) => {
            console.log(err, 'modal error2');
          })
      }

    onDelete(url){

        fetch('/api/commdelete/' + url+'/'+this.state.id+'/'+this.props.session.marketing, {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        })
          .then((response) => response.json())
          .then((status) => {
            if (status.code === 200) {
              this.getComments();
            }
            else if (status.code === 400) {
              console.log(status.err, 'modal error');
            }
          })
          .catch((err) => {
            console.log(err, 'modal error2');
          })
      }

    onunDelete(url){

        fetch('/api/commundelete/' + url+'/'+this.state.id+'/'+this.props.session.marketing, {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        })
          .then((response) => response.json())
          .then((status) => {
            if (status.code === 200) {
              this.getComments();
            }
            else if (status.code === 400) {
              console.log(status.err, 'modal error');
            }
          })
          .catch((err) => {
            console.log(err, 'modal error2');
          })
      }



    handlePageChange(pageNumber) {
        var prev = ((pageNumber)-1)*10;
        var next = prev+10;
        this.setState({
            activePage: pageNumber,
            Comments: this.state.Records.slice(prev, next)
        });

    }


    componentDidMount(){
        this.getComments();
    }
    render() {
        return (


            <div className="container body">
                <div className="main_container">
                    {/* page content */}
                    <div className="right_col" role="main">
                        <div className='' >
                            <div className="row">

                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <div className="x_panel">
                                        <div className="x_title">
                                            <h4 className='up col-md-8'>Comments</h4>
                                           <div className="clearfix" />
                                        </div>
                                        <div className="x_content">
                                            <div className="table-responsive">
                                                <table className="table table-striped jambo_table bulk_action">
                                                    <thead className="thead-inverse">
                                                    <tr className="headings">

                                                        <th className="column-title">Project Name</th>
                                                        <th className="column-title">Company Name</th>
                                                        <th className="column-title">Comment</th>
                                                        <th className="column-title">Comment Date</th>
                                                        <th className="column-title">Approve </th>
                                                        <th className="column-title">Delete </th>




                                                    </tr>
                                                    </thead>
                                                    <tbody>

                                                    {
                                                        this.state.Comments.map((comm)=>(
                                                            <tr key={comm.CommentID} className="even pointer">

                                                                <td className=" ">{comm.ProjectName}</td>
                                                                <td className=" ">{comm.CompanyName}</td>
                                                                <td className=" ">{comm.CommentText}</td>
                                                                <td className=" ">{getDate.getDate(comm.CommentDateTime)}</td>
                                                                {(() => {
                                                                        switch (comm.pStatus) {
                                                                            case 0  : return (<td><button id="submit" type="button" onClick={() => this.onApprove(comm.CommentID)} className="btn btn-danger center">Approve</button></td>);
                                                                            case 1  : return (<td><button id="submit" type="button" onClick={() => this.onunApprove(comm.CommentID)}className="btn btn-success center">Approved</button></td>);
                                                                            default : return (<td><button id="submit" type="submit" className="btn btn-info center">default</button></td>);
                                                                        }
                                                                    })()}
                                                                {(() => {
                                                                        switch (comm.pDelete) {
                                                                            case 0  : return (<td><button id="submit" type="button" onClick={() => this.onDelete(comm.CommentID)} className="btn btn-danger center">Delete</button></td>);
                                                                            case 1  : return (<td><button id="submit" type="button" onClick={() => this.onunDelete(comm.CommentID)}className="btn btn-success center">Deleted</button></td>);
                                                                            default : return (<td><button id="submit" type="submit" className="btn btn-info center">default</button></td>);
                                                                        }
                                                                    })()}
                                                            </tr>
                                                        ))

                                                    }

                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="pull-right">
                                                <Pagination
                                                    firstPageText={<i className='glyphicon glyphicon-chevron-left' />}
                                                    lastPageText={<i className='glyphicon glyphicon-chevron-right' />}
                                                    prevPageText={<i className='glyphicon glyphicon-menu-left' />}
                                                    nextPageText={<i className='glyphicon glyphicon-menu-right' />}
                                                    activePage={this.state.activePage}
                                                    itemsCountPerPage={this.state.perPage}
                                                    totalItemsCount={this.state.totalCount}
                                                    onChange={this.handlePageChange}
                                                />
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const { object, bool } = PropTypes;

Approvecomments.propTypes = {
    session: object.isRequired,
    authenticated: bool.isRequired,
    checked: bool.isRequired
};

const mapState = ({ session }) => ({
    session: session.user,
    checked: session.checked,
    authenticated: session.authenticated
});

export default withRouter(connect(mapState)(Approvecomments));
