import PropTypes from 'prop-types'
import React from 'react';
import {Col, Modal, Row} from 'react-bootstrap';
import {ContainerComponent} from "../../../shared/ContainerComponent/ContainerComponent";
import * as Utils from '../../../../utils/General';

const ProjectUpdateExternalTestEmail = (props) => {
    const {
        openModal, closeModal, emailList, externalEmail,
        onChangeHandler, addEmailToList, removeEmail, sendEmailTest,
    } = props;

    const disableSendButton = (emailList.length === 0);
    const isValidEmail = Utils.isCorrectEmail(externalEmail);
    const hideErrorMessage = (!isValidEmail && externalEmail !== "");

    const emails = emailList.map(email => (
        <div key={email.key}
             className="email-badge">
            {email.email}
            <button className="btn-link white-text fa fa-trash pull-right"
                    onClick={() => removeEmail(email.key)}/>
        </div>
    ));

    const _addEmail = (event) => {
        if (isValidEmail) addEmailToList(event);
    };

    return (
        <Modal show={openModal}
               onHide={closeModal}>
            <Modal.Header>
                <h3>Add the emails that will receive the test email</h3>
            </Modal.Header>
            <Modal.Body>
                <ContainerComponent>
                    <Row>
                        <Col xs={12} sm={12} md={4} lg={4}/>
                        <Col xs={12} sm={12} md={4} lg={4}>
                            <form onSubmit={_addEmail}>
                                <div className="form-group">
                                    {emailList.length > 0 &&
                                    <h3>Recipients:</h3>}
                                    {emails}
                                </div>
                                <div className="form-group">
                                    <label><span className="red-text">*</span> Write an email</label>
                                    <div className="input-group form-input">
                                        <input type="email"
                                               name="externalEmail"
                                               value={externalEmail}
                                               onChange={onChangeHandler}
                                               className="form-control"/>
                                        <span className="btn btn-info input-group-addon"
                                              onClick={_addEmail}>
                                            Add Email
                                        </span>
                                    </div>
                                    {hideErrorMessage &&
                                    <div className="red-text">Not a valid email</div>}
                                </div>
                            </form>
                        </Col>
                    </Row>
                </ContainerComponent>
            </Modal.Body>
            <Modal.Footer>
                <ContainerComponent>
                    <Row>
                        <Col xs={12} sm={12} md={4} lg={4}/>
                        <Col xs={12} sm={12} md={4} lg={4}>
                            <button className="btn btn-primary pull-right"
                                    disabled={disableSendButton}
                                    onClick={sendEmailTest}>
                                Send
                            </button>
                            <button className="btn btn-danger pull-right"
                                    onClick={closeModal}>
                                Cancel
                            </button>
                        </Col>
                    </Row>
                </ContainerComponent>
            </Modal.Footer>
        </Modal>
    );
};

ProjectUpdateExternalTestEmail.propTypes = {
    addEmailToList: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    emailList: PropTypes.array.isRequired,
    externalEmail: PropTypes.string.isRequired,
    onChangeHandler: PropTypes.func.isRequired,
    openModal: PropTypes.bool.isRequired,
    removeEmail: PropTypes.func.isRequired,
    sendEmailTest: PropTypes.func.isRequired
};

ProjectUpdateExternalTestEmail.defaultProps = {
    addEmailToList: () => null,
    closeModal: () => null,
    emailList: [],
    externalEmail: "",
    onChangeHandler: () => null,
    openModal: () => null,
    removeEmail: () => null,
    sendEmailTest: () => null,
};

export default ProjectUpdateExternalTestEmail;

