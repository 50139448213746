import PropTypes from 'prop-types';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Icon from '../../shared/IconComponent/IconComponent';
import StatusModalComponent from './components/StatusModalComponent';
import { PROJECT_UPDATE_CREATE, PROJECT_UPDATE_EDIT } from '../../../utils/ApplicationConstants';
import * as Actions from './actions/actions';


class ProjectStatusContainerComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isReordering: false,
            isStatusModalOpen: false,
            statusAction: '',
            statusToEdit: {},
            statusList: [],
        };

        this.draggedtItem = null;
        this.statusListCopy = [];
    }

    componentDidMount() {
        const { getAllStatuses } = this.props;

        getAllStatuses();
    }

    componentDidUpdate(prevProps) {
        const { projectStatusList } = this.props;
        const _projectStatusList = JSON.stringify(prevProps.projectStatusList);
        const strProjectStatusList = JSON.stringify(projectStatusList);

        if (_projectStatusList !== strProjectStatusList) {
            this.setState({ statusList: projectStatusList });
        }
    }

    toggleStatusModal = (statusAction = '', statusToEdit = {}) => {
        const { isStatusModalOpen } = this.state;

        this.setState({
            isStatusModalOpen: !isStatusModalOpen,
            statusToEdit,
            statusAction,
        });
    };

    // DRAG AND DROP MANAGER
    onReorderButtonClick = () => {
        const { statusList } = this.state;

        this.statusListCopy = [...statusList];
        this.setState({ isReordering: true });
    };

    onCancelReorderButtonClick = () => {
        this.setState({ statusList: this.statusListCopy });
        this.cleanReorder();
    };

    onSaveReorder = () => {
        // TODO: ADD CODE TO STORE THE POSITION CHANGES IN THE DB
        this.cleanReorder();
    };

    cleanReorder = () => {
        this.statusListCopy = [];
        this.setState({ isReordering: false });
    };

    onDragOver = (draggedOverItem) => {
        if (draggedOverItem.statusId === this.draggedtItem.statusId) return;

        const { statusList } = this.state;
        const listCopy = [...statusList];
        const draggedItemIndex = statusList.indexOf(this.draggedtItem);

        listCopy.splice(draggedItemIndex, 1);

        const draggedOverIndex = listCopy.indexOf(draggedOverItem);

        listCopy.splice(draggedOverIndex, 0, this.draggedtItem);

        this.setState({ statusList: listCopy });
    };

    onDragStart = (event, selectedItem) => {
        this.draggedtItem = selectedItem;

        event.dataTransfer.effectAllowed = 'move'; // eslint-disable-line
        event.dataTransfer.setData('text/html', event.target.parentNode); // eslint-disable-line
        event.dataTransfer.setDragImage(event.target.parentNode, 20, 20); // eslint-disable-line
    };

    onDragEnd = () => {
        this.draggedtItem = null;
    };

    // RENDER MANAGER
    renderStatusItem = () => {
        const { statusList, isReordering } = this.state;

        return statusList.map(status => (
            <tr
                key={status.statusId}
                onDragOver={() => this.onDragOver(status)}
            >
                {isReordering && (
                    <td
                        style={{ align: 'center' }}
                        onDragStart={event => this.onDragStart(event, status)}
                        onDragEnd={this.onDragEnd}
                        draggable
                    >
                        <Icon iconName="sort"/>
                    </td>
                )}
                <td>{status.statusName}</td>
                <td>
                    <button
                        className="btn btn-primary no-margin capitalize-text btn-sm"
                        onClick={() => this.toggleStatusModal(PROJECT_UPDATE_EDIT, status)}
                        type="button"
                    >
                        <Icon iconName="pencil" extraClasses="white-text"/>
                        {' '}
                        edit
                    </button>
                    <button
                        className="btn btn-danger no-margin capitalize-text btn-sm"
                        type="button"
                    >
                        <Icon iconName="trash" extraClasses="white-text"/>
                        {' '}
                        Delete
                    </button>
                </td>
            </tr>
        ));
    };

    renderMainButton = () => {
        const { isReordering } = this.state;

        if (isReordering) return null;

        return (
            <div className="pull-right">
                <button
                    className="btn btn-default upper-case-text btn-sm"
                    onClick={() => this.toggleStatusModal(PROJECT_UPDATE_CREATE)}
                    type="button"
                >
                    <Icon iconName="plus"/>
                    {' '}
                    Add status
                </button>
                <button
                    className="btn btn-default upper-case-text btn-sm"
                    onClick={this.onReorderButtonClick}
                    type="button"
                >
                    <Icon iconName="sort"/>
                    {' '}
                    Reorder statuses
                </button>
            </div>
        );
    };

    renderOrderingButtons = () => {
        const { isReordering } = this.state;

        if (!isReordering) return null;

        return (
            <div className="pull-right">
                <button
                    className="btn btn-primary upper-case-text btn-sm"
                    onClick={this.onSaveReorder}
                    type="button"
                >
                    <Icon iconName="floppy-o" extraClasses="white-text"/>
                    {' '}
                    Save order changes
                </button>
                <button
                    className="btn btn-warning upper-case-text btn-sm"
                    onClick={this.onCancelReorderButtonClick}
                    type="button"
                >
                    <Icon iconName="times" extraClasses="white-text"/>
                    {' '}
                    cancel changes
                </button>
            </div>
        );
    };

    render = () => {
        const {
            isReordering, isStatusModalOpen,
            statusAction, statusToEdit,
        } = this.state;
        const {
            createNewStatus,
        } = this.props;

        return (
            <div className="container margin-top-30px">
                <div className="col-xs-12 col-md-2"/>
                <div className="col-xs-12 col-md-8">
                    <fieldset>
                        <legend className="capitalize-text">
                            <div className="pull-left">
                                <h2 className="heading-text">project statuses</h2>
                            </div>

                            {this.renderMainButton()}
                            {this.renderOrderingButtons()}
                        </legend>

                        <div className="col-xs-12">
                            <div className="table-responsive">
                                <table className="table table-striped table-hover">
                                    <thead>
                                    <tr>
                                        {isReordering && <th/>}
                                        <th>Status Name</th>
                                        <th>Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.renderStatusItem()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </fieldset>
                </div>

                <StatusModalComponent
                    isStatusModalOpen={isStatusModalOpen}
                    onModalClose={this.toggleStatusModal}
                    statusToEdit={statusToEdit}
                    action={statusAction}
                    onSaveChanges={createNewStatus}
                />
            </div>
        );
    };
}

ProjectStatusContainerComponent.propTypes = {
    createNewStatus: PropTypes.func.isRequired,
    getAllStatuses: PropTypes.func.isRequired,
    projectStatusList: PropTypes.array.isRequired,
};

ProjectStatusContainerComponent.defaultProps = {
    createNewStatus: () => null,
    getAllStatuses: () => null,
    projectStatusList: [],
};

const mapStateToProps = _state => ({ ..._state.projectStatusManager });
const mapDispatchToProps = _dispatch => ({ ...bindActionCreators(Actions, _dispatch) });

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProjectStatusContainerComponent);
