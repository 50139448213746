import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import swal2 from 'sweetalert2';

import store from '../../index';
import RequestService from '../../api/RequestService';
import AddProductionData from './AddProductionData';



class RecordEarnings extends Component {
    constructor(props) {
        super(props);

        this.state = {
			id: props.session.id,
			dataEntryProjectID: 0,
			projectInfo: {},
            Projects_RegD: [{"ProjectName": "No RegD Projects"}],
            Projects_RegCF: [{"ProjectName": "No RegCF Projects"}],
            Type: '',

			// Production Data Vars:
			// GasMCF: 0,
			// OilBarrels: 0,
			// FundTax: 0,
			// GasPrice: 0,
			// OilPrice: 0,
			// TotalPayout: 0,
			// TotalOpEx: 0,
			// FundEscrow: 0,
        };

        store.subscribe(() => {
            let currentState = store.getState();

            this.setState({
                id: currentState.session.user.id
            });
        });

		this.proInvForm = this.proInvForm.bind(this);
		this.onTypeSelect = this.onTypeSelect.bind(this);
		this.onSelectProject = this.onSelectProject.bind(this);
		this.onInputChange = this.onInputChange.bind(this);
		this.setTotalPayout = this.setTotalPayout.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }



	componentDidMount() {
		window.scrollTo(0, 0);
		this.proInvForm();
	}



	async proInvForm() {
		const { data } = await RequestService.get({
			url: `/api/getProjectList`
		});

		if (data.reg_d.length > 0) {
			this.setState({
				Projects_RegD: data.reg_d,
			});
		}

		if (data.reg_cf.length > 0) {
			this.setState({
				Projects_RegCF: data.reg_cf,
			});
		}
	}



	onTypeSelect() {
        this.setState({
            Type: this.refs.type.value
        });
    }



	async onSelectProject(e) {
    	// Notes: get data-attribute:  https://stackoverflow.com/questions/35180109/how-do-i-retrieve-select-option-data-attribute-value-in-reactjs

		let projectID = e.target[e.target.selectedIndex].value;
		let parentProjectID = e.target[e.target.selectedIndex].getAttribute('data-parentid');

		let dataEntryProjectID = (parentProjectID > 0 ? parentProjectID : projectID);

		const { data } = await RequestService.get({
			url: `/api/project/details/${dataEntryProjectID}`
		});

		if (data.code === 200) {
			this.setState({
				dataEntryProjectID: dataEntryProjectID,
				projectInfo: data.projectInfo
			});
		}
		else {
			alert('Could not get project details. Please try again.');
		}
	}



	onInputChange(e) {
		const { name, value } = e.target;

		this.setState({ [name]: value });
	}



	setTotalPayout(totalPayout) {
    	this.setState({TotalPayout: totalPayout});
	}



    async onSubmit(e) {
        e.preventDefault();



        async function savePayout(props, adminID, projType, projectID, paymentAmount, internalNotes, bitcoinTransaction, bitcoinAmount) {
			let formBody = [];
			let bitcoinPayment = bitcoinTransaction ? 1 : 0;
			formBody.push("EnteredByAdminUserID=" + encodeURIComponent(adminID));
			formBody.push("Type=" + encodeURIComponent(projType));
			formBody.push("ProjectInvestmentID=" + encodeURIComponent(projectID));
			formBody.push("PaymentAmount=" + encodeURIComponent(paymentAmount));
			formBody.push("InternalNotes=" + encodeURIComponent(internalNotes));
			formBody.push("BitcoinPayment=" + encodeURIComponent(bitcoinPayment));
			formBody.push("BitcoinAmount=" + encodeURIComponent(bitcoinAmount));
			formBody = formBody.join("&");

			try {
				const {data: status} = await RequestService.post({
					url: `/api/savePaymentEarnings`,
					body: formBody,
				});

				// console.log(JSON.stringify(status));
				if (status.code === 200) {
					swal('Earnings Recorded Successfully.', '', 'success');
					props.updateRowsOnSuccess();
					props.closeModal();
				}
				else if (status.code === 400) {
					alert("Error occured");
				}
			}
			catch (err) {
				console.log(err);
				alert("Error: " + JSON.stringify(err))
			}
		}



        if (this.refs.type.value === 'RegD' && this.refs.projectID.value > 0) {
        	let paymentAmount = this.refs.PaymentAmount.value.replace(/[^0-9.]/g, '').trim();	// Strip out non numbers, except decimals.
        	let bitcoinAmount = this.refs.BitcoinAmount.value.replace(/[^0-9.]/g, '').trim();	// Strip out non numbers, except decimals.

        	if (this.refs.overrideEntry.checked) {
				await savePayout(this.props, this.state.id, this.state.Type, this.refs.projectID.value, paymentAmount, this.refs.InternalNotes.value,this.refs.bitcoinTransaction.checked,bitcoinAmount);
			}
        	else {
				// (1) Do Calculations:
				const { dataEntryProjectID, MonthYear, TotalPayout, FundEscrow, GasMCF, OilBarrels, FundTax, GasPrice, OilPrice, TotalOpEx } = this.state;

				if (GasMCF !== '' && OilBarrels !== '' && FundTax !== '' && GasPrice !== '' && OilPrice !== ''
					&& TotalOpEx !== '' && FundEscrow !== '' && MonthYear && TotalPayout) {

					if (Number(this.state.TotalPayout) === Number(paymentAmount)) {

						// (2) Save Data:
						let formBody = [];
						formBody.push(`MonthYear=${encodeURIComponent(MonthYear)}`);
						formBody.push(`TotalPayout=${encodeURIComponent(TotalPayout)}`);
						formBody.push(`FundEscrow=${encodeURIComponent(FundEscrow)}`);
						formBody.push(`GasMCF=${encodeURIComponent(GasMCF)}`);
						formBody.push(`OilBarrels=${encodeURIComponent(OilBarrels)}`);
						formBody.push(`FundTax=${encodeURIComponent(FundTax)}`);
						formBody.push(`GasPrice=${encodeURIComponent(GasPrice)}`);
						formBody.push(`OilPrice=${encodeURIComponent(OilPrice)}`);
						formBody.push(`TotalOpEx=${encodeURIComponent(TotalOpEx)}`);
						formBody = formBody.join('&');

						const { data } = await RequestService.post({
							url: `/api/project/addProductionData/${dataEntryProjectID}`,
							data: formBody
						});

						if (data.code === 200) {
							swal2({
								title: 'Data Saved',
								type: 'success',
							});
						}
						else {
							swal2({
								title: 'There was an error',
								text: 'Data did not save.',
								type: 'warning',
							});
						}

						// (3) Save Payout:
						await savePayout(this.props, this.state.id, this.state.Type, this.refs.projectID.value, paymentAmount, this.refs.InternalNotes.value,this.refs.bitcoinTransaction.checked,bitcoinAmount);
					}
					else {
						swal('Payment Amount Calculation Mismatch.', '', 'error');
					}
				}
				else {
					swal('Please complete all fields', '', 'error');
				}
			}
		}
        else if (this.refs.type.value === 'RegCF' && this.refs.projectID.value > 0) {
        	await savePayout(this.props, this.state.id, this.state.Type, this.refs.projectID.value, this.refs.PaymentAmount.value.replace(/[^0-9.]/g, '').trim(), this.refs.InternalNotes.value,this.refs.bitcoinTransaction.checked,this.refs.BitcoinAmount.value.replace(/[^0-9.]/g, '').trim());
		}
		else {
			swal('Please complete all fields', '', 'error');
		}
    }



    render() {
        return (
            <div className="container body">
                <div className="main_container">
                    <div className="right_col" role="main">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-2"/>

                                <div className="col-md-8 col-sm-8 col-xs-12">
                                    <div className="panel-body form-horizontal payment-form">
                                        <div className="form-group">
                                            <label htmlFor="status"
                                                   className="col-sm-3 col-md-3 col-xs-12 control-label">
                                                Project Type:
                                            </label>
                                            <p className="col-sm-9 col-md-9 col-xs-12">
                                                <select className="form-control" id="status" ref="type"
                                                        name="status" onChange={this.onTypeSelect}
												>
                                                    <option value={0}>Select Project Type</option>
                                                    <option value={"RegCF"}>Regulation CF</option>
                                                    <option value={"RegD"}>Regulation D</option>
                                                </select>
                                            </p>
                                            <label htmlFor="status"
                                                   className="col-sm-3 col-md-3 col-xs-12 control-label">
                                                Project Name:
                                            </label>
                                            <div className="col-sm-9 col-md-9 col-xs-12">
                                                <select className="form-control" id="projectID" ref="projectID"
														name="projectID"
														onChange={this.onSelectProject}
												>
                                                    <option value={0}>Select Project</option>

													{this.state.Type === 'RegD' && this.state.Projects_RegD.map((entity) => (
														<option key={entity.ProjectInvestmentID} data-parentid={entity.ParentProjectID}
																value={entity.ProjectID}>({entity.Entity}) {entity.ProjectName}</option>
													))}

													{this.state.Type === 'RegCF' && this.state.Projects_RegCF.map((entity) => (
														<option key={entity.ProjectInvestmentID}
																value={entity.ProjectID}>{entity.InvestmentName}</option>
													))}

                                                </select>
                                            </div>
                                        </div>

										<div className="form-group">
											<label htmlFor="PaymentAmount"
												   className="col-sm-3 col-md-3 col-xs-12 control-label">
												Override Entry:
											</label>
											<div className="col-sm-9 col-md-9 col-xs-12">
												<div className="check_tc">
													<label className="custom-container">
														<input type="checkbox" ref="overrideEntry" name="OverrideEntry" id="OverrideEntry" />
														<span className="checkmark" />
													</label>
												</div>
											</div>
										</div>
										<div className="form-group">
											<label htmlFor="PaymentAmount"
												   className="col-sm-3 col-md-3 col-xs-12 control-label">
												Bitcoin Transaction:
											</label>
											<div className="col-sm-9 col-md-9 col-xs-12">
												<div className="check_tc">
													<label className="custom-container">
														<input type="checkbox" ref="bitcoinTransaction" name="bitcoinTransaction" id="bitcoinTransaction" />
														<span className="checkmark" />
													</label>
												</div>
											</div>
										</div>

										{(this.state.Type === 'RegD' && this.state.dataEntryProjectID > 0) &&
										<AddProductionData projectID={this.state.dataEntryProjectID} from="RecordEarnings"
														   onInputChange={this.onInputChange} setTotalPayout={this.setTotalPayout}
										/>}

										<div className="form-group">
                                            <label htmlFor="PaymentAmount"
                                                   className="col-sm-3 col-md-3 col-xs-12 control-label">
                                                Net Revenue Amount:
                                            </label>
                                            <div className="col-sm-9 col-md-9 col-xs-12">
                                                <input type="text" className="form-control" id="PaymentAmount"
                                                       name="PaymentAmount" ref="PaymentAmount" placeholder="Enter..."
													   style={{border: '1px solid #ccc', borderRadius: 0, backgroundColor: 'white'}}
												/>
                                            </div>
                                        </div>
										<div className="form-group">
                                            <label htmlFor="BitcoinAmount"
                                                   className="col-sm-3 col-md-3 col-xs-12 control-label">
                                                Net Revenue Bitcoin value:
                                            </label>
                                            <div className="col-sm-9 col-md-9 col-xs-12">
                                                <input type="text" className="form-control" id="BitcoinAmount"
                                                       name="BitcoinAmount" ref="BitcoinAmount" placeholder="Enter Bitcoin amount"
													   style={{border: '1px solid #ccc', borderRadius: 0, backgroundColor: 'white'}}
												/>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="description"
                                                   className="col-sm-3 col-md-3 col-xs-12 control-label">
                                                Internal Notes:
                                            </label>
                                            <div className="col-sm-9 col-md-9 col-xs-12">
                                                <textarea className="form-control" id="description"
														  name="description" ref="InternalNotes"
														  placeholder="Enter..." rows="4"
												/>
                                            </div>
                                        </div>

                                        <center>
                                            <button type="submit"
                                                    className="btn btn-danger"
                                                    onClick={this.props.closeModal}
                                                    style={{width: 100}}>
                                                Cancel
                                            </button> &nbsp;&nbsp;&nbsp;&nbsp;
                                            <button type="submit"
                                                    className="btn btn-success"
                                                    onClick={this.onSubmit}
                                                    style={{width: 100}}>
                                                Submit
                                            </button>
                                        </center>

                                    </div>
                                </div>

                                <div className="col-md-2"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const {object, bool, func} = PropTypes;

RecordEarnings.propTypes = {
    session: object.isRequired,
    authenticated: bool.isRequired,
    checked: bool.isRequired,
    closeModal: func.isRequired,
    updateRowsOnSuccess: func.isRequired,
};

RecordEarnings.defaultProps = {
    closeModal: () => null,
    updateRowsOnSuccess: () => null,
};

const mapState = ({session}) => ({
    session: session.user,
    checked: session.checked,
    authenticated: session.authenticated
});

export default withRouter(connect(mapState)(RecordEarnings));
