import PropTypes from "prop-types";
import React from "react";
import Pagination from "react-js-pagination";
import moment from "moment";
import { getUUID } from "../../../utils/General";
import { withRouter } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { GrNotes } from "react-icons/gr";


class MembershipTableComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			activePage: 1,
			itemsPerPage: props.itemsPerPage,
			totalItems: 0,
			selectedItemsList: [],
		};
		this.handlePageChange = this.handlePageChange.bind(this);
		this.handleChangeItemsPerPage =
			this.handleChangeItemsPerPage.bind(this);
	}
	componentDidMount() {
		const { itemsPerPage } = this.state;
		const { memberships } = this.props;
		let page = 1;
		const prev = (page - 1) * itemsPerPage;
		const next = prev + itemsPerPage;
		this.setState({
			activePage: page,
			selectedItemsList: memberships.slice(prev, next),
			totalItems: memberships.length,
		});
	}
	componentDidUpdate(prevProps) {
		const { memberships } = this.props;
		const { activePage } = this.state;
		const _investorEarningsList = JSON.stringify(prevProps.memberships);
		const strInvestorEarningsList = JSON.stringify(memberships);
		if (_investorEarningsList !== strInvestorEarningsList) {
			this.setState({ totalItems: memberships.length }, () =>
				this.handlePageChange(activePage, false)
			);
		}
	}
	handlePageChange(page, scrollUp) {
		const { itemsPerPage } = this.state;
		const { memberships } = this.props;
		const prev = (page - 1) * itemsPerPage;
		const next = prev + itemsPerPage;
		this.setState({
			activePage: page,
			selectedItemsList: memberships.slice(prev, next),
		});
		if (scrollUp) {
			//document.getElementById('EarningTableComponent').scrollIntoView();
		}
	}
	handleChangeItemsPerPage() {
		this.setState(
			{
				itemsPerPage: Number(this.refs.selectItemsPerPage.value), // Must be typecast.
				activePage: 1,
			},
			() => this.handlePageChange(1, true)
		);
	}

	render() {
		const { activePage, itemsPerPage, totalItems, selectedItemsList } =
			this.state;
		return (
			<div id="InvestmentsComponent" className="mt-4">
				{/* <h3>My Membership</h3> */}
				<div className="col-xs-12 p-0">
					<fieldset>
						<div className="table-responsive">
							<table className="table table-striped jambo_table">
								<thead>
									<tr className="no-text-wrap">
										<th>Date</th>
										<th>PROJECT</th>
										<th>Entity</th>
										<th>Type</th>
										<th>Investment</th>
										<th>Fee</th>
										<th>Membership Units</th>
										<th>Refunded</th>
										<th>Edit</th>
									</tr>
								</thead>
								<tbody>
									{(() => {
										return selectedItemsList?.filter(item=>item?.Deleted==0)?.map((p) => (
											<tr key={getUUID()} style={{textDecoration: p?.Refunded === 1 ? 'line-through' : ''}}>
												<td>
													{moment(
														p.InvestmentDate
													).format("MMM DD, YYYY")}
												</td>
												<td>{p.ProjectName}</td>
												<td>{p.Entity}</td>
												<td>
													{p.ProjectID_RegD === 0
														? "Reg CF"
														: "Reg D"}
												</td>
												<td>{p.InvestmentAmount}</td>
												<td>{p.FeeAmount}</td>
												<td>{p.MembershipUnits}</td>
												<td>
													{p.Refunded === 1
														? "Yes"
														: "No"}
												</td>
												<td className="d-flex " style={{justifyContent: 'space-between'}}><span onClick={()=>this.props.editMembershipFeeTable(p)} className="p-2"><FaEdit size={20}/></span>{p?.notesCount > 0 && <span onClick={()=>this.props?.showMembershipNotesTable(p)} className="p-2"><GrNotes size={20}/></span>}</td>
											</tr>
										));
									})()}
								</tbody>
							</table>
						</div>
					</fieldset>
				</div>
				<div className="col-xs-12">
					<div className="pull-right">
						<Pagination
							firstPageText={
								<i className="glyphicon glyphicon-chevron-left" />
							}
							lastPageText={
								<i className="glyphicon glyphicon-chevron-right" />
							}
							prevPageText={
								<i className="glyphicon glyphicon-menu-left" />
							}
							nextPageText={
								<i className="glyphicon glyphicon-menu-right" />
							}
							activePage={activePage}
							itemsCountPerPage={itemsPerPage}
							totalItemsCount={totalItems}
							onChange={this.handlePageChange}
						/>
						<div
							className="pull-left"
							style={{ margin: "0 10px 0 0" }}
						>
							<select
								ref="selectItemsPerPage"
								style={{
									margin: "20px 0",
									padding: "0 10px",
									height: 32,
								}}
								onChange={this.handleChangeItemsPerPage}
							>
								<option>5</option>
								<option>10</option>
								<option>20</option>
								<option>50</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
MembershipTableComponent.propTypes = {
	memberships: PropTypes.array.isRequired,

	itemsPerPage: PropTypes.number.isRequired,
};
MembershipTableComponent.defaultProps = {
	memberships: [],

	itemsPerPage: 10,
};

export default withRouter(MembershipTableComponent);
