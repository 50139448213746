import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import moment from 'moment';
import CollapsiblePanelComponent from '../CollapsiblePanelComponent/CollapsiblePanelComponent';
import { formatMoney, getUUID } from '../../../utils/General';


class EarningsTableComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activePage: 1,
            itemsPerPage: props.itemsPerPage,
            totalItems: 0,
            selectedItemsList: [],
        };

		this.handlePageChange = this.handlePageChange.bind(this);
		this.handleChangeItemsPerPage = this.handleChangeItemsPerPage.bind(this);
    }



    componentDidUpdate(prevProps) {
        const { investorEarningsList } = this.props;
		const { activePage } = this.state;

        const _investorEarningsList = JSON.stringify(prevProps.investorEarningsList);
        const strInvestorEarningsList = JSON.stringify(investorEarningsList);

        if (_investorEarningsList !== strInvestorEarningsList) {
			this.setState({ totalItems: investorEarningsList.length }, () => this.handlePageChange(activePage, false));
        }
    }



    handlePageChange(page, scrollUp) {
        const { itemsPerPage } = this.state;
        const { investorEarningsList } = this.props;

        const prev = ((page) - 1) * itemsPerPage;
        const next = prev + itemsPerPage;

        this.setState({
            activePage: page,
            selectedItemsList: investorEarningsList.slice(prev, next),
        });

		if (scrollUp) {
			document.getElementById('EarningsTableComponent').scrollIntoView();
		}
    };



	handleChangeItemsPerPage() {
		this.setState({
			itemsPerPage: Number(this.refs.selectItemsPerPage.value),	// Must be typecast.
			activePage: 1
		}, () => this.handlePageChange(1, true));
	}



    render() {
        const { totalPaymentsRegD, totalPaymentsRegCf, role } = this.props;
        const { activePage, itemsPerPage, totalItems, selectedItemsList } = this.state;

        return (
            <CollapsiblePanelComponent
                panelTitle={<h3 className="heading-text" id="EarningsTableComponent">My Earnings</h3>}
                isExpanded
            >
                <div className="col-xs-12">
                    <div className="pull-left">
                        <h4>
                            Regulation D Total: $
                            {formatMoney(totalPaymentsRegD)}
                        </h4>
                        <h4>
                            Regulation CF Total: $
                            {formatMoney(totalPaymentsRegCf)}
                        </h4>
                    </div>
					{(() => {
						if (role !== 'Admin') {
							return(
								<div className="pull-right">
									<Link
										className="btn btn-success small pull-right"
										to={'/app/Earnings2'}
									> View All
									</Link>
								</div>
							);
						}
					})()}
                </div>
                <hr />
                <div className="col-xs-12">
                    <fieldset>
                        <div className="table-responsive">
                            <table className="table table-striped jambo_table">
                                <thead>
                                    <tr className="no-text-wrap">
										{(() => {
											if (role === 'Admin') {
												return (<th>Payment ID</th>);
											}
										})()}
                                        <th>Date</th>
                                        <th>Investor Entity</th>
                                        <th>Type</th>
                                        <th>Project</th>
                                        <th>Project Entity</th>
                                        <th>Amount</th>
										{(() => {
											if (role === 'Admin') {
												return (<th>Payment Method</th>);
											}
										})()}
                                    </tr>
                                </thead>
                                <tbody>
									{(() => {
										return selectedItemsList.map(p => (
											<tr key={getUUID()}>
												{(() => {
													if (role === 'Admin') {
														return (<td>{p.PaymentID}</td>);
													}
												})()}
												<td>
													{moment(p.EarningDate).format('MMM DD, YYYY')}
												</td>
												<td>{p.Entity}</td>
												<td>{p.ProjectID_RegD === 0 ? 'Reg CF' : 'Reg D'}</td>
												<td>{p.ProjectID_RegD === 0 ? p.InvestmentName : p.ProjectName}</td>
												<td>{p.CompanyEntity}</td>
												<td>${formatMoney(p.EarningAmount)}</td>
												{(() => {
													if (role === 'Admin') {
														return (<td>{p.PaymentMethod}</td>);
													}
												})()}
											</tr>
										));
									})()}
                                </tbody>
                            </table>
                        </div>
                    </fieldset>
                </div>

                <div className="col-xs-12">
					<div className="pull-right">
						<Pagination
							firstPageText={<i className="glyphicon glyphicon-chevron-left" />}
							lastPageText={<i className="glyphicon glyphicon-chevron-right" />}
							prevPageText={<i className="glyphicon glyphicon-menu-left" />}
							nextPageText={<i className="glyphicon glyphicon-menu-right" />}
							activePage={activePage}
							itemsCountPerPage={itemsPerPage}
							totalItemsCount={totalItems}
							onChange={this.handlePageChange}
						/>
						<div className="pull-left" style={{margin: '0 10px 0 0'}}>
							<select ref="selectItemsPerPage" style={{margin: '20px 0', padding: '0 10px', height: 32}} onChange={this.handleChangeItemsPerPage}>
								<option>5</option>
								<option>10</option>
								<option>20</option>
								<option>50</option>
							</select>
						</div>
					</div>
                </div>
            </CollapsiblePanelComponent>
        );
    };
}


EarningsTableComponent.propTypes = {
    investorEarningsList: PropTypes.array.isRequired,
    totalPaymentsRegCf: PropTypes.number.isRequired,
    totalPaymentsRegD: PropTypes.number.isRequired,
    itemsPerPage: PropTypes.number.isRequired,
};

EarningsTableComponent.defaultProps = {
    investorEarningsList: [],
    totalPaymentsRegCf: 0,
    totalPaymentsRegD: 0,
    itemsPerPage: 10,
};

export default EarningsTableComponent;
