import React, { Component } from 'react';
import swal from 'sweetalert2';
import store from '../../index'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import getDate from '../../utils/ConvertDate'
import {Link} from 'react-router-dom'
import { withRouter } from 'react-router-dom';
import InvestorMessageSearchComponent from '../shared/InvestorMessageSearchComponent/InvestorMessageSearchComponent';
import RequestService from '../../api/RequestService';
import  './css/messages.css'
class Messages extends Component {
    constructor(props){
        super(props);
        this.state={
            id: props.session.id,
            FirstName:'',
          
        };
     
    }
    componentDidMount(){

        this.getInvestorProfile();
    }
    getInvestorProfile = async () => {
      
        try {
            const { data } = await RequestService.get('/api/getInvestorProfile');

            if (data.code === 200) {
                this.setState({
                  
                 	FirstName:data.rows[0].FirstName,
				
                });
              }
           
        }
        catch (err) {
            console.log(err);
        }
    };


    render() {
     
        return(
            <div className="container body messages">
            <div style={{ background: 'white' }}>

            <div className="right_col">
              
                       <div className="col-md-12 col-sm-12 col-xs-12">
                             
                     <InvestorMessageSearchComponent userID ={this.state.id} userName ={this.state.FirstName}/>
               </div>
                          
                   </div>
                </div>
            </div>


        );
    }
}

const { object, bool } = PropTypes;

Messages.propTypes = {
    session: object.isRequired,
    authenticated: bool.isRequired,
    checked: bool.isRequired
};

const mapState = ({session}) => ({
    session:session.user,
    checked: session.checked,
    authenticated:session.authenticated,
   
});

export default withRouter(connect(mapState)(Messages));
