import React from 'react';
import PropTypes from "prop-types";
import Pagination from "react-js-pagination";
import TableElement from './ProjectUpdateTableElement';
import {PROJECT_REG_CF, PROJECT_REG_D} from "../../../../utils/ApplicationConstants";
import {getUUID} from "../../../../utils/General";

const propTypes = {
    projectList: PropTypes.array.isRequired,
    projectType: PropTypes.oneOf([PROJECT_REG_CF, PROJECT_REG_D]),
    onApproveClickHandler: PropTypes.func.isRequired,
    onCloneAsNew: PropTypes.func.isRequired,
    onRejectClickHandler: PropTypes.func.isRequired,
    onEditClickHandler: PropTypes.func.isRequired,
    onDeleteClickHandler: PropTypes.func.isRequired,
    onOpenUpdateTypeEditor: PropTypes.func.isRequired,
    onRestoreDeletedClickHandler: PropTypes.func.isRequired,
    onSelectFileToEdit: PropTypes.func.isRequired,
    onViewClickHandler: PropTypes.func.isRequired,
};

const emptyMethod = () => null;
const defaultProps = {
    projectList: [],
    projectType: PROJECT_REG_D,
    onApproveClickHandler: emptyMethod,
    onSelectFileToEdit: emptyMethod,
    onCloneAsNew: emptyMethod,
    onRejectClickHandler: emptyMethod,
    onEditClickHandler: emptyMethod,
    onDeleteClickHandler: emptyMethod,
    onOpenUpdateTypeEditor: emptyMethod,
    onRestoreDeletedClickHandler: emptyMethod,
    onViewClickHandler: emptyMethod,
};


class ProjectUpdateTable extends React.Component {
    constructor(props) {
        super(props);

        this.ITEMS_PER_PAGE = 10;
        this.state = {
            activePage: 1,
            totalItems: 0,
            selectedPageElements: [],
        }
    }

    componentDidMount() {
        this.updateComponentState();
    }

    componentDidUpdate(prevProps) {
        const prevProjects = JSON.stringify(prevProps.projectList);
        const actualProject = JSON.stringify(this.props.projectList);

        if (prevProjects !== actualProject) {
            this.updateComponentState();
        }
    }

    updateComponentState = (activePage = this.state.activePage) => {
        const {projectList} = this.props;
        const selectedPageElements = this.getPageItems(activePage);
        this.setState({
            activePage,
            selectedPageElements,
            totalItems: projectList.length,
        });
    };

    getPageItems = (activePage = 1) => {
        const {projectList} = this.props;
        const start = (activePage - 1) * this.ITEMS_PER_PAGE;
        const end = (start + this.ITEMS_PER_PAGE);

        return projectList.slice(start, end);
    };

    renderTableElements = () => {
        let {selectedPageElements} = this.state;
        selectedPageElements = selectedPageElements || [];
        return selectedPageElements.map(el => (
            <TableElement key={getUUID()}
                          project={el}
                          projectType={this.props.projectType}
                          onEditClickHandler={this.props.onSelectFileToEdit}
                          onDeleteClickHandler={this.props.onDeleteClickHandler}
                          onRejectClickHandler={this.props.onRejectClickHandler}
                          onApproveClickHandler={this.props.onApproveClickHandler}
                          onRestoreDeletedClickHandler={this.props.onRestoreDeletedClickHandler}
                          onViewClickHandler={this.props.onViewClickHandler}
                          onOpenUpdateTypeEditor={this.props.onOpenUpdateTypeEditor}
                          onCloneAsNew={this.props.onCloneAsNew}/>
        ));
    };

    render = () => {
        const {activePage, totalItems} = this.state;

        return (
            <div className="col-xs-12 col-sm-12 col-md-12">
                <div className="table-responsive">
                    <table className="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th className="column-title">Project Name</th>
                            <th className="column-title">Internal Title</th>
                            <th className="column-title">Entity</th>
                            <th className="column-title no-text-wrap">User Name</th>
                            <th className="column-title">Creation Date</th>
                            <th className="column-title">Subject</th>
                            <th className="column-title">Status</th>
                            <th className="column-title no-text-wrap">Display Status</th>
                            <th className="column-title text-center">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.renderTableElements()}
                        </tbody>
                    </table>
                </div>
                <div className="pull-right">
                    <Pagination
                        firstPageText={<i className='glyphicon glyphicon-chevron-left'/>}
                        lastPageText={<i className='glyphicon glyphicon-chevron-right'/>}
                        prevPageText={<i className='glyphicon glyphicon-menu-left'/>}
                        nextPageText={<i className='glyphicon glyphicon-menu-right'/>}
                        activePage={activePage}
                        itemsCountPerPage={this.ITEMS_PER_PAGE}
                        totalItemsCount={totalItems}
                        onChange={this.updateComponentState}
                    />
                </div>
            </div>
        );
    };
}

ProjectUpdateTable.propTypes = propTypes;
ProjectUpdateTable.defaultProps = defaultProps;

export default ProjectUpdateTable;
