function _storeData(key, data) {
    const _data = JSON.stringify(data);
    localStorage.setItem(key, _data);
}

function _getData(key) {
    const _data = localStorage.getItem(key);
    return JSON.parse(_data)
}


const storeSelectedUpdateInfo = (data) => _storeData("SelectedProjectUpdate", data);
const getStoredSelectedUpdateInfo = () => _getData("SelectedProjectUpdate");

const storeExternalImages = (data) => _storeData("storeExternalImages", data);
const getExternalImages = () => _getData("storeExternalImages");

export default {
    storeSelectedUpdateInfo,
    getStoredSelectedUpdateInfo,
    storeExternalImages,
    getExternalImages,
}
