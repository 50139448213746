import React from 'react';
import PropTypes from 'prop-types'
import {getUUID} from "../../../../../utils/General";
import moment from 'moment'

class UpdatesTabComponent extends React.Component {
    largeFont = {fontSize: 17};

    renderUpdates = () => {
        const {updateList} = this.props;

        return updateList.map(update => (
            <tr key={getUUID()}>
                <th style={this.largeFont}>
                    {moment(update.createdDate).format('DD MMM, YYYY')}
                </th>
                <th>
                    <button className="btn btn-link"
                            style={this.largeFont}
                            onClick={() => this.props.onOpenUpdate(update.updateId)}>
                        {update.subject}
                    </button>
                </th>
            </tr>
        ));
    };

    render = () => {
        return (
            <div className="table-primary table-responsive">
                <table className="table table-hover table-striped">
                    <thead>
                    <tr>
                        <th>Date</th>
                        <th>Subject</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.renderUpdates()}
                    </tbody>
                </table>
            </div>
        );
    };
}

UpdatesTabComponent.propTypes = {
    onOpenUpdate: PropTypes.func.isRequired,
    tabId: PropTypes.any,
    tabName: PropTypes.string,
    tabOrder: PropTypes.number,
    updateList: PropTypes.array.isRequired
};

UpdatesTabComponent.defaultProps = {
    onOpenUpdate: () => null,
    tabId: getUUID(),
    tabName: "Updates",
    tabOrder: 1001,
    updateList: []
};

export default UpdatesTabComponent;
