import PropTypes from 'prop-types'
import React from "react";
import {USER_ADMIN} from "../../../../utils/ApplicationConstants";

const propTypes = {
    longText: PropTypes.string.isRequired,
    smallText: PropTypes.string.isRequired,
    userType: PropTypes.string.isRequired
};

const defaultProps = {
    longText: "",
    smallText: "",
    userType: ""
};

const ViewerBodyComponent = (props) => {
    const {longText, smallText, userType} = props;

    const showLongText = (!!longText && longText.length > 0);
    const showSmallText = (!!smallText && smallText.length > 0);
    const isUserAdmin = (userType === USER_ADMIN);

    return (
        <tr>
            <td align="left" valign="top"
                width="100%"
                colSpan="12"
                style={{
                    color: "#444444",
                    fontFamily: "sans-serif",
                    fontSize: "15px",
                    lineHeight: "150%",
                    textAlign: "left",
                }}>
                <div style={{padding: "30px 50px", fontSize: "16px", lineHeight: 1.5}}>
                    <div className="content">
                        {showSmallText &&
                        <section className="container">
                            {isUserAdmin &&
                            <h4 className="dark-grey-text"
                                style={{paddingTop: "10px"}}>
                                Short description (Included In The Email)
                            </h4>}
                            <div style={{paddingTop: "10px"}}
                                 dangerouslySetInnerHTML={{__html: smallText}}>
                            </div>
                        </section>}

                        {showLongText &&
                        <section className="container">
                            {isUserAdmin &&
                            <h4 className="dark-grey-text"
                                style={{paddingTop: "10px"}}>
                                Full description (Not Included In The Email)
                            </h4>}
                            <div style={{paddingTop: "10px"}}
                                 dangerouslySetInnerHTML={{__html: longText}}>
                            </div>
                        </section>}
                    </div>
                </div>
            </td>
        </tr>
    );
};

ViewerBodyComponent.propTypes = propTypes;
ViewerBodyComponent.defaultProps = defaultProps;

export default ViewerBodyComponent;
