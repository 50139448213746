import React from "react";

export default function NewUsersDashboard() {
  
	return (
		<div className="new-user-dashboard">
			<h1>Welcome to Your Investor Dashboard</h1>
            <p>In the future, this page will show your Earnings, Distributions, and Current Investment information.</p>
		</div>
	);
}
