import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import moment from 'moment';
import CollapsiblePanelComponent from '../../../../shared/CollapsiblePanelComponent/CollapsiblePanelComponent';
import SingleLineInputComponent
    from '../../../../shared/InputComponents/SingleLineInputComponent/SingleLineInputComponent';
import MultilineInputComponent
    from '../../../../shared/InputComponents/MultilineInputComponent/MultilineInputComponent';
import DateInputComponent from '../../../../shared/InputComponents/DateInputComponent/DateInputComponent';
import RequestService from '../../../../../api/RequestService';
import swal from 'sweetalert2';


const ProfileInfoComponent = (props) => {
    const { profile } = props;

    const {
		UserID, FirstName, LastName, Email,
        CreatedDate, DaysLeftInWaitPeriod, VisibleNotes,
		SignupCompletion, mfaType
    } = profile;
	const [notes, setNotes] = useState(VisibleNotes ?? "");
	useEffect(() => {
		setNotes(VisibleNotes);
	}, [VisibleNotes]);

	const notesOnChange = (e) => {
		console.log('updateNotes', e.target.value);
		setNotes(e.target.value)
	}
	const updateNotes = async function () {
		const result = await swal({
			title: 'Are you sure you want to change Notes?',
			text: '',
			type: 'info',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes',
		});
		var formBody = [];
		formBody.push("userid=" + UserID);
		formBody.push("notes=" + notes);
		formBody = formBody.join("&");
		if (result.value) {
			try {
				const { data: status } = await RequestService.post({
					url: `/api/updateUserNote`,
					data: formBody,
				});

				if (status.code === 200) {
					swal('Done!', '', 'success');
				}
				else {
					alert('Unable to reset User Accreditation.');
				}
			}
			catch (err) {
				console.log(err);
			}
		}
	};

    const showResetAccreditation = async function() {
		const result = await swal({
			title: 'Are you sure you want to Reset Accreditation?',
			text: '',
			type: 'info',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes Reset',
		});

		if (result.value) {
			try {
				const { data: status } = await RequestService.post(`/api/resetUserAccreditation/${UserID}`);

				if (status.code === 200) {
					swal('Done!', '', 'success').then(() => document.getElementById(`signupCompletionText${UserID}`).value = 'Incomplete Accreditation');
				}
				else {
					alert('Unable to reset User Accreditation.');
				}
			}
			catch (err) {
				console.log(err);
			}
		}
	};



	const disableMFAForUser = async function () {
		const result = await swal({
			title: `Are you sure you want to Disable User's MFA?`,
			text: '',
			type: 'info',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, Disable',
		});

		if (result.value) {
			try {
				const { data: status } = await RequestService.post(`/api/disableMFAForUser/${UserID}`);

				if (status.code === 200) {
					console.log('asdf', document.getElementById(`mfaStatus${UserID}`).value);
					swal('Done!', '', 'success').then(() => document.getElementById(`mfaStatus${UserID}`).value = 'MFA Disabled');
				}
				else {
					alert('Unable to disable MFA for User.');
				}
			}
			catch (err) {
				console.log(err);
			}
		}
	};



    return (
        <CollapsiblePanelComponent
            panelTitle={<h3 className="heading-text">User Profile Info</h3>}
            isExpanded
        >
            <div className="col-xs-12 col-md-3">
                <SingleLineInputComponent
                    elementValue={String(UserID)}
                    inputTitle="User ID"
                    maxInputLength={null}
                />
            </div>
            <div className="col-xs-12 col-md-9">
                <SingleLineInputComponent
                    elementValue={Email}
                    inputTitle="E-mail"
                    maxInputLength={null}
                />
            </div>
            <div className="col-xs-12 col-md-6">
                <SingleLineInputComponent
                    elementValue={FirstName}
                    inputTitle="First Name"
                    maxInputLength={null}
                />
            </div>
            <div className="col-xs-12 col-md-6">
                <SingleLineInputComponent
                    elementValue={LastName}
                    inputTitle="Last Name"
                    maxInputLength={null}
                />
            </div>
            <div className="col-xs-12 col-md-6">
                <DateInputComponent
                    elementValue={moment(CreatedDate).format('YYYY-MM-DDTHH:mm:ss')}
                    inputTitle="Created Date"
                />
            </div>
            <div className="col-xs-12 col-md-6">
                <SingleLineInputComponent
                    elementValue={String(DaysLeftInWaitPeriod)}
                    inputTitle="Days Left in Waiting Period:"
                    maxInputLength={null}
                />
            </div>
            <div className="col-xs-12">
                <MultilineInputComponent
					elementValue={notes}
					inputTitle="Notes:"
					onInputUpdate={notesOnChange}
					maxInputLength={null}
				/>
				{VisibleNotes != notes && <button onClick={updateNotes} className="btn btn-primary btn-green pull-left">Update Notes</button>}
            </div>
			<div className="col-xs-12 col-md-6">
				<SingleLineInputComponent
					inputName={`signupCompletionText${UserID}`}
					elementValue={SignupCompletion === 2 ? 'Completed Accreditation' : 'Incomplete Accreditation'}
					inputTitle="Accreditation Completion Status:"
					maxInputLength={null}
				/>
				<button onClick={showResetAccreditation} className="btn btn-primary btn-green pull-left">Reset Accreditation</button>
			</div>
			<div className="col-xs-12 col-md-6">
				<SingleLineInputComponent
					inputName={`mfaStatus${UserID}`}
					elementValue={mfaType > 0 ? 'MFA Enabled' : 'MFA Disabled'}
					inputTitle="MFA Status:"
					maxInputLength={null}
				/>
				<button onClick={disableMFAForUser} className="btn btn-primary btn-green pull-left">Disable MFA</button>
			</div>
			<div className="col-xs-12 col-md-6" />
        </CollapsiblePanelComponent>
    );
};


ProfileInfoComponent.propTypes = {
    profile: PropTypes.object.isRequired,
};


ProfileInfoComponent.defaultProps = {
    profile: {},
};


export default ProfileInfoComponent;
