import PropTypes from "prop-types";
import React from "react";
import "./OfferingsStyles.css";


class EarningsOfferingsHeader extends React.Component {
	constructor(props) {
		super(props);
		this.state ={
			active:'allprojects'
		}
	}
	handleChange = (selectedOption) => {
		this.props.onProjectTypeClickHandler(selectedOption);
		this.setState({active:selectedOption.value})
	};
	render() {
		const allProjects = [
			{
				value: "allprojects",
				label: "All Funds",
			},
			...this.props.totalProjects,
		];
	const {active} =this.state;
	// console.log("inearningsoffering table page is accredited and was accredited user",this.props.isAccreditedUser,this.props.wasAccreditedUser)
		return (
			<div>
				{(() => {
					// if (
					// 	!!this.props.isAccreditedUser ||
					// 	!!this.props.wasAccreditedUser
					// ) {
						return (
							<div className="px-0">
							<ul className="list-funds">
								<div className="lt-sec"><span>Your Funds</span>	</div>
								<div className="rt-sec">{allProjects.map((item) => (
										<li  className={active === item.value ? 'active' :''}
											key={item.value}
											onClick={() =>
												this.handleChange(item)
											}
										>
											{item.label}
										</li>
									))}</div>
								
								
								</ul>
							</div>
						);
					// }
				})()}
			</div>
		);
	}
}

EarningsOfferingsHeader.propTypes = {
	activeOfferingsChecked: PropTypes.bool.isRequired,
	onCheckboxClickedHandler: PropTypes.func.isRequired,
	onProjectTypeClickHandler: PropTypes.func.isRequired,
	pastOfferingsChecked: PropTypes.bool.isRequired,
	isAccreditedUser: PropTypes.bool.isRequired,
	selectedProjectType: PropTypes.string.isRequired,
	projectCategoryList: PropTypes.array.isRequired,
	projectCategoryOnClickHandler: PropTypes.func.isRequired,
	selectedProjectCategory: PropTypes.any.isRequired,
};

EarningsOfferingsHeader.defaultProps = {
	activeOfferingsChecked: true,
	onCheckboxClickedHandler: () => null,
	onProjectTypeClickHandler: () => null,
	pastOfferingsChecked: false,
	isAccreditedUser: false,
	selectedProjectType: PropTypes.string.isRequired,
	projectCategoryList: [],
	projectCategoryOnClickHandler: () => null,
	selectedProjectCategory: null,
};

export default EarningsOfferingsHeader;
