import React from 'react';
import PropTypes from 'prop-types'
import BaseComponent from "../utils/BaseComponent";

class IntInputComponent extends BaseComponent {
    onChange = (event) => {
        let {name, value} = event.target;
        value = parseFloat(value);

        this.props.onInputUpdate({
            target: {
                name,
                value,
            }
        })
    };

    render = () => {
        const {inputReference, inputName, inputTitle, inputSubtitle, elementValue, isDisabled} = this.props;

        return (
            <div className="form-group">
                <label htmlFor={inputName}
                       className="input-label">
                    {inputTitle}{this.getRequired()} <span style={{fontSize: '0.6em'}}>{inputSubtitle}</span>
                </label>
                <input id={inputName}
                       onChange={this.onChange}
                       className="form-control input"
                       ref={inputReference}
                       name={inputName}
                       type="number"
                       value={elementValue}
					   disabled={isDisabled}
				/>
                <span id={`error_${inputName}`} className="text-danger"/>
            </div>
        );
    };
}

IntInputComponent.propTypes = {
    elementValue: PropTypes.number.isRequired,
    inputName: PropTypes.string.isRequired,
    inputReference: PropTypes.any.isRequired,
    inputTitle: PropTypes.string.isRequired,
	inputSubtitle: PropTypes.string,
    onInputUpdate: PropTypes.func.isRequired,
	isRequired: PropTypes.bool,
	isDisabled: PropTypes.bool,
};

IntInputComponent.defaultProps = {
    elementValue: '',
    inputName: '',
    inputReference: () => null,
    inputTitle: '',
	inputSubtitle: '',
	onInputUpdate: () => null,
    isRequired: false,
	isDisabled: false,
};

export default IntInputComponent;
