import React,{Component} from 'react';
class EducationalPage extends Component{
    componentWillMount(){
        window.scrollTo(0, 0);
    }
    render(){
        return(
            <div className="container body">
                <div className="main_container">
                    <div className="right_col" role="main" style={{minHeight: 3753}}>
            <section className="">

                <div className="">
                    <ul id="basics" className="cd-faq-group">
                        <li className="cd-faq-title"><div className="vc_text_separator wpb_content_element full separator_align_center"><div><button type="button" class="btn btn-primary btn-block sizebut"><h1 style={{margin:0}}>FAQ for Investors</h1></button></div></div></li>
                        <li>
                            <a className="cd-faq-trigger" href="">WHAT TYPES OF INVESTMENT PROJECTS ARE OFFERED?</a>
                            <div className="cd-faq-content">
                                <iframe width={560} height={315} src="https://www.youtube.com/embed/Vhd4JbMjeWk" frameBorder={0} allowFullScreen />
                                <p>EnergyFunders focuses on proven fields, multi-well oil and gas drilling, reworking, or recompletion projects to be funded in whole or in part by investors.

                                    <br/><br/>Investors own interests in a project Limited Partnership (LP) set up to purchase wellbore working interest in specific well(s). The investor has the option to invest as a General Partner during drilling and completion OR can invest as a Limited Partner.

                                    <br/><br/>EnergyFunders’ sole compensation is a 10-20% carried interest in the project LP. Investors receive their pro-rata shares of the revenue from the well(s) less the carried interest owned by EnergyFunders.

                                    <br/><br/>You will never have to deal directly with oil and gas operators and your interest is professionally managed by a team of experienced Texas attorneys. EnergyFunders’ interest is aligned with yours and our reputation is tied directly to how well we protect investor interests.</p>
                            </div> {/* cd-faq-content */}
                        </li>
                        <li>
                            <a className="cd-faq-trigger" href="">WHAT IS AN ACCREDITED INVESTOR?</a>
                            <div className="cd-faq-content">
                                <p>Only Accredited Investors may participate in EnergyFunders projects at this time. Click here to determine if you are Accredited.</p>
                            </div> {/* cd-faq-content */}
                        </li>
                        <li>
                            <a className="cd-faq-trigger" href="">CAN NON-ACCREDITED INVESTORS PARTICIPATE?</a>
                            <div className="cd-faq-content">
                                <p>EnergyFunders has made the decision to hold off on entering this market for the funding of asset-based projects like direct oil and gas investing, until Title III is revamped in a way that allows platforms like ours to play a more active role in the investment.  We feel that the Title III structure is flawed as applied to direct oil and gas investments.  These are complex investments click here to read more.</p>
                            </div> {/* cd-faq-content */}
                        </li>
                        <li>
                            <a className="cd-faq-trigger" href="">WHAT TYPE OF OIL & GAS OPERATORS WOULD USE CROWDFUNDING?</a>
                            <div className="cd-faq-content">
                                <p>Any operator who is looking to spend more time on exploration and production and less time on fundraising would be well advised to try crowdfunding

                                    <br/>Raising capital for exploitation of oil and gas has historically been a time consuming task, with many projects not receiving funding in time.  EnergyFunders simplifies the fund raising process for Operators, by acting as an Investor Relations team

                                    <br/>Crowdfunding is already a multi-billion dollar industry. It makes sense for oil and gas operators to seek funding using this avenue.</p>
                            </div> {/* cd-faq-content */}
                        </li>
                        <li>
                            <a className="cd-faq-trigger" href="">IS INVESTOR MONEY BEING HELD IN A SAFE LOCATION?</a>
                            <div className="cd-faq-content">
                                <p>Yes. All individual project funds are held separately in a secure bank account with a major financial institution. Investors receive their money back if a project is not fully funded.</p>
                            </div> {/* cd-faq-content */}
                        </li>
                        <li>
                            <a className="cd-faq-trigger" href="">WHEN DOES ENERGYFUNDERS PAY THE OIL & GAS OPERATOR FOR DRILLING A WELL?</a>
                            <div className="cd-faq-content">
                                <p>Typically, funds are disbursed to the operator through a series of negotiated phases. Sometimes just prior to a well being drilled at a time determined in EnergyFunders contract with the operator.  Some agreements with operators will specify that “completion” funds will be paid only after the well is drilled to total depth and the well is determined to be commercially viable.  In those cases, un-used funds will be returned to investors.</p>
                            </div> {/* cd-faq-content */}
                        </li>
                        <li>
                            <a className="cd-faq-trigger" href="">WHAT DO I OWN WHEN I INVEST IN A WELL?</a>
                            <div className="cd-faq-content">
                                <p>Prior to investing, you will electronically sign a contract giving you ownership in the specific project limited partnership (LP), set up to purchase working interest in the well(s).  You will receive revenue generated from the project at your pro-rata share of the distributions in the LP.
                                    Your right to receive revenue is non-transferable except in the event of death or court order (e.g., inheritance or divorce).<br/> However, EnergyFunders may direct the the LP to sell its assets in an arm’s length transaction on behalf of investors when EnergyFunders determines it makes economic sense to sell. EnergyFunders generally anticipates holding wells for a 5-year period; however, this is decided on a case-by-case basis.
                                    Payment to investors will be made on a quarterly basis.</p>
                            </div> {/* cd-faq-content */}
                        </li>
                        <li>
                            <a className="cd-faq-trigger" href="">DOES ENERGYFUNDERS CHARGE TRANSACTION FEES?</a>
                            <div className="cd-faq-content">
                                <p>No. However, you may elect to pay via an electronic method that charges a fee or you may pay via wire transfer. Payment via mailing a check is also acceptable.  Bitcoin payments are covered in a separate portion of this FAQ.
                                    EnergyFunders will never receive fees from investors for putting together an investment opportunity. We are right there with you and only profit when a well is successful.</p>
                            </div> {/* cd-faq-content */}
                        </li>
                        <li>
                            <a className="cd-faq-trigger" href="">HOW IS THE INVESTOR PURCHASE PRICE FOR A PROJECT DETERMINED?</a>
                            <div className="cd-faq-content">
                                <p>The oil and gas operator proposes a purchase price in exchange for a certain working interest percentage (ownership percentage) in a well.
                                    <br/>The project cost will be clearly disclosed in investor materials, and EnergyFunders will have negotiated to keep the purchase price as closely aligned to drilling and completion costs as possible. However, it is important to remember that large expenses have likely been incurred to get a deal in place and ready to go. Geological, technical, and legal expertise are necessary to get a well permitted and ready to go.
                                    It is not uncommon for investors—including major oil companies—to pay a premium for access to more lucrative projects. Operators on the site must compete with each other for investor dollars.</p>
                            </div> {/* cd-faq-content */}
                        </li>
                        <li>
                            <a className="cd-faq-trigger" href="">WHAT TYPE OF RISK IS INVOLVED IN DRILLING AN OIL AND GAS WELL?</a>
                            <div className="cd-faq-content">
                                <p>Investing in oil and gas wells is a high risk / high reward investment category. You must be prepared to lose your entire investment in any given project. That is the reality. It illustrates the importance of diversifying your investment dollars across different wells and different operators and keeping oil and gas investments at a percentage of your investing portfolio in which you are comfortable.
                                    <br/>The risk of a dry hole is arguably the highest risk in oil and gas investing. Geology involves theorizing about what happened millennia ago thousands of feet below the earth’s surface. Drilling in mature fields (infill drilling) that have proven production can lower the overall risk of a dry hole, along with investing in multiple well packages. EnergyFunders is focused on finding drilling and recompletion projects in proven areas with more than one well.
                                    <br/>Oil and gas prices are volatile and present another risk. Prices could fall to a level where an investment is not profitable and an operator is forced to shut in the well.  Reworking and recompletion projects can have lower lifting cost, which allows a project to produce at a profitable level even when prices fall.</p>
                            </div> {/* cd-faq-content */}
                        </li>
                        <li>
                            <a className="cd-faq-trigger" href="">WHAT ARE THE BUY-INS FOR A PROJECT?</a>
                            <div className="cd-faq-content">
                                <p>The “buy-in” is the lowest amount for which an investor may participate in a project. These will be determined on a case-by-case basis. Most of our current projects we have a $5,000 minimum buy-in.</p>
                            </div> {/* cd-faq-content */}
                        </li>
                        <li>
                            <a className="cd-faq-trigger" href="">CAN I CONTACT AN OPERATOR WITH MY QUESTIONS ABOUT A PROJECT?</a>
                            <div className="cd-faq-content">
                                <p>No. You are free at any time to contact EnergyFunders at info@EnergyFunders.com or via the blue bar or button at the bottom of the page.
                                    <br/>One of the advantages of EnergyFunders is that operators are free from the task of investor relations with numerous investors. Additionally, the outlook for each project must remain as presented in the vetted due diligence package for Verified Investors.
                                    <br/>Directly contacting an oil and gas operator may result in loss of site privileges and loss of access to exciting projects not yet available.</p>
                            </div> {/* cd-faq-content */}
                        </li>
                        <li>
                            <a className="cd-faq-trigger" href="">WHAT ARE THE TAX ADVANTAGES FOR OIL AND GAS INVESTING?</a>
                            <div className="cd-faq-content">
                                <p>One of the biggest differentiators of Oil and Gas Investing compared with other Asset classes is the tax incentives.  EnergyFunders provides investors with the option to take incredible tax deductions unique to oil & gas.  Investors have the option to offset 100% of the investment over 7 years.  Read more here /tax-advantages-oil-gas-investing/.
                                    We always suggest that you consult your tax or financial professional for tax advice, as they will be familiar with your personal tax situation which may affect your ability to take advantage of these tax benefits and incentives.
                                    <br/>For more information, we believe that this Investopedia article is helpful: http://www.investopedia.com/articles/07/oil-tax-break.asp.
                                </p>
                            </div> {/* cd-faq-content */}
                        </li>
                        <li>
                            <a className="cd-faq-trigger" href="">UNDER WHICH LEGAL FRAMEWORK DOES ENERGYFUNDERS OPERATE?</a>
                            <div className="cd-faq-content">
                                <p>The EnergyFunders platform is enabled under regulatory changes and legislation, including the JOBS Act of 2012. Only accredited investors are able to participate in EnergyFunders projects.
                                    <br/>EnergyFunders has carefully examined letters issued by the SEC regarding crowdfunding guidelines and has consulted with the lawyers at the SEC Division of Trading and Markets to narrowly tailor its activities to be in compliance with securities laws and regulations, and additionally relies on its own securities counsel for compliance matters.</p>
                            </div> {/* cd-faq-content */}
                        </li>
                        <li>
                            <a className="cd-faq-trigger" href="">CAN I INVEST USING BITCOIN?</a>
                            <div className="cd-faq-content">
                                <p>YES. For existing Bitcoin owners, we strive to make Bitcoin payment convenient and straightforward. But, you need to be aware of the major caveats involved.
                                    <br/>All investment deposits made via Bitcoin will be converted to U.S. dollars.
                                    <br/>EnergyFunders does not endorse the use of Bitcoin, which has unique risks and tax consequences associated with it, and the user should be aware of these risks and tax consequences.
                                    <br/>EnergyFunders cannot guarantee Bitcoin valuation and assumes no liability for fluctuation in value prior to conversion to U.S. dollars.
                                    <br/>EnergyFunders cannot be liable for the security of third party Bitcoin payment or conversion platforms.
                                    <br/>The user is responsible for any and all third-party fees related to payment via Bitcoin.
                                    Revenue, if any, paid to the investor, will be paid in U.S. dollars, not Bitcoin.</p>
                            </div> {/* cd-faq-content */}
                        </li>
                        <li>
                            <a className="cd-faq-trigger" href="">HOW DO I BECOME AN ENERGYFUNDERS ACCREDITED INVESTOR?</a>
                            <div className="cd-faq-content">
                                <p>Simply sign up and follow the instructions. We have made the process as simple as possible. Unfortunately, non-accredited investors may not participate in projects at this time.
                                </p>
                            </div> {/* cd-faq-content */}
                        </li>

                    </ul> {/* cd-faq-group */}
                    <ul id="mobile" className="cd-faq-group">
                        <li className="cd-faq-title"><div className="vc_text_separator wpb_content_element full separator_align_center"><div><button type="button" class="btn btn-primary btn-block sizebut"><h1 style={{margin:0}}>FAQ for Operators</h1></button></div></div> </li>
                        <li>
                            <a className="cd-faq-trigger" href="">WHAT PROJECTS ARE YOU LOOKING FOR?</a>
                            <div className="cd-faq-content">
                                <p>EnergyFunders is interested in funding multi-well drilling, re-completion, and re-work projects in proven areas all over the country.
                                    <br/>We use equity crowdfunding to raise the money to purchase a wellbore-only working interest block to be managed on behalf of investors.  EnergyFunders is not looking to invest in leasehold outside the wellbore, nor ownership in your company or a place on the board of directors, just wellbore only working interest in successful wells.
                                    <br/>Drilling projects can include re-working or vertical fracturing of existing wells.  Capital is available for multi-well continuous drilling projects in one area, “turn-key” is preferred.</p>
                            </div> {/* cd-faq-content */}
                        </li>
                        <li>
                            <a className="cd-faq-trigger" href="">WHEN SHOULD AN OPERATOR REQUEST A SINGLE WELL VS. MULTI-WELL PROJECT?</a>
                            <div className="cd-faq-content">
                                <p>Multi-well projects are favorable as they have the ability to spread risk. In some circumstances, we will accept single well projects.</p>
                            </div> {/* cd-faq-content */}
                        </li>
                        <li>
                            <a className="cd-faq-trigger" href="">CAN ONE OPERATOR HAVE MULTIPLE ACCOUNTS?</a>
                            <div className="cd-faq-content">
                                <p>EnergyFunders recognizes that sometimes multiple individuals at a company have the decision-making ability to drill wells in different assets or even the same asset. Multiple accounts are welcome in those circumstances.
                                    <br/>However, only the account used to create a project may be used to work on that project on an ongoing basis.</p>
                            </div> {/* cd-faq-content */}
                        </li>
                        <li>
                            <a className="cd-faq-trigger" href="">WHAT COST SHOULD I EXPECT?</a>
                            <div className="cd-faq-content">
                                <p>It’s free to create an account and submit a project for initial review.  Assuming the project moves forward a Memorandum of Understanding (MOU) will be sent.  The MOU list the terms of the offer, including a non-refundable application fee in order to engage a third party engineering review.
                                    <br/>Upon receipt of a signed MOU, an application fee is due. This fee will cover the cost of a thorough review including background checks, credit checks, trade references, and banking references which will be conducted along with an engineering review of the project in order to determine whether to proceed with the project.
                                    <br/>Upon approval, a Purchase Sale Agreement (PSA) will be presented to the operator in order to move forward to funding. <br/> At the time of signing, a $7,000 partially refundable deposit is required.  This fee is refundable less third party cost if the project doesn’t fund on the platform.
                                    <br/>We require an exclusive time frame to raise funds following the execution of the PSA.</p>
                            </div> {/* cd-faq-content */}
                        </li>
                        <li>
                            <a className="cd-faq-trigger" href="">WHAT IS THE TIME FRAME YOU CAN FINANCE MY PROJECT?</a>
                            <div className="cd-faq-content">
                                <p>This largely depends on how quickly the right information is submitted and the initial review process can begin.  However, most projects take 45 days to complete the diligence phase and less than 30 days to fund on the platform.</p>
                            </div> {/* cd-faq-content */}
                        </li>
                        <li>
                            <a className="cd-faq-trigger" href="">WHAT ARE THE ADVANTAGES OF CROWDFUNDING MY PROJECT VS. TRADITIONAL FINANCING?</a>
                            <div className="cd-faq-content">
                                <p>If you’ve raised money from investors before, you know there are quite few things you’d need to do on your own to legally and effectively raise capital:
                                    <br/>–  Determine, draft and file the appropriate regulatory filings at the federal and state level
                                    <br/>–  Determine and comply with federal and individual states’ legal requirements
                                    <br/>–  Write and generate marketing literature
                                    <br/>–  Obtain and pay for legal services to draft the subscription agreement, management agreement and other required contracts
                                    <br/>–  Pay a legal services firm or communication firm to generate a legally-compliant private disclosure memorandum
                                    <br/>–  Possibly pay a promoter or broker-dealer to assist you with fundraising efforts
                                    <br/>–  Receive and process payments
                                    <br/>–  Meet with individual investors, perhaps several times with each, and travel constantly to do so
                                    <br/>–  Receive and process payments
                                    <br/>–  After you fund the project, maintain ongoing communications with each investor
                                    <br/>–  After you fund the project, calculate the amount owed to each investor and timely pay each investor
                                    <br/>How much would you pay a promoter/full time business development employee to do this for you:
                                    <br/>–  Promote your project
                                    <br/>–  Gather together groups of investors you’ve never met before
                                    <br/>–  Travel across the country to find investors
                                    <br/>–  Host online communication events with potentially interested investors
                                    <br/>–  Build a relationship with each individual investor so they decide to invest in your project
                                    <br/>How much would you pay a full time Investor Relations person to do this for you:
                                    <br/>–  Communicate with each potentially interested investor
                                    <br/>–  Generate marketing literature advertising your project
                                    <br/>–  Communicate with each investor after they’ve invested on an ongoing basis for years
                                    <br/>–  Answer investor questions and comments
                                    <br/>How much would you pay a communications company or legal services firm to do this for you:
                                    <br/>–  Write a private disclosure memorandum
                                    <br/>–  Determine and make mandatory state filings
                                    <br/>–  Form a funding entity
                                    <br/>–  Draft the correct company and management documents for the fund
                                    <br/>–  Draft the appropriate subscription agreement for the fund
                                    <br/>–  File a Form D under Rule 506 of the Securities Act of 1933
                                    <br/>–  Manage the fund and investor relationships on an ongoing basis, likely for years
                                    <br/>EnergyFunders manages all of this on behalf of our venture capital funds instead of you doing this for your investors.</p>
                            </div> {/* cd-faq-content */}
                        </li>

                        <li>
                            <a className="cd-faq-trigger" href="" >WHAT IS THE STRUCTURE OF THE DEAL?</a>
                            <div className="cd-faq-content">
                                <p>An EnergyFunders venture capital fund and the operator sign a wellbore-only Purchase and Sale Agreement contingent on raising funds in the specified time frame (30, 60, 90 or 120 days).
                                    <br/>If the funds are raised, the well is drilled. If not, no further obligations are owed.
                                    <br/>The fund’s assignment for working interest is due after payment and after the well is completed as a producer. No assignment is due if the well is P&A as a non-producer.
                                    <br/>Operators must “turn-key” the project, meaning operators must cover up-front future expenses that don’t trigger a non-consent penalty under the JOA.</p>
                            </div> {/* cd-faq-content */}
                        </li>

                        <li>
                            <a className="cd-faq-trigger" href="">WHEN DO I GET PAYMENT?</a>
                            <div className="cd-faq-content">
                                <p>EnergyFunders will work with you to determine a staged funding process that works with your project AFE.  Once all money has been collected, the LP will release the agreed to amount to begin work.  There is very little red tape in order to receive the additional money.</p>
                            </div> {/* cd-faq-content */}
                        </li>

                        <li>
                            <a className="cd-faq-trigger" href="">DO I HAVE TO DEAL WITH LOTS OF WORKING INTEREST OWNERS?</a>
                            <div className="cd-faq-content">
                                <p>No. You will only ever be required to deal with one working interest owner: EnergyFunders.
                                    <br/>Once we diligence your project, we strive to be a no-hassle business partner.
                                    <br/>EnergyFunders will buy your working interest as a block and manage it for the investors. You only deal with one working interest partner on a wellbore only basis.
                                    <br/>Investors may not contact you without permission. Doing so may result in loss of site privileges.</p>
                            </div> {/* cd-faq-content */}
                        </li>
                        <li>
                            <a className="cd-faq-trigger" href="">HOW DO I SET A PURCHASE PRICE?</a>
                            <div className="cd-faq-content">
                                <p>Make us a fair offer in which the purchase price aligns as closely as possible with the drilling cost for the working interest you wish to sell.
                                    <br/>We do understand that work and costs go into putting together a well that is ready to go. You will have incentive to offer competitive terms due to competition for investor dollars on the website, just like in a traditional capital raise.
                                    <br/>When appropriate, please allow for a percentage of the cost to be paid at completion only after a well is determined to be a commercial producer. Investors will be refunded any money left over for wells not completed.</p>
                            </div> {/* cd-faq-content */}
                        </li>
                        <li>
                            <a className="cd-faq-trigger" href="">WHAT WILL BE REQUESTED IN THE DUE DILIGENCE PACKAGE AND WHAT IS THE FORM OF THE PURCHASE AND SALE AGREEMENT?</a>
                            <div className="cd-faq-content">
                                <p>Our agreement with an operator is typically in the following format:  Simply register as an Operator and when you are approved, you will be able to upload your project on EnergyFunders’ proprietary project upload platform.
                                    Generally, the due diligence packet requires a proposal like you would submit to any potential non-operating working interest partner. It will also require land documents like title opinions, leases and permits.
                                    You will need a qualified petroleum landman or oil and gas attorney to prepare the land due diligence materials and complete the due diligence checklist.
                                    Here is a list we request:
                                    <ol type={1} class="orderlt">
                                        <li>Drilling or re-working plan clearly written.An honest assessment of risk.  Drilling locations need to include standard legal descriptions.</li>
                                        <li>Revenue projections in a easy to read format, accounting for different production scenarios and taxes.  Remember, the plan has to be profitable in a low price environment.</li>
                                        <li>AFE (Drilling Budget)</li>
                                        <li>Lease(s).</li>
                                        <li>Title Work / Title Opinion(s).  Preference goes to title opinions which are typically required.  Drilling Units, if applicable</li>
                                        <li>Permits (we understand if you don’t have them prior to proposing the project)</li></ol></p>
                            </div> {/* cd-faq-content */}
                        </li>
                        <li>
                            <a className="cd-faq-trigger" href="">WHO WILL SEE MY PROPOSED PROJECT?</a>
                            <div className="cd-faq-content">
                                <p>Prior to approval and execution of the Purchase and Sale Agreement (PSA), only EnergyFunders and its affiliates will have access to your proposal.
                                    <br/>After the PSA is executed, the project will go live on the website to seek investor funding. only Verified Investors will able to see the details and the due diligence materials.
                                    <br/>You should assume that other oil and gas operators will be viewing your project. If your project needs to be kept secret, equity crowdfunding may not be a viable fund-raising option.</p>
                            </div> {/* cd-faq-content */}
                        </li>
                        <li>
                            <a className="cd-faq-trigger" href="">
                                HOW DO I GET STARTED?</a>
                            <div className="cd-faq-content">
                                <p>Simply sign-up as an Operator.
                                    <br/>Once you are approved, you may submit projects for review on the proprietary project upload platform.
                                    <br/>Please feel free to contact EnergyFunders with any questions at info@EnergyFunders.com or contact us using the blue bar or button at the bottom of the window.</p>
                            </div> {/* cd-faq-content */}
                        </li>


                        {/* cd-faq-group */}

                    </ul> {/* cd-faq-group */}
                </div> {/* cd-faq-items */}
                <a href="" className="cd-close-panel">Close</a>
            </section>
                    </div>
                </div>
            </div>
        )
    }
}
export default EducationalPage;
