import React, {Component} from 'react';
import FooterCopyright from './FooterCopyright/FooterCopyright';
import RequestService from '../../../../api/RequestService';
import { getImpersonateID  } from "../../../../utils/General";


class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {
			IsAccredited: 0
        };
    }



    async componentDidMount() {
        await this.checkAccreditation();
    }



    async checkAccreditation() {
        const impersonateID = await getImpersonateID();
        const { data } = await RequestService.get(`/api/checkAccreditation/${impersonateID}`);

        if (data.code === 200) {
			this.setState({
				IsAccredited: data.AccreditationStatus,
				WasAccredited: data.PriorAccredStatus
			});
        }
        else {
            // console.log(data);
        }
    }



    renderAccreditedFooter() {
        return (
            <React.Fragment>
                
         <FooterCopyright IsAccredited={this.state.IsAccredited || this.state.WasAccredited} />
            </React.Fragment>
        );
    };



    renderNonAccreditedFooter() {
        return (
            <React.Fragment>
               <FooterCopyright IsAccredited={this.state.IsAccredited || this.state.WasAccredited} />
            </React.Fragment>
        );
    };



    render() {
        return (
            <footer className="app-main--footer" style={{margin: 0}}>
				{this.state.IsAccredited > 0 ? this.renderAccreditedFooter() : this.renderNonAccreditedFooter()}
            </footer>
        );
    }
}


export default Footer;
