import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../../../shared/IconComponent/IconComponent';

class FileSelectionViewer extends React.Component {
    renderPreviewElement = () => {
        const { selectedFileList, onDeleteSelectedFile } = this.props;

        return selectedFileList.map((item, position) => (
            <tr key={item.key}>
                <td>{position + 1}</td>
                <td>{item.name}</td>
                <td>{item.type}</td>
                <td>
                    <button
                        className="btn btn-danger btn-sm"
                        onClick={() => onDeleteSelectedFile(position)}
                        type="button"
                    >
                        <Icon iconName="trash" extraClasses="white-text" />
                        {' '}
                        Delete
                    </button>
                </td>
            </tr>
        ));
    };

    render = () => (
        <fieldset className="col-xs-12">
            <legend>Selected Files Preview</legend>
            <div className="table-responsive">
                <table className="table table-hover table-striped">
                    <thead>
                        <tr>
                            <th />
                            <th>File Name</th>
                            <th>File Type</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody className="upper-case-text">
                        {this.renderPreviewElement()}
                    </tbody>
                </table>
            </div>
        </fieldset>
    );
}

FileSelectionViewer.propTypes = {
    selectedFileList: PropTypes.array.isRequired,
    onDeleteSelectedFile: PropTypes.func.isRequired,
};

FileSelectionViewer.defaultProps = {
    selectedFileList: [],
    onDeleteSelectedFile: () => null,
};

export default FileSelectionViewer;
