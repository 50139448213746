import PropTypes from 'prop-types'
import React from "react";
import {getUUID} from "../../../../utils/General";
import BaseComponent from "../utils/BaseComponent";

class CheckboxInputComponent extends BaseComponent {
    render() {
        const {checkId, checkTitle, name, onClick, checked, labelStyle, divStyle} = this.props;
        const _checkId = checkId ? checkId : getUUID();

        const _onClick = (event) => {
            const {name, checked} = event.target;
            onClick({
                target: {
                    name,
                    value: checked
                }
            });
        };

        return (
            <div className="form-group" style={divStyle}>
                <div className="checkbox" style={{padding: 0}}>
                    <input id={_checkId}
                           name={name}
                           type="checkbox"
                           checked={checked}
                           onChange={_onClick}
                           className="offerings-header-checkbox"
                    />
                    <label htmlFor={_checkId} className="no-padding fontSize14" style={labelStyle}>
                        {checkTitle}{this.getRequired()}
                    </label>
                </div>
            </div>
        );
    };
}

CheckboxInputComponent.propTypes = {
    checkId: PropTypes.string,
    checkTitle: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    isRequired: PropTypes.bool,
};

CheckboxInputComponent.defaultProps = {
    checkId: null,
    checkTitle: '',
    checked: false,
    name: '',
    onClick: () => null,
    isRequired: false,
};

export default CheckboxInputComponent;
