import React, { Component } from "react";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as AllInvestmentActions from '../shared/AllInvestmentsContainerComponent/actions/actions';

import {bindActionCreators} from 'redux';
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import "./css/investmentOpportunities.css";
import { Button, Modal } from "react-bootstrap";
import { getImpersonateID } from "../../utils/General";
import RequestService from "../../api/RequestService";
import { LP_Project_ID,GP_Project_ID } from '../../utils/General';
import LPVotingModal from "./LPVotingModal";
import GPVotingModal from "./GPVotingModal";
import sweetAlert from "sweetalert2";
class GPLPVotings extends Component {
	constructor(props) {
		super(props);
		this.state = {
		showLPModal :false,
		showGPModal:false,
		isLPInvested:false,
		isGPInvested:false,
		isLPAgree:'',
		isGPAgree:'',
		allProjectsD:[],
		GPLPVotings:[],
		submitDisabled:false,
		};
	}

	componentDidMount() {
	this.getMyInvestments();

	}

    async getMyInvestments() {
        const impersonateID = await getImpersonateID();
		const { data } = await RequestService.get(`/api/myInvestments/${impersonateID}`);
		if (data.code === 200) {
			this.checkLPInvestments(data.reg_d)
           this.setState({
				allProjectsD: data.reg_d,
			
			});
			
		}
	}
	 checkLPInvestments =async (data) =>{
	
		let isLPInvested;
	let showLPModal=false;
		let LPVotings =[];
		
	
		if(data){
		isLPInvested = data?.some((item) => item.ProjectID === LP_Project_ID);
	

			if(isLPInvested){
				LPVotings =	await this.getMergeGPLPVotings();
			 let   LPAgreeStatus = LPVotings.length > 0 ? LPVotings?.filter(item => item.ProjectID_RegD === LP_Project_ID) :[];
			 if(LPAgreeStatus.length > 0){
				let isVoted = LPAgreeStatus?.find(item => item?.response == 'Yes' || item?.response == 'No' || item?.response == 'Postpone the vote and conduct an online meeting');
				if(isVoted)  this.checkGPInvestments(data);
				showLPModal =isVoted ? false  :true;
			}
			else  showLPModal =true;

			this.setState({isLPInvested:isLPInvested,showLPModal:showLPModal,GPLPVotings:data});
			}
			else this.checkGPInvestments(data)

	
	}
	
	}

	checkGPInvestments =async (data) =>{

		let isGPInvested;
		let showGPModal=false;
		let GPVotings =[];
		
	if(data){
	
		isGPInvested = data?.some((item) => item.ProjectID === GP_Project_ID);

			if(isGPInvested){
			 GPVotings =	await this.getMergeGPLPVotings();
			 let   GPAgreeStatus = GPVotings.length > 0 ? GPVotings?.filter(item => item.ProjectID_RegD === GP_Project_ID) :[];
			 if(GPAgreeStatus.length > 0){
				showGPModal =GPAgreeStatus?.find(item => item?.response == 'Yes' || item?.response == 'No' || item?.response == 'Postpone the vote and conduct an online meeting' ) ? false  : true;
			}
			else  showGPModal =true
			}


		this.setState({isGPInvested:isGPInvested,showGPModal:showGPModal});
	}
	
	}


	 handleGPLPVotings = (vote,type) => {
	
		 if(type==="lp"){
			let isLPAgree = vote;
			this.setState({isLPAgree:isLPAgree});
		 }
		 if(type==="gp"){
			let isGPAgree = vote;
			this.setState({isGPAgree:isGPAgree});
		 }

	 }

	 getMergeGPLPVotings = async()=>{
		const impersonateID = await getImpersonateID();
		const {data } = await RequestService.get({
			url: `/api/getMergeGPLPVotings/${impersonateID}`,
		});
	   if(data.code ===200){
	

		return data.result;
		}
	 }

	 submitVotingsForm = async(e,type) =>{
		let ret =true;
		 e.preventDefault();
     	  const {isLPAgree,isGPAgree,GPLPVotings} =this.state;
	
  		let projectID;
 		let agree;
 
        if(type === 'lp'){
			projectID =LP_Project_ID;
			agree =isLPAgree;

			if(isLPAgree===""){
				ret =false
			}
		}
		if(type === 'gp'){
			projectID =GP_Project_ID;
			agree =isGPAgree;
			if(isGPAgree===""){
				ret =false
			}
		}
	
         
		if(ret){
			this.setState({submitDisabled: true});
				let formBody = [];
				formBody.push("response=" + encodeURIComponent(agree));
				formBody.push("projectID_RegD=" + encodeURIComponent(projectID));
			formBody = formBody.join("&");
			const impersonateID = await getImpersonateID();
				const { data } =  await RequestService.post({
					url: `/api/createMergeGPLPVotings/${impersonateID}`,
					data: formBody
				});
			
				if (data.code === 200) {
				
					this.setState({showLPModal:false,showGPModal:false});
					this.props.getVoteResponse();
					sweetAlert("Thank you. Your vote has been recorded", "", "success").then(() =>{
						if(Number(data.projectID_RegD) ===LP_Project_ID) this.checkGPInvestments(GPLPVotings);

					});  
				
				}
				this.setState({submitDisabled: false});
			}
        
      
        }

					showLPModalHandler =()=>{

							this.setState({ showLPModal: false })
						}

					showGPModalHandler =()=>{

						this.setState({ showGPModal: false })
					}

	render() {
	
		const {showLPModal,showGPModal, submitDisabled} =this.state;
	
		return (
			
			<>
			<LPVotingModal showLPModal = {showLPModal} submitDisabled={submitDisabled} handleGPLPVotings ={this.handleGPLPVotings} submitVotingsForm ={this.submitVotingsForm} showLPModalHandler={this.showLPModalHandler} />
		   <GPVotingModal showGPModal = {showGPModal} submitDisabled={submitDisabled} handleGPLPVotings ={this.handleGPLPVotings} submitVotingsForm ={this.submitVotingsForm} showGPModalHandler={this.showGPModalHandler} />

			</>
		);
	}
}

const { object, bool } = PropTypes;

GPLPVotings.propTypes = {
	session: object.isRequired,
	allInvestments:object.isRequired,
};
const mapDispatchToProps = (dispatch) => {
    const allInvestmentActions = bindActionCreators(AllInvestmentActions, dispatch);
    return {...allInvestmentActions};
};
const mapStateToProps = (_state) => ({
	session: _state.session.user,
	allInvestments:_state.allInvestments,
});



const connectedComponent = connect(
    mapStateToProps,
    mapDispatchToProps,
)(GPLPVotings);

export default withRouter(connectedComponent);


