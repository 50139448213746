import React, { Component } from 'react';
import swal from 'sweetalert2';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';


class View_regcf_education extends Component {
	constructor(props) {
		super(props);

		this.state = {
			id: props.session.id,
			q1: '',
			q2: '',
			q3: '',
			q4: '',
			q5: '',
			q6: '',
			q7: '',
			q8: '',
			q9: '',
			q10: '',
			error1: '',
			error2: '',
			error3: '',
			error4: '',
			error5: '',
			error6: '',
			error7: '',
			error8: '',
			error9: '',
			error10: '',
		};

		this.quiz = this.quiz.bind(this);
		this.questionError = this.questionError.bind(this);
  	}


	componentDidMount() {
		window.scrollTo(0, 0);
	}


	quiz(e)
	{
		e.preventDefault();

		if (document.getElementById('iack').checked) {
			if (this.state.q1 && this.state.q2 && this.state.q3 && this.state.q4 && this.state.q5 &&
				this.state.q6 && this.state.q7 && this.state.q8 && this.state.q9 && this.state.q10)
			{
				if (this.state.q1 === 'no' || this.state.q2 === 'no' || this.state.q3 === 'no' || this.state.q4 === 'no' || this.state.q5 === 'no'||
					this.state.q6 === 'no' || this.state.q7 === 'no' || this.state.q8 === 'no' || this.state.q9 === 'no' || this.state.q10 === 'no')
				{
					if (this.state.q1 === 'no')
					{
						this.setState({
							error1: 1
						});
					}
					if (this.state.q2 === 'no')
					{
						this.setState({
							error2: 1
						});
					}
					if (this.state.q3 === 'no')
					{
						this.setState({
							error3: 1
						});
					}
					if (this.state.q4 === 'no')
					{
						this.setState({
							error4: 1
						});
					}
					if (this.state.q5 === 'no')
					{
						this.setState({
							error5: 1
						});
					}
					if (this.state.q6 === 'no')
					{
						this.setState({
							error6: 1
						});
					}
					if (this.state.q7 === 'no')
					{
						this.setState({
							error7: 1
						});
					}
					if (this.state.q8 === 'no')
					{
						this.setState({
							error8: 1
						});
					}
					if (this.state.q9 === 'no')
					{
						this.setState({
							error9: 1
						});
					}
					if (this.state.q10 === 'no')
					{
						this.setState({
							error10: 1
						});
					}
				}
				else {
					fetch(`/api/finishEduPage/${this.state.id}/${this.props.session.marketing}`, {
						method:'post',
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/x-www-form-urlencoded'
						}
					})
						.then((response)=>response.json())
						.then((status)=>{
							if(status.code === 200) {
								swal("Quiz Submitted", "", "success")
									.then(()=>{
										this.props.history.push("/app/Allinvestments")
									})
							}
							else if(status.code === 400) {
								swal("Error","","error")
								// window.location = '/Setupprofile2';
							}
						})
						.catch((err)=>{
							alert("Error occured: " + JSON.stringify(err))
						})
				}
			}
			else {
				swal("All Answers Must be Submited", "", "info")
			}
		}
		else {
			swal("Please acknowledge the terms", "", "info")
		}
	}


	questionError(q, ans) {
		this.setState({
			[`q${q}`]: ans,
			[`error${q}`]: 0
		});
	}



  	render() {
		return (
			<div className="container body">
				<div className="main_container">
					{/* page content */}
					<div className="right_col" role="main">
						<section id className="l-section" style={{color:"Black"}}>
							<div className="l-subsection">
								<div className="l-subsection-h">
									<div className="l-subsection-hh g-html i-cf" style={{marginTop: 40}}>
										<div className="ef_form">
											<div style={{marginBottom: 45}}>
												<div className="l-subsection-hh g-html">
													<div style={{alignContent: 'center', fontSize: 30, fontWeight: 400}}>Welcome to EnergyFunders' Investor Education Page!</div><br />
													<div style={{textAlign: 'left', fontSize: 24, fontWeight: 100}}>Please review the education below and acknowledge you have completed the educational materials at the bottom of the page.</div><br />
													<div style={{textAlign: 'center', margin: '0 auto', maxWidth: 640}}>
														<div className="wistia_responsive_padding" style={{padding: '56.25% 0 0 0', position: 'relative',border: '1px solid black'}}><div className="wistia_responsive_wrapper" style={{height: '100%', left: 0, position: 'absolute', top: 0, width: '100%'}}><iframe src="https://fast.wistia.net/embed/iframe/jzkvbls3eo?seo=false&videoFoam=true" title="Wistia video player" allowTransparency="true" frameBorder={0} scrolling="no" className="wistia_embed" name="wistia_embed" allowFullScreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen width="100%" height="100%" /></div></div>
														<br />
														<div style={{textAlign: 'center', margin: '0 auto'}}><a href="#quiz" ><button id="gotoquiz" type="button" className="g-btn type_secondary" style={{float: 'none'}} onclick="sctop('html, body').animate({scrollTop: sctop('#quiz').offset().top - 100}, 500);"><span>Skip Down To Quiz</span></button></a></div>
													</div>
													<br /><br style={{clear: 'both'}} />
													<div style={{textAlign: 'left'}}>
														<h2>Welcome to EnergyFunders Marketplace!</h2>
														<p>Take a look around. Whether you’re a serious energy investor or just browsing, there are a variety of different investment opportunities to explore.</p>
														<p>While you’re at it, there are <a href="https://www.energyfunders.com/education_topics/information-for-potential-investors/" target="_blank">some important things you should know</a> about investing online through Regulation Crowdfunding. For instance, all investments carry risk, and investing in startup companies in the Marketplace is more risky than investing in publicly traded stocks or bonds.</p>
														<p>We’re not investment advisors and we don’t provide advice on investments. This is entirely your business and your responsibility. It’s your chance to do your own due diligence. To these ends, we have a wealth of educational materials that can be accessed through the <a href="https://www.energyfunders.com/education" target="_blank">Education Center</a>.</p>
														<p>Also, by law, you’re required to confirm that you have received <a href="https://www.energyfunders.com/education_topics/information-for-potential-investors/" target="_blank">some important educational materials</a> before you make your first investment. That comes later, just before you make your first investment.  You'll be guided through the process.</p>
														<p>Let us know if we can help in any way and enjoy your direct access to energy investment opportunities.</p>

														<br /><br />
														<span style={{fontSize: '1.2em', fontWeight: 'bold'}}>We also ask that you thoroughly read through our <a href="https://www.energyfunders.com/education-investors/" target="_blank">Investor Education</a> page prior to taking this quiz.<br/><br/>We know you'd like to get started. To comply with Crowdfunding Regulations, please complete this short quiz. Thank you!</span>
														<br /><br />
													</div>
													<div id="quiz">
														<div style={{alignContent: 'center', fontSize: 30, fontWeight: 400, color: '#999999'}}>Take The Quiz!</div>
														<br /><br />
														<div style={{textAlign: 'left'}}>

															1. Do you understand that most startups fail?
															<br />

															<label><input type="radio"  name="q1" id="q1y"  onChange={()=>this.questionError(1, 'yes')} /> Yes</label><br />
															<label><input type="radio" name="q1" id="q1n"  onChange={()=>this.questionError(1, 'no')} /> No</label><br />
															{this.state.error1 ? <span id="error_nwells" className="text-danger" >Answer is Yes</span>:null}<br />

															2. Do you understand that you may never see a return on your money?
															<br />
															<label><input type="radio" name="q2" id="q2y" defaultValue={1} onChange={()=>this.questionError(2, 'yes')} /> Yes</label><br />
															<label><input type="radio" name="q2" id="q2n" defaultValue={2} onChange={()=>this.questionError(2, 'no')}/> No</label><br />
															{this.state.error2 ? <span id="error_nwells" className="text-danger" >Answer is Yes</span>:null}<br />
															3. Do you understand that if you do see a return, it may come years later and be less than you put in?
															<br />
															<label><input type="radio" name="q3" id="q3y" defaultValue={1} onChange={()=>this.questionError(3, 'yes')} /> Yes</label><br />
															<label><input type="radio" name="q3" id="q3n" defaultValue={2} onChange={()=>this.questionError(3, 'no')} /> No</label><br />
															{this.state.error3 ? <span id="error_nwells" className="text-danger" >Answer is Yes</span>:null}<br />
															4. Do you understand that these are "restricted shares" and any return will be limited to dividends, stock buy-back, sale, merger, IPO, or any potential liquidity on the secondary market?
															<br />
															<label><input type="radio" name="q4" id="q4y" defaultValue={1} onChange={()=>this.questionError(4, 'yes')}/> Yes</label><br />
															<label><input type="radio" name="q4" id="q4n" defaultValue={2} onChange={()=>this.questionError(4, 'no')}/> No</label><br />
															{this.state.error4 ? <span id="error_nwells" className="text-danger" >Answer is Yes</span>:null}<br />
															5. Secondary markets are based on supply and demand; while you may be able to list your shares for sale after holding them for one year, that doesn’t mean that someone will be waiting there to buy them. Do you agree?
															<br />
															<label><input type="radio" name="q5" id="q5y" defaultValue={1} onChange={()=>this.questionError(5, 'yes')} /> Yes</label><br />
															<label><input type="radio" name="q5" id="q5n" defaultValue={2} onChange={()=>this.questionError(5, 'no')}/> No</label><br />
															{this.state.error5 ? <span id="error_nwells" className="text-danger" >Answer is Yes</span>:null}<br />
															6. Do you understand that crowdfund investing is a high risk investment and, as with most investments, the way to reduce exposure is to diversify (for example, invest in stocks that are not as risky)?
															<br />
															<label><input type="radio" name="q6" id="q6y" defaultValue={1} onChange={()=>this.questionError(6, 'yes')} /> Yes</label><br />
															<label><input type="radio" name="q6" id="q6n" defaultValue={2} onChange={()=>this.questionError(6, 'no')}/> No</label><br />
															{this.state.error6 ? <span id="error_nwells" className="text-danger" >Answer is Yes</span>:null}<br />
															{/*Do you know and trust the entrepreneur or small business owner and understand his/her idea and business model?
													<br/>
													<label><input type="radio" name="q7" id="q7y" value="1"> Yes</label><br>
													<label><input type="radio" name="q7" id="q7n" value="2"> No</label><br>
													<label for="q7" generated="true" class="error"></label><br/>
													The reason crowdfund investing works is because it is presumed that you have a first- or second-degree relation to the entrepreneur.
													<br/>
													<label><input type="radio" name="q8" id="q8y" value="1"> Yes</label><br>
													<label><input type="radio" name="q8" id="q8n" value="2"> No</label><br>
													<label for="q8" generated="true" class="error"></label><br/>*/}
															7. Do you understand that it is your responsibility to understand the business idea and how it makes money to decide if it is worthy of your investment?
															<br />
															<label><input type="radio" name="q9" id="q9y" defaultValue={1} onChange={()=>this.questionError(7, 'yes')}/> Yes</label><br />
															<label><input type="radio" name="q9" id="q9n" defaultValue={2} onChange={()=>this.questionError(7, 'no')}/> No</label><br />
															{this.state.error7 ? <span id="error_nwells" className="text-danger" >Answer is Yes</span>:null}<br />
															8. Do you understand that no one should be making this investment choice for you?
															<br />
															<label><input type="radio" name="q10" id="q10y" defaultValue={1} onChange={()=>this.questionError(8, 'yes')}/> Yes</label><br />
															<label><input type="radio" name="q10" id="q10n" defaultValue={2} onChange={()=>this.questionError(8, 'no')}/> No</label><br />
															{this.state.error8 ? <span id="error_nwells" className="text-danger" >Answer is Yes</span>:null}<br />
															9. Do you understand that the investment offering is an arbitrary amount determined by the entrepreneur and that if you do not deem it worthy for the risk, you shouldn’t invest?
															<br />
															<label><input type="radio" name="q11" id="q11y" defaultValue={1} onChange={()=>this.questionError(9, 'yes')}/> Yes</label><br />
															<label><input type="radio" name="q11" id="q11n" defaultValue={2} onChange={()=>this.questionError(9, 'no')}/> No</label><br />
															{this.state.error9 ? <span id="error_nwells" className="text-danger" >Answer is Yes</span>:null}<br />
															10. Do you understand that crowdfund investing is active rather than passive investing?
															<br />
															<label><input type="radio" name="q12" id="q12y" defaultValue={1} onChange={()=>this.questionError(10, 'yes')} /> Yes</label><br />
															<label><input type="radio" name="q12" id="q12n" defaultValue={2} onChange={()=>this.questionError(10, 'no')}/> No</label><br />
															{this.state.error10 ? <span id="error_nwells" className="text-danger" >Answer is Yes</span>:null}<br />
															<input type="checkbox" name="iack" id="iack" defaultValue={1} /> I acknowledge that I have received and reviewed the
															<a href="https://www.energyfunders.com/education_topics/information-for-potential-investors/" target="_blank">Educational Materials</a><br /><br />
															<button id="submitquiz" onClick={this.quiz} className="g-btn type_secondary" style={{float: 'none'}}><span>Submit Quiz Answers</span></button>

														</div>
													</div>
													<br /><br />
													<p>Also, you may <a href="https://www.energyfunders.com/about-us" target="_blank">learn more about EnergyFunders</a>, and follow us on <a href="https://twitter.com/EnergyFunders" target="_blank">Twitter</a>, <a href="https://www.facebook.com/EnergyFunders" target="_blank">Facebook</a>, and <a href="https://www.linkedin.com/company/energyfunders" target="_blank">LinkedIn</a> for updates!</p>
												</div>
												<hr />
												<div className="w-form contacts_form" style={{maxWidth: 'none'}}>{/* contacts_form */}
												</div>{/*end contacts_form */}
												<div className="g-form-row">
													<div className="g-form-row-label" />
													<div className="g-form-row-field">

														<button id="back" type="button" onClick={() => this.props.history.push('/app/UserNotifications')} className="g-btn type_secondary" style={{float: 'left'}}><span>Back to Dashboard</span></button>

													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>
				</div>
			</div>
		);
  	}
}


const { object, bool } = PropTypes;

View_regcf_education.propTypes = {
    session: object.isRequired,
    authenticated: bool.isRequired,
    checked: bool.isRequired
};

const mapState = ({ session }) => ({
    session: session.user,
    checked: session.checked,
    authenticated: session.authenticated
});

export default  withRouter(connect(mapState)(View_regcf_education));
