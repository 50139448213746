import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Pagination from 'react-js-pagination';
import { getUUID } from '../../../../utils/General';
import HideComponent from '../../HideComponent/HideComponent';

class EmailHistoryViewerComponent extends React.Component {
    ITEMS_PER_PAGE = 10;

    constructor(props) {
        super(props);

        this.state = {
            activePage: 1,
            pageItems: [],
            totalItems: 0,
        };
    }

    componentDidMount() {
        const { onSetParentActivePage } = this.props;
        const { activePage } = this.state;

        this.onPageChange(activePage);
        onSetParentActivePage(this.onPageChange);
    }

    componentDidUpdate(prevProps) {
        const { activePage } = this.state;
        const { investorEmailHistory } = this.props;
        const strInvestorEmailHistory = JSON.stringify(investorEmailHistory);
        const _strInvestorEmailHistory = JSON.stringify(prevProps.investorEmailHistory);

        if (strInvestorEmailHistory !== _strInvestorEmailHistory) {
            this.onPageChange(activePage);
        }
    }


    onPageChange = (activePage) => {
        const { investorEmailHistory } = this.props;
        const start = ((activePage - 1) * this.ITEMS_PER_PAGE);
        const end = (start + this.ITEMS_PER_PAGE);
        const pageItems = investorEmailHistory.slice(start, end);

        this.setState({
            pageItems,
            activePage,
            totalItems: investorEmailHistory.length,
        });
    };

    getFormattedDate = strDate => moment(strDate)
        .format('MMM DD, YYYY @ HH:mm');

    // RENDER MANAGER
    renderTableElement = () => {
        const { pageItems } = this.state;
        const { onViewEmail, showInvestorData } = this.props;
        const colSpan = showInvestorData ? 5 : 3;

        if (!pageItems || !pageItems.length) {
            return (
                <tr style={{ textAlign: 'center' }}>
                    <td colSpan={colSpan}>No emails</td>
                </tr>
            );
        }

        return pageItems.map(item => (
            <tr
                style={item.isRead ? { color: 'lightgrey' } : null}
                onClick={() => onViewEmail(item)}
                className="pointer"
                key={getUUID()}
            >
                <HideComponent hide={!showInvestorData}>
                    <td>{item.userId}</td>
                    <td>
                        {item.userName}
                        <br />
                        {item.email}
                    </td>
                </HideComponent>
                <td>{item.subject}</td>
                <td>{this.getFormattedDate(item.sentDate)}</td>
                <td>{item.readDate ? this.getFormattedDate(item.readDate) : 'Not viewed'}</td>
            </tr>
        ));
    };

    renderTable = () => {
        const { showInvestorData } = this.props;

        return (
            <div className="table-responsive">
                <table className="table table-hover table-striped">
                    <thead>
                        <tr>
                            <HideComponent hide={!showInvestorData}>
                                <th>UserId</th>
                                <th>User Name</th>
                            </HideComponent>
                            <th>Subject</th>
                            <th>Date Sent</th>
                            <th>Date Viewed</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderTableElement()}
                    </tbody>
                </table>
            </div>
        );
    };

    render = () => {
        const { activePage, totalItems } = this.state;

        return (
            <div className="margin-top-30px">
                {this.renderTable()}
                <div className="pull-right">
                    <Pagination
                        firstPageText={<i className="glyphicon glyphicon-chevron-left" />}
                        lastPageText={<i className="glyphicon glyphicon-chevron-right" />}
                        prevPageText={<i className="glyphicon glyphicon-menu-left" />}
                        nextPageText={<i className="glyphicon glyphicon-menu-right" />}
                        activePage={activePage}
                        itemsCountPerPage={this.ITEMS_PER_PAGE}
                        totalItemsCount={totalItems}
                        onChange={this.onPageChange}
                    />
                </div>
            </div>
        );
    };
}

EmailHistoryViewerComponent.propTypes = {
    investorEmailHistory: PropTypes.array.isRequired,
    onViewEmail: PropTypes.func.isRequired,
    onSetParentActivePage: PropTypes.func,
    showInvestorData: PropTypes.bool,
};

EmailHistoryViewerComponent.defaultProps = {
    investorEmailHistory: [],
    onViewEmail: () => null,
    onSetParentActivePage: () => null,
    showInvestorData: false,
};

export default EmailHistoryViewerComponent;
