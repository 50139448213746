import PropTypes from 'prop-types';
import React from 'react';
import SelectComponent from '../../../shared/InputComponents/SelectComponent/SelectComponent';
import SingleLineInputComponent
    from '../../../shared/InputComponents/SingleLineInputComponent/SingleLineInputComponent';
import Icon from '../../../shared/IconComponent/IconComponent';
import { PROJECT_REG_CF, PROJECT_REG_D, YEAR_MONTHS } from '../../../../utils/ApplicationConstants';
import { yearSelectGenerator } from '../../../../utils/General';

class FilterComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            filterKey: '',
            filterValue: '',
            projectIdFilterKey: '',
        };

        this.filteringOptions = [
            {
                id: 'userId',
                title: 'User ID',
            },
            {
                id: 'docTypeId',
                title: 'Document Type',
            },
            {
                id: 'docMonth',
                title: 'Document Month',
            },
            {
                id: 'docYear',
                title: 'Document Year',
            },
            {
                id: 'projectIdFilter',
                title: 'Project ID',
            },
            {
                id: 'projectType',
                title: 'Project Type',
            },
        ];
        this.filteringOptions2 = [
            {
                id: 'userId',
                title: 'User ID',
            },
            {
                id: 'docMonth',
                title: 'Document Month',
            },
            {
                id: 'docYear',
                title: 'Document Year',
            },
            {
                id: 'projectIdFilter',
                title: 'Project ID',
            },
            {
                id: 'projectType',
                title: 'Project Type',
            },
        ];

        this.projectTypeList = [
            {
                id: PROJECT_REG_D,
                title: 'Reg D',
            },
            {
                id: PROJECT_REG_CF,
                title: 'Reg CF',
            },
        ];

        this.projectIdTypeList = [
            {
                id: 'regDId',
                title: 'Reg D',
            },
            {
                id: 'regCfId',
                title: 'Reg CF',
            },
        ];
    }

    onInputChange = (event) => {
        const { name, value } = event.target;

        this.setState({ [name]: value });
    };

    onClearFilter = () => {
        const { onClearFiltering } = this.props;

        this.setState({
            filterKey: '',
            filterValue: '',
        });

        onClearFiltering();
    };

    onFilter = () => {
        const { filterKey, filterValue, projectIdFilterKey } = this.state;
        const { onPerformFilter } = this.props;

        let key = filterKey;

        if (filterKey === 'projectIdFilter') {
            key = projectIdFilterKey;
        }

        onPerformFilter(key, filterValue);
    };

    // RENDER MANAGER
    renderUserIdFilterInput = () => {
        const { filterKey, filterValue } = this.state;

        if (filterKey !== 'userId') {
            return null;
        }

        return (
            <div className="col-xs-12 col-md-8">
                <SingleLineInputComponent
                    elementValue={filterValue}
                    inputName="filterValue"
                    inputTitle="Write User ID to filter"
                    maxInputLength={null}
                    onInputUpdate={this.onInputChange}
                    isRequired
                />
            </div>
        );
    };

    renderYearMonthsFilterInput = () => {
        const { filterKey, filterValue } = this.state;

        if (filterKey !== 'docMonth') {
            return null;
        }

        return (
            <SelectComponent
                xs={12}
                md={8}
                name="filterValue"
                onChange={this.onInputChange}
                options={YEAR_MONTHS}
                selectTitle="Select the month of the year to filter"
                value={filterValue}
                isRequired
            />
        );
    };

    renderYearsFilterInput = () => {
        const { filterKey, filterValue } = this.state;

        if (filterKey !== 'docYear') {
            return null;
        }

        return (
            <SelectComponent
                xs={12}
                md={8}
                name="filterValue"
                onChange={this.onInputChange}
                options={yearSelectGenerator(2014, new Date().getFullYear())}
                selectTitle="Select the year to filter"
                value={filterValue}
                isRequired
            />
        );
    };

    renderFileTypeFilterInput = () => {
        const { filterKey, filterValue } = this.state;
        const { fileTypeList } = this.props;

        if (filterKey !== 'docTypeId') {
            return null;
        }

        return (
            <SelectComponent
                xs={12}
                md={8}
                name="filterValue"
                onChange={this.onInputChange}
                options={fileTypeList}
                selectTitle="Select the document type"
                value={filterValue}
                isRequired
            />
        );
    };

    renderProjectTypeFilterInput = () => {
        const { filterKey, filterValue } = this.state;

        if (filterKey !== 'projectType') {
            return null;
        }

        return (
            <SelectComponent
                xs={12}
                md={8}
                name="filterValue"
                onChange={this.onInputChange}
                options={this.projectTypeList}
                selectTitle="Select the document type"
                value={filterValue}
                isRequired
            />
        );
    };

    renderProjectIdFilterFilterInput = () => {
        const { filterKey, projectIdFilterKey, filterValue } = this.state;
        const { allProjects } = this.props;
        const { regCfProjectList, regDProjectList } = allProjects;
        let projectList = [];

        if (filterKey !== 'projectIdFilter') {
            return null;
        }

        if (projectIdFilterKey === 'regDId') {
            projectList = regDProjectList;
        } else if (projectIdFilterKey === 'regCfId') {
            projectList = regCfProjectList;
        }

        return (
            <React.Fragment>
                <SelectComponent
                    xs={12}
                    sm={6}
                    md={4}
                    name="projectIdFilterKey"
                    onChange={this.onInputChange}
                    options={this.projectIdTypeList}
                    selectTitle="Select the project type"
                    value={projectIdFilterKey}
                    isRequired
                />
                <SelectComponent
                    xs={12}
                    sm={6}
                    md={4}
                    name="filterValue"
                    onChange={this.onInputChange}
                    options={projectList}
                    selectTitle="Select the project to filter"
                    value={filterValue}
                    isRequired
                />
            </React.Fragment>
        );
    };

    render = () => {
        const { filterKey, filterValue } = this.state;
        const { inContractTab } = this.props;
        return (
            <fieldset className="margin-top-30px">
                <legend>
                    <div className="pull-left">
                        <h3>Filter documents</h3>
                    </div>
                </legend>
                <SelectComponent
                    xs={12}
                    md={4}
                    name="filterKey"
                    onChange={this.onInputChange}
                    options={ inContractTab ? this.filteringOptions2 : this.filteringOptions}
                    selectTitle="Filter by:"
                    value={filterKey}
                    isRequired
                />

                {this.renderYearsFilterInput()}
                {this.renderUserIdFilterInput()}
                {this.renderFileTypeFilterInput()}
                {this.renderYearMonthsFilterInput()}
                {this.renderProjectTypeFilterInput()}
                {this.renderProjectIdFilterFilterInput()}

                <div className="col-xs-12">
                    <button
                        className="btn btn-default btn-sm upper-case-text"
                        onClick={this.onClearFilter}
                        type="button"
                    >
                        <Icon iconName="times"/>
                        {' '}
                        Clear filter
                    </button>
                    <button
                        className="btn btn-primary btn-sm upper-case-text"
                        onClick={this.onFilter}
                        disabled={filterKey === '' || !filterKey}
                        type="button"
                    >
                        <Icon iconName="filter" extraClasses="white-text"/>
                        {' '}
                        Filter
                    </button>
                </div>
            </fieldset>
        );
    };
}

FilterComponent.propTypes = {
    allProjects: PropTypes.array.isRequired,
    fileTypeList: PropTypes.array.isRequired,
    onClearFiltering: PropTypes.func.isRequired,
    onPerformFilter: PropTypes.func.isRequired,
};

FilterComponent.defaultProps = {
    allProjects: [],
    fileTypeList: [],
    onClearFiltering: () => null,
    onPerformFilter: () => null,
};

export default FilterComponent;
