import PropTypes from 'prop-types'
import React from 'react';
import {Collapse, Panel} from 'react-bootstrap';
import {PROJECT_REG_CF, PROJECT_REG_D, PROJECT_UPDATE_CREATE} from "../../../../../utils/ApplicationConstants";
import Icon from "../../../IconComponent/IconComponent";
import SingleLineInputComponent from '../../../InputComponents/SingleLineInputComponent/SingleLineInputComponent'
import {getUUID} from "../../../../../utils/General";
import IntInputComponent from "../../../InputComponents/IntInputComponent/IntInputComponent";
import CKEditor from "react-ckeditor-component";

class AddTabComponent extends React.Component {
    DEFAULT_STATE_VALUES = {
        tabName: '',
        tabOrder: 0,
        tabContent: '',
        isInputPanelExpanded: true,
    };

    constructor(props) {
        super(props);

        this.state = {...this.DEFAULT_STATE_VALUES};

        this.refTabContent = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const _tabId = prevProps.tabId;
        const {tabId} = this.props;

        if (_tabId !== tabId) {
            this.setState({...this.props.selectedTab});
        }
    }

    setContentAfterLoad = (referenceKey, valueKey) => {
        const _value = this.state[valueKey];
        const {id} = this[referenceKey].current.editorInstance;
        const instances = window.CKEDITOR.instances;
        const result = Object.keys(instances)
            .map(key => instances[key])
            .filter(instance => instance.id === id)[0];
        result.document.$.getElementsByTagName("body")[0].innerHTML = _value;
    };

    onSubmitData = (event) => {
        event.preventDefault();
        const {actionType, onAddNewTab, onUpdateTab} = this.props;
        let method = actionType === PROJECT_UPDATE_CREATE ? onAddNewTab : onUpdateTab;

        method(this.createSubmitObject());
        this.setState(this.DEFAULT_STATE_VALUES, this.closeModal);
    };

    togglePanel = (panelName) => {
        this.setState({[panelName]: !this.state[panelName]})
    };

    onInputUpdate = (event) => {
        const {name, value} = event.target;
        this.setState({[name]: value});
    };

    onEditorChange = (event, name) => {
        let content = event.editor.getData();
        this.setState({[name]: content});
    };

    createSubmitObject = () => {
        const {projectType, projectId, actionType, tabId} = this.props;
        const {tabName, tabOrder, tabContent} = this.state;

        const projectRegId = projectType === PROJECT_REG_D ? projectId : 0;
        const projectRegCfId = projectType === PROJECT_REG_CF ? projectId : 0;
        const _tabId = actionType === PROJECT_UPDATE_CREATE ? getUUID() : tabId;

        return {
            tabName,
            tabContent,
            projectRegId,
            projectRegCfId,
            tabId: _tabId,
            tabOrder: parseInt(tabOrder),
        };
    };

    closeModal = () => {
        this.setState(this.DEFAULT_STATE_VALUES);
        this.props.onCloseModal();
    };

    disableSubmitButton = () => {
        const {tabName, tabContent} = this.state;
        const isCorrectTabName = tabName !== '';
        const isCorrectTabContent = tabContent !== '';

        return (!isCorrectTabName || !isCorrectTabContent);
    };

    render = () => {
        const {isInputPanelExpanded} = this.state;

        if(!this.props.isModalOpen) {
            return null;
        }

        return (
            <Collapse
                in={this.props.isModalOpen}
            >
                <div>
                    <Panel expanded={isInputPanelExpanded}>
                        <Panel.Heading>
                            <Panel.Title>
                                <div className="container pointer no-margin"
                                     onClick={() => this.togglePanel('isInputPanelExpanded')}>
                                    <span className="pull-left">Tab info</span>
                                    <Icon iconName={isInputPanelExpanded ? "minus" : "plus"} extraClasses="pull-right"/>
                                </div>
                            </Panel.Title>
                        </Panel.Heading>
                        <Panel.Collapse>
                            <Panel.Body>
                                <form onSubmit={this.onSubmitData}>
                                    <IntInputComponent elementValue={this.state.tabOrder}
                                                       inputName="tabOrder"
                                                       inputTitle="Tab Order"
                                                       onInputUpdate={this.onInputUpdate}/>

                                    <SingleLineInputComponent elementValue={this.state.tabName}
                                                              onInputUpdate={this.onInputUpdate}
                                                              inputTitle="Tab name"
                                                              inputName="tabName"
                                                              maxInputLength={50}/>

                                    <div className="form-group">
                                        <label>Tab Component</label>
                                        <CKEditor name="tabContent"
                                                  ref={this.refTabContent}
                                                  content={this.state.tabContent}
                                                  events={{
                                                      "change": (e) => this.onEditorChange(e, 'tabContent'),
                                                      "instanceReady": () => this.setContentAfterLoad("refTabContent", "tabContent"),
                                                  }}/>
                                    </div>

                                    <br/>

                                    <div className="pull-right">
                                        <button type="reset"
                                                onClick={this.closeModal}
                                                className="btn btn-danger btn-sm capitalize-text">
                                            cancel
                                        </button>
                                        <button type="submit"
                                                disabled={this.disableSubmitButton()}
                                                className="btn btn-primary btn-sm capitalize-text">
                                            save
                                        </button>
                                    </div>
                                </form>
                            </Panel.Body>
                        </Panel.Collapse>
                    </Panel>
                </div>
            </Collapse>
        );
    };
}

AddTabComponent.propTypes = {
    actionType: PropTypes.string.isRequired,
    tabId: PropTypes.any.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    onAddNewTab: PropTypes.func.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    onUpdateTab: PropTypes.func.isRequired,
    projectId: PropTypes.number.isRequired,
    projectType: PropTypes.string.isRequired,
    selectedTab: PropTypes.any.isRequired,
};

AddTabComponent.defaultProps = {
    actionType: '',
    tabId: 0,
    isModalOpen: false,
    onAddNewTab: () => null,
    onCloseModal: () => null,
    onUpdateTab: () => null,
    projectId: 0,
    projectType: '',
    selectedTab: {},
};

export default AddTabComponent;
