import * as Actions from '../actions/actions';
import initialState from '../../../../reducers/appInitialState';
import { updateRequestCounter } from '../../../../utils/General';

export default (state = initialState.newEntity, action) => {
    const { minus, plus } = updateRequestCounter(state, 'NEOnFlightRequest');

    switch (action.type) {
    case Actions.NE_END_REQUEST:
        return {
            ...state,
            NEOnFlightRequest: minus,
        };
    case Actions.NE_START_REQUEST:
        return {
            ...state,
            NEOnFlightRequest: plus,
        };
    case Actions.NE_DWOLLA_CATEGORIES_SUCCESS_REQ:
        return {
            ...state,
            ...action.data,
            NEOnFlightRequest: minus,
        };
    default:
        return { ...state };
    }
};
