import React from 'react';
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import TabComponent from "../../shared/TabsComponent/index";
import * as ROCActions from './actions/actions'
import DistributeTableComponent from "./components/DistributeTableComponent";
import RoCViewerModal from "./components/RoCViewerModal";
import {
    PERFORM_RETURN_OF_CAPITAL,
    PROJECT_REG_CF,
    PROJECT_REG_D,
    VIEW_RETURN_OF_CAPITAL
} from "../../../utils/ApplicationConstants";
import {Modal} from 'react-bootstrap';
import CreatReturnOfCapitalContainer from './ReturnOfCapitalContainer';

class DistributeReturnOfCapitalContainer extends React.Component {
    TABS_CONFIG = [
        {number: 0, title: 'EnergyFunders Black (Reg D)'},
        {number: 1, title: 'EnergyFunders Marketplace (Reg CF)'},
    ];

    constructor(props) {
        super(props);

        this.state = {
            selectedTab: 0,
            openRoCViewerModal: false,

            selectedReturnId: 0,
            selectedProjectName: '',
            selectedProjectType: '',
            selectedActionType: VIEW_RETURN_OF_CAPITAL,

            openReturnOfCapitalModal: false,
        };
    }

    componentDidMount() {
        this.init();
    }

    init = () => {
        const {getAllRecordedReturnsOfCapital} = this.props;
        getAllRecordedReturnsOfCapital();
    };

    setSelectedTab = (selectedTab) => {
        this.setState({selectedTab});
    };

    verifyReturnDistribution = (selectedReturnId, selectedProjectName, selectedProjectEntity, projectType) => {
        const {verifyReturnsOfCapital} = this.props;

        this.setState({
            selectedReturnId,
            selectedProjectName,
            selectedProjectEntity,
            openRoCViewerModal: true,
            selectedProjectType: projectType,
            selectedActionType: PERFORM_RETURN_OF_CAPITAL,
        });

        verifyReturnsOfCapital(selectedReturnId, projectType);
    };

    performReturnOfCapital = (returnId, projectType) => {
        const {performDistribution} = this.props;

        performDistribution(returnId, projectType);
        this.closeVerifyModal();
    };

    getDistributionResult = (selectedReturnId, selectedProjectName, selectedProjectEntity, projectType) => {
        const {getDistributionResult} = this.props;

        this.setState({
            selectedReturnId,
            selectedProjectName,
            selectedProjectEntity,
            openRoCViewerModal: true,
            selectedProjectType: projectType,
            selectedActionType: VIEW_RETURN_OF_CAPITAL,
        });

        getDistributionResult(selectedReturnId);
    };

    closeVerifyModal = () => {
        this.setState({openRoCViewerModal: false});
    };

    openRecordReturnOfCapitalModal = () => {
        this.setState({openReturnOfCapitalModal: true});
    };

    closeRecordReturnOfCapitalModal = () => {
        this.setState({openReturnOfCapitalModal: false});
    };

    renderRegDReturnedCapital = () => {
        if (this.state.selectedTab === 0) {
            return (
                <DistributeTableComponent onDistributeClicked={this.verifyReturnDistribution}
                                          onViewDistributedResult={this.getDistributionResult}
                                          projectList={this.props.regDReturnList}
                                          projectType={PROJECT_REG_D}/>
            );
        }

        return null;
    };

    renderRegCfReturnedCapital = () => {
        if (this.state.selectedTab === 1) {
            return (
                <DistributeTableComponent onDistributeClicked={this.verifyReturnDistribution}
                                          onViewDistributedResult={this.getDistributionResult}
                                          projectList={this.props.regCFReturnList}
                                          projectType={PROJECT_REG_CF}/>
            );
        }

        return null;
    };

    render = () => {
        return (
            <div style={{padding: 30}}>
                <div className="row">
                    <div className="page_title pull-left">
                        <h3 className="capitalize-text">Distribute Return Of Capital</h3>
                    </div>
                    <div className="pull-right">
                        <button className="btn btn-primary btn-sm"
                                onClick={this.openRecordReturnOfCapitalModal}>
                            Record Return Of Capital
                        </button>
                    </div>
                </div>

                <div className="container">
                    <TabComponent tabList={this.TABS_CONFIG}
                                  defaultSelection={0}
                                  setSelected={this.setSelectedTab}>
                        {this.renderRegDReturnedCapital()}
                        {this.renderRegCfReturnedCapital()}
                    </TabComponent>
                </div>

                <RoCViewerModal closeModal={this.closeVerifyModal}
                                entityName={this.state.selectedProjectEntity}
                                open={this.state.openRoCViewerModal}
                                projectName={this.state.selectedProjectName}
                                returnList={this.props.investorReturnOfCapitalList}
                                internalNotes={this.props.internalNotes}
                                performReturnOfCapital={this.performReturnOfCapital}
                                returnId={this.state.selectedReturnId}
                                projectType={this.state.selectedProjectType}
                                actionType={this.state.selectedActionType}/>

                <Modal show={this.state.openReturnOfCapitalModal}
                       onHide={this.closeRecordReturnOfCapitalModal}>
                    <Modal.Header closeButton>
                        <Modal.Title className="capitalize-text">
                            Record a New Return Of capital
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <CreatReturnOfCapitalContainer updateOnSuccessfulRecord={this.init}
                                                       closeModal={this.closeRecordReturnOfCapitalModal}/>
                    </Modal.Body>
                </Modal>
            </div>
        );
    };
}

DistributeReturnOfCapitalContainer.propTypes = {
    getAllRecordedReturnsOfCapital: PropTypes.func.isRequired,
    investorReturnOfCapitalList: PropTypes.array.isRequired,
    performDistribution: PropTypes.func.isRequired,
    regCFReturnList: PropTypes.array.isRequired,
    regDReturnList: PropTypes.array.isRequired,
    verifyReturnsOfCapital: PropTypes.func.isRequired,
    getDistributionResult: PropTypes.func.isRequired,
};

DistributeReturnOfCapitalContainer.defaultProps = {
    getAllRecordedReturnsOfCapital: () => null,
    investorReturnOfCapitalList: [],
    performDistribution: () => null,
    regCFReturnList: [],
    regDReturnList: [],
    verifyReturnsOfCapital: () => null
};

const mapStateToProps = (_state) => {
    const {returnOfCapital} = _state;
    const {regCfReturnedCapital, regDReturnedCapital, investorReturn, internalNotes} = returnOfCapital;
    return {
        investorReturnOfCapitalList: investorReturn,
        regCFReturnList: regCfReturnedCapital,
        regDReturnList: regDReturnedCapital,
        internalNotes: internalNotes
    };
};

const mapDispatchToProps = (_dispatch) => {
    const dispatchObj = bindActionCreators(ROCActions, _dispatch);
    return {...dispatchObj};
};

const connectedComponent = connect(
    mapStateToProps,
    mapDispatchToProps,
)(DistributeReturnOfCapitalContainer);

export default withRouter(connectedComponent);
