import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import * as Action from './actions/actions';

import ProjectDetailsComponent from '../ProjectDetailsComponent/ProjectDetailsComponent';
import { getUUID } from '../../../utils/General';
import NotificationService from '../../../utils/NotificationService';

import './ProjectEditorContainerComponent.style.css';


class ProjectEditorContainerComponent extends React.Component {
    T_DATE = 'YYYY-MM-DDTHH:mm:ss';
    SPACE_DATE = 'YYYY-MM-DD HH:mm:ss';

    DEFAULT_PROPS = {
        PEV2OnFlightRequest: 0,
        projectTabs: [],
        projectDetails: {},
        parentProjects: [],
        projectHighlights: [],
        helloSignTemplates: [],
        projectCategories: [],
        projectStatuses: [],
        projectFiles: [],
    };

    constructor(props) {
        super(props);

        this.state = {
            projectId: 0,
            projectType: '',

            projectDetails: {
                // GENERAL DETAILS
                image: '',
                status: '',
                category: '',
                location: '',
                operatedBy: '',
                description: '',
                projectName: '',
                fundingPeriod: 0,
                cardImageBlob: null,
                dealCardDescription: '',

                // PROJECT DETAILS
                entity: '',
                isCashCall: false,
                contactInfo: '',
                publishDate: '',
                projectType: 'Conventional',
                selectedParent: 0,
                regulationType: '506B',
                workingInterest: 0.0,
                dwollaAccountUrl: '',
                investmentStartDate: '',
                percentOfOriginalInvestment: '',
				GP_LP_Forced: 0,

                // FINANCE DETAILS
                unit: 0,
                efCarry: 0,
                fundingGoalMin: 0,
                fundingGoalMax: 0,
                minimumInvestment: 0,
                investmentIncrementUnit: 0,

                // HELLO SIGN VARIABLES
                fundName: '',
                minimumUnits: '',
                maximumUnits: '',
                minimumProceeds: '',
                maximumProceeds: '',
                managingGPCarryBPO: '',
                managingGPCarryAPO: '',
                helloSignTemplateId: 0,
                operatorLPAgreement: '',
                minUnitsWithManagingGPCarry: '',
                maxUnitsWithManagingGPCarry: '',
				SkipHelloSign: 0,

                // SOCIAL NETWORK DETAILS
                socialTwitter: '',
                socialFacebook: '',
                socialLinkedIn: '',
                socialVideoURL: '',
                socialProjectWebPage: '',

                // SECURITIES FOR PROJECT
                typeOfSecurity: '',
                securityUnitPrice: 0,
                totalNumberOfSecurities: 0,
            },

            projectHighlights: [],
            selectedHighlight: {},
            deletedHighlights: [],

            projectTabs: [],
            selectedTab: {},
            deletedTabs: [],

            projectFiles: [],
            newProjectFiles: [],
            deletedProjectDocs: [],

            isApprovalPage: false,
            isNotPublished: false,
        };
    }


    componentDidMount() {
        const { projectId, projectType } = this.props.location.state;
        const {
            user, getHelloSignTemplates, getAllProject, getProjectInformation,
        } = this.props;
        const isApprovalPage = this.isApprovalPage();

        getProjectInformation(projectId, projectType);
        getHelloSignTemplates(user);
        getAllProject();

        this.setState({
            projectId,
            projectType,
            isApprovalPage,
        });

        window.addEventListener('scroll', this.showFloatingSaveButton);
    }


    componentDidUpdate(prevProps) {
        const projectTabs = JSON.stringify(prevProps.projectTabs);
        const _projectTabs = JSON.stringify(this.props.projectTabs);

        const projectHighlights = JSON.stringify(prevProps.projectHighlights);
        const _projectHighlights = JSON.stringify(this.props.projectHighlights);

        const projectFiles = JSON.stringify(prevProps.projectFiles);
        const _projectFiles = JSON.stringify(this.props.projectFiles);

        this.setProjectDetailValues(prevProps);

        if (projectTabs !== _projectTabs) {
            const { projectTabs } = this.props;

            this.setState({ projectTabs });
        }

        if (projectHighlights !== _projectHighlights) {
            const { projectHighlights } = this.props;

            this.setState({ projectHighlights });
        }

        if (_projectFiles !== projectFiles) {
            this.setState({ projectFiles: this.props.projectFiles });
        }
    }


    componentWillUnmount() {
        const { getProjectDataSuccess: resetProps } = this.props;

        window.removeEventListener('scroll', this.showFloatingSaveButton);

        resetProps(this.DEFAULT_PROPS);
    }


    isApprovalPage = () => {
        const { pathname } = this.props.location;
        const locationName = pathname.split('/')
            .pop();

        return (locationName === 'approve');
    };


    setProjectDetailValues = (prevProps) => {
        const _projectDetails = JSON.stringify(prevProps.projectDetails);
        const projectDetails = JSON.stringify(this.props.projectDetails);

        if (_projectDetails !== projectDetails) {
            if (!this.props.projectDetails.isNotPublished) {
                this.setPublishedProjectDetails();
            } else {
                this.setNewProjectDetails();
            }
        }
    };


    setPublishedProjectDetails = () => {
        const projectDetails = { ...this.props.projectDetails };

        projectDetails.publishDate = this.parseDate(projectDetails.publishDate, this.T_DATE);	// T_DATE is for Chrome to format it into a calendar select.
        projectDetails.investmentStartDate = this.parseDate(projectDetails.investmentStartDate, this.T_DATE);
        projectDetails.isCashCall = (projectDetails.isCashCall === 1);
        projectDetails.CashCallDateLPEnd = this.parseDate(projectDetails.CashCallDateLPEnd, this.T_DATE);
        const isNotPublished = false;

        this.setState({
            projectDetails,
            isNotPublished,
        });
    };


    setNewProjectDetails = () => {
        const futureDefaultDate = moment().add(3, 'd').format(this.T_DATE);
        const projectDetails = { ...this.state.projectDetails, ...this.props.projectDetails };
        const { isNotPublished } = projectDetails;

        projectDetails.publishDate = futureDefaultDate;
        projectDetails.investmentStartDate = futureDefaultDate;

        delete projectDetails.isNotPublished;
        this.setState({
            projectDetails,
            isNotPublished,
        });
    };


    parseDate = (date, format) => moment(date).format(format);


    onInputChange = (event) => {
        const { name, value } = event.target;
        const _projectDetails = { ...this.state.projectDetails };

        _projectDetails[name] = value;

        this.setState({ projectDetails: _projectDetails }, this.validateFormData);
    };


    validateFormData = () => {
        const { projectDetails, isApprovalPage, isNotPublished } = this.state;
        const notRequired = ['isCashCall', 'image', 'projectID', 'synchronizedToHubSpot', 'cardImageBlob'];
        const approvalPageRequired = ['projectName', 'publishDate', 'investmentStartDate'];

        if (!projectDetails.isCashCall) {
            notRequired.push(...['percentOfOriginalInvestment', 'selectedParent']);
        }

        if (!isNotPublished) {
            return Object.keys(projectDetails)
                .filter(key => notRequired.indexOf(key) === -1)
                .map(key => ({
                    key,
                    value: projectDetails[key],
                }))
                .map(this.verifyInputData)
                .every(bool => bool);
        } if (isApprovalPage && isNotPublished) {
            return Object.keys(projectDetails)
                .filter(key => approvalPageRequired.indexOf(key) !== -1)
                .map(key => ({
                    key,
                    value: projectDetails[key],
                }))
                .map(this.verifyInputData)
                .every(bool => bool);
        }
    };


    verifyInputData = (item) => {
        const { value, key } = item;
        const el = document.getElementsByName(key)[0];

        if ((!value || value === '' || value === 0 || value < 0) && el) {
            el.classList.add('error-input');
            return false;
        } else if (el) {
            el.classList.remove('error-input');
            return true;
        }

        if (key === 'description' && (value && value !== '')) {
            const el = document.getElementById('projectDescription');
            el.classList.remove('error-input');
            return true;
        } else if (key === 'description' && (!value || value === '')) {
            const el = document.getElementById('projectDescription');
            el.classList.add('error-input');
            return false;
        }
    };


    onCardImageChange = (event) => {
        const fileList = this.getFileArray(event);

        if (!fileList || !fileList.length) {
            return;
        }

        const imageFile = fileList[0];
        const fileType = imageFile.type.toLowerCase();

        if (!this.isCorrectSize(imageFile) || !fileType.startsWith('image/')) {
            return;
        }

        this.cardImageReader(imageFile);
        this.onInputChange({
            target: {
                name: 'cardImageBlob',
                value: imageFile,
            },
        });
    };


    cardImageReader = (imageFile) => {
        const fileReader = new FileReader();

        fileReader.onloadend = (event) => {
            const { result: value } = event.target;
            const _event = {
                target: {
                    name: 'image',
                    value,
                },
            };

            this.onInputChange(_event);
        };

        fileReader.readAsDataURL(imageFile);
    };


    onTabInputFilesChange = (event) => {
        const fileList = this.getFileArray(event);

        if (!fileList || !fileList.length) {
            return;
        }

        const newProjectFiles = [...this.state.newProjectFiles];
        const projectFiles = [...this.state.projectFiles];


        const mappedProjectFiles = fileList
            .filter(this.isCorrectSize)
            .filter(this.isAllowedFileType)
            .map((item) => {
                const fileId = getUUID();

                item.key = fileId;
                newProjectFiles.unshift(item);

                return {
                    documentId: fileId,
                    documentName: item.name,
                    s3Location: '',
                };
            });

        if (mappedProjectFiles.length !== fileList.length) {
            NotificationService.warning(
                'Remember that the files must be 32MB of size or less and we '
                + 'only accept this files types:\n"PDF", "DOC", "DOCX", "XLS", "XLSX", '
                + '"PPT", "PPTX", "GIF", "JPG", "PNG" and "TXT"',
                10000,
            );
        }

        projectFiles.unshift(...mappedProjectFiles);
        this.setState({
            projectFiles,
            newProjectFiles,
        });
    };


    isAllowedFileType = (file) => {
        const acceptedFileTypes = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'gif', 'jpg', 'png', 'txt'];
        const type = file.name.split('.')
            .pop();

        return acceptedFileTypes.some(fType => fType === type);
    };


    isCorrectSize = (file) => {
        const MAX_MB_PER_FILE = 32;
        const fileSize = file.size;
        const result = parseInt(((fileSize / 1024) / 1000));

        return (MAX_MB_PER_FILE >= result);
    };


    getFileArray = (event) => {
        const { files: eventFilesObj } = event.target;

        return Object.keys(eventFilesObj)
            .map(key => eventFilesObj[key]);
    };


    onSaveProject = () => {
        const {
            projectDetails, projectHighlights, deletedHighlights,
            projectTabs, deletedTabs, deletedProjectDocs, newProjectFiles,
        } = this.state;

		const { isApprovalPage, isNotPublished, projectType} = this.state;
		const { storeUpdatedProject, storeNewProjectInvestment, user } = this.props;

        const paramsContent = {
            // GENERAL DETAILS
            Category: parseInt(projectDetails.category),
            Location: projectDetails.location,
            OperatedBy: projectDetails.operatedBy,
            FundPeriod: projectDetails.fundingPeriod,
            Description: projectDetails.description,
            ProjectName: projectDetails.projectName,
            ProjectStatus: projectDetails.status,
            TemplateVersion: 2,
            DealCardDescription: projectDetails.dealCardDescription,

            // PROJECT DETAILS
            Entity: projectDetails.entity,
            FaqEnabled: false,
            IsCashCall: projectDetails.isCashCall ? 'true' : 'false',
			CashCallVersion: projectDetails.CashCallVersion,
			CashCallOpenToAll: projectDetails.CashCallOpenToAll ? 'true' : 'false',
			CashCallDateLPEnd: projectDetails.CashCallDateLPEnd,
            ProjectType: projectDetails.projectType,
            RegulationType: projectDetails.regulationType,
            ParentProjectID: (projectDetails.isCashCall ? projectDetails.selectedParent : 0),
            DwollaAccountUrl: projectDetails.dwollaAccountUrl,
			BTCAddress: projectDetails.BTCAddress,
			ETHAddress: projectDetails.ETHAddress,
            CompanyContactInfo: projectDetails.contactInfo,
            ProjectPublishDate: this.parseDate(projectDetails.publishDate, this.SPACE_DATE),
            InvestmentStartDate: this.parseDate(projectDetails.investmentStartDate, this.SPACE_DATE),
            PercentofOriginalInvestment: (projectDetails.isCashCall ? projectDetails.percentOfOriginalInvestment : 0),
			GP_LP_Forced: projectDetails.GP_LP_Forced,

            // FINANCE DETAILS
			efCarry: projectDetails.efCarry,
			Units: projectDetails.unit,
			WorkingInterest: projectDetails.workingInterest,	// BPO
			WorkingInterestAPO: projectDetails.WorkingInterestAPO,
			NetRevenueInterestBPO: projectDetails.NetRevenueInterestBPO,
			NetRevenueInterestAPO: projectDetails.NetRevenueInterestAPO,
			ManagerProfitShareBPO: projectDetails.ManagerProfitShareBPO,
			ManagerProfitShareAPO: projectDetails.ManagerProfitShareAPO,
			GasTaxes: projectDetails.GasTaxes,
			OilTaxes: projectDetails.OilTaxes,
			TotalDeductions: projectDetails.TotalDeductions,
            MemberPrice: projectDetails.investmentIncrementUnit,
            MinInvestment: projectDetails.minimumInvestment,
            FundingGoalmin: projectDetails.fundingGoalMin,
            FundingGoalmax: projectDetails.fundingGoalMax,

            // HELLO SIGN VARIABLES
            FundName: projectDetails.fundName,
            MinimumUnits: projectDetails.minimumUnits,
            MaximumUnits: projectDetails.maximumUnits,
            MinimumProceeds: projectDetails.minimumProceeds,
            MaximumProceeds: projectDetails.maximumProceeds,
            ManagingGPCarryBPO: projectDetails.managingGPCarryBPO,
            ManagingGPCarryAPO: projectDetails.managingGPCarryAPO,
            HelloSignTemplate: projectDetails.helloSignTemplateId,
            OperatorLPAgreement: projectDetails.operatorLPAgreement,
            MinUnitsWithManagingGPCarry: projectDetails.minUnitsWithManagingGPCarry,
            MaxUnitsWithManagingGPCarry: projectDetails.maxUnitsWithManagingGPCarry,
			SkipHelloSign: projectDetails.SkipHelloSign ? 1 : 0,

            // SOCIAL NETWORK DETAILS
            socialTwitter: projectDetails.socialTwitter,
            socialFacebook: projectDetails.socialFacebook,
            socialLinkedIn: projectDetails.socialLinkedIn,
            socialVideoURL: projectDetails.socialVideoURL,
            socialProjectWebPage: projectDetails.socialProjectWebPage,

            // SECURITIES FOR PROJECT
            typeOfSecurity: projectDetails.typeOfSecurity,
            securityUnitPrice: projectDetails.securityUnitPrice,
            totalNumberOfSecurities: projectDetails.totalNumberOfSecurities,

            InternalNotes: '',
            EditProject: 1,

            highlights: projectHighlights,
            projectTabs,
            deletedTabs,
            deletedHighlight: deletedHighlights,
            deletedProjectDocs,
        };

        const formData = new FormData();

        if (projectDetails.cardImageBlob) {
            formData.append('dealCard', projectDetails.cardImageBlob, projectDetails.cardImageBlob.name);
        }

        if (newProjectFiles && newProjectFiles.length) {
            newProjectFiles.forEach(file => formData.append('dueDiligence', file, file.name));
        }

        formData.append('params', JSON.stringify(paramsContent));

		if (!isNotPublished) {
			storeUpdatedProject(user, formData, projectDetails.projectID, projectType);
		}
		else if (isNotPublished && isApprovalPage) {
			storeNewProjectInvestment(projectDetails.projectID, projectType, formData);
		}
    };


    // HIGHLIGHTS MANAGER
    onDeleteHighlight = (highlight) => {
        const isStored = (highlight.highlightId.toString()
            .toLowerCase()
            .indexOf('-') === -1);

        if (isStored) {
            const { deletedHighlights } = this.state;
            const _deletedHighlights = [...deletedHighlights, highlight.highlightId];

            this.setState({ deletedHighlights: _deletedHighlights });
        }

        this.removeHighlightFromList(highlight);
    };


    removeHighlightFromList = (selectedHighlight) => {
        const { projectHighlights } = this.state;
        const _projectHighlights = [...projectHighlights];
        const highlightIndex = projectHighlights.indexOf(selectedHighlight);

        _projectHighlights.splice(highlightIndex, 1);
        this.setState({ projectHighlights: _projectHighlights });
    };


    onPerformUpdateHighlight = (highlight) => {
        const { projectHighlights, selectedHighlight } = this.state;
        const _projectHighlights = [...projectHighlights];
        const selectedHighlightIndex = projectHighlights.indexOf(selectedHighlight);

        _projectHighlights[selectedHighlightIndex] = highlight;

        this.setState({ projectHighlights: _projectHighlights },
            this.toggleHighlightModal);
    };


    onAddNewHighlight = (highlight) => {
        const projectHighlights = [...this.state.projectHighlights];

        projectHighlights.push(highlight);

        this.setState({ projectHighlights },
            this.toggleHighlightModal);
    };


    setSelectedHighlights = (highlight) => {
        this.setState({ selectedHighlight: highlight });
    };


    // TABS MANAGER
    onAddNewTab = (tab) => {
        const projectTabs = [...this.state.projectTabs];

        projectTabs.push(tab);

        this.setState({ projectTabs },
            this.toggleTabsModal);
    };


    onDeleteTab = (tab) => {
        const isTabStored = (tab.tabId.toString().indexOf('-') === -1);
        const { projectTabs } = this.state;
        const _projectTabs = [...projectTabs];
        const tabIndex = projectTabs.indexOf(tab);

        _projectTabs.splice(tabIndex, 1);

        if (isTabStored) {
            const { deletedTabs } = this.state;
            const _deletedTabs = [...deletedTabs, tab.tabId];

            this.setState({ deletedTabs: _deletedTabs });
        }

        this.setState({ projectTabs: _projectTabs });
    };


    onPerformTabUpdate = (tab) => {
        const { selectedTab, projectTabs } = this.state;
        const _projectTabs = [...projectTabs];
        const selectedTabIndex = projectTabs.indexOf(selectedTab);

        _projectTabs[selectedTabIndex] = tab;

        this.setState({ projectTabs: _projectTabs },
            this.toggleTabsModal);
    };


    onDeleteProjectFile = (deleteFile) => {
        const { documentId } = deleteFile;
        const isTabStored = (documentId.toString()
            .indexOf('-') === -1);
        const { projectFiles, newProjectFiles, deletedProjectDocs } = this.state;

        const _deletedProjectDocs = [...deletedProjectDocs];
        const _projectFiles = this.deleteFileFromList(projectFiles, documentId, 'documentId');
        const _newProjectFiles = this.deleteFileFromList(newProjectFiles, documentId, 'key');

        if (isTabStored) {
            _deletedProjectDocs.push(documentId);
        }

        this.setState({
            projectFiles: _projectFiles,
            newProjectFiles: _newProjectFiles,
            deletedProjectDocs: _deletedProjectDocs,
        });
    };


    deleteFileFromList = (itemList, objectId, key) => {
        const obj = itemList.find(item => item[key] === objectId);
        const itemListIndex = itemList.indexOf(obj);
        const listCopy = [...itemList];

        listCopy.splice(itemListIndex, 1);

        return listCopy;
    };


    setSelectedTab = (selectedTab) => {
        this.setState({ selectedTab });
    };


    showFloatingSaveButton = () => {
        const hrDivider = document.getElementById('saveBannerDivider');

        if (hrDivider) {
            const { top } = hrDivider.getBoundingClientRect();
            const banner = document.getElementById('saveButtonBanner');

            if (top <= 900) {
                banner.classList.remove('save-banner-button');
                banner.classList.add('save-banner-button-static');
            } else {
                banner.classList.remove('save-banner-button-static');
                banner.classList.add('save-banner-button');
            }
        }
    };


    onSaveFileReorder = (fileList, cleanMethod) => {
        const { projectDetails, updateFileOrdering } = this.props;
        const { projectType } = this.state;
        const { projectID } = projectDetails;

        const newFilePositions = fileList.map(({ documentId }, position) => ({
            documentId,
            position,
        }));

        const reqData = {
            projectType,
            newFilePositions,
        };

        updateFileOrdering(projectID, projectType, reqData, cleanMethod);
    };


    render() {
        const {
            projectHighlights, projectDetails, projectTabs, projectFiles,
        } = this.state;

        return (
            <div className="container">
                <div className="container">
                    <ProjectDetailsComponent
                        projectDetails={projectDetails}
                        projectHighlights={projectHighlights}
                        onAddNewHighlight={this.onAddNewHighlight}
                        onPerformUpdateHighlight={this.onPerformUpdateHighlight}
                        onSelectedHighlight={this.setSelectedHighlights}
                        onDeleteHighlight={this.onDeleteHighlight}

                        projectTabs={projectTabs}
                        onAddNewTab={this.onAddNewTab}
                        onDeleteTab={this.onDeleteTab}
                        projectFiles={projectFiles}
                        onSelectedTab={this.setSelectedTab}
                        onPerformTabUpdate={this.onPerformTabUpdate}
                        onTabInputFilesChange={this.onTabInputFilesChange}
                        onDeleteProjectFile={this.onDeleteProjectFile}

                        onInputChange={this.onInputChange}
                        allCategories={this.props.projectCategories}
                        onCardImageChange={this.onCardImageChange}
                        helloSignTemplates={this.props.helloSignTemplates}
                        cashCallParentProject={this.props.parentProjects}
                        approveProjectID={this.state.projectId}
                        projectType={this.state.projectType}
                        allStatuses={this.props.projectStatuses}
                        onSaveFileReorder={this.onSaveFileReorder}

                        showImportFilesButton
                        isEditMode
                    />
                </div>

                <hr id="saveBannerDivider" />

                <div id="saveButtonBanner" className="container save-banner-button">
                    <div className="col-xs-12 col-sm-8">
                        <h3>{this.state.projectDetails.projectName}</h3>
                    </div>
                    <div className="col-xs-12 col-sm-4">
                        <button
                            className="btn btn-primary btn-lg upper-case-text no-margin"
                            onClick={this.onSaveProject}
                            type="button"
                        >
                            Save Project
                        </button>
                    </div>
                </div>
            </div>
        );
    };
}


const mapStateToProps = _state => ({ user: _state.session.user, ..._state.projectEditor });

const mapDispatchToProps = _dispatch => ({ ...bindActionCreators(Action, _dispatch) });

const connectedComponent = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProjectEditorContainerComponent);

ProjectEditorContainerComponent.propTypes = {
    getAllProject: PropTypes.func.isRequired,
    getProjectInformation: PropTypes.func.isRequired,
    parentProjects: PropTypes.array.isRequired,
    projectCategories: PropTypes.array.isRequired,
    projectStatuses: PropTypes.array.isRequired,
    projectDetails: PropTypes.object.isRequired,
    projectHighlights: PropTypes.array.isRequired,
    projectTabs: PropTypes.array.isRequired,
    storeUpdatedProject: PropTypes.func.isRequired,
    storeNewProjectInvestment: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    helloSignTemplates: PropTypes.array.isRequired,
    getProjectDataSuccess: PropTypes.func.isRequired,
    updateFileOrdering: PropTypes.func.isRequired,
};

ProjectEditorContainerComponent.defaultProps = {
    parentProjects: [],
    projectCategories: [],
    projectStatuses: [],
    projectDetails: {},
    projectHighlights: [],
    projectTabs: [],
    user: {},
    helloSignTemplates: [],
};

export default withRouter(connectedComponent);
