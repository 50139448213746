import { Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';
import { getUUID } from '../../../../utils/General';

const SelectComponent = (props) => {
    const {
        options, selectTitle, name, onChange, value,
        xs, sm, md, lg, selectId, isRequired, disableNone,
    } = props;

    const _selectId = selectId || getUUID();

    const _options = options.map(option => (
        <option
            key={getUUID()}
            value={option.id}
			disabled={option.isDisabled}
        >
            {option.title}
        </option>
    ));

    const requiredInput = isRequired ? <span className="red-text"> *</span> : null;

    let noneValue = !disableNone ? 'none' : undefined;

    if (!value || value === '') noneValue = value;

    return (
        <Col xs={xs} sm={sm} md={md} lg={lg}>
            <div className="form-group">
                <label className="input-label">
                    {selectTitle}
                    {requiredInput}
                </label>
                <select
                    className="form-control input"
                    onChange={onChange}
                    id={_selectId}
                    value={value}
                    name={name}
                >
                    <option
                        value={noneValue}
                        disabled={disableNone}
                        selected
                    >
                        None
                    </option>
                    {_options}
                </select>
                <span id={`error_${_selectId}`} className="text-danger" />
            </div>
        </Col>
    );
};

SelectComponent.propTypes = {
    disableNone: PropTypes.bool,
    isRequired: PropTypes.bool,
    lg: PropTypes.number,
    md: PropTypes.number,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    selectId: PropTypes.any,
    selectTitle: PropTypes.string.isRequired,
    sm: PropTypes.number,
    value: PropTypes.any.isRequired,
    xs: PropTypes.number,
};

SelectComponent.defaultProps = {
    disableNone: true,
    isRequired: false,
    name: '',
    onChange: () => null,
    options: [],
    selectTitle: '',
    value: null,
};

export default SelectComponent;
