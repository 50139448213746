import initialState from '../../../../reducers/appInitialState';
import * as Actions from '../actions/actions';

export default (state = initialState.allInvestments, action) => {
    const {allInvestmentsOnFlightReq} = state;
    const plus = (allInvestmentsOnFlightReq + 1);
    const minus = allInvestmentsOnFlightReq <= 0 ? 0 :
        (allInvestmentsOnFlightReq - 1);

    switch (action.type) {
  
        case Actions.ALL_INVESTMENTS_REQUEST_START:
            return {...state, allInvestmentsOnFlightReq: plus};
        case Actions.ALL_INVESTMENTS_REQUEST_END:
            return {...state, allInvestmentsOnFlightReq: minus};
        case Actions.ALL_INVESTMENTS_REQUEST_SUCCESS:
            return {...state, allInvestmentsOnFlightReq: minus, ...action.data};
        case Actions.ALL_INVESTMENT_ALL_CATEGORIES:
            return {...state, allInvestmentsOnFlightReq: minus, projectCategories: action.data};
            case Actions.CURRENT_VIEW:
               return {
                    ...state,
                    isAdminView:action.data
                };
        default:
            return state;
    }
};
