import React,{useState, useEffect} from 'react';
// import { loadParallel } from '@parallelmarkets/vanilla'
import { useParallel } from '@parallelmarkets/react'
import { getImpersonateID } from '../../utils/General';
import RequestService from '../../api/RequestService';
import fetch from 'cross-fetch'
import swal from 'sweetalert2';
import moment from 'moment';

export const withParallelMarketsHOC = (Component) => {

    return (props) => {
      // const [Entities, setEntities] = useState();
      // const [numEntities, setNumEntities] = useState();
      const testResponse = {
        "accreditations": [
            {
                "assertion_type": "net-worth",
                "certified_at": null,
                "created_at": 1668645416,
                "documents": [],
                "expires_at": null,
                "first_name": "Mano",
                "id": "VXNlckFjY3JlZGl0YXRpb246Mjc5OQ==",
                "last_name": "Samy",
                "status": "pending"
            }
        ],
        "id": "VXNlcjozNjk5",
        "indicated_unaccredited": null,
        "type": "individual",
        "user_id": "VXNlcjozNjk5",
        "user_providing_for": "self"
    }
      const testEntities = [{Address: "12765 W. Goldenbrook Ct.",
      Address2: "",
      City: "Boise",
      ContactPrefSMS: 0,
      Country: "United States",
      DwollaBOVerificationStatus: "",
      DwollaBeneficialOwnerURL: "",
      EntityController: "Self",
      EntityID: 785,
      EntityLabel: "Rich Collins",
      EntityName: "Rich Collins",
      EntityType: "Personal",
      LegalFirstName: "Rich",
      LegalLastName: "",
      Phone: "208-939-3348",
      State: "Idaho",
      UserID: 1021,
      ZipCode: "83713",
      default_gp_lp_election: 2,
      filesUploaded: 0,
      status: "",
      w8n9formVerify: 0}];
      console.log("eeeeeeeeee",moment(new Date(testResponse.accreditations[0]?.expires_at * 1000).toISOString()).format("MM-DD-YYYY"));
        const { parallel, loginStatus } = useParallel();
        console.log("Parallel: ",parallel);
        console.log("Props: ", props);
        console.log("LoginStatus: ",loginStatus);
        
useEffect(() => {
  if(loginStatus?.status === "connected"){
(async () => {
  let url = 'https://api.parallelmarkets.com/v1/accreditations';
    console.log("ttt",loginStatus?.authResponse?.access_token)
const data  = await fetch(url,{method: 'GET', headers: {Authorization: 'Bearer ' + loginStatus?.authResponse?.access_token}});
const response = await data?.json();


console.log("parallel api fetched responce is",response);
if(response?.accreditations?.length > 0){
  const investor = await getInvestorProfile();
const {entities} = await getInvestorEntities();
console.log("entities", entities);
console.log("accreditations", response?.accreditations);
const filteredEntities = await entities?.filter(item => response?.accreditations?.some(o2 => (response?.type == "individual" ? item?.EntityName?.toLowerCase()?.includes(o2?.first_name?.toLowerCase()) : item?.EntityName?.toLowerCase()?.includes(o2?.name?.toLowerCase())) && expiryDays( item?.thirdPartyExpiry ) <= 0 ));
const filteredAccredit = await response?.accreditations?.filter(item => filteredEntities.some(o2 => response?.type == "individual" ? o2?.EntityName.startsWith(item?.first_name) : o2?.EntityName.includes(item?.name)));
console.log("filteredEntities",filteredEntities);
console.log("filteredAccredit",filteredAccredit);

if(filteredEntities.length > 0 && filteredAccredit.length > 0){
  const d1 = await filteredEntities.map(async(item) => {
    let EntityID = item?.EntityID;
    let data = await filteredAccredit.find(x => response?.type == "individual" ? item.EntityName?.toLowerCase()?.startsWith(x.first_name?.toLowerCase()) : item.EntityName?.toLowerCase()?.includes(x.name?.toLowerCase()) )
    if(data && Object.keys(data).length !== 0 ){
    const d2 = await addThirdPartyAccredited( investor?.Email, EntityID,response?.user_id, response?.type, data, loginStatus?.authResponse?.access_token );
    console.log("added parallelmarkets", d2);
    }
  })
  console.log("d1 datas",d1);
}
}

})();
  }
  
  },[loginStatus])
  const expiryDays = (date)=> {
    if(date){
    let date_string = date?.toString();
    var expiration = moment(date_string).format("YYYY-MM-DD");
    var current_date = moment().format("YYYY-MM-DD");
    var days = moment(expiration).diff(current_date, 'days');
    console.log("remaining days",days);
    return days;
    }else{
      return 0
    }
  }
  const getInvestorProfile = async () => {
    try {
        const impersonateID = await getImpersonateID();
        const { data } = await RequestService.get(`/api/getInvestorProfile?impersonateID=${impersonateID}`);

        if (data.code === 200) {
            return data.rows[0];
        }
        else {
            console.log('Could not retrieve your profile. Please try again later.');
        }
    }
    catch (err) {
        console.log(err);
    }
};
  const getInvestorEntities = async () => {
    try {
        const impersonateID = await getImpersonateID();
        const { data } = await RequestService.get(`/api/investor/profile/entities/${impersonateID}`);

        if (data.code === 200) {
            let numEntities = 0;
            data.rows.map(entity => {
                if (entity.EntityID > 0 && entity.EntityController === 'Self')
                    numEntities++;
            });
            // setEntities(data.rows);
            // setNumEntities(numEntities);
            return { entities:data.rows, numEntities:numEntities };
        }
        else {
            alert('Could not get list of entities. Please try again later.');
        }
    }
    catch (err) {
        console.log(err);
    }
};

const addThirdPartyAccredited = async (Email,EntityID,user_id,user_type,data, authToken) => {
  
  const formTemp = [];

  formTemp.push(`Email=${encodeURIComponent(Email)}`);
  formTemp.push(`EntityID=${encodeURIComponent(EntityID)}`);
  formTemp.push(`authToken=${encodeURIComponent(authToken)}`);
  formTemp.push(`userID=${encodeURIComponent(user_id)}`);
  formTemp.push(`userType=${encodeURIComponent(user_type)}`);
  formTemp.push(`AccreditationAssertionType=${encodeURIComponent(data?.assertion_type)}`);
  formTemp.push(`AccreditationCertifiedAt=${encodeURIComponent(!!data?.certified_at ? new Date(data?.certified_at * 1000).toISOString() : null)}`);
  formTemp.push(`AccreditationCreatedAt=${encodeURIComponent( !!data?.created_at ? new Date(data?.created_at * 1000).toISOString() : null)}`);
  formTemp.push(`AccreditationExpiresAt=${encodeURIComponent(!!data?.expires_at ? new Date(data?.expires_at * 1000).toISOString() : null)}`);
  formTemp.push(`AccreditationID=${encodeURIComponent(data?.id)}`);
  formTemp.push(`AccreditationStatus=${encodeURIComponent(data?.status)}`);
  formTemp.push(`AccreditationFirstName=${encodeURIComponent(data?.first_name)}`);
  formTemp.push(`AccreditationLastName=${encodeURIComponent(data?.last_name)}`);
  const formBody = formTemp.join('&');

  try {
      const { data: status } = await RequestService.post({
          url: '/api/AddThirdPartyAccredited',
          data: formBody,
      });

      if (status.code === 200) {
          swal('Accreditation Added successfully', '', 'success');

          
      }
      else {
          alert('Could not Add Accreditation. Please try again later.');
      }
  }
  catch (err) {
      console.log(err);
  }
}
  
      return <Component parallel = {parallel} loginStatus = {loginStatus} {...props} />;
    };
  };