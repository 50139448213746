import PropTypes from 'prop-types'
import React from 'react';
import BaseComponent from "../utils/BaseComponent";

class DateInputComponent extends BaseComponent {
    render = () => {
        const {inputReference, inputName, inputTitle, elementValue, onInputChange, dateType} = this.props;

        return (
            <div className="form-group">
                <label htmlFor={inputName}
                       className="input-label">
                    {inputTitle}{this.getRequired()}
                </label>
                <input id={inputName}
                       onChange={onInputChange}
                       className="form-control input"
                       ref={inputReference}
                       name={inputName}
                       type={dateType}
                       value={elementValue}/>
                <span id={`error_${inputName}`} className="text-danger"/>
            </div>
        );
    };
}

DateInputComponent.propTypes = {
    elementValue: PropTypes.string.isRequired,
    inputName: PropTypes.string.isRequired,
    inputReference: PropTypes.any.isRequired,
    inputTitle: PropTypes.string.isRequired,
    onInputChange: PropTypes.func.isRequired,
    isRequired: PropTypes.bool,
    dateType: PropTypes.string,
};

DateInputComponent.defaultProps = {
    elementValue: '',
    inputName: '',
    inputReference: () => null,
    inputTitle: '',
    onInputChange: () => null,
    isRequired: false,
    dateType: 'datetime-local',
};

export default DateInputComponent;
