import React, {Component} from 'react';
import CKEditor from "react-ckeditor-component";
import {withRouter} from 'react-router-dom';
import '../node_modules/flatpickr/dist/themes/material_green.css';
import Flatpickr from 'react-flatpickr';
import store from './index'
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import sweetAlert from 'sweetalert2'
import axios from 'axios';
import FilePreview from './components/shared/FilePreviewComponent/FilePreviewComponent';
import RequestService from './api/RequestService';
import DealCardDescriptionComponent
    from "./components/shared/dealCardDescriptionComponent/DealCardDescriptionComponent";
import MultilineInputComponent
    from "./components/shared/InputComponents/MultilineInputComponent/MultilineInputComponent";
import SingleLineInputComponent
    from "./components/shared/InputComponents/SingleLineInputComponent/SingleLineInputComponent";
import moment from 'moment';
import Icon from "./components/shared/IconComponent/IconComponent";
import HighlightComponent
    from "./components/shared/ProjectDetailsContainerComponent/components/HiglightComponent/HighlightComponent";
import AddHighlightComponent
    from "./components/shared/ProjectDetailsContainerComponent/components/AddHighlightComponent/AddHighlightComponent";
import {PROJECT_REG_D, PROJECT_UPDATE_CREATE, PROJECT_UPDATE_EDIT} from "./utils/ApplicationConstants";
import AddTabComponent
    from "./components/shared/ProjectDetailsContainerComponent/components/AddTabComponent/AddTabComponent";
import ProjectDetailTabsWrapper
    from "./components/shared/ProjectDetailsContainerComponent/components/ProjectDetailTabsComponent/ProjectDetailTabsWrapper";
import TabComponent
    from "./components/shared/ProjectDetailsContainerComponent/components/ProjectDetailTabsComponent/TabComponent/TabComponent";
import {getUUID} from "./utils/General";
import FilesTabComponent
    from "./components/shared/ProjectDetailsContainerComponent/components/FilesTabComponent/FilesTabComponent";
import ExternalImageContainer from './components/shared/ExternalImageContainerComponent/ExternalImageContainer';


class EditProjectD extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isCashCall: false,
            form: {
                projectName: '',
                nwells: '',
                avgnr: '',
                avgnrma: '',
                totalpp: '',
                wboi: '',
                fperiod: '',
                avgbd: '',
                pdate: '',
                sdate: '',
                targetf: '',
                reserveest: '',
                option2: '',
                disclose: '',
                disclosure: '',
                depth: '',
                operatorc: '',
                prlow: '',
                description: '',
                detailedescription: '',
                dealCardDescription: '',
                FundName: '',
                MinimumUnits: '',
                MaximumUnits: '',
                MinimumProceeds: '',
                MaximumProceeds: '',
                MinUnitsWithManagingGPCarry: '',
                MaxUnitsWithManagingGPCarry: '',
                OperatorLPAgreement: '',
                ManagingGPCarryBPO: '',
                ManagingGPCarryAPO: '',
                templateVersion: 1,
                ProjectStatus: null,
            },
            Deal: [],
            Deal1: [],
            HSDoclist: [],
            parentProjectList: [],
            date: new Date(),
            startdate: new Date(),
            dealCardFiles: {},
            dealCardImages: {},
            pertinentMaps: {},
            afeOperations: {},
            additionalMaterials: {},
            pertinentMapsFiles: {},
            afeOperationsFiles: {},
            additionalMaterialsFiles: {},
            pertinentMapsImages: {},
            afeOperationsImages: {},
            additionalMaterialsImages: {},
            ApproveProjectID: this.props.location.state.ProjectID,
            IsSaved: this.props.location.state.isSaved,
            isLoaded: false,
            errorOnLoad: false,
            id: props.session.id, /*added for block chain*/
            description: '',
            detailedescription: '',
            projectType: PROJECT_REG_D,

            // file management
            dealCard: null,
            dueDiligence: null,
            extraFiles: null,

            // highlights
            isHighlightModalOpen: false,
            highlightModalAction: '',
            selectedHighlight: {},
            projectHighlights: [],
            deletedProjectHighlights: [],

            // tabs
            tabModalAction: '',
            isTabsModalOpen: false,
            selectedTab: {},
            projectTabs: [],
            deletedTabs: [],
            tabsDefaultIndex: 0,

            // files
            projectDocs: [],
            deletedProjectDocs: [],

            // IMAGE URL
            openExternalImageContainer: false,
        };

        this.onCKEditorChange = this.onCKEditorChange.bind(this);
        this.onPublish = this.onPublish.bind(this);
        this.onChangeDescription = this.onChangeDescription.bind(this);
        this.onChangeDetailedDescription = this.onChangeDetailedDescription.bind(this);
        this.fileUploading = this.fileUploading.bind(this);
        this.onChangeFiles = this.onChangeFiles.bind(this);
        this.filesUpload = this.filesUpload.bind(this);
        this.onDeleteFile = this.onDeleteFile.bind(this);

        this.refFundName = React.createRef();
        this.refMinimumUnits = React.createRef();
        this.refMaximumUnits = React.createRef();
        this.refMinimumProceeds = React.createRef();
        this.refMaximumProceeds = React.createRef();
        this.refMinUnitsWithManagingGPCarry = React.createRef();
        this.refMaxUnitsWithManagingGPCarry = React.createRef();
        this.refOperatorLPAgreement = React.createRef();
        this.refManagingGPCarryBPO = React.createRef();
        this.refManagingGPCarryAPO = React.createRef();
    }



	async componentDidMount() {
		window.scrollTo(0, 0);
		store.subscribe(async () => {
			let currentState = await store.getState();
			this.setState({
				id: await currentState.session.user.id
			});
		});

		this.getProjectInvestmentDetailsRegD();
		this.getHelloSignDocList();
		this.getParentProjectList();
	}



    onCKEditorChange() {
        console.log(this.refs.description.value, "CKEDITOR")
    }



    getImage(File) {
        return new Promise(function (resolve, reject) {
            let reader = new FileReader();
            reader.onload = function () {
                resolve(reader.result)
            };
            reader.readAsDataURL(File);
        });
    }



    onDeleteFile(i, type) {
        let data = {};
        switch (type) {
            case 'dealfiles':
                data = Object.values(this.state.dealCard).filter((item, j) => (i != j));
                break;
            case 'maps':
                data = Object.values(this.state.pertinentMaps).filter((item, j) => (i != j));
                break;
            case 'afe':
                data = Object.values(this.state.afeOperations).filter((item, j) => (i != j));
                break;
            case 'addmat':
                data = Object.values(this.state.additionalMaterials).filter((item, j) => (i != j));
                break;

            default:
                break;
        }

        let detail = {};
        if (data != undefined) {
            Object.assign(detail, data);
        }
        this.onChangeFiles({target: ''}, type, detail);
    }



    checkFileType(files, fileType) {
        return new Promise(function (resolve, reject) {
            let dat = {};
            switch (fileType) {
                case 'dealfiles':
                    dat = Object.values(files).filter(function (object) {
                        if ((object['type'] === "image/jpeg") || (object['type'] === "image/png") || (object['type'] === "image/gif")) {
                            return true;
                        } else {
                            console.log('Only images allowed');
                        }
                    });
                    resolve(Object.assign({}, dat));
                    break;
                case 'maps':
                    dat = Object.values(files).filter(function (object) {
                        if ((object['type'] === "image/jpeg") || (object['type'] === "image/png") || (object['type'] === "image/gif")) {
                            return true;
                        } else {
                            console.log('Only images allowed');
                        }
                    });
                    resolve(Object.assign({}, dat));
                    break;
                case 'afe':
                    dat = Object.values(files).filter(function (object) {
                        if ((object['type'] === "application/pdf") || (object['type'] === "application/docx") || (object['type'] === "application/doc") || (object['type'] === "application/txt") ||
                            (object['type'] === "application/xls") || (object['type'] === "application/xlsx")) {
                            return true;
                        } else {
                            console.log('Only Documents allowed');
                        }
                    });
                    resolve(Object.assign({}, dat));
                    break;
                case 'addmat':
                    dat = Object.values(files).filter(function (object) {
                        if ((object['type'] === "application/pdf") || (object['type'] === "application/docx") || (object['type'] === "application/doc") || (object['type'] === "application/txt") || (object['type'] === "image/jpeg") || (object['type'] === "image/png") || (object['type'] === "image/gif")) {
                            return true;
                        } else {
                            console.log('Not allowed this file type ' + object['type']);
                        }
                    });
                    resolve(Object.assign({}, dat));
                    break;
                default:
                    reject({});
                    break;
            }
        });
    }



    async onChangeFiles(e, type = '', records = '-1') {
        let file = await e.target;
        let fileType = await (type == '') ? file.id : type;
        let files = await (records == '-1') ? file.files : records;
        console.log('filetype = ', fileType);
        console.log('files = ', files);
        let data = {};
        this.checkFileType(files, fileType).then((files) => {
            for (let i = 0; i < Object.values(files).length; i++) {
                this.getImage(Object.values(files)[i]).then((image) => {
                    data[i] = image;
                    switch (fileType) {
                        case 'dealfiles':
                            this.setState({
                                dealCardImages: data,
                            });
                            break;
                        case 'maps':
                            this.setState({
                                pertinentMapsImages: data,
                            });
                            break;
                        case 'afe':
                            this.setState({
                                afeOperationsImages: data,
                            });
                            break;
                        case 'addmat':
                            this.setState({
                                additionalMaterialsImages: data,
                            });
                            break;
                        default:
                            console.log('Unknown error');
                            break;
                    }
                });
            }
        });

        switch (fileType) {
            case 'dealfiles':
                await this.checkFileType(files, fileType).then((data) => {
                    this.setState({
                        dealCardFiles: {},
                        dealCardImages: {},
                        dealCard: data,
                    });
                });

                break;
            case 'maps':
                await this.checkFileType(files, fileType).then((data) => {
                    this.setState({
                        pertinentMapsFiles: {},
                        pertinentMapsImages: {},
                        pertinentMaps: data,
                    });
                });

                break;
            case 'afe':
                await this.checkFileType(files, fileType).then((data) => {
                    this.setState({
                        afeOperationsFiles: {},
                        afeOperationsImages: {},
                        afeOperations: data,
                    });
                });
                break;
            case 'addmat':
                await this.checkFileType(files, fileType).then((data) => {
                    this.setState({
                        additionalMaterialsFiles: {},
                        additionalMaterialsImages: {},
                        additionalMaterials: data,
                    });
                });
                break;
            default:
                await console.log('Unknown error');
                break;
        }
    }



    async filesUpload(e) {
        e.preventDefault();
        let fileType = e.target.id;
        var obj = {};
        switch (fileType) {
            case 'dealCard':
                obj = await this.state.dealCard;
                break;
            case 'pertinentMaps':
                obj = await this.state.pertinentMaps;
                break;
            case 'afeOperations':
                obj = await this.state.afeOperations;
                break;
            case 'additionalMaterials':
                obj = await this.state.additionalMaterials;
                break;
            default:
                await console.log('file Upload check error');
                break;
        }
        await console.log(obj);
        await console.log(fileType);
        if (!obj) {
            await alert('Please select files to upload');
            await console.log('Empty data');
        } else {
            console.log('checking...');
            this.fileUploading(obj, fileType);
        }
    }



    async fileUploading(file, fileType) {
        const formData = new FormData();
        const headers = Object.assign({},
            {'Content-Type': 'multipart/form-data'}
        );
        Object.keys(headers)
            .forEach(function (k) {
                if (k.toLowerCase() === 'content-type') delete headers[k]
            });
        console.log(file);
        for (var key in file) {
            if (file.hasOwnProperty(key)) {
                formData.append('data', file[key]);
            }
        }
        formData.append('type', 'regD');

        fetch('/api/uploadRemote', {
            method: 'post',
            /* headers: {'content-type': 'multipart/form-data'}, */
            headers: headers,
            body: formData
        })
            .then((response) => response.json())
            .then((data) => {
                switch (fileType) {
                    case 'dealCard':
                        this.setState({
                            dealCardFiles: data
                        });
                        break;
                    case 'pertinentMaps':
                        this.setState({
                            pertinentMapsFiles: data
                        });
                        break;
                    case 'afeOperations':
                        this.setState({
                            afeOperationsFiles: data
                        });
                        break;
                    case 'additionalMaterials':
                        this.setState({
                            additionalMaterialsFiles: data
                        });
                        break;
                    default:
                        console.log('file Upload check error');
                        break;
                }
                console.log(data, "uploaded data");
            })
    }



    async getHelloSignDocList() {
        try {
            const {data} = await RequestService.get(`/api/getHelloSignDocList/${this.state.id}/${this.props.session.marketing}`);

            if (data.code === 200) {
                this.setState({
                    HSDoclist: data.data.templates,
                    isLoaded: true,
                    err: {},
                    errorOnLoad: false,
                });
            } else {
                alert('Could not get list of HelloSign Documents.');
            }
        } catch (err) {
            console.log(err);
            this.setState({
                err,
                errorOnLoad: true
            });
        }
    }



    async getParentProjectList() {
        try {
            const {data, status} = await RequestService.get('/api/getParentProjectList');

            if (status === 200) {
                this.setState({
                    parentProjectList: data
                });
            }
            else {
                alert('Could not get list of Parent Projects.');
            }
        } catch (err) {
            console.log(err);
        }
    };



    onChangeDescription(evt) {
        const newDescription = evt.editor.getData();
        this.setState({
            description: newDescription
        });
    }



    onChangeDetailedDescription(evt) {
        var newDetailedDescription = evt.editor.getData();
        this.setState({
            detailedescription: newDetailedDescription
        });
    }



    inputChange = async (e) => {
        let property = e.target.id;
        let value = e.target.value;
        let error = '';
        let prev = await this.state.form;
        prev[property] = await value;
        await this.setState({
            form: prev
        });
        error = this.inputValidation(property, value);
        document.getElementById('error_' + property).innerHTML = error;
    };



    inputValidation(property, value) {
        let error = '';
        switch (property) {
            case 'City':
                if (value < 0) {
                    error = 'Please select City';
                }
                break;
            default:
                if (value === '' || value < 0) {
                    error = 'This field is Required';
                }
                break;
        }
        return error;
    }



    fullFormValidation() {
        let checkFields = 0;
        let object = this.state.form;
        for (const key in object) {
            if (object.hasOwnProperty(key)) {
                let error = this.inputValidation(key, object[key]);
                document.getElementById('error_' + key).innerHTML = error;
                if (error != '') {
                    checkFields = 1;
                }
            }
        }

        return checkFields;
    }



    async getProjectInvestmentDetailsRegD() {
		const {data} = await RequestService.get(`/api/getRegD/${this.state.ApproveProjectID}`);

		if (data) {
			let prev = this.state.form;
			prev['projectName'] = data[0].ProjectName;
			prev['avgnrma'] = data[0].TotalPurchasePrice;
			prev['avgbd'] = data[0].FundingPeriod;
			prev['targetf'] = data[0].OperatingCompany;
			prev['PercentofOriginalInvestment'] = data[0].PercentofOriginalInvestment;
			prev['nwells'] = data[0].Location;
			prev['avgnr'] = data[0].FundingGoalmin;
			prev['wi'] = data[0].efCarry;
			prev['totalpp'] = data[0].WorkingInterest;
			prev['wboi'] = data[0].MinInvestment;
			prev['fperiod'] = data[0].FundPeriod;
			prev['reserveest'] = data[0].Entity;
			prev['depth'] = data[0].Entity;
			prev['operatorc'] = data[0].Units;
			prev['pdate'] = data[0].DatePublished;
			prev['sdate'] = data[0].DateInvestmentStart;
			prev['option2'] = data[0].FaqEnabled;
			prev['option3'] = data[0].HelloSignSubscriptionTemplateID;
			prev['disclose'] = data[0].SyncedToHubSpot;
			prev['disclosure'] = data[0].InternalNotes;
			prev['description'] = data[0].Description;
			prev['detailedescription'] = data[0].DetailedDescription;
			prev['FundName'] = data[0].FundName;
			prev['MinimumUnits'] = data[0].MinimumUnits;
			prev['MaximumUnits'] = data[0].MaximumUnits;
			prev['MinimumProceeds'] = data[0].MinimumProceeds;
			prev['MaximumProceeds'] = data[0].MaximumProceeds;
			prev['MinUnitsWithManagingGPCarry'] = data[0].MinUnitsWithManagingGPCarry;
			prev['MaxUnitsWithManagingGPCarry'] = data[0].MaxUnitsWithManagingGPCarry;
			prev['OperatorLPAgreement'] = data[0].OperatorLPAgreement;
			prev['ManagingGPCarryBPO'] = data[0].ManagingGPCarryBPO;
			prev['ManagingGPCarryAPO'] = data[0].ManagingGPCarryAPO;
			prev['dealCardDescription'] = data[0].DealCardDescription;
			prev['templateVersion'] = data[0].TemplateVersion;
			prev['projectStatus'] = data[0].ProjectStatus;

			let html = await data[0].Description;
			let html1 = await data[0].DetailedDescription;

			this.setState({
				Deal1: data[0],
				description: data[0].Description,
				detailedescription: data[0].DetailedDescription,
				form: prev,
				isLoaded: true,
				err: {},
				errorOnLoad: false,
				isCashCall: (data[0].ParentProjectID > 0),
				projectHighlights: data[0].highlights,
				projectTabs: data[0].tabs,
				projectDocs: data[0].projectDocs,
			}, () => {
				this.refs.FundingGoalmax.value = this.state.Deal1.FundingGoalMax;
				this.refs.ProjectName.value = this.state.Deal1.ProjectName;
				this.refs.OperatedBy.value = this.state.Deal1.OperatedBy;
				this.refs.Location.value = this.state.Deal1.Location;
				this.refs.FundingGoalmin.value = this.state.Deal1.FundingGoalmin;
				this.refs.efCarry.value = this.state.Deal1.efCarry;
				this.refs.WorkingInterest.value = this.state.Deal1.WorkingInterest;
				this.refs.MinInvestment.value = this.state.Deal1.MinInvestment;
				this.refs.FundPeriod.value = this.state.Deal1.FundPeriod;
				this.refs.ROI.value = this.state.Deal1.ROI;
				this.refs.CompanyContactInfo.value = this.state.Deal1.CompanyContactInfo;
				this.refs.InternalNotes.value = this.state.Deal1.InternalNotes;
				this.refs.SynchedToHubSpot.value = this.state.Deal1.SyncedToHubSpot;
				this.refs.FaqEnabled.value = this.state.Deal1.FaqEnabled;
				this.refs.entity.value = this.state.Deal1.Entity;
				this.refs.Units.value = this.state.Deal1.Units;
				this.refs.MemberPrice.value = this.state.Deal1.IncrementPrice;
				this.refs.PercentofOriginalInvestment.value = this.state.Deal1.PercentofOriginalInvestment;
				this.refs.FaqEnabled.value = this.state.Deal1.FaqEnabled;
				this.refs.HelloSignSubscriptionTemplateID.value = this.state.Deal1.HelloSignSubscriptionTemplateID;
				// this.refs.SyncedToHubSpot.value = this.state.Deal1.SyncedToHubSpot;
				this.refs.InternalNotes.value = this.state.Deal1.InternalNotes;
			});
		}
		else {
			alert('Error');
		}
    }



    onPublish(e) {
        e.preventDefault();
        e.stopPropagation();
        const paramsContent = {
            ParentProjectID: (this.state.isCashCall ? this.refs.parentProjectID.value : 0),
            UserID: this.state.id,
            ProjectName: this.refs.ProjectName.value,
            Location: this.refs.Location.value,
            FundingGoalmin: this.refs.FundingGoalmin.value,
            FundingGoalmax: this.refs.FundingGoalmax.value,
            efCarry: this.refs.efCarry.value,
            WorkingInterest: this.refs.WorkingInterest.value,
            MinInvestment: this.refs.MinInvestment.value,
            ROI: this.refs.ROI.value,
            FundPeriod: this.refs.FundPeriod.value,
            OperatedBy: this.refs.OperatedBy.value,
            Entity: this.refs.entity.value,
            MemberPrice: this.refs.MemberPrice.value,
            Units: this.refs.Units.value,
            Description: this.state.description,
            ProjectPublishDate: this.state.form.pdate,
            InvestmentStartDate: this.state.form.sdate,
            IsCashCall: this.state.isCashCall,
            DetailedDescription: this.state.detailedescription,
            CompanyContactInfo: this.refs.CompanyContactInfo.value,
            PercentofOriginalInvestment: (this.state.isCashCall ? this.refs.PercentofOriginalInvestment.value : 0),
            FaqEnabled: this.refs.FaqEnabled.value,
            HelloSignTemplate: this.refs.HelloSignTemplate.value,
            SynchedToHubSpot: this.refs.SynchedToHubSpot.value,
            dealCardImage: ((this.state.dealCardFiles.length) ? (this.state.dealCardFiles[0].newPath) : ('/img/image.jpg')),
            InternalNotes: this.refs.InternalNotes.value,
            IsSaved: 0,
            ProjectStatus: this.state.form.projectStatus,
            EditProject: 1,
            DealCardDescription: this.state.form.dealCardDescription,
            FundName: this.state.form.FundName,
            MinimumUnits: this.state.form.MinimumUnits,
            MaximumUnits: this.state.form.MaximumUnits,
            MinimumProceeds: this.state.form.MinimumProceeds,
            MaximumProceeds: this.state.form.MaximumProceeds,
            MinUnitsWithManagingGPCarry: this.state.form.MinUnitsWithManagingGPCarry,
            MaxUnitsWithManagingGPCarry: this.state.form.MaxUnitsWithManagingGPCarry,
            OperatorLPAgreement: this.state.form.OperatorLPAgreement,
            ManagingGPCarryBPO: this.state.form.ManagingGPCarryBPO,
            ManagingGPCarryAPO: this.state.form.ManagingGPCarryAPO,
            TemplateVersion: this.refs.templateVersion.value,
            highlights: this.state.projectHighlights,
            deletedHighlight: this.state.deletedProjectHighlights,
            projectTabs: this.state.projectTabs,
            deletedTabs: this.state.deletedTabs,
            deletedProjectDocs: this.state.deletedProjectDocs,
            Category: this.state.Deal1.CategoryID,
            DwollaAccountUrl: this.state.Deal1.DwollaAccountURL,
        };

        const URL = '/api/updateProjectInvestment_RegDsaved/' + (this.state.ApproveProjectID) +
            '/' + this.state.id + '/' + this.props.session.marketing;

        const formData = new FormData();

        if (this.state.dealCard) {
            const {dealCard} = this.state;
            formData.append('dealCard', dealCard, dealCard.name);
        }
        if (this.state.dueDiligence) {
            const {dueDiligence} = this.state;
            for (let index = 0; index < dueDiligence.length; index++) {
                const name = `dueDiligence${index !== 0 ? index : ''}`;
                formData.append(name, dueDiligence[index], dueDiligence[index].name);
            }
        }
        if (this.state.extraFiles) {
            const {extraFiles} = this.state;
            for (let index = 0; index < extraFiles.length; index++) {
                const name = `extraFiles${index !== 0 ? index : ''}`;
                formData.append(name, extraFiles[index], extraFiles[index].name);
            }
        }

        // adds the parameters recollected from the form to the form data
        // this way all the necessary information is sent to the api
        // and the request is process without having to send the information
        // as query data in the URI
        formData.append('params', JSON.stringify(paramsContent));
        axios.post(URL, formData)
            .then(result => {
                const {status} = result;
                if (status === 200) {
                    sweetAlert("Project updated successfully", "", "success")
                        .then(() => this.props.history.push("/app/EditProjects"));
                } else if (status === 402) {
                    sweetAlert("Access Denied", "", "error");
                } else if (status === 400) {
                    sweetAlert("An error occured", "", "error");
                }
            })
            .catch(() => {
                sweetAlert("Error in the server request", "", "error");
            });
    }

    dealCardImageInputOnChangeHandler = (event) => {
        const {files} = event.target;
        if (files.length > 1) {
            sweetAlert('Error', 'You can only select 1 file');
            return;
        }

        let selectedFile = files[0];
        if (!this.isAcceptedImageType(selectedFile.type)) {
            sweetAlert('Eror:', 'We only accept this image types: JPEG, PNG & GIF');
            return;
        }
        if (!this.isCorrectSize(selectedFile)) {
            sweetAlert('Eror:', 'File is too big. Max file size: 15MB');
            return;
        }

        this.setState({
            dealCard: selectedFile,
        })
    };

    multipleDocumentUploadHandler = (event, target, validateType = true) => {
        let _target = this.state[target];
        _target = _target || [];

        const {files} = event.target;
        const acceptedFiles = Object.keys(files)
            .map(key => files[key])
            .filter(file => this.isAcceptedDocument(file.type, validateType))
            .filter(file => this.isCorrectSize(file));

        this.setState({[target]: [..._target, ...acceptedFiles]})
    };

    isAcceptedImageType = (fileType) => {
        const ACCEPTED_IMAGES = ["image/jpeg", "image/png", "image/gif"];
        return ACCEPTED_IMAGES.some(type => type === fileType);
    };

    isAcceptedDocument = (docType, validateType) => {
        const ACCEPTED_DOCS = ["application/pdf", "application/docx", "application/doc", "application/txt", "application/xls", "application/xlsx"];
        if (validateType) return ACCEPTED_DOCS.some(type => type === docType);
        return true;
    };

    deleteSelectedFile = (file, target) => {
        const _target = this.state[target];
        let newTarget = null;

        if (Array.isArray(_target)) {
            newTarget = _target.filter(_file => _file !== file);
        }

        this.setState({[target]: newTarget})
    };

    isCorrectSize = (file) => {
        const MAX_MB_PER_FILE = 15;
        const fileSize = file.size;
        const result = parseInt(((fileSize / 1024) / 1000));

        return (MAX_MB_PER_FILE >= result)
    };

    setContentAfterLoad = (referenceKey, value) => {
        const {id} = this.refs[referenceKey].editorInstance;
        const instances = window.CKEDITOR.instances;
        const result = Object.keys(instances)
            .map(key => instances[key])
            .filter(instance => instance.id === id)[0];

        result.document.$.getElementsByTagName("body")[0].innerHTML = value;
    };

    onSelectChangeChandler = (event) => {
        const {name, value} = event.target;
        const newForm = {...this.state.form};
        newForm[name] = value;

        this.setState({form: newForm});
    };

    // HIGHLIGHT MANAGEMENT
    renderHighlight = () => {
        const {projectHighlights} = this.state;

        return projectHighlights
            .sort((h1, h2) => h1.highlightOrder - h2.highlightOrder)
            .map(highlight => <HighlightComponent key={highlight.highlightId}
                                                  onDeleteHighlight={this.onDeleteHighlight}
                                                  onUpdateHighlight={this.onUpdateHighlight}
                                                  highlight={highlight}
                                                  showActions/>);
    };

    onDeleteHighlight = (highlight) => {
        const isStored = (highlight.highlightId.toString().toLowerCase().indexOf('-') === -1);

        if (isStored) {
            const {deletedProjectHighlights} = this.state;
            const _deletedHighlights = [...deletedProjectHighlights, highlight.highlightId];
            this.setState({deletedProjectHighlights: _deletedHighlights});
        }

        this.removeHighlightFromList(highlight);
    };

    removeHighlightFromList = (selectedHighlight) => {
        const {projectHighlights} = this.state;
        const _projectHighlights = [...projectHighlights];
        const highlightIndex = projectHighlights.indexOf(selectedHighlight);

        _projectHighlights.splice(highlightIndex, 1);
        this.setState({projectHighlights: _projectHighlights});
    };

    onPerformUpdateHighlight = (highlight) => {
        const {projectHighlights, selectedHighlight} = this.state;
        const _projectHighlights = [...projectHighlights];
        const selectedHighlightIndex = projectHighlights.indexOf(selectedHighlight);
        _projectHighlights[selectedHighlightIndex] = highlight;

        this.setState({projectHighlights: _projectHighlights},
            this.toggleHighlightModal);
    };

    onUpdateHighlight = (selectedHighlight) => {
        this.setState({selectedHighlight},
            () => this.toggleHighlightModal(PROJECT_UPDATE_EDIT));
    };

    toggleHighlightModal = (highlightModalAction) => {
        let {isHighlightModalOpen, selectedHighlight} = this.state;
        isHighlightModalOpen = !isHighlightModalOpen;

        if (!isHighlightModalOpen)
            selectedHighlight = {};

        this.setState({
            isHighlightModalOpen,
            highlightModalAction,
            selectedHighlight
        });
    };

    onAddNewHighlight = (highlight) => {
        const projectHighlights = [...this.state.projectHighlights];
        projectHighlights.push(highlight);

        this.setState({projectHighlights},
            this.toggleHighlightModal)
    };

    // TAB MANAGER
    toggleTabsModal = (tabModalAction) => {
        let {isTabsModalOpen, selectedTab} = this.state;
        isTabsModalOpen = !isTabsModalOpen;

        if (!isTabsModalOpen)
            selectedTab = {};

        this.setState({
            tabModalAction,
            isTabsModalOpen,
            selectedTab,
        });
    };

    onAddNewTab = (tab) => {
        const projectTabs = [...this.state.projectTabs];
        projectTabs.push(tab);

        this.setState({projectTabs},
            this.toggleTabsModal)
    };

    onDeleteTab = (tab) => {
        const isTabStored = (tab.tabId.toString().indexOf('-') === -1);

        const {projectTabs} = this.state;
        const _projectTabs = [...projectTabs];
        const tabIndex = projectTabs.indexOf(tab);
        _projectTabs.splice(tabIndex, 1);

        if (isTabStored) {
            const {deletedTabs} = this.state;
            const _deletedTabs = [...deletedTabs, tab.tabId];
            this.setState({deletedTabs: _deletedTabs});
        }

        this.setState({projectTabs: _projectTabs});
    };

    onUpdateTab = (selectedTab) => {
        this.setState({selectedTab},
            () => this.toggleTabsModal(PROJECT_UPDATE_EDIT));
    };

    onPerformTabUpdate = (tab) => {
        const {selectedTab, projectTabs} = this.state;
        const _projectTabs = [...projectTabs];
        const selectedTabIndex = projectTabs.indexOf(selectedTab);
        _projectTabs[selectedTabIndex] = tab;

        this.setState({projectTabs: _projectTabs},
            this.toggleTabsModal);
    };

    renderProjectTabs = () => {
        const {projectTabs, projectDocs} = this.state;
        const tabs = projectTabs
            .sort((t1, t2) => t1.tabOrder - t2.tabOrder)
            .map(tab => (<TabComponent key={tab.tabId}
                                       onDeleteTab={this.onDeleteTab}
                                       onUpdateTab={this.onUpdateTab}
                                       showActions
                                       tab={tab}
                                       {...tab}
			/>));

        if (projectDocs && projectDocs.length) {
            const filesTab = (<FilesTabComponent filesList={projectDocs}
                                                 onDeleteFile={this.onDeleteStoredFile}
                                                 isEditMode/>);
            tabs.push(filesTab)
        }

        return (<ProjectDetailTabsWrapper defaultTabIndex={this.state.tabsDefaultIndex}
                                          setDefaultIndex={this.setTabsDefaultIndex}
                                          children={tabs}/>);
    };

    onDeleteStoredFile = (deletedFile) => {
        const {projectDocs, deletedProjectDocs} = this.state;
        const _projectDocs = [...projectDocs];
        const _deletedProjectDocs = [...deletedProjectDocs];
        const projectDocsIndex = projectDocs.indexOf(deletedFile);

        _deletedProjectDocs.push(deletedFile.documentId);
        _projectDocs.splice(projectDocsIndex, 1);

        this.setState({
            projectDocs: _projectDocs,
            deletedProjectDocs: _deletedProjectDocs
        });
    };

    toggleExternalImageContainer = () => {
        this.setState({openExternalImageContainer: !this.state.openExternalImageContainer});
    };

    setTabsDefaultIndex = (tabsDefaultIndex) => {
        this.setState({tabsDefaultIndex});
    };

    render() {
        const showAddNewHighlightButton = (this.state.projectHighlights.length < 4);
        const showAddNewTabButton = (this.state.projectTabs.length < 8);
        const emptyTabsMessage = (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <h3>No tabs registered for this project</h3>
            </div>
        );

        return (
            <div className="container body">
                <div className="main_container">
                    <div className="right_col" role="main">
                        <div className="panel panel-primary">
                            <div className="panel-heading">
                                <h3 className="panel-title">Submit Your Project (not a binding offer) </h3>
                            </div>

                            <div className="col-xs-12">
                                <div className="pull-right">
                                    <button className="btn btn-primary capitalize-text margin-10-px"
                                            onClick={this.toggleExternalImageContainer}>
                                        Image Manager
                                    </button>
                                </div>
                            </div>

                            <div className="col-xs-12">
                                <ExternalImageContainer closeModal={this.toggleExternalImageContainer}
                                                        openModal={this.state.openExternalImageContainer}/>
                            </div>

                            <div className="panel-body">
                                <form>
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                        <div className="form-group">

                                            <label htmlFor="projectName">ProjectName *</label>
                                            <input type="text" id="projectName" name="projectName" ref="ProjectName"
                                                   className="form-control "
                                                   onBlur={(e) => this.inputChange(e)}
                                                   onChange={(e) => this.inputChange(e)}/>
                                            <span id="error_projectName" className="text-danger"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="nwells">Location *</label>
                                            <input id="nwells" name="nwells" className="form-control" type="text"
                                                   ref="Location"
                                                   onBlur={(e) => this.inputChange(e)}
                                                   onChange={(e) => this.inputChange(e)}/>
                                            <span id="error_nwells" className="text-danger"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="option3">Project Status *</label>

                                            <select className="form-control"
                                                    onChange={this.onSelectChangeChandler}
                                                    value={this.state.form.projectStatus}
                                                    ref="ProjectStatus"
                                                    name="projectStatus"
                                                    id="option3">
                                                <option value="none" disabled selected>None</option>
                                                <option value="Asset Sold">Asset Sold</option>
                                                <option value="Completion">Completion</option>
                                                <option value="Confidential">Confidential</option>
                                                <option value="Drilling Soon">Drilling Soon</option>
                                                <option value="Drilling">Drilling</option>
                                                <option value="Dry Hole">Dry Hole</option>
                                                <option value="Pending">Pending</option>
                                                <option value="Producing">Producing</option>
                                                <option value="Unassign">Unassign</option>
                                            </select>
                                            <span id="error_option3" className="text-danger"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="option3">Project Template Version *</label>
                                            <select className="form-control"
                                                    onChange={this.onSelectChangeChandler}
                                                    value={this.state.form.templateVersion}
                                                    name="templateVersion"
                                                    ref="templateVersion"
                                                    id="option4">
                                                <option value="none" disabled selected>None</option>
                                                <option value={1}>Version 1</option>
                                                <option value={2}>Version 2</option>
                                            </select>
                                            <span id="error_option4" className="text-danger"/>
                                        </div>
                                        {/*<div className="form-group">
                                            <label htmlFor="gconcept">Deal Card Description *</label>
                                            <span id="error_description" className="text-danger"/>
                                            <div className='editor_container'
                                                 style={{
                                                     border: '1px solid #ccc',
                                                     padding: '10px',
                                                     maxHeight: '800px',
                                                     overflowY: 'scroll'
                                                 }}>
                                                <textarea className="form-control" name="" id="" rows="8" />
                                            </div>
                                        </div>*/}
                                        <DealCardDescriptionComponent maxCharCount={500}
                                                                      value={this.state.form.dealCardDescription}
                                                                      onInputChange={this.inputChange}/>
                                        <div className="form-group">
                                            <label htmlFor="gconcept">Description *</label>
                                            <span id="error_description" className="text-danger"/>
                                            <div className='editor_container'
                                                 style={{
                                                     border: '1px solid #ccc',
                                                     padding: '10px',
                                                     maxHeight: '800px',
                                                     overflowY: 'scroll'
                                                 }}>
                                                <CKEditor activeClass="p10"
                                                          content={this.state.description}
                                                          ref="editProjectDDescription"
                                                          events={{
                                                              "change": this.onChangeDescription,
                                                              "instanceReady": () => this.setContentAfterLoad("editProjectDDescription", this.state.description)
                                                          }}/>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="estpaytime">Detailed Description </label>
                                            <span id="error_detailedescription" className="text-danger"/>
                                            <div className='editor_container'
                                                 style={{
                                                     border: '1px solid #ccc',
                                                     padding: '10px',
                                                     maxHeight: '800px',
                                                     overflowY: 'scroll'
                                                 }}>
                                                <CKEditor activeClass="p10"
                                                          content={this.state.detailedescription}
                                                          ref="editProjectDDetails"
                                                          events={{
                                                              "change": this.onChangeDetailedDescription,
                                                              "instanceReady": () => this.setContentAfterLoad("editProjectDDetails", this.state.detailedescription)
                                                          }}/>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="avgnr">Funding Goal Min*</label>
                                            <input id="avgnr" name="avgnr" className="form-control" type="text"
                                                   ref="FundingGoalmin"
                                                   onBlur={(e) => this.inputChange(e)}
                                                   onChange={(e) => this.inputChange(e)}/>
                                            <span id="error_avgnr" className="text-danger"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="avgnrma">Funding Goal Max*</label>
                                            <input id="avgnrma" name="avgnr" className="form-control" type="text"
                                                   ref="FundingGoalmax"
                                                   onBlur={(e) => this.inputChange(e)}
                                                   onChange={(e) => this.inputChange(e)}/>
                                            <span id="error_avgnrma" className="text-danger"/>
                                        </div>
                                        <div>
                                            <div className="form-group">
                                                <label className=" control-label">Is Cash Call
                                                    : &nbsp;&nbsp;&nbsp;</label>
                                                <input type="checkbox" name="isCashCall" id="isCashCall"
                                                       ref="isCashCall"
                                                       checked={this.state.isCashCall} defaultValue={1}
                                                       onClick={() => {
                                                           this.setState({isCashCall: !this.state.isCashCall})
                                                       }}
												/>
                                                <span id="error_isCashCall" className="text-danger"/>
                                            </div>

											<div className="form-group " id="parentProjectIDDiv" style={this.state.isCashCall ? {display: ''} : {display : 'none'}}>
												<label>Select Parent Project</label>
												<div style={{paddingTop: 7}}>
													<select className="form-control"
															name="parentProjectID" ref="parentProjectID"
															id="parentProjectID">
														<option value={0}>Select Parent Project
															Investment
														</option>
														{
															this.state.parentProjectList.map((project) => (
																<option value={project.ProjectID}
																		selected={project.ProjectID === this.state.Deal1.ParentProjectID}>{project.ProjectName}</option>
															))
														}
													</select>
													<span id="error_parentProjectID"
														  className="text-danger"/>
												</div>
												<br/>
												<div className="form-group">
													<label htmlFor="cc">Cash Call Investment %</label>
													<input type="text" id="cc" name="cc"
														   ref="PercentofOriginalInvestment"
														   className="form-control"/>
													<span id="error_cc" className="text-danger"/>
												</div>
											</div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="wi">efCarry</label>
                                            <input type="text" id="wi" name="wi" ref="efCarry"
                                                   className="form-control"/>
                                            <span id="error_wi" className="text-danger"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="totalpp">WorkingInterest *</label>
                                            <input id="totalpp" name="totalpp" className="form-control"
                                                   ref="WorkingInterest" type="text"
                                                   onBlur={(e) => this.inputChange(e)}
                                                   onChange={(e) => this.inputChange(e)}/>
                                            <span id="error_totalpp" className="text-danger"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="wboi">MinInvestment *</label>
                                            <input type="text" id="wboi" name="wboi" ref="MinInvestment"
                                                   className="form-control"
                                                   onBlur={(e) => this.inputChange(e)}
                                                   onChange={(e) => this.inputChange(e)}/>
                                            <span id="error_wboi" className="text-danger"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="fperiod">ROI *</label>
                                            <input id="fperiod" name="fperiod" className="form-control" type="text"
                                                   ref="ROI"
                                                   onBlur={(e) => this.inputChange(e)}
                                                   onChange={(e) => this.inputChange(e)}/>
                                            <span id="error_fperiod" className="text-danger"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="avgbd">FundPeriod *</label>
                                            <input id="avgbd" ref="FundPeriod" name="avgbd" className="form-control"
                                                   type="text"
                                                   onBlur={(e) => this.inputChange(e)}
                                                   onChange={(e) => this.inputChange(e)}/>
                                            <span id="error_avgbd" className="text-danger"/>
                                        </div>
                                        <div className="form-group">
                                            <label className="control-label">Project Publish Date *</label>
                                            <Flatpickr data-enable-time options={{enableSeconds: true}} id="pdate"
                                                       ref="ProjectPublishDate"
                                                       value={this.state.Deal1.DatePublished}
                                                       onChange={date => {
                                                           const prev = this.state.form;
                                                           prev['pdate'] = moment(date[0]).format('YYYY-MM-DD HH:mm:ssZ');
                                                           this.setState({form: prev});
                                                       }}/>
                                            <span id="error_pdate" className="text-danger"/>
                                        </div>
                                        <div className="form-group">
                                            <label className="control-label">Investment Start Date *</label>
                                            <Flatpickr data-enable-time options={{enableSeconds: true}} id="sdate"
                                                       ref="InvestmentStartDate"
                                                       value={this.state.Deal1.DateInvestmentStart}
                                                       onChange={date => {
                                                           let prev = this.state.form;
                                                           prev['sdate'] = moment(date[0]).format('YYYY-MM-DD HH:mm:ssZ');
                                                           this.setState({form: prev})
                                                       }}/> <span id="error_sdate" className="text-danger"/>
                                            <span id="error_sdate" className="text-danger"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="targertf"> Operated By *</label>
                                            <input type="text" id="targetf" name="targetf" ref="OperatedBy"
                                                   className="form-control"
                                                   onBlur={(e) => this.inputChange(e)}
                                                   onChange={(e) => this.inputChange(e)}/>
                                            <span id="error_targetf" className="text-danger"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="reserveest">Entity *</label>
                                            <input id="reserveest" name="reserveest" className="form-control"
                                                   ref="entity" type="text"
                                                   onBlur={(e) => this.inputChange(e)}
                                                   onChange={(e) => this.inputChange(e)}/>
                                            <span id="error_reserveest" className="text-danger"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="depth">MemberPrice</label>
                                            <input id="depth" name="depth" className="form-control" type="text"
                                                   ref="MemberPrice"
                                                   onBlur={(e) => this.inputChange(e)}
                                                   onChange={(e) => this.inputChange(e)}/>
                                            <span id="error_depth" className="text-danger"/>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="operatorc"> Units </label>
                                            <input type="text" id="operatorc" name="operatorc" ref="Units"
                                                   className="form-control"
                                                   onBlur={(e) => this.inputChange(e)}
                                                   onChange={(e) => this.inputChange(e)}/>
                                            <span id="error_operatorc" className="text-danger"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="prlow">CompanyContactInfo</label>
                                            <input type="text" id="prlow" name="prlow" ref="CompanyContactInfo"
                                                   className="form-control"
                                                   onBlur={(e) => this.inputChange(e)}
                                                   onChange={(e) => this.inputChange(e)}/>
                                            <span id="error_prlow" className="text-danger"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="option2">FaqEnabled*</label>
                                            <input id="option2" name="option2" className="form-control" type="text"
                                                   ref="FaqEnabled"
                                                   onBlur={(e) => this.inputChange(e)}
                                                   onChange={(e) => this.inputChange(e)}/>
                                            <span id="error_option2" className="text-danger"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="option3">HelloSignSubscriptionTemplateId *</label>

                                            <select className="form-control" ref="HelloSignSubscriptionTemplateID" id="option3"
                                                    onBlur={(e) => this.inputChange(e)}
                                                    onChange={(e) => this.inputChange(e)}>
                                                <option value={-1}>Please select a HelloSign template for the User To
                                                    Sign
                                                </option>
                                                {
                                                    this.state.HSDoclist.map((Doc, i) => (
                                                        <option key={i}
                                                                selected={Doc.template_id === this.state.Deal1.HelloSignSubscriptionTemplateID}
                                                                value={Doc.template_id}
                                                                style={{color: 'black'}}>{Doc.title}</option>
                                                    ))
                                                }
                                            </select>
                                            <span id="error_option3" className="text-danger"/>
                                        </div>

                                        <MultilineInputComponent inputName="FundName"
                                                                 inputReference={this.refFundName}
                                                                 inputTitle="Fund Name"
                                                                 maxInputLength={250}
                                                                 onInputUpdate={this.inputChange}
                                                                 elementValue={this.state.form.FundName}
                                                                 rows={2}/>

                                        <SingleLineInputComponent inputName="MinimumUnits"
                                                                  inputReference={this.refMinimumUnits}
                                                                  inputTitle="Minimum Units"
                                                                  maxInputLength={20}
                                                                  elementValue={this.state.form.MinimumUnits}
                                                                  onInputUpdate={this.inputChange}/>

                                        <SingleLineInputComponent inputName="MaximumUnits"
                                                                  inputReference={this.refMaximumUnits}
                                                                  inputTitle="Maximum Units"
                                                                  maxInputLength={20}
                                                                  elementValue={this.state.form.MaximumUnits}
                                                                  onInputUpdate={this.inputChange}/>

                                        <SingleLineInputComponent inputName="MinimumProceeds"
                                                                  inputReference={this.refMinimumProceeds}
                                                                  inputTitle="Minimum Proceeds"
                                                                  maxInputLength={20}
                                                                  elementValue={this.state.form.MinimumProceeds}
                                                                  onInputUpdate={this.inputChange}/>

                                        <SingleLineInputComponent inputName="MaximumProceeds"
                                                                  inputReference={this.refMaximumProceeds}
                                                                  inputTitle="Maximum Proceeds"
                                                                  maxInputLength={20}
                                                                  elementValue={this.state.form.MaximumProceeds}
                                                                  onInputUpdate={this.inputChange}/>

                                        <SingleLineInputComponent inputName="MinUnitsWithManagingGPCarry"
                                                                  inputReference={this.refMinUnitsWithManagingGPCarry}
                                                                  inputTitle="Minimum Units with Managing GP Carry"
                                                                  maxInputLength={20}
                                                                  elementValue={this.state.form.MinUnitsWithManagingGPCarry}
                                                                  onInputUpdate={this.inputChange}/>

                                        <SingleLineInputComponent inputName="MaxUnitsWithManagingGPCarry"
                                                                  inputReference={this.refMaxUnitsWithManagingGPCarry}
                                                                  inputTitle="Maximum Units with Managing GP Carry"
                                                                  maxInputLength={20}
                                                                  elementValue={this.state.form.MaxUnitsWithManagingGPCarry}
                                                                  onInputUpdate={this.inputChange}/>

                                        <MultilineInputComponent inputName="OperatorLPAgreement"
                                                                 inputReference={this.refOperatorLPAgreement}
                                                                 inputTitle="Operator for LP Agreement"
                                                                 maxInputLength={250}
                                                                 onInputUpdate={this.inputChange}
                                                                 elementValue={this.state.form.OperatorLPAgreement}
                                                                 rows={2}/>

                                        <SingleLineInputComponent inputName="ManagingGPCarryBPO"
                                                                  inputReference={this.refManagingGPCarryBPO}
                                                                  inputTitle="Managing GP Carry BPO"
                                                                  maxInputLength={20}
                                                                  elementValue={this.state.form.ManagingGPCarryBPO}
                                                                  onInputUpdate={this.inputChange}/>

                                        <SingleLineInputComponent inputName="ManagingGPCarryAPO"
                                                                  inputReference={this.refManagingGPCarryAPO}
                                                                  inputTitle="Managing GP Carry APO"
                                                                  maxInputLength={20}
                                                                  elementValue={this.state.form.ManagingGPCarryAPO}
                                                                  onInputUpdate={this.inputChange}/>

                                        <div className="container form-group">
                                            <label className="pull-left">Highlights</label>
                                            {showAddNewHighlightButton &&
                                            <button type="button"
                                                    onClick={() => this.toggleHighlightModal(PROJECT_UPDATE_CREATE)}
                                                    className="btn btn-primary btn-sm pull-right">
                                                <Icon iconName="plus" extraClasses="white-text"/> Add Highlight
                                            </button>}
                                        </div>

                                        <div className="form-group" style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            flexWrap: 'wrap',
                                        }}>
                                            {this.renderHighlight()}
                                        </div>


                                        <div className="container form-group">
                                            <label className="pull-left">Tabs</label>
                                            {showAddNewTabButton &&
                                            <button type="button"
                                                    onClick={() => this.toggleTabsModal(PROJECT_UPDATE_CREATE)}
                                                    className="btn btn-primary btn-sm pull-right">
                                                <Icon iconName="plus" extraClasses="white-text"/> Add new tab
                                            </button>}
                                        </div>

                                        <AddTabComponent actionType={this.state.tabModalAction}
                                                         tabId={this.state.selectedTab.tabId}
                                                         isModalOpen={this.state.isTabsModalOpen}
                                                         onAddNewTab={this.onAddNewTab}
                                                         onCloseModal={this.toggleTabsModal}
                                                         onUpdateTab={this.onPerformTabUpdate}
                                                         projectId={this.state.ApproveProjectID}
                                                         projectType={this.state.projectType}
                                                         selectedTab={this.state.selectedTab}/>

                                        <div className="container form-group">
                                            {!this.state.projectTabs.length && emptyTabsMessage}
                                            {this.state.projectTabs.length && this.renderProjectTabs()}
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="disclose"> SynchedToHubSpot *</label>
                                            <input id="disclose" name="disclose" className="form-control" type="text"
                                                   ref="SynchedToHubSpot"
                                                   onBlur={(e) => this.inputChange(e)}
                                                   onChange={(e) => this.inputChange(e)}/>
                                            <span id="error_disclose" className="text-danger"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="disclosure">InternalNotes*</label>
                                            <input id="disclosure" name="disclosure" className="form-control"
                                                   type="text" ref="InternalNotes"
                                                   onBlur={(e) => this.inputChange(e)}
                                                   onChange={(e) => this.inputChange(e)}/>
                                            <span id="error_disclosure" className="text-danger"/>
                                        </div>
                                        <div className="container">
                                            <div className="panel panel-default">
                                                <div className="panel-heading1 ph">
                                                    Deal Card Image
                                                </div>
                                                <div className="panel-body">
                                                    <div className="upload-btn-wrapper">
                                                        <button className="btn btn-primary">
                                                            <span><span className="fa fa-plus-circle"/> Select one file</span>
                                                        </button>
                                                        <input type="file" id="dealfiles" name="mapfiles"
                                                               onChange={this.dealCardImageInputOnChangeHandler}/>
                                                    </div>

                                                    { // this shows the details of the selected files
                                                        this.state.dealCard &&
                                                        <FilePreview fileList={[this.state.dealCard]}
                                                                     deleteFile={this.deleteSelectedFile}
                                                                     target="dealCard"/>
                                                    }

                                                    <div style={{fontSize: 11}}><em> *All uploaded files need to be 15MB
                                                        or less.</em></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container">
                                            <div className="panel panel-default">
                                                <div className="panel-heading1 ph">
                                                    Due Diligence Documents
                                                </div>
                                                <div className="panel-body">
                                                    <div className="upload-btn-wrapper">
                                                        <button className="btn btn-primary">
                                                            <span><span
                                                                className="fa fa-plus-circle"/> Add files...</span>
                                                        </button>
                                                        <input type="file"
                                                               multiple="multiple"
                                                               onChange={(event) =>
                                                                   this.multipleDocumentUploadHandler(event, 'dueDiligence')}/>
                                                    </div>

                                                    { // this shows the details of the selected files
                                                        this.state.dueDiligence &&
                                                        this.state.dueDiligence.length !== 0 &&
                                                        <FilePreview fileList={this.state.dueDiligence}
                                                                     deleteFile={this.deleteSelectedFile}
                                                                     target="dueDiligence"/>
                                                    }

                                                    <div style={{fontSize: 11}}><em> *All uploaded files need to be 15MB
                                                        or less.</em></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container">
                                            <div className="panel panel-default">
                                                <div className="panel-heading1 ph">
                                                    Please also include any additional materials you would like us to
                                                    have.
                                                </div>
                                                <div className="panel-body">
                                                    <div className="upload-btn-wrapper">
                                                        <button className="btn btn-primary">
                                                            <span><span
                                                                className="fa fa-plus-circle"/> Add files...</span>
                                                        </button>
                                                        <input type="file"
                                                               multiple="multiple"
                                                               onChange={(event) =>
                                                                   this.multipleDocumentUploadHandler(event, 'extraFiles', false)}/>
                                                    </div>

                                                    { // this shows the details of the selected files
                                                        this.state.extraFiles &&
                                                        this.state.extraFiles.length !== 0 &&
                                                        <FilePreview fileList={this.state.extraFiles}
                                                                     deleteFile={this.deleteSelectedFile}
                                                                     target="extraFiles"/>
                                                    }

                                                    <div style={{fontSize: 11}}><em> *All uploaded files need to be 15MB
                                                        or less.</em></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                                <AddHighlightComponent isModalOpen={this.state.isHighlightModalOpen}
                                                       actionType={this.state.highlightModalAction}
                                                       highlightId={this.state.selectedHighlight.highlightId}
                                                       selectedHighlight={this.state.selectedHighlight}
                                                       onAddNewHighlight={this.onAddNewHighlight}
                                                       onCloseModal={this.toggleHighlightModal}
                                                       onUpdateHighlight={this.onPerformUpdateHighlight}
                                                       projectId={this.state.ApproveProjectID}
                                                       projectType={this.state.projectType}/>

                                <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                    <button type="Submit" onClick={this.onPublish}
                                            className="btn btn-primary pull-right">Update Project
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const {object, bool} = PropTypes;

EditProjectD.propTypes = {
    session: object.isRequired,
    authenticated: bool.isRequired,
    checked: bool.isRequired
};

const mapState = ({session}) => ({
    session: session.user,
    checked: session.checked,
    authenticated: session.authenticated
});

export default withRouter(connect(mapState)(EditProjectD));
