import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Bar, Line } from "react-chartjs-2";
import * as IDActions from "./actions/actions";
import InvestorOverviewContainer from "../../shared/AllInvestmentsContainerComponent/InvestorOverviewContainer";
import InvestorEarningsContainer from "../../shared/AllInvestmentsContainerComponent/InvestorEarningsContainer";
import InvestorMembershipsContainer from "../../shared/AllInvestmentsContainerComponent/InvestorMembershipContainer";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import InvestorTransactionsContainer from "../../shared/AllInvestmentsContainerComponent/InvestorTransactionsContainer";
import InvestorMessagesContainer from "../../shared/AllInvestmentsContainerComponent/InvestorMessagesContainer";
import InvestorW9Container from "../../shared/AllInvestmentsContainerComponent/InvestorW9Container";
import InvestorFilesContainerComponent from "../../shared/InvestorFilesContainerComponent/InvestorFilesContainerComponent";
import RequestService from '../../../api/RequestService';
import InvestmentChart from "./InvestmentChart";
import InvestorEarningsChart from "./InvestorEarningsChart";
import InvestorNotifications from "../InvestorNotifications";
import InvestmentOpportunities from "../InvestmentOpportunities";
import NewUserDashboard from './NewUsersDashboard'
import { getImpersonateID  } from "../../../utils/General";
import { Link } from "react-router-dom";

class InvestorDashboardContainerComponent extends React.Component {
	ITEMS_PER_PAGE = 5;
	constructor(props) {
		super(props);
		this.state = {
			isCumulativeDataVisible: true,
			tabIndex: 0,
			wnVerified: false,
			investorEntities:[],
			isAdmin:false,
		
		};
	}
	componentDidMount() {
		const {
			getInvestorEarnings,
			investorId,
			getAllUserInvestments,
			getDashboardData,
		
		} = this.props;
		getDashboardData();
	
		getInvestorEarnings(investorId);
		getAllUserInvestments(investorId);
		this.getInvestorEntities();
		this.getRoles();
	
	}
	componentWillUnmount() {
		const { resetDashboardData } = this.props;
		resetDashboardData();

	}
	getBarChartDataConfig = (labels, data, title) => ({
		labels,
		datasets: [
			{
				label: title,
				data,
				backgroundColor: "rgba(126, 52, 153, .45)",
				borderColor: "#602a7b",
				pointBorderWidth: 2,
				beginAtZero: true,
			},
		],
	});
	getChartOptions = (xAxesLabel, yAxesLabel, others = {}) => ({
		maintainAspectRatio: false,
		scales: {
			yAxes: [
				{
					scaleLabel: {
						display: true,
						labelString: yAxesLabel,
					},
					ticks: {
						suggestedMin: 0,
					},
				},
			],
			xAxes: [
				{
					scaleLabel: {
						display: true,
						labelString: xAxesLabel,
					},
				},
			],
		},
		...others,
	});
	toggleCumulativeData = () => {
		const { isCumulativeDataVisible } = this.state;
		this.setState({ isCumulativeDataVisible: !isCumulativeDataVisible });
	};
	 getInvestorEntities = async () => {
		try {
			const impersonateID = await getImpersonateID();
			const { data } = await RequestService.get(`/api/investor/profile/entities/${impersonateID}`);
	
			if (data.code === 200) {
				this.setState({ investorEntities:data?.rows});
				this.checkInvestorInvestmentsWnverified();

			}
		
		}
		catch (err) {
			console.log(err);
		}
	};

	checkInvestorInvestmentsWnverified(){
	
		const{	investorInvestments} =this.props;
		const {investorEntities} = this.state;
	const entityIDS =investorInvestments.map(item => item?.EntityID);
	const notVerifiedList = investorEntities.filter(item => entityIDS.includes(item.EntityID) && item.w8n9formVerify===0);
this.setState({ wnVerified:notVerifiedList.length > 0,investorEntities:notVerifiedList});

	}

	getRoles(){
      
        fetch('/api/getrole/'+this.state.id+'/'+ +this.state.id+'/' +  this.props.session.marketing,{
            method:'get'
        })
            .then((response)=>{
                return response.json()
            })
            .then((data)=>{
            const roles = data.map(item => item.Role);
          
            if(roles.includes("SuperAdmin") || roles.includes("Admin")){
                this.setState({
                             
                    isAdmin: true,
               
                });
            }
          
            })
        
    }
	render() {
		const { isCumulativeDataVisible,wnVerified,investorEntities,isAdmin } = this.state;
		const {
			totalPaymentsRegD,
			totalPaymentsRegCF,
			investorEarnings,
			reducedEarnings,
			reducedInvestments,
			investorInvestments,
			totalInvestmentRegD,
			totalInvestmentRegCF,
			TotalReturnAmountRegD,
			activeTab,
			activeInvestorTab,
			
		} = this.props;
	
        const { earningLabels, earningsAmount, cumulativeTimeSeries: cumulativeEarningsTimeSeries, cumulativeEarningsAmount } = reducedEarnings;
		
        const {
            investmentCounts, investmentAmounts, investmentLabels,
            cumulativeInvestmentCounts, cumulativeInvestmentAmounts,
            cumulativeTimeSeries: cumulativeInvestmentTimeSeries
        } = reducedInvestments;
		
		const cumulativeEarnings = earningsAmount
			.map((i) => i.toFixed(2))
			.map((i) => parseFloat(i));
	
		const calculateCumulativeAmount = cumulativeEarnings?.slice(1).map((v, i) => v - cumulativeEarnings[i]);
	const cumulativeDistributions = [...cumulativeEarnings.slice(0,1),...calculateCumulativeAmount];
	

		return (
			<>{!isAdmin &&  investorInvestments?.length > 0 ?
			<div className="container invest-dashboard">
				
				{/* <Tabs
					selectedIndex={activeTab}
					onSelect={(index) => {
						activeInvestorTab(index);
					}}
				>
					<TabList>
						<Tab>Overview</Tab>
						<Tab>EARNINGS</Tab>
						<Tab>TRANSACTIONS</Tab>
						<Tab>DOCUMENTS</Tab>
						<Tab>MESSAGES</Tab>
						{wnVerified && <Tab>W-9 Form</Tab>}
						<Tab>Membership</Tab>
				</TabList>
					<TabPanel>
						<InvestorOverviewContainer wnVerified = {wnVerified}
							showPastInvestments={false}
							investmentLabels={investmentLabels}
							investmentAmounts={investmentAmounts}
							cumulativeEarnings={cumulativeEarnings}
              cumulativeEarningsTimeSeries={cumulativeEarningsTimeSeries} 
              cumulativeInvestmentTimeSeries={cumulativeInvestmentTimeSeries} 
              cumulativeEarningsAmount={cumulativeEarningsAmount}
							totalPaymentsRegD={totalPaymentsRegD}
							itemsPerPage={this.ITEMS_PER_PAGE}
							investorInvestments={investorInvestments}
							role="Investor"
						/>
					</TabPanel>
					<TabPanel>
						<InvestorEarningsContainer
							investmentLabels={investmentLabels}
							investmentAmounts={investmentAmounts}
							cumulativeEarnings={cumulativeEarnings}
							investorEarnings={investorEarnings}
							totalPaymentsRegCf={totalPaymentsRegCF}
							totalPaymentsRegD={totalPaymentsRegD}
							itemsPerPage={this.ITEMS_PER_PAGE}
							investorInvestments={investorInvestments}
							role="Investor"
						/>
				
					</TabPanel>
					<TabPanel>
						<InvestorTransactionsContainer
							investorInvestmentsList={investorInvestments}
							itemsPerPage={this.ITEMS_PER_PAGE}
							role="Investor"
						/>
					</TabPanel>
					<TabPanel>
						<InvestorFilesContainerComponent />
					</TabPanel>
					<TabPanel>
						<InvestorMessagesContainer />
					</TabPanel>
					{wnVerified && <TabPanel>
						<InvestorW9Container wnVerified ={wnVerified} investorEntities ={investorEntities} />
					</TabPanel>}
					<TabPanel>
						<InvestorMembershipsContainer
							investmentLabels={investmentLabels}
							investmentAmounts={investmentAmounts}
							cumulativeEarnings={cumulativeEarnings}
							investorEarningsList={investorEarnings}
							totalPaymentsRegCf={totalPaymentsRegCF}
							totalPaymentsRegD={totalPaymentsRegD}
							itemsPerPage={this.ITEMS_PER_PAGE}
							investorInvestments={investorInvestments}
							role="Investor"
						/>
					</TabPanel>
				
				</Tabs> */}

			{/* new items */}
			
				<InvestorOverviewContainer wnVerified = {wnVerified}
							showPastInvestments={false}
							investmentLabels={investmentLabels}
							investmentAmounts={investmentAmounts}
							cumulativeEarnings={cumulativeEarnings}
              cumulativeEarningsTimeSeries={cumulativeEarningsTimeSeries} 
              cumulativeInvestmentTimeSeries={cumulativeInvestmentTimeSeries} 
              cumulativeEarningsAmount={cumulativeEarningsAmount}
							totalPaymentsRegD={totalPaymentsRegD}
							itemsPerPage={this.ITEMS_PER_PAGE}
							investorInvestments={investorInvestments}
							role="Investor"
						/>
						<div className="container">
						<div className="p-3 px-md-5">
							<InvestorEarningsChart
						title="Your Distributions"
						investmentLabels={investmentLabels}
						investmentAmounts={investmentAmounts}
						cumulativeEarnings={cumulativeEarnings}
						cumulativeDistributions ={cumulativeDistributions}
					/></div>
					</div>

					<div className="container">
						<div className="p-3 px-md-5">
						<div className="col-md-12 col-xs-12">
						{/* <h2>Project Updates <span className="font-md"><Link to="/">View All</Link></span></h2> */}
						<div><InvestorNotifications /></div>
					</div>
					</div>
					</div>
					<div className="container">
					<div className="col-md-12 col-xs-12">
						<div className="p-3 px-md-5">
						<h2 className="pb-0 pb-md-0">Add More Funds to Your Current Investments</h2>
					<InvestmentOpportunities/>
					</div>
					</div>
					</div>
			
						
						 {/* <InvestorEarningsContainer
							investmentLabels={investmentLabels}
							investmentAmounts={investmentAmounts}
							cumulativeEarnings={cumulativeEarnings}
							investorEarnings={investorEarnings}
							totalPaymentsRegCf={totalPaymentsRegCF}
							totalPaymentsRegD={totalPaymentsRegD}
							itemsPerPage={this.ITEMS_PER_PAGE}
							investorInvestments={investorInvestments}
							role="Investor"
						/> */}
					
			</div> :<NewUserDashboard/>}
			</>
		);
	}
}
InvestorDashboardContainerComponent.propTypes = {
	getAllUserInvestments: PropTypes.func.isRequired,
	resetDashboardData: PropTypes.func.isRequired,
	getInvestorEarnings: PropTypes.func.isRequired,
	getDashboardData: PropTypes.func.isRequired,
	investorEarnings: PropTypes.array.isRequired,
	investorId: PropTypes.number.isRequired,
	investorInvestments: PropTypes.array.isRequired,
	totalInvestmentRegCF: PropTypes.any.isRequired,
	totalInvestmentRegD: PropTypes.any.isRequired,
	totalPaymentsRegCF: PropTypes.any.isRequired,
	totalPaymentsRegD: PropTypes.any.isRequired,
	reducedEarnings: PropTypes.object.isRequired,
	reducedInvestments: PropTypes.object.isRequired,
	activeInvestorTab: PropTypes.func.isRequired,
	activeTab: PropTypes.any.isRequired,
	wnVerified:PropTypes.bool.isRequired,
};
InvestorDashboardContainerComponent.defaultProps = {
	getAllUserInvestments: () => null,
	resetDashboardData: () => null,
	getDashboardData: () => null,
	getInvestorEarnings: () => null,
	investorEarnings: [],
	investorId: 0,
	investorInvestments: [],
	totalInvestmentRegCF: 0,
	totalInvestmentRegD: 0,
	totalPaymentsRegCF: 0,
	totalPaymentsRegD: 0,
	activeTab: 0,
	activeInvestorTab: () => null,
wnVerified:0,
};
const mapStateToProps = (_state) => ({
	..._state.investorDashboard,
	investorId: _state.session.user.id,
	session: _state.session.user
});
const mapDispatchToProps = (_dispatch) => ({
	...bindActionCreators(IDActions, _dispatch),
});
const connectedComponent = connect(
	mapStateToProps,
	mapDispatchToProps
)(InvestorDashboardContainerComponent);
export default connectedComponent;
