import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import ReactLoaderSpinner from 'react-loader-spinner';
import { Collapse, Modal } from 'react-bootstrap';

import './CommentsTabComponent.styles.css';
import { getHash } from '../../../../../utils/General';
import Icon from '../../../IconComponent/IconComponent';
import MultilineInputComponent from '../../../InputComponents/MultilineInputComponent/MultilineInputComponent';

class CommentElement extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            commentText: '',
            openReplyModal: false,
            isShowingReplies: false,
        };
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate() {
        this.init();
    }

    init = () => {
        const { isShowingReplies } = this.state;
        const { comment } = this.props;
        const { replyList } = comment;

        if (!isShowingReplies && replyList && replyList.length !== 0) {
            this.setState({ isShowingReplies: true });
        }
    };

    toggleModalOpen = () => {
        const { isShowingReplies } = this.state;
        const { getCommentReplies, comment, position } = this.props;

        getCommentReplies(comment.CommentID, position);
        this.setState({ isShowingReplies: !isShowingReplies });
    };

    formatDate = strDate => moment(strDate)
        .format('MMM DD, YYYY');

    getCommentStatus = (comment) => {
        if (comment.mainStatus === 1) {
            return null;
        }

        return (
            <div className="pull-right comment-text red-text">
                <span style={{ fontSize: 16 }}>Pending approval</span>
            </div>
        );
    };

    onInputChange = (event) => {
        const { name, value } = event.target;

        this.setState({ [name]: value });
    };

    onReply = () => {
        this.setState({ openReplyModal: true });
    };

    onCancelReply = () => {
        this.setState({
            openReplyModal: false,
            commentText: '',
        });
    };

    isEmptyComment = () => {
        const { commentText } = this.state;

        return (!commentText || commentText === '');
    };

    onSendReply = () => {
        const { onReply, comment } = this.props;
        const { commentText } = this.state;

        onReply(commentText, comment.CommentID, () => {
            this.toggleModalOpen();
            this.onCancelReply();
        });
    };

    // RENDER MANAGER
    renderCommentHeader = comment => (
        <span className="purple-text">
            {comment.isFundRaiser ? 'Fundraiser: ' : null}
            {comment.FirstName}
            {' - '}
            {this.formatDate(comment.CommentDateTime)}
        </span>
    );

    renderReplies = () => {
        const { comment } = this.props;
        const { replyList } = comment;

        if (!replyList || !replyList.length) {
            return null;
        }

        return replyList.map(reply => (
            <div
                key={getHash(reply)}
                className="comment-replies"
            >
                <div className="comment-replies-item">
                    <div className="container no-margin no-padding">
                        <div className="col-xs-12 comment-date">
                            {this.renderCommentHeader(reply)}

                            {this.getCommentStatus(reply)}
                        </div>
                        <div className="col-xs-12 comment-text">
                            {reply.CommentText}
                        </div>
                    </div>
                </div>
            </div>
        ));
    };

    render = () => {
        const { isShowingReplies, openReplyModal } = this.state;
        const { comment, isLoggedInUser } = this.props;

        const canShowReplies = !!comment.replies;
        const isReplyListReady = (comment.replyList && comment.replyList.length);

        return (
            <div className="list-group-item no-padding comment-item">
                <div className="container no-padding no-margin">
                    <div className="col-xs-12 comment-date">
                        <div className="pull-left">
                            {this.renderCommentHeader(comment)}
                        </div>

                        {this.getCommentStatus(comment)}
                    </div>
                    <div className="col-xs-12 comment-text">
                        {comment.CommentText}
                    </div>
                    <Collapse in={isShowingReplies}>
                        <div className="col-xs-12 no-padding no-margin margin-top-30px">
                            <div>
                                {!isReplyListReady && (
                                    <div className="comment-replies-loading">
                                        <ReactLoaderSpinner type="ThreeDots" color="#somecolor" height={80} width={80} />
                                        <p>Loading replies</p>
                                    </div>
                                )}

                                {this.renderReplies()}
                            </div>
                        </div>
                    </Collapse>
                    <div className="col-xs-12 no-padding margin-top-30px">
                        {canShowReplies && !isShowingReplies && (
                            <div className="pull-left">
                                <button
                                    type="button"
                                    onClick={this.toggleModalOpen}
                                    className="btn btn-default capitalize-text comment-action"
                                >
                                    See replies
                                </button>
                            </div>
                        )}
                        {isLoggedInUser && (
                            <div className="pull-right">
                                <button
                                    type="button"
                                    onClick={this.onReply}
                                    className="btn btn-default capitalize-text comment-action"
                                >
                                    reply
                                </button>
                            </div>
                        )}
                    </div>
                </div>

                <Modal
                    show={openReplyModal}
                    onHide={this.onCancelReply}
                >
                    <Modal.Header closeButton />
                    <Modal.Body>
                        <div className="container">
                            <div className="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
                                <div className="col-xs-12 no-margin no-padding">
                                    <h3 className="heading-text upper-case-text">
                                        {this.renderCommentHeader(comment)}
                                    </h3>
                                </div>

                                <div className="col-xs-12 mo-margin no-padding comment-replies-item">
                                    <div className="container no-margin no-padding">
                                        <div className="col-xs-12 comment-text">
                                            {comment.CommentText}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xs-12 mo-margin no-padding margin-top-30px">
                                    <MultilineInputComponent
                                        rows={4}
                                        inputName="commentText"
                                        inputTitle="your reply:"
                                        placeholder="Please add your comment here"
                                        elementValue={this.state.commentText}
                                        onInputUpdate={this.onInputChange}
                                        maxInputLength={null}
                                        isRequired
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
                            <div className="pull-right">
                                <button
                                    className="btn btn-default capitalize-text"
                                    onClick={this.onCancelReply}
                                    type="button"
                                >
                                    <Icon iconName="times" />
                                    {' '}
                                    cancel
                                </button>
                                <button
                                    className="btn btn-primary capitalize-text"
                                    disabled={this.isEmptyComment()}
                                    onClick={this.onSendReply}
                                    type="button"
                                >
                                    <Icon iconName="paper-plane" extraClasses="white-text" />
                                    {' '}
                                    send
                                </button>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    };
}

CommentElement.propTypes = {
    comment: PropTypes.object.isRequired,
    getCommentReplies: PropTypes.func.isRequired,
    onReply: PropTypes.func.isRequired,
    position: PropTypes.number.isRequired,
    isLoggedInUser: PropTypes.bool.isRequired,
};

CommentElement.defaultProps = {
    comment: {},
    getCommentReplies: () => null,
    onReply: () => null,
    position: 0,
    isLoggedInUser: true,
};

export default CommentElement;
