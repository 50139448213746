import initialState from './appInitialState';
import * as Project from '../actions/ProjectActions';

export default function (state = initialState.projects, action) {
    const {type} = action;
    const {requestOnFlight} = state;
    const plus = (requestOnFlight + 1);
    const minus = requestOnFlight <= 0 ? 0 : (requestOnFlight - 1);

    if (Project.START_PROJECT_REQUEST === type) {
        return {...state, requestOnFlight: plus};
    } else if (Project.END_PROJECT_REQUEST === type) {
        return {...state, requestOnFlight: minus};
    } else if (Project.REQUEST_ALL_REG_CF_PROJECTS_SUCCESS === type) {
        return {...state, regCFProjects: action.data, requestOnFlight: minus};
    } else if (Project.REQUEST_ALL_REG_D_PROJECTS_SUCCESS === type) {
        return {...state, regDProjects: action.data, requestOnFlight: minus};
    } else {
        return state;
    }
}

