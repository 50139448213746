
import React from 'react';
import { ReactSVG } from "react-svg";


export default function NavSignUp() {
	const efLogo = "/img/ef-logo.svg";
	return(
	<div className="col-12 white-bg text-center" style={{ padding:10}}>
		   <ReactSVG src={efLogo} alt="Logo" />
 </div>
	)
}
