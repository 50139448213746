import initialState from '../../../../reducers/appInitialState';
import * as Actions from '../actions/actions';

export default (state = initialState.projectDetails, action) => {
    const { projectDetailsOnFlightRequest } = state;
    const plus = (projectDetailsOnFlightRequest + 1);
    const minus = projectDetailsOnFlightRequest <= 0 ? 0
        : (projectDetailsOnFlightRequest - 1);

    switch (action.type) {
    case Actions.PROJECT_DETAILS_REQ_START:
        return { ...state, projectDetailsOnFlightRequest: plus };
    case Actions.PROJECT_DETAILS_REQ_END:
        return { ...state, projectDetailsOnFlightRequest: minus };
    case Actions.PROJECT_DETAILS_GET_DETAILS_SUCCESS:
        return { ...state, projectDetailsOnFlightRequest: minus, ...action.data };
    case Actions.PROJECT_DETAILS_VERIFY_SIGN_CA:
        return { ...state, projectDetailsOnFlightRequest: minus, isSigned: action.data };
    case Actions.PROJECT_DETAILS_GET_UPDATE_SUCCESS:
        return { ...state, projectDetailsOnFlightRequest: minus, selectUpdate: action.data };
    case Actions.PROJECT_DETAILS_GET_COMMENTS_SUCCESS:
        return { ...state, projectDetailsOnFlightRequest: minus, regCfCommentList: action.data };
    default:
        return state;
    }
};
