import PropTypes from 'prop-types'
import React from "react";
import {PROJECT_REG_D} from "../../../utils/ApplicationConstants";
import Icon from "../IconComponent/IconComponent";


class CardImageComponent extends React.Component {
    renderSelectCardImage = () => {
        const {isEditMode} = this.props;

        if (!isEditMode) return null;

        return (
            <div className="image-card-select-new-image ">
                <label className="pointer" htmlFor="cardImageSelect">
                    Click here to change image{' '}
                    <Icon iconName="pencil" extraClasses="white-text"/>
                </label>
            </div>
        );
    };

    render = () => {
        const {
            isProjectDetails, onSeeDetails, projectID, projectType,
            version, imageUrl, isEditMode, onCardImageChange
        } = this.props;

        const isPointer = !isProjectDetails ? "pointer" : null;

        const onClick = () => {
            if (!isProjectDetails && !isEditMode)
                onSeeDetails(projectID, projectType, version);
        };


        return (
            <div className={`grid-image ${isPointer}`}
                 onClick={onClick}
                 style={{backgroundImage: `url(${imageUrl})`}}>

                <input type="file"
                       id="cardImageSelect"
                       onChange={onCardImageChange}
                       style={{display: 'none'}}/>

                {this.renderSelectCardImage()}
            </div>
        );
    };
}


CardImageComponent.propTypes = {
    imageUrl: PropTypes.string.isRequired,
    isProjectDetails: PropTypes.bool.isRequired,
    onSeeDetails: PropTypes.func.isRequired,
    projectID: PropTypes.number.isRequired,
    projectType: PropTypes.string.isRequired,
    version: PropTypes.number.isRequired
};

CardImageComponent.defaultProps = {
    imageUrl: '',
    onSeeDetails: () => null,
    projectID: 0,
    projectType: PROJECT_REG_D,
    version: 1
};

export default CardImageComponent;

