import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';
import SingleLineInputComponent
    from '../../../shared/InputComponents/SingleLineInputComponent/SingleLineInputComponent';
import Icon from '../../../shared/IconComponent/IconComponent';
import { PROJECT_UPDATE_CREATE, PROJECT_UPDATE_EDIT } from '../../../../utils/ApplicationConstants';

class StatusModalComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            statusName: '',
        };
    }

    componentDidUpdate = (prevProps) => {
        const { statusToEdit } = this.props;
        const _statusToEdit = JSON.stringify(prevProps.statusToEdit);
        const strStatusToEdit = JSON.stringify(statusToEdit);

        if (strStatusToEdit !== _statusToEdit) {
            this.setState({ statusName: statusToEdit.statusName });
        }
    };

    onInputChange = (event) => {
        const { name, value } = event.target;

        this.setState({ [name]: value });
    };

    onCloseModal = () => {
        const { onModalClose } = this.props;

        this.setState({ statusName: '' });
        onModalClose();
    };

    onSaveButtonClick = () => {
        const {
            action, onSaveChanges, onUpdateStatus, statusToEdit,
        } = this.props;
        const { statusName } = this.state;
        const { statusId } = statusToEdit;

        if (action === PROJECT_UPDATE_CREATE) {
            onSaveChanges({ statusName });
        } else if (action === PROJECT_UPDATE_EDIT) {
            onUpdateStatus({
                statusName,
                statusId,
            });
        }

        this.onCloseModal();
    };

    render = () => {
        const { statusName } = this.state;
        const { isStatusModalOpen } = this.props;

        return (
            <Modal
                className="no-margin"
                onHide={this.onCloseModal}
                show={isStatusModalOpen}
            >
                <Modal.Header closeButton/>
                <Modal.Body>
                    <div className="container">
                        <div className="col-sm-12 col-md-2"/>
                        <div className="col-sm-12 col-md-8">
                            <div className="col-xs-12">
                                <SingleLineInputComponent
                                    onInputUpdate={this.onInputChange}
                                    maxInputLength={25}
                                    elementValue={statusName}
                                    inputName="statusName"
                                    inputTitle="Status name"
                                    isRequired
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="container">
                        <div className="col-sm-12 col-md-2"/>
                        <div className="col-sm-12 col-md-8">
                            <div className="pull-right">
                                <button
                                    className="btn btn-primary btn-sm capitalize-text"
                                    onClick={this.onSaveButtonClick}
                                    type="button"
                                >
                                    <Icon iconName="floppy-o" extraClasses="white-text"/>
                                    {' '}
                                    save status
                                </button>
                                <button
                                    className="btn btn-warning btn-sm capitalize-text"
                                    onClick={this.onCloseModal}
                                    type="button"
                                >
                                    <Icon iconName="times" extraClasses="white-text"/>
                                    {' '}
                                    cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    };
}

StatusModalComponent.propTypes = {
    action: PropTypes.string.isRequired,
    isStatusModalOpen: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
    onSaveChanges: PropTypes.func.isRequired,
    onUpdateStatus: PropTypes.func.isRequired,
    statusToEdit: PropTypes.object.isRequired,
};

StatusModalComponent.defaultProps = {
    action: '',
    isStatusModalOpen: false,
    onModalClose: () => null,
    onSaveChanges: () => null,
    onUpdateStatus: () => null,
    statusToEdit: {},
};

export default StatusModalComponent;
