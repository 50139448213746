import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Icon from "../IconComponent/IconComponent";
import AddBank from "./components/AddBank";
import * as Actions from "./actions/actions";
import HideComponent from "../HideComponent/HideComponent";
import { formatPhoneNumber } from "../../../utils/General";
import RequestService from "../../../api/RequestService";
import store from "../../../index";
import swal from "sweetalert2";

class NewBankAccountContainerComponent extends React.Component {
	INITIAL_STATE = {
		step: 1,
		modalTitle: null,
		allowModalClose: true,
		createdEntityId: null,
		details: {
			entityType: "",
			entityName: "",
			bankName: "",
			accountName: "",
			accountNumber: "",
			accountType: "",
			dwollaCusID: "",
			isFundingSourceVerified: 0,
			isPrimary: 0,
			achAddenda :'',
		},
	};

	constructor(props) {
		super(props);

		this.state = {
			...this.INITIAL_STATE,
			step: props.stepNumber,
			id: props.session?.id,
		};
	}

	componentDidMount() {}

	onInputChange = (event, objName = "details") => {
		const { [objName]: details } = this.state;
		const _details = { ...details };
		const { name, value: _value } = event.target;
		let value = _value;

		if (name === "phone") {
			value = formatPhoneNumber(_value);
		}

		if (
			objName === "entityVerificationInfo" &&
			name === "businessCategories"
		) {
			_details.businessClassification = "";
		}

		_details[name] = value;

		this.setState({ [objName]: _details });
	};

	onAddNewBankAccount = async () => {
	
		const { isAdmin, investorId, createNewEntity} = this.props;
		const { details } = this.state;

		let formBody = [];
		formBody.push("UserID=" + encodeURIComponent(investorId));
		formBody.push("EntityID=" + encodeURIComponent(details.entityType));
		formBody.push(
			"dwollaCusID=" +
				encodeURIComponent(
					`https://api.dwolla.com/funding-sources/${details.dwollaCusID}`
				)
		);
		formBody.push(
			"isFundingSourceVerified=" +
				encodeURIComponent(Number(details.isFundingSourceVerified))
		);
		formBody.push("bankName=" + encodeURIComponent(details.bankName));
		formBody.push("accountName=" + encodeURIComponent(details.accountName));
		formBody.push("accountType=" + encodeURIComponent(details.accountType));
		formBody.push(
			"accountNumber=" + encodeURIComponent(details.accountNumber)
		);
		formBody.push(
			"isPrimary=" + encodeURIComponent(Number(details.isPrimary))
		);
		formBody.push("AchAddenda=" + encodeURIComponent(details.achAddenda));
		formBody = formBody.join("&");

		// do fetch call
		const { data: status } = await RequestService.post({
			url: `/api/AddBankAccount`,
			data: formBody,
			headers: {
				Accept: "application/json",
				"Content-Type": "application/x-www-form-urlencoded",
			},
		});
		if (status.code === 200) {
			swal("Bank added Successfully.", "", "success").then((value) => {
				const { onCloseModal } = this.props;
                const initialState = { ...this.INITIAL_STATE };
                this.setState(initialState);
				onCloseModal();
			});
		} else if (status.code === 400) {
			swal("Something went wrong.", "", "error").then((value) => {
				const { onCloseModal } = this.props;
				onCloseModal();
			});
		}

		// createNewEntity(isAdmin, investorId, details, this.onVerifyNewEntity);
	};

	onCloseModalAndResetValues = () => {
		const { allowModalClose } = this.state;
		const { stepNumber, createdEntityId, entityType } = this.props;
		const { onCloseModal, parentUpdateMethod, isVerification } = this.props;

		const initialState = { ...this.INITIAL_STATE };

		if (!allowModalClose) {
			// doesn't allow the user to close the modal
			// until they finish the validation process
			return;
		}

		if (isVerification) {
			initialState.step = stepNumber;
			initialState.createdEntityId = createdEntityId;
			initialState.details.entityType = entityType;
		}

		this.setState(initialState);
		onCloseModal();
		parentUpdateMethod();
	};

	validateFirstStepEntityInputs = () => {
		const { details } = this.state;
		const requiredKey = ["entityType", "accountType", "dwollaCusID"];

		return requiredKey
			.map(
				(key) =>
					details[key] === "" ||
					details[key] === null ||
					details[key] === undefined
			)
			.some((item) => item);
	};

	setTitle = (title, stepNumber) => {
		const { step, modalTitle } = this.state;

		if (step === stepNumber && modalTitle !== title) {
			this.setState({ modalTitle: title });
		}
	};

	// RENDER MANAGER
	renderFirstStep = () => {
		const title = "Step 1";
		const { step, details } = this.state;
		const { Entity } = this.props;
		if (step !== 1) {
			return null;
		}

		this.setTitle(title, 1);

		return (
			<AddBank
				{...details}
				onInputChange={this.onInputChange}
				questionAnswer={details.questionAnswer}
				Entity={Entity}
				// isRocketDollarIRA={this.isRocketDollarIRA()}
				// isOtherIRA={this.isOtherIRA}
				// onSetSelectedAddressInfo={this.setSelectedAddressInfo}
				// isFinancialAdvisor={this.state.isFinancialAdvisor}
			/>
		);
	};

	renderFirstStepButton = () => {
		const { step } = this.state;

		if (step !== 1) {
			return null;
		}

		return (
			<button
				className="btn btn-primary btn-green"
				disabled={this.validateFirstStepEntityInputs()}
				// onClick={this.goToNextStep}
				onClick={this.onAddNewBankAccount}
				type="button"
			>
				Submit
			</button>
		);
	};

	render = () => {
		const { step, modalTitle } = this.state;
		const { isModalOpen } = this.props;

		return (
			<Modal
				className="no-margin"
				show={isModalOpen}
				onHide={this.onCloseModalAndResetValues}
			>
				<Modal.Header closeButton>
					<h3 className="heading-text capitalize-text">
						Add New Bank Account
					</h3>
				</Modal.Header>
				<Modal.Body>{this.renderFirstStep()}</Modal.Body>
				<Modal.Footer>
					<div className="col-xs-12">
						<div className="pull-left">
							{this.renderFirstStepButton()}
						</div>
						<HideComponent>
							<div className="pull-left">
								<button
									className="btn btn-danger capitalize-text"
									onClick={this.onCloseModalAndResetValues}
									type="button"
								>
									<Icon
										iconName="times"
										extraClasses="white-text"
									/>{" "}
									Cancel
								</button>
							</div>
						</HideComponent>
					</div>
				</Modal.Footer>
			</Modal>
		);
	};
}

NewBankAccountContainerComponent.propTypes = {
	entityType: PropTypes.string,
	getDwollaBusinessCategories: PropTypes.func.isRequired,
	investorId: PropTypes.number,
	isAdmin: PropTypes.bool,
	isModalOpen: PropTypes.bool.isRequired,

	onCloseModal: PropTypes.func.isRequired,
	parentUpdateMethod: PropTypes.func.isRequired,
	stepNumber: PropTypes.number,
};

NewBankAccountContainerComponent.defaultProps = {
	entityType: "",
	getDwollaBusinessCategories: () => null,
	investorId: null,
	isAdmin: false,
	isModalOpen: false,

	onCloseModal: () => null,
	parentUpdateMethod: () => null,
	stepNumber: 1,
};

const mapStateToProps = (_state) => ({ ..._state.newEntity });

const mapDispatchToProps = (_dispatch) => ({
	...bindActionCreators(Actions, _dispatch),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(NewBankAccountContainerComponent);
