import moment from "moment";
import React, { Component } from "react";
import Chart from "react-apexcharts";

import { formatChartLabel, formatChartMoney } from "../../../utils/General";
class InvestorCumulativeEarningsChart extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		const {
			investmentLabels,
			cumulativeEarnings,
			cumulativeDistributions,
			cumulativeEarningsChartData,
			investmentAmounts,
		} = this.props;


		const cumulativeEarningsWithLabels = investmentLabels?.map((e, i) => {
			return {
				ivestmentDate:moment(e)?.format("YYYY-MM"),
				investmentLabels: e,
				cumulativeEarnings: cumulativeEarnings[i],
			};
		});

		const combinedData = [];
		if(cumulativeEarningsWithLabels?.length>0)
		combinedData.push(cumulativeEarningsWithLabels[0]);

		for (let index = 1;index < cumulativeEarningsWithLabels?.length;index++) {
			let nextMonth = moment(cumulativeEarningsWithLabels[index - 1]?.investmentLabels).add(1, "M").format("YYYY-MM");
			if (nextMonth ==cumulativeEarningsWithLabels[index]?.ivestmentDate)
				combinedData.push({
					...cumulativeEarningsWithLabels[index],
				});
			else {
				do {
					let previousData = {
						...cumulativeEarningsWithLabels[index - 1],
					};
					previousData.investmentLabels =moment(nextMonth).format("YYYY-MMMM");
					combinedData.push(previousData);
					nextMonth = moment(nextMonth).add(1, "M").format("YYYY-MM");
				} while (
					nextMonth !=cumulativeEarningsWithLabels[index].ivestmentDate
				);
				combinedData.push({
					...cumulativeEarningsWithLabels[index],
				});
			}
		}



		const setColumnWidth = () => {
			const seriesLength = investmentLabels?.length;
			if (seriesLength > 2) {
				return "50%";
			} else if (seriesLength <= 2) {
				return "5%";
			}
		};

		const series = [
			{
				name: "Cumulative Distributions",
				// data: combinedData?.map((i) => i?.cumulativeEarnings),
				data:cumulativeEarningsChartData
			
			},
		];

		const options = {
			tooltip: {
				y: {
					formatter: function (val, index) {
						return "$"+val?.toFixed(2);
						// return formatChartMoney(val);
					},
				},
			},
			chart: {
				fontFamily: "Montserrat, Arial, sans-serif",
				toolbar: {
					show: false,
				},
			},
			colors: ["#5bb462"],
			dataLabels: {
				enabled: false,
			},
			plotOptions: {
				bar: {
					columnWidth: setColumnWidth(),
        //   columnWidth: '100%',
				},
			},

			grid: {
				borderColor: "#e7e7e7",
				row: {
					colors: ["#e7e7e7", "transparent"], // takes an array which will be repeated on columns
					//   opacity: 0.5
				},
			},
			markers: {
				size: 4,
			},
			xaxis: {
				type: "datetime",
			},
			labels: combinedData?.map((i) => i?.investmentLabels),
	
			yaxis: {
				labels: {
					formatter: function (val, index) {
						return "$"+val?.toFixed(2);
						// return formatChartLabel(val);
					},
				},
			},
		};

		return (
			<>
				<div className="container white-bg mb-2 clearfix rounded-top ">
					<div className="col-md-12 col-xs-12 px-0">
						<h2>{this.props?.title}</h2>
						<Chart
							options={options}
							series={series}
							type="line"
							height={260}
						/>
					</div>
				</div>
			</>
		);
	}
}

export default InvestorCumulativeEarningsChart;
