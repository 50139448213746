import PropTypes from 'prop-types'
import React from 'react';

const TabComponent = (props) => {
    const {showActions, onDeleteTab, onUpdateTab, tab} = props;
    return (
        <div className="container">
            {showActions &&
            <div className="row" style={{marginBottom: 20}}>
                <div className="pull-right">
                    <button type="button"
                            className="btn btn-warning"
                            onClick={() => onDeleteTab(tab)}>
                        Delete Tab
                    </button>
                    <button type="button"
                            className="btn btn-primary"
                            onClick={() => onUpdateTab(tab)}>
                        Edit Tab
                    </button>
                </div>
                <br/>
            </div>}
            <div className="row">
                <div dangerouslySetInnerHTML={{__html: props.tabContent}}/>
            </div>
        </div>
    );
};

TabComponent.propTypes = {
    onDeleteTab: PropTypes.func,
    onUpdateTab: PropTypes.func,
    showActions: PropTypes.bool,
    tab: PropTypes.object,
    tabContent: PropTypes.string.isRequired,
    tabId: PropTypes.any.isRequired,
    tabName: PropTypes.string.isRequired,
    tabOrder: PropTypes.number.isRequired
};

TabComponent.defaultProps = {
    onDeleteTab: () => null,
    onUpdateTab: () => null,
    showActions: false,
    tab: {}
};

export default TabComponent;
