import { gql } from '@apollo/client';

export const QUERY_ALL_PAGES = gql`
query AllPages {
  pages(first: 10000) {
    edges {
      node {
        author {
          node {
            avatar {
              height
              url
              width
            }
            id
            name
            slug
            pages {
              edges {
                node {
                  id
                }
                cursor
              }
            }
          }
        }
        id
        content
        date
        excerpt
        featuredImage {
          node {
            altText
            caption
            sourceUrl
            srcSet
            sizes
            id
          }
        }
        modified
        databaseId
        title
      }
    }
  }
}

`;



export function sanitizeExcerpt(excerpt,landingPage=false) {
  if (typeof excerpt !== "string") {
    throw new Error(
      `Failed to sanitize excerpt: invalid type ${typeof excerpt}`
    );
  }

  let sanitized = excerpt;

  // If the theme includes [...] as the more indication, clean it up to just ...

  sanitized = sanitized.replace(/\s?\[&hellip;\]/, "&hellip;");

  // If after the above replacement, the ellipsis includes 4 dots, it's
  // the end of a setence

  sanitized = sanitized.replace("....", ".");
  sanitized = sanitized.replace(".&hellip;", ".");

  // If the theme is including a "Continue..." link, remove it

  sanitized = sanitized.replace(/\w*<a class="more-link".*<\/a>/, "");
 if(!landingPage){
   sanitized = sanitized.substring(0, 180) + "...";
  } 
  
  return sanitized;
}