import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import Pagination from 'react-js-pagination';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import getDate from '../../utils/ConvertDate';
import { PROJECT_REG_CF, PROJECT_REG_D } from '../../utils/ApplicationConstants';
import RequestService from "../../api/RequestService";


class EditProjects extends Component {
    constructor(props) {
        super(props);

        this.state = {
            totalProjects: [],
            allProjects: [],
            projects: [],
            pageNumber: 1,
            totalCount: 0,
            perPage: 10,
            activePage: 1,

            totalProjects_CF: [],
            allProjects_CF: [],
            projects_CF: [],
            pageNumber_CF: 1,
            totalCount_CF: 0,
            perPage_CF: 10,
            activePage_CF: 1,

            viewData_RegD: [],
            viewData_RegCF: [],
            id: props.session.id,
            isLoaded: false,
            errorOnLoad: false
        };

        this.getprojects = this.getprojects.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleCFPageChange = this.handleCFPageChange.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleCFSearchChange = this.handleCFSearchChange.bind(this);
        this.getDat = this.getDat.bind(this);
        this.onEditD = this.onEditD.bind(this);
        this.onEditCF = this.onEditCF.bind(this);
        this.showProductionData = this.showProductionData.bind(this);
        this.createNewProject = this.createNewProject.bind(this);
    }



    componentDidMount() {
        window.scrollTo(0, 0);
        this.getprojects();
    }



    async getprojects() {
        const {data} = await RequestService.get(`/api/getProjectInvestments`);

        if (data.code === 200) {
            this.setState({
                totalProjects: data.reg_d,
                allProjects: data.reg_d,
                projects: data.reg_d.slice(0, 10),
                pageNumber: 1,
                totalCount: data.reg_d.length,
                perPage: 10,
                activePage: 1,

                totalProjects_CF: data.reg_cf,
                allProjects_CF: data.reg_cf,
                projects_CF: data.reg_cf.slice(0, 10),
                pageNumber_CF: 1,
                totalCount_CF: data.reg_cf.length,
                perPage_CF: 10,
                activePage_CF: 1,
                isLoaded: true,
                err: {},
                errorOnLoad: false,
            });
        }
    }



    onEditD(id, version) {
        if (version === 2) {
            this.props.history.push({
                pathname: "/app/project/edit",
                state: {projectId: id, projectType: PROJECT_REG_D}
            });
        }
        else {
            this.props.history.push({
                pathname: "/app/editproject_d",
                state: {ProjectID: id}
            });
        }
    }



    onEditCF(id, version) {
        if (version === 2) {
            this.props.history.push({
                pathname: "/app/project/edit",
                state: {projectId: id, projectType: PROJECT_REG_CF}
            });
        }
        else {
            this.props.history.push({
                pathname: "/app/editproject_cf",
                state: {ProjectID: id}
            });
        }
    }



	showProductionData(project) {
		this.props.history.push({
			pathname: '/app/project/ProductionData',
			state: {
				projectID: (project.ParentProjectID > 0 ? project.ParentProjectID : project.ProjectID),
				role: 'SuperAdmin'
			}
		});
	}



    handlePageChange(pageNumber) {
        let prev = ((pageNumber) - 1) * 10;
        let next = prev + 10;

        this.setState({
            activePage: pageNumber,
            projects: this.state.allProjects.slice(prev, next)
        });
    }



    handleCFPageChange(pageNumber) {
        let prev = ((pageNumber) - 1) * 10;
        let next = prev + 10;

        this.setState({
            activePage_CF: pageNumber,
            projects_CF: this.state.allProjects_CF.slice(prev, next)
        });
    }



    getDat(toSearch, objects) {
        return new Promise((resolve, reject) => {
            let dat = objects.filter(function (object) {
                let searchStatus = 0;

                for (const key in object) {
                    if (object.hasOwnProperty(key)) {
                        let patt = new RegExp(toSearch, 'i');

                        if (patt.test(object[key])) {
                            searchStatus = 1;
                        }
                    }
                }
                if (searchStatus) {
                    return true;
                }
            });

            resolve(dat);
        });
    }



    handleSearchChange(e) {
        let toSearch = e.target.value;
        if (toSearch === "" || toSearch === undefined) {
            this.setState({
                allProjects: this.state.totalProjects,
                projects: this.state.totalProjects.slice(0, 10),
                pageNumber: 1,
                totalCount: this.state.totalProjects.length,
                perPage: 10,
                activePage: 1
            });
        }
        else {
            let objects = this.state.totalProjects;
            this.getDat(toSearch, objects).then((dat) => {
                    console.log(dat);
                    this.setState({
                        allProjects: dat,
                        projects: dat.slice(0, 10),
                        pageNumber: 1,
                        totalCount: dat.length,
                        perPage: 10,
                        activePage: 1
                    })
                }
            );
        }
    }



    handleCFSearchChange(e) {
        let toSearch = e.target.value;
        if (toSearch === "" || toSearch === undefined) {
            this.setState({
                allProjects_CF: this.state.totalProjects_CF,
                projects_CF: this.state.totalProjects_CF.slice(0, 10),
                pageNumber_CF: 1,
                totalCount_CF: this.state.totalProjects_CF.length,
                perPage_CF: 10,
                activePage_CF: 1
            })
        } else {
            let objects = this.state.totalProjects_CF;
            this.getDat(toSearch, objects).then((dat) => {
                    console.log(dat);
                    this.setState({
                        allProjects_CF: dat,
                        projects_CF: dat.slice(0, 10),
                        pageNumber_CF: 1,
                        totalCount_CF: dat.length,
                        perPage_CF: 10,
                        activePage_CF: 1
                    })
                }
            );
        }
    }



	createNewProject() {
		this.props.history.push({ pathname: '/app/Myprojects' });
	}



    renderRegD() {
        return (
            <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="x_panel">
                    <div className="x_title">
                        <div className="col-md-8"> </div>
                        <div className="col-md-4">
                            <input className="pull-right" type="text"
                                   onChange={this.handleSearchChange}
                                   placeholder="Search here"/>
                        </div>
                        <div className="clearfix"/>
                    </div>

                    <div className="x_content">
                        <div id="datatable_wrapper" className="dataTables_wrapper form-inline dt-bootstrap no-footer">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="table-responsive">
                                        <table
                                            className="table table-striped table-bordered dataTable no-footer">
                                            <thead>
                                            <tr className="headings">
                                                <th className="column-title">Project ID</th>
                                                <th className="column-title">Project Name</th>
                                                <th className="column-title">Project Entity</th>
                                                <th className="column-title">Created Date</th>
                                                <th className="column-title">Action</th>
                                            </tr>
                                            </thead>
                                            {(() => {
                                                if (this.state.projects.length > 0) {
                                                    return (
                                                        <tbody>
                                                        {
                                                            this.state.projects.map((project, i) => (
                                                                <tr key={i} className="even pointer">
                                                                    <td>{project.ProjectID}</td>
                                                                    <td>{project.ProjectName}</td>
                                                                    <td>{project.Entity} {project.IsCashCall ? ' (Cash Call)' : ''}</td>
                                                                    <td>{getDate.getDate(project.DateCreated)}</td>
                                                                    <td>
                                                                        <button
                                                                            className="btn-sm btn-primary"
                                                                            onClick={() => this.onEditD(project.ProjectID, project.TemplateVersion)}
																		>
                                                                            Edit
                                                                        </button>
																		<a
																			className="btn-sm"
																			onClick={() => this.showProductionData(project)}
																		>
																			Edit/View Production Data
																		</a>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                        </tbody>
                                                    );
                                                }
                                                else {
                                                    return (
                                                        <tbody>
                                                            <tr className="even pointer">
                                                                <td colSpan="4" className="text-center">Nothing to show</td>
                                                            </tr>
                                                        </tbody>
                                                    );
                                                }
                                            })()}
                                        </table>
                                    </div>
                                </div>

                                <div className="pull-right">
                                    <Pagination
                                        firstPageText={<i
                                            className='glyphicon glyphicon-chevron-left'/>}
                                        lastPageText={<i
                                            className='glyphicon glyphicon-chevron-right'/>}
                                        prevPageText={<i
                                            className='glyphicon glyphicon-menu-left'/>}
                                        nextPageText={<i
                                            className='glyphicon glyphicon-menu-right'/>}
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={this.state.perPage}
                                        totalItemsCount={this.state.totalCount}
                                        onChange={this.handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }



    renderRegCF() {
        return (
            <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="x_panel">
                    <div className="x_title">
                        <div className="col-md-8"> </div>
                        <div className="col-md-4">
                            <input className="pull-right" type="text"
                                   onChange={this.handleCFSearchChange}
                                   placeholder="Search here"/>
                        </div>
                        <div className="clearfix"/>
                    </div>

                    <div className="x_content">
                        <div id="datatable_wrapper" className="dataTables_wrapper form-inline dt-bootstrap no-footer">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="table-responsive">
                                        <table
                                            className="table table-striped table-bordered">
                                            <thead>
                                            <tr className="headings">
                                                <th className="column-title">Project ID</th>
                                                <th className="column-title">Project Name</th>
                                                <th className="column-title">Created Date</th>
                                                <th className="column-title">Action</th>
                                            </tr>
                                            </thead>
                                            {(() => {
                                                if (this.state.projects_CF.length > 0) {
                                                    return (
                                                        <tbody>
                                                        {
                                                            this.state.projects_CF.map((project, i) => (
                                                                <tr key={i} className="even pointer">
                                                                    <td>{project.ProjectID}</td>
                                                                    <td>{project.InvestmentName}</td>
                                                                    <td>{getDate.getDate(project.ProjectPublishDate)}</td>
                                                                    <td>
                                                                        <button
                                                                            className="btn-sm btn-primary"
                                                                            onClick={() => this.onEditCF(project.ProjectID, project.TemplateVersion)}>
                                                                            Edit
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                        </tbody>
                                                    );
                                                }
                                                else {
                                                    return (
                                                        <tbody>
                                                        <tr className="even pointer">
                                                            <td colSpan="4"
                                                                className="text-center">Nothing
                                                                to show
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    );
                                                }
                                            })()}
                                        </table>
                                    </div>
                                </div>

                                <div className="pull-right">
                                    <Pagination
                                        firstPageText={<i
                                            className='glyphicon glyphicon-chevron-left'/>}
                                        lastPageText={<i
                                            className='glyphicon glyphicon-chevron-right'/>}
                                        prevPageText={<i
                                            className='glyphicon glyphicon-menu-left'/>}
                                        nextPageText={<i
                                            className='glyphicon glyphicon-menu-right'/>}
                                        activePage={this.state.activePage_CF}
                                        itemsCountPerPage={this.state.perPage_CF}
                                        totalItemsCount={this.state.totalCount_CF}
                                        onChange={this.handleCFPageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }



    render() {
        return (
            <div className="container body">
                <div className="main_container">
                    <div className="right_col" role="main" style={{minHeight: 3753}}>
                        <div className>
                            <div className="page-title">
                                <div className="title_left">
                                    <h3>Edit Project Investments</h3>
									<div className="pull-right">
										<button className="btn btn-default" onClick={this.createNewProject}>Add New Project</button>
									</div>
                                </div>
                            </div>
							<div className="clearfix"/>
                            <div className="row">
                                <div>
                                    <ul className="nav nav-pills nav-justified"
                                        style={{padding: '0 10px', marginBottom: 20}}>
                                        <li className="active"><a data-toggle="pill" href="#home">EnergyFunders Black
                                            (Reg D)</a></li>
                                        <li><a data-toggle="pill" href="#menu1">EnergyFunders Marketplace (Reg CF)</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content">
                                        <div id="home" className="tab-pane fade in active">
                                            {this.renderRegD()}
                                        </div>
                                        <div id="menu1" className="tab-pane fade">
                                            {this.renderRegCF()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const {object, bool} = PropTypes;

EditProjects.propTypes = {
    session: object.isRequired,
    authenticated: bool.isRequired,
    checked: bool.isRequired
};

const mapState = ({session}) => ({
    session: session.user,
    checked: session.checked,
    authenticated: session.authenticated
});

export default withRouter(connect(mapState)(EditProjects));
