import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import NotificationService from '../../../utils/NotificationService';
import RequestService from '../../../api/RequestService';
import { getUUID } from '../../../utils/General';
import Icon from '../IconComponent/IconComponent';
import {
    TRACKING_BY_INVESTOR,
    TRACKING_BY_PROJECT,
    TRACKING_BY_PROJECT_TYPE,
} from '../../../utils/ApplicationConstants';
import SelectComponent from '../InputComponents/SelectComponent/SelectComponent';
import DateInputComponent from '../InputComponents/DateInputComponent/DateInputComponent';
import SingleLineInputComponent from '../InputComponents/SingleLineInputComponent/SingleLineInputComponent';
import InvestorDetailsViewerModal
    from '../../admin/InvestorContainer/InvestorDetailsModalComponent/InvestorDetailsViewerModal';
import TrackingViewerComponent from './component/TrackingViewerComponent';
import HideComponent from '../HideComponent/HideComponent';

class InvestorTrackingContainerComponent extends React.Component {
    FILTER_BY = [
        {
            id: 'projectName',
            title: 'Project Name',
        },
        {
            id: 'eventName',
            title: 'Event Name',
        },
        {
            id: 'createdDate',
            title: 'Created Date',
        },
        {
            id: 'userId',
            title: 'User ID',
        },
    ];

    constructor(props) {
        super(props);

        this.state = {
            investorDetailsModal: null,
            investorTrackedEvents: [],
            filteredTrackedEvents: [],
            isModalOpen: true,
            filter: {
                filterBy: '',
                filterValue: '',
            },
        };

        this.goToFirstPage = () => null;
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getTrackingData();
    }

    setOnPageChange = (method) => {
        this.goToFirstPage = method;
    };

    onInputChange = (event) => {
        const { filter } = this.state;
        const { name, value } = event.target;
        const _filter = { ...filter };

        _filter[name] = value;

        this.setState({ filter: _filter }, this.onFilter);
    };

    onFilter = () => {
        const { investorTrackedEvents, filter } = this.state;
        const { filterBy, filterValue } = filter;

        if (!filterValue || !filter || (filterValue === '') || (filterBy === '')) {
            this.setState({ filteredTrackedEvents: investorTrackedEvents });
            return;
        }

        if (filterBy === 'none') {
            this.setState({
                filteredTrackedEvents: investorTrackedEvents,
                filter: {
                    filterBy: '',
                    filterValue: '',
                },
            });
            return;
        }

        const filteredTrackedEvents = investorTrackedEvents
            .filter(item => !!item[filterBy])
            .map(item => ({
                ...item,
                [filterBy]: item[filterBy].toString(),
            }))
            .filter(item => item[filterBy].toLowerCase()
                .indexOf(filterValue.toLowerCase()) !== -1);

        this.setState({ filteredTrackedEvents },
            () => this.goToFirstPage(1));
    };

    onCloseModal = () => {
        const { closeModal } = this.props;

        this.setState(
            { isModalOpen: false },
            () => setTimeout(closeModal, 500),
        );
    };

    openInvestorDetailsModal = (userId) => {
        const { session } = this.props;

        const investorDetailsModal = (
            <InvestorDetailsViewerModal
                key={getUUID()}
                handleCloseProfileModal={this.closeInvestorDetailsModal}
                session={session}
                UserID={userId}
            />
        );

        this.setState({ investorDetailsModal });
    };

    closeInvestorDetailsModal = () => {
        this.setState({ investorDetailsModal: false });
    };

    // DATA MANAGER
    getTrackingData = async () => {
        let url = '';
        const {
            projectType, getTrackingBy, investorId, projectId,
        } = this.props;

        if (getTrackingBy === TRACKING_BY_INVESTOR) {
            url = `/api/investor/${investorId}/tracking`;
        }
        else if (getTrackingBy === TRACKING_BY_PROJECT) {
            url = `/api/admin/project/${projectId}/projectType/${projectType}/tracking`;
        }
        else if (getTrackingBy === TRACKING_BY_PROJECT_TYPE) {
            url = `/api/admin/project/projectType/${projectType}/tracking`;
        }

        try {
            const { data } = await RequestService.get(url);

            this.setState({
                investorTrackedEvents: data,
                filteredTrackedEvents: data,
            });
        }
        catch (error) {
            console.log('Error extracting the investor tracking data: ERROR: ', error);
            NotificationService.error('Error extracting the investor tracking data', 10000);
        }
    };

    // RENDER MANAGER
    renderFilters = () => {
        const { filter } = this.state;

        return (
            <div className="col-xs-12">
                <div className="col-xs-12 col-sm-6">
                    <SelectComponent
                        name="filterBy"
                        value={filter.filterBy}
                        options={this.FILTER_BY}
                        onChange={this.onInputChange}
                        selectTitle="Filter by"
                        disableNone={false}
                        isRequired
                    />
                </div>

                <HideComponent hide={filter.filterBy === 'createdDate'}>
                    <div className="col-xs-12 col-sm-6">
                        <SingleLineInputComponent
                            inputName="filterValue"
                            inputTitle="Filter value"
                            elementValue={filter.filterValue}
                            onInputUpdate={this.onInputChange}
                            maxInputLength={null}
                            isRequired
                        />
                    </div>
                </HideComponent>

                <HideComponent hide={filter.filterBy !== 'createdDate'}>
                    <div className="col-xs-12 col-sm-6">
                        <DateInputComponent
                            onInputChange={this.onInputChange}
                            elementValue={filter.filterValue}
                            inputTitle="Date to filter"
                            inputName="filterValue"
                            dateType="date"
                            isRequired
                        />
                    </div>
                </HideComponent>
            </div>
        );
    };

    renderTableList = () => {
        const { investorDetailsModal, filteredTrackedEvents } = this.state;

        return (
            <React.Fragment>
                {this.renderFilters()}

                <TrackingViewerComponent
                    setOnPageChange={this.setOnPageChange}
                    trackedEventsList={filteredTrackedEvents}
                    openInvestorDetailsModal={this.openInvestorDetailsModal}
                />

                {investorDetailsModal}
            </React.Fragment>
        );
    };

    render = () => {
        const { isModalOpen } = this.state;
        const { noModal } = this.props;

        if (noModal) {
            return this.renderTableList();
        }

        return (
            <Modal
                className="no-padding no-margin"
                onHide={this.onCloseModal}
                show={isModalOpen}
            >
                <Modal.Header closeButton>
                    <div className="col-xs-12">
                        <h3 className="heading-text">Investor Tracking</h3>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <div className="container">
                        <div className="col-xs-12">
                            {this.renderTableList()}
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <div className="col-xs-12">
                        <div className="pull-right">
                            <button
                                className="btn btn-primary"
                                onClick={this.onCloseModal}
                                type="button"
                            >
                                <Icon iconName="times" extraClasses="white-text"/>
                                {' '}
                                Close
                            </button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    };
}

const mapStateToProps = ({ session }) => ({ session: session.user });

InvestorTrackingContainerComponent.propTypes = {
    closeModal: PropTypes.func,
    getTrackingBy: PropTypes.string,
    investorId: PropTypes.number,
    noModal: PropTypes.bool,
    projectId: PropTypes.number,
    projectType: PropTypes.string,
    session: PropTypes.object.isRequired,
};

InvestorTrackingContainerComponent.defaultProps = {
    closeModal: () => null,
    getTrackingBy: TRACKING_BY_INVESTOR,
    investorId: 0,
    noModal: false,
    projectId: 0,
    projectType: '',
    session: {},
};

export default connect(mapStateToProps)(InvestorTrackingContainerComponent);
