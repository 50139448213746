import React,{Component} from 'react';
class ViewProfile extends Component{


render(){
return(
  <div className="container body">
                <div className="main_container">
                    <div className="right_col" role="main" style={{minHeight: 3753}}>
    <div className="col-md-12 col-sm-12 col-xs-12">
      <div className="col-md-3 "></div>
      <div className="col-md-6 col-sm-6 col-xs-12 panel panel-primary">                    
      <div className="font">
        <h1 style={{textAlign: 'center'}} className="head">Profile</h1>
        <form>
          <div className="form-group">
            <label>Name</label>
            <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />
            </div>
          <div className="form-group">
            <label>Roles</label>
            <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter role" />
            </div>
          <div className="form-group">
            <label>Investments</label>
            <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Investments" />
            </div>
          <div className="form-group">
            <label>Projects</label>
            <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Projects" />
            </div>
          <div className="form-group">
            <label>Earnings</label>
            <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Earnings" />
            </div>
         
          <center><button type="submit" className="btn btn-primary">Submit</button></center>
        </form>
      </div>
      </div>
      <div className="col-md-3 "></div>
    </div>      
      </div>
      </div>
      </div>

)
}
}
export default ViewProfile;
