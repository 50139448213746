import React from 'react';

class BaseComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            charsLeft: props.maxInputLength,
        }
    }

    getRequired = () => {
        const {isRequired} = this.props;
        return isRequired ? <span className="red-text">{' *'}</span> : null;
    };

    onInputChange = (event) => {
        const {value} = event.target;
        const {maxInputLength} = this.props;
        const charsLeft = (maxInputLength - value.length);

        this.setState({charsLeft});

        this.props.onInputUpdate(event);
    };
}

export default BaseComponent