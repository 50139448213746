import React from "react";
import { sanitizeExcerpt } from "../../../utils/data/wordpressPages";
import "./projectStyle.css";
import ProjectMapView from "./ProjectMapView";
import SeeYourFund from "./SeeYourFund";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { LeftArrow, RightArrow } from "./arrows";

export default function Card(props) {
	const {
		projectUpdates,
		fundsWell,
		bitcoinMines,
		landing,
		allProjectsD,
		projectMap,
		seeYourFund,
	} = props;

	const projectMapCollection = projectMap?.map((item) => {
		item.show = false;
		const excerpt = item.excerpt
			.toString()
			?.replace(/<[^>]*>/g, "")
			?.replace(/\n/g, "")
			?.split(",");
		const coordinates = excerpt?.slice(1, 3);
		const parseIDs = excerpt?.slice(8);
		const ProjectedIDs = parseJSONSafely(parseIDs);
		const mapTableDetails = excerpt?.slice(3, 8);

		const sourceUrl =
			item?.featuredImage?.sourceUrl ?? item.featuredImage?.sourceUrl;
		const { id, content, show } = item;
		return {
			id,
			content,
			coordinates,
			show,
			sourceUrl,
			ProjectedIDs,
			mapTableDetails,
		};
	});

	const seeYourFundCollection = seeYourFund?.map((item) => {
		const excerpt = item.excerpt
			.toString()
			?.replace(/<[^>]*>/g, "")
			?.replace(/\n/g, "")
			?.split(",");
			const imgURL = item?.featuredImage?.sourceUrl ?? item.featuredImage?.sourceUrl;
		const parseIDs = excerpt?.slice(3);
		const ProjectedIDs = parseJSONSafely(parseIDs);
		const orderBy = excerpt[1];
		const videoURL = excerpt[2];
		const { id, content, title } = item;
		return { id, content, title,orderBy, videoURL,imgURL, ProjectedIDs };
	});

	const constructCollection = (collection) => {
		return collection.map((item) => {
			const excerpt = item.excerpt
				.toString()
				?.replace(/<[^>]*>/g, "")
				?.replace(/\n/g, "")
				?.split(",");
			const parseIDs = excerpt?.slice(1);
			const ProjectedIDs = parseJSONSafely(parseIDs);
			const { id,date, content, title } = item;
			return { id,date, content, ProjectedIDs, title };
		});
	};
	const bitcoinMinesCollection = constructCollection(bitcoinMines);
	const fundsWellCollection = constructCollection(fundsWell);
	const projectUpdateCollection = constructCollection(projectUpdates);
	const userInvestedProjectsIDs = allProjectsD.map((item) => item.ProjectID);

	function parseJSONSafely(parseIDs) {
		try {
			return parseIDs.length > 0 && JSON.parse(parseIDs);
		} catch (e) {
			// console.log(e);
			return [];
		}
	}
const showProjectUpdate =projectUpdateCollection?.filter((item) =>userInvestedProjectsIDs?.some((elem) =>item?.ProjectedIDs.toString()?.includes(elem)));

const showFundsWell = fundsWellCollection?.filter((item) =>userInvestedProjectsIDs?.some((elem) =>item?.ProjectedIDs.toString()?.includes(elem)));

const showBitcoinMines = bitcoinMinesCollection?.filter((item) =>userInvestedProjectsIDs?.some((elem) =>item?.ProjectedIDs.toString()?.includes(elem)));

return (
		<>
			{showProjectUpdate?.length > 0 && 
				<>
				<ScrollMenu
            LeftArrow={LeftArrow}
            RightArrow={RightArrow}
            options={{
              ratio: 0.9,
              rootMargin: "5px",
              threshold: [0.01, 0.05, 0.5, 0.75, 0.95, 1]
            }}
          >
            {showProjectUpdate?.sort((obj1,obj2)=> Math.max(...obj2.ProjectedIDs) - Math.max(...obj1.ProjectedIDs))?.map((project) => (
					<div className="col-sm-4 card " style={{width:'fit-content'}}>
					<div className=" pt-3" >
						<h2 className={`${!landing && "title"}`} style={{paddingLeft:"8px", width:"max-content"}}>
							{project.title}
						</h2>
						<div
							dangerouslySetInnerHTML={{
								__html: sanitizeExcerpt(project.content, true),
							}}
						/>
					</div>
					</div>
				))}
          </ScrollMenu>
				
				{/* <div className="ppp" style={{display:'flex', overflowX:'overlay', height:'max-content',overflowY:'clip'}}> */}
				{/* {showProjectUpdate?.sort((obj1,obj2)=> Math.max(...obj2.ProjectedIDs) - Math.max(...obj1.ProjectedIDs))?.map((project) => (
					<div className="col-sm-4 card " style={{width:'fit-content'}}>
					<div className=" pt-3" >
						<h2 className={`${!landing && "title"}`} style={{paddingLeft:"8px", width:"max-content"}}>
							{project.title}
						</h2>
						<div
							dangerouslySetInnerHTML={{
								__html: sanitizeExcerpt(project.content, true),
							}}
						/>
					</div>
					</div>
				))} */}
				{/* </div> */}
				</>
			}
		

			{projectMapCollection && landing && (
				<div className={`pt-3 clear mb-5 ${showProjectUpdate?.length === 0 ? 'px-3' :"" }` }>
					<ProjectMapView
						projectMapCollection={projectMapCollection}
						allProjectsD={allProjectsD}
					/>
				</div>
			)}
			{seeYourFundCollection && (
				<div className="prj-update clear mb-5">
					<SeeYourFund seeYourFundCollection={seeYourFundCollection} userInvestedProjectsIDs={userInvestedProjectsIDs} />
				</div>
			)}
			{showFundsWell.length > 0 &&
				landing && (
					<div className="well-update clear">
						<h1>The Fund&apos;s Wells</h1>
						{showFundsWell && landing && showFundsWell?.map((well) => (
									<div className="pb-3 clear">
										<div
											dangerouslySetInnerHTML={{
												__html: sanitizeExcerpt(
													well.content,
													true
												),
											}}
										/>
									</div>
								))}
					</div>
				)}

			{showBitcoinMines &&
				showBitcoinMines?.length > 0 &&
				landing && (
					<div className="well-update">
						<h1>The Bitcoin Mines</h1>
						{showBitcoinMines &&
							landing &&
							showBitcoinMines?.map((bitcoinMine) => (
									<div className="pb-3 clear">
										<div
											dangerouslySetInnerHTML={{
												__html: sanitizeExcerpt(
													bitcoinMine.content,
													true
												),
											}}
										/>
									</div>
								))}
					</div>
				)}
		</>
	);
}
