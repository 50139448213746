/**
 * Created by goutham on 9/13/2017.
 */
import React, {Component} from 'react';
import swal from 'sweetalert2';
import store from '../../index'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import getDate from '../../utils/ConvertDate'


class Admindealcard1 extends Component {
    constructor(props){
        super(props);
        this.state={
            id:props.session.id,
            URL:"",
            Roles:[],
            Aprovedprojects:[],
            CurrentFunding:[],
            Cashcallprojects:[],
            isLoaded:false,
            errorOnLoad:false
        };
        this.getApprovedprojects=this.getApprovedprojects.bind(this);
        // this.getCashcallprojects=this.getCashcallprojects.bind(this);
        this.onAddProject=this.onAddProject.bind(this);
        this.onDealClick=this.onDealClick.bind(this);
        this.getRoles=this.getRoles.bind(this);
        this.getCurrentFunding=this.getCurrentFunding.bind(this)
    }
    componentWillMount(){
        window.scrollTo(0, 0);
    }
    onDealClick(id, type, e) {
        if(type === "notify") {
            e.stopPropagation();
             this.props.history.push({
                              pathname: '/app/projectnotification',
                              state: { id: id }
                            })
            // this.props.history.push({
            //                   pathname: '/app/projectNotification',
            //                   state: { id: id }
            //                 })

        } else {
            this.props.history.push({
                pathname: '/app/deal',
                state: { id: id }
              })
        }
    }

    getCurrentFunding(){
        fetch('/api/getcurrentfunding'+'/'+this.state.id+'/'+this.props.session.marketing,{
            method:'get'
        })
            .then((response)=>{
                return response.json()
            })
            .then((data)=>{
                this.setState({
                    CurrentFunding:data,
                    isLoaded:true,
                    err:{},
                    errorOnLoad:false,
                });
                var result = this.state.CurrentFunding.filter( obj => obj.ProjectID_RegD === 2)[0].CurrentFunding;
                //var result = this.state.CurrentFunding.find(x => x.ProjectID_RegD === 2).CurrentFunding;
            })
            .catch((err)=>{
                this.setState({
                    err:err,
                    errorOnLoad:true
                })
            })
    }

    // getCashcallprojects(){
    //     fetch('/api/getprojectinvestments',{
    //         method:'get'
    //     })
    //         .then((response)=>{
    //             return response.json()
    //         })
    //         .then((data)=>{
    //             this.setState({
    //                 Cashcallprojects:data,
    //                 isLoaded:true,
    //                 err:{},
    //                 errorOnLoad:false,
    //             });
    //         })
    //         .catch((err)=>{
    //             this.setState({
    //                 err:err,
    //                 errorOnLoad:true
    //             })

    //         })
    // }

    getRoles(){
        fetch('/api/getrole/'+this.state.id+'/'+this.state.id+'/'+this.props.session.marketing,{
            method:'get'
        })
            .then((response)=>{
                return response.json()
            })
            .then((data)=>{
                this.setState({
                    Roles:data,
                    isLoaded:true,
                    err:{},
                    errorOnLoad:false,
                });

            })
            .catch((err)=>{
                this.setState({
                    err:err,
                    errorOnLoad:true
                })
            })
    }

    getApprovedprojects(){
        fetch('/api/getapprovedprojects'+'/'+this.state.id+'/'+this.props.session.marketing,{
            method:'get'
        })
            .then((response)=>{
                return response.json()
            })
            .then((data)=>{
                this.setState({
                    Aprovedprojects:data,
                    isLoaded:true,
                    err:{},
                    errorOnLoad:false,
                });
            })
            .catch((err)=>{
                this.setState({
                    err:err,
                    errorOnLoad:true
                })
            })
    }

    onAddProject(){
        fetch('/api/rolecheck/'+this.state.id+'/'+this.props.session.marketing,{
            method:'get'
        })
            .then((response)=>{
                return response.json()
            })
            .then((status)=>{
                if(status.code===200){
                    swal({
                        title: "Please Select Project Type:",
                        showCancelButton: true,
                        confirmButtonText: "Regulation D",
                        cancelButtonText: "Regulation CF",
                        closeOnConfirm: false,
                        closeOnCancel: true,
                        showLoaderOnConfirm: true
                    }).then((isConfirm)=>{
                        if (isConfirm.value===true) {
                            this.props.history.push('/app/AdminaddProject_D')
                        }
                        else {
                            this.props.history.push('/app/AdminaddProject_CF')
                        }
                    })


                }
                else if(status.code === 402){
                    swal("Access Denied", " ", "error")
                }
                else if(status.code === 500){
                    swal("Access Denied", " ", "error")
                }
            })
            .catch((err) => {
                alert("Error: " + JSON.stringify(err))
            })
    }

    async componentDidMount(){
        await this.getCurrentFunding();
        this.getApprovedprojects();
        // this.getCashcallprojects();
        this.getRoles();
    }

    render() {
        if(this.state.Roles.length>1){
            return (
                <div className="container body">
                    <div className="main_container">
                        {/* page content */}
                        <div className="right_col" role="main">
                            <div className="col-md-10 col-sm-10 col-xs-8">
                            </div>
                            <div className="col-md-2 col-sm-2 col-xs-4">
                                <button onClick={this.onAddProject} className="fa fa-plus-circle btn btn-primary space">&nbsp;&nbsp;Add Project&nbsp;&nbsp;</button>
                            </div>
                            {/* <div className="row">
                                {(() => {
                                    switch (this.state.Cashcallprojects.length) {
                                        case 0  : return (<h3>No Active CashCall Investments Opportunities</h3>);
                                        case 1  : return (<h3>Active CashCall Investment Opportunities</h3>);
                                        default : return (<h3>Active CashCall Investment Opportunities</h3>);
                                    }
                                })()}
                            {
                                this.state.Cashcallprojects.map((project)=>(
                                    <span style={{cursor:'pointer'}}><div key={project.ProjectInvestmentID} onClick={()=>this.onDealClick(project.ProjectInvestmentID)} className="col-xs-6 col-md-3 col-sm-4 col-lg-3">
                                    <div className="project project-radius project-primary">
                                        <div className="shape">
                                            <div className="shape-text">
                                                New
                                            </div>
                                        </div>
                                        <img className="topimage" height="175" width="100%" src="/img/image.jpg" alt=""/>

                                        <div className="project-content">

                                            <h3 className="lead">
                                                {(() => {
                                                    switch (project.IsCashCall) {
                                                        case 0  : return (project.ProjectName);
                                                        case 1  : return (project.ProjectName)+" - Cash Call";
                                                        default : return (project.ProjectName);
                                                    }
                                                })()}

                                            </h3>
                                            <p>
                                                Company : {project.OperatedBy}
                                                <br/>Opens On : {project. DateInvestmentStart.slice(0,10)}
                                            </p>
                                            <b>
                                                {(() => {
                                                    switch (project.IsCashCall) {
                                                        case 0  : return ('$'+(project.currentFunding)+'/'+'$'+(project.FundingGoal));
                                                        case 1  : return ('$'+(project.ccCurrentFunding)+'/'+'$'+(project.Amount));
                                                        default : return ('$'+(project.currentFunding)+'/'+'$'+(project.FundingGoal));
                                                    }
                                                })()}</b>
                                            {(() => {
                                                switch (project.IsCashCall) {
                                                    case 0  : return (<div className="progress" style={{width:'100%'}}>
                                                        <div className="progress-bar" role="progressbar" aria-valuenow={70} aria-valuemin={0} aria-valuemax={100} style={{width: ""+(project.currentFunding/project.FundingGoal)*100+"%"}}></div>
                                                    </div>);
                                                    case 1  : return (<div className="progress" style={{width:'100%'}}>
                                                        <div className="progress-bar" role="progressbar" aria-valuenow={70} aria-valuemin={0} aria-valuemax={100} style={{width: ""+(project.ccCurrentFunding/project.Amount)*100+"%"}}></div>
                                                    </div>);
                                                    default : return (<div className="progress" style={{width:'100%'}}>
                                                        <div className="progress-bar" role="progressbar" aria-valuenow={70} aria-valuemin={0} aria-valuemax={100} style={{width: ""+(project.currentFunding/project.FundingGoal)*100+"%"}}></div>
                                                    </div>);
                                                }
                                            })()}


                                            <div> {(() => {
                                                switch (project.IsCashCall) {
                                                    case 0  : return (Math.round((project.currentFunding/project.FundingGoal)*100)+'%');
                                                    case 1  : return (Math.round((project.ccCurrentFunding/project.Amount)*100)+'%');
                                                    default : return (Math.round((project.currentFunding/project.FundingGoal)*100)+'%');
                                                }
                                            })()}</div><br/>
                                        </div>
                                    </div>
                                </div></span>
                                ))
                            }
                        </div> */}
                            <div className="row">
                                {(() => {
                                    switch (this.state.Aprovedprojects.length) {
                                        case 0  : return (<h3>No Active Investment Opportunities</h3>);
                                        case 1  : return (<h3>Active Investment Opportunities</h3>);
                                        default : return (<h3>Active Investment Opportunities</h3>);
                                    }
                                })()}
                                {
                                    this.state.Aprovedprojects.map((project)=>(
                                        <span style={{cursor:'pointer'}}><div key={project.ProjectID} onClick={(e)=>this.onDealClick(this,project.ProjectInvestmentID,'deal',e)} className="col-xs-6 col-md-3 col-sm-4 col-lg-3">
                                    <div className="project project-radius project-primary">
                                        <div className="shape">
                                            <div className="shape-text">
                                                New
                                            </div>
                                        </div>
                                        <img className="topimage" height="175" width="100%" src="/img/image.jpg" alt=""/>

                                        <div className="project-content">

                                            <h3 className="lead">
                                                {(() => {
                                                    switch (project.IsCashCall) {
                                                        case 0  : return (project.ProjectName);
                                                        case 1  : return (project.ProjectName)+" - Cash Call";
                                                        default : return (project.ProjectName);
                                                    }
                                                })()}

                                            </h3>
                                            <p>
                                                Company : {project.OperatedBy}
                                                <br/>Opens On : {getDate.getDate(project.DateInvestmentStart)}
                                            </p>
                                            <b>
                                                {(() => {
                                                    switch (project.IsCashCall) {
                                                        case 0  : return ('$'+project.currentFunding+'/'+'$'+(project.FundingGoal));
                                                        case 1  : return ('$'+(project.ccCurrentFunding)+'/'+'$'+(project.Amount));
                                                        default : return ('$'+(project.currentFunding)+'/'+'$'+(project.FundingGoal));
                                                    }
                                                })()}</b>
                                            {(() => {
                                                switch (project.IsCashCall) {
                                                    case 0  : return (<div className="progress" style={{width:'100%'}}>
                                                        <div className="progress-bar" role="progressbar" aria-valuenow={70} aria-valuemin={0} aria-valuemax={100} style={{width: ""+(project.currentFunding/project.FundingGoal)*100+"%"}}></div>
                                                    </div>);
                                                    case 1  : return (<div className="progress" style={{width:'100%'}}>
                                                        <div className="progress-bar" role="progressbar" aria-valuenow={70} aria-valuemin={0} aria-valuemax={100} style={{width: ""+(project.ccCurrentFunding/project.Amount)*100+"%"}}></div>
                                                    </div>);
                                                    default : return (<div className="progress" style={{width:'100%'}}>
                                                        <div className="progress-bar" role="progressbar" aria-valuenow={70} aria-valuemin={0} aria-valuemax={100} style={{width: ""+(project.currentFunding/project.FundingGoal)*100+"%"}}></div>
                                                    </div>);
                                                }
                                            })()}


                                            <div> {(() => {
                                                switch (project.IsCashCall) {
                                                    case 0  : return (Math.round((project.currentFunding/project.FundingGoal)*100)+'%');
                                                    case 1  : return (Math.round((project.ccCurrentFunding/project.Amount)*100)+'%');
                                                    default : return (Math.round((project.currentFunding/project.FundingGoal)*100)+'%');
                                                }
                                            })()}</div><br/>
                                                <button className="btn btn-primary" onClick={(e) => this.onDealClick(project.ProjectInvestmentID, 'notify', e)} style={{ width: '100%' }}>Notify</button>
                                        </div>
                                    </div>
                                </div></span>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else{
            return (
                <div className="container body">
                    <div className="main_container">
                        {/* page content */}
                        <div className="right_col" role="main">
                            <div className="col-md-10 col-sm-10 col-xs-8">
                            </div>
                            <div className="row">
                                {(() => {
                                    switch (this.state.Cashcallprojects.length) {
                                        case 0  : return (<h3>No Active CashCall Investments Opportunities</h3>);
                                        case 1  : return (<h3>Active CashCall Investment Opportunities</h3>);
                                        default : return (<h3>Active CashCall Investment Opportunities</h3>);
                                    }
                                })()}
                            {
                                this.state.Cashcallprojects.map((project)=>(
                                        <span  style={{ cursor: 'pointer' }}><div key={project.ProjectID}  onClick={() => this.onDealClick(project.ProjectInvestmentID)} className="col-xs-6 col-md-3 col-sm-4 col-lg-3">
                                    <div className="project project-radius project-primary">
                                        <div className="shape">
                                            <div className="shape-text">
                                                New
                                            </div>
                                        </div>
                                        <img className="topimage" height="175" width="100%" src="/img/image.jpg" alt=""/>

                                        <div className="project-content">

                                            <h3 className="lead">
                                                {(() => {
                                                    switch (project.IsCashCall) {
                                                        case 0  : return (project.ProjectName);
                                                        case 1  : return (project.ProjectName)+" - Cash Call";
                                                        default : return (project.ProjectName);
                                                    }
                                                })()}

                                            </h3>
                                            <p>
                                                Company : {project.OperatedBy}
                                                <br/>Opens On : {getDate.getDate(project.DateInvestmentStart)}
                                            </p>
                                            <b>
                                                {(() => {
                                                    switch (project.IsCashCall) {
                                                        case 0  : return ('$'+(project.currentFunding)+'/'+'$'+(project.FundingGoal));
                                                        case 1  : return ('$'+(project.ccCurrentFunding)+'/'+'$'+(project.Amount));
                                                        default : return ('$'+(project.currentFunding)+'/'+'$'+(project.FundingGoal));
                                                    }
                                                })()}</b>
                                            {(() => {
                                                switch (project.IsCashCall) {
                                                    case 0  : return (<div className="progress" style={{width:'100%'}}>
                                                        <div className="progress-bar" role="progressbar" aria-valuenow={70} aria-valuemin={0} aria-valuemax={100} style={{width: ""+(project.currentFunding/project.FundingGoal)*100+"%"}}></div>
                                                    </div>);
                                                    case 1  : return (<div className="progress" style={{width:'100%'}}>
                                                        <div className="progress-bar" role="progressbar" aria-valuenow={70} aria-valuemin={0} aria-valuemax={100} style={{width: ""+(project.ccCurrentFunding/project.Amount)*100+"%"}}></div>
                                                    </div>);
                                                    default : return (<div className="progress" style={{width:'100%'}}>
                                                        <div className="progress-bar" role="progressbar" aria-valuenow={70} aria-valuemin={0} aria-valuemax={100} style={{width: ""+(project.currentFunding/project.FundingGoal)*100+"%"}}></div>
                                                    </div>);
                                                }
                                            })()}


                                            <div> {(() => {
                                                switch (project.IsCashCall) {
                                                    case 0  : return (Math.round((project.currentFunding/project.FundingGoal)*100)+'%');
                                                    case 1  : return (Math.round((project.ccCurrentFunding/project.Amount)*100)+'%');
                                                    default : return (Math.round((project.currentFunding/project.FundingGoal)*100)+'%');
                                                }
                                            })()}</div><br/>
                                        </div>
                                    </div>
                                </div></span>
                                ))
                            }
                        </div>
                            <div className="row">
                                {(() => {
                                    switch (this.state.Aprovedprojects.length) {
                                        case 0  : return (<h3>No Active Investment Opportunities</h3>);
                                        case 1  : return (<h3>Active Investment Opportunities</h3>);
                                        default : return (<h3>Active Investment Opportunities</h3>);
                                    }
                                })()}
                                {
                                    this.state.Aprovedprojects.map((project)=>(
                                        <span style={{cursor:'pointer'}}><div key={project.ProjectID} onClick={()=>this.onDealClick(project.ProjectInvestmentID)} className="col-xs-6 col-md-3 col-sm-4 col-lg-3">
                                    <div className="project project-radius project-primary">
                                        <div className="shape">
                                            <div className="shape-text">
                                                New
                                            </div>
                                        </div>
                                        <img className="topimage" height="175" width="100%" src="/img/image.jpg" alt=""/>

                                        <div className="project-content">

                                            <h3 className="lead">
                                                {(() => {
                                                    switch (project.IsCashCall) {
                                                        case 0  : return (project.ProjectName);
                                                        case 1  : return (project.ProjectName)+" - Cash Call";
                                                        default : return (project.ProjectName);
                                                    }
                                                })()}

                                            </h3>
                                            <p>
                                                Company : {project.OperatedBy}
                                                <br/>Opens On : {getDate.getDate(project.DateInvestmentStart)}
                                            </p>
                                            <b>
                                                {(() => {
                                                    switch (project.IsCashCall) {
                                                        case 0  : return ('$'+(project.currentFunding)+'/'+'$'+(project.FundingGoal));
                                                        case 1  : return ('$'+(project.ccCurrentFunding)+'/'+'$'+(project.Amount));
                                                        default : return ('$'+(project.currentFunding)+'/'+'$'+(project.FundingGoal));
                                                    }
                                                })()}</b>
                                            {(() => {
                                                switch (project.IsCashCall) {
                                                    case 0  : return (<div className="progress" style={{width:'100%'}}>
                                                        <div className="progress-bar" role="progressbar" aria-valuenow={70} aria-valuemin={0} aria-valuemax={100} style={{width: ""+(project.currentFunding/project.FundingGoal)*100+"%"}}></div>
                                                    </div>);
                                                    case 1  : return (<div className="progress" style={{width:'100%'}}>
                                                        <div className="progress-bar" role="progressbar" aria-valuenow={70} aria-valuemin={0} aria-valuemax={100} style={{width: ""+(project.ccCurrentFunding/project.Amount)*100+"%"}}></div>
                                                    </div>);
                                                    default : return (<div className="progress" style={{width:'100%'}}>
                                                        <div className="progress-bar" role="progressbar" aria-valuenow={70} aria-valuemin={0} aria-valuemax={100} style={{width: ""+(project.currentFunding/project.FundingGoal)*100+"%"}}></div>
                                                    </div>);
                                                }
                                            })()}


                                            <div> {(() => {
                                                switch (project.IsCashCall) {
                                                    case 0  : return (Math.round((project.currentFunding/project.FundingGoal)*100)+'%');
                                                    case 1  : return (Math.round((project.ccCurrentFunding/project.Amount)*100)+'%');
                                                    default : return (Math.round((project.currentFunding/project.FundingGoal)*100)+'%');
                                                }
                                            })()}</div><br/>
                                        </div>
                                    </div>
                                </div></span>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

    }
}

const { object, bool } = PropTypes;

Admindealcard1.propTypes = {
    session: object.isRequired,
    authenticated: bool.isRequired,
    checked: bool.isRequired
};

const mapState = ({ session }) => ({
    session: session.user,
    checked: session.checked,
    authenticated: session.authenticated
});

export default withRouter(connect(mapState)(Admindealcard1));
