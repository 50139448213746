import React, { Component } from "react";
import Chart from "react-apexcharts";
import moment from "moment";
import { Link } from "react-router-dom";
import { formatChartLabel,formatChartMoney } from "../../../utils/General";
class InvestorEarningsChart extends Component {
	constructor(props) {
		super(props);
		
	}
	render() {
		const { investmentLabels, cumulativeEarnings,cumulativeDistributions, investmentAmounts,distributionEarningChartData } =this.props;

		
		const setColumnWidth = () => {

			const seriesLength  =	investmentLabels?.length;
		if(seriesLength > 2){
			return "50%";
			}
			else if(seriesLength  <= 2){
			
				return  "5%";
			}
		
			
		}	
		const series = [{
			name: 'Distributions',
			data:  distributionEarningChartData
			// [
			// 	[
			// 		1583001000000,
			// 		487.84
			// 	],
			// 	[
			// 		1585679400000,
			// 		662.03
			// 	],
			// 	[
			// 		1588271400000,
			// 		395.6600000000001
			// 	],
			// 	[
			// 		1590949800000,
			// 		254.77999999999997
			// 	],
			// 	[
			// 		1593541800000,
			// 		107.00999999999999
			// 	],
			// 	[
			// 		1596220200000,
			// 		138.57000000000016
			// 	],
			// 	[
			// 		1598898600000,
			// 		145.06999999999994
			// 	],
			// 	[
			// 		1609439400000,
			// 		164.55000000000018
			// 	],
			// 	[
			// 		1612117800000,
			// 		153.94999999999982
			// 	],
			// 	[
			// 		1617215400000,
			// 		142.01999999999998
			// 	],
			// 	[
			// 		1619807400000,
			// 		72.07999999999993
			// 	],
			// 	[
			// 		1622485800000,
			// 		118.5
			// 	],
			// 	[
			// 		1625077800000,
			// 		69.73000000000002
			// 	],
			// 	[
			// 		1627756200000,
			// 		57.99000000000024
			// 	],
			// 	[
			// 		1630434600000,
			// 		171.67999999999984
			// 	],
			// 	[
			// 		1633026600000,
			// 		166.40999999999985
			// 	],
			// 	[
			// 		1635705000000,
			// 		165.71000000000004
			// 	],
			// 	[
			// 		1638297000000,
			// 		174.5999999999999
			// 	],
			// 	[
			// 		1640975400000,
			// 		210.35000000000036
			// 	],
			// 	[
			// 		1643653800000,
			// 		60.75999999999976
			// 	],
			// 	[
			// 		1646073000000,
			// 		63.73000000000002
			// 	],
			// 	[
			// 		1648751400000,
			// 		191.51999999999998
			// 	],
			// 	[
			// 		1651343400000,
			// 		79.9399999999996
			// 	],
			// 	[
			// 		1654021800000,
			// 		321.40000000000055
			// 	],
			// 	[
			// 		1659292200000,
			// 		86.14999999999964
			// 	],
			// 	[
			// 		1661970600000,
			// 		217.82000000000062
			// 	]
			// ]
		},
		
		];

		const options = {
			tooltip: {
    
				y: {
			   formatter: function(val, index) {
				   return "$"+val?.toFixed(2);
				 }
			  
		   },
	   },
    // tooltip: {
    //   x: {
    //     format: 'dd MMM yyyy'
    //   }
    // },
			chart: {
				fontFamily: 'Montserrat, Arial, sans-serif',
				type: 'column',
				toolbar: {
					show:false},
			}, 
			colors: ["#5bb462"],
		dataLabels: {
				enabled: false,
			},
			plotOptions: {
				bar: {
					columnWidth: setColumnWidth(),
        //   columnWidth: '100%',
				},
			},
	
			grid: {
				borderColor: "#e7e7e7",
				row: {
					colors: ["#e7e7e7", "transparent"], // takes an array which will be repeated on columns
					//   opacity: 0.5
				},
			},
			markers: {
				size: 4,
			},
			xaxis: {
				type: "datetime",

			  },
			// labels: investmentLabels,-
      yaxis: {
        labels: {
          formatter: function (value) {
            return  "$"+value;
          },
        },
      },
			
		};

		return (
			<>
				<div className="container white-bg  mb-2 clearfix rounded-top ">
					<div className="col-md-12 col-xs-12 px-0">
						<h2>{this.props?.title}</h2>
						<Chart
							options={options}
							series={series}
							height={260}
							type ="bar"
						/>
					</div>
				</div>
			</>
		);
	}
}

export default InvestorEarningsChart;
