import React from 'react'
import { Button, Modal } from "react-bootstrap";
export default function GPVotingModal(props) {
  return (
    <div>
       		<Modal
					show={props.showGPModal}
					style={{ width: '90%', margin: '0 auto' }} className="gplp-voting-modal"
				>
						<Modal.Header>
							<Modal.Title><h3>Greetings, Yield Fund I Investor!</h3>
						<p>We have a some follow-up logistics related to your investment in Yield Fund I. Please help by
voting below.</p></Modal.Title>
						</Modal.Header>

						<Modal.Body>
								<form>
                                    <div className="col-md-12">
									<h3>Please Vote</h3>
						<p>You are an investor in EnergyFunders Yield Fund I GP LLC, which we refer to as the
“Company.” We would like to ask for your consent for the merger of the Company into
EnergyFunders Yield Fund I LLC (the “LP Fund”) and the adoption of a new Limited Liability
Company Agreement.</p>
								<h3>Background</h3>
								<p>Both the Company and the LP Fund are operated by EnergyFunders. Both funds invest in the
same asset classes, have the same investment strategies, and are run by the same people.</p>
<p>The only notable difference between the two funds is in the personal liability assumed by
investors. Investors in the Company assume personal liability for a share of all the liabilities of
the entity. Investors in the LP Fund, on the other hand, assume no personal liability other than
the amount they invest.</p>
<p>We are now recommending a merger of the Company into the LP Fund. By merging the two
entities we expect to save operating and administrative costs, simplify recordkeeping, and create
a larger asset base.</p>
<p>As an investor in the Company, you will continue to be liable for liabilities of that entity and also
for liabilities of the merged companies incurred following the merger. Investors in the LP Fund
will not be liable for any liabilities of the merged companies.</p>
<p>As part of the merger, the combined entity will adopt a new Limited Liability Company
Agreement. Click <a className='font-bold text-underline' href="/documents/Second-Amended-and-Restated-LLC-Agreement-(combined)-EnergyFunders-Yield-Fund-I-Final.pdf" target="_blank"> here </a> to access a copy of the new Agreement. This Limited Liability Company
Agreement is substantially the same as the Company’s current Limited Liability Company
Agreement.</p>
<h3>Voting</h3>
<p>The merger of the two companies and the adoption of the new Limited Liability Company
Agreement require the consent of a majority of the investors in each company, measured by the
number of shares owned.</p>
<p>Please indicate below whether you approve or disapprove of these proposed actions by voting
below. You may also indicate that you would like us to conduct an online meeting to discuss the
proposed actions. We will conduct such a meeting if investors owning at least 20% of the shares
want one. In that case we will postpone the vote until after the meeting.</p>
<p>Thank you for your investment and your time.</p>
<p className='text-center'>Very truly yours,</p>
<h3 className='text-center'>EF MANAGER, LLC</h3>

						 

                                        <div className="form-group mt-5 mb-0">
                                       	<div className="check_tc" style={{marginTop: 0}}>
																<label className="custom-container">I APPROVE of the proposed actions.
																	<input type="radio"  name="agreement" 
																		   onClick={() =>props.handleGPLPVotings("Yes","gp")}
																	/>
																	<span className="checkmark" />
																</label>
															</div>
										  
										  </div>
										<div className="form-group">
										<div className="check_tc" style={{marginTop: 0}}>
																<label className="custom-container">I DO NOT APPROVE of the proposed actions.
																	<input type="radio"  name="agreement" 
																		   onClick={() =>props.handleGPLPVotings("No","gp")}
																	/>
																	<span className="checkmark" />
																</label>
															</div>
								   </div>
								   <div className="form-group">
										<div className="check_tc" style={{marginTop: 0}}>
																<label className="custom-container">I want you to postpone the vote and conduct an online meeting.
																	<input type="radio"  name="agreement" 
																		   onClick={() =>props.handleGPLPVotings("Postpone the vote and conduct an online meeting","gp")}
																	/>
																	<span className="checkmark" />
																</label>
															</div>
								   </div>
                                    </div>

                                </form>

                               
                                <div className="form-group col-md-5">
								<a className="" href="/documents/Second-Amended-and-Restated-LLC-Agreement-(combined)-EnergyFunders-Yield-Fund-I-Final.pdf" target="_blank">
                                        <button 
										   className="btn btn-primary btn-green px-md-5 py-3 ml-3"
                                            style={{ margin: '30px 0px 0px' }}
                                        >LLC agreement
                                        </button>
                                        <br/>
                                    </a>
                                    

                                </div>
								<div className="form-group col-md-2 col-sm-2">
								<div className="">
                                        <button 
                                            type="Submit"
                                            id="submit"
											disabled={props?.submitDisabled}
                                           onClick={(e)=>props.submitVotingsForm(e,"gp")}
										   className="btn btn-primary btn-green px-5 py-3 ml-3"
                                            style={{ margin: '30px 0px 0px' }}
                                        >Submit
                                        </button>
                                        <br/>
                                    </div>

                                </div>
						</Modal.Body>
						<Modal.Footer>
						<Button onClick={props.showGPModalHandler}>Close</Button>
						</Modal.Footer>
					</Modal>
    </div>
  )
}
