/*
 * This page is called in "Admin Menu" - "User Investments" menu item.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Pagination from 'react-js-pagination';
import { Modal } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import getDate from '../../utils/ConvertDate';
import InvestorDetailsInvestmentsEarningsModal
	from './InvestorContainer/InvestorDetailsModalComponent/InvestorDetailsViewerModal';

import store from '../../index';
import RequestService from '../../api/RequestService';
import { getStatusFromCode, getUUID, formatMoney, Yield_Fund_2_ID, America_First_Energy_Fund_1 } from '../../utils/General';
import Icon from '../shared/IconComponent/IconComponent';
import InvestorTrackingContainerComponent
	from '../shared/InvestorTrackingContainerComponent/InvestorTrackingContainerComponent';

import { PROJECT_REG_CF, PROJECT_REG_D, TRACKING_BY_PROJECT } from '../../utils/ApplicationConstants';
import swal from 'sweetalert';
import swal2 from 'sweetalert2';



class Adminuserinvestments extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showRegCFListModal: false,
			showRegDListModal: false,
			showSignedCAModal: false,
			showCreatePlaceholderModal: false,
			showConvertInvestmentModal: false,
			showConfirmCryptoInvestmentModal: false,

			ViewsD: [],
			ViewsD_CSV: [],
			Views: [],
			ViewSignedCAs: [],
			Invester: [],
			totalProjects: [],
			allProjects: [],
			pageNumber: 1,
			totalCount: 0,
			perPage: 10,
			activePage: 1,

			InvesterCF: [],
			totalProjectsCF: [],
			allProjectsCF: [],
			pageNumberCF: 1,
			totalCountCF: 0,
			perPageCF: 10,
			activePageCF: 1,

			id: props.session.id,
			isLoaded: false,
			errorOnLoad: false,

			doFullDownloadD: false,
			doFullDownloadCF: false,
			fullDownloadDataD: {},
			fullDownloadDataCF: {},

			InvestorDetailsInvestmentsEarningsModal: null,
			investorTrackingModal: null,
			lastViewedProjectID: 0,
			lastViewedProjectTitle: '',
			lastViewedProjectEntity: '',
			lastViewedProjectIsCashCall: 0,
			lastViewedProjectCurrentFunding: 0,
			convertInvestmentID: 0,
			confirmCryptoInvestmentID: 0,
			cashCallComparisonOn: 0,

			K1Data: [],
			K1DataFilename: ''
		};

		this.handlePageChangeD = this.handlePageChangeD.bind(this);
		this.handlePageChangeCF = this.handlePageChangeCF.bind(this);

		this.handleCloseRegDListModal = this.handleCloseRegDListModal.bind(this);
		this.handleCloseRegCFListModal = this.handleCloseRegCFListModal.bind(this);

		this.showCreatePlaceholderForm = this.showCreatePlaceholderForm.bind(this);
		this.showSampleAmounts = this.showSampleAmounts.bind(this);
		this.handleCloseCreatePlaceholderModal = this.handleCloseCreatePlaceholderModal.bind(this);

		this.getAllInvestorInfo = this.getAllInvestorInfo.bind(this);
		this.handleCloseProfileModal = this.handleCloseProfileModal.bind(this);
		this.handleCloseSignedCAModal = this.handleCloseSignedCAModal.bind(this);

		this.showConvertPlaceholderInvestment = this.showConvertPlaceholderInvestment.bind(this);
		this.handleCloseConvertInvestmentModal = this.handleCloseConvertInvestmentModal.bind(this);
		this.doConvertPlaceholderInvestment = this.doConvertPlaceholderInvestment.bind(this);

		this.showConfirmCryptoInvestment = this.showConfirmCryptoInvestment.bind(this);
		this.handleCloseConfirmCryptoInvestmentModal = this.handleCloseConfirmCryptoInvestmentModal.bind(this);
		this.doConfirmCryptoInvestment = this.doConfirmCryptoInvestment.bind(this);

		this.fullDataExport = this.fullDataExport.bind(this);
		this.createPlaceholderInvestment = this.createPlaceholderInvestment.bind(this);
		this.deletePlaceholderInvestment = this.deletePlaceholderInvestment.bind(this);
		this.compareToParentProject = this.compareToParentProject.bind(this);
	}



	componentDidMount() {
		window.scrollTo(0, 0);

		store.subscribe(() => {
			const currentState = store.getState();

			this.setState({
				id: currentState.session.user.id,
			});
		});

		this.getAllProjectInvestments();
	}



	async getAllProjectInvestments() {
		try {
			const { data } = await RequestService.get('/api/getAllProjectInvestments');

			if (data.code === 200) {
				this.setState({
					Invester: data.reg_d,
					totalProjects: data.reg_d,
					allProjects: data.reg_d,
					pageNumber: 1,
					totalCount: data.reg_d.length,
					perPage: 10,
					activePage: 1,

					InvesterCF: data.reg_cf.slice(0, 10),
					totalProjectsCF: data.reg_cf,
					allProjectsCF: data.reg_cf,
					pageNumberCF: 1,
					totalCountCF: data.reg_cf.length,
					perPageCF: 10,
					activePageCF: 1,

					MainRole: 'Investor',
					isLoaded: true,
					err: {},
					errorOnLoad: false,
				});
			}
			else {
				alert('Could not get list of project investments.');
			}
		} catch (err) {
			console.log(err);
		}
	}



	handleCloseRegDListModal() {
		this.setState({ showRegDListModal: false });
	}



	handleCloseRegCFListModal() {
		this.setState({ showRegCFListModal: false });
	}



	handleCloseCreatePlaceholderModal() {
		this.setState({ showCreatePlaceholderModal: false });
	}



	handlePageChangeD(pageNumber) {
		const prev = ((pageNumber) - 1) * 10;
		const next = prev + 10;

		this.setState({
			activePage: pageNumber,
			Invester: this.state.allProjects.slice(prev, next),
		});
	}



	handlePageChangeCF(pageNumber) {
		const prev = ((pageNumber) - 1) * 10;
		const next = prev + 10;

		this.setState({
			activePage: pageNumber,
			InvesterCF: this.state.allProjectsCF.slice(prev, next),
		});
	}



	async onViewD(id, name, entity, isCashCall) {
		try {
			const { data } = await RequestService.get(`/api/getInvestmentsForProjectD/${id}`);

			if (data.code === 200) {

				let ViewsD_CSV = data.data.filter((item) => item.Deleted !== 1);

				this.setState({
					ViewsD: data.data,
					ViewsD_CSV: ViewsD_CSV,
					fileName: name,
					showRegDListModal: true,
					lastViewedProjectID: id,
					lastViewedProjectTitle: name,
					lastViewedProjectEntity: entity,
					lastViewedProjectIsCashCall: isCashCall,
					lastViewedProjectCurrentFunding: formatMoney(data.projectCurrentFunding),
					cashCallComparisonOn: 0
				});

				if (isCashCall > 0) {
					this.refs.CompareButton.style.display = '';
					this.refs.closeCompareButton.style.display = 'none';
				}
			}
			else {
				alert('Could not get the list of Reg D Investors.');
			}
		}
		catch (err) {
			console.log(err);
		}
	}



	async onViewSignedCA(id, name, entity) {
		try {
			const { data } = await RequestService.get(`/api/getSignedCADataForProject/${id}`);

			if (data.code === 200) {
				this.setState({
					ViewSignedCAs: data.data,
					fileName: name,
					showSignedCAModal: !this.state.showSignedCAModal,
					lastViewedProjectTitle: name,
					lastViewedProjectEntity: entity
				});
			}
			else {
				alert('Could not get Signed CA Data.');
			}
		}
		catch (err) {
			console.log(err);
		}
	}



	async onViewCF(id, name) {
		try {
			const { data } = await RequestService.get(`/api/getInvestmentsForProjectCF/${id}`);

			if (data.code === 200) {
				this.setState({
					Views: data.data,
					fileName: name,
					isLoaded: true,
					err: {},
					errorOnLoad: false,
					showRegCFListModal: !this.state.showRegCFListModal,
				});
			}
			else {
				alert('Could not get the list of Reg CF Investors.');
			}
		} catch (err) {
			console.log(err);
		}
	}



	getAllInvestorInfo(UserID) {
		this.setState({
			showRegCFListModal: false,
		});

		this.setState({
			InvestorDetailsInvestmentsEarningsModal: (
				<InvestorDetailsInvestmentsEarningsModal
					key={getUUID()}
					id={this.state.id}
					UserID={UserID}
					handleCloseProfileModal={this.handleCloseProfileModal}
				/>
			),
		});
	}



	handleCloseProfileModal() {
		this.setState({
			InvestorDetailsInvestmentsEarningsModal: null,
		});
	}



	handleCloseSignedCAModal() {
		this.setState({ showSignedCAModal: false });
	}



	handleCloseConvertInvestmentModal() {
		this.setState({
			showConvertInvestmentModal: false,
			convertInvestmentID: 0,
		});
	}



	handleCloseConfirmCryptoInvestmentModal() {
		this.setState({
			showConfirmCryptoInvestmentModal: false,
			confirmCryptoInvestmentID: 0,
		});
	}



	setStateAsync(state) {
		return new Promise((resolve) => {
			this.setState(state, resolve);
		});
	}



	/* This call is used for Reed's Analysis */
	async fullDataExport(regType) {
		if (regType === 'regD') {
			this.refs.CSVButtonRegD.innerHTML = 'Please Wait';
			this.refs.CSVButtonRegD.setAttribute('disabled', 'disabled');
		}
		else if (regType === 'regCF') {
			this.refs.CSVButtonRegCF.innerHTML = 'Please Wait';
			this.refs.CSVButtonRegCF.setAttribute('disabled', 'disabled');
		}

		try {
			const { data } = await RequestService.get(`/api/getFullDataExportAnalysis/${regType}`);

			if (await data.code === 200) {
				if (regType === 'regD') {
					this.setStateAsync({
						doFullDownloadD: true,
						fullDownloadDataD: await data.data,
					});
					this.refs.CSVButtonRegD.style = 'display: none';
				}
				else if (regType === 'regCF') {
					this.setStateAsync({
						doFullDownloadCF: true,
						fullDownloadDataCF: await data.data,
					});
					this.refs.CSVButtonRegCF.style = 'display: none';
				}
			}
			else {
				alert('Could not get Full Data Export');
			}
		}
		catch (err) {
			console.log(err);
		}
	}



	getTrackingByProjectModal = (projectId, projectType) => {
		const element = (
			<InvestorTrackingContainerComponent
				closeModal={this.closeTrackingByProjectModal}
				getTrackingBy={TRACKING_BY_PROJECT}
				projectType={projectType}
				projectId={projectId}
				key={getUUID()}
			/>
		);

		this.setState({ investorTrackingModal: element });
	};



	closeTrackingByProjectModal = () => {
		this.setState({ investorTrackingModal: null });
	};



	async exportK1Data(ProjectID, ProjectName, ProjectEntity) {
		try {
			const { data } = await RequestService.get(`/api/getProjectK1Data/${ProjectID}`);
			if (data.K1Data?.length > 0) {
				this.setState({
					K1Data: data?.K1Data,
					K1DataFilename: `K1Data-${ProjectName}-${ProjectEntity}`
				}, () => {
					setTimeout(() => this.refs.K1CSVLink.link.click(), 1000);
				})
			}
			else {
				swal2({
					title: 'K1 Data Not available',
					text: 'K1 data is not available for this investment yet',
					type: 'warning',
				});
			}
		}
		catch (e) {
			swal2({
				title: 'Error processing request',
				text: 'Please contact administrator',
				type: 'error',
			});
		}
	}



	async showCreatePlaceholderForm() {
		this.setState({ showCreatePlaceholderModal: true });
	}



	async showSampleAmounts() {
		try {
			const { data } = await RequestService.get(`/api/getCashCallSampleAmounts/${this.state.lastViewedProjectID}`);

			if (data.code === 200) {
				let text = 'Cash Call Method: ' + (data.cashCallVersion === 2 ? 'LP First, Then GP.' : 'LP and GP Equal.');
				swal('Sample Amounts per $5000 Investment:', `LP: $${data.lpRetAmt}\nGP (currently): $${data.gpRetAmt}\n${text}`, 'success');
			}
		}
		catch (e) {

		}
	}



	async compareToParentProject() {
		try {
			const { data } = await RequestService.get(`/api/getParentInvestmentsForProjectD/${this.state.lastViewedProjectID}`);

			if (data.code === 200) {

				let ViewsD_CSV = data.data.filter((item) => item.Deleted !== 1);

				this.setState({
					ViewsD: data.data,
					ViewsD_CSV: ViewsD_CSV,
					// fileName: name,
					showRegDListModal: true,
					// lastViewedProjectID: id,
					// lastViewedProjectTitle: name,
					// lastViewedProjectEntity: entity,
					// lastViewedProjectIsCashCall: isCashCall,
					// lastViewedProjectCurrentFunding: formatMoney(data.projectCurrentFunding)
					cashCallComparisonOn: 1
				});

				this.refs.CompareButton.style.display = 'none';
				this.refs.closeCompareButton.style.display = '';
			}
			else {
				alert('Could not get the list of Reg D Investors.');
			}
		}
		catch (err) {
			console.log(err);
		}
	}



	async createPlaceholderInvestment() {
		if (this.state.lastViewedProjectID > 0 && this.refs.EntityID.value > 0 && this.refs.UserID.value > 0 && this.refs.InvestmentAmount.value > 0) {
			const formTemp = [];

			formTemp.push(`ProjectID=${encodeURIComponent(this.state.lastViewedProjectID)}`);
			formTemp.push(`EntityID=${encodeURIComponent(this.refs.EntityID.value)}`);
			formTemp.push(`UserID=${encodeURIComponent(this.refs.UserID.value)}`);
			formTemp.push(`InvestmentAmount=${encodeURIComponent(this.refs.InvestmentAmount.value)}`);
			formTemp.push(`FeeAmount=${encodeURIComponent(this.refs.FeeAmount.value)}`);

			const formBody = formTemp.join('&');

			const { data } = await RequestService.post({
				url: '/api/createPlaceholderInvestment',
				data: formBody,
			});

			if (data.code === 200 && data.success === 'Successful') {
				swal('Investment Placeholder Created Successfully.', '', 'success');
				this.handleCloseCreatePlaceholderModal();
				this.handleCloseRegDListModal();
				this.onViewD(this.state.lastViewedProjectID, this.state.lastViewedProjectTitle, this.state.lastViewedProjectEntity, this.state.lastViewedProjectIsCashCall);
			}
			else {
				alert('Could not create the Investment Placeholder. Please check your inputs to make sure they match the user info.');
			}
		}
		else {
			alert('Please complete all the fields.');
		}
	}



	async deletePlaceholderInvestment(inv) {
		swal2({
			title: 'Confirm',
			text: 'Are you sure you want to delete this placeholder?',
			showCancelButton: true,
			confirmButtonText: 'Delete',
			showLoaderOnConfirm: true,
			confirmButtonClass: 'btn btn-success',
			cancelButtonClass: 'btn btn-danger',
			preConfirm: (async (doDelete) => {
				if (doDelete) {
					const { data } = await RequestService.delete(`/api/deletePlaceholderInvestment/${inv.UserInvestmentID}`);

					if (data.code === 200 && data.success === 'Successful') {
						swal('Investment Placeholder Deleted Successfully.', '', 'success');
						this.handleCloseRegDListModal();
						this.onViewD(this.state.lastViewedProjectID, this.state.lastViewedProjectTitle, this.state.lastViewedProjectEntity, this.state.lastViewedProjectIsCashCall);
					}
					else {
						alert('Could not delete the Investment Placeholder. Please contact support.');
					}
				}
			}),
		}).then((result) => {
			if (result.value) {
				swal2({
					type: 'success',
					title: 'Success!',
					html: 'Placeholder Deleted'
				});
			}
		});
	}



	async showConvertPlaceholderInvestment(inv) {
		this.setState({
			showConvertInvestmentModal: true,
			convertInvestmentID: inv.UserInvestmentID,
		});
	}



	async showConfirmCryptoInvestment(inv) {
		this.setState({
			showConfirmCryptoInvestmentModal: true,
			confirmCryptoInvestmentID: inv.UserInvestmentID,
		});
	}



	async doConvertPlaceholderInvestment() {
		let invID = this.state.convertInvestmentID;

		if (invID > 0) {
			let formBody = [];
			formBody.push("psNotes=" + encodeURIComponent(this.refs.psNotes.value));
			formBody = formBody.join("&");

			const { data } = await RequestService.patch({
				url: `/api/convertPlaceholderInvestment/${invID}`,
				body: formBody
			});

			if (data.code === 200) {
				swal2({
					type: 'success',
					title: 'Success!',
					html: 'Placeholder Converted'
				});

				this.handleCloseConvertInvestmentModal();
				this.onViewD(this.state.lastViewedProjectID, this.state.lastViewedProjectTitle, this.state.lastViewedProjectEntity, this.state.lastViewedProjectIsCashCall);
			}
		}
		else {
			// No Investment ID.
		}
	}



	async doConfirmCryptoInvestment() {
		let invID = this.state.confirmCryptoInvestmentID;

		if (invID > 0) {
			let formBody = [];
			formBody.push("csNotes=" + encodeURIComponent(this.refs.csNotes.value));
			formBody = formBody.join("&");

			const { data } = await RequestService.patch({
				url: `/api/confirmCryptoInvestment/${invID}`,
				body: formBody
			});

			if (data.code === 200) {
				swal2({
					type: 'success',
					title: 'Success!',
					html: 'Placeholder Converted'
				});

				this.handleCloseConfirmCryptoInvestmentModal();
				this.onViewD(this.state.lastViewedProjectID, this.state.lastViewedProjectTitle, this.state.lastViewedProjectEntity, this.state.lastViewedProjectIsCashCall);
			}
		}
		else {
			// No Investment ID.
		}
	}



	renderRegD() {
		const IDs = [];

		return (
			<div className="col-md-12 col-sm-12 col-xs-12">
				<div className="x_panel">
					<div className="x_title">
						<div
							id="CSVButtonRegD"
							ref="CSVButtonRegD"
							className="btn btn-primary col-md-4 col-md-offset-4"
							style={{ float: 'right' }}
						>
							<span style={{ color: 'white' }} onClick={() => { this.fullDataExport('regD'); }}>
								Prepare CSV
							</span>
						</div>
						{
							this.state.doFullDownloadD
								? (
									<CSVLink
										target=""
										data={this.state.fullDownloadDataD}
										filename="FullDataExport_RegD.csv"
										className="btn btn-primary col-md-4 col-md-offset-4"
										style={{ float: 'right' }}
									>
										Download
										CSV
									</CSVLink>
								) : ''
						}
						<div className="clearfix" />
					</div>
					<div className="x_content">
						<div className="table-responsive">
							<table className="table table-striped jambo_table ">
								<thead className="thead-inverse">
									<tr className="headings">
										<th className="column-title" />
										<th className="column-title">ID</th>
										<th className="column-title">Investment</th>
										<th className="column-title">Entity</th>
										<th className="column-title">Funding Goal Min</th>
										<th className="column-title">Funding Goal Max</th>
										<th className="column-title">Min. Inv.</th>
										<th className="column-title">Actions</th>
									</tr>
								</thead>
								{(() => {
									if (this.state.Invester.length > 0) {
										return (
											<tbody>
												{
													this.state.Invester.map((inv, i) => {
														if (!IDs.includes(inv.ProjectID)) {
															IDs.push(inv.ProjectID);
															return (
																<tr
																	className="even pointer"
																	key={i}
																>
																	<td className=" ">{i + 1}</td>
																	<td className=" ">{inv.ProjectID}</td>
																	<td className=" ">{inv.ProjectName}</td>
																	<td className=" ">{inv.Entity}</td>
																	<td className=" ">${formatMoney(inv.FundingGoalmin)}</td>
																	<td className=" ">${formatMoney(inv.FundingGoalMax)}</td>
																	<td className=" ">${formatMoney(inv.MinInvestment)}</td>
																	<td>
																		<div className="dropdown">
																			<button
																				className="btn btn-primary dropdown-toggle capitalize-text"
																				data-toggle="dropdown"
																				type="button"
																			>
																				<Icon iconName="chevron-down" extraClasses="white-text" />
																				{' '}
																				actions
																			</button>
																			<ul className="dropdown-menu">
																				<li>
																					<a onClick={() => this.onViewD(inv.ProjectID, inv.ProjectName, inv.Entity, inv.IsCashCall)}>
																						View
																						Investor List
																					</a>
																				</li>
																				<li>
																					<a onClick={() => this.onViewSignedCA(inv.ProjectID, inv.ProjectName, inv.Entity)}>
																						View Signed CA List
																					</a>
																				</li>
																				<li>
																					<a onClick={() => this.getTrackingByProjectModal(inv.ProjectID, PROJECT_REG_D)}>
																						View Investor Tracking
																					</a>
																				</li>
																				<li>
																					<a onClick={() => this.exportK1Data(inv.ProjectID, inv.ProjectName, inv.Entity)}>
																						K1 Data Export
																					</a>

																				</li>
																			</ul>
																		</div>
																	</td>
																	{/* NEED TO FIX: <td><a className="btn btn-primary" href={inv.ContractFilePath} download>Download</a></td> */}
																</tr>
															);
														}
													})
												}
											</tbody>
										);
									}
									else {
										return (
											<tbody>
												<tr className="even pointer">
													<td colSpan="8" className="text-center">Nothing to Show</td>
												</tr>
											</tbody>
										);
									}
								})()}
							</table>
							{/* PAGINATION DISABLED FOR NOW */}
							{/* <div className="pull-right">
                                                            <Pagination
                                                                firstPageText={<i className='glyphicon glyphicon-chevron-left' />}
                                                                lastPageText={<i className='glyphicon glyphicon-chevron-right' />}
                                                                prevPageText={<i className='glyphicon glyphicon-menu-left' />}
                                                                nextPageText={<i className='glyphicon glyphicon-menu-right' />}
                                                                activePage={this.state.activePage}
                                                                itemsCountPerPage={this.state.perPage}
                                                                totalItemsCount={this.state.totalCount}
                                                                onChange={this.handlePageChangeD}
                                                            />
                                                        </div> */}
						</div>
					</div>
				</div>
			</div>
		)
	}



	renderRegCF() {
		const IDs = [];

		return (
			<div className="col-md-12 col-sm-12 col-xs-12">
				<div className="x_panel">
					<div className="x_title">
						<div
							id="CSVButtonRegCF"
							ref="CSVButtonRegCF"
							className="btn btn-primary col-md-4 col-md-offset-4"
							style={{ float: 'right' }}
						>
							<span style={{ color: 'white' }} onClick={() => { this.fullDataExport('regCF'); }}>
								Prepare CSV
							</span>
						</div>
						{
							this.state.doFullDownloadCF
								? (
									<CSVLink
										target=""
										data={this.state.fullDownloadDataCF}
										filename="FullDataExport_RegCF.csv"
										className="btn btn-primary col-md-4 col-md-offset-4"
										style={{ float: 'right' }}
									>
										Download
										CSV
									</CSVLink>
								) : ''
						}
						<div className="clearfix" />
					</div>
					<div className="x_content">
						<div className="table-responsive">
							<table className="table table-striped jambo_table ">
								<thead className="thead-inverse">
									<tr className="headings">
										<th className="column-title">Investment</th>
										<th className="column-title">Funding Goal Min</th>
										<th className="column-title">Funding Goal Max</th>
										<th className="column-title">Min. Inv.</th>
										<th className="column-title">Details</th>
									</tr>
								</thead>
								{(() => {
									if (this.state.InvesterCF.length > 0) {
										return (
											<tbody>
												{
													this.state.InvesterCF.map((inv) => {
														if (!IDs.includes(inv.ProjectID)) {
															IDs.push(inv.ProjectID);
															return (
																<tr
																	key={inv.UserID}
																	className="even pointer"
																>
																	<td className=" ">{inv.InvestmentName}</td>
																	<td className=" ">${formatMoney(inv.FundingGoalMin)}</td>
																	<td className=" ">${formatMoney(inv.FundingGoalMax)}</td>
																	<td className=" ">${formatMoney(inv.MinimumInvestment)}</td>
																	<td>
																		<div className="dropdown">
																			<button
																				className="btn btn-primary dropdown-toggle capitalize-text"
																				data-toggle="dropdown"
																				type="button"
																			>
																				<Icon iconName="chevron-down" extraClasses="white-text" />
																				{' '}
																				actions
																			</button>
																			<ul className="dropdown-menu">
																				<li>
																					<a onClick={() => this.onViewCF(inv.ProjectID, inv.InvestmentName)}>
																						View
																						Investor List
																					</a>
																				</li>
																				<li>
																					<a onClick={() => this.getTrackingByProjectModal(inv.ProjectID, PROJECT_REG_CF)}>
																						View Investor Tracking
																					</a>
																				</li>
																			</ul>
																		</div>
																	</td>
																	{/* NEED TO FIX: <td><a className="btn btn-primary" href={inv.ContractFilePath} download>Download</a></td> */}
																</tr>
															);
														}
													})
												}
											</tbody>
										);
									}
									else {
										return (
											<tbody>
												<tr className="even pointer">
													<td colSpan="5" className="text-center">Nothing to Show</td>
												</tr>
											</tbody>
										);
									}
								})()}
							</table>
							<div className="pull-right">
								<Pagination
									firstPageText={(
										<i
											className="glyphicon glyphicon-chevron-left"
										/>
									)}
									lastPageText={(
										<i
											className="glyphicon glyphicon-chevron-right"
										/>
									)}
									prevPageText={(
										<i
											className="glyphicon glyphicon-menu-left"
										/>
									)}
									nextPageText={(
										<i
											className="glyphicon glyphicon-menu-right"
										/>
									)}
									activePage={this.state.activePageCF}
									itemsCountPerPage={this.state.perPageCF}
									totalItemsCount={this.state.totalCountCF}
									onChange={this.handlePageChangeCF}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}



	render() {
		return (
			<div className="container body">
				<div className="main_container">
					{/* page content */}
					<div className="right_col" role="main">
						<h3 className="up" style={{ float: 'left' }}>User Investments</h3>
						<div className="col-md-12 col-sm-12 col-xs-12" />
						<div className="row">
							<div>
								{(() => {
									if (this.state.MainRole !== 'FundRaiser') {
										return (
											<ul
												className="nav nav-pills nav-justified"
												style={{
													padding: '0 10px',
													marginBottom: 20,
												}}
											>
												<li className="active">
													<a data-toggle="pill" href="#menu1">
														EnergyFunders
														Black (Reg D)
													</a>
												</li>
												<li>
													<a data-toggle="pill" href="#home">
														EnergyFunders Marketplace (Reg
														CF)
													</a>
												</li>
											</ul>
										);
									}
								})()}
								<div className="tab-content">
									<div id="home" className="tab-pane fade">
										{this.renderRegCF()}
									</div>
									<div id="menu1" className="tab-pane fade in active">
										{this.renderRegD()}
									</div>

									{this.state.InvestorDetailsInvestmentsEarningsModal}

									<Modal show={this.state.showRegCFListModal} onHide={this.handleCloseRegCFListModal}>
										<Modal.Header closeButton>
											<Modal.Title>{this.state.fileName}</Modal.Title>
										</Modal.Header>
										<Modal.Body>
											<div className="x_panel">
												<div className="x_title">
													<h4 className="up">Investor List</h4>
													<div className="clearfix" />
												</div>
												<div className="x_content">
													<div className="table-responsive">
														<table className="table table-striped jambo_table ">
															<thead className="thead-inverse">
																<tr className="headings">
																	<th className="column-title">Name</th>
																	<th className="column-title">Email</th>
																	<th className="column-title">Investment Amount</th>
																	<th className="column-title">Investment Date</th>
																	<th className="column-title">Payment status</th>
																	<th className="column-title">Phone Number</th>
																	<th className="column-title">Address</th>
																	<th className="column-title">City</th>
																	<th className="column-title">State</th>
																	<th className="column-title">ZipCode</th>
																	<th className="column-title">Country</th>
																</tr>
															</thead>
															{(() => {
																if (this.state.Views.length > 0) {
																	return (
																		<tbody>
																			{
																				this.state.Views.map(inv => (
																					<tr
																						key={inv.UserInvestmentID}
																						className="even pointer"
																					>
																						<td className=" ">
																							<a
																								href="javascript:void(0);"
																								onClick={() => this.getAllInvestorInfo(inv.UserID)}
																							>
																								{`${inv.FirstName} ${inv.LastName}`}
																							</a>
																						</td>
																						<td className=" ">{inv.Email}</td>
																						<td className=" ">{inv.InvestmentAmount}</td>
																						<td className=" ">{getDate.getDate(inv.InvestmentDate)}</td>
																						<td className="capitalize-text">
																							{getStatusFromCode(inv.PaymentStatus)}
																						</td>
																						<td className=" ">{inv.Phone}</td>
																						<td className=" ">{inv.Address}</td>
																						<td className=" ">{inv.City}</td>
																						<td className=" ">{inv.State}</td>
																						<td className=" ">{inv.ZipCode}</td>
																						<td className=" ">{inv.Country}</td>
																						{/* <td><a className="btn btn-primary" href={inv.ContractFilePath} download>Download</a></td> */}
																					</tr>
																				))
																			}
																		</tbody>
																	);
																}
																else {
																	return (
																		<tbody>
																			<tr className="even pointer">
																				<td colSpan="11" className="text-center">Nothing to Show</td>
																			</tr>
																		</tbody>
																	);
																}
															})()}
														</table>
													</div>
												</div>
											</div>
										</Modal.Body>
										<div className="row">
											<CSVLink data={this.state.Views} filename={`${this.state.fileName}.csv`}>
												<div className="btn btn-primary col-md-4 col-md-offset-4">
													<span style={{ color: 'white' }}>Download As CSV</span>
												</div>
											</CSVLink>
											<br />
										</div>
										<Modal.Footer>
											<button className="btn btn-info left" onClick={this.handleCloseRegCFListModal}>Close</button>
										</Modal.Footer>
									</Modal>

									<Modal show={this.state.showRegDListModal} onHide={this.handleCloseRegDListModal}>
										<Modal.Header closeButton>
											<Modal.Title>{this.state.lastViewedProjectEntity} | {this.state.lastViewedProjectTitle}</Modal.Title>
										</Modal.Header>
										<Modal.Body>
											<div className="x_panel">
												<div className="x_title">
													<h4 className="up">Investor List</h4>
													<h4 className="pull-left">Currently Raised: ${this.state.lastViewedProjectCurrentFunding}</h4>
													<button onClick={this.showCreatePlaceholderForm} className="btn btn-primary pull-right">Create Investment Placeholder</button>
													{(() => {
														if (this.state.lastViewedProjectIsCashCall === 1) {
															return (
																<div>
																	<button ref="CompareButton" onClick={this.compareToParentProject} className="btn btn-primary pull-right">
																		Compare To Parent Project
																	</button>
																	<button ref="closeCompareButton" className="btn btn-primary pull-right"
																		onClick={() => this.onViewD(this.state.lastViewedProjectID, this.state.lastViewedProjectTitle,
																			this.state.lastViewedProjectEntity, this.state.lastViewedProjectIsCashCall)}
																		style={{ display: 'none' }}
																	>
																		Close Comparison
																	</button>
																	<button onClick={this.showSampleAmounts} className="btn btn-primary pull-right">
																		Show Sample Amounts
																	</button>
																</div>
															);
														}
													})()}
													<div className="clearfix" />
												</div>
												<div className="x_content">
													<div className="table-responsive">
														<table className="table table-striped jambo_table ">
															<thead className="thead-inverse">
																<tr className="headings">
																	<th className="column-title" />
																	<th className="column-title">IDs</th>
																	<th className="column-title">Name</th>
																	<th className="column-title">Email</th>
																	<th className="column-title">Investor Entity</th>
																	<th className="column-title">Investment Amount</th>
																	<th className="column-title">Units</th>
																	<th className="column-title">Investment Date</th>
																	 { (this.state?.lastViewedProjectID == America_First_Energy_Fund_1 || this.state?.lastViewedProjectID == Yield_Fund_2_ID) ? <th className="column-title" style={{minWidth:'150px'}}>Class A/Class B </th> : <th className="column-title"> GP/LP </th>}
																	{(() => {
																		if (this.state.lastViewedProjectIsCashCall > 0 && this.state.cashCallComparisonOn > 0) {
																			return (
																				<React.Fragment>
																					<th className="column-title">Cash Call Paid</th>
																					<th className="column-title">Cash Call Amount</th>
																				</React.Fragment>
																			)
																		}
																	})()}
																	<th className="column-title">Payment Status</th>
																	<th className="column-title">Payment Type</th>
																	<th className="column-title">Refunded</th>
																	<th className="column-title">Deleted</th>
																	<th className="column-title">Tax Basis</th>
																	<th className="column-title">Notes</th>
																	<th className="column-title">Phone Number</th>
																	<th className="column-title">Address</th>
																	<th className="column-title">City</th>
																	<th className="column-title">State</th>
																	<th className="column-title">ZipCode</th>
																	<th className="column-title">Country</th>
																	<th className="column-title">Action</th>
															
																</tr>
															</thead>
															{(() => {
																if (this.state.ViewsD.length > 0) {
																	return (
																		<tbody>
																			{
																				this.state.ViewsD?.map((view) => (this.state?.lastViewedProjectID == America_First_Energy_Fund_1 || this.state?.lastViewedProjectID == Yield_Fund_2_ID) ? {...view, GP_LP: view?.GP_LP == 'GP' ? 'Class B' : 'Class A'} : view ).map((inv, index) => (
																					<tr
																					key={inv.UserInvestmentID}
																					className="even pointer"
																					style={{ textDecoration: inv.Deleted === 1 ? 'line-through' : '',color:inv?.Refunded === 1 ? 'red' : '' }}
																					>
																						<td className=" ">
																							<a onClick={() => this.getAllInvestorInfo(inv.UserID)}>
																								{index + 1}
																							</a>
																						</td>
																						<td className=" ">
																							<a onClick={() => this.getAllInvestorInfo(inv.UserID)}>
																								{inv.UserID} -- {inv.UserInvestmentID}
																							</a>
																						</td>
																						<td className=" ">
																							<a onClick={() => this.getAllInvestorInfo(inv.UserID)}>
																								{`${inv.FirstName} ${inv.LastName}`}
																							</a>
																						</td>
																						<td className=" ">{inv.Email}</td>
																						<td className=" ">{inv.EntityName}</td>
																						<td className=" ">${formatMoney(inv.InvestmentAmount)}</td>
																						<td className=" ">{inv.Units}</td>
																						<td className=" ">{getDate.getDate(inv.InvestmentDate)}</td>
																						<td className=" ">{inv.GP_LP}</td>
																						{(() => {
																							if (this.state.lastViewedProjectIsCashCall > 0 && this.state.cashCallComparisonOn > 0) {
																								return (
																									<React.Fragment>
																										<td className="column-title" style={{ borderLeft: '1px solid #ccc' }}>
																											{inv.CashCallPlaceHolder > 0 ?
																												<i className="fa fa-warning" /> :
																												(inv.CashCallInvestmentAmount > 0 ?
																													<i className="fa fa-check-square-o" /> :
																													'')}
																										</td>
																										<td className="column-title" style={{ borderRight: '1px solid #ccc' }}>
																											{inv.CashCallInvestmentAmount ? '$' + formatMoney(inv.CashCallInvestmentAmount) : ''}<br />
																											{inv.CashCallInvestmentDate ? 'on ' + getDate.getDate(inv.CashCallInvestmentDate) : ''}
																										</td>
																									</React.Fragment>
																								)
																							}
																						})()}
																						<td className="capitalize-text">{getStatusFromCode(inv.PaymentStatus)}</td>
																						<td className=" ">{inv.PaymentType}</td>
																						<td className=" ">{inv?.Refunded === 1 ? 'Yes' : 'No'}</td>
																						<td className=" ">{inv?.Deleted === 1 ? 'Yes' : 'No'}</td>
																						<td className=" ">{inv.TaxBasis}</td>
																						<td className=" ">{inv.PaymentStatusNotes}</td>
																						<td className=" ">{inv.Phone}</td>
																						<td className=" ">{inv.Address}</td>
																						<td className=" ">{inv.City}</td>
																						<td className=" ">{inv.State}</td>
																						<td className=" ">{inv.ZipCode}</td>
																						<td className=" ">{inv.Country}</td>
																						<td className=" ">
																							{inv.PaymentStatusNotes.toUpperCase().indexOf('MANUAL PLACEHOLDER') > -1
																								? <>
																									<div onClick={() => this.deletePlaceholderInvestment(inv)}>
																										<Icon iconName="trash" extraClasses="card-info" />
																									</div>
																									<div onClick={() => this.showConvertPlaceholderInvestment(inv)}>
																										<Icon iconName="money" extraClasses="card-info" />
																									</div>
																								</>
																								: ''
																							}
																							{(inv.PaymentType.indexOf('Bitcoin') > -1) || (inv.PaymentType.indexOf('Ethereum') > -1)
																								?
																								<>
																									{(inv.PaymentStatus < 3) ?
																										<>
																											<div onClick={() => this.showConfirmCryptoInvestment(inv)}>
																												<Icon iconName="money" extraClasses="card-info" />
																											</div>
																										</>
																										: ''
																									}
																								</>
																								: ''
																							}
																						</td>
																						{/* <td><a className="btn btn-primary" href={inv.ContractFilePath} download>Download</a></td> */}
																					</tr>
																				))
																			}
																		</tbody>
																	);
																}
																else {
																	return (
																		<tbody>
																			<tr className="even pointer">
																				<td colSpan="18" className="text-center">Nothing to Show</td>
																			</tr>
																		</tbody>
																	);
																}
															})()}
														</table>
													</div>
												</div>
											</div>

											<div className="row">
												<CSVLink data={this.state.ViewsD_CSV} filename={`${this.state.lastViewedProjectEntity}-${this.state.lastViewedProjectTitle}.csv`}>
													<div className="btn btn-primary col-md-4 col-md-offset-4">
														<span style={{ color: 'white' }}>Download As CSV</span>
													</div>
												</CSVLink>
												<br />
											</div>
										</Modal.Body>
										<Modal.Footer>
											<button className="btn btn-info left" onClick={this.handleCloseRegDListModal}>Close</button>
										</Modal.Footer>
									</Modal>

									<Modal show={this.state.showSignedCAModal} onHide={this.handleCloseSignedCAModal}>
										<Modal.Header closeButton>
											<Modal.Title>{this.state.lastViewedProjectEntity} | {this.state.fileName}</Modal.Title>
										</Modal.Header>
										<Modal.Body>
											<div className="x_panel">
												<div className="x_title">
													<h4 className="up">Signed CA List</h4>
													<div className="clearfix" />
												</div>
												<div className="x_content">
													<div className="table-responsive">
														<table className="table table-striped jambo_table ">
															<thead className="thead-inverse">
																<tr className="headings">
																	<th className="column-title" />
																	<th className="column-title">User ID</th>
																	<th className="column-title">Investor Name</th>
																	<th className="column-title">Email</th>
																	<th className="column-title">Signed Name</th>
																	<th className="column-title">Signed Date Time</th>
																	<th className="column-title">Views</th>
																	<th className="column-title">City</th>
																	<th className="column-title">State</th>
																	<th className="column-title">Country</th>
																</tr>
															</thead>
															{(() => {
																if (this.state.ViewSignedCAs.length > 0) {
																	return (
																		<tbody>
																			{
																				this.state.ViewSignedCAs.map((inv, index) => (
																					<tr
																						key={inv.UserInvestmentID}
																						className="even pointer"
																					>
																						<td className=" ">
																							<a
																								href="javascript:void(0);"
																								onClick={() => this.getAllInvestorInfo(inv.UserID)}
																							>
																								{index + 1}
																							</a>
																						</td>
																						<td className=" ">
																							<a
																								href="javascript:void(0);"
																								onClick={() => this.getAllInvestorInfo(inv.UserID)}
																							>
																								{inv.UserID}
																							</a>
																						</td>
																						<td className=" ">
																							<a
																								href="javascript:void(0);"
																								onClick={() => this.getAllInvestorInfo(inv.UserID)}
																							>
																								{`${inv.FirstName} ${inv.LastName}`}
																							</a>
																						</td>
																						<td className=" ">{inv.Email}</td>
																						<td className=" ">{inv.signedName}</td>
																						<td className=" ">{inv.signedDateTime}</td>
																						<td className=" ">{inv.ViewCount}</td>
																						<td className=" ">{inv.city}</td>
																						<td className=" ">{inv.state}</td>
																						<td className=" ">{inv.country}</td>
																					</tr>
																				))
																			}
																		</tbody>
																	);
																}

																return (
																	<tbody>
																		<tr className="even pointer">
																			<td colSpan="10" className="text-center">Nothing to Show</td>
																		</tr>
																	</tbody>
																);
															})()}
														</table>
													</div>
												</div>
											</div>
										</Modal.Body>
										<Modal.Footer>
											<button
												className="btn btn-info left"
												onClick={this.handleCloseSignedCAModal}
											>
												Close
											</button>
										</Modal.Footer>
									</Modal>

									<Modal show={this.state.showCreatePlaceholderModal} onHide={this.handleCloseCreatePlaceholderModal}
										style={{ width: '90%', margin: '0 auto' }}
									>
										<Modal.Header closeButton>
											<Modal.Title>Create Placeholder Investment for {this.state.fileName}</Modal.Title>
										</Modal.Header>
										<Modal.Body>
											<div className="x_panel">
												<div className="x_title">
													<div className="clearfix" />
												</div>
												<div className="x_content">
													<div className="table-responsive">
														<div className="row" style={{ margin: '10px 5px' }}>
															<div className="col-xs-3">
																<label htmlFor="UserID">UserID <span className="red">*</span></label>
																<input id="UserID" ref="UserID" className="form-control" type="text" data-validation="required"
																	placeholder="UserID"
																/>
															</div>
														</div>
														<div className="row" style={{ margin: '10px 5px' }}>
															<div className="col-xs-3">
																<label htmlFor="EntityID">EntityID <span className="red">*</span></label>
																<input id="EntityID" ref="EntityID" className="form-control" type="text" data-validation="required"
																	placeholder="EntityID"
																/>
															</div>
														</div>
														<div className="row" style={{ margin: '10px 5px' }}>
															<div className="col-xs-3">
																<label htmlFor="InvestmentAmount">InvestmentAmount <span className="red">*</span></label>
																<input id="InvestmentAmount" ref="InvestmentAmount" className="form-control" type="text" data-validation="required"
																	placeholder="5000"
																/>
															</div>
														</div>
														<div className="row" style={{ margin: '10px 5px' }}>
															<div className="col-xs-3">
																<label htmlFor="Origination Fee">Origination Fee <span className="red">*</span></label>
																<input id="FeeAmount" ref="FeeAmount" className="form-control" type="text" data-validation="required"
																	placeholder="0"
																/>
															</div>
														</div>
														<div className="row" style={{ margin: '10px 5px' }}>
															<div className="col-xs-3">
																<button className="btn btn-primary" onClick={this.createPlaceholderInvestment}>Submit</button>
															</div>
														</div>
													</div>
												</div>
											</div>
										</Modal.Body>
										<Modal.Footer>
											<button className="btn btn-info left" onClick={this.handleCloseCreatePlaceholderModal}>
												Cancel
											</button>
										</Modal.Footer>
									</Modal>

									<Modal show={this.state.showConvertInvestmentModal} onHide={this.handleCloseConvertInvestmentModal}
										style={{ width: '50%', margin: '0 auto' }}
									>
										<Modal.Header closeButton>
											<Modal.Title>Convert Placeholder Investment for {this.state.fileName}</Modal.Title>
										</Modal.Header>
										<Modal.Body>
											<div className="x_panel">
												<div className="x_title">
													<div className="clearfix" />
												</div>
												<div className="x_content">
													<div className="table-responsive">
														<div className="row" style={{ margin: '10px 5px' }}>
															<div className="col-xs-12">
																<label htmlFor="UserID">Conversion Note <span className="red">*</span></label>
																<input id="psNotes" name="psNotes" ref="psNotes" className="form-control" type="text" data-validation="required"
																	placeholder="Ex. Wire transfer received on ... " style={{ width: '100%' }}
																/>
															</div>
														</div>
														<div className="row" style={{ margin: '10px 5px' }}>
															<div className="col-xs-3">
																<button className="btn btn-primary" onClick={this.doConvertPlaceholderInvestment}>Submit</button>
															</div>
														</div>
													</div>
												</div>
											</div>
										</Modal.Body>
										<Modal.Footer>
											<button className="btn btn-info left" onClick={this.handleCloseConvertInvestmentModal}>
												Cancel
											</button>
										</Modal.Footer>
									</Modal>

									<Modal show={this.state.showConfirmCryptoInvestmentModal} onHide={this.handleCloseConfirmCryptoInvestmentModal}
										style={{ width: '50%', margin: '0 auto' }}
									>
										<Modal.Header closeButton>
											<Modal.Title>Confirm Crypto Investment for {this.state.fileName}</Modal.Title>
										</Modal.Header>
										<Modal.Body>
											<div className="x_panel">
												<div className="x_title">
													<div className="clearfix" />
												</div>
												<div className="x_content">
													<div className="table-responsive">
														<div className="row" style={{ margin: '10px 5px' }}>
															<div className="col-xs-12">
																<label htmlFor="UserID">Confirmation Note <span className="red">*</span></label>
																<input id="csNotes" name="csNotes" ref="csNotes" className="form-control" type="text" data-validation="required"
																	placeholder="Ex. Verified on ... " style={{ width: '100%' }}
																/>
															</div>
														</div>
														<div className="row" style={{ margin: '10px 5px' }}>
															<div className="col-xs-3">
																<button className="btn btn-primary" onClick={this.doConfirmCryptoInvestment}>Submit</button>
															</div>
														</div>
													</div>
												</div>
											</div>
										</Modal.Body>
										<Modal.Footer>
											<button className="btn btn-info left" onClick={this.handleCloseConfirmCryptoInvestmentModal}>
												Cancel
											</button>
										</Modal.Footer>
									</Modal>

									<CSVLink
										data={this.state.K1Data}
										filename={this.state.K1DataFilename + '.csv'}
										className="hidden"
										ref="K1CSVLink"
										target="_blank"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				{this.state.investorTrackingModal}
			</div>
		);
	}
}


const { object, bool } = PropTypes;

Adminuserinvestments.propTypes = {
	session: object.isRequired,
	authenticated: bool.isRequired,
	checked: bool.isRequired,
};

const mapState = ({ session }) => ({
	session: session.user,
	checked: session.checked,
	authenticated: session.authenticated,
});

export default connect(mapState)(Adminuserinvestments);
