import * as Actions from '../actions/actions';
import initialState from '../../../../reducers/appInitialState';
import { updateRequestCounter } from '../../../../utils/General';

export default (state = initialState.projectStatusManager, action) => {
    const { plus, minus } = updateRequestCounter(state, 'PSOnFlightReq');

    switch (action.type) {
    case Actions.PROJECT_STATUS_END_REQUEST:
        return {
            ...state,
            PSOnFlightReq: minus,
        };
    case Actions.PROJECT_STATUS_START_REQUEST:
        return {
            ...state,
            PSOnFlightReq: plus,
        };
    case Actions.PROJECT_STATUS_GET_ALL_SUCCESS:
        return {
            ...state,
            projectStatusList: action.data,
            PSOnFlightReq: minus,
        };
    default:
        return { ...state };
    }
};
