import React, { Component } from 'react';
import store from '../../../index'
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import RequestService from '../../../api/RequestService';

class Totalconnections extends Component {
    constructor(props)
    {
        super(props);
        this.state={
            id:props.session.id,
            earning:[]
        }
    }

    async componentDidMount()
    {
        try {
            const { data } = await RequestService.get('/api/efearnings');
            this.setState({
                earning: Math.round(data[0].amount)
            });
        } catch (err) {
            console.log(err);
        }
    }
    render() {
        return(
            <div className="col-md-2 col-sm-4 col-xs-12 tile_stats_count">
                <span className="count_top"><i className="fa fa-user"></i> Total EF Earnings</span>
                <div className="count">${this.state.earning}</div>
            </div>
        );
    }
}
const { object, bool } = PropTypes;

Totalconnections.propTypes = {
session: object.isRequired,
authenticated: bool.isRequired,
checked: bool.isRequired
};

const mapState = ({ session }) => ({
session: session.user,
checked: session.checked,
authenticated: session.authenticated
});

export default withRouter(connect(mapState)(Totalconnections));
