import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types'
import {withRouter} from 'react-router-dom';
import OfferingsHeader from './components/OfferingsTitle/OfferingsTitle';
import InvestmentViewer from "./components/InvestmentViewer/InvestmentViewer";
import {bindActionCreators} from 'redux';
import * as AllInvestmentActions from './actions/actions';

import './AllInvestmentsContainerStyles.css';
import {PROJECT_REG_CF, PROJECT_REG_D} from "../../../utils/ApplicationConstants";


class AllInvestmentsContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeOfferingsChecked: true,
            pastOfferingsChecked: true,
            selectedProjectType: PROJECT_REG_D,
            selectedProjectCategory: 'all',
        };
    }



    componentDidMount() {
        const {getAllInvestments, getAllProjectCategories} = this.props;
        getAllInvestments(this.state.selectedProjectCategory);
        getAllProjectCategories();
    }



    onActivePastCheckedClickHandler = (event) => {
        const {name, checked} = event.target;
        this.setState({[name]: checked});
    };



    onProjectTypeChanged = (projectType) => {
        this.setState({selectedProjectType: projectType});
    };



    incrementViewCount = (projectId, projectType) => {
        const {incrementViewCount, user} = this.props;
        incrementViewCount(user, projectType, projectId);
    };



    onSeeDetailsClickHandler = (projectId, projectType, version) => {
        if (version === 1)
            this.redirectToDealPage(projectType, projectId);
        else if (version === 2)
            this.redirectToProjectDetailsPage(projectType, projectId);
    };



    redirectToProjectDetailsPage = (projectType, projectId) => {
        const {push} = this.props.history;

        // console.log({projectId, projectType});

        const pushStateObj = {
            state: {
                id: projectId,
                projectType: this.state.selectedProjectType,
            },
            pathname: '/app/project/details',
        };

        this.incrementViewCount(projectId, projectType);
        push(pushStateObj);
    };



    redirectToDealPage = (projectType, projectId) => {
        const {push} = this.props.history;

        const obj = {
            state: {
                id: projectId,
                projectType: this.state.selectedProjectType,
            }
        };
        if (projectType === PROJECT_REG_D)
            obj.pathname = '/app/deal';
        else if (projectType === PROJECT_REG_CF)
            obj.pathname = '/app/dealCF';

        this.incrementViewCount(projectId, projectType);
        push(obj);
    };



    onProjectCategorySelected = (projectCategoryId) => {
        const {getAllInvestments} = this.props;

        this.setState({selectedProjectCategory: projectCategoryId});
        getAllInvestments(projectCategoryId);
    };



    onAddToWatchListClickHandler = (projectType, projectId) => {
        const categoryId = this.state.selectedProjectCategory;

        this.props.addToWatchList(projectType, projectId, categoryId);
    };



    renderRegDProjects(activeOffering, pastOfferings) {
        const { activeRegDInvestments, pastRegDInvestments, isAccreditedUser, wasAccreditedUser, showPastInvestments, loadingComplete } = this.props;

        if (this.state.selectedProjectType === PROJECT_REG_D && (isAccreditedUser || wasAccreditedUser)) {
            return (
                <InvestmentViewer activeProjects={activeRegDInvestments}
                                  pastRegDInvestments={pastRegDInvestments}
                                  showActiveOfferings={activeOffering}
                                  showPastOfferings={pastOfferings}
                                  onSeeDetails={this.onSeeDetailsClickHandler}
                                  projectType={PROJECT_REG_D}
                                  selectProjectType={this.state.selectedProjectCategory}
                                  addToWatchlist={this.onAddToWatchListClickHandler}
                                  isAccredited={isAccreditedUser}
								  wasAccredited={wasAccreditedUser}
                                  showPastInvestments={showPastInvestments}
								  loadingComplete={loadingComplete}
                />
            );
        }
        return null;
    };



    renderRegCFProjects(activeOffering, pastOfferings) {
        const { activeRegCfInvestments, pastRegCfInvestments, isAccreditedUser, wasAccreditedUser, showPastInvestments, loadingComplete } = this.props;

        if (this.state.selectedProjectType === PROJECT_REG_CF || (!isAccreditedUser && !wasAccreditedUser)) {
            return (
                <InvestmentViewer activeProjects={activeRegCfInvestments}
                                  pastRegDInvestments={pastRegCfInvestments}
                                  showActiveOfferings={activeOffering}
                                  showPastOfferings={pastOfferings}
                                  onSeeDetails={this.onSeeDetailsClickHandler}
                                  projectType={PROJECT_REG_CF}
                                  selectProjectType={this.state.selectedProjectCategory}
                                  addToWatchlist={this.onAddToWatchListClickHandler}
                                  isAccredited={isAccreditedUser}
                                  showPastInvestments={showPastInvestments}
								  loadingComplete={loadingComplete}
                />
            );
        }
        return null;
    };



    render() {
        const { pastOfferingsChecked, activeOfferingsChecked } = this.state;
        const { isAccreditedUser, wasAccreditedUser } = this.props;

        return (
            <div className="container" style={{marginTop: 10}}>
                <div className="right_col card-container">
                    <OfferingsHeader onCheckboxClickedHandler={this.onActivePastCheckedClickHandler}
                                     onProjectTypeClickHandler={this.onProjectTypeChanged}
                                     activeOfferingsChecked={activeOfferingsChecked}
                                     pastOfferingsChecked={pastOfferingsChecked}
                                     isAccreditedUser={isAccreditedUser}
									 wasAccreditedUser={wasAccreditedUser}
                                     selectedProjectType={this.state.selectedProjectType}
                                     projectCategoryList={this.props.projectCategories}
                                     projectCategoryOnClickHandler={this.onProjectCategorySelected}
                                     selectedProjectCategory={this.state.selectedProjectCategory}/>
                </div>

                {this.renderRegDProjects(activeOfferingsChecked, pastOfferingsChecked)}
                {this.renderRegCFProjects(activeOfferingsChecked, pastOfferingsChecked)}
            </div>
        );
    };
}



AllInvestmentsContainer.propTypes = {
    activeRegCfInvestments: PropTypes.array.isRequired,
    activeRegDInvestments: PropTypes.array.isRequired,
    addToWatchList: PropTypes.func.isRequired,
    getAllInvestments: PropTypes.func.isRequired,
    getAllProjectCategories: PropTypes.func.isRequired,
    history: PropTypes.any,
    incrementViewCount: PropTypes.func.isRequired,
    isAccreditedUser: PropTypes.bool.isRequired,
    pastRegCfInvestments: PropTypes.array.isRequired,
    pastRegDInvestments: PropTypes.array.isRequired,
    projectCategories: PropTypes.array.isRequired,
    showPastInvestments: PropTypes.bool.isRequired,
    user: PropTypes.any,
};

AllInvestmentsContainer.defaultProps = {
    showPastInvestments: true,
};

const mapDispatchToProps = (dispatch) => {
    const allInvestmentActions = bindActionCreators(AllInvestmentActions, dispatch);
    return {...allInvestmentActions};
};

const mapStateToProps = (_state) => {
    return {
        ..._state.allInvestments,
        user: _state.session.user,
        location: _state.location,
        projectCategories: _state.allInvestments.projectCategories,
    };
};

const connectedComponent = connect(
    mapStateToProps,
    mapDispatchToProps,
)(AllInvestmentsContainer);

export default withRouter(connectedComponent);
