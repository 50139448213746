import React from 'react';
import PropTypes from "prop-types";

class TabComponent extends React.Component {
    isActive = () => {
        const {tab, selectedTab} = this.props;
        return tab.number === selectedTab ? "active" : "";
    };

    render = () => {
        return (
            <li className={this.isActive()}
                onClick={() => this.props.onSelectedTabHandler(this.props.tab.number)}>
                <a data-toggle="pill">
                    {this.props.tab.title}
                </a>
            </li>
        );
    };
}

TabComponent.propTypes = {
    tab: PropTypes.shape({
        number: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
    }).isRequired,
    selectedTab: PropTypes.number.isRequired,
    onSelectedTabHandler: PropTypes.func.isRequired,
};

TabComponent.defaultProps = {
    tab: {number: 0, title: ""},
    selectedTab: -1,
    onSelectedTabHandler: () => "",
};

export default TabComponent;
