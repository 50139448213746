import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import OverviewOfferingsHeader from "./components/OfferingsTitle/OverviewOfferingsTitle";
import { bindActionCreators } from "redux";
import * as AllInvestmentActions from "./actions/actions";
import RequestService from "../../../api/RequestService";
import { formatMoney, getStatusFromCode, ROI,getImpersonateID } from "../../../utils/General";
import store from "../../../index";
import {
	PROJECT_REG_CF,
	PROJECT_REG_D,
	PROJECT_REG_ALL,
} from "../../../utils/ApplicationConstants";

import InvestorMessageSearchComponent from "../InvestorMessageSearchComponent/InvestorMessageSearchComponent";
class InvestorMessagesContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			ROI: 0,
			// totalEarningsRegD:0,
			activeOfferingsChecked: true,
			pastOfferingsChecked: true,
			selectedProjectType: PROJECT_REG_ALL,
			selectedProjectCategory: "all",
			id: props.user.id,
			users: [],
			totalProjects: [],
			allProjects_cf: [],
			pageNumber: 1,
			totalCount: 0,
			perPage: 10,
			activePage: 1,
			allProjectsD: [],
			usersD: [],
			pageNumberD: 1,
			totalCountD: 0,
			perPageD: 10,
			activePageD: 1,
			docsD: [],
			docsCF: [],
			tia_d: 0,
			tia_cf: 0,
			troc_d: 0,
			troc_cf: 0,
			modalTitle: "",
			docDataForModal: [],
			showDocsModal: false,
			showDocFrameModal: false,
			docFrameURL: "",
			modalDocTitle: "",
		};
	}
	componentDidMount() {
		store.subscribe(() => {
			let currentState = store.getState();
			this.setState({
				id: currentState.session.user.id,
			});
		});
		//  console.log('My investments', this.getMyInvestments());
		this.getMyInvestments();
		const { getAllInvestments, getAllProjectCategories } = this.props;
		getAllInvestments(this.state.selectedProjectCategory);
		getAllProjectCategories();
		console.log("props", this.props);
		console.log("state", this.state);
		const { investorId, getAllUserInvestments } = this.props;
		getAllUserInvestments(investorId);
	}
	async getMyInvestments() {
		const impersonateID = await getImpersonateID();
		const { data } = await RequestService.get(`/api/myInvestments/${impersonateID}`);
		if (data.code === 200) {
			this.setState({
				users: data.reg_cf.slice(0, 10),
				allProjects_cf: data.reg_cf,
				docsCF: data.docsCF,
				tia_d: data.tia_d,
				tia_cf: data.tia_cf,
				troc_d: data.troc_d,
				troc_cf: data.troc_cf,
				totalCount: data.reg_cf.length,
				allProjectsD: data.reg_d,
				usersD: data.reg_d.slice(0, 10),
				docsD: data.docsD,
				pageNumberD: 1,
				totalCountD: data.reg_d.length,
				activePageD: 1,
			});
		}
		console.log("updated state:", this.state);
	}
	onActivePastCheckedClickHandler = (event) => {
		const { name, checked } = event.target;
		this.setState({ [name]: checked });
	};
	onProjectTypeChanged = (projectType) => {
		this.setState({ selectedProjectType: projectType });
	};
	incrementViewCount = (projectId, projectType) => {
		const { incrementViewCount, user } = this.props;
		incrementViewCount(user, projectType, projectId);
	};
	onSeeDetailsClickHandler = (projectId, projectType, version) => {
		if (version === 1) this.redirectToDealPage(projectType, projectId);
		else if (version === 2)
			this.redirectToProjectDetailsPage(projectType, projectId);
	};
	redirectToProjectDetailsPage = (projectType, projectId) => {
		const { push } = this.props.history;
		// console.log({projectId, projectType});
		const pushStateObj = {
			state: {
				id: projectId,
				projectType: this.state.selectedProjectType,
			},
			pathname: "/app/project/details",
		};
		this.incrementViewCount(projectId, projectType);
		push(pushStateObj);
	};
	redirectToDealPage = (projectType, projectId) => {
		const { push } = this.props.history;
		const obj = {
			state: {
				id: projectId,
				projectType: this.state.selectedProjectType,
			},
		};
		if (projectType === PROJECT_REG_D) obj.pathname = "/app/deal";
		else if (projectType === PROJECT_REG_CF) obj.pathname = "/app/dealCF";
		this.incrementViewCount(projectId, projectType);
		push(obj);
	};
	onProjectCategorySelected = (projectCategoryId) => {
		const { getAllInvestments } = this.props;
		this.setState({ selectedProjectCategory: projectCategoryId });
		getAllInvestments(projectCategoryId);
	};
	renderRegALLProjects(activeOffering, pastOfferings) {
		const {
			activeRegDInvestments,
			pastRegDInvestments,
			isAccreditedUser,
			wasAccreditedUser,
			showPastInvestments,
			loadingComplete,
		} = this.props;
		const {
			investmentLabels,
			investmentAmounts,
			cumulativeEarnings,
		} = this.props;
		if (
			this.state.selectedProjectType === PROJECT_REG_ALL &&
			(isAccreditedUser || wasAccreditedUser)
		) {
			return (
				<InvestorMessageSearchComponent/>

	);
		}
		return null;
	}
	renderRegDProjects(activeOffering, pastOfferings) {
		const {
			activeRegDInvestments,
			pastRegDInvestments,
			isAccreditedUser,
			wasAccreditedUser,
			showPastInvestments,
			loadingComplete,
		} = this.props;
		const {
			investmentLabels,
			investmentAmounts,
			cumulativeEarnings,
		} = this.props;
		if (
			this.state.selectedProjectType === PROJECT_REG_D &&
			(isAccreditedUser || wasAccreditedUser)
		) {
			return (
				<InvestorMessageSearchComponent/>	
			);
		}
		return null;
	}
	renderRegCFProjects(activeOffering, pastOfferings) {
		const {
			activeRegCfInvestments,
			pastRegCfInvestments,
			isAccreditedUser,
			wasAccreditedUser,
			showPastInvestments,
			loadingComplete,
		} = this.props;
		const {
			investmentLabels,
			investmentAmounts,
			cumulativeEarnings,
		} = this.props;
		if (
			this.state.selectedProjectType === PROJECT_REG_CF ||
			(!isAccreditedUser && !wasAccreditedUser)
		) {
			return (
				<div>
				<InvestorMessageSearchComponent/>	
				</div>
			);
		}
		return null;
	}
	render() {
		const { pastOfferingsChecked, activeOfferingsChecked } = this.state;
		const { isAccreditedUser, wasAccreditedUser } = this.props;
		return (
			<div className="container filter-wrap">
				<div className="pull-right">
					<OverviewOfferingsHeader
						onProjectTypeClickHandler={this.onProjectTypeChanged}
						activeOfferingsChecked={activeOfferingsChecked}
						pastOfferingsChecked={pastOfferingsChecked}
						isAccreditedUser={isAccreditedUser}
						wasAccreditedUser={wasAccreditedUser}
						selectedProjectType={this.state.selectedProjectType}
						projectCategoryList={this.props.projectCategories}
						projectCategoryOnClickHandler={
							this.onProjectCategorySelected
						}
						selectedProjectCategory={
							this.state.selectedProjectCategory
						}
					/>
				</div>
				{this.renderRegDProjects(
					activeOfferingsChecked,
					pastOfferingsChecked
				)}
				{this.renderRegCFProjects(
					activeOfferingsChecked,
					pastOfferingsChecked
				)}
				{this.renderRegALLProjects(
					activeOfferingsChecked,
					pastOfferingsChecked
				)}
			</div>
		);
	}
}
InvestorMessagesContainer.propTypes = {
	activeRegCfInvestments: PropTypes.array.isRequired,
	activeRegDInvestments: PropTypes.array.isRequired,
	addToWatchList: PropTypes.func.isRequired,
	getAllInvestments: PropTypes.func.isRequired,
	getAllProjectCategories: PropTypes.func.isRequired,
	history: PropTypes.any,
	incrementViewCount: PropTypes.func.isRequired,
	isAccreditedUser: PropTypes.bool.isRequired,
	pastRegCfInvestments: PropTypes.array.isRequired,
	pastRegDInvestments: PropTypes.array.isRequired,
	projectCategories: PropTypes.array.isRequired,
	showPastInvestments: PropTypes.bool.isRequired,
	user: PropTypes.any,
	investorId: PropTypes.number.isRequired,
	investorInvestments: PropTypes.array.isRequired,
	totalInvestmentRegCF: PropTypes.any.isRequired,
	totalInvestmentRegD: PropTypes.any.isRequired,
	totalPaymentsRegCF: PropTypes.any.isRequired,
	totalPaymentsRegD: PropTypes.any.isRequired,
	getAllUserInvestments: PropTypes.func.isRequired,
};
InvestorMessagesContainer.defaultProps = {
	showPastInvestments: true,
	getAllUserInvestments: () => null,
};
const mapDispatchToProps = (dispatch) => {
	const allInvestmentActions = bindActionCreators(
		AllInvestmentActions,
		dispatch
	);
	return { ...allInvestmentActions };
};
const mapStateToProps = (_state) => {
	return {
		..._state.investorDashboard,
		investorId: _state.session.user.id,
		..._state.allInvestments,
		user: _state.session.user,
		location: _state.location,
		projectCategories: _state.allInvestments.projectCategories,
	};
};
const connectedComponent = connect(
	mapStateToProps,
	mapDispatchToProps
)(InvestorMessagesContainer);
export default withRouter(connectedComponent);
