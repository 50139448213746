
import React, { useEffect } from 'react';
import swal from 'sweetalert2';
import NavBar from './components/shared/NavigationManager/NavigationBar/NavBarNoAuth';
import FooterFirst from './components/shared/NavigationManager/Footer/FooterFirst';
import RequestService from './api/RequestService';



export default function EmailVerified(props) {

	useEffect(() => {
		window.scrollTo(0, 0);
		verifyEmail();
	}, []);



	async function verifyEmail() {
		try {
			let data;

			if (props.isToken) {
				({ data } = await RequestService.get(`/api/verifyEmail/${props.match.params.id}`));    // Destucturing to existing variable, use outside ().
			}
			else {
				({ data } = await RequestService.get(`/api/validateEmail/${props.match.params.id}`));  // Destucturing to existing variable, use outside ().
			}

			if (data.code === 200) {
				swal('Email Verified', 'Thank you. You may login now.', 'success')
					.then(() => { window.location = '/login' });
			}
			else {
				swal('Error', data.message, 'error')
					.then(() => { window.location = '/login' });
			}
		}
		catch (err) {
			console.log(err);
		}
	}



	return (
		<div>
			<NavBar isMarketplace={props.isMarketplace} />
			<FooterFirst isMarketplace={props.isMarketplace} />
		</div>
	);
}


