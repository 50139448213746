import React from 'react';
import PropTypes from 'prop-types'
import BaseComponent from "../utils/BaseComponent";

class MultilineInputComponent extends BaseComponent {
    render = () => {
        const {inputReference, inputName, rows, maxInputLength, inputTitle, elementValue} = this.props;
        const maxInputMessage = maxInputLength ? `(Characters left: ${this.state.charsLeft})` : null;

        return (
            <div className="form-group">
                <label htmlFor={inputName}
                       className="input-label">
                    {inputTitle}{this.getRequired()} {maxInputMessage}
                </label>
                <textarea id={inputName}
                          onChange={this.onInputChange}
                          maxLength={maxInputLength}
                          className="form-control input"
                          ref={inputReference}
                          name={inputName}
                          rows={rows}
                          value={elementValue}/>
                <span id={`error_${inputName}`} className="text-danger"/>
            </div>
        );
    };
}

MultilineInputComponent.propTypes = {
    elementValue: PropTypes.any.isRequired,
    inputName: PropTypes.string.isRequired,
    inputReference: PropTypes.any.isRequired,
    inputTitle: PropTypes.string.isRequired,
    maxInputLength: PropTypes.number.isRequired,
    onInputUpdate: PropTypes.func.isRequired,
    rows: PropTypes.number.isRequired,
    isRequired: PropTypes.bool,
};

MultilineInputComponent.defaultProps = {
    elementValue: '',
    inputName: '',
    inputReference: () => null,
    inputTitle: '',
    maxInputLength: 250,
    onInputUpdate: () => null,
    rows: 2,
    isRequired: false,
};

export default MultilineInputComponent;