import initialState from '../../../../reducers/appInitialState';
import * as Actions from '../actions/actions';

export default (state = initialState.externalImages, action) => {
    const {onFlightRequest} = state;
    const plus = (onFlightRequest + 1);
    const minus = onFlightRequest <= 0 ? 0 : (onFlightRequest - 1);

    switch (action.type) {
        case Actions.EXTERNAL_IMAGE_REQUEST_START:
            return {...state, onFlightRequest: plus};
        case Actions.EXTERNAL_IMAGE_REQUEST_END:
            return {...state, onFlightRequest: minus};
        case Actions.EXTERNAL_IMAGE_UPLOAD_SUCCESS:
            return {...state, urlList: action.data, onFlightRequest: minus};
        default:
            return state
    }
}
