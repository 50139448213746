import React from 'react';
import PropTypes from "prop-types";
import TabComponent from './TabComponent';

class TabsComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedTab: -1,
        };
    };

    componentDidMount = () => {
        this.setDefaultSelection();
    };

    setDefaultSelection = () => {
        const {defaultSelection} = this.props;
        if (defaultSelection !== null || defaultSelection !== undefined)
            this.onSelectedTabHandler(defaultSelection);
    };

    onSelectedTabHandler = (tab) => {
        this.setState({selectedTab: tab});
        this.props.setSelected(tab);
    };

    renderTabs = () => {
        const {selectedTab} = this.state;
        return this.props.tabList.map((tab, key) => (
            <TabComponent key={key}
                          tab={tab}
                          selectedTab={selectedTab}
                          onSelectedTabHandler={this.onSelectedTabHandler}/>
        ));
    };

    render = () => {
        return (
            <div className="right_col">
                <ul className="nav nav-pills nav-justified">
                    {this.renderTabs()}
                </ul>
                <div className="tab-content">
                    {this.props.children}
                </div>
            </div>
        );
    };
}

TabsComponent.propTypes = {
    tabList: PropTypes.arrayOf(PropTypes.shape({
        number: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
    })).isRequired,
    /**
     * is the number of the tab that will be selected by default
     **/
    defaultSelection: PropTypes.number.isRequired,
    /**
     * This function will be called every time the user clicks in a
     * tab, it send as a parameter the number of the tab that was clicked
     **/
    setSelected: PropTypes.func.isRequired
};

TabsComponent.defaultProps = {
    tabList: [{number: 0, title: ""}],
    defaultSelection: 0,
    setSelected: () => "",
};

export default TabsComponent;
