import React, { Component } from 'react';
import swal from 'sweetalert';
import store from './index'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Link
} from 'react-router-dom'
import { withRouter } from 'react-router-dom';
import getDate from './utils/ConvertDate'


class SelectedProject extends Component {
    constructor(props){
        super(props);
        this.state={
            id:props.session.id,
            Deal:[],
            isLoaded:false,
            errorOnLoad:false
        };
        this.getProject=this.getProject.bind(this);
        this.onApprove=this.onApprove.bind(this);
    }

    onApprove(){
        this.props.history.push("/app/adminapproveproject");
    }

    getProject(){
        fetch('/api/getnonappproject/'+(localStorage.getItem("ApproveProjectID")),{
            method:'get'
        })
            .then((response)=>{
                return response.json()
            })
            .then((data)=>{
                this.setState({
                    Deal:data[0],
                    isLoaded:true,
                    err:{},
                    errorOnLoad:false,
                });
            })
            .catch((err)=>{
                this.setState({
                    err:err,
                    errorOnLoad:true
                })

            })
    }

    componentWillMount(){
        window.scrollTo(0, 0);
        store.subscribe(() => {
            var currentState=store.getState();
            this.setState({
                id:currentState.session.user.id
            });
        });
        this.getProject();
    }

    render() {
        return(
            <div className="container body">
                <div className="main_container">
                    <div className="right_col" role="main">
                        <div className="clearfix" />
                        <div>
                            <div className="portfolio-modal" >
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="container">

                                            <div className=" col-sm-12 col-md-12 col-xs-12">
                                                <div className="modal-body">
                                                    <h2 className="modaltext">{this.state.Deal.ProjectName}</h2>
                                                    <hr className="star-primary" />

                                                        <h5 className="modaltext">Company Name</h5>
                                                            <strong>
                                                                <b className="text2">{this.state.Deal.CompanyName}</b>
                                                            </strong>
                                                        <br/>
                                                        <h5 className="modaltext">Funding Goal</h5>
                                                            <strong>
                                                                <b className="text2">{this.state.Deal.TotalPurchasePrice}</b>
                                                            </strong>
                                                        <br/>
                                                        <h5 className="modaltext">Funding Period</h5>
                                                            <p className="text2">{this.state.Deal.FundingPeriod}</p>

                                                        <h5 className="modaltext">Project Description</h5>
                                                            <strong>
                                                                <b className="text2">{this.state.Deal.ProjectDescriptionDocId}</b>
                                                            </strong>
                                                        <br/>
                                                        <h5 className="modaltext">Project Started on:</h5>
                                                            <strong>

                                                                <b className="text2">{getDate.getDate(this.state.Deal.CreatedDate)}</b>
                                                            </strong>
                                                        <br/>
                                                        <h5 className="modaltext">Contact Name</h5>
                                                            <strong>
                                                                <b className="text2">{this.state.Deal.ContactName}</b>
                                                            </strong>
                                                        <br/>
                                                    <br/>
                                                    <center><button className="btn btn-success"  type="Submit" onClick={this.onApprove}>
                                                        Approve</button></center>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }
}
const { object, bool } = PropTypes;

SelectedProject.propTypes = {
    session: object.isRequired,
    authenticated: bool.isRequired,
    checked: bool.isRequired
};

const mapState = ({ session }) => ({
    session: session.user,
    checked: session.checked,
    authenticated: session.authenticated
});

export default withRouter(connect(mapState)(SelectedProject));
