
import React, {Component} from 'react';
import swal from 'sweetalert2';
import store from '../../index'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import getDate from '../../utils/ConvertDate'
import RequestService from "../../api/RequestService";


class Postinvestments extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id:props.session.id,
            URL:"",
            Roles:[],
            MainRole:"Investor",
            IsAccreditated: props.session.AccreditationStatus,

            allAprovedprojectsCF:[],
            AprovedprojectsCF:[],
            CurrentFunding:[],
            CurrentFundingCF:[],
            Cashcallprojects:[],
            pageNumber: 1,
            totalCount: 0,
            perPage: 10,
            activePage: 1,


            allProjectsD: [],
            Aprovedprojects:[],
            pageNumberD: 1,
            totalCountD: 0,
            perPageD: 10,
            activePageD: 1,
            isLoaded:false,
            errorOnLoad:false
        };

        this.getApprovedprojects=this.getApprovedprojects.bind(this);
        this.getApprovedprojectsCF=this.getApprovedprojectsCF.bind(this);
        // this.getCashcallprojects=this.getCashcallprojects.bind(this);
        this.onAddProject=this.onAddProject.bind(this);
        this.onDealClick=this.onDealClick.bind(this);
        this.onDealClickCF=this.onDealClickCF.bind(this);
        this.getRoles=this.getRoles.bind(this);
        this.IncrementCount = this.IncrementCount.bind(this);
        this.getCurrentFunding=this.getCurrentFunding.bind(this);
        this.getCurrentFundingCF=this.getCurrentFundingCF.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handlePageChangeD = this.handlePageChangeD.bind(this);
    }



	componentDidMount() {
		window.scrollTo(0, 0);
		this.getCurrentFunding();
		this.getCurrentFundingCF();
		this.getApprovedprojects();
		this.getApprovedprojectsCF();
		// this.getCashcallprojects();
		this.getRoles();
	}



    handlePageChange(pageNumber) {
        var prev = ((pageNumber)-1)*2;
        var next = prev+2;
        this.setState({
            activePage: pageNumber,
            AprovedprojectsCF: this.state.allAprovedprojectsCF.slice(prev, next)
        });

    }

    handlePageChangeD(pageNumberD) {
        var prev = ((pageNumberD) - 1) * 4;
        var next = prev + 4;
        this.setState({
            activePageD: pageNumberD,
            Aprovedprojects: this.state.allProjectsD.slice(prev, next)
        });
    }



    IncrementCount(type, id) {
		RequestService.get(`/api/updateViewCount/${type}/${id}`);
    }



    getCurrentFunding(){
        fetch('/api/getcurrentfund'+'/'+this.state.id+'/'+this.props.session.marketing,{
            method:'get'
        })
            .then((response)=>{
                return response.json()
            })
            .then((data)=>{
                this.setState({
                    CurrentFunding:data,
                    isLoaded:true,
                    err:{},
                    errorOnLoad:false,
                });
            })
            .catch((err)=>{
                this.setState({
                    err:err,
                    errorOnLoad:true
                })
            })
    }

    getCurrentFundingCF(){
        fetch('/api/getcurrentfundCF'+'/'+this.state.id+'/'+this.props.session.marketing,{
            method:'get'
        })
            .then((response)=>{
                return response.json()
            })
            .then((data)=>{
                this.setState({
                    CurrentFundingCF:data,
                    isLoaded:true,
                    err:{},
                    errorOnLoad:false,
                });
            })
            .catch((err)=>{
                this.setState({
                    err:err,
                    errorOnLoad:true
                })
            })
    }


    onDealClick(id, type, e) {
        if(type === "notify") {
            e.stopPropagation();
             this.props.history.push({
                              pathname: '/app/projectnotification',
                              state: { id: id }
                            })
        } else {
            this.IncrementCount(id);
            this.props.history.push({
                    pathname: '/app/deal',
                    state: { id: id }
              })
        }
    }
    onDealClickCF(id, type, e) {
        if(type === "notify") {
            e.stopPropagation();
             this.props.history.push({
                              pathname: '/app/projectnotificationCF',
                              state: { id: id }
                            })
        }
        else {
            this.IncrementCountCF(id);
            this.props.history.push({
                    pathname: '/app/dealCF',
                    state: { id: id }
              })
        }
    }

    // getCashcallprojects(){
    //     fetch('/api/getprojectinvestments',{
    //         method:'get'
    //     })
    //         .then((response)=>{
    //             return response.json()
    //         })
    //         .then((data)=>{
    //             this.setState({
    //                 Cashcallprojects:data,
    //                 isLoaded:true,
    //                 err:{},
    //                 errorOnLoad:false,
    //             });
    //         })
    //         .catch((err)=>{
    //             this.setState({
    //                 err:err,
    //                 errorOnLoad:true
    //             })

    //         })
    // }

    getRoles(){
        fetch('/api/getrole/'+this.state.id+'/'+this.state.id+'/'+this.props.session.marketing,{
            method:'get'
        })
            .then((response)=>{
                return response.json()
            })
            .then((data)=>{
                for (let i = 0; i < data.length; i++) {
                        if (data[i].Role === "SuperAdmin") {
                            this.setState({
                                Roles:data,
                                MainRole: "SuperAdmin",
                                isLoaded:true,
                                err:{},
                                errorOnLoad:false
                            });
                            break;
                        }
                        else if(data[i].Role === "Admin") {
                            this.setState({
                                Roles:data,
                                MainRole: "Admin",
                                isLoaded:true,
                                err:{},
                                errorOnLoad:false
                            });
                            break;
                        }
                        else if(data[i].Role === "FundRaiser") {
                            this.setState({
                                Roles:data,
                                MainRole: "FundRaiser",
                                isLoaded:true,
                                err:{},
                                errorOnLoad:false
                            });
                            break;
                        } else {
                            this.setState({
                                Roles:data,
                                MainRole: "Investor",
                                isLoaded:true,
                                err:{},
                                errorOnLoad:false
                            });
                            break;
                        }
                    }
            })
            .catch((err)=>{
                this.setState({
                    err:err,
                    errorOnLoad:true
                })
            })
    }

    getApprovedprojects(){
        fetch('/api/getapprovedprojectsbyid/'+this.state.id+'/'+this.props.session.marketing,{
            method:'get'
        })
        .then((response)=>{
            return response.json()
        })
        .then((data)=>{
            this.setState({
                Aprovedprojects:data.slice(0,4),
                allProjectsD:data,
                pageNumberD: 1,
                totalCountD: data.length,
                perPageD: 4,
                activePageD: 1,
                isLoaded:true,
                err:{},
                errorOnLoad:false,
            });
        })
        .catch((err)=>{
            this.setState({
                err:err,
                errorOnLoad:true
            })
        })
    }

    getApprovedprojectsCF(){
        fetch('/api/getapprovedprojectsCFbyid/'+this.state.id+'/'+this.props.session.marketing,{
            method:'get'
        })
        .then((response)=>{
            return response.json()
        })
        .then((data)=>{
            this.setState({
                AprovedprojectsCF:data.slice(0,2),
                allAprovedprojectsCF:data,
                pageNumber: 1,
                totalCount: data.length,
                perPage: 2,
                activePage: 1,
                isLoaded:true,
                err:{},
                errorOnLoad:false,
            });
        })
        .catch((err)=>{
            this.setState({
                err:err,
                errorOnLoad:true
            })
        })
    }

    onAddProject(){
        fetch('/api/rolecheck/'+this.state.id+'/'+this.props.session.marketing,{
            method:'get'
        })
            .then((response)=>{
                return response.json()
            })
            .then((status)=>{
                if(status.code===200){
                    swal({
                        title: "Please Select Project Type:",
                        showCancelButton: true,
                        confirmButtonText: "Regulation D",
                        cancelButtonText: "Regulation CF",
                        closeOnConfirm: false,
                        closeOnCancel: true,
                        showLoaderOnConfirm: true
                    }).then((isConfirm)=>{
                        if (isConfirm.value===true) {
                            this.props.history.push('/app/AdminaddProject_D')
                        }
                        else {
                            this.props.history.push('/app/AdminaddProject_CF')
                        }
                    })


                }
                else if(status.code === 402){
                    swal("Access Denied", " ", "error")
                }
                else if(status.code === 500){
                    swal("Access Denied", " ", "error")
                }
            })
            .catch((err) => {
                alert("Error: " + JSON.stringify(err))
            })
    }


    render() {
        return (
            <div className="container body">
            <div className="main_container">
                {/* page content */}
                <div className="right_col" role="main">
                    <div className="col-md-10 col-sm-10 col-xs-8">
                    </div>
                    <div className="row">
                        {(() => {
                            switch (this.state.Aprovedprojects.length) {
                                case 0  : return (<h3>No Active Investment Opportunities</h3>);
                                case 1  : return (<h3>Active Investment Opportunities</h3>);
                                default : return (<h3>Active Investment Opportunities</h3>);
                            }
                        })()}
                        <div>
                            {
                                (() => {
                                    if (this.state.IsAccreditated) {
                                        return (
                                            <ul className="nav nav-pills nav-justified" style={{ padding: '0 10px', marginBottom: 20 }}>
                                                <li className="active"><a data-toggle="pill" href="#menu1">EnergyFunders Marketplace (Reg CF)</a></li>
                                                <li><a data-toggle="pill" href="#home">EnergyFunders Black (Reg D)</a></li>
                                            </ul>
                                        );
                                    }
                                })()
                            }

                            <div className="tab-content">
                                <div id="home" className="tab-pane fade">
                                    {
                                        (() => {
                                            if (this.state.IsAccreditated) {
                                                return (
                                                    <div>
                                                        {
                                                            this.state.Aprovedprojects.map((project)=>(
                                                                <span style={{cursor:'pointer'}}>
                                                                    <div key={project.ProjectInvestmentID} onClick={(e)=>this.onDealClick(project.ProjectID,'deal',e)} className="col-xs-6 col-md-3 col-sm-4 col-lg-3">
                                                                        <div className="project project-radius project-primary">


                                                                                {(() => {
                                                                                    switch (project.IsCashCall) {
                                                                                        case 0  : return (<div className="shape" style={{borderRightColor: "#905aab"}}><div className="shape-text">New</div></div>);
                                                                                        case 1  : return (<div className="shape"><div className="shape-text" style={{color:"#fff",fontSize:12,fontWeight:"bold",position:'relative',right:-27,top:-4 }}>CashCall</div></div>);
                                                                                        default : return (<div className="shape"><div className="shape-text">New</div></div>);
                                                                                    }
                                                                                })()}


                                                                            <img className="topimage" height="175" width="100%" src="/img/image.jpg" alt=""/>

                                                                            <div className="project-content">

                                                                                <h3 className="lead">
                                                                                    {(() => {
                                                                                        switch (project.IsCashCall) {
                                                                                            case 0  : return (project.ProjectName);
                                                                                            case 1  : return (project.ProjectName)+" - Cash Call";
                                                                                            default : return (project.ProjectName);
                                                                                        }
                                                                                    })()}

                                                                                </h3>
                                                                                <p>
                                                                                    Company : {project.OperatedBy}
                                                                                    <br/>Opens On : {getDate.getDate(project.DateInvestmentStart)}
                                                                                </p>
                                                                                <b>
                                                                                    {(() => {
                                                                                            let obj = this.state.CurrentFunding.find(x => x.ProjectID === project.ProjectID);
                                                                                            let emptyobj={
                                                                                                CurrentFunding:0
                                                                                            };
                                                                                        switch (project.IsCashCall) {
                                                                                            case 0: return ('$' + ((obj) ? Math.round(obj.CurrentFunding) : emptyobj.CurrentFunding))+'/'+'$'+(project.FundingGoalMax);
                                                                                            case 1  : return ('$' + ((obj) ? Math.round(obj.CurrentFunding) : emptyobj.CurrentFunding))+'/'+'$'+(project.FundingGoalMax);
                                                                                            default : return ('$'+(project.currentFunding)+'/'+'$'+(project.FundingGoal));
                                                                                        }
                                                                                    })()}</b>
                                                                                {(() => {
                                                                                     let obj =  this.state.CurrentFunding.find(x => x.ProjectID === project.ProjectID);
                                                                                     let emptyobj={
                                                                                         CurrentFunding:0
                                                                                     };
                                                                                    switch (project.IsCashCall) {
                                                                                        case 0  : return (<div className="progress" style={{width:'100%'}}>
                                                                                            <div className="progress-bar" role="progressbar" aria-valuenow={70} aria-valuemin={0} aria-valuemax={100} style={{ width: "" + (((obj) ? Math.round(obj.CurrentFunding):emptyobj.CurrentFunding)/project.FundingGoalMax)*100+"%"}}></div>
                                                                                        </div>);
                                                                                        case 1  : return (<div className="progress" style={{width:'100%'}}>
                                                                                            <div className="progress-bar" role="progressbar" aria-valuenow={70} aria-valuemin={0} aria-valuemax={100} style={{ width: "" + (((obj) ? Math.round(obj.CurrentFunding):emptyobj.CurrentFunding)/project.FundingGoalMax)*100+"%"}}></div>
                                                                                        </div>);
                                                                                        default : return (<div className="progress" style={{width:'100%'}}>
                                                                                            <div className="progress-bar" role="progressbar" aria-valuenow={70} aria-valuemin={0} aria-valuemax={100} style={{width: ""+(project.currentFunding/project.FundingGoalMax)*100+"%"}}></div>
                                                                                        </div>);
                                                                                    }
                                                                                })()}


                                                                                <div> {(() => {
                                                                                        let obj =  this.state.CurrentFunding.find(x => x.ProjectID === project.ProjectID);
                                                                                        let emptyobj={
                                                                                            CurrentFunding:0
                                                                                        };
                                                                                    switch (project.IsCashCall) {
                                                                                        case 0: return ((Math.round((((obj) ? Math.round(obj.CurrentFunding):emptyobj.CurrentFunding)/project.FundingGoalMax)*100))+'%');
                                                                                        case 1  : return ((Math.round((((obj) ? Math.round(obj.CurrentFunding):emptyobj.CurrentFunding)/project.FundingGoalMax)*100))+'%');
                                                                                        default : return (Math.round((project.currentFunding/project.FundingGoal)*100)+'%');
                                                                                    }
                                                                                })()}</div><br/>
                                                                                {
                                                                                    (() => {
                                                                                        if(this.state.MainRole !== 'Investor') {
                                                                                            return(
                                                                                                <button className="btn btn-primary" onClick={(e) => this.onDealClick(project.ProjectInvestmentID, 'notify', e)} style={{ width: '100%' }}>Notify</button>
                                                                                            )
                                                                                        }
                                                                                    })()
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </span>
                                                            ))
                                                        }

                                                        <div className="pull-right" style={{clear:'both'}}>
                                                            <Pagination
                                                                firstPageText={<i className='glyphicon glyphicon-chevron-left' />}
                                                                lastPageText={<i className='glyphicon glyphicon-chevron-right' />}
                                                                prevPageText={<i className='glyphicon glyphicon-menu-left' />}
                                                                nextPageText={<i className='glyphicon glyphicon-menu-right' />}
                                                                activePage={this.state.activePageD}
                                                                itemsCountPerPage={this.state.perPageD}
                                                                totalItemsCount={this.state.totalCountD}
                                                                onChange={this.handlePageChangeD}
                                                            />
                                                        </div>
                                                    </div>
                                                )

                                            } else {
                                                return (
                                                    <div style={{ minHeight: 100 }}>
                                                        <h4 className="text-center" style={{ color: 'red' }}>You are not accredited to access</h4>
                                                    </div>
                                                )
                                            }
                                        })()
                                    }
                                </div>
                                <div id="menu1" className="tab-pane fade in active">

                                    {
                                        this.state.AprovedprojectsCF.map((project) => (
                                            <span style={{ cursor: 'pointer' }}>
                                                <div key={project.ProjectInvestmentID} onClick={(e) => this.onDealClickCF(project.ProjectID, 'dealCF', e)} className="col-xs-6 col-md-3 col-sm-4 col-lg-3">
                                                    <div className="project project-radius project-primary">
                                                        <div className="shape" style={{borderRightColor: "#905aab"}}>
                                                            <div className="shape-text">
                                                                New
                                                            </div>
                                                        </div>
                                                        <img className="topimage" height="175" width="100%" src={(project.Image!='0')?(project.Image):("/img/image.jpg")} alt="" />
                                                        <div className="project-content">

                                                            <h3 className="lead">
                                                                {(() => {
                                                                    switch (project.IsCashCall) {
                                                                        case 0: return (project.InvestmentName);
                                                                        case 1: return (project.InvestmentName) + " - Cash Call";
                                                                        default: return (project.InvestmentName);
                                                                    }
                                                                })()}
                                                            </h3>
                                                            <p>
                                                                 MinimumInvestment : {project.MinimumInvestment}
                                                                <br />Opens On : {getDate.getDate(project.InvestmentStartDate)}
                                                            </p>
                                                            <b>
                                                                {(() => {
                                                                     let obj =  this.state.CurrentFundingCF.find(x => x.ProjectID === project.ProjectID);
                                                                     let emptyobj={
                                                                         CurrentFunding:0
                                                                     };
                                                                    switch (project.IsCashCall) {
                                                                        case 0: return ('$' + ((obj)?Math.round(obj.CurrentFunding):emptyobj.CurrentFunding) + '/' + '$' + (project.FundingGoalMax));
                                                                        case 1: return ('$' + (project.ccCurrentFunding) + '/' + '$' + (project.Amount));
                                                                        default: return ('$' + (project.currentFunding) + '/' + '$' + (project.FundingGoal));
                                                                    }
                                                                })()}</b>
                                                            {(() => {
                                                                    let obj =  this.state.CurrentFundingCF.find(x => x.ProjectID === project.ProjectID);
                                                                    let emptyobj={
                                                                       CurrentFunding:0
                                                                    };
                                                                switch (project.IsCashCall) {
                                                                    case 0: return (<div className="progress" style={{ width: '100%' }}>
                                                                        <div className="progress-bar" role="progressbar" aria-valuenow={70} aria-valuemin={0} aria-valuemax={100} style={{ width: "" + (((obj)?Math.round(obj.CurrentFunding):emptyobj.CurrentFunding) / project.FundingGoalMax) * 100 + "%" }}></div>
                                                                    </div>);
                                                                    case 1: return (<div className="progress" style={{ width: '100%' }}>
                                                                        <div className="progress-bar" role="progressbar" aria-valuenow={70} aria-valuemin={0} aria-valuemax={100} style={{ width: "" + (project.ccCurrentFunding / project.Amount) * 100 + "%" }}></div>
                                                                    </div>);
                                                                    default: return (<div className="progress" style={{ width: '100%' }}>
                                                                        <div className="progress-bar" role="progressbar" aria-valuenow={70} aria-valuemin={0} aria-valuemax={100} style={{ width: "" + (project.currentFunding / project.FundingGoal) * 100 + "%" }}></div>
                                                                    </div>);
                                                                }
                                                            })()}
                                                                <div> {(() => {
                                                                    let obj =  this.state.CurrentFundingCF.find(x => x.ProjectID === project.ProjectID);
                                                                    let emptyobj={
                                                                        CurrentFunding:0
                                                                    };
                                                                    switch (project.IsCashCall) {
                                                                        case 0: return (Math.round((((obj) ? Math.round(obj.CurrentFunding) : emptyobj.CurrentFunding) / project.FundingGoalMax) * 100) + '%');
                                                                        case 1: return (Math.round((project.ccCurrentFunding / project.Amount) * 100) + '%');
                                                                        default: return (Math.round((project.currentFunding / project.FundingGoal) * 100) + '%');
                                                                    }
                                                                    })()}</div><br />
                                                                    {
                                                                        (() => {
                                                                            if (this.state.MainRole !== 'Investor') {
                                                                                return (
                                                                                    <button className="btn btn-primary" onClick={(e) => this.onDealClickCF(project.ProjectInvestmentID, 'notify', e)} style={{ width: '100%' }}>Notify</button>
                                                                                        )
                                                                                }
                                                                        })()
                                                                    }
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                </span>
                                                            ))
                                                        }
                                                        <div className="pull-right" style={{clear:'both'}}>
                                                            <Pagination
                                                                firstPageText={<i className='glyphicon glyphicon-chevron-left' />}
                                                                lastPageText={<i className='glyphicon glyphicon-chevron-right' />}
                                                                prevPageText={<i className='glyphicon glyphicon-menu-left' />}
                                                                nextPageText={<i className='glyphicon glyphicon-menu-right' />}
                                                                activePage={this.state.activePage}
                                                                itemsCountPerPage={this.state.perPage}
                                                                totalItemsCount={this.state.totalCount}
                                                                onChange={this.handlePageChange}
                                                            />
                                                        </div>
                                                    </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

const { object, bool } = PropTypes;

Postinvestments.propTypes = {
    session: object.isRequired,
    authenticated: bool.isRequired,
    checked: bool.isRequired
};

const mapState = ({ session }) => ({
    session: session.user,
    checked: session.checked,
    authenticated: session.authenticated
});

export default withRouter(connect(mapState)(Postinvestments));
