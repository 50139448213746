import PropTypes from 'prop-types'
import React from 'react';
import Icon from "../../../IconComponent/IconComponent";

class CategoryDropdown extends React.Component {
    renderCategoryList = () => {
        const {categoryList, onClickHandler} = this.props;
        return categoryList.map(obj => {
            const {categoryId, categoryName} = obj;
            return (
                <li key={categoryId}
                    className="btn-default"
                    onClick={() => onClickHandler(categoryId)}>
                    <a className="text-capitalize">{categoryName}</a>
                </li>
            )
        });
    };

    render() {
        const {onClickHandler, selectedCategory, categoryList} = this.props;
        let _selectedCategory = categoryList.filter(cat => cat.categoryId === selectedCategory)[0];
        _selectedCategory = _selectedCategory ? _selectedCategory : {categoryName: 'all categories'};

        return (
            <div className="dropdown offerings-header-type-selector">
                <button className="btn btn-primary btn-lg dropdown-toggle"
                        type="button" id="dropdownMenu1"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="true">
                    <span className="pull-left text-capitalize">
                        {_selectedCategory.categoryName}
                    </span>
                    <Icon iconName="caret-down" extraClasses="white-text pull-right"/>
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
                    <li className="btn-default"
                        onClick={() => onClickHandler('all')}>
                        <a className="text-capitalize">all categories</a>
                    </li>
                    {this.renderCategoryList()}
                </ul>
            </div>
        );
    }
}

CategoryDropdown.propTypes = {
  categoryList: PropTypes.array.isRequired,
  onClickHandler: PropTypes.func.isRequired,
  selectedCategory: PropTypes.any.isRequired
};

CategoryDropdown.defaultProp = {
    categoryList: [],
    onClickHandler: () => null,
    selectedCategory: null,
};

export default CategoryDropdown;
