import RequestService from '../../../../api/RequestService';
import NotificationService from '../../../../utils/NotificationService';



const MESSAGE_SHOW_TIME = 10000;

export const MASS_FILE_UPLOAD_ALL_FILES_SUCCESS = 'MASS_FILE_UPLOAD_ALL_FILES_SUCCESS';
export const MASS_FILE_UPLOAD_REQ_START = 'MASS_FILE_UPLOAD_REQ_START';
export const MASS_FILE_UPLOAD_REQ_END = 'MASS_FILE_UPLOAD_REQ_END';
export const GET_ALL_INVESTOR_CONTRACT_FILES_SUCCESS = 'GET_ALL_INVESTOR_CONTRACT_FILES_SUCCESS'

const startRequest = () => ({ type: MASS_FILE_UPLOAD_REQ_START });
const endRequest = () => ({ type: MASS_FILE_UPLOAD_REQ_END });
const getAllInvestorFiles = data => ({
    type: MASS_FILE_UPLOAD_ALL_FILES_SUCCESS,
    data,
});
const getAllContractFilesSuccess = data => ({
    type: GET_ALL_INVESTOR_CONTRACT_FILES_SUCCESS,
    data,
});


export const massFileUpload = (data, clean = () => null) => async (dispatch) => {
    const url = '/api/admin/investor/files/mass-upload';

    try {
        dispatch(startRequest());

        await RequestService.post({
            url,
            data,
        });

        dispatch(endRequest());
        NotificationService.success();
        clean();
    }
    catch (error) {
        console.log('Error in the mass file upload');
        NotificationService.error('Error storing the files in the server', MESSAGE_SHOW_TIME);
        dispatch(endRequest());
    }
};



export const getAllInvestorFile = () => async (dispatch) => {
    const url = '/api/admin/investor/files/all';
    console.log('Getting all files')

    try {
        dispatch(startRequest());

        const { data } = await RequestService.get(url);

        dispatch(getAllInvestorFiles(data));
    }
    catch (error) {
        dispatch(endRequest());
        console.log('Error requesting all the stored files: ERROR: ', error);
        NotificationService.error('Error getting the list of stored files', MESSAGE_SHOW_TIME);
    }
};

export const getAllContractFiles =() => async (dispatch) =>{
        const url = '/api/getAllHelloSignContractDoc';
        console.log('Getting all the contract files');
        try{

            dispatch(startRequest());

            const { data, status } = await RequestService.get(url);

            dispatch(getAllContractFilesSuccess(data));

        }catch (error) {
            NotificationService.error('Error getting contract files', MESSAGE_SHOW_TIME);
            dispatch(endRequest());
        }
}


export const markFileAsDeleted = (investorId, fileId) => async (dispatch) => {
    const url = `/api/admin/investor/${investorId}/files/${fileId}`;

    try {
        dispatch(startRequest());

        await RequestService.delete(url);

        dispatch(endRequest());

        NotificationService.success();
        dispatch(getAllInvestorFile());
    }
    catch (error) {
        NotificationService.error('Error deleting the file from the server', 6000);
        dispatch(endRequest());
    }
};



export const updateInvestorFile = (investorId, fileId, data) => async (dispatch) => {
    const url = `/api/admin/investor/${investorId}/files/${fileId}`;

    try {
        dispatch(startRequest());

        await RequestService.put({
            url,
            data,
        });

        dispatch(endRequest());

        NotificationService.success();
        dispatch(getAllInvestorFile());
    }
    catch (error) {
        console.log('Error updating the file: ERROR: ', error);
        dispatch(endRequest());
    }
};
