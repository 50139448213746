import RequestService from '../../../../api/RequestService';
import { HTTP_ERR_BAD_REQUEST, PROJECT_REG_D } from '../../../../utils/ApplicationConstants';
import NotificationService from '../../../../utils/NotificationService';

export const PROJECT_EDITOR_V2_REQUEST_START = 'PROJECT_EDITOR_V2_REQUEST_START';
export const PROJECT_EDITOR_V2_REQUEST_END = 'PROJECT_EDITOR_V2_REQUEST_END';
export const PROJECT_EDITOR_V2_EXTRACT_DATA_SUCCESS = 'PROJECT_EDITOR_V2_EXTRACT_DATA_SUCCESS';
export const PROJECT_EDITOR_V2_EXTRACT_PARENTS_SUCCESS = 'PROJECT_EDITOR_V2_EXTRACT_PARENTS_SUCCESS';
export const PROJECT_EDITOR_V2_EXTRACT_HELLO_SIGN_SUCCESS = 'PROJECT_EDITOR_V2_EXTRACT_HELLO_SIGN_SUCCESS';

const startRequest = () => ({ type: PROJECT_EDITOR_V2_REQUEST_START });
const endRequest = () => ({ type: PROJECT_EDITOR_V2_REQUEST_END });
const getProjectHellosignSuccess = data => ({
    type: PROJECT_EDITOR_V2_EXTRACT_HELLO_SIGN_SUCCESS,
    data,
});
const getProjectParentListSuccess = data => ({
    type: PROJECT_EDITOR_V2_EXTRACT_PARENTS_SUCCESS,
    data,
});

export const getProjectDataSuccess = data => ({
    type: PROJECT_EDITOR_V2_EXTRACT_DATA_SUCCESS,
    data,
});

export function getProjectInformation(projectId, projectType) {
    return async (dispatch) => {
        const URL = `/api/project/${projectId}/edit/type/${projectType}`;

        dispatch(startRequest());
        const { data, status } = await RequestService.get(URL);

        if (status >= HTTP_ERR_BAD_REQUEST) {
            NotificationService.error('Error Extracting the project information');
            dispatch(endRequest());
            return;
        }

        dispatch(getProjectDataSuccess(data));
    };
}

export function getAllProject() {
    return async (dispatch) => {
        dispatch(startRequest());
        const { data, status } = await RequestService.get('/api/getParentProjectList');

        if (status >= HTTP_ERR_BAD_REQUEST) {
            NotificationService.error('Error extracting the project parent list');
            dispatch(endRequest());
            return;
        }

        let _data = data || [];

        _data = _data.map(item => ({
            id: item.ProjectID,
            title: item.ProjectName,
        }));

        dispatch(getProjectParentListSuccess(_data));
    };
}

export function storeUpdatedProject(userData, projectData, projectId, projectType) {
    return async (dispatch) => {
        const { id, marketing } = userData;
        const regDUrl = `/api/updateProjectInvestment_RegDsaved/${projectId}/${id}/${marketing}`;
        const regCfUrl = `/api/updateProjectInvestment_RegCFsavedV2/${projectId}/${id}/${marketing}`;
        const URL = projectType === PROJECT_REG_D ? regDUrl : regCfUrl;

        dispatch(startRequest());
        const { status } = await RequestService.post({
            url: URL,
            data: projectData,
        });

        dispatch(endRequest());

        if (status >= HTTP_ERR_BAD_REQUEST) {
            NotificationService.error('Error updating project');
            return;
        }

        dispatch(endRequest());
        NotificationService.success();
		/* There is a bug which prevents users from updating TABS after saving once.
		Reloading the window fixes it.
		Once the bug is fixed, we can remove the "reload" and put the dispatch back. */

        // dispatch(getProjectInformation(projectId, projectType));
		window.location.href = window.location.pathname;
    };
}

export function getHelloSignTemplates(userData) {
    return async (dispatch) => {
        const { id, marketing } = userData;

        dispatch(startRequest());
        const { data, status } = await RequestService.get(`/api/getHelloSignDocList/${id}/${marketing}`);

        if (data.code >= HTTP_ERR_BAD_REQUEST || status >= HTTP_ERR_BAD_REQUEST) {
            dispatch(endRequest());
            NotificationService.error('Error extracting the HelloSign templates');
            return;
        }

        const _data = data.data.templates.map(item => ({
            id: item.template_id,
            title: item.title,
        }));

        dispatch(getProjectHellosignSuccess(_data));
    };
}

export function storeNewProjectInvestment(projectId, projectType, requestData) {
    return async (dispatch) => {
        const url = `/api/project/${projectId}/approve/type/${projectType}`;

        dispatch(startRequest());
        const { data } = await RequestService.post({
            url,
            data: requestData,
        });
        const { status } = data;

        if (status >= HTTP_ERR_BAD_REQUEST) {
            NotificationService.error('Error creating new project investment');
            dispatch(endRequest());
            return;
        }

        NotificationService.success();
        dispatch(getProjectInformation(projectId, projectType));
        dispatch(endRequest());
    };
}

export function updateFileOrdering(projectId, projectType, data, cleanMethod = () => null) {
    return async (dispatch) => {
        const url = `/api/admin/project/${projectId}/position`;

        try {
            dispatch(startRequest());
            await RequestService.put({
                url,
                data,
            });

            cleanMethod();
            NotificationService.success();
            dispatch(getProjectInformation(projectId, projectType));
        } catch (error) {
            NotificationService.error('Error updating the files order');
            dispatch(endRequest());
        }
    };
}
