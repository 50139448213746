import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';



function SupportPage() {


	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);


	return (
		<div className="container body">
			<div className="main_container">
				<div className="right_col" role="main">
					<div className>
						<div className="page-title">
							<div className="title_left">
								<h3>EnergyFunders Support</h3>
							</div>
						</div>
						<div className="clearfix" />
						<a href="https://www.energyfunders.com/support" className="btn btn-success" target="_blank">Request Screen Share Support</a>
					</div>
				</div>
			</div>
		</div>
	);
}


export default withRouter(SupportPage);
