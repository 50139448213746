import axios from "axios";
import * as Constants from "../utils/ApplicationConstants";
import NotificationService from "../utils/NotificationService";

const ERROR_MESSAGE_DURATION = 5000;

export const END_PROJECT_REQUEST = "END_PROJECT_REQUEST";
export const REQUEST_ALL_REG_CF_PROJECTS_SUCCESS = "REQUEST_ALL_REG_CF_PROJECTS_SUCCESS";
export const REQUEST_ALL_REG_D_PROJECTS_SUCCESS = "REQUEST_ALL_REG_D_PROJECTS_SUCCESS";
export const START_PROJECT_REQUEST = "START_PROJECT_REQUEST";

// actions
export function startProjectRequest() {
    return {type: START_PROJECT_REQUEST};
}

export function endProjectRequest() {
    return {type: END_PROJECT_REQUEST};
}

export function getAllProjectsDSuccess(data) {
    return {type: REQUEST_ALL_REG_D_PROJECTS_SUCCESS, data};
}

export function getAllProjectsCFSuccess(data) {
    return {type: REQUEST_ALL_REG_CF_PROJECTS_SUCCESS, data};
}

// requests
export function requestAllProjects(userData, successDispatch, type) {
    return async function (dispatch) {
        dispatch(startProjectRequest());
        const {id, marketing} = userData;
        const response = await axios.get(`/api/projects/u/${id}/h/${marketing}/project-type/${type}`);
        dispatchSuccess(response, dispatch, successDispatch);
        dispatchError(response, dispatch, "Error Requesting Reg D Projects");
    }
}

export function getAllProjects(userData) {
    return function (dispatch) {
        dispatch(requestAllProjects(userData, getAllProjectsDSuccess, Constants.PROJECT_REG_D));
        dispatch(requestAllProjects(userData, getAllProjectsCFSuccess, Constants.PROJECT_REG_CF));
    }
}


// private methods
function dispatchError(response, dispatch, message) {
    if (response.status >= Constants.HTTP_ERR_BAD_REQUEST) {
        dispatch(endProjectRequest());
        const _message = response.data.error || message;
        NotificationService.error(_message, ERROR_MESSAGE_DURATION);
    }
}

function dispatchSuccess(response, dispatch, successMethod) {
    if (response.status === Constants.HTTP_SUCCESS) {
        dispatch(successMethod(response.data));
    }
}
