import initialState from '../../../../reducers/appInitialState';
import { updateRequestCounter } from '../../../../utils/General';
import * as Actions from '../actions/actions';

export default (state = initialState.investorEmailHistory, action) => {
    const { minus, plus } = updateRequestCounter(state, 'EMHOnFlightRequest');

    switch (action.type) {
    case Actions.EMH_END_REQUEST:
        return {
            ...state,
            EMHOnFlightRequest: minus,
        };
    case Actions.EMH_START_REQUEST:
        return {
            ...state,
            EMHOnFlightRequest: plus,
        };
    case Actions.EMH_GET_INVESTOR_EMAILS_SUCCESS:
        return {
            ...state,
            EMHOnFlightRequest: minus,
            emailHistoryList: action.data,
        };
    default:
        return { ...state };
    }
};
