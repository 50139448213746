import React, { Component } from 'react';
import swal from 'sweetalert2';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Stepper from 'react-stepper-horizontal';
import LocationSearchInput from './GoogleMapsPlaces';
import RequestService from './api/RequestService';
import store from './index';

function zeroPad(num, places) {
    const zero = places - num.toString().length + 1;

    return new Array(+(zero > 0 && zero)).join('0') + num;
}

class VerifyDwolla extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.session.id,
            isLoaded: false,
            errorOnLoad: false,
            showAddressFields: false,
            currentDwollaStatus: props.location.state
                ? props.location.state.currentDwollaStatus
                : '',
            EntityID: props.location.state ? props.location.state.EntityID : 0,
            steps: [
                { title: 'Verify Your Identity' },
                { title: 'Connect Funds' },
            ],
            currentStep: 0,
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.handleSelectedLocation = this.handleSelectedLocation.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        store.subscribe(() => {
            const currentState = store.getState();

            this.setState({
                id: currentState.session.user.id,
            });
        });

        this.getCurrentDwollaStatus();
        this.generateDateDropDowns();

        /* document.getElementById('phoneNumber').addEventListener('blur', function (e) {
			let x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
			e.target.value = !x[2] ? x[1] : x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '');
		});

		document.getElementById('phoneNumber').addEventListener('input', function (e) {
			let x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
			e.target.value = !x[2] ? x[1] : x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '');
		}); */
    }

    async getCurrentDwollaStatus() {
        let data;

        // If Financial Advisor, and setting a specific EntityID to verify:
        if (this.state.EntityID > 0) {
            ({ data } = await RequestService.get(
                `/api/getAnEntitysDwollaStatus/${this.state.EntityID}`,
            ));
        }
        // Not FA, and determining own Dwolla Status:
        else {
            ({ data } = await RequestService.get('/api/getDwollaStatus'));
        }

        if (data.code === 200) {
            if (data.data.dwollaStatus === 'retry') {
                this.setState({
                    currentDwollaStatus: data.data.dwollaStatus,
                });
            } else if (data.data.dwollaStatus === 'verified') {
                await swal('You are already verified.').then(
                    this.props.history.push('/app/UserNotifications'),
                );
            } else if (data.data.dwollaStatus === 'document') {
                await swal('You need to upload supporting documents.').then(
                    this.props.history.push('/app/Profile'),
                );
            } else if (data.data.dwollaStatus === 'suspended') {
                await swal(
                    'Unfortunately you could not be verified.',
                    'Please contact investor relations.',
                ).then(this.props.history.push('/app/UserNotifications'));
            }
        }
    }

    handleSelectedLocation(place) {
        // alert(JSON.stringify(place));

        this.setState({
            showAddressFields: true,
        });

        const componentForm = {
            // street_number: 'short_name',	// not used.
            locality: 'long_name',
            administrative_area_level_1: 'short_name',
            country: 'long_name',
            postal_code: 'short_name',
        };

        for (const component in componentForm) {
            document.getElementById(component).value = '';
        }

        document.getElementById('address1').value = '';
        document.getElementById('address2').value = '';

        // Get each component of the address from the place details
        // and fill the corresponding field on the form.
        for (let i = 0; i < place.address_components.length; i++) {
            const addressType = place.address_components[i].types[0];

            if (componentForm[addressType]) {
                document.getElementById(addressType).value =					place.address_components[i][componentForm[addressType]];
            }
        }

        document.getElementById(
            'address1',
        ).value = `${place.address_components[0].short_name} ${place.address_components[1].short_name}`;
    }

    generateDateDropDowns() {
        // Generate Years to the Year Dropdown.
        for (let y = 1920; y <= 2000; y++) {
            const optn = document.createElement('OPTION');

            optn.text = y;
            optn.value = y;

            /* // How to preselect a year.
			if (y == 1950) {
				optn.selected = true;
			}
			*/

            document.getElementById('year').options.add(optn);
        }

        // Generate Months to the Months Dropdown.
        const monthArray = [];

        monthArray[0] = 'January';
        monthArray[1] = 'February';
        monthArray[2] = 'March';
        monthArray[3] = 'April';
        monthArray[4] = 'May';
        monthArray[5] = 'June';
        monthArray[6] = 'July';
        monthArray[7] = 'August';
        monthArray[8] = 'September';
        monthArray[9] = 'October';
        monthArray[10] = 'November';
        monthArray[11] = 'December';
        for (let m = 0; m <= 11; m++) {
            const optn = document.createElement('OPTION');

            optn.text = monthArray[m];
            optn.value = m + 1; // Make months 1-12

            /* // How to preselect a month.
			if (m == 5) {
			    optn.selected = true;
			}
			*/

            document.getElementById('month').options.add(optn);
        }

        // Generate days to the days Dropdown.
        for (let d = 1; d <= 31; d++) {
            const optn = document.createElement('OPTION');

            optn.text = d;
            optn.value = d;

            document.getElementById('day').options.add(optn);
        }
    }

    async onSubmit() {
        if (this.refs.legalFirstName.value.trim() === '') {
            swal('Please enter your Legal First Name.', '', 'info');
        } else if (this.refs.legalLastName.value.trim() === '') {
            swal('Please enter your Legal Last Name.', '', 'info');
        } else if (this.refs.jobTitle.value.trim() === '') {
            /* else if (this.refs.phoneNumber.value.trim() === '') {
			swal("Please enter your Mobile Phone Number.", "", "info");
		} */
            swal('Please enter your current Job Title.', '', 'info');
        } else if (this.refs.address1.value === '') {
            swal('Please ensure your address is correct.', '', 'info');
        } else if (this.refs.addressCity.value === '') {
            swal('Please ensure your address is correct.', '', 'info');
        } else if (this.refs.addressState.value === '') {
            swal('Please ensure your address is correct.', '', 'info');
        } else if (this.refs.addressZip.value === '') {
            swal('Please ensure your address is correct.', '', 'info');
        } else if (
            this.refs.month.value === ''
			|| this.refs.year.value === ''
			|| this.refs.day.value === ''
        ) {
            swal('Please enter your Date Of Birth', '', 'info');
        } else if (this.refs.ssn.value.trim() === '') {
            swal(
                'Please enter the last four digits of your social security number',
                '',
                'info',
            );
        }
        // else if (!document.getElementById('tosCheck').checked) {
        // 	swal("You must view the terms of service and privacy policy, and agree by checking the box", "", "info");
        // }
        else {
            this.refs.submitButton.setAttribute('disabled', 'disabled');
            this.refs.submitButton.innerHTML = 'Please Wait ... ';

            // build form body
            const month = zeroPad(this.refs.month.value, 2);

            const day = zeroPad(this.refs.day.value, 2);

            const DOB = `${this.refs.year.value}-${month}-${day}`;

            let formBody = [];

            formBody.push(
                `legalFirstName=${
					 encodeURIComponent(this.refs.legalFirstName.value.trim())}`,
            );
            formBody.push(
                `legalLastName=${
					 encodeURIComponent(this.refs.legalLastName.value.trim())}`,
            );
            // formBody.push("phoneNumber=" + encodeURIComponent(this.refs.phoneNumber.value.trim()));
            formBody.push(
                `address1=${
					 encodeURIComponent(this.refs.address1.value.trim())}`,
            );
            formBody.push(
                `address2=${
					 encodeURIComponent(this.refs.address2.value.trim())}`,
            );
            formBody.push(
                `addressCity=${
					 encodeURIComponent(this.refs.addressCity.value.trim())}`,
            );
            formBody.push(
                `addressState=${
					 encodeURIComponent(this.refs.addressState.value.trim())}`,
            );
            formBody.push(
                `addressZip=${
					 encodeURIComponent(this.refs.addressZip.value.trim())}`,
            );
            formBody.push(
                `addressCountry=${
					 encodeURIComponent(this.refs.addressCountry.value.trim())}`,
            );
            formBody.push(
                `jobTitle=${
					 encodeURIComponent(this.refs.jobTitle.value.trim())}`,
            );
            formBody.push(`DOB=${encodeURIComponent(DOB)}`);
            formBody.push(`SSN=${encodeURIComponent(this.refs.ssn.value)}`);
            formBody.push(`EntityID=${this.state.EntityID}`);
            formBody = formBody.join('&');

            const { data: status } = await RequestService.post({
                url: '/api/verifyDwollaInvestor',
                data: formBody,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            });

            if (status.code === 200 && status.dwollaStatus === 'verified') {
                swal(
                    'Investor Account Verified Successfully',
                    '',
                    'success',
                ).then((value) => {
                    this.props.history.push({
                        pathname: '/app/setupprofile3',
                    });
                    window.location.reload();
                });
            } else if (status.code === 200 && status.dwollaStatus === 'retry') {
                swal(
                    'Investor Account Verification Failed',
                    'Please Enter All 9 Digits Of Your SSN To Retry',
                    'info',
                ).then((value) => {
                    this.refs.submitButton.removeAttribute('disabled');
                    this.refs.submitButton.innerHTML = 'Submit';
                    this.props.history.push('/app/VerifyDwolla');
                });
            } else if (
                status.code === 200
				&& status.dwollaStatus === 'document'
            ) {
                swal(
                    'Investor Account Verification Failed (Documents Required)',
                    'You will need to upload an image of one of the following:<br/>A Photo ID, or Drivers License, or Passport ID Page.',
                    'info',
                ).then(async (value) => {
                    this.props.history.push('/app/UploadDwollaDocument');
                });
            } else if (status.code === 400) {
                swal(
                    status.msg,
                    'Please contact Investor Relations',
                    'error',
                ).then((value) => {
                    if (status.msg === 'Address cannot be a PO box.') {
                        this.props.history.push('/app/Profile');
                    } else {
                        this.props.history.push('/app/UserNotifications');
                    }
                });
            } else {
                swal(
                    'Please contact Investor Relations',
                    status.msg,
                    'error',
                ).then((value) => {
                    this.props.history.push('/app/UserNotifications');
                });
            }
        }
    }

    render() {
        const { currentDwollaStatus } = this.state;
        const { steps, currentStep } = this.state;
        const imgIdentity = '/img/verify-identity.png';
        const pathname = window.location.pathname.toLocaleLowerCase();

        if (pathname.match('/verifydwolla')) {
            document.body.classList.add('videntity-vcenter');
        }
        return (

            <div className="verify-identity">
                <div
                    className="stepper-horizontal"
                    style={{
                        backgroundColor: '#092736',
                        paddingBottom: 20,
                    }}
                >
                    <Stepper
                        steps={steps}
                        activeStep={currentStep}
                        defaultColor="#fff"
                        activeColor="#5bb462"
                        circleFontColor="#000"
                        circleFontSize={14}
                        defaultTitleColor="#fff"
                        activeTitleColor="#5bb462"
                        completeColor="#5bb462"
                        completeTitleColor="#5bb462"
                        completeBarColor="#fff"
                    />
                </div>
                <div className="vi-container-fluid position-relative">
                    <div className="linear-gradient" />
                    <div className="container">
                        <div className="d-md-flex align-items-center">
                            <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5 d-flex flex-column align-items-center justify-content-center position-relative lt-sec">
                                <img src={imgIdentity} />
                                <h1>Verify Your Identity</h1>
                            </div>
                            <div className="col-xs-12 col-sm-12  col-md-7 col-lg-7   d-flex flex-column rt-sec">
                                {this.state.EntityID > 0 && (
                                    <div style={{ fontSize: '1.2em' }}>
                                        <p>
                                            Please fill this out with your
                                            client's information.
                                        </p>
                                    </div>
                                )}
                                {/* <div style={{ fontSize: '1.2em' }}>This information helps us satisfy "Know Your Customer" (KYC) requirements as defined by the SEC.</div>
								<br /> */}
                                <form>
                                    <div>
                                        <div className="form-group">
                                            {/* <label htmlFor="legalFirstName">Legal First Name *</label>
											<br/> */}
                                            <input
                                                id="legalFirstName"
                                                name="legalFirstName"
                                                className="verify-form-control form-control"
                                                ref="legalFirstName"
                                                type="text"
                                                placeholder="Legal First Name"
                                            />
                                            <span
                                                id="error_legalFirstName"
                                                className="text-danger"
                                            />
                                        </div>
                                        <div className="form-group">
                                            {/* <label htmlFor="legalLastName">Legal Last Name *</label>
											<br/> */}
                                            <input
                                                id="legalLastName"
                                                name="legalLastName"
                                                className="verify-form-control form-control"
                                                ref="legalLastName"
                                                type="text"
                                                placeholder="Legal Last Name"
                                            />
                                            <span
                                                id="error_legalLastName"
                                                className="text-danger"
                                            />
                                        </div>
                                        {/* <div className="form-group">
											<label htmlFor="phoneNumber">Mobile Number *</label>
											<br/>
											<input id="phoneNumber" name="phoneNumber" className="verify-form-control form-control" ref="phoneNumber" type="text" placeholder="phone number" />
											<span id="error_phoneNumber" className="text-danger" />
										</div> */}

                                        <div className="form-group">
                                            {/* <label htmlFor="jobTitle">Current Job Title *</label>
											<br/> */}
                                            <input
                                                id="jobTitle"
                                                name="jobTitle"
                                                className="verify-form-control form-control"
                                                ref="jobTitle"
                                                type="text"
                                                placeholder="Current Job Title"
                                            />
                                            <span
                                                id="error_jobTitle"
                                                className="text-danger"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <LocationSearchInput
                                                onSelectLocation={
                                                    this
                                                        .handleSelectedLocation
                                                }
                                            />
                                        </div>
                                        <div
                                            className="form-group"
                                            style={{
                                                display: this.state
                                                    .showAddressFields
                                                    ? ''
                                                    : 'none',
                                            }}
                                        >
                                            {/* <label htmlFor="address1">Primary Address Line 1 *</label>
											<br/> */}
                                            <input
                                                id="address1"
                                                name="address1"
                                                className="verify-form-control form-control"
                                                ref="address1"
                                                type="text"
                                                placeholder="Physical Address (No PO Boxes)"
                                            />
                                            <span
                                                id="error_address1"
                                                className="text-danger"
                                            />
                                        </div>
                                        <div
                                            className="form-group"
                                            style={{
                                                display: this.state
                                                    .showAddressFields
                                                    ? ''
                                                    : 'none',
                                            }}
                                        >
                                            {/* <label htmlFor="address2">Primary Address Line 2</label>
											<br/> */}
                                            <input
                                                id="address2"
                                                name="address2"
                                                className="verify-form-control form-control"
                                                ref="address2"
                                                type="text"
                                                placeholder="(Optional) Apt, Suite, etc."
                                            />
                                            <span
                                                id="error_address2"
                                                className="text-danger"
                                            />
                                        </div>
                                        <div
                                            className="form-group d-flex"
                                            style={{
                                                display: this.state
                                                    .showAddressFields
                                                    ? ''
                                                    : 'none',
                                            }}
                                        >
                                            <div
                                                className="col-md-6 col-sm-6"
                                                style={{
                                                    paddingLeft: 0,
                                                    paddingRight: 5,
                                                }}
                                            >
                                                {/* <label htmlFor="addressCity">City *</label>
												<br/> */}
                                                <input
                                                    id="locality"
                                                    name="addressCity"
                                                    className="verify-form-control form-control"
                                                    ref="addressCity"
                                                    type="text"
                                                    placeholder="City"
                                                />
                                                <span
                                                    id="error_addressCity"
                                                    className="text-danger"
                                                />
                                            </div>
                                            <div
                                                className="col-md-3 col-sm-3"
                                                style={{
                                                    paddingLeft: 5,
                                                    paddingRight: 5,
                                                }}
                                            >
                                                {/* <label htmlFor="addressState">State *</label>
												<br/> */}
                                                <input
                                                    id="administrative_area_level_1"
                                                    name="addressState"
                                                    className="verify-form-control form-control"
                                                    ref="addressState"
                                                    type="text"
                                                    placeholder="ST"
                                                    maxLength="2"
                                                />
                                                <span
                                                    id="error_addressState"
                                                    className="text-danger"
                                                />
                                            </div>
                                            <div
                                                className="col-md-3 col-sm-3"
                                                style={{
                                                    paddingLeft: 5,
                                                    paddingRight: 0,
                                                }}
                                            >
                                                {/* <label htmlFor="addressZip">Zip *</label>
												<br/> */}
                                                <input
                                                    id="postal_code"
                                                    name="addressZip"
                                                    className="verify-form-control form-control"
                                                    ref="addressZip"
                                                    type="text"
                                                    placeholder="Zip"
                                                    maxLength="5"
                                                />
                                                <span
                                                    id="error_address2"
                                                    className="text-danger"
                                                />
                                                <input
                                                    id="country"
                                                    name="addressCountry"
                                                    ref="addressCountry"
                                                    type="hidden"
                                                    value=""
                                                />
                                            </div>
                                        </div>
                                        <div style={{ clear: 'both' }}>
                                            {' '}
                                        </div>

                                        <div className="form-group d-flex">
                                            <label htmlFor="month">Date Of Birth *</label>
                                            <br />
                                            <div
                                                className="col-md-6 col-sm-6"
                                                style={{
                                                    paddingLeft: 0,
                                                    paddingRight: 5,
                                                }}
                                            >
                                                <select
                                                    ref="month"
                                                    name="month"
                                                    id="month"
                                                    className="verify-form-control form-control"
                                                >
                                                    <option value="">
                                                        Month
                                                    </option>
                                                </select>
                                            </div>
                                            <div
                                                className="col-md-3 col-sm-3"
                                                style={{
                                                    paddingLeft: 5,
                                                    paddingRight: 5,
                                                }}
                                            >
                                                <select
                                                    ref="day"
                                                    name="day"
                                                    id="day"
                                                    className="verify-form-control form-control"
                                                >
                                                    <option value="">
                                                        Day
                                                    </option>
                                                </select>
                                            </div>
                                            <div
                                                className="col-md-3 col-sm-3"
                                                style={{
                                                    paddingLeft: 5,
                                                    paddingRight: 0,
                                                }}
                                            >
                                                <select
                                                    ref="year"
                                                    name="year"
                                                    id="year"
                                                    className="verify-form-control form-control"
                                                >
                                                    <option value="">
                                                        Year
                                                    </option>
                                                </select>
                                                <span
                                                    id="error_totalpp"
                                                    className="text-danger"
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            {/* <label htmlFor="totalpp">{currentDwollaStatus === 'retry' ? 'Full Social Security Number *' : 'Last four digits of Social Security Number *'}</label>
											<br/> */}
                                            <input
                                                id="totalpp"
                                                name="totalpp"
                                                className="verify-form-control form-control"
                                                ref="ssn"
                                                type="text"
                                                placeholder="Last four digits of Social Security Number"
                                            />
                                            <span
                                                id="error_totalpp"
                                                className="text-danger"
                                            />
                                        </div>
                                        {/* <div className="check_tc">
											<label className="custom-container">I certify under penalty of perjury that: *<br/>(1) I am a US Citizen or other US Person.<br/>(2) I agree to Dwolla's <a href="https://www.dwolla.com/legal/tos/" target="_blank" rel="noopener noreferrer">Terms of Service</a> and <a href="https://www.dwolla.com/legal/privacy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>. <span className="red">*</span>
												<input type="checkbox" required ref="terms" name="terms" id="tosCheck" />
												<span className="checkmark" />
											</label>
										</div> */}
                                    </div>
                                </form>
                                <div className="form-group">
                                    <div className="upload-btn-wrapper">
                                        <button
                                            type="Submit"
                                            id="submitButton"
                                            ref="submitButton"
                                            onClick={this.onSubmit}
                                            className="btn green-bg semi-bold text-white submitfiles"
                                            style={{ height: 50 }}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <button
                                        onClick={() => {
                                            this.props.history.goBack();
                                        }}
                                        type="button"
                                        className="btn pl-0 box-shadow-none"
                                        style={{
                                            height: 50,
                                            width: 'auto',
                                            backgroundColor: '#fff',
                                            color: '#182F3B9A',
                                            margin: 0,
                                        }}
                                    >
                                        Back
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

const { object, bool } = PropTypes;

VerifyDwolla.propTypes = {
    session: object.isRequired,
    authenticated: bool.isRequired,
    checked: bool.isRequired,
};

const mapState = ({ session }) => ({
    session: session.user,
    checked: session.checked,
    authenticated: session.authenticated,
});

export default withRouter(connect(mapState)(VerifyDwolla));
