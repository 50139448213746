import React, { Component } from 'react';
import RequestService from '../../../api/RequestService';


class Totalcollections extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [],
            final: 0
        };
    }


    async componentDidMount()
    {
        try {
            const { data } = await RequestService.get('/api/totalfunds');
            this.setState({
                users: Math.round(data.rows),
            });
        } catch (err) {
            console.log(err);
        }
    }


    render() {
        return(
            <div className="col-md-2 col-sm-4 col-xs-12 tile_stats_count">
                <span className="count_top"><i className="fa fa-user"></i>Total Funds Raised</span>
                <div className="count">${this.state.users}</div>
            </div>
        );
    }
}


export default Totalcollections;
