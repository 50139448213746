import PropTypes from 'prop-types'
import React from "react";

const propTypes = {
    projectName: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
};

const defaultProps = {
    projectName: "",
    subject: "",
};

const ViewerSubjectSectionComponent = (props) => {
    const {projectName, subject} = props;

    return (
        <tr>
            <td colSpan={2}>
                <div style={{
                    padding: "30px 50px 15px",
                    backgroundColor: "#5bb462",
                    marginRight: 0,
                    marginLeft: 0,
                    color: "#ffffff",
                    fontFamily: "sans-serif",
                    fontSize: "30px",
                    fontWeight: "bold",
                    marginBottom: 0,
                    marginTop: 0,
                    lineHeight: 1.1,
                    boxSizing: "border-box",
                    width: "100%",
                }}>
                    <h2>{projectName}</h2>
                    {subject && subject.length > 0 &&
                    <h4><span className="bold-text">Subject:</span> {subject}</h4>}
                </div>
            </td>
        </tr>
    );
};

ViewerSubjectSectionComponent.propTypes = propTypes;
ViewerSubjectSectionComponent.defaultProps = defaultProps;

export default ViewerSubjectSectionComponent;

