import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import moment from 'moment';

import {formatMoney, getStatusFromCode, getUUID, ROI} from '../../../utils/General';
import Icon from "../IconComponent/IconComponent";


class InvestmentComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activePage: 1,
            itemsPerPage: props.itemsPerPage,
            totalItems: 0,
            selectedItemsList: [],
           
        };

        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleChangeItemsPerPage = this.handleChangeItemsPerPage.bind(this);
    }

componentDidMount(){

	
	const { itemsPerPage } = this.state;
	const { investmentList } = this.props;
	let page =1;
	const prev = ((page) - 1) * itemsPerPage;
	const next = prev + itemsPerPage;
this.setState({
	
		activePage: page,
	selectedItemsList: investmentList.slice(prev, next),
	totalItems: investmentList.length
	})
	
}

    componentDidUpdate(prevProps) {

        const { investmentList } = this.props;
        const { activePage } = this.state;
 const _investmentList = JSON.stringify(prevProps.investmentList);
        const strInvestmentList = JSON.stringify(investmentList);

        if (_investmentList !== strInvestmentList) {
            this.setState({ totalItems: investmentList.length }, () => this.handlePageChange(activePage, false));
        }
		//this.handlePageChange(1,false);
    }



    handlePageChange(page, scrollUp) {
        const { itemsPerPage } = this.state;
        const { investmentList } = this.props;

        const prev = ((page) - 1) * itemsPerPage;
        const next = prev + itemsPerPage;
 this.setState({
            activePage: page,
            selectedItemsList: investmentList.slice(prev, next),
        });

        if (scrollUp) {
	//	document.getElementById('InvestmentsComponent').scrollIntoView();
		}
    };



    handleChangeItemsPerPage() {
		this.setState({
			itemsPerPage: Number(this.refs.selectItemsPerPage.value),	// Must be typecast.
			activePage: 1
		}, () => this.handlePageChange(1, true));
	}



	CustomPopover(totalInvestmentRegD, TotalReturnAmountRegD) {
		return (
			<div
				style={{
					position: 'absolute',
					backgroundColor: '#333333',
					color: '#ffffff',
					boxShadow: '0 5px 10px rgba(0, 0, 0, 0.2)',
					border: '1px solid #CCC',
					borderRadius: 3,
					padding: 10,
					zIndex: 99999
				}}
			>
				{`$${formatMoney(totalInvestmentRegD)} (Original Investment) - $${formatMoney(TotalReturnAmountRegD)} (Returned Capital)`}
			</div>
		);
	}



    render() {
        const { totalInvestmentRegD, totalInvestmentRegCF, TotalReturnAmountRegD, role } = this.props;
        const { activePage, itemsPerPage, totalItems, selectedItemsList } = this.state;

        return (
            <div
                panelTitle={<h3 className="heading-text" id="InvestmentsComponent">My Investments</h3>}
                isExpanded
            >
                <div className="col-xs-12 p-0">
                
					
                </div>
                <div className="col-xs-12 p-0">
                    <fieldset>
                        <div className="table-responsive">
                            <table className="table table-striped jambo_table">
                                <thead>
                                    <tr className="no-text-wrap">
                                        {(() => {
                                            if (role === 'Admin') {
                                                return (<th>Investment ID</th>);
                                            }
                                        })()}
                                        {/* <th>STATUS</th> */}
                                        <th>PROJECT</th>
                                        <th>INVESTED</th>
                                        <th>EARNINGS</th>
                                        <th>DURATION</th>
                                        {/* <th>ROI</th> */}
                                     {(() => {
											if (role === 'Admin') {
												return (<th>Notes</th>);
											}
										})()}
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedItemsList.map((i) => {
										let InvAmt = i.InvestmentAmount * 100 / 100;
                                      
                                        let EarAmt =i.EarningAmount;
										let RetText = '';
										let RetText2 = '';
                                       let ROIAmount =ROI(InvAmt,EarAmt) 

										if (i.ReturnAmount !== '0.00') {
											InvAmt = (i.InvestmentAmount - i.ReturnAmount);

											RetText = <span style={{fontStyle: 'italic', fontWeight: 'normal', display: 'inherit'}}>
																		There were one or more Returns of Capital issued.
																	  </span>;

											RetText2 =
												<OverlayTrigger
													placement="top"
													overlay={
														<Tooltip id={String(i.UserInvestmentID)}>
															<div style={{
																padding: 3,
																fontSize: '1.0em'
															}}>{`$${formatMoney(i.InvestmentAmount)} (Original Investment) - $${formatMoney(i.ReturnAmount)} (Returned Capital)`}</div>
														</Tooltip>}>
													<a><Icon iconName="info-circle" extraClasses="card-info" /></a>
												</OverlayTrigger>;
										}

										return (
											<tr
												key={getUUID()}
												style={{textDecoration: i.Deleted === 1 ? 'line-through' : ''}}
											>
												{(() => {
													if (role === 'Admin') {
														return (<td>{i.UserInvestmentID}</td>);
													}
												})()}
											
                                            {/* <td>{getStatusFromCode(i.ProjectStatus)}</td>  */}
												
												<td>{i.ProjectID_RegD === 0 ? i.InvestmentName : i.ProjectName}{RetText}</td>
											
												<td>${formatMoney(InvAmt)}<br/>{RetText2}</td>
                                               <td>{EarAmt ?  "$" +formatMoney(EarAmt) : '$ 0.00'}</td>
                                                <td>{i.Payback && i.Payback + ' month'}</td>
                                               {/* <td>{ROIAmount ? formatMoney(ROIAmount) + "%" : '0%'} <br/></td> */}
												
												{(() => {
													if (role === 'Admin') {
														return (<td>{i.PaymentStatusNotes}</td>);
													}
												})()}
											</tr>
										)
									})}
                                </tbody>
                            </table>
                        </div>
                    </fieldset>
                </div>

                <div className="col-xs-12">
					<div className="pull-right">
						<Pagination
							firstPageText={<i className="glyphicon glyphicon-chevron-left" />}
							lastPageText={<i className="glyphicon glyphicon-chevron-right" />}
							prevPageText={<i className="glyphicon glyphicon-menu-left" />}
							nextPageText={<i className="glyphicon glyphicon-menu-right" />}
							activePage={activePage}
							itemsCountPerPage={itemsPerPage}
							totalItemsCount={totalItems}
							onChange={this.handlePageChange}
						/>
						<div className="pull-left" style={{margin: '0 10px 0 0'}}>
							<select ref="selectItemsPerPage" style={{margin: '20px 0', padding: '0 10px', height: 32}} onChange={this.handleChangeItemsPerPage}>
								<option>5</option>
								<option>10</option>
								<option>20</option>
								<option>50</option>
							</select>
						</div>
					</div>
                </div>
            </div>
        );
    };
}


InvestmentComponent.propTypes = {
    investmentList: PropTypes.array.isRequired,
    totalInvestmentRegD: PropTypes.number.isRequired,
    totalInvestmentRegCF: PropTypes.number.isRequired,
	TotalReturnAmountRegD: PropTypes.number.isRequired,
    itemsPerPage: PropTypes.number.isRequired,
};

InvestmentComponent.defaultProps = {
    investmentList: [],
    totalInvestmentRegD: 0,
    totalInvestmentRegCF: 0,
	TotalReturnAmountRegD: 0,
    itemsPerPage: 5,
};

export default InvestmentComponent;
