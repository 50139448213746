import React, { Component } from 'react';
import RequestService from '../../../api/RequestService';


class Totalinvestors extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: null
        };
    }


    async componentDidMount() {
        try {
            const { data } = await RequestService.get('/api/totalinvestors');

            this.setState({
                users: data.data
            });
        } catch (err) {
            console.log(err);
        }
    }


    render() {
        return(
            <div className="col-md-2 col-sm-4 col-xs-12 tile_stats_count">
                <span className="count_top"><i className="fa fa-user"></i> Total Investors</span>
                <div className="count green">{this.state.users}</div>
            </div>
        );
    }
}

export default Totalinvestors;
