import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import OverviewOfferingsHeader from "./components/OfferingsTitle/OverviewOfferingsTitle";
import TransactionOfferingsHeader from "./components/OfferingsTitle/TransactionOfferingsTitle";
import { bindActionCreators } from "redux";
import * as AllInvestmentActions from "./actions/actions";
import RequestService from "../../../api/RequestService";
import { formatMoney, getStatusFromCode, ROI,getImpersonateID } from "../../../utils/General";
import store from "../../../index";
import {
	PROJECT_REG_CF,
	PROJECT_REG_D,
	PROJECT_REG_ALL,ALL_PROJECTS
} from "../../../utils/ApplicationConstants";
import InvestmentDescription from "../../investor/InvestorDashboardContainerComponent/InvestmentDescription";
import InvestmentChart from "../../investor/InvestorDashboardContainerComponent/InvestmentChart";
import InvestorEarningsChart from "../../investor/InvestorDashboardContainerComponent/InvestorEarningsChart";
import InvestmentComponent from "../../shared/investmentsComponent/InvestmentComponent";
import InvestmentsTableComponent from "../InvestmentsTableComponent/InvestmentsTableComponent";
class InvestorTransactionsContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			ROI: 0,
		
			activeOfferingsChecked: true,
			pastOfferingsChecked: true,
			selectedProjectType: PROJECT_REG_D,
			selectedProjectCategory: "all",
			id: props.user.id,
			users: [],
			totalProjects: [],
			allProjects_cf: [],
			pageNumber: 1,
			totalCount: 0,
			perPage: 10,
			activePage: 1,
			allProjectsD: [],
			usersD: [],
			pageNumberD: 1,
			totalCountD: 0,
			perPageD: 10,
			activePageD: 1,
			docsD: [],
			docsCF: [],
			tia_d: 0,
			tia_cf: 0,
			troc_d: 0,
			troc_cf: 0,
			modalTitle: "",
			docDataForModal: [],
			showDocsModal: false,
			showDocFrameModal: false,
			docFrameURL: "",
			modalDocTitle: "",
			selectedProject: ALL_PROJECTS,
			totalProjects: [],
			investorInvestmentsList:[]
		};
	}
	componentDidMount() {
		store.subscribe(() => {
			let currentState = store.getState();
			this.setState({
				id: currentState.session.user.id,
			});
		});
		//  console.log('My investments', this.getMyInvestments());
		this.getMyInvestments();
		const { getAllInvestments, getAllProjectCategories } = this.props;
		getAllInvestments(this.state.selectedProjectCategory);
		getAllProjectCategories();
	const { investorId, getAllUserInvestments,investorInvestmentsList } = this.props;
		getAllUserInvestments(investorId);
		this.allProjectsList();
		this.setState({

			investorInvestmentsList:investorInvestmentsList
			})
	}
	async getMyInvestments() {
		const impersonateID = await getImpersonateID();
		const { data } = await RequestService.get(`/api/myInvestments/${impersonateID}`);
		if (data.code === 200) {
			this.setState({
				users: data.reg_cf.slice(0, 10),
				allProjects_cf: data.reg_cf,
				docsCF: data.docsCF,
				tia_d: data.tia_d,
				tia_cf: data.tia_cf,
				troc_d: data.troc_d,
				troc_cf: data.troc_cf,
				totalCount: data.reg_cf.length,
				allProjectsD: data.reg_d,
				usersD: data.reg_d.slice(0, 10),
				docsD: data.docsD,
				pageNumberD: 1,
				totalCountD: data.reg_d.length,
				activePageD: 1,
			});
		}
		
	}

	allProjectsList = () => {
		const { investorInvestmentsList } = this.props;
		let totalProjects = investorInvestmentsList.map((item) => {
			return { value: item.ProjectID_RegD, label: item.ProjectName };
		});
		const uniqueTotalProjects = [
			...new Map(
				totalProjects?.map((item) => [item.value, item])
			).values(),
		];
	this.setState({
			...this.state,
			totalProjects: uniqueTotalProjects,
		});
	};
	selectedProject = (ID) => {
    
        const {investorInvestmentsList} = this.props;
  this.setState({	...this.state, investorInvestmentsList: investorInvestmentsList?.filter(item => item?.ProjectID_RegD === ID ) });
 
 };



	onProjectTypeChanged = (project) => {

		this.selectedProject(project?.value);
		this.setState({ selectedProject: project });

	};
	incrementViewCount = (projectId, projectType) => {
		const { incrementViewCount, user } = this.props;
		incrementViewCount(user, projectType, projectId);
	};


	onProjectCategorySelected = (projectCategoryId) => {
		const { getAllInvestments } = this.props;
		this.setState({ selectedProjectCategory: projectCategoryId });
		getAllInvestments(projectCategoryId);
	};

	renderRegDProjects(activeOffering, pastOfferings) {
		const {
			activeRegDInvestments,
			pastRegDInvestments,
			isAccreditedUser,
			wasAccreditedUser,
			showPastInvestments,
			loadingComplete,
		} = this.props;
		const {
			investmentLabels,
			investmentAmounts,
			cumulativeEarnings,investorInvestmentsList
		} = this.props;
		if (
			this.state.selectedProjectType === PROJECT_REG_D &&
			(isAccreditedUser || wasAccreditedUser)
		) {
			return (
                <InvestmentsTableComponent
                investorInvestmentsList={this.state.investorInvestmentsList}
                itemsPerPage={this.props.itemsPerPage}
                role="Investor"
            />	
			);
		}
		return null;
	}

	render() {
		const { pastOfferingsChecked, activeOfferingsChecked } = this.state;
		const { isAccreditedUser, wasAccreditedUser } = this.props;
		return (
			<div className="container filter-wrap">
				<div className="pull-right">
					<TransactionOfferingsHeader
						totalProjects={this.state.totalProjects}
						selectedProject={this.state.selectedProject}
						onProjectTypeClickHandler={this.onProjectTypeChanged}
						activeOfferingsChecked={activeOfferingsChecked}
						pastOfferingsChecked={pastOfferingsChecked}
						isAccreditedUser={isAccreditedUser}
						wasAccreditedUser={wasAccreditedUser}
						selectedProjectType={this.state.selectedProjectType}
						projectCategoryList={this.props.projectCategories}
						projectCategoryOnClickHandler={
							this.onProjectCategorySelected
						}
						selectedProjectCategory={
							this.state.selectedProjectCategory
						}
					/>
				</div>
				{this.renderRegDProjects(
					activeOfferingsChecked,
					pastOfferingsChecked
				)}
				</div>
		);
	}
}
InvestorTransactionsContainer.propTypes = {
	activeRegCfInvestments: PropTypes.array.isRequired,
	activeRegDInvestments: PropTypes.array.isRequired,
	addToWatchList: PropTypes.func.isRequired,
	getAllInvestments: PropTypes.func.isRequired,
	getAllProjectCategories: PropTypes.func.isRequired,
	history: PropTypes.any,
	incrementViewCount: PropTypes.func.isRequired,
	isAccreditedUser: PropTypes.bool.isRequired,
	pastRegCfInvestments: PropTypes.array.isRequired,
	pastRegDInvestments: PropTypes.array.isRequired,
	projectCategories: PropTypes.array.isRequired,
	showPastInvestments: PropTypes.bool.isRequired,
	user: PropTypes.any,
	investorId: PropTypes.number.isRequired,
	investorInvestments: PropTypes.array.isRequired,
	totalInvestmentRegCF: PropTypes.any.isRequired,
	totalInvestmentRegD: PropTypes.any.isRequired,
	totalPaymentsRegCF: PropTypes.any.isRequired,
	totalPaymentsRegD: PropTypes.any.isRequired,
	getAllUserInvestments: PropTypes.func.isRequired,
};
InvestorTransactionsContainer.defaultProps = {
	showPastInvestments: true,
	getAllUserInvestments: () => null,
};
const mapDispatchToProps = (dispatch) => {
	const allInvestmentActions = bindActionCreators(
		AllInvestmentActions,
		dispatch
	);
	return { ...allInvestmentActions };
};
const mapStateToProps = (_state) => {
	return {
		..._state.investorDashboard,
		investorId: _state.session.user.id,
		..._state.allInvestments,
		user: _state.session.user,
		location: _state.location,
		projectCategories: _state.allInvestments.projectCategories,
	};
};
const connectedComponent = connect(
	mapStateToProps,
	mapDispatchToProps
)(InvestorTransactionsContainer);
export default withRouter(connectedComponent);
