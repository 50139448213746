import React from 'react';
import PropTypes from 'prop-types';
import CollapsiblePanelComponent from '../CollapsiblePanelComponent/CollapsiblePanelComponent';

import Icon from '../IconComponent/IconComponent';
import SelectComponent from '../InputComponents/SelectComponent/SelectComponent';
import IntInputComponent from '../InputComponents/IntInputComponent/IntInputComponent';
import HighlightComponent from '../ProjectDetailsContainerComponent/components/HiglightComponent/HighlightComponent';
import ProjectCardComponent from '../ProjectCardComponent/Card';
import CheckboxInputComponent from '../InputComponents/CheckboxInputComponent/CheckboxInputComponent';
import SingleLineInputComponent from '../InputComponents/SingleLineInputComponent/SingleLineInputComponent';
import RichTextEditorComponent from '../InputComponents/ReachTextEditorComponent/RichTextEditorComponent';
import MultilineInputComponent from '../InputComponents/MultilineInputComponent/MultilineInputComponent';
import DateInputComponent from '../InputComponents/DateInputComponent/DateInputComponent';

import ProjectDetailTabsWrapper
    from '../ProjectDetailsContainerComponent/components/ProjectDetailTabsComponent/ProjectDetailTabsWrapper';
import TabComponent
    from '../ProjectDetailsContainerComponent/components/ProjectDetailTabsComponent/TabComponent/TabComponent';
import {
    PROJECT_UPDATE_CREATE,
    PROJECT_UPDATE_EDIT,
    USER_INVESTOR,
    REG_D_REGULATION_TYPES, PROJECT_REG_CF, PROJECT_REG_D_PROJECT_TYPES, PROJECT_REG_D_CASHCALL_TYPES,
	GP_LP_FORCED_TYPES
} from '../../../utils/ApplicationConstants';
import AddHighlightComponent
    from '../ProjectDetailsContainerComponent/components/AddHighlightComponent/AddHighlightComponent';
import AddTabComponent from '../ProjectDetailsContainerComponent/components/AddTabComponent/AddTabComponent';
import { getHash, getUUID, isValidUrl } from '../../../utils/General';

import './ProjectDetailsComponent.styles.css';
import FilesTabComponent from '../ProjectDetailsContainerComponent/components/FilesTabComponent/FilesTabComponent';
import UpdatesTabComponent
    from '../ProjectDetailsContainerComponent/components/UpdatesTabComponent/UpdatesTabComponent';
import ProjectUpdateViewer from '../projectUpdateViewerComponent/ProjectUpdateViewer';
import ExternalImageContainer from '../ExternalImageContainerComponent/ExternalImageContainer';
import HideComponent from '../HideComponent/HideComponent';
import CommentsTabComponent from '../ProjectDetailsContainerComponent/components/CommentsTabComponent/CommentsTabComponent';


class ProjectDetailsComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            // Highlights
            isHighlightModalOpen: false,
            highlightModalAction: '',
            selectedHighlight: {},

            // Tabs
            tabModalAction: '',
            selectedTabTitle: '',
            isTabsModalOpen: false,
            selectedTab: [],

            tabsDefaultIndex: 0,
            openExternalImageContainer: false,
        };
    }


    setSelectedTabTitle = (tabTitle) => {
        this.setState({ selectedTabTitle: tabTitle });
    };


    toggleExternalImageContainer = () => {
        this.setState({ openExternalImageContainer: !this.state.openExternalImageContainer });
    };


    isRegCfProject = () => {
        const { projectType } = this.props;

        return projectType === PROJECT_REG_CF;
    };


    // HIGHLIGHT MANAGEMENT
    onUpdateHighlight = (selectedHighlight) => {
        this.setState({ selectedHighlight }, () => {
            this.props.onSelectedHighlight(selectedHighlight);
            this.toggleHighlightModal(PROJECT_UPDATE_EDIT);
        });
    };


    toggleHighlightModal = (highlightModalAction) => {
        let { isHighlightModalOpen, selectedHighlight } = this.state;

        isHighlightModalOpen = !isHighlightModalOpen;

        if (!isHighlightModalOpen) {
            selectedHighlight = {};
        }

        this.setState({
            isHighlightModalOpen,
            highlightModalAction,
            selectedHighlight,
        });
    };


    renderHighlight = () => {
        const { projectHighlights } = this.state;

        return projectHighlights
            .sort((h1, h2) => h1.highlightOrder - h2.highlightOrder)
            .map(highlight => (
                <HighlightComponent
                    key={highlight.highlightId}
                    onDeleteHighlight={this.props.onDeleteHighlight}
                    onUpdateHighlight={this.onUpdateHighlight}
                    highlight={highlight}
                    showActions
                />
            ));
    };


    // TAB MANAGER
    toggleTabsModal = (tabModalAction) => {
        let { isTabsModalOpen, selectedTab } = this.state;

        isTabsModalOpen = !isTabsModalOpen;

        if (!isTabsModalOpen) {
            selectedTab = {};
        }

        this.setState({
            tabModalAction,
            isTabsModalOpen,
            selectedTab,
        });
    };


    onUpdateTab = (selectedTab) => {
        this.setState({ selectedTab }, () => {
            this.props.onSelectedTab(selectedTab);
            this.toggleTabsModal(PROJECT_UPDATE_EDIT);
        });
    };


    setTabsDefaultIndex = (tabsDefaultIndex) => {
        this.setState({ tabsDefaultIndex });
    };


    onGetCommentReplies = (commentId, position) => {
        const { regCfCommentList, getCommentReplies } = this.props;

        getCommentReplies(commentId, regCfCommentList, position);
    };


    // RENDER MANAGER
    renderProjectTabs = () => {
        const {
            projectTabs, projectFiles, projectNotifications, isEditMode,
            showImportFilesButton, onTabInputFilesChange, onDeleteProjectFile,
            onSaveFileReorder, regCfCommentList, onSaveNewComment, isLoggedInUser,
            onRefreshCommentList,
        } = this.props;

        if (!projectTabs) return null;

        const tabChildren = projectTabs.map(tab => (
            <TabComponent
                key={tab.tabId}
                showActions={isEditMode}
                onUpdateTab={this.onUpdateTab}
                onDeleteTab={this.props.onDeleteTab}
                tab={tab}
                {...tab}
            />
        ));

        if ((projectFiles && projectFiles.length) || isEditMode) {
            const file = (
                <FilesTabComponent
                    key={getUUID()}
                    filesList={projectFiles}
                    isEditMode={isEditMode}
                    tabContent={this.getProjectFilesContent(projectFiles)}
                    onDeleteFile={onDeleteProjectFile}
                    showImportFiles={showImportFilesButton}
                    onTabInputFilesChange={onTabInputFilesChange}
                    onSaveFileReorder={onSaveFileReorder}
                />
            );

            tabChildren.push(file);
        }

        if (projectNotifications && projectNotifications.length) {
            const updates = (
                <UpdatesTabComponent
                    updateList={projectNotifications}
                    onOpenUpdate={this.props.getUpdateById}
                />
            );

            tabChildren.push(updates);
        }

        if (this.isRegCfProject()) {
            const commentsComponent = (
                <CommentsTabComponent
                    tabContent={getHash(regCfCommentList)}
                    regCfCommentList={regCfCommentList}
                    getCommentReplies={this.onGetCommentReplies}
                    onSaveNewComment={onSaveNewComment}
                    isLoggedInUser={isLoggedInUser}
                    onRefreshCommentList={onRefreshCommentList}
                />
            );

            tabChildren.push(commentsComponent);
        }

        return (
            <ProjectDetailTabsWrapper
                defaultTabIndex={this.state.tabsDefaultIndex}
                setDefaultIndex={this.setTabsDefaultIndex}
                setSelectedTabTitle={this.setSelectedTabTitle}
            >
                {tabChildren}
            </ProjectDetailTabsWrapper>
        );
    };


    getProjectFilesContent = projectFiles => projectFiles.map(item => item.documentId)
        .map(item => item.toString())
        .join('');


    renderHighlights = () => {
        const { projectHighlights } = this.props;

        return projectHighlights.map(h => (
            <HighlightComponent
                highlight={h}
                key={h.highlightId}
                showActions={this.props.isEditMode}
                onUpdateHighlight={this.onUpdateHighlight}
                onDeleteHighlight={this.props.onDeleteHighlight}
            />
        ));
    };


    renderAddNewTabButton = () => {
        const { selectedTabTitle } = this.state;
        const { isEditMode, projectTabs } = this.props;

        const showAddNewTabButton = (projectTabs.length < 8);

        return (
            <div
                className="container margin-top-30px"
                id="projectDetailsHighlights"
            >
                <br />
                <h3 className="heading-text pull-left capitalize-text">{selectedTabTitle}</h3>

                {showAddNewTabButton && isEditMode && (
                    <button
                        type="button"
                        onClick={() => this.toggleTabsModal(PROJECT_UPDATE_CREATE)}
                        className="btn btn-primary btn-sm pull-right"
                    >
                        <Icon iconName="plus" extraClasses="white-text" />
                        {' '}
                        Add new tab
                    </button>
                )}
            </div>
        );
    };


    renderAddNewHighlightButton = () => {
        const { isEditMode, projectHighlights } = this.props;

        if (!isEditMode || (projectHighlights.length >= 4)) return null;

        return (
            <button
                type="button"
                onClick={() => this.toggleHighlightModal(PROJECT_UPDATE_CREATE)}
                className="btn btn-primary btn-sm pull-right"
            >
                <Icon iconName="plus" extraClasses="white-text" />
                {' '}
                Add Highlight
            </button>
        );
    };


    renderProjectDetailsPanel = () => {
        const isRegCF = this.isRegCfProject();

        const { isEditMode, projectDetails, cashCallParentProject, onInputChange } = this.props;

        const {
            publishDate, selectedParent, investmentStartDate,
            entity, contactInfo, percentOfOriginalInvestment, dwollaAccountUrl, regulationType,
            socialVideoURL, projectType, isCashCall, CashCallVersion, CashCallOpenToAll, CashCallDateLPEnd,
			GP_LP_Forced, BTCAddress, ETHAddress
		} = projectDetails;

        if (!isEditMode) return null;

        return (
            <div className="container margin-top-30px">
                <CollapsiblePanelComponent
                    panelTitle={<h3 className="heading-text">Project Details</h3>}
                    isExpanded
                >
                    <HideComponent hide={!isRegCF}>
                        <div className="row">
                            <div className="col-xs-12">
                                <SingleLineInputComponent
                                    inputName="socialVideoURL"
                                    inputTitle="Video URL"
                                    elementValue={socialVideoURL}
                                    onInputUpdate={onInputChange}
                                    maxInputLength={255}
                                />
                            </div>
                        </div>
                    </HideComponent>

                    <div className="row">
                        <HideComponent hide={isRegCF}>
                            <SelectComponent
                                md={3}
                                sm={6}
                                xs={12}
                                name="regulationType"
                                onChange={this.props.onInputChange}
                                options={REG_D_REGULATION_TYPES}
                                selectTitle="Regulation Type"
                                value={regulationType}
                            />

                            <SelectComponent
                                md={3}
                                sm={6}
                                xs={12}
                                name="projectType"
                                onChange={this.props.onInputChange}
                                options={PROJECT_REG_D_PROJECT_TYPES}
                                selectTitle="Project Type"
                                value={projectType}
                            />

							<SelectComponent
								md={3}
								sm={6}
								xs={12}
								name="GP_LP_Forced"
								onChange={this.props.onInputChange}
								options={GP_LP_FORCED_TYPES}
								selectTitle="GP/LP Forced"
								disableNone={true}
								value={GP_LP_Forced}
							/>
                        </HideComponent>

                        <div className="col-xs-12">
                            <SingleLineInputComponent
                                elementValue={dwollaAccountUrl}
                                inputName="dwollaAccountUrl"
                                inputTitle="Dwolla Account Url"
                                maxInputLength={200}
                                onInputUpdate={this.props.onInputChange}
                            />
                        </div>
						<div className="col-xs-6">
							<SingleLineInputComponent
								elementValue={BTCAddress}
								inputName="BTCAddress"
								inputTitle="BTC Address"
								maxInputLength={64}
								onInputUpdate={this.props.onInputChange}
							/>
						</div><div className="col-xs-6">
						<SingleLineInputComponent
							elementValue={ETHAddress}
							inputName="ETHAddress"
							inputTitle="ETH Address"
							maxInputLength={64}
							onInputUpdate={this.props.onInputChange}
						/>
					</div>
                    </div>

                    <div className="row">
                        <div className={`col-xs-12 col-sm-${isRegCF ? '6' : '4'}`}>
                            <DateInputComponent
                                inputName="publishDate"
                                inputTitle="Publish Date"
                                onInputChange={this.props.onInputChange}
                                elementValue={publishDate}
                            />
                        </div>
                        <div className={`col-xs-12 col-sm-${isRegCF ? '6' : '4'}`}>
                            <DateInputComponent
                                inputName="investmentStartDate"
                                inputTitle="investment start date"
                                onInputChange={this.props.onInputChange}
                                elementValue={investmentStartDate}
                            />
                        </div>
                        <HideComponent hide={isRegCF}>
                            <div className="col-xs-12 col-sm-4">
                                <SingleLineInputComponent
                                    inputName="entity"
                                    inputTitle="Entity"
                                    maxInputLength={40}
                                    onInputUpdate={this.props.onInputChange}
                                    elementValue={entity}
                                    rows={3}
                                />
                            </div>
                        </HideComponent>
                    </div>

                    <div className="row">
                        <div className="col-xs-12">
                            <MultilineInputComponent
                                inputName="contactInfo"
                                inputTitle="Contact info"
                                elementValue={contactInfo}
                                onInputUpdate={this.props.onInputChange}
                                maxInputLength={255}
                                rows={3}
                            />
                        </div>
                    </div>

					<HideComponent hide={isRegCF}>
						<div className="row">
							<div className="col-xs-12 col-sm-2 col-md-3 col-lg-2 checkbox-elements">
								<CheckboxInputComponent
									checkTitle="Is cash call"
									onClick={this.props.onInputChange}
									checked={isCashCall}
									name="isCashCall"
								/>
							</div>

							<HideComponent hide={!isCashCall}>
								<SelectComponent
									xs={12}
									sm={5}
									md={4}
									lg={5}
									onChange={this.props.onInputChange}
									options={cashCallParentProject}
									selectTitle="Parent project"
									value={selectedParent}
									name="selectedParent"
									style={{ backgroundColor: 'blue' }}
								/>

								<div className="col-xs-12 col-sm-5 col-md-5 col-lg-5">
									<IntInputComponent
										inputName="percentOfOriginalInvestment"
										elementValue={parseFloat(percentOfOriginalInvestment)}
										onInputUpdate={this.props.onInputChange}
										inputTitle="Percent of original investment"
									/>
								</div>
							</HideComponent>
						</div>
						<div className="row">
							<HideComponent hide={!isCashCall}>
								<div className="col-xs-12 col-sm-2 col-md-3 col-lg-2" />
								<div className="col-xs-12 col-sm-5 col-md-5 col-lg-5">
									<SelectComponent
										name="CashCallVersion"
										onChange={this.props.onInputChange}
										options={PROJECT_REG_D_CASHCALL_TYPES}
										selectTitle="Cash Call Type"
										value={CashCallVersion}
									/>
								</div>

								<div className="col-xs-12 col-sm-5 col-md-5 col-lg-5">
									<DateInputComponent
										inputName="CashCallDateLPEnd"
										inputTitle="LP Investment Cutoff Date"
										onInputChange={this.props.onInputChange}
										elementValue={CashCallDateLPEnd}
									/>
								</div>
							</HideComponent>
						</div>
						<div className="row">
							<HideComponent hide={!isCashCall}>
								<div className="col-xs-12 col-sm-2 col-md-3 col-lg-2" />
								<div className="col-xs-12 col-sm-5 col-md-5 col-lg-5">
									<CheckboxInputComponent
										checkTitle="Cash Call Open to All"
										onClick={this.props.onInputChange}
										checked={CashCallOpenToAll}
										name="CashCallOpenToAll"
									/>
								</div>

								<div className="col-xs-12 col-sm-5 col-md-5 col-lg-5" />
							</HideComponent>
						</div>
					</HideComponent>
                </CollapsiblePanelComponent>
            </div>
        );
    };


    renderProjectFianceDetailsPanel = () => {
		const isRegCF = this.isRegCfProject();
        const { isEditMode, projectDetails } = this.props;

        const {
			efCarry, workingInterest, WorkingInterestAPO, NetRevenueInterestBPO, NetRevenueInterestAPO,
			ManagerProfitShareBPO, ManagerProfitShareAPO, GasTaxes, OilTaxes, TotalDeductions,
			fundingGoalMin, fundingGoalMax, minimumInvestment,
            investmentIncrementUnit, unit,
        } = projectDetails;

        if (!isEditMode) return null;

        return (
            <div className="container margin-top-30px">
                <CollapsiblePanelComponent
                    panelTitle={<h3 className="heading-text">Finance Details</h3>}
                    isExpanded
                >
                    <div className="col-xs-6 col-md-3">
                        <IntInputComponent
                            inputName="efCarry"
                            elementValue={parseFloat(efCarry)}
                            onInputUpdate={this.props.onInputChange}
                            inputTitle="EF Carry %"
                        />
                    </div>

					<HideComponent hide={isRegCF}>
						<div className="col-xs-6 col-md-3">
							<IntInputComponent
								inputName="unit"
								elementValue={parseFloat(unit)}
								onInputUpdate={this.props.onInputChange}
								inputTitle="Total Units"
							/>
						</div>

						<div className="col-xs-6 col-md-3">
							<IntInputComponent
								inputName="workingInterest"
								elementValue={parseFloat(workingInterest)}
								onInputUpdate={this.props.onInputChange}
								inputTitle="Working Interest BPO"
							/>
						</div>

						<div className="col-xs-6 col-md-3">
							<IntInputComponent
								inputName="WorkingInterestAPO"
								elementValue={parseFloat(WorkingInterestAPO)}
								onInputUpdate={this.props.onInputChange}
								inputTitle="Working Interest APO"
							/>
						</div>

						<div className="col-xs-6 col-md-3">
							<IntInputComponent
								inputName="NetRevenueInterestBPO"
								elementValue={parseFloat(NetRevenueInterestBPO)}
								onInputUpdate={this.props.onInputChange}
								inputTitle="NRI BPO"
							/>
						</div>

						<div className="col-xs-6 col-md-3">
							<IntInputComponent
								inputName="NetRevenueInterestAPO"
								elementValue={parseFloat(NetRevenueInterestAPO)}
								onInputUpdate={this.props.onInputChange}
								inputTitle="NRI APO"
							/>
						</div>

						<div className="col-xs-6 col-md-3">
							<IntInputComponent
								inputName="ManagerProfitShareBPO"
								elementValue={parseFloat(ManagerProfitShareBPO)}
								onInputUpdate={this.props.onInputChange}
								inputTitle="Manager Profit Share BPO"
							/>
						</div>

						<div className="col-xs-6 col-md-3">
							<IntInputComponent
								inputName="ManagerProfitShareAPO"
								elementValue={parseFloat(ManagerProfitShareAPO)}
								onInputUpdate={this.props.onInputChange}
								inputTitle="Manager Profit Share APO"
							/>
						</div>

						<div className="col-xs-6 col-md-4">
							<IntInputComponent
								inputName="GasTaxes"
								elementValue={parseFloat(GasTaxes)}
								onInputUpdate={this.props.onInputChange}
								inputTitle="Gas Taxes"
							/>
						</div>

						<div className="col-xs-6 col-md-4">
							<IntInputComponent
								inputName="OilTaxes"
								elementValue={parseFloat(OilTaxes)}
								onInputUpdate={this.props.onInputChange}
								inputTitle="Oil Taxes"
							/>
						</div>

						<div className="col-xs-6 col-md-4">
							<IntInputComponent
								inputName="TotalDeductions"
								elementValue={parseFloat(TotalDeductions)}
								onInputUpdate={this.props.onInputChange}
								inputTitle="Total Deductions"
							/>
						</div>
					</HideComponent>

                    <div className="col-xs-12 col-sm-6">
                        <IntInputComponent
                            inputName="fundingGoalMin"
                            elementValue={parseFloat(fundingGoalMin)}
                            onInputUpdate={this.props.onInputChange}
                            inputTitle="Minimum goal"
                        />
                    </div>

                    <div className="col-xs-12 col-sm-6">
                        <IntInputComponent
                            inputName="fundingGoalMax"
                            elementValue={parseFloat(fundingGoalMax)}
                            onInputUpdate={this.props.onInputChange}
                            inputTitle="Maximum goal"
                        />
                    </div>

                    <div className="col-xs-12 col-sm-6">
                        <IntInputComponent
                            inputName="minimumInvestment"
                            elementValue={parseFloat(minimumInvestment)}
                            onInputUpdate={this.props.onInputChange}
                            inputTitle="Minimum investment"
                        />
                    </div>

                    <div className="col-xs-12 col-sm-6">
                        <IntInputComponent
                            inputName="investmentIncrementUnit"
                            elementValue={parseFloat(investmentIncrementUnit)}
                            onInputUpdate={this.props.onInputChange}
                            inputTitle="Investment increment unit"
                        />
                    </div>
                </CollapsiblePanelComponent>
            </div>
        );
    };


    renderHelloSignVariablesPanel = () => {
        const { isEditMode, projectDetails, helloSignTemplates } = this.props;
        const {
            helloSignTemplateId, fundName, operatorLPAgreement, minimumUnits, maximumUnits,
            minimumProceeds, maximumProceeds, minUnitsWithManagingGPCarry, maxUnitsWithManagingGPCarry,
            managingGPCarryBPO, managingGPCarryAPO, SkipHelloSign
        } = projectDetails;

        if (!isEditMode) return null;

        return (
            <div className="container margin-top-30px">
                <CollapsiblePanelComponent
                    panelTitle={<h3 className="heading-text">HelloSign variables</h3>}
                    isExpanded
                >

                    <div className="row">

						<div className="col-xs-12 col-sm-2 col-md-3 col-lg-2 checkbox-elements">
							<CheckboxInputComponent
								checkTitle="Skip Hello Sign"
								onClick={this.props.onInputChange}
								checked={SkipHelloSign}
								name="SkipHelloSign"
							/>
						</div>

                        <SelectComponent
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            name="helloSignTemplateId"
                            value={helloSignTemplateId}
                            options={helloSignTemplates}
                            onChange={this.props.onInputChange}
                            selectTitle="HelloSign subscription template"
                        />
                    </div>

                    <HideComponent hide={this.isRegCfProject()}>
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-6">
                                <MultilineInputComponent
                                    inputName="fundName"
                                    inputTitle="Fund Name"
                                    elementValue={fundName}
                                    maxInputLength={250}
                                    onInputUpdate={this.props.onInputChange}
                                    rows={2}
                                />

                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6">
                                <MultilineInputComponent
                                    inputName="operatorLPAgreement"
                                    elementValue={operatorLPAgreement}
                                    inputTitle="Operator for LP Agreement"
                                    onInputUpdate={this.props.onInputChange}
                                    maxInputLength={250}
                                    rows={2}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                                <SingleLineInputComponent
                                    inputName="minimumUnits"
                                    inputTitle="Minimum Units"
                                    elementValue={minimumUnits}
                                    onInputUpdate={this.props.onInputChange}
                                    maxInputLength={20}
                                />
                            </div>

                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                                <SingleLineInputComponent
                                    inputName="maximumUnits"
                                    inputTitle="Maximum Units"
                                    elementValue={maximumUnits}
                                    onInputUpdate={this.props.onInputChange}
                                    maxInputLength={20}
                                />
                            </div>

                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                                <SingleLineInputComponent
                                    inputName="minimumProceeds"
                                    elementValue={minimumProceeds}
                                    onInputUpdate={this.props.onInputChange}
                                    inputTitle="Minimum Proceeds"
                                    maxInputLength={20}
                                />
                            </div>

                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                                <SingleLineInputComponent
                                    inputName="maximumProceeds"
                                    elementValue={maximumProceeds}
                                    onInputUpdate={this.props.onInputChange}
                                    inputTitle="Maximum Proceeds"
                                    maxInputLength={20}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-6">
                                <SingleLineInputComponent
                                    inputName="minUnitsWithManagingGPCarry"
                                    inputTitle="Minimum Units with Managing GP Carry"
                                    elementValue={minUnitsWithManagingGPCarry}
                                    onInputUpdate={this.props.onInputChange}
                                    maxInputLength={20}
                                />
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-6">
                                <SingleLineInputComponent
                                    inputName="maxUnitsWithManagingGPCarry"
                                    inputTitle="Maximum Units with Managing GP Carry"
                                    elementValue={maxUnitsWithManagingGPCarry}
                                    onInputUpdate={this.props.onInputChange}
                                    maxInputLength={20}
                                />
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-6">
                                <SingleLineInputComponent
                                    inputName="managingGPCarryBPO"
                                    inputTitle="Managing GP Carry BPO"
                                    elementValue={managingGPCarryBPO}
                                    onInputUpdate={this.props.onInputChange}
                                    maxInputLength={20}
                                />
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-6">
                                <SingleLineInputComponent
                                    inputName="managingGPCarryAPO"
                                    inputTitle="Managing GP Carry APO"
                                    elementValue={managingGPCarryAPO}
                                    onInputUpdate={this.props.onInputChange}
                                    maxInputLength={20}
                                />
                            </div>
                        </div>
                    </HideComponent>
                </CollapsiblePanelComponent>
            </div>
        );
    };


    renderSocialNetworksPanel = () => {
        const { isEditMode, projectDetails, onInputChange } = this.props;
        const {
            socialFacebook, socialTwitter, socialLinkedIn, socialProjectWebPage,
        } = projectDetails;

        if (!isEditMode) return null;

        return (
            <HideComponent hide={!this.isRegCfProject()}>
                <div className="container margin-top-30px">
                    <CollapsiblePanelComponent
                        panelTitle={<h3 className="heading-text">Social Network URLs</h3>}
                        isExpanded
                    >
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-6">
                                <SingleLineInputComponent
                                    inputName="socialFacebook"
                                    inputTitle="Facebook Page"
                                    elementValue={socialFacebook}
                                    onInputUpdate={onInputChange}
                                    maxInputLength={255}
                                />
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-6">
                                <SingleLineInputComponent
                                    inputName="socialTwitter"
                                    inputTitle="Twitter Page"
                                    elementValue={socialTwitter}
                                    onInputUpdate={onInputChange}
                                    maxInputLength={255}
                                />
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-6">
                                <SingleLineInputComponent
                                    inputName="socialLinkedIn"
                                    inputTitle="LinkedIn Page"
                                    elementValue={socialLinkedIn}
                                    onInputUpdate={onInputChange}
                                    maxInputLength={255}
                                />
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-6">
                                <SingleLineInputComponent
                                    inputName="socialProjectWebPage"
                                    inputTitle="Company web page"
                                    elementValue={socialProjectWebPage}
                                    onInputUpdate={onInputChange}
                                    maxInputLength={255}
                                />
                            </div>
                        </div>
                    </CollapsiblePanelComponent>
                </div>
            </HideComponent>
        );
    };


    renderSecuritiesPanel = () => {
        const { isEditMode, projectDetails, onInputChange } = this.props;
        const {
            typeOfSecurity, securityUnitPrice, totalNumberOfSecurities,
        } = projectDetails;

        if (!isEditMode) return null;

        return (
            <HideComponent hide={!this.isRegCfProject()}>
                <div className="container margin-top-30px">
                    <CollapsiblePanelComponent
                        panelTitle={<h3 className="heading-text">Security details</h3>}
                        isExpanded
                    >
                        <div className="row">
                            <div className="col-xs-12 col-sm-12">
                                <SingleLineInputComponent
                                    inputName="typeOfSecurity"
                                    inputTitle="Security type"
                                    elementValue={typeOfSecurity}
                                    onInputUpdate={onInputChange}
                                    maxInputLength={255}
                                />
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-6">
                                <IntInputComponent
                                    inputName="securityUnitPrice"
                                    inputTitle="Security unit Price"
                                    elementValue={securityUnitPrice}
                                    onInputUpdate={onInputChange}
                                />
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-6">
                                <IntInputComponent
                                    inputName="totalNumberOfSecurities"
                                    inputTitle="Total No. of Securities"
                                    elementValue={totalNumberOfSecurities}
                                    onInputUpdate={onInputChange}
                                    maxInputLength={255}
                                />
                            </div>
                        </div>
                    </CollapsiblePanelComponent>
                </div>
            </HideComponent>
        );
    };


    renderFloatingInvestButton = (currentFunding, projectName, isPastProject) => {
        let _currentFunding = currentFunding;

        if (currentFunding && (typeof currentFunding === 'string')) {
            _currentFunding = parseFloat(currentFunding)
                .toLocaleString();
        }

        return (
            <div
                id="floatingInvestButton"
                className="floating-button-panel"
            >
                <div className="col-sm-10 col-md-10 col-lg-7 visible-sm visible-md visible-lg">
                    <h3 className="heading-text">{projectName}</h3>
                </div>
                <div
                    className="col-lg-4 visible-lg"
                    style={{ textAlign: 'center' }}
                >
                    {/*<h3 className="heading-text">
                        $
                        {_currentFunding}
                        {' '}
                        Funded
                    </h3>*/}
                </div>
				<HideComponent hide={isPastProject}>
					<div className="col-xs-12 col-sm-2 col-md-2 col-lg-1">
						<div className="pull-right">
							<button
								onClick={this.props.projectCardClick}
								style={{ backgroundColor: '#5bb462' }}
								className="btn btn-primary btn-lg upper-case-text no-margin"
								id="topInvestButton"
							>
								Invest
							</button>
						</div>
					</div>
				</HideComponent>
            </div>
        );
    };


    render() {
        const {
            projectDetails, showGoBackButton, isEditMode, onCardImageChange, allStatuses,
        } = this.props;

        const {
            description, projectName, socialTwitter, socialVideoURL,
            socialFacebook, socialLinkedIn, socialProjectWebPage, isPastProject
        } = projectDetails;

        return (
            <div>
                {isEditMode
                && (
                    <div>
                        <div className="col-xs-12">
                            <div className="pull-right">
                                <button
                                    className="btn btn-primary capitalize-text margin-10-px"
                                    onClick={this.toggleExternalImageContainer}
                                >
                                    Image Manager
                                </button>
                            </div>
                        </div>
                        <div className="col-xs-12">
                            <ExternalImageContainer
                                closeModal={this.toggleExternalImageContainer}
                                openModal={this.state.openExternalImageContainer}
                            />
                        </div>
                    </div>
                )}
                <div id="projectDetailsMainContainer">
                    {this.renderFloatingInvestButton(projectDetails.currentFunding, projectName, isPastProject)}

                    <div className="project-details-container">
                        {showGoBackButton
                        && (
                            <div className="project-details-container-go-back">
                                <span className="project-details-container-go-back-title">
                                    <span className="pointer" onClick={this.props.goToPreviousPage}>
                                        <Icon iconName="chevron-left" />
                                        {' '}
                                        Back to Investment Opportunities
                                    </span>
                                </span>
                            </div>
                        )}

                        <div className="project-details-container-project-name margin-top-30px">
                            {!isEditMode && <h1 className="capitalize-text heading-text">{projectName}</h1>}
                            {isEditMode
                            && (
                                <div className="edit-input">
                                    <SingleLineInputComponent
                                        maxInputLength={255}
                                        inputName="projectName"
                                        inputTitle="Project Name"
                                        onInputUpdate={this.props.onInputChange}
                                        elementValue={projectName}
                                    />
                                </div>
                            )}

                            <div className="project-details-container-project-image" />
                        </div>

                        <HideComponent hide={!this.isRegCfProject()}>
                            <div className="container project-reg-cf-social-buttons no-padding margin-top-30px">
                                <HideComponent hide={!socialTwitter || socialTwitter === ''}>
                                    <div className="reg-cf-social-project-button-container">
                                        <a
                                            href={socialTwitter}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <Icon iconName="twitter" extraClasses="reg-cf-social-project-button"/>
                                        </a>
                                    </div>
                                </HideComponent>

                                <HideComponent hide={!socialVideoURL || socialVideoURL === ''}>
                                    <div className="reg-cf-social-project-button-container">
                                        <a
                                            href={socialVideoURL}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <Icon iconName="youtube" extraClasses="reg-cf-social-project-button"/>
                                        </a>
                                    </div>
                                </HideComponent>

                                <HideComponent hide={!socialFacebook || socialFacebook === ''}>
                                    <div className="reg-cf-social-project-button-container">
                                        <a
                                            href={socialFacebook}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <Icon iconName="facebook" extraClasses="reg-cf-social-project-button"/>
                                        </a>
                                    </div>
                                </HideComponent>

                                <HideComponent hide={!socialLinkedIn || socialLinkedIn === ''}>
                                    <div className="reg-cf-social-project-button-container">
                                        <a
                                            href={socialLinkedIn}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <Icon iconName="linkedin" extraClasses="reg-cf-social-project-button"/>
                                        </a>
                                    </div>
                                </HideComponent>

                                <HideComponent hide={!socialProjectWebPage || socialProjectWebPage === ''}>
                                    <div className="reg-cf-social-project-button-container">
                                        <a
                                            href={socialProjectWebPage}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <Icon iconName="file" extraClasses="reg-cf-social-project-button"/>
                                        </a>
                                    </div>
                                </HideComponent>
                            </div>
                        </HideComponent>

                        <div className="project-details-container-card">
                            <ProjectCardComponent
                                isProjectDetails
                                cardImage={projectDetails.image}
                                category={projectDetails.category}
                                companyName={projectDetails.operatedBy}
                                fundPeriod={projectDetails.fundingPeriod}
                                goal={projectDetails.fundingGoalMax}
                                irr={projectDetails.irr}
                                location={projectDetails.location}
                                payback={projectDetails.payback}
                                recollected={projectDetails.currentFunding}
                                roi={projectDetails.roi}
                                ror={projectDetails.ror}
                                status={projectDetails.status}
                                investmentStartDate={projectDetails.investmentStartDate}
                                minGoal={projectDetails.fundingGoalMin}
                                description={projectDetails.dealCardDescription}
                                onSeeDetails={this.props.projectCardClick}
                                investorCount={projectDetails.investorCount}
                                isEditMode={this.props.isEditMode}
                                onInputChange={this.props.onInputChange}
                                allCategories={this.props.allCategories}
                                onCardImageChange={onCardImageChange}
                                allStatuses={allStatuses}
                                isRegCf={this.isRegCfProject()}
								isPastProject={isPastProject}
                            />
                        </div>

                        <HideComponent hide={(!this.isRegCfProject() || !socialVideoURL || !isValidUrl(socialVideoURL))}>
                            <div className="container margin-top-30px">
                                <h3 className="heading-text">Video</h3>
                            </div>
                            <div className="container project-reg-cf-video-buttons margin-top-30px">
                                <div className="embed-responsive embed-responsive-16by9">
                                    <iframe
                                        src={socialVideoURL}
                                        className="embed-responsive-item"
                                    />
                                </div>
                            </div>
                        </HideComponent>

                        {this.renderProjectDetailsPanel()}

                        {this.renderProjectFianceDetailsPanel()}

                        {this.renderHelloSignVariablesPanel()}

                        {this.renderSocialNetworksPanel()}

                        {this.renderSecuritiesPanel()}

                        <div
                            className="container margin-top-30px"
                            id="projectDetailsHighlights"
                        >
                            <h3 className="heading-text pull-left">Highlights</h3>

                            {this.renderAddNewHighlightButton()}
                        </div>

                        <div className="container project-details-highlight margin-top-30px">
                            {this.renderHighlights()}
                        </div>

                        <div className="container margin-top-30px">
                            <h3 className="heading-text">Overview</h3>
                        </div>

                        <div
                            className="container project-details-overview-data margin-top-30px"
                            id="projectDescription"
                        >
                            {!isEditMode && <div dangerouslySetInnerHTML={{ __html: description }} />}
                            {isEditMode && (
                                <RichTextEditorComponent
                                    name="description"
                                    value={description}
                                    onChange={this.props.onInputChange}
                                    editorHeight={550}
                                />
                            )}
                        </div>

                        {this.renderAddNewTabButton()}

                        <AddTabComponent
                            actionType={this.state.tabModalAction}
                            tabId={this.state.selectedTab.tabId}
                            isModalOpen={this.state.isTabsModalOpen}
                            projectId={this.props.approveProjectID}
                            projectType={this.props.projectType}
                            selectedTab={this.state.selectedTab}
                            onCloseModal={this.toggleTabsModal}
                            onUpdateTab={this.props.onPerformTabUpdate}
                            onAddNewTab={this.props.onAddNewTab}
                        />

                        {this.renderProjectTabs()}
                    </div>

                    <AddHighlightComponent
                        isModalOpen={this.state.isHighlightModalOpen}
                        actionType={this.state.highlightModalAction}
                        highlightId={this.state.selectedHighlight.highlightId}
                        selectedHighlight={this.state.selectedHighlight}
                        projectId={this.props.approveProjectID}
                        projectType={this.props.projectType}
                        onCloseModal={this.toggleHighlightModal}
                        onUpdateHighlight={this.props.onPerformUpdateHighlight}
                        onAddNewHighlight={this.props.onAddNewHighlight}
                    />

                    <ProjectUpdateViewer
                        showModal={this.props.isUpdateModalOpen}
                        closeModal={this.props.closeUpdateModal}
                        subject={this.props.selectUpdate.Subject}
                        smallText={this.props.selectUpdate.NotificationSmallText}
                        longText={this.props.selectUpdate.NotificationText}
                        projectName={projectName}
                        creationDate={this.props.selectUpdate.CreatedDate}
                        coverImage={this.props.selectUpdate.CoverImage}
                        userType={USER_INVESTOR}
                    />
                </div>
            </div>
        );
    };
}


ProjectDetailsComponent.propTypes = {
    cashCallParentProject: PropTypes.array.isRequired,
    closeUpdateModal: PropTypes.func.isRequired,
    getCommentReplies: PropTypes.func,
    getUpdateById: PropTypes.func.isRequired,
    goToPreviousPage: PropTypes.func.isRequired,
    helloSignTemplates: PropTypes.any,
    isEditMode: PropTypes.bool.isRequired,
    isUpdateModalOpen: PropTypes.bool.isRequired,
    isLoggedInUser: PropTypes.bool.isRequired,
    onAddNewHighlight: PropTypes.func.isRequired,
    onAddNewTab: PropTypes.func.isRequired,
    onDeleteHighlight: PropTypes.func.isRequired,
    onDeleteTab: PropTypes.func.isRequired,
    onInputChange: PropTypes.func.isRequired,
    onPerformTabUpdate: PropTypes.func.isRequired,
    onRefreshCommentList: PropTypes.func.isRequired,
    onPerformUpdateHighlight: PropTypes.func.isRequired,
    onSelectedHighlight: PropTypes.func.isRequired,
    onSelectedTab: PropTypes.func.isRequired,
    projectCardClick: PropTypes.func.isRequired,
    projectDetails: PropTypes.object.isRequired,
    projectFiles: PropTypes.array.isRequired,
    projectHighlights: PropTypes.array.isRequired,
    projectNotifications: PropTypes.array.isRequired,
    projectTabs: PropTypes.array.isRequired,
    selectUpdate: PropTypes.object.isRequired,
    showGoBackButton: PropTypes.bool.isRequired,
    allCategories: PropTypes.array.isRequired,
    allStatuses: PropTypes.array.isRequired,
    onCardImageChange: PropTypes.func.isRequired,
    showImportFilesButton: PropTypes.bool.isRequired,
    onTabInputFilesChange: PropTypes.func.isRequired,
    onSaveFileReorder: PropTypes.func.isRequired,
    onSaveNewComment: PropTypes.func.isRequired,
    approveProjectID: PropTypes.any.isRequired,
    projectType: PropTypes.string.isRequired,
    regCfCommentList: PropTypes.array,
};

ProjectDetailsComponent.defaultProps = {
    cashCallParentProject: [],
    closeUpdateModal: () => null,
    onRefreshCommentList: () => null,
    getCommentReplies: () => null,
    getUpdateById: () => null,
    goToPreviousPage: () => null,
    isEditMode: false,
    isUpdateModalOpen: false,
    onAddNewHighlight: () => null,
    onAddNewTab: () => null,
    onDeleteHighlight: () => null,
    onDeleteTab: () => null,
    onInputChange: () => null,
    onPerformTabUpdate: () => null,
    onPerformUpdateHighlight: () => null,
    onSelectedHighlight: () => null,
    onSelectedTab: () => null,
    projectCardClick: () => null,
    onSaveFileReorder: () => null,
    projectDetails: {},
    projectFiles: [],
    projectHighlights: [],
    projectNotifications: [],
    projectTabs: [],
    selectUpdate: {},
    showGoBackButton: false,
    allCategories: [],
    allStatuses: [],
    onCardImageChange: () => null,
    onSaveNewComment: () => null,
    showImportFilesButton: false,
    isLoggedInUser: true,
    onTabInputFilesChange: () => null,
    approveProjectID: 0,
    projectType: '',
    regCfCommentList: [],
};

export default ProjectDetailsComponent;
