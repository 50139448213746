import React from 'react';
import PropTypes from 'prop-types';

class InvestmentLogBase extends React.Component {
    static defaultProps = {
        performStepDataLog: () => null,
    };

    static propTypes = {
        performStepDataLog: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {};
    }

    init = () => new Promise((resolve) => {
        this.setState({
            log: {
                userId: '',
                regDProjectId: 0,
                regCFProjectId: 0,
                stepNumber: 0,
                eventName: '',
                stepData: '',
            },
        }, resolve);
    });

    setLogData = (logData = {}) => new Promise((resolve) => {
        const { log } = this.state;

        this.setState({ log: { ...log, ...logData } }, resolve);
    });

    setProjectIDs = (projectId, projectType) => new Promise((resolve) => {
        const { log } = this.state;

        // we need to update the 'RegD' and 'RegCF' strings
        // with the app constants when we do the invest overhaul
        const regDProjectId = projectType === 'RegD' ? projectId : 0;
        const regCFProjectId = projectType === 'RegCF' ? projectId : 0;

        this.setState({
            log: {
                ...log,
                regDProjectId,
                regCFProjectId,
            },
        }, resolve);
    });

    performStepLog = () => {
        const { log } = this.state;
        const { performStepDataLog } = this.props;

        performStepDataLog(log);
    };
}

export default InvestmentLogBase;
