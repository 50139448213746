import PropTypes from 'prop-types';
import React from 'react';
import { getUUID } from '../../../../utils/General';
import BaseComponent from '../utils/BaseComponent';

class RadioInputComponent extends BaseComponent {
    render = () => {
        const {
            checkId, checkTitle, elementName, onClick, checked,
            elementValue, className,
        } = this.props;
        const _checkId = checkId || getUUID();

        const _onClick = (event) => {
            const { name: _name, value } = event.target;

            onClick({
                target: {
                    name: _name,
                    value,
                },
            });
        };

        return (
            <div className="form-group">
                <div className="checkbox" style={{ padding: 0 }}>
                    <input
                        id={_checkId}
                        name={elementName}
                        type="checkbox"
                        value={elementValue}
                        checked={checked}
                        onChange={_onClick}
                        className="offerings-header-checkbox"
                    />
                    <label htmlFor={_checkId} className={`input-label no-padding ${className}`}>
                        {checkTitle}
                        {this.getRequired()}
                    </label>
                </div>
            </div>
        );
    };
}

RadioInputComponent.propTypes = {
    checkId: PropTypes.bool,
    checkTitle: PropTypes.string.isRequired,
    className: PropTypes.string,
    checked: PropTypes.bool.isRequired,
    elementName: PropTypes.string.isRequired,
    elementValue: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    isRequired: PropTypes.bool.isRequired,
};

RadioInputComponent.defaultProps = {
    checkTitle: '',
    checked: false,
    elementName: '',
    className: '',
    elementValue: '',
    onClick: () => null,
    isRequired: false,
};

export default RadioInputComponent;
