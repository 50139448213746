import initialState from '../../../../reducers/appInitialState'
import {
    PROJECT_UPDATE_REQUEST_END,
    PROJECT_UPDATE_REQUEST_START,
    REQUEST_ALL_REG_CF_UPDATES_SUCCESS,
    REQUEST_ALL_REG_D_UPDATES_SUCCESS,
    REQUEST_GET_PROJECT_UPDATE_BY_ID_SUCCESS,
    SET_SELECTED_PROJECT_UPDATE
} from '../actions/ProjectUpdateConstants';

export default function (state = initialState.projectUpdate, action) {
    const {projectUpdateOnFlightReq} = state;
    const plus = (projectUpdateOnFlightReq + 1);
    const minus = projectUpdateOnFlightReq <= 0 ? 0 :
        (projectUpdateOnFlightReq - 1);

    switch (action.type) {
        case PROJECT_UPDATE_REQUEST_START:
            return {...state, projectUpdateOnFlightReq: plus};
        case PROJECT_UPDATE_REQUEST_END:
            return {...state, projectUpdateOnFlightReq: minus};
        case REQUEST_ALL_REG_D_UPDATES_SUCCESS:
            return {...state, projectUpdateOnFlightReq: minus, regDUpdateList: action.data};
        case REQUEST_ALL_REG_CF_UPDATES_SUCCESS:
            return {...state, projectUpdateOnFlightReq: minus, regCFUpdateList: action.data};
        case SET_SELECTED_PROJECT_UPDATE:
            return {...state, selectedUpdated: action.data};
        case REQUEST_GET_PROJECT_UPDATE_BY_ID_SUCCESS:
            const _selectedUpdated = {...state.selectedUpdated};
            _selectedUpdated.notificationData = action.data;
            return Object.assign({}, state, {selectedUpdated: _selectedUpdated});
        default:
            return state;
    }
}
