import PropTypes from 'prop-types';
import React from 'react';
import { getUUID } from '../../../../../utils/General';
import Icon from '../../../IconComponent/IconComponent';

class FilesTabComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isSortingFiles: false,
            filesList: [],
        };

        this.draggedItem = null;
        this.sortingFileListCopy = [];
    }

    componentDidMount() {
        this.setFileListState();
    }

    componentDidUpdate(prevProps) {
        const { filesList } = this.props;
        const _filesList = JSON.stringify(prevProps.filesList);
        const strFileList = JSON.stringify(filesList);

        if (_filesList !== strFileList) {
            this.setFileListState();
        }
    }

    setFileListState = () => {
        const { filesList } = this.props;
        const fileListCopy = filesList.map((item) => {
            const copy = { ...item };

            copy.key = getUUID();
            return copy;
        });

        this.setState({ filesList: fileListCopy });
    };

    verifyIfButtonCanBeDisabled = (file) => {
        const newFile = { ...file };

        if (file.s3Location === '') newFile.disabledButton = true;

        return newFile;
    };

    toggleFileArrangement = () => {
        const { isSortingFiles } = this.state;

        this.setState({ isSortingFiles: !isSortingFiles });
        this.sortingFileListCopy = [];
    };

    onSortFileClick = () => {
        const { filesList } = this.state;

        this.toggleFileArrangement();
        this.sortingFileListCopy = [...filesList];
    };

    onCancelFileSorting = () => {
        this.setState({ filesList: [...this.sortingFileListCopy] },
            this.toggleFileArrangement);
    };

    onSaveFileSorting = () => {
        const { onSaveFileReorder } = this.props;
        const { filesList } = this.state;

        onSaveFileReorder(filesList, this.toggleFileArrangement);
    };

    // DRAGGABLE MANAGER
    onDragStart = (event, index) => {
        const { filesList } = this.state;

        this.draggedItem = filesList[index];

        event.dataTransfer.effectAllowed = 'move';
        event.dataTransfer.setData('text/html', event.target.parentNode);
        event.dataTransfer.setDragImage(event.target.parentNode, 20, 20);
    };

    onDragOver = (index) => {
        const { filesList } = this.state;
        const draggedOverItem = filesList[index];

        if (this.draggedItem === draggedOverItem) {
            return;
        }

        const items = filesList.filter(item => item !== this.draggedItem);

        items.splice(index, 0, this.draggedItem);

        this.setState({ filesList: items });
    };

    onDragEnd = () => {
        this.draggedItem = null;
    };

    // RENDER MANAGER
    renderFileList = () => {
        const { filesList } = this.state;

        return filesList
            .map(this.verifyIfButtonCanBeDisabled)
            .map(this.renderTableElement);
    };

    renderTableElement = (file, index) => {
        const { onDeleteFile, isEditMode } = this.props;
        const { isSortingFiles } = this.state;
        const sortElement = (
            <td
                className="capitalize-text"
                onDragStart={event => this.onDragStart(event, index)}
                onDragEnd={this.onDragEnd}
                draggable
            >
                <Icon iconName="sort"/>
                &ensp;
                Drag Row Up and Down to Move
            </td>
        );
        const actionsElement = (
            <td>
                {file.disabledButton && (
                    <button
                        className="btn btn-primary btn-sm no-margin"
                        type="button"
                        disabled
                    >
                        <Icon iconName="download" extraClasses="white-text"/>
                        {' '}
                        Download
                    </button>
                )}
                {!file.disabledButton && (
                    <a
                        className="btn btn-primary btn-sm no-margin"
                        href={file.s3Location}
                        target="_blank"
                    >
                        <Icon iconName="download" extraClasses="white-text"/>
                        {' '}
                        Download
                    </a>
                )}
                &ensp;
                {isEditMode && (
                    <a
                        className="btn btn-danger btn-sm no-margin"
                        onClick={() => onDeleteFile(file)}
                    >
                        <Icon iconName="trash" extraClasses="white-text"/>
                        {' '}
                        Delete
                    </a>
                )}
            </td>
        );

        const sortCell = isSortingFiles ? sortElement : null;
        const actionsCell = !isSortingFiles ? actionsElement : null;

        return (
            <tr
                key={file.key}
                onDragOver={() => this.onDragOver(index)}
            >
                <td>{file.documentId}</td>
                <td>{file.documentName}</td>
                {sortCell}
                {actionsCell}
            </tr>
        );
    };

    renderAdminTabHeader = () => {
        const { showImportFiles, onTabInputFilesChange } = this.props;

        if (!showImportFiles) return null;

        return (
            <div className="col-xs-12">
                <input
                    id="projectDocumentsInput"
                    type="file"
                    style={{ display: 'none' }}
                    multiple
                    onChange={onTabInputFilesChange}
                />
                <div className="pull-left">
                    <strong className="upper-case-text">
                        <i>
                            all files must be
                            {' '}
                            <span className="red-text">32mb</span>
                            {' '}
                            or less
                        </i>
                    </strong>
                </div>
                <div className="pull-right">
                    {this.renderMainActionButtons()}
                    {this.renderActiveSortingButtons()}
                </div>
            </div>
        );
    };

    renderMainActionButtons = () => {
        const { isSortingFiles } = this.state;

        if (isSortingFiles) return null;

        return (
            <React.Fragment>
                <button
                    className="btn btn-default capitalize-text"
                    onClick={this.onSortFileClick}
                    type="button"
                >
                    <Icon iconName="sort"/>
                    &ensp;
                    Sort files
                </button>
                <label
                    className="btn btn-primary capitalize-text"
                    htmlFor="projectDocumentsInput"
                >
                    <Icon iconName="plus" extraClasses="white-text"/>
                    &ensp;
                    Add new files
                </label>
            </React.Fragment>
        );
    };

    renderActiveSortingButtons = () => {
        const { isSortingFiles } = this.state;

        if (!isSortingFiles) return null;

        return (
            <React.Fragment>
                <button
                    className="btn btn-primary capitalize-text"
                    onClick={this.onSaveFileSorting}
                    type="button"
                >
                    <Icon iconName="floppy-o" extraClasses="white-text"/>
                    &ensp;
                    save changes
                </button>
                <button
                    className="btn btn-warning capitalize-text"
                    onClick={this.onCancelFileSorting}
                    type="button"
                >
                    <Icon iconName="times" extraClasses="white-text"/>
                    &ensp;
                    cancel sorting
                </button>
            </React.Fragment>
        );
    };

    render = () => {
        const { isSortingFiles } = this.state;

        return (
            <div className="container">
                {this.renderAdminTabHeader()}

                <div className="col-xs-12">
                    <div className="table-primary table-responsive">
                        <table className="table table-hover table-striped">
                            <thead>
                            <tr>
                                {isSortingFiles && <th>Rearrange</th>}
                                <th>File ID</th>
                                <th>File Name</th>
                                {!isSortingFiles && <th>Actions</th>}
                            </tr>
                            </thead>
                            <tbody>
                            {this.renderFileList()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    };
}

FilesTabComponent.propTypes = {
    filesList: PropTypes.array.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    onDeleteFile: PropTypes.func.isRequired,
    onTabInputFilesChange: PropTypes.func.isRequired,
    onSaveFileReorder: PropTypes.func.isRequired,
    showImportFiles: PropTypes.bool.isRequired,
    tabId: PropTypes.any,
    tabName: PropTypes.string,
    tabOrder: PropTypes.number,
};

FilesTabComponent.defaultProps = {
    filesList: [],
    isEditMode: false,
    onDeleteFile: () => null,
    onTabInputFilesChange: () => null,
    onSaveFileReorder: () => null,
    showImportFiles: false,
    tabId: getUUID(),
    tabName: 'Documents',
    tabOrder: 1000,
};

export default FilesTabComponent;
