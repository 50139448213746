import React, { Component } from "react";
import Chart from "react-apexcharts";

import moment from "moment";
import { formatChartMoney,formatChartLabel } from "../../../utils/General";
class InvestmentChart extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		const {
			numberOfInvestments,
			investmentLabels,
			cumulativeEarnings,
			cumulativeEarningsTimeSeries,
			cumulativeInvestmentTimeSeries,
		} = this.props;
		const investmentSeries = cumulativeInvestmentTimeSeries?.map((i) => ({
			x: i.x,
			y: parseFloat(i.y.toFixed(0)),
		}));
		const earningsSeries = cumulativeEarningsTimeSeries.map((i) => ({
			x: i.x,
			y: parseFloat(i.y.toFixed(0)),
		}));


	const series = [
			{
				name: "Account Value",
				type: "line",
				data: investmentSeries,
			},
		
		];
		const options = {
			tooltip: {
    
     				y: {
					formatter: function(val, index) {
						return formatChartMoney(val);
					  }
				   
				},
			},
			chart: {
				fontFamily: 'Montserrat, Arial, sans-serif',
				toolbar: {
					show:false},
				type: "line",
				
			},
			colors: ["#182f3b", "#5bb462"],
			stroke: {
				width: [8, 0],
				curve: "smooth",
				lineCap: "round",
			},
			plotOptions: {
				bar: {
					columnWidth: "40%",
				},
			},
			markers: {
				size: 4,
			},
			xaxis: {
				type: "datetime",
				// labels: {
				// 	formatter: function (val) {
				// 		return moment(val).format("YYYY MMMM");
				// 	},
				// },
			},
			title: {
				text: "",
			},
			dataLabels: {
                enabled: false,
              },
			labels: investmentLabels,
		
			yaxis: [
				{
					labels: {
			
						formatter: function(val, index) {
						  return formatChartLabel(val);
						}
					  },
					title: {
						text: "Account Value",
						
					},
				},
			
			],
			
		
			
		};
	
		return (
			<>
				<h3 style={{borderBottom: "1px", margin: 0, padding: "10px" }}>Account Value <span style={{fontSize: "12px", display: "block", fontWeight: 400, marginTop: "5px", lineHeight: "18px"}}>Includes your original investment, plus reinvestments, but does not include cash distributions</span></h3>
				<Chart
					options={options}
					series={series}
					type="line"
					height={340}
				/>
			</>
		);
	}
}
export default InvestmentChart;
