import React from "react";
import {Col} from "react-bootstrap";
import CKEditor from "react-ckeditor-component";
import PropTypes from "prop-types";
import {ContainerComponent} from "../../../shared/ContainerComponent/ContainerComponent";
import {
    PROJECT_REG_CF,
    PROJECT_REG_D, PROJECT_UPDATE_CLONE_AS_NEW,
    PROJECT_UPDATE_CREATE,
    PROJECT_UPDATE_EDIT,
    PROJECT_UPDATE_VIEW,
} from "../../../../utils/ApplicationConstants";
import ToolbarConfig from "./CkEditorConfig";
import ProjectUpdateTypeEditor from "./ProjectUpdateTypeEditor";
import Icon from "../../../shared/IconComponent/IconComponent";
import ExternalImageContainer from '../../../shared/ExternalImageContainerComponent/ExternalImageContainer';

const propTypes = {
    parentProjectIdRegCF: PropTypes.number.isRequired,
    parentProjectIdRegD: PropTypes.number.isRequired,
    selectedProject: PropTypes.string.isRequired,
    projectType: PropTypes.string.isRequired,
    smallText: PropTypes.string.isRequired,
    longText: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    projectList: PropTypes.arrayOf(PropTypes.shape({
        projectId: PropTypes.number,
        projectName: PropTypes.string,
    })).isRequired,
    coverImage: PropTypes.any.isRequired,
    updateType: PropTypes.any.isRequired,
    isPrivateForEver: PropTypes.bool.isRequired,
    daysAsPrivateUpdate: PropTypes.any.isRequired,
    internalTitle: PropTypes.string.isRequired,

    // functions
    onEditorChangeHandler: PropTypes.func.isRequired,
    onChangeHandler: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onPreviewClickHandler: PropTypes.func.isRequired,
    onFileInputChange: PropTypes.func.isRequired,
    onSendTestEmailClick: PropTypes.func.isRequired,
    onCheckboxSelected: PropTypes.func.isRequired,
    openExternalEmailModal: PropTypes.func.isRequired,
};

const emptyFunc = () => null;
const defaultProps = {
    parentProjectIdRegCF: 0,
    parentProjectIdRegD: 0,
    selectedProject: "0",
    projectType: "",
    smallText: "",
    longText: "",
    subject: "",
    action: "",
    projectList: [{
        projectId: 0,
        projectName: "",
    }],
    coverImage: {},
    updateType: -1,
    isPrivateForEver: false,
    daysAsPrivateUpdate: 1,
    internalTitle: "",

    // functions
    onEditorChangeHandler: emptyFunc,
    onChangeHandler: emptyFunc,
    onUpdate: emptyFunc,
    onCancel: emptyFunc,
    onSave: emptyFunc,
    onPreviewClickHandler: emptyFunc,
    onFileInputChange: emptyFunc,
    onSendTestEmailClick: emptyFunc,
    onCheckboxSelected: emptyFunc,
};

class ProjectUpdateEditor extends React.Component {
    buttonStyle = {
        border: "none",
        margin: 0,
        padding: "0px 10px",
    };

    constructor(props) {
        super(props);

        this.state = {
            openExternalImageContainer: false,
        };
    }

    onSubmit = (e) => {
        e.preventDefault();

        const {action} = this.props;
        if (action === PROJECT_UPDATE_EDIT)
            this.props.onUpdate();
        else if (action === PROJECT_UPDATE_CREATE || action === PROJECT_UPDATE_CLONE_AS_NEW)
            this.props.onSave();
    };

    disableOnEditAnView = () => {
        const {action} = this.props;
        return (action === PROJECT_UPDATE_EDIT || action === PROJECT_UPDATE_VIEW);
    };

    disableSaveButton = () => {
        const _selectedProject = (this.props.selectedProject === -1);
        const _projectType = (this.props.projectType === "");
        const _smallText = (this.props.smallText === "");
        const _subject = (this.props.subject === "");
        const _internalTitle = (this.props.internalTitle === "");

        if (this.props.action === PROJECT_UPDATE_CREATE) {
            return (_subject || _smallText || _projectType || _selectedProject || _internalTitle);
        } else {
            return false
        }
    };

    renderProjectOptions = () => {
        const {projectList} = this.props;

        return projectList.map((project, key) => (
            <option key={key}
                    value={project.projectId}
                    selected={this.selectProjectOnEdit(project)}>
                {project.projectName}
            </option>
        ));
    };

    selectProjectOnEdit = (project) => {
        return (project.projectId === this.props.parentProjectIdRegCF ||
            project.projectId === this.props.parentProjectIdRegD)
    };

    renderText = (a, b) => {
        return this.props.action === PROJECT_UPDATE_CREATE ? a : b;
    };

    getImageName = () => {
        const {coverImage} = this.props;

        if (!coverImage || coverImage === "")
            return "Select an image";

        const fileName = coverImage["name"] || coverImage;
        return fileName.split("/").pop();
    };

    setContentAfterLoad = (referenceKey, valueKey) => {
        const _value = this.props[valueKey];
        const {id} = this.refs[referenceKey].editorInstance;
        const instances = window.CKEDITOR.instances;
        const result = Object.keys(instances)
            .map(key => instances[key])
            .filter(instance => instance.id === id)[0];
        result.document.$.getElementsByTagName("body")[0].innerHTML = _value;
    };

    closeExternalImageContainer = () => {
        this.setState({openExternalImageContainer: false});
    };

    openExternalImageContainer = () => {
        this.setState({openExternalImageContainer: true});
    };

    render = () => {
        return (
            <div className="right_col">
                <ContainerComponent>
                    <div className="col-xs-12">
                        <button className="btn btn-primary btn-sm pull-right"
                                type="button"
                                onClick={this.props.onPreviewClickHandler}>
                            Preview
                        </button>

                        <div className="dropdown">
                            <button className="btn-sm btn-info dropdown-toggle pull-right"
                                    data-toggle="dropdown"
                                    type="button"
                                    disabled={this.disableSaveButton()}>
                                <span>Send Test Email <Icon iconName="caret-down" extraClasses="white-text"/></span>
                            </button>
                            <ul className="dropdown-menu">
                                <li onClick={this.props.onSendTestEmailClick}>
                                    <a className="btn-sm btn-default btn-dropdown">Send email to me</a>
                                </li>
                                <li onClick={() => this.props.openExternalEmailModal(true)}>
                                    <a className="btn-sm btn-default btn-dropdown">Send email to others</a>
                                </li>
                            </ul>
                        </div>

                        <button className="btn btn-default btn-sm pull-right"
                                onClick={this.openExternalImageContainer}
                                type="button">
                            Image Manager
                        </button>
                    </div>

                    <div className="col-xs-12">
                        <ExternalImageContainer closeModal={this.closeExternalImageContainer}
                                                openModal={this.state.openExternalImageContainer}/>
                    </div>

                    <div>
                        <Col className="visible-md visible-lg" md={1} lg={3}/>
                        <Col xs={12} sm={12} md={10} lg={6}>
                            <form onSubmit={this.onSubmit}>
                                <h3>{this.renderText("Create", "Edit")} Project Update</h3>

                                <div className="form-group">
                                    <label><span className="red-text">*</span> Project Type</label>
                                    <select className="form-control"
                                            name="projectType"
                                            onChange={this.props.onChangeHandler}
                                            disabled={this.disableOnEditAnView()}>
                                        <option value="" selected>None</option>
                                        <option value={PROJECT_REG_D}
                                                selected={this.props.projectType === PROJECT_REG_D}>
                                            Regulation D
                                        </option>
                                        <option value={PROJECT_REG_CF}
                                                selected={this.props.projectType === PROJECT_REG_CF}>
                                            Regulation CF
                                        </option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label><span className="red-text">*</span> Project</label>
                                    <select className="form-control"
                                            name="selectedProject"
                                            onChange={this.props.onChangeHandler}
                                            disabled={this.disableOnEditAnView()}>
                                        <option value={0} selected>None</option>
                                        {this.renderProjectOptions()}
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label><span className="red-text">*</span> Cover image</label>
                                    <label htmlFor="pxr_imageCoverFile"
                                           className="form-control"
                                           style={{borderRadius: "5px", fontWeight: 400, cursor: "pointer"}}>
                                        {this.getImageName()}
                                    </label>
                                    <input id="pxr_imageCoverFile"
                                           className="btn btn-default btn-sm"
                                           name="coverImage"
                                           type="file"
                                           accept="image/*"
                                           style={{display: "none"}}
                                           onChange={this.props.onFileInputChange}/>
                                </div>

                                <ProjectUpdateTypeEditor daysAsPrivateUpdate={this.props.daysAsPrivateUpdate}
                                                         isPrivateForEver={this.props.isPrivateForEver}
                                                         onChangeHandler={this.props.onChangeHandler}
                                                         onCheckboxSelected={this.props.onCheckboxSelected}
                                                         updateType={this.props.updateType}/>

                                <div className="form-group">
                                    <label><span className="red-text">*</span> Internal Title</label>
                                    <input type="text"
                                           name="internalTitle"
                                           value={this.props.internalTitle}
                                           onChange={this.props.onChangeHandler}
                                           className="form-control form-input"/>
                                </div>

                                <div className="form-group">
                                    <label><span className="red-text">*</span> Email Subject</label>
                                    <input type="text"
                                           name="subject"
                                           value={this.props.subject}
                                           onChange={this.props.onChangeHandler}
                                           className="form-control form-input"/>
                                </div>

                                <div className="form-group">
                                    <label>
                                        <span className="red-text">*</span> Short description (Included in Email)
                                    </label>
                                    <CKEditor name="smallText"
                                              ref="smallText"
                                              content={this.props.smallText}
                                              config={{
                                                  height: 250,
                                                  toolbar: ToolbarConfig,
                                              }}
                                              events={{
                                                  "change": (event) => this.props.onEditorChangeHandler(event, "smallText"),
                                                  "instanceReady": () => this.setContentAfterLoad("smallText", "smallText"),
                                              }}/>
                                </div>

                                <div className="form-group">
                                    <label>Full description (Not included in Email, Only Seen on Updates Page)</label>
                                    <CKEditor name="longText"
                                              ref="longText"
                                              content={this.props.longText}
                                              config={{
                                                  height: 500,
                                                  toolbar: ToolbarConfig,
                                              }}
                                              events={{
                                                  "change": (event) => this.props.onEditorChangeHandler(event, "longText"),
                                                  "instanceReady": () => this.setContentAfterLoad("longText", "longText"),
                                              }}/>
                                </div>

                                <div className="pull-right" style={{paddingBottom: "30px", paddingTop: "20px"}}>
                                    <button className="btn btn-danger"
                                            onClick={this.props.onCancel}
                                            type="button">
                                        Cancel
                                    </button>
                                    <button className="btn btn-primary"
                                            type="submit"
                                            disabled={this.disableSaveButton()}>
                                        Save
                                    </button>
                                </div>
                            </form>
                        </Col>
                    </div>
                </ContainerComponent>
            </div>
        );
    };
}

ProjectUpdateEditor.propTypes = propTypes;
ProjectUpdateEditor.defaultProps = defaultProps;

export default ProjectUpdateEditor;
