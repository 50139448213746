/**
 * This is /app/setupprofile3
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Stepper from "react-stepper-horizontal";

class Dwolapayment extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoaded: false,
			errorOnLoad: false,
			steps: [
				{ title: "Verify Your Identity" },
				{ title: "Connect Funds" },
			],
			currentStep: 1,
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	doMicroDeposits = async () => {
		this.props.history.push({
			pathname: "/app/setupprofile4",
			state: {
				Method: "md",
				//addMissingAccount: this.props.location.state.addMissingAccount, // causes error when you go automatically from verifying entity to adding bank account.  Also, I don't think we need this, because the page should know which dropdowns to populate with that are missing bank account.
			},
		});
	};

	doIAV = async () => {
		this.props.history.push({
			pathname: "/app/setupprofile4",
			state: {
				Method: "iav",
				//addMissingAccount: this.props.location.state.addMissingAccount, // causes error when you go automatically from verifying entity to adding bank account.  Also, I don't think we need this, because the page should know which dropdowns to populate with that are missing bank account.
			},
		});
	};

	render() {
		const { steps, currentStep } = this.state;
		const imgAddBank = "/img/add-your-bank.png";
		const pathname = window.location.pathname.toLocaleLowerCase();
		
		if (pathname.match("/setupprofile3")) {
			document.body.classList.add("videntity-vcenter");
		}
		return (
			<div className="verify-identity add-bank">
				<div className="stepper-horizontal"
					style={{
						backgroundColor: "#092736",
						paddingBottom: 20,
					}}
				>
					<Stepper
						steps={steps}
						activeStep={currentStep}
						defaultColor="#fff"
						activeColor="#5bb462"
						circleFontColor="#000"
						circleFontSize={14}
						defaultTitleColor="#fff"
						activeTitleColor="#5bb462"
						completeColor="#fff"
						completeTitleColor="#fff"
						completeBarColor="#fff"
					/>
				</div>
				<div className="vi-container-fluid position-relative">
					<div className="linear-gradient"></div>
					<div className="container">
						<div className="d-md-flex  align-items-center">
							<div className="col-xs-12 col-sm-12 col-md-5 col-lg-5 d-flex flex-column align-items-center justify-content-center position-relative lt-sec">
								<img src={imgAddBank} />
								<h1>Add Your Bank</h1>
							</div>
							<div className="col-xs-12 col-sm-12  col-md-7 col-lg-7 d-md-flex rt-sec">
								<div className="row d-flex justify-content-center"> {/* after integrating Dwolla IAV remove d-flex and justify-content-center from this class */}
									{/*after integrating Dwolla IAV with new dwolla dropin component uncomment below */}
									{/* <div className="col-md-6">
										<div className="tile-box">
											<div>
												<h5>Instant &amp; Preferred</h5>
												<h3>
													Instant Account Verification
												</h3>
												<p>
												Instant Account Verification (IAV) gives you the ability to add and
												verify your bank account in a matter of seconds. This is the
												preferred way to add your account. Please note that Instant Account
												Verification is limited to banks that support it and some banks
												require that you affirmatively authorize Instant Account
												Verification. If you have questions about support or authorization,
												please check with your bank. If you are unable to successfully
												verify your account with Instant Account Verification, please try
												Micro Deposits.
												</p>
											</div>
											<div className="text-center">
												<button
													id="submit1"
													ref="submit1"
													onClick={this.doIAV}
													value="submit"
													className="btn semi-bold"
												>
													Select
												</button>
											</div>
										</div>
									</div> 
									<div className="col-md-6"> */}
									 <div className="col-md-8">{/* remove this line after adding IAV */}
										<div className="tile-box">
											<div>
												<h5>2-3 Business Days</h5>
												<h3>Micro Deposits</h3>
												<p>
												If you choose the "Micro Deposits" method of bank verification, we
												will transfer two deposits of $0.10 or less to your bank or credit
												union account. It may take 2-3 business days for the micro deposits
												to be completed. Once completed, please enter the amounts into our
												system to verify your bank account.
												</p>
											</div>
											<div className="text-center">
												<button
													id="submit1"
													ref="submit1"
													onClick={
														this.doMicroDeposits
													}
													value="submit"
													className="btn semi-bold"
												>
													Select
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const { object, bool } = PropTypes;

Dwolapayment.propTypes = {
	session: object.isRequired,
	authenticated: bool.isRequired,
	checked: bool.isRequired,
};

const mapState = ({ session }) => ({
	session: session.user,
	checked: session.checked,
	authenticated: session.authenticated,
});

export default withRouter(connect(mapState)(Dwolapayment));
