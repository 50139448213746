import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as sessionActions from '../../../../actions/sessionActions';
import PropTypes from 'prop-types';
import MenuSection from '../components/MenuSection';
import MenuList from '../components/MenuList';
import {AdminItems, FundRaiserItems, InvestorItems, SuperAdminItems,NewInvestorItems} from '../utils/Locations';
import store from '../../../../index'

class SidebarContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			user: {},
			Role: [],
			MainRole: this.props.role,
			data: [],
			id: "",
			isLoaded: false,
			errorOnLoad: false
		};

		this.redirectTo = this.redirectTo.bind(this);
		this.getDetails = this.getDetails.bind(this);
	}


	componentDidMount() {
		window.scrollTo(0, 0);
		this.getDetails();
	};


	redirectTo() {
		if (this.state.MainRole === ("SuperAdmin" || "Admin")) {
			this.props.history.push("/app/admindashboard");
		}
		else {
			this.props.history.push("/app/UserNotifications");
		}
	}


	getDetails() {
		fetch('/api/getusername/' + this.props.id + '/' + this.props.user.marketing, {
			method: 'get'
		})
			.then((response) => {
				return response.json()
			})
			.then((data) => {
				this.setState({
					data: data[0].FirstName,
					isLoaded: true,
					err: {},
					errorOnLoad: false,
				});
			})
			.catch((err) => {
				this.setState({
					err: err,
					errorOnLoad: true
				})
			});

		fetch('/api/getrole/' + this.props.id + '/' + this.props.id + '/' + this.props.user.marketing, {
			method: 'get'
		})
			.then((response) => response.json())
			.then((data) => {
				this.setState({
					Role: data,
					isLoaded: true,
					err: {},
					errorOnLoad: false,
				});
				for (let i = 0; i < this.state.Role.length; i++) {
					if (this.state.Role[i].Role === "Admin") {
						this.setState({
							MainRole: "Admin",
							isLoaded: true,
							err: {},
							errorOnLoad: false,
						});
						break;
					}
					else if (this.state.Role[i].Role === "SuperAdmin") {
						this.setState({
							MainRole: "SuperAdmin",
							isLoaded: true,
							err: {},
							errorOnLoad: false,
						});
						break;
					}
					else {
						this.setState({
							MainRole: "Investor",
							isLoaded: true,
							err: {},
							errorOnLoad: false,
						});
					}
				}
			})
			.catch((err) => {
				this.setState({
					err: err,
					errorOnLoad: true
				})
			})
	}


	render() {
		let menuItems = null;
		let opItems = null;
		let invItems = null;
		const adminView = this.props.isAdminView;


		if (this.state.Role.Role === "Investor" && adminView ) {
			invItems = (
				<MenuSection sectionTitle={InvestorItems.sectionTitle} isOpen={InvestorItems.isOpen}>
					<MenuList linkList={InvestorItems.linkList} showIcon={this.props.showIcon}/>
				</MenuSection>
			);
		}
		else {
			let {Role} = this.state;
			Role = Role || [];

			Role.forEach(roleObj => {
				const strRole = roleObj.Role;

				if (strRole === "SuperAdmin"  && adminView) {
					menuItems = (
						<MenuSection sectionTitle={SuperAdminItems.sectionTitle} isOpen={SuperAdminItems.isOpen} showIcon={this.props.showIcon}>
							<MenuList linkList={SuperAdminItems.linkList} showIcon={this.props.showIcon} adminView ={adminView} />
						</MenuSection>
					);
				}
				else if (strRole === "Admin"  && adminView) {
					menuItems = (
						<MenuSection sectionTitle={AdminItems.sectionTitle} isOpen={AdminItems.isOpen} showIcon={this.props.showIcon}>
							<MenuList linkList={AdminItems.linkList} showIcon={this.props.showIcon} adminView ={adminView} />
						</MenuSection>
					);
				}
				else if (strRole === "FundRaiser"  && adminView) {
					opItems = (
						<MenuSection sectionTitle={FundRaiserItems.sectionTitle} isOpen={FundRaiserItems.isOpen} showIcon={this.props.showIcon}>
							<MenuList linkList={FundRaiserItems.linkList} showIcon={this.props.showIcon} adminView ={adminView} />
						</MenuSection>
					);
				}
				else {
					if(!adminView){
					if (Role.length === 1 ) {
						invItems = (<MenuList linkList={NewInvestorItems.linkList} showIcon={this.props.showIcon} adminView ={adminView} />);
					}
					else {
						invItems = (
							<MenuSection sectionTitle={InvestorItems.sectionTitle} isOpen={InvestorItems.isOpen} showIcon={this.props.showIcon}>
								<MenuList linkList={NewInvestorItems.linkList} showIcon={this.props.showIcon} adminView ={adminView} />
							</MenuSection>
						);
					}
				}
				}
			});
		}

		return (
			<div className="left_col">
				<div id="sidebar-menu"
					 className="main_menu_side hidden-print main_menu"
					 style={{width: "100%"}}>
					<div className="menu_section">
						{menuItems}
						{invItems}
						{opItems}
					</div>
				</div>
			</div>
		);
	};
}


const {object, bool} = PropTypes;

SidebarContainer.propTypes = {
	session: object.isRequired,
	actions: object.isRequired,
	user: object.isRequired,
	authenticated: bool.isRequired
};

const mapState = (state) => ({
	session: state.user,
	user: state.session.user,
	authenticated: state.session.authenticated,
	isAdminView:state.allInvestments.isAdminView
});

const mapDispatch = (dispatch) => {
	return {
		actions: bindActionCreators(sessionActions, dispatch)
	};
};

export default withRouter(connect(mapState, mapDispatch)(SidebarContainer));
