import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import { sanitizeExcerpt } from "../../../utils/data/wordpressPages";
import { Modal } from 'react-bootstrap'; 
import { ReactSVG } from "react-svg";

export class MapContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			center: { lat:29.7589382, lng: -95.3676974 },
			zoom: 5,
		data: [],
		allProjectsD:[],
		};
	}
	componentDidMount() {}

	componentWillReceiveProps(props) {
		this.setState({
			data: props.projectMapCollection,
			allProjectsD:props.allProjectsD,
			// center: {
			// 	...this.state.center,
			// 	lat: Number(props.projectMapCollection[0]?.coordinates[0]),
			// 	lng: Number(props.projectMapCollection[0]?.coordinates[1]),
			// },
		});
	}

	render() {
		const { data,allProjectsD } = this.state;
	 const userInvestedProjectsIDs =allProjectsD.map(item =>item.ProjectID);

	 const  onMapClick = (obj) =>{ 
		this.setState((state) => {

		let updateState =state.data?.map(item => {
			  return {...item,show:false} 
			});
		
			return { data:updateState };
					});
	}


		const onChildClickCallback = (key) => {
	this.setState((state) => {
	const index = state.data.findIndex((e) => e.id === key);
let updateState =state.data?.map(item => {
		if(item.id !=key)
       return {...item,show:false} 
	   else return item;
	});

	updateState[index].show = !updateState[index].show;
	return { data:updateState };
			});
		};
	

    	// InfoWindow component
		const InfoWindow = (props) => {
			const { content, sourceUrl } = props;
			const infoWindowStyle = {
				position: "relative",
				left: "-400px",
				width: 800,
				height: 800,
				boxShadow: "0 2px 7px 1px rgba(0, 0, 0, 0.3)",
				padding: 10,
				fontSize: 14,
				zIndex: 100,
			};

			return (
				<div style={infoWindowStyle}>
					{/* <div style={{ fontSize: 16 }}>
						<div
							dangerouslySetInnerHTML={{
								__html: sanitizeExcerpt(content, true),
							}}
						/>
					</div>
					<div className="mt-3">
						
						<img src={sourceUrl} />
					</div> */}
				</div>
			);
		};
		// Marker component
		const Marker = ({ show, content, sourceUrl }) => {
			const markerStyle = {
				border: "2px solid #5bb462",
				borderRadius: "50%",
				height: 20,
				width: 20,
				backgroundColor: show ? "#5bb462" : "#fff",
				cursor: "pointer",
				zIndex: 10,		
			};


			return (
				<>
					<div style={markerStyle} />
					{show && (
						<InfoWindow content={content} sourceUrl={sourceUrl} />
					)}
				</>
			);
		};
		const toggleMapModal = () => {
			this.setState((state) => {

				let updateState =state.data?.map(item => {
					  return {...item,show:false} 
					});
				
					return { data:updateState };
							});
		};
		




const documentIcon = "/img/document.svg";
const showSummaryofWells =data?.filter((item) =>userInvestedProjectsIDs?.some((elem) =>item?.ProjectedIDs?.toString()?.includes(elem)));
const isShowSummaryAvailable =showSummaryofWells?.map(item => !!item?.mapTableDetails[2]?.replace(/#038;/g,"")?.replace(/&#8221;/g,"")).includes(true);

	return (
			<div className="prj-update map-view w-100">

				{data.length > 0 &&  <>
				<h1>Fund Project Map</h1> 
				<div
					style={{
						height: "700px",
						width: "100%",
						position: "relative",
					}}
				>
					
					
							
							<GoogleMapReact onClick={onMapClick}
								bootstrapURLKeys={{
									key: "AIzaSyC8kbTOE0lBWFaB8Qa65HVQOeFSFGJDFtE",
								}}
								defaultCenter={this.state.center}
								defaultZoom={this.state.zoom}
								options={function (maps) {
									return { mapTypeId: "hybrid" };
								}}
								onChildClick={onChildClickCallback}
							
							>
								{data
									.filter((item) =>
										userInvestedProjectsIDs?.some((elem) =>
											item?.ProjectedIDs?.toString()?.includes(
												elem
											)
										)
									)
									?.map((place) => (
										<Marker
											key={place.id}
											lat={place?.coordinates[0]}
											lng={place?.coordinates[1]}
											show={place.show}
											content={place.content}
											sourceUrl={place.sourceUrl}
										/>
									))}
							</GoogleMapReact>
							
					
			
				</div></>	}
			
				{/* { isShowSummaryAvailable && <div className="well-list container py-5">
								<div className="col-md-3 pl-0 left">
									<h4 className="font-bold">
										Read the Summary<br/> of Our Wells
									</h4>
								</div>
								<div className="col-md-9 px-0">
									<ul className="list-unstyled">
										{showSummaryofWells?.map(item =>  !!item?.mapTableDetails[2]?.replace(/#038;/g,"")?.replace(/&#8221;/g,"") &&
										<li>
											 <a href={item?.mapTableDetails[2] && item?.mapTableDetails[2]?.replace(/#038;/g,"")} target="_blank">
											<ReactSVG src={documentIcon}  style={{height:"24px"}} />
											{item?.mapTableDetails[0] }
										</a>
										
									</li>
									
									)}
									
									</ul>
								</div>
							</div>
				} */}

				<Modal
show={data.some(item=>item.show)}
onHide={toggleMapModal}
style={{ width: '70%', margin: '0 auto' }} className="prj-update map-view"
>
<Modal.Header closeButton >
<div className="col-md-12 pt-0 d-flex w-100 align-items-center">
				{data?.filter(item=>item.show)?.map(item=> (	<>
	<div className="col-xs-7 col-md-7"><h3 className="title">{item?.mapTableDetails[0]}</h3></div>
	<div className="col-xs-5 col-md-5 text-right text-green font-bold my-2 py-2">{`${item?.mapTableDetails[1]},${item?.mapTableDetails[2]}`}</div></>))}</div>
</Modal.Header>
<Modal.Body>
	<div className="row m-0">
	{data?.filter(item=>item.show)?.map(item=> (	
	<><div className="col-md-12 grey-bg">
		
						<div
							dangerouslySetInnerHTML={{
								__html: sanitizeExcerpt(item?.content, true),
							}}
						/>
				
				
				</div>
			
				<div className="col-md-12 pt-3 d-flex w-100 align-items-center grey-bg">
				<div className="col-xs-7 col-md-7">
					<p className="d-flex align-items-center read-doc">{!item?.mapTableDetails[3]?.replace(/#038;/g,"")?.replace(/&#8221;/g,"") ?  <><ReactSVG src={documentIcon} alt="document" /> <span className=" pl-2 font-bold">Read More about the {item?.mapTableDetails[0]}</span></> : <><ReactSVG src={documentIcon} alt="document" /> <a className=" pl-2 font-bold" href ={item?.mapTableDetails[3]?.replace(/#038;/g,"")?.replace(/&#8221;/g,"")} target="_blank">Read More about the {item?.mapTableDetails[0]}</a></>}</p> 
					</div>
					<div className="col-xs-5 col-md-5 text-right text-green font-bold"><p>{item?.mapTableDetails[4]}</p> </div>
					</div>
				
					{item?.sourceUrl && <div className="col-md-12 mt-3 text-center">
						
						<img src={item?.sourceUrl} />
					</div>}	
					<div className="col-md-12  w-100  text-center text-green py-2 "><p className="m-0">Portions of this data retrieved from DrillingInfo</p></div>
				</>))}
	   </div>
</Modal.Body>
</Modal>				
			</div>
			
		);
		
	}
	
}

export default MapContainer;
