import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../../IconComponent/IconComponent';
import { ROCKET_DOLLAR_IRA_ACCOUNT, TRUST_ENTITY, IRA_OR_OTHER_RETIREMENT } from '../../../../utils/ApplicationConstants';
import {
    convertObjectToArray, getHash, isAllowedFileType, isCorrectFileSize,
} from '../../../../utils/General';
import NotificationService from '../../../../utils/NotificationService';
import SelectComponent from '../../InputComponents/SelectComponent/SelectComponent';



class DwollaDocUpload extends React.Component {
    isSingleOwner = () => {
        const { entityType } = this.props;

        return (entityType === TRUST_ENTITY || entityType === ROCKET_DOLLAR_IRA_ACCOUNT || entityType === IRA_OR_OTHER_RETIREMENT);
    };

    onControllerFileInputChange = (event) => {
        const { onControllerInputChange } = this.props;
        const { files } = event.target;
        const [selectedFile] = this.parseSelectedFiles(files);

        if (!selectedFile) return;

        onControllerInputChange(selectedFile);
    };

    onBusinessFileInputChange = (event) => {
        const { onBusinessInputChange } = this.props;
        const { files } = event.target;
        const selectedFiles = this.parseSelectedFiles(files);

        onBusinessInputChange(selectedFiles);
    };

    parseSelectedFiles = (fileObj) => {
        const acceptedFileTypes = ['jpg', 'jpeg', 'png', 'pdf'];
        const maxFileSize = 10;

        const files = convertObjectToArray(fileObj);
        const _files = files.filter(file => isCorrectFileSize(file, maxFileSize))
            .filter(file => isAllowedFileType(file, acceptedFileTypes));

        if (files.length !== _files.length) {
            NotificationService.warning(
                'Remember that the files accepted are: \n'
                + `${acceptedFileTypes.map(item => item.toUpperCase())
                    .join(',')}`
                + 'and the max size must be 10MB',
                10000,
            );
        }

        return _files;
    };

    getBusinessVerificationMessage = () => {
        const isSingleOwner = this.isSingleOwner();
        const singleOwnerInfo = 'One or more of the following, as applicable to your Entity: '
            + 'Fictitious Business Name Statement; Certificate of Assumed Name; Business License; Sales/Use Tax '
            + 'License; Registration of Trade Name; EIN documentation (IRS-issued SS4 confirmation letter); '
            + 'Color copy of a valid government-issued photo ID (e.g., a driver’s license, passport, or state ID card).';
        const multipleOwners = 'EIN Letter (IRS-issued SS4 confirmation letter) for LLC, Corporation or Partnership';

        return isSingleOwner ? singleOwnerInfo : multipleOwners;
    };

    // RENDER MANAGER
    renderSelectControllerVerificationFile = () => {
        const { verifyController, entityVerificationDocs, onEntityControllerFileType } = this.props;
        const { controllerFile, documentType } = entityVerificationDocs;
        const personalDocType = [
            {
                id: 'passport',
                title: 'Passport',
            }, {
                id: 'license',
                title: 'State issued driver’s license',
            }, {
                id: 'idCard',
                title: 'Other U.S. government-issued photo id card',
            },
        ];

        if (!verifyController) return null;
        return null;	// Added 12/16/2020 as a temporary fix to entities being able to upload document.

        return (
            <div className="col-xs-12">
                <h3>
                    Entity controller verification file required
                    {' '}
                    <label
                        className="btn btn-default btn-sm upper-case-text no-margin"
                        htmlFor="controllerVerificationFile"
                    >
                        <input
                            type="file"
                            className="no-display"
                            id="controllerVerificationFile"
                            onChange={this.onControllerFileInputChange}
                        />
                        <Icon iconName="plus" />
                        {' '}
                        add file
                    </label>
                </h3>

                <SelectComponent
                    selectTitle="Select the document type"
                    onChange={onEntityControllerFileType}
                    options={personalDocType}
                    value={documentType}
                    name="documentType"
                    sm={12}
                />

                <p>
                    A color scanned photo of the Controller’s identifying document can be specified as documentType:
                    passport, license (state issued driver’s license), or idCard (other U.S. government-issued photo
                    id
                    card).
                </p>

                {controllerFile && (
                    <div className="table-responsive">
                        <table className="table table-hover table-striped">
                            <thead>
                                <tr>
                                    <th>Selected File</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{controllerFile.name}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        );
    };

    renderSelectBusinessVerificationFiles = () => {
        const { verifyBusiness, verifyController } = this.props;

        if (!verifyBusiness) return null;

        return (
            <div className="col-xs-12">
                <h3>
                    Entity Business verification file required
                    {' '}
                    <label
                        className="btn btn-default btn-sm upper-case-text no-margin"
                        htmlFor="businessVerificationFile"
                    >
                        <input
                            type="file"
                            className="no-display"
                            id="businessVerificationFile"
                            onChange={this.onBusinessFileInputChange}
                            multiple
                        />
                        <Icon iconName="plus" />
                        {' '}
                        add files
                    </label>
                </h3>
                <p>{this.getBusinessVerificationMessage()}</p>
                <p className="text-danger">
                    {verifyController && 'Max 3 files'}
                    {!verifyController && 'Max 4 files'}
                </p>

                {this.renderSelectedFilesTable()}
            </div>
        );
    };

    renderSelectedFilesTable = () => {
        const { entityVerificationDocs } = this.props;

        if (!entityVerificationDocs.businessFiles.length) {
            return null;
        }

        return (
            <div className="table-responsive">
                <table className="table table-hover table-striped">
                    <thead>
                        <tr>
                            <th>Selected File</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderSelectedFilesTableElements()}
                    </tbody>
                </table>
            </div>
        );
    };

    renderSelectedFilesTableElements = () => {
        const { entityVerificationDocs, onRemoveBusinessFile } = this.props;

        return entityVerificationDocs.businessFiles.map(item => (
            <tr key={getHash([item.name, item.size])}>
                <td>{item.name}</td>
                <td>
                    <button
                        type="button"
                        onClick={() => onRemoveBusinessFile(item)}
                        className="btn btn-danger btn-sm upper-case-text no-margin"
                    >
                        <Icon iconName="times" />
                        {' '}
                        remove
                    </button>
                </td>
            </tr>
        ));
    };

    render = () => (
        <div className="container">
            <div className="vol-xs-12 col-sm-12 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3">
                {this.renderSelectControllerVerificationFile()}
                {this.renderSelectBusinessVerificationFiles()}
            </div>
        </div>
    );
}

DwollaDocUpload.propTypes = {
    entityType: PropTypes.string.isRequired,
    entityVerificationDocs: PropTypes.array.isRequired,
    onBusinessInputChange: PropTypes.func.isRequired,
    onControllerInputChange: PropTypes.func.isRequired,
    onEntityControllerFileType: PropTypes.func.isRequired,
    onRemoveBusinessFile: PropTypes.func.isRequired,
    verifyBusiness: PropTypes.bool.isRequired,
    verifyController: PropTypes.bool.isRequired,
};

DwollaDocUpload.defaultProps = {
    entityType: '',
    entityVerificationDocs: [],
    onBusinessInputChange: () => null,
    onControllerInputChange: () => null,
    onEntityControllerFileType: () => null,
    onRemoveBusinessFile: () => null,
    verifyBusiness: false,
    verifyController: false,
};

export default DwollaDocUpload;
