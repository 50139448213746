import React, { Component } from 'react';
import swal from 'sweetalert2';
import store from '../../index'/*added for blockchain*/
import { connect } from 'react-redux';/*added for blockchain*/
import PropTypes from 'prop-types';/*added for blockchain*/
import getDate from '../../utils/ConvertDate'
import {
    Link
} from 'react-router-dom'
import { withRouter } from 'react-router-dom';

class InvestmentDetails extends Component {
    constructor(props){
        super(props);
        this.state={
            projects:[],
            id:props.session.id,
            isLoaded:false,
            errorOnLoad:false
        };
        this.getinvestments=this.getinvestments.bind(this);
        this.onProjectClose=this.onProjectClose.bind(this);
        this.onProjectExtend=this.onProjectExtend.bind(this);
    }


    onProjectClose(){
        if(this.props.location.state.ProjectType==="RegD"){
            swal({
                title: 'Do you want to Close this Project?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Close'
              }).then((result) => {
                if (result.value) {

                    fetch('/api/getprojectinvestment/'+this.props.location.state.ProjectID+'/'+this.state.id+'/'+this.props.session.marketing,{
                        method:'get',
                    })
                        .then((response)=>{
                            return response.json()
                        })
                        .then((data)=>{
                            var formBody = []

                            formBody.push("IsCashCall=" + data[0].IsCashCall);
                            formBody.push("UserID=" + this.state.id);/*added for blockchain checkprojectFundedregD*/
                            formBody = formBody.join("&");
                            fetch('/api/closeprojectinvestment/'+this.props.location.state.ProjectID+'/'+this.state.id+'/'+this.props.session.marketing,{
                                method:'post',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/x-www-form-urlencoded'
                                },
                                body: formBody
                            })
                                .then((response)=>response.json())
                                .then((status)=>{
                                    if(status.code===200){
                                        swal(
                                            'Project Closed Successfully!',
                                            '',
                                            'success'
                                          )
                                          .then((value) => {
                                            this.props.history.push("/app/finalizeprojects");
                                          });

                                    }
                                })
                                .catch((err)=>{
                                    swal("Error to Close Project","","error");
                                    console.log(err)
                                })

                        })
                        .catch((err)=>{
                            swal("Error to Close Project","","error");
                            console.log(err)
                        })

                }
              })



        }
        else{

            swal({
                title: 'Do you want to Close this Project?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Close'
              }).then((result) => {
                if (result.value) {
                  swal(
                    'Project Closed Successfully!',
                    '',
                    'success'
                  )
                  .then((value) => {
                    fetch('/api/getprojectinvestmentCF/'+this.props.location.state.ProjectID+'/'+this.state.id+'/'+this.props.session.marketing,{
                        method:'get',
                    })
                        .then((response)=>{
                            return response.json()
                        })
                        .then((data)=>{
                            var formBody = []

                            formBody.push("IsCashCall=" + data[0].IsCashCall);
                            formBody.push("UserID=" + this.state.id);/*added for blockchain checkprojectFundedregCF*/
                            formBody = formBody.join("&");
                            fetch('/api/closeprojectinvestmentCF/'+this.props.location.state.ProjectID+'/'+this.state.id+'/'+this.props.session.marketing,{
                                method:'post',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/x-www-form-urlencoded'
                                },
                                body: formBody
                            })
                                .then((response)=>response.json())
                                .then((status)=>{
                                    if(status.code===200){
                                                this.props.history.push("/app/finalizeprojects");
                                    }
                                })
                                .catch((err)=>{
                                    swal("Error to Close Project",err,"error");
                                })

                        })
                        .catch((err)=>{
                            swal("Error to Close Project",err,"error");
                        })
                });
                }
              })
        }
    }
    async onProjectExtend() {
        const {value: name} = await swal({
            title: 'Number of Days to Extend',
            input: 'number',
            inputPlaceholder: 'Ex.10',
            showCancelButton: true,
            inputValidator: (value) => {
              return (!(((value)/0) === (1/0))) && 'The input must be a number!'
            }
          })

          if (name) {
                    var formBody = [];
                    formBody.push("period=" + encodeURIComponent(name));
                    formBody.push("type=" + (this.props.location.state.ProjectType));
                    formBody.push("adminID=" + encodeURIComponent(this.state.id))/**added for blockchain*/
                    formBody = formBody.join("&");

                    //do fetch call
                    fetch('/api/extend/'+this.props.location.state.ProjectID+'/'+this.state.id+'/'+this.props.session.marketing,{
                        method:'post',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        body: formBody
                    })
                        .then((response)=>response.json())
                        .then((status)=>{
                            if(status.code===200){
                                swal("Funding Period extended successfully","","success")
                            }

                        })
                        .catch((err)=>{
                            swal("Error ","","error");
                        })
          }
        // swal("Enter Number of days to Extend:", {
        //     content: "input",
        // })
        //     .then((value) => {
        //         if (((value)/0) === (1/0)) {

        //         }
        //         else {
        //             swal("Input must be a Number")
        //         }
        //     })
        //     .catch((err)=>{
        //         swal("Error ","","error");
        //     })
    }
    getinvestments(id){
        fetch('/api/getuserinvestments/'+this.props.location.state.ProjectID+'/'+this.state.id+'/'+this.props.session.marketing,{
            method:'get'
        })
            .then((response)=>{
                return response.json()
            })
            .then((data)=>{
                this.setState({
                    projects:data.data,
                    isLoaded:true,
                    err:{},
                    errorOnLoad:false,
                });
            })
            .catch((err)=>{
                this.setState({
                    err:err,
                    errorOnLoad:true
                })
            })

    }

    getinvestmentsCF(id){
        fetch('/api/getuserinvestmentsCF/'+this.props.location.state.ProjectID+'/'+this.state.id+'/'+this.props.session.marketing,{
            method:'get'
        })
            .then((response)=>{
                return response.json()
            })
            .then((data)=>{
                this.setState({
                    projects:data.data,
                    isLoaded:true,
                    err:{},
                    errorOnLoad:false,
                });
            })
            .catch((err)=>{
                this.setState({
                    err:err,
                    errorOnLoad:true
                })
            })

    }

    componentWillMount(){
        window.scrollTo(0, 0);
        if(this.props.location.state.ProjectType==="RegD"){
            this.getinvestments();
        }
        else{
            this.getinvestmentsCF();
        }

    }

    render() {
        return(
            <div className="container body">
                <div className="main_container">
                    <div className="right_col" role="main" style={{minHeight: 3753}}>
                        <div className>
                            <div className="page-title">
                                <div className="title_left">
                                    <h3>Investment Details</h3>
                                </div>
                            </div>
                            <div className="clearfix" />
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <div className="x_panel">
                                        <div className="x_title">

                                            <div className="clearfix" />
                                        </div>
                                        <div className="x_content">
                                            <div id="datatable_wrapper" className="dataTables_wrapper form-inline dt-bootstrap no-footer">

                                                <div className="row">
                                                    <div className="col-sm-12">
                                                    <div className="table-responsive">
                                                        <table className="table table-striped table-bordered dataTable no-footer">
                                                            <thead>
                                                            <tr className="headings">
                                                                <th className="column-title">First Name</th>
                                                                <th className="column-title">Last Name</th>
                                                                <th className="column-title">Email</th>
                                                                <th className="column-title">Entity Name</th>
                                                                <th className="column-title">Investment Type</th>
                                                                <th className="column-title">Investment Amount</th>
                                                                <th className="column-title">Investment Date</th>
                                                                <th className="bulk-actions" colSpan={7}>
                                                                    <a  className="antoo" style={{color: '#fff', fontWeight: 500}}>Bulk Actions ( <span className="action-cnt"> </span> ) <i className="fa fa-chevron-down" /></a>
                                                                </th>
                                                            </tr>
                                                            </thead>
                                                            {(() => {
                                                            switch (this.state.projects.length<1) {
                                                            case false: return (
                                                            <tbody>
                                                            {
                                                                this.state.projects.map((project)=>(
                                                                    <tr key={project.UserInvestmentID} className="even pointer">
                                                                        <td>{project.FirstName}</td>
                                                                        <td>{project.LastName}</td>
                                                                        <td>{project.Email}</td>
                                                                        <td>{project.Entity}</td>
                                                                        {(() => {
                                                                            switch (project.IsCashCall) {
                                                                                case 0  : return (<td>Normal Investment</td>);
                                                                                case 1  : return (<td>CashCall Investment</td>);
                                                                                default : return (<td>Normal Investment</td>);
                                                                            }
                                                                        })()}
                                                                        <td>{project.InvestmentAmount}</td>
                                                                        <td>{getDate.getDate(project.InvestmentDate)}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                            </tbody>
                                                            );
                                                            case true: return (
                                                                <tbody>
                                                                  <tr  className="even pointer">
                                                                    <td colSpan="7" className="text-center">Nothing to show</td>
                                                                  </tr>
                                                                </tbody>
                                                                      );
                                                                    }
                                                            })()}
                                                        </table>
                                                        </div>
                                                        <td><button  className="btn btn-success" onClick={this.onProjectClose}>Close</button></td>
                                                        <td><button  className="btn btn-success" onClick={this.onProjectExtend}>Extend Investment</button></td>
                                                        <td><button  className="btn btn-success" >Manage</button></td>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }
}

const { object, bool } = PropTypes;

InvestmentDetails.propTypes = {
    session: object.isRequired,
    authenticated: bool.isRequired,
    checked: bool.isRequired
};

const mapState = ({ session }) => ({
    session: session.user,
    checked: session.checked,
    authenticated: session.authenticated
});

export default withRouter(connect(mapState)(InvestmentDetails));
