import React from 'react';
import PreviewElement from './PreviewElementComponent';

const FilePreviewComponent = (props) => {
    const {fileList, deleteFile, target} = props;

    // console.log('FilePreviewComponent props = ', props);

    const preViewList = fileList
        .map((file, key) => (
                <PreviewElement key={key}
                                file={file}
                                deleteFile={deleteFile}
                                target={target}/>
            )
        );

    return (
        <div className="table-responsive">
            <table className="table table-striped table-hover jambo_table bulk_action">
                <thead>
                <tr>
                    <th className="center">File name</th>
                    <th className="center">File size</th>
                    <th className="center">File type</th>
                    <th className="center">Action</th>
                </tr>
                </thead>
                <tbody>
                {preViewList}
                </tbody>
            </table>
        </div>
    );
};

export default FilePreviewComponent;
