import RequestService from '../../../../api/RequestService';
import { HTTP_ERR_BAD_REQUEST } from '../../../../utils/ApplicationConstants';
import NotificationService from '../../../../utils/NotificationService';
import { getUUID } from '../../../../utils/General';

export const PROJECT_DETAILS_REQ_START = 'PROJECT_DETAILS_REQ_START';
export const PROJECT_DETAILS_REQ_END = 'PROJECT_DETAILS_REQ_END';
export const PROJECT_DETAILS_GET_DETAILS_SUCCESS = 'PROJECT_DETAILS_GET_DETAILS_SUCCESS';
export const PROJECT_DETAILS_VERIFY_SIGN_CA = 'PROJECT_DETAILS_VERIFY_SIGN_CA';
export const PROJECT_DETAILS_GET_UPDATE_SUCCESS = 'PROJECT_DETAILS_GET_UPDATE_SUCCESS';
export const PROJECT_DETAILS_GET_COMMENTS_SUCCESS = 'PROJECT_DETAILS_GET_COMMENTS_SUCCESS';

const endRequest = () => ({ type: PROJECT_DETAILS_REQ_END });
const startRequest = () => ({ type: PROJECT_DETAILS_REQ_START });
const getDetailsSuccess = data => ({
    type: PROJECT_DETAILS_GET_DETAILS_SUCCESS,
    data,
});
const verifySignCASuccess = data => ({
    type: PROJECT_DETAILS_VERIFY_SIGN_CA,
    data,
});
const getUpdateByIdSuccess = data => ({
    type: PROJECT_DETAILS_GET_UPDATE_SUCCESS,
    data,
});

const allCommentsReqSuccess = data => ({
    type: PROJECT_DETAILS_GET_COMMENTS_SUCCESS,
    data,
});

const ERROR_MESSAGE_DISPLAY_TIME = 6000;

export const setDefaultSignCAData = () => verifySignCASuccess('_');




export function getProjectDetails(projectId, projectType) {
    return async (dispatch) => {
        dispatch(startRequest());

        const URL = `/api/project/${projectId}/details/${projectType}`;
        const { data, status } = await RequestService.get(URL);

        // console.log('asdf2', data);
        if (status >= HTTP_ERR_BAD_REQUEST) {
            dispatch(endRequest());
            NotificationService.error(
                'Error extracting the details of the project',
                ERROR_MESSAGE_DISPLAY_TIME,
            );
            return;
        }

        dispatch(getDetailsSuccess(data));
    };
}



export function verifySignedCA(investmentId) {
    return async (dispatch) => {
        dispatch(startRequest());
        const { data, status } = await RequestService.get(`/api/checkSignedCA/${investmentId}`);

        if (status >= HTTP_ERR_BAD_REQUEST) {
            dispatch(endRequest());
            NotificationService.error(
                'Error verification CA sign',
                ERROR_MESSAGE_DISPLAY_TIME,
            );
            return;
        }

        const { signed } = data;

        dispatch(verifySignCASuccess(signed));
    };
}



export function performCASign(investmentId, investorSign) {
    return async (dispatch) => {
        const requestObj = {
            ProjectID: investmentId,
            signedName: investorSign,
        };

        dispatch(startRequest());
        const { status } = await RequestService.post({
            url: '/api/saveSignedCA',
            data: requestObj,
        });

        dispatch(endRequest());
        if (status >= HTTP_ERR_BAD_REQUEST) {
            NotificationService.error(
                'Error storing CA sign',
                ERROR_MESSAGE_DISPLAY_TIME,
            );
            return;
        }

        dispatch(verifySignedCA(investmentId));
    };
}



export function getUpdateById(updateId) {
    return async (dispatch) => {
        dispatch(startRequest());
        const { data, status } = await RequestService.get(`/api/getProjectNotificationByID/${updateId}`);

        if (status >= HTTP_ERR_BAD_REQUEST) {
            dispatch(endRequest());
            NotificationService.error(
                'Error extracting the details for this update',
                ERROR_MESSAGE_DISPLAY_TIME,
            );
            return;
        }

        const [update] = data.notification;

        update.key = getUUID();

        dispatch(getUpdateByIdSuccess(update));
    };
}



export function getPublicProject(projectId, projectType) {
    return async (dispatch) => {
        const URL = `/api/public/project/${projectId}/details/type/${projectType}`;

        dispatch(startRequest());
        const { data, status } = await RequestService.get(URL);

        if (status >= HTTP_ERR_BAD_REQUEST) {
            dispatch(endRequest());
            NotificationService.error(
                'Error extracting the details of the project',
                ERROR_MESSAGE_DISPLAY_TIME,
            );
            return;
        }

        dispatch(getDetailsSuccess(data));
    };
}



export function getAllProjectComments(projectId, isLoggedInUser) {
    return async (dispatch) => {
        const signUserUrl = `/api/project/${projectId}/comments/all`;
        const publicUrl = `/api/public/project/${projectId}/comments/all`;
        const url = isLoggedInUser ? signUserUrl : publicUrl;

        try {
            dispatch(startRequest());
            const { data } = await RequestService.get(url);

            dispatch(allCommentsReqSuccess(data.data));
        } catch (error) {
            console.log('Error extracting the project comments: ERROR: ', error);
            NotificationService.error(
                'Error extracting the comments',
                ERROR_MESSAGE_DISPLAY_TIME,
            );
            dispatch(endRequest());
        }
    };
}



export function getCommentReplies(projectId, commentId, commentList, position, isLoggedInUser) {
    return async (dispatch) => {
        const loggedUserUrl = `/api/project/${projectId}/comment/${commentId}/replies`;
        const publicUrl = `/api/public/project/${projectId}/comment/${commentId}/replies`;
        const url = isLoggedInUser ? loggedUserUrl : publicUrl;

        try {
            dispatch(startRequest());
            const { data } = await RequestService.get(url);
            const commentListCopy = [...commentList];
            const objAtIndex = commentListCopy[position];

            objAtIndex.replyList = data;
            commentListCopy[position] = objAtIndex;

            dispatch(allCommentsReqSuccess(commentListCopy));
        } catch (error) {
            console.log('Error extracting replies for comment: ERROR: ', error);
            dispatch(endRequest());
            NotificationService.error(
                'Error ',
                ERROR_MESSAGE_DISPLAY_TIME,
            );
        }
    };
}



export function addNewPost(commentText, projectId, parentCommentId, isLoggedInUser, cleanUp = () => null) {
    return async (dispatch) => {
        const url = `/api/project/${projectId}/comment`;

        try {
            dispatch(startRequest());
            await RequestService.post({
                url,
                data: {
                    CommentText: commentText,
                    parentCommentId,
                },
            });

            dispatch(endRequest());
            NotificationService.success();
            dispatch(getAllProjectComments(projectId, isLoggedInUser));
            cleanUp();
        } catch (error) {
            console.log('Error creating the new comment');
            dispatch(endRequest());
            NotificationService.error(
                'Error adding the new comment',
                ERROR_MESSAGE_DISPLAY_TIME,
            );
        }
    };
}
