import React from 'react';
import { NavItem, Nav, Navbar } from 'react-bootstrap';



export default function NavBar(props) {
	if (props.isMarketplace) {
		return(
			<Navbar inverse collapseOnSelect style={{ backgroundColor: '#5bb462', 'border': 'none', borderRadius: '0' }}>
				<Navbar.Header>
					<div className="col-md-12">
					<Navbar.Brand>
						<img src="/img/ef-footer-logo.png" alt="logo" style={{ backgroundColor: '#5bb462', width: "auto"}}/>
					</Navbar.Brand>
					{/* <Navbar.Toggle style={{marginRight:"10px"}}/> */}
					</div>
				</Navbar.Header>
				{/* <Navbar.Collapse>
					<Nav pullRight>
						<NavItem eventKey={1} href="https://www.efmarketplace.com" rel="noopener noreferrer" className="violetBacground">
							Home
						</NavItem>
						<NavItem eventKey={4} href="https://www.efmarketplace.com/education" target="_blank" rel="noopener noreferrer" className="violetBacground">
							Education Center
						</NavItem>
					</Nav>
				</Navbar.Collapse> */}
			</Navbar>
		);
	}
	else {
		return(
			<Navbar inverse collapseOnSelect style={{ backgroundColor: '#5bb462', 'border': 'none', borderRadius: '0' }}>
				<Navbar.Header>
				<div className="col-md-12">
					<Navbar.Brand>
						<img src="/img/ef-footer-logo.png" alt="logo" style={{ backgroundColor: '#5bb462', width: "auto" }}/>
					</Navbar.Brand>
					{/* <Navbar.Toggle style={{marginRight:"10px"}}/> */}
					</div>
				</Navbar.Header>
				{/* <Navbar.Collapse>
					<Nav pullRight>
						<NavItem eventKey={1} href="https://www.energyfunders.com" rel="noopener noreferrer" className="violetBacground">
							Home
						</NavItem>
						<NavItem eventKey={2} href="https://www.energyfunders.com/investments" target="_blank" rel="noopener noreferrer" className="violetBacground">
							Browse Investments
						</NavItem>
						<NavItem eventKey={3} href="https://www.energyfunders.com/for-advisors" target="_blank" rel="noopener noreferrer" className="violetBacground">
							For Advisors
						</NavItem>
						
						<NavItem eventKey={5} href="https://www.energyfunders.com/our-company" target="_blank" rel="noopener noreferrer" className="violetBacground">
							About Us
						</NavItem>
					</Nav>
				</Navbar.Collapse> */}
			</Navbar>
		);
	}
}
