import React from 'react';
import PropTypes from 'prop-types'
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {PROJECT_REG_CF, PROJECT_REG_D} from '../../../utils/ApplicationConstants';
import * as ReturnOfCapitalActions from './actions/actions';
import {getUUID} from "../../../utils/General";

class ReturnOfCapitalContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            projectType: PROJECT_REG_D,
            selectedProject: 0,
            internalNotes: '',
            returnAmount: 0.0,
            projectList: [],
        };

        // refs
        this.refProjectType = React.createRef();
        this.refSelectedProject = React.createRef();
        this.refReturnAmount = React.createRef();
        this.refInternalNotes = React.createRef();
    }

    componentDidMount() {
        const {getAllProjects, user} = this.props;
        getAllProjects(user);
    }

    onSelectChangeHandler = (event) => {
        const {name, value} = event.target;
        this.setState({[name]: value});

        if (name === 'projectType' && value === PROJECT_REG_D) {
            this.setState({projectList: this.props.regDProjects});
        } else if (name === 'projectType' && value === PROJECT_REG_CF) {
            this.setState({projectList: this.props.regCfProjects});
        }
    };

    onInputChangeHandler = (event) => {
        let {name, value, type} = event.target;

        if (type === 'number')
            value = parseFloat(value);

        this.setState({[name]: value});
    };

    onSubmitReturnCapital = (event) => {
        event.preventDefault();

        const {projectType, selectedProject, returnAmount, internalNotes} = this.state;
        if (selectedProject > 0 && returnAmount > 0) {
            const body = {projectType, returnAmount, internalNotes};
            const {storeReturnedCapital} = this.props;

            storeReturnedCapital(body, selectedProject, this.resetValues);
        }
        else {
            return false;
        }
    };

    resetValues = () => {
        this.setState({
            selectedProject: "0",
            internalNotes: '',
            returnAmount: 0.0,
            projectList: [],
        });

        this.refProjectType.current.value = 0;
        this.refSelectedProject.current.value = "0";
        this.refReturnAmount.current.value = null;
        this.refInternalNotes.current.value = null;

        this.props.updateOnSuccessfulRecord();
        this.props.closeModal();
    };

    disableSubmitButton = () => {
        const {selectedProject, returnAmount, projectType} = this.state;
        const hasNoSelectedType = (projectType === 0);
        const hasNoReturnAmount = (!returnAmount || (returnAmount <= 0) || (returnAmount.toString().split('').indexOf('e') !== -1));
        const hasNoSelectedProject = (selectedProject === "0");

        return (hasNoReturnAmount || hasNoSelectedProject || hasNoSelectedType)
    };

    renderRegDOptions = () => {
        const {projectList, projectType} = this.state;

        if (projectType !== PROJECT_REG_D)
            return null;

        return projectList.map(project => (
            <option key={getUUID()}
                    value={project.ProjectID}>
                ({project.Entity}) {project.ProjectName}
            </option>
        ));
    };

    renderRegCfOptions = () => {
        const {projectList, projectType} = this.state;

        if (projectType !== PROJECT_REG_CF)
            return null;

        return projectList.map(project => (
            <option key={getUUID()}
                    value={project.ProjectID}>
                {project.InvestmentName}
            </option>
        ));
    };

    render = () => {
        return (
            <div className="container">
                <div className="col-sm-12 col-sm-1 col-md-3 col-lg-4"/>
                <div className="col-sm-12 col-sm-10 col-md-6 col-lg-4">
                    <div className="panel-body form-horizontal payment-form">
                        <form onSubmit={this.onSubmitReturnCapital}>
                            <div className="form-group">
                                <label htmlFor="status"
                                       className="col-sm-3 col-md-3 col-xs-12 control-label">
                                    Project Type:
                                </label>
                                <div className="col-sm-9 col-md-9 col-xs-12">
                                    <select id="status"
                                            name="projectType"
                                            className="form-control"
                                            ref={this.refProjectType}
                                            onChange={this.onSelectChangeHandler}>
                                        <option value={0} disabled selected>Select a Project Type</option>
                                        <option value={PROJECT_REG_CF}>Regulation CF</option>
                                        <option value={PROJECT_REG_D}>Regulation D</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="status"
                                       className="col-sm-3 col-md-3 col-xs-12 control-label">
                                    Project Name:
                                </label>
                                <div className="col-sm-9 col-md-9 col-xs-12">
                                    <select id="status"
                                            name="selectedProject"
                                            className="form-control"
                                            ref={this.refSelectedProject}
                                            onChange={this.onSelectChangeHandler}
                                            value={this.state.selectedProject}>
                                        <option value="0" disabled selected>Select a Project</option>
                                        {this.renderRegDOptions()}
                                        {this.renderRegCfOptions()}
                                    </select>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="concept"
                                       className="col-sm-3 col-md-3 col-xs-12 control-label">
                                    Amount:
                                </label>
                                <div className="col-sm-9 col-md-9 col-xs-12">
                                    <input type="text"
                                           placeholder="0.00"
                                           name="returnAmount"
                                           className="form-control"
                                           ref={this.refReturnAmount}
                                           onKeyUp={this.onInputChangeHandler}
										   style={{border: '1px solid #ccc', borderRadius: 0}}
									/>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="description"
                                       className="col-sm-3 col-md-3 col-xs-12 control-label">
                                    Internal Notes:
                                </label>
                                <div className="col-sm-9 col-md-9 col-xs-12">
                                    <textarea name="internalNotes" className="form-control"
											  ref={this.refInternalNotes}
											  onKeyUp={this.onInputChangeHandler}
											  rows="4"
									/>
                                </div>
                            </div>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignContent: 'center',
                            }}>
                                <button type="button"
                                        className="btn btn-danger"
                                        style={{width: "100px"}}
                                        onClick={this.props.closeModal}>
                                    Cancel
                                </button>
                                <button type="submit"
                                        className="btn btn-primary"
                                        style={{width: "100px"}}
                                        disabled={this.disableSubmitButton()}>
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    };
}

ReturnOfCapitalContainer.propTypes = {
    regCfProjects: PropTypes.array.isRequired,
    regDProjects: PropTypes.array.isRequired,
    getAllProjects: PropTypes.func.isRequired,
    storeReturnedCapital: PropTypes.func.isRequired,
    updateOnSuccessfulRecord: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
};

ReturnOfCapitalContainer.defaultProps = {
    regCfProjects: [],
    regDProjects: [],
    updateOnSuccessfulRecord: () => null,
    closeModal: () => null,
};

const mapStateToProps = (_state) => {
    return {
        ..._state.returnOfCapital,
        user: _state.session.user,
    };
};

const mapDispatchToProps = (_dispatch) => {
    const dispatchObj = bindActionCreators(ReturnOfCapitalActions, _dispatch);
    return {...dispatchObj};
};

const connectedComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(ReturnOfCapitalContainer);

export default withRouter(connectedComponent);
