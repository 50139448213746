import PropTypes from 'prop-types'
import React from 'react';
import {Modal, Col} from 'react-bootstrap';
import NotificationService from "../../../../../utils/NotificationService";

class SignCAComponent extends React.Component {
    INPUT_SIZE_ERROR_DISPLAY_TIME = 6000;

    constructor(props) {
        super(props);

        this.state = {
            isSignButtonDisabled: true,
        };
    }

    onInputChange = (event) => {
        const {value} = event.target;

        this.props.onInputChange(event);

        if (this.isCorrectInput(value))
            this.setState({isSignButtonDisabled: false});
        else
            this.setState({isSignButtonDisabled: true});
    };

    performCASign = () => {
        const {isSignButtonDisabled} = this.state;
        if (!isSignButtonDisabled)
            this.props.performCASign();
        else
            NotificationService.error("You must write your full name in the field",
                this.INPUT_SIZE_ERROR_DISPLAY_TIME);
    };

    isCorrectInput = (investorCASign) => {

        console.log(investorCASign.toString().length, (investorCASign.toString().length > 5), investorCASign !== '', (investorCASign !== '') &&
            (investorCASign.toString().length > 5));

        return ((investorCASign !== '') &&
            (investorCASign.toString().length > 5));
    };

    render = () => {
        return (
            <Modal className="no-padding"
                   show>
                <Modal.Header>
                    <Modal.Title>Confidentiality Agreement</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container"
                         style={{lineHeight: '150%', fontSize: '15px'}}>
                        <Col xs={12} sm={12} md={12} lg={12}/>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <strong>
                                This agreement appears only once per project. Please read carefully and agree to
                                continue.
                            </strong>
                            <br/><br/>
                            <div>
                                The following page and files accessed on such page constitute Confidential Information,
                                and any unauthorized disclosure or use of such Confidential Information may cause
                                irreparable harm the parties which own such Confidential Information. By accessing this
                                page and/or the files available for download, you agree to treat all documentary, image,
                                data, and other Confidential Information confidentially and not to, and to cause any
                                spouse or co-investor who accesses such information through or from you, not to disclose
                                to others; use for any purpose other than to decide whether to make an investment in the
                                project set forth on the page; use for any purpose that is detrimental or adverse to
                                EnergyFunders, the operator, or other parties involved in the project set forth on the
                                page; or otherwise disclose any such information.
                                <br/><br/>
                                You further agree not make any copies of the Confidential Information other than for
                                your personal files relating to your investment in the project listed on the page;
                                directly or indirectly, attempt to reverse-engineer; decipher; use such Confidential
                                Information in any way related to other projects, prospects, or properties; or take
                                other similar actions with respect to the Confidential Information.
                                <br/><br/>
                                The above restrictions on Confidential Information shall not apply to Confidential
                                Information that: (i) is or becomes publicly known through no wrongful act of you; or
                                (ii) is, at the time of disclosure already known to you without restriction on
                                disclosure.
                                <br/><br/>
                                The Confidential Information is typically received from project parties or other sources
                                and is provided "AS IS" and without any express or implied representations or warranties
                                regarding accuracy, operability, use or fitness for a particular purpose.
                            </div>
                            <hr/>
                            <div>
                                Please Type Your Name Here:
                                <br/>
                                <input onChange={this.onInputChange}
                                       className="form-control"
                                       name="investorCASign"
                                       type="text"/>
                            </div>
                            <br/>
                            <div style={{display: 'flex', justifyContent: 'left'}}>
                                <button className="btn btn-primary capitalize-text"
                                        disabled={this.state.isSignButtonDisabled}
                                        onClick={this.performCASign}
                                        type="button">
                                    I agree
                                </button>
                                <button className="btn btn-default capitalize-text"
                                        onClick={this.props.goBack}
                                        type="button">
                                    go back
                                </button>
                            </div>
                            <br/>
                        </Col>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };
}

SignCAComponent.propTypes = {
    goBack: PropTypes.func.isRequired,
    onInputChange: PropTypes.func.isRequired,
    performCASign: PropTypes.func.isRequired
};

SignCAComponent.defaultProps = {
    goBack: () => null,
    onInputChange: () => null,
    performCASign: () => null
};

export default SignCAComponent;
