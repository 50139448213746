import PropTypes from 'prop-types'
import React from 'react';
import ProjectDetailTabsHeader from "./ProjectDetailTabsHeader";

class ProjectDetailTabsWrapper extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mappedComponents: {},
            headerComponentList: [],

            selectedTab: 0,
            selectedChildren: null,
        }
    }

    componentDidMount() {
        const {defaultTabIndex} = this.props;

        this.initComponent(defaultTabIndex);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const _children = JSON.stringify(prevProps.children.map(this.getTabProps));
        const children = JSON.stringify(this.props.children.map(this.getTabProps));

        if (children !== _children) {
            this.initComponent();
        }
    }

    getTabProps = (item) => {
        if (!item.props) return {};

        const {tabId, tabName, tabOrder, tabContent} = item.props;
        return {tabId, tabName, tabOrder, tabContent};
    };


    initComponent = () => {
        const mappedComponents = {};
        const headerComponentList = [];
        const {children} = this.props;

        if (!children || !children.length) return null;

        const lastIndex = (children.length - 1);
        let defaultIndex = this.props.defaultTabIndex;

        if (defaultIndex > lastIndex) {
            defaultIndex = lastIndex;
        }

        if (defaultIndex <= -1) {
            defaultIndex = 0
        }

        for (let item of children) {
            const {tabId, tabName, tabOrder} = item.props;
            const headerObject = {
                id: tabId,
                title: tabName.toString().toUpperCase(),
                tab: tabOrder,
            };

            mappedComponents[tabId] = item;
            headerComponentList.push(headerObject);
        }

        const a = children[defaultIndex];
        const {tabId} = a.props;
        this.setState({mappedComponents, headerComponentList},
            () => this.setSelectedChildren(tabId));
    };

    setSelectedChildren = (tab) => {
        this.setParentDefaultIndex(tab);
        this.setState({
            selectedChildren: this.state.mappedComponents[tab],
            selectedTab: tab,
        }, this.setTabHeaderTitle);
    };

    setTabHeaderTitle = () => {
        const { setSelectedTabTitle } = this.props;
        const { selectedChildren } = this.state;

        setSelectedTabTitle(selectedChildren.props.tabName);
    };

    setParentDefaultIndex = (tabId) => {
        const {headerComponentList} = this.state;
        const selectedTab = headerComponentList.find(tab => tab.id === tabId);
        const tabIndex = headerComponentList.indexOf(selectedTab);

        this.props.setDefaultIndex(tabIndex);
    };

    render = () => {
        return (
            <div className="container project-details-table-container margin-top-30px">
                <ProjectDetailTabsHeader menuItems={this.state.headerComponentList}
                                         selectedTab={this.state.selectedTab}
                                         onTabSelected={this.setSelectedChildren}/>
                <div className="project-details-table-body">
                    {this.state.selectedChildren}
                </div>
            </div>
        );
    };
}

ProjectDetailTabsWrapper.propTypes = {
    children: PropTypes.array.isRequired,
    defaultTabIndex: PropTypes.number.isRequired,
    setDefaultIndex: PropTypes.func.isRequired,
    setSelectedTabTitle: PropTypes.func.isRequired,
    tabId: PropTypes.any,
    tabName: PropTypes.any,
    tabOrder: PropTypes.any
};

ProjectDetailTabsWrapper.defaultProps = {
    children: [],
    defaultTabIndex: 0,
    setDefaultIndex: () => null,
    setSelectedTabTitle: () => null,
};

export default ProjectDetailTabsWrapper;
