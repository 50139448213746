import PropTypes from 'prop-types'
import React from 'react';
import Icon from "../../../IconComponent/IconComponent";

const HighlightComponent = (props) => {
    const {highlight, onDeleteHighlight, onUpdateHighlight, showActions} = props;
    const {highlightName, highlightImageName, highlightDescription} = highlight;
    return (
        <div className="highlight-container">
            <div className="highlight-icon">
                <Icon iconName={highlightImageName}
                      extraClasses="highlight-container-image-container-image-size"/>
            </div>
            <div className="highlight-title">
                <span className="heading-text">{highlightName}</span>
            </div>
            <p className="highlight-data">
                {highlightDescription}
            </p>

            {showActions &&
            <div className="dropdown">
                <button className="btn btn-link dropdown-toggle upper-case-text"
                        data-toggle="dropdown"
                        type="button">
                    Actions <Icon iconName="chevron-down"/>
                </button>
                <ul className="dropdown-menu">
                    <li onClick={() => onDeleteHighlight(highlight)}>
                        <a className="btn-default">
                            Delete
                        </a>
                    </li>
                    <li onClick={() => onUpdateHighlight(highlight)}>
                        <a className="btn-default">
                            Update
                        </a>
                    </li>
                </ul>
            </div>}

        </div>
    );
};

HighlightComponent.propTypes = {
    highlight: PropTypes.object.isRequired,
    onDeleteHighlight: PropTypes.func.isRequired,
    onUpdateHighlight: PropTypes.func.isRequired,
    showActions: PropTypes.bool.isRequired
};

HighlightComponent.defaultProps = {
    highlight: {},
    onDeleteHighlight: () => null,
    onUpdateHighlight: () => null,
    showActions: false
};

export default HighlightComponent;
