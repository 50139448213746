import axios from "axios";
import * as RegD from "./RegDActions";
import * as RegCF from "./RegCFActions";
import NotificationService from "../../../../utils/NotificationService";
import {REQUEST_GET_PROJECT_UPDATE_BY_ID_SUCCESS, SET_SELECTED_PROJECT_UPDATE} from "./ProjectUpdateConstants";
import {HTTP_ERR_BAD_REQUEST, PROJECT_REG_D} from "../../../../utils/ApplicationConstants";

const MESSAGE_DURATION = 3000;

export function setSelectedProjectUpdate(data) {
    return {type: SET_SELECTED_PROJECT_UPDATE, data};
}

export default function getProjectUpdateByIdSuccess(data) {
    return {type: REQUEST_GET_PROJECT_UPDATE_BY_ID_SUCCESS, data}
}

export function getAllProjectUpdates(userData) {
    return (dispatch) => {
        dispatch(RegCF.fetchAllRegCFUpdates(userData));
        dispatch(RegD.fetchAllRegDUpdates(userData));
    }
}

export function approveProjectUpdate(type, approvalData, userData) {
    return async (dispatch) => {
        const startRequest = verifyType(type, RegD.startRegDHttpRequest, RegCF.startRegCFHttpRequest);
        dispatch(startRequest());

        const {projectID, notificationID} = approvalData;
        const {id, marketing} = userData;
        const reqPayload = {
            projectType: type,
            NotificationID: notificationID,
        };

        const URL = `/api/sendnotifications/${projectID}/${id}/${marketing}`;
        const response = await axios.post(URL, reqPayload);
        const successMessage = "Project update was approved!";
        const errorMessage = response.data.NoOfUsers === 0 ?
            "Error, project must have users assigned" :
            "Error when executing the request";

        updateDataAfterRequest(response, dispatch, type, userData, successMessage, errorMessage);
    }
}

export function rejectProjectUpdate(type, notificationID, userData) {
    return async (dispatch) => {
        const startRequest = verifyType(type, RegD.startRegDHttpRequest, RegCF.startRegCFHttpRequest);
        dispatch(startRequest());
        const {id, marketing} = userData;

        const URL = `/api/rejectnotification/${notificationID}/${id}/${marketing}`;
        const response = await axios.get(URL);
        const errorMessage = "Error when rejecting the project update";

        updateDataAfterRequest(response, dispatch, type, userData, null, errorMessage);
    }
}

export function deleteProjectUpdate(type, notificationID, userData) {
    return async (dispatch) => {
        const startRequest = verifyType(type, RegD.startRegDHttpRequest, RegCF.startRegCFHttpRequest);
        dispatch(startRequest());
        const {id, marketing} = userData;

        const URL = `/api/deletenotification/${notificationID}/${id}/${marketing}`;
        const response = await axios.get(URL);
        const errorMessage = "Error when deleting the project update";

        updateDataAfterRequest(response, dispatch, type, userData, null, errorMessage);
    }
}

export function restoredDeletedProjectUpdate(type, notificationID, userData) {
    return async (dispatch) => {
        const startRequest = verifyType(type, RegD.startRegDHttpRequest, RegCF.startRegCFHttpRequest);
        dispatch(startRequest());
        const {id, marketing} = userData;

        const URL = `/api/undeletenotification/${notificationID}/${id}/${marketing}`;
        const response = await axios.get(URL);
        const errorMessage = "Error when restoring the project update";

        updateDataAfterRequest(response, dispatch, type, userData, null, errorMessage);
    }
}

export function getNotificationById(type, notificationId, userData) {
    return async (dispatch) => {
        dispatch(verifyRequestStart(type));
        const response = await axios.get(`/api/getnotifications/${notificationId}/${userData.id}/${userData.marketing}`);
        const {data} = response;

        if (data.code >= HTTP_ERR_BAD_REQUEST) {
            dispatch(verifyRequestEnd(type));
            NotificationService.error(`Error requesting the project update with ID: ${notificationId}`);
            return;
        }
        dispatch(verifyRequestEnd(type));
        dispatch(getProjectUpdateByIdSuccess(data.data));
    }
}

export function getProjectAndNotification(type, notificationId, userData) {
    return async (dispatch) => {
        const URL = `/api/projects/u/${userData.id}/h/${userData.marketing}/project-type/${type}/notification/${notificationId}`
        dispatch(verifyRequestStart(type));

        const response = await axios.get(URL);
        const {status, data} = response;

        if (status >= HTTP_ERR_BAD_REQUEST) {
            dispatch(verifyRequestEnd(type));
            NotificationService.error("Error in the request");
            return;
        }

        dispatch(verifyRequestEnd(type));
        dispatch(getProjectUpdateByIdSuccess(data));
    };
}

export function saveNewProjectUpdate(type, userData, requestPayload, redirectMethod) {
    return (dispatch) => {
        dispatch(verifyRequestStart(type));
        const saveRequest = verifyType(type, RegD.storeNewProjectUpdateRegD, RegCF.storeNewProjectUpdateRegCF);
        dispatch(saveRequest(userData, requestPayload, redirectMethod));
    }
}

export function performNotificationUpdate(type, userData, requestPayload, redirect) {
    return async (dispatch) => {
        dispatch(verifyRequestStart(type));

        const URL = `/api/updateprojectnotification/${userData.id}/${userData.marketing}`;
        const response = await axios.post(URL, requestPayload);
        const {data} = response;

        if (data.code >= HTTP_ERR_BAD_REQUEST) {
            NotificationService.error(
                "Error executing the update, please cantact support for more information",
                MESSAGE_DURATION
            );
            dispatch(verifyRequestEnd(type));
            return;
        }

        dispatch(verifyRequestEnd(type));
        NotificationService.success(
            "The Update was completed successfully!",
            MESSAGE_DURATION
        );
        redirect();
    }
}

export function sendTestEmail(type, userData, payload) {
    return async (dispatch) => {
        dispatch(verifyRequestEnd(type));
        const {id, marketing} = userData;
        const URL = `/api/u/${id}/h/${marketing}/email/send/test`;
        const response = await axios.post(URL, payload);
        const {data} = response;

        if (data.code >= HTTP_ERR_BAD_REQUEST) {
            const endRequest = verifyType(type, RegD.endRegDHttpRequest, RegCF.endRegCFHttpRequest);
            NotificationService.error("Error when sending the test email");
            dispatch(endRequest());
            return;
        }

        const endRequest = verifyType(type, RegD.endRegDHttpRequest, RegCF.endRegCFHttpRequest);
        NotificationService.success("Test Email Sent.", 5000);
        dispatch(endRequest());
    };
}

export function updateDisplayStatus(type, payload, userData) {
    return async (dispatch) => {
        const {id, marketing} = userData;
        const URL = `/api/u/${id}/h/${marketing}/project/updates`;

        dispatch(verifyRequestStart(type));
        const response = await axios.put(URL, payload);
        const successMessage = "Display Status Updated Correctly!";
        const errorMessage = "Error Updating the Display Status";

        updateDataAfterRequest(response, dispatch, type, userData, successMessage, errorMessage);
    }
}

// utils
const updateDataAfterRequest = (response, dispatch, type, userData, successMessage = '', errorMessage = '') => {
    const {code} = response.data;

    if (code >= HTTP_ERR_BAD_REQUEST) {
        const endRequest = verifyType(type, RegD.endRegDHttpRequest, RegCF.endRegCFHttpRequest);
        NotificationService.error(errorMessage);
        dispatch(endRequest());
        return;
    }

    const endRequest = verifyType(type, RegD.endRegDHttpRequest, RegCF.endRegCFHttpRequest);
    dispatch(getAllProjectUpdates(userData));
    NotificationService.success(successMessage);
    dispatch(endRequest());
};

const verifyRequestStart = (type) => {
    return verifyType(type, RegD.startRegDHttpRequest, RegCF.startRegCFHttpRequest);
};

const verifyRequestEnd = (type) => {
    return verifyType(type, RegD.endRegDHttpRequest, RegCF.endRegCFHttpRequest);
};

const verifyType = (type, regDObj, regCFObj) => {
    if (type === PROJECT_REG_D)
        return regDObj;
    else
        return regCFObj;
};
