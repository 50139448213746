import React, { Component } from "react";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as AllInvestmentActions from "../shared/AllInvestmentsContainerComponent/actions/actions";
import { PROJECT_REG_D } from "../../utils/ApplicationConstants";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import "./css/investmentOpportunities.css";
import { ReactSVG } from "react-svg";
import OurTeam from "../shared/OurTeam/OurTeam";
import { Yield_Fund_2_ID } from '../../utils/General';

class YieldFund2 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			projects: [],
			selectedProjectCategory: "all",
		};
	}

	componentDidMount() {
		const { getAllInvestments } = this.props;
		getAllInvestments(this.state.selectedProjectCategory);
		window.scrollTo(0,0);
	}

	onSeeDetailsClickHandler = () => {
		const { push } = this.props.history;
		const { activeRegDInvestments } = this.props.allInvestments;
		const pushStateObj = {
			state: {
				id: activeRegDInvestments[0]?.projectID,
				projectType: PROJECT_REG_D,
			},
			pathname: "/app/project/details",
		};
		push(pushStateObj);
	};
	render() {
		const agreement = "/img/investmentopportunities/agreement.png";
	const documentIcon = "/img/document.svg";
		const  ProjectUpdatesIcon = "/img/investmentopportunities/oilwell.svg";
		// const wildcatFundOverview = "/img/investmentopportunities/wildcat-fund-overview.png";
		// const wildcatFundSummary = "/img/investmentopportunities/wildcat-fund-summary.png";
		return (
			<div className="container body">
				<div className="main_container">
					<div
						className="right_col invest-opportunities-detail"
						role="main"
					>
						<div className="col-md-12 col-sm-12 col-xs-12 px-0">
							<h2>Yield Fund II </h2>
						</div>

						<div className="col-md-12 col-sm-12 col-xs-12 px-0">
							<div className="col-md-8 v-line pr-md-5 pl-0">
								<p>
								Yield Fund II follows closely in the footsteps of our flagship fund Yield Fund I. While our initial fund included wellhead bitcoin mining, Yield Fund II will be solely focused on oil and gas assets to take advantage of the strong commodity price environment (relative to bitcoin price).
									<br />
									<br />Yield Fund II will deploy capital into wellbore investment opportunities, which may include drilling or recompleting oil and gas wells along with outright purchases of working interests in wells that are already producing. We plan to invest this capital primarily in prospects planned in areas with proven, economic production while also hedging risk by investing in currently producing wells. We will further limit the concentration risks associated with single well oil and gas prospects by including multiple, diversified assets in this fund.
									<br />
									<br />Producing wells can have a long and stable production profile and provide immediate cash flow. Furthermore, these wells sometimes have untapped behind-pipe potential that has yet to be produced. Proven undeveloped targets are located near existing, economically productive wells. This provides the critical data needed for our team of oil and gas professionals to have confidence in the geology and reservoir characteristics of offset drilling locations. 
									<br />
									<br />Take advantage of our team’s extensive oil and gas experience and proven track record by investing in Yield Fund II today!
									<br />
									<br />For Yield Fund II, we will be offering two share classes within the same fund. Investors will choose which share class to invest in and will even be able to split each investment between share classes should they choose to do so. Share classes are as follows:
									<ul>
										<li>
											<h3>Class A</h3>
											<ul>
												<li>Liability capped at members’ total investment amount </li>
												<li>Potential tax deductions against passive income</li>
											</ul>
										</li>
										<li>
											<h3>Class B</h3>
											<ul>
												<li>Individual members are liable for their proportionate share of all of the Fund’s debts and liabilities</li>
												<li>Potential tax deductions against active income</li>
											</ul>
										</li>
									</ul>



								</p>
							</div>
							<div className="col-md-4 pl-md-5 px-0">
								<div className="pl-md-5 w-75">
									<div className="mt-0">
										<h3>What Are You Investing In?</h3>
										<div className="progress">
											<div className="progress-bar d-flex flex-row align-items-center justify-content-center fill bold text-left py-3 px-3">
											<ReactSVG src="/img/investmentopportunities/oil.svg"  />
												<span className="pl-4">
													100% Oil &amp; Gas
												</span>
											</div>
										</div>
									</div>
									<div className="mt-5">
										<h3>Ready to Invest?</h3>
										<button
											className="btn btn-primary btn-green px-3 py-4 w-100 border-0 progress"
											onClick={ () =>   this.props.history.push({
												pathname: '/app/invest',
												state: {
													ProjectType: 'RegD',
													ProjectID: Yield_Fund_2_ID,
													
												}
											})
												
											}
										>
											Start Investing Now
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-12 col-sm-12 col-xs-12 px-0 mt-5">
							<h3>The Highlights</h3>
							<ul className="highlights list-unstyled d-flex">
								<li>
									<ReactSVG src="/img/time.svg" />
									<span>3-5 Years</span> Target Fund Life
								</li>
								<li>
									<ReactSVG src="/img/piechart.svg" />
									<span>Periodic</span> Distributions
								</li>
								<li>
									<ReactSVG src="/img/risklevel.svg" />
									<span>Lower</span> Risk Level
								</li>
								<li>
									<ReactSVG src="/img/money.svg" />
									<span>$5,000</span> Minimum Investment
								</li>
<br/>
								{/* <li>
									<ReactSVG src="/img/investmentopportunities/screen.svg" />
									<span>Investor Platform </span> See Well
									Videos, Pics &amp; Details on Your Funds
								</li> */}
								
							</ul>
							<ul className="highlights list-unstyled d-flex">
							<li>
									<ReactSVG src="/img/investmentopportunities/handshake.svg" />
									<span>Potential Liquidity </span> Via tZERO
									Trading Platform
								</li>
								<li>
									<ReactSVG src="/img/investmentopportunities/exclusive.svg" />
									<span>Exclusive Access</span> Private Market
									Opportunities
								</li>
								<li>
									<ReactSVG src="/img/investmentopportunities/certificate.svg" />
									<span>Technical Expertise</span> A Strong Vetting Process
								</li>
								<li>
									<ReactSVG src="/img/investmentopportunities/taxes.svg" />
									<span>Tax Savings</span> Potential
									Deductions Against Active Income
								</li>
							</ul>
						</div>
						<div className="col-md-12 col-sm-12 col-xs-12 px-0 mt-5 fee-detail">
							<h3>The Fees</h3>
							<div className="col-md-7">
								<table className="table table-striped jambo_table fee-table">
									<thead>
										<tr>
											<th>
												If the Capital Contribution is…
											</th>
											<th>
												The Origination Fee Shall be…
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>Less than $100,000</td>
											<td>
												5.00% of the Capital
												Contribution
											</td>
										</tr>
										<tr>
											<td>
												At least $100,000 but less than
												$250,000
											</td>
											<td>
												3.25% of the Capital
												Contribution
											</td>
										</tr>
										<tr>
											<td>
												At least $250,000 but less than
												$500,000
											</td>
											<td>
												2.50% of the Capital
												Contribution
											</td>
										</tr>
										<tr>
											<td>
												At least $500,000 but less than
												$1,000,000
											</td>
											<td>
												1.75% of the Capital
												Contribution
											</td>
										</tr>
										<tr>
											<td>
												Equal to or greater than
												$1,000,000
											</td>
											<td>
												1.00% of the Capital
												Contribution
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="col-md-5">
								<h4 className="mt-0">
									Assets Under Management Fee{" "}
								</h4>
								<p>
									2% assets under management fee calculated
									annually and charged monthly over the course
									of the year.
								</p>
								{/* <h4 className="mt-4">Distributions</h4>
								<p>
									All the profits of the company, after fees
									and other expenses, will be distributed as
									follows: First, to Investors until each
									Investor has received an internal rate of
									return of 15%. And second, 80% to Investors
									and 20% to the Manager.
								</p> */}
							</div>
						</div>
						<div className="col-md-12 col-sm-12 col-xs-12 px-0 mt-5 documents">
							<h3>The Documents</h3>
							<ul className="list-unstyled">
								<li>
									<div className="img-wrapper"  style={{backgroundImage: `url(${agreement})`}}></div>
									<h3>LLC Agreement</h3>
									<div className="mt-4"><a href="/documents/yieldfund2/llc-agreement-ef-yield-fund-ii-llc-final.pdf" target="_blank"><ReactSVG src={documentIcon} />View The Document</a></div>
								</li>
								<li>
									<div className="img-wrapper"  style={{backgroundImage: `url(${agreement})`}}></div>
									<h3>Disclosure Document</h3>
									<div className="mt-4"><a href="/documents/yieldfund2/disclosure-document-ef-yield-fund-ii-llc-final.pdf" target="_blank"><ReactSVG src={documentIcon} />View The Document</a></div>
								</li>
								<li>
									<div className="img-wrapper"  style={{backgroundImage: `url(${agreement})`}}></div>
									<h3>Investment Agreement</h3>
									<div className="mt-4"><a href="/documents/yieldfund2/investment-agreement-ef-yieldfund-ii-llc-final.pdf" target="_blank"><ReactSVG src={documentIcon} />View The Document</a></div>
								</li>
								{/* <li>
									<div className="img-wrapper"  style={{backgroundImage: `url(${wildcatFundSummary})`}}></div>
									<h3>Drilling Fund I LLC Two-page Summary</h3>
									<div className="mt-4"><a href="/documents/drillingfund/drillingfund-2-pager.pdf" target="_blank"><ReactSVG src={documentIcon} />View The Document</a></div>
								</li> */}
							</ul>
						</div>
					{/* <OurTeam/> */}
					<div className="col-md-12 col-sm-12 col-xs-12  my-5 wildcat-footer ">
<div className="pull-left d-flex align-items-center left-sec"><ReactSVG src={ProjectUpdatesIcon} /> <span>Yield Fund II </span></div>
<div className="pull-right rt-sec"><button className="btn btn-primary  px-5 py-4 ml-3 font-bold" onClick={ () =>   this.props.history.push({
												pathname: '/app/invest',
												state: {
													ProjectType: 'RegD',
													ProjectID: Yield_Fund_2_ID,
													
												}
											})
												
											}>
									Start Investing Today
										</button></div>
					</div>
					</div>
				</div>
			</div>
		);
	}
}

const { object, bool } = PropTypes;

YieldFund2.propTypes = {
	session: object.isRequired,
	allInvestments: object.isRequired,
};
const mapDispatchToProps = (dispatch) => {
	const allInvestmentActions = bindActionCreators(
		AllInvestmentActions,
		dispatch
	);
	return { ...allInvestmentActions };
};
const mapStateToProps = (_state) => ({
	session: _state.session.user,
	allInvestments: _state.allInvestments,
});

const connectedComponent = connect(
	mapStateToProps,
	mapDispatchToProps
)(YieldFund2);

export default withRouter(connectedComponent);
