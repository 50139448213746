import React, {Component} from 'react';
import swal from 'sweetalert';
import {withRouter} from 'react-router-dom';
import store from '../../index'
import {connect} from 'react-redux';
import PropTypes from 'prop-types';


class Preview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: Object.values(props.data),
            images: (props.images) ? (props.images) : ({}),
            type: (props.type) ? (props.type) : '',
            isLoaded: false,
            errorOnLoad: false
        };
    }

    delete(e, i) {
        this.props.deleteAction(i, this.state.type);
    }

    render() {
        return (
            <div className='table-responsive' style={{marginBottom: '20px'}}>
                <table className="table table-sm table-bordered" style={{textAlign: 'center'}}>
                    <thead>
                    <tr>
                        <th style={{textAlign: 'center'}}>S.NO.</th>
                        <th style={{textAlign: 'center'}}>Name</th>
                        <th style={{textAlign: 'center'}}>File</th>
                        <th style={{textAlign: 'center'}}>Status</th>
                        <th style={{textAlign: 'center'}}>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        Object.values(this.props.data).map((file, i) => {

                            return (
                                <tr key={i}>
                                    <td style={{height: 60, lineHeight: '60px'}}>{i + 1}</td>
                                    <td style={{
                                        height: 60,
                                        lineHeight: '60px'
                                    }}>{(file.fieldName) ? (file.originalFilename) : (file.name)}</td>
                                    <td style={{height: 60, lineHeight: '60px'}}>{
                                        (() => {
                                            if (file.fieldName) {
                                                if (file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/png') {
                                                    return (
                                                        <img
                                                            src={(!file.fieldName) ? ((this.state.images[i]) ? (this.state.images[i]) : ('/img/dummy.jpg')) : ((file.fieldName) ? (file.newPath) : ('/img/dummy.jpg'))}
                                                            width={100} height={50} alt=""/>
                                                    );
                                                } else {
                                                    return (
                                                        <img src="/img/dummy.jpg" width={100} height={50} alt=""/>
                                                    );
                                                }

                                            } else {
                                                return (
                                                    <img
                                                        src={(!file.fieldName) ? ((this.state.images[i]) ? (this.state.images[i]) : ('/img/dummy.jpg')) : ((file.fieldName) ? (file.newPath) : ('/img/dummy.jpg'))}
                                                        width={100} height={50} alt=""/>
                                                );
                                            }

                                        })()
                                    }</td>
                                    <td style={{
                                        height: 60,
                                        lineHeight: '60px'
                                    }}>{(!file.fieldName) ? 'Pending' : 'Uploaded'}</td>
                                    <td style={{height: 60, lineHeight: '60px'}}>
                                        {
                                            (() => {
                                                if (!file.fieldName) {
                                                    return (
                                                        <button type="button" className="btn btn-default btn-sm"
                                                                onClick={(e) => this.delete(e, i)}>
                                                            <span className="glyphicon glyphicon-trash"></span>
                                                        </button>
                                                    );
                                                }
                                            })()
                                        }

                                    </td>
                                </tr>
                            )
                        })
                    }

                    </tbody>
                </table>

            </div>
        );
    }
}



class Adminaddproject_d extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saved: "",
            form: {
                ProjectName: '',
                companyname: '',
                operations: '',
                name: '',
                position: '',
                address: '',
                phonenumber: '',
                email: '',
                drilling: '',
                honest: '',
                revenue: '',
                raise: '',
                leases: '',
                list: '',
                permits: '',
                hear: '',
            },
            ProjectID: "",
            id: props.session.id,
            isLoaded: false,
            errorOnLoad: false,
            pertinentMaps: {},
            afeOperations: {},
            additionalMaterials: {},
            pertinentMapsFiles: {},
            afeOperationsFiles: {},
            additionalMaterialsFiles: {},
            pertinentMapsImages: {},
            afeOperationsImages: {},
            additionalMaterialsImages: {}
        };

        this.onSave = this.onSave.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.fileUploading = this.fileUploading.bind(this);
        this.onChangeFiles = this.onChangeFiles.bind(this);
        this.filesUpload = this.filesUpload.bind(this);
        this.onDeleteFile = this.onDeleteFile.bind(this);
    }



	componentDidMount() {
		window.scrollTo(0, 0);
		if (this.props.location.state !== undefined) {
			fetch('/api/getsingleproject/' + this.props.location.state.ProjectID + '/' + this.state.id + '/' + this.props.session.marketing, {
				method: 'get',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/x-www-form-urlencoded'
				},
			})
				.then((response) => {
					return response.json()
				})
				.then((data) => {
					if (data.code === 200) {
						this.refs.ProjectName.value = data.rows[0].ProjectName;
						this.refs.OperatingCompany.value = data.rows[0].ProjectName;
						this.refs.CompanyName.value = data.rows[0].CompanyName;
						this.refs.MailingAddress.value = data.rows[0].MailingAddress;
						this.refs.ContactName.value = data.rows[0].ContactName;
						this.refs.ContactPosition.value = data.rows[0].ContactPosition;
						this.refs.ContactPhoneNumber.value = data.rows[0].ContactPhoneNumber;
						this.refs.ContactFaxNumber.value = data.rows[0].ContactFaxNumber;
						this.refs.ContactEmail.value = data.rows[0].ContactEmail;
						this.refs.ReworkingPlan.value = data.rows[0].ReworkingPlan;
						this.refs.ProjectRisk.value = data.rows[0].ProjectRisk;
						this.refs.RevenueProjections.value = data.rows[0].RevenueProjections;
						this.refs.WorkingInterestDescription.value = data.rows[0].WorkingInterestDescription;
						this.refs.PrepareLeaseTitle.value = data.rows[0].PrepareLeaseTitle;
						this.refs.PrincipalsList.value = data.rows[0].PrincipalsList;
						this.refs.DoesHavePermits.value = data.rows[0].DoesHavePermits;
						this.refs.EFsource.value = data.rows[0].EFsource;
					}
					this.setState({
						saved: data.rows[0].saved
					})
				})
				.catch((err) => {
					alert("Error getting saved projects" + JSON.stringify(err))
				})
		}
	}



	getImage(File) {
        return new Promise(function (resolve, reject) {
            let reader = new FileReader();
            reader.onload = function () {
                resolve(reader.result)
            };
            reader.readAsDataURL(File);
        });
    }



    onDeleteFile(i, type) {
        let data = {};

        switch (type) {
            case 'maps':
                data = Object.values(this.state.pertinentMaps).filter((item, j) => (i != j));
                break;
            case 'afe':
                data = Object.values(this.state.afeOperations).filter((item, j) => (i != j));
                break;
            case 'addmat':
                data = Object.values(this.state.additionalMaterials).filter((item, j) => (i != j));
                break;

            default:
                break;
        }

        let detail = {};
        if (data !== undefined) {
            Object.assign(detail, data);
        }

        this.onChangeFiles({target: ''}, type, detail);

    }



    checkFileType(files, fileType) {
        return new Promise(function (resolve, reject) {
            let dat = {};
            switch (fileType) {
                case 'maps':
                    dat = Object.values(files).filter(function (object) {
                        if ((object['type'] === "image/jpeg") || (object['type'] === "image/png") || (object['type'] === "image/gif")) {
                            return true;
                        } else {
                            console.log('Only images allowed');
                        }
                    });
                    resolve(Object.assign({}, dat));
                    break;
                case 'afe':
                    dat = Object.values(files).filter(function (object) {
                        if ((object['type'] === "application/pdf") || (object['type'] === "application/docx") || (object['type'] === "application/doc") || (object['type'] === "application/txt")) {
                            return true;
                        } else {
                            console.log('Only Documents allowed');
                        }
                    });
                    resolve(Object.assign({}, dat));
                    break;
                case 'addmat':
                    dat = Object.values(files).filter(function (object) {
                        if ((object['type'] === "application/pdf") || (object['type'] === "application/docx") || (object['type'] === "application/doc") || (object['type'] === "application/txt") || (object['type'] === "image/jpeg") || (object['type'] === "image/png") || (object['type'] === "image/gif")) {
                            return true;
                        } else {
                            console.log('Not allowed this file type ' + object['type']);
                        }
                    });
                    resolve(Object.assign({}, dat));
                    break;

                default:
                    reject({});
                    break;
            }
        });
    }

    async onChangeFiles(e, type = '', records = '-1') {
        let file = await e.target;
        let fileType = await (type == '') ? file.id : type;
        let files = await (records == '-1') ? file.files : records;
        console.log(fileType);
        console.log(files);
        let data = {};
        this.checkFileType(files, fileType).then((files) => {
            for (let i = 0; i < Object.values(files).length; i++) {
                this.getImage(Object.values(files)[i]).then((image) => {
                    data[i] = image;
                    switch (fileType) {
                        case 'maps':
                            this.setState({
                                pertinentMapsImages: data,
                            });
                            break;
                        case 'afe':
                            this.setState({
                                afeOperationsImages: data,
                            });
                            break;
                        case 'addmat':
                            this.setState({
                                additionalMaterialsImages: data,
                            });
                            break;

                        default:
                            console.log('Unknown error');
                            break;
                    }
                });
            }
        });

        switch (fileType) {
            case 'maps':
                await this.checkFileType(files, fileType).then((data) => {
                    this.setState({
                        pertinentMapsFiles: {},
                        pertinentMapsImages: {},
                        pertinentMaps: data,
                    });
                })

                break;
            case 'afe':
                await this.checkFileType(files, fileType).then((data) => {
                    this.setState({
                        afeOperationsFiles: {},
                        afeOperationsImages: {},
                        afeOperations: data,
                    });
                });
                break;
            case 'addmat':
                await this.checkFileType(files, fileType).then((data) => {
                    this.setState({
                        additionalMaterialsFiles: {},
                        additionalMaterialsImages: {},
                        additionalMaterials: data,
                    });
                });
                break;

            default:
                await console.log('Unknown error');
                break;
        }
        await console.log(this.state);
    }

    isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    }

    async filesUpload(e) {
        e.preventDefault();
        let fileType = e.target.id;
        var obj = {};
        switch (fileType) {
            case 'pertinentMaps':
                obj = await this.state.pertinentMaps;
                break;
            case 'afeOperations':
                obj = await this.state.afeOperations;
                break;
            case 'additionalMaterials':
                obj = await this.state.additionalMaterials;
                break;

            default:
                await console.log('file Upload check error');
                break;
        }
        await console.log(obj);
        await console.log(fileType);
        if (!obj) {
            await alert('Please select files to upload');
            await console.log('Empty data');
        } else {
            console.log('checking...');
            this.fileUploading(obj, fileType);
        }
    }

    async fileUploading(file, fileType) {
        var formData = new FormData();
        var headers = Object.assign({},
            /* { 'content-type': 'application/json' }, */
            {'Content-Type': 'multipart/form-data'}
        );
        Object.keys(headers)
            .forEach(function (k) {
                if (k.toLowerCase() === 'content-type') delete headers[k]
            })
        console.log(file);
        for (var key in file) {
            if (file.hasOwnProperty(key)) {
                formData.append('data', file[key]);
            }
        }
        formData.append('type', 'regD');

        fetch('/api/uploadRemote', {
            method: 'post',
            /* headers: {'content-type': 'multipart/form-data'}, */
            headers: headers,
            body: formData
        })
            .then((response) => response.json())
            .then((data) => {
                console.log('asdfghjk');

                switch (fileType) {
                    case 'pertinentMaps':
                        this.setState({
                            pertinentMapsFiles: data
                        });
                        break;
                    case 'afeOperations':
                        this.setState({
                            afeOperationsFiles: data
                        });
                        break;
                    case 'additionalMaterials':
                        this.setState({
                            additionalMaterialsFiles: data
                        });
                        break;

                    default:
                        console.log('file Upload check error');
                        break;
                }
                console.log(data, "uploaded data");
            })
    }

    async inputChange(e) {
        let property = e.target.id;
        let value = e.target.value;
        let error = '';
        let prev = await this.state.form;
        prev[property] = await value;
        await this.setState({
            form: prev
        })
        error = this.inputValidation(property, value);
        document.getElementById('error_' + property).innerHTML = error;
    }

    inputValidation(property, value) {
        let error = '';
        switch (property) {
            case 'City':
                if (value < 0) {
                    error = 'Please select City';
                }
                break;
            default:
                if (value == '' || value < 0) {
                    error = 'This field is Required';
                }
                break;
        }
        return error;
    }

    fullFormValidation() {
        let checkFields = 0;
        let object = this.state.form;
        for (const key in object) {
            if (object.hasOwnProperty(key)) {
                let error = this.inputValidation(key, object[key]);
                document.getElementById('error_' + key).innerHTML = error;
                if (error != '') {
                    checkFields = 1;
                }
            }
        }
        return checkFields;
    }



    onsubmitcheck(e, Status) {
        e.preventDefault();
        if (Status) {
            let checkFields = 1;
            checkFields = this.fullFormValidation();
            if (checkFields) {
                swal("Please Fill all the fields", "", 'info');
            } else {
                this.onupdate(Status);
            }
        } else {
            this.onupdate(Status);
        }
    }

    onupdate(Status) {


        var formBody = [];
        formBody.push("UserID=" + encodeURIComponent(this.state.id));
        formBody.push("ProjectName=" + encodeURIComponent(this.refs.ProjectName.value));
        formBody.push("OperatingCompany=" + encodeURIComponent(this.refs.OperatingCompany.value));
        formBody.push("CompanyName=" + encodeURIComponent(this.refs.CompanyName.value));
        formBody.push("MailingAddress=" + encodeURIComponent(this.refs.MailingAddress.value));
        formBody.push("ContactName=" + encodeURIComponent(this.refs.ContactName.value));
        formBody.push("ContactPosition=" + encodeURIComponent(this.refs.ContactPosition.value));
        formBody.push("ContactPhoneNumber=" + encodeURIComponent(this.refs.ContactPhoneNumber.value));
        formBody.push("ContactFaxNumber=" + encodeURIComponent(this.refs.ContactFaxNumber.value));
        formBody.push("ContactEmail=" + encodeURIComponent(this.refs.ContactEmail.value));
        formBody.push("ReworkingPlan=" + encodeURIComponent(this.refs.ReworkingPlan.value));
        formBody.push("ProjectRisk=" + encodeURIComponent(this.refs.ProjectRisk.value));
        formBody.push("RevenueProjections=" + encodeURIComponent(this.refs.RevenueProjections.value));
        formBody.push("WorkingInterestDescription=" + encodeURIComponent(this.refs.WorkingInterestDescription.value));
        formBody.push("PrepareLeaseTitle=" + encodeURIComponent(this.refs.PrepareLeaseTitle.value));
        formBody.push("PrincipalsList=" + encodeURIComponent(this.refs.PrincipalsList.value));
        formBody.push("DoesHavePermits=" + encodeURIComponent(this.refs.DoesHavePermits.value));
        formBody.push("EFsource=" + encodeURIComponent(this.refs.EFsource.value));
        formBody.push("ProjectStatus=" + encodeURIComponent(Status));
        formBody.push("ProjectID=" + encodeURIComponent(this.props.location.state.ProjectID));

        formBody = formBody.join("&");
        //do fetch call
        fetch('/api/updateProjectD/' + this.state.id + '/' + this.props.session.marketing, {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: formBody
        })
            .then((response) => response.json())
            .then((status) => {
                if (status.code === 200) {
                    swal(status.success, "", "success")
                        .then((value) => {
                            this.props.history.push('/app/Myprojects');
                        });

                } else if (status.code === 402) {
                    alert("Access Denied");
                } else if (status.code === 400) {
                    alert("Error occured");
                }
            })
            .catch((err) => {
                alert("Error: " + JSON.stringify(err))
            })
    }

    onSave(e) {
        //build form body
        e.preventDefault();

        var formBody = [];
        formBody.push("UserID=" + encodeURIComponent(this.state.id));
        formBody.push("ProjectName=" + encodeURIComponent(this.refs.ProjectName.value));
        formBody.push("OperatingCompany=" + encodeURIComponent(this.refs.OperatingCompany.value));
        formBody.push("CompanyName=" + encodeURIComponent(this.refs.CompanyName.value));
        formBody.push("MailingAddress=" + encodeURIComponent(this.refs.MailingAddress.value));
        formBody.push("ContactName=" + encodeURIComponent(this.refs.ContactName.value));
        formBody.push("ContactPosition=" + encodeURIComponent(this.refs.ContactPosition.value));
        formBody.push("ContactPhoneNumber=" + encodeURIComponent(this.refs.ContactPhoneNumber.value));
        formBody.push("ContactFaxNumber=" + encodeURIComponent(this.refs.ContactFaxNumber.value));
        formBody.push("ContactEmail=" + encodeURIComponent(this.refs.ContactEmail.value));
        formBody.push("ReworkingPlan=" + encodeURIComponent(this.refs.ReworkingPlan.value));
        formBody.push("ProjectRisk=" + encodeURIComponent(this.refs.ProjectRisk.value));
        formBody.push("RevenueProjections=" + encodeURIComponent(this.refs.RevenueProjections.value));
        formBody.push("WorkingInterestDescription=" + encodeURIComponent(this.refs.WorkingInterestDescription.value));
        formBody.push("PrepareLeaseTitle=" + encodeURIComponent(this.refs.PrepareLeaseTitle.value));
        formBody.push("PrincipalsList=" + encodeURIComponent(this.refs.PrincipalsList.value));
        formBody.push("DoesHavePermits=" + encodeURIComponent(this.refs.DoesHavePermits.value));
        formBody.push("EFsource=" + encodeURIComponent(this.refs.EFsource.value));

        formBody = formBody.join("&");

        //do fetch call
        fetch('/api/saveprojectD/' + this.state.id + '/' + this.props.session.marketing, {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: formBody
        })
            .then((response) => response.json())
            .then((status) => {
                if (status.code === 200) {
                    swal("Project Saved successfully", "", "success")
                        .then((value) => {
                            this.props.history.push('/app/Allinvestments');
                        });

                } else if (status.code === 402) {
                    alert("Access Denied");
                } else if (status.code === 400) {
                    alert("Error occured");
                }
            })
            .catch((err) => {
                alert("Error: " + JSON.stringify(err))
            })
    }

    onSubmit(e) {
        //build form body
        e.preventDefault();
        let checkFields = 1;
        checkFields = this.fullFormValidation();
        if (checkFields) {
            console.log('Please check all fields');
        } else {
            var formBody = [];
            formBody.push("UserID=" + encodeURIComponent(this.state.id));
            formBody.push("ProjectName=" + encodeURIComponent(this.refs.ProjectName.value));
            formBody.push("OperatingCompany=" + encodeURIComponent(this.refs.OperatingCompany.value));
            formBody.push("CompanyName=" + encodeURIComponent(this.refs.CompanyName.value));
            formBody.push("MailingAddress=" + encodeURIComponent(this.refs.MailingAddress.value));
            formBody.push("ContactName=" + encodeURIComponent(this.refs.ContactName.value));
            formBody.push("ContactPosition=" + encodeURIComponent(this.refs.ContactPosition.value));
            formBody.push("ContactPhoneNumber=" + encodeURIComponent(this.refs.ContactPhoneNumber.value));
            formBody.push("ContactFaxNumber=" + encodeURIComponent(this.refs.ContactFaxNumber.value));
            formBody.push("ContactEmail=" + encodeURIComponent(this.refs.ContactEmail.value));
            formBody.push("ReworkingPlan=" + encodeURIComponent(this.refs.ReworkingPlan.value));
            formBody.push("ProjectRisk=" + encodeURIComponent(this.refs.ProjectRisk.value));
            formBody.push("RevenueProjections=" + encodeURIComponent(this.refs.RevenueProjections.value));
            formBody.push("WorkingInterestDescription=" + encodeURIComponent(this.refs.WorkingInterestDescription.value));
            formBody.push("PrepareLeaseTitle=" + encodeURIComponent(this.refs.PrepareLeaseTitle.value));
            formBody.push("PrincipalsList=" + encodeURIComponent(this.refs.PrincipalsList.value));
            formBody.push("DoesHavePermits=" + encodeURIComponent(this.refs.DoesHavePermits.value));
            formBody.push("EFsource=" + encodeURIComponent(this.refs.EFsource.value));
            formBody.push("pertinentMaps=" + encodeURIComponent(JSON.stringify(this.state.pertinentMapsFiles)));
            formBody.push("afeOperations=" + encodeURIComponent(JSON.stringify(this.state.afeOperationsFiles)));
            formBody.push("additionalMaterials=" + encodeURIComponent(JSON.stringify(this.state.additionalMaterialsFiles)));

            formBody = formBody.join("&");

            //do fetch call
            fetch('/api/addprojectD/' + this.state.id + '/' + this.props.session.marketing, {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: formBody
            })
                .then((response) => response.json())
                .then((status) => {
                    if (status.code === 200) {
                        swal("Project added successfully", "", "success")
                            .then((value) => {
                                this.props.history.push('/app/Allinvestments');
                            });

                    } else if (status.code === 402) {
                        alert("Access Denied");
                    } else if (status.code === 400) {
                        alert("Error occured");
                    }
                })
                .catch((err) => {
                    alert("Error: " + JSON.stringify(err))
                })
        }
    }

    render() {
        return (
            <div className="container body">
                <div className="main_container">
                    <div className="right_col" role="main">
                        <div className="page-content container-fluid" style={{paddingBottom: 20}}>
                            <div className="">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    {/*-<h3 class="page-title"><div style="color:#EC1010;font-size:20px;font-weight:400;letter-spacing:0px">Due to very high demand, we're not currently evaluating new projects at this time.  At the first opportunity, we will let you know when we are ready to evaluate your new project.  Thank you for your patience and your interest in EnergyFunders!</div></small></h3> */}
                                    <h3 className="page-title">
                                        <div style={{
                                            color: '#333',
                                            fontSize: 18,
                                            fontWeight: 400,
                                            letterSpacing: 0
                                        }}>Edit your pending project here. Once you have finalized your project details
                                            below, click submit to send your project to us for approval. Once approved,
                                            your project details will no longer be editable.
                                        </div>
                                    </h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <div className="portlet" id="form_wizard_1">
                                        <div className="portlet-title">
                                            <div className="caption">Submit Your Project (not a binding offer)<span
                                                className="step-title"/></div>
                                            <div className="tools hidden-xs"><a href="javascript:;"
                                                                                className="collapse"/></div>
                                        </div>
                                        <div className="portlet-body form">
                                            <form action="" id="project_operator" method="post"
                                                  encType="multipart/form-data">
                                                <div className="form-wizard">
                                                    <div className="form-body">
                                                        <div className="tab-content">
                                                            {/* <div className="alert alert-danger display-none"><button className="close" data-dismiss="alert" /> You have some form errors. Please check below.</div>
                                                            <div className="alert alert-success display-none"><button className="close" data-dismiss="alert" /> Your form validation is successful!</div> */}
                                                            <div className="tab-pane active" id="tab1">
                                                                <div className="portlet-body form">
                                                                    <div className="form-body">
                                                                        <div className="row">
                                                                            <div
                                                                                className="col-md-12 col-sm-12 col-xs-12">
                                                                                <div className="form-group">
                                                                                    <label htmlFor="ProjectName">Project
                                                                                        Nickname</label>
                                                                                    <input type="text"
                                                                                           name="ProjectName"
                                                                                           ref="ProjectName"
                                                                                           id="ProjectName"
                                                                                           className="form-control"
                                                                                           onBlur={(e) => this.inputChange(e)}
                                                                                           onChange={(e) => this.inputChange(e)}/>
                                                                                    {/* <button class="btn blue" type="submit">Save Project</button> */}
                                                                                    <span id="error_ProjectName"
                                                                                          className="text-danger"/>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div
                                                                                className="col-md-4 col-sm-12 col-xs-12">
                                                                                <div className="form-group">
                                                                                    <label className="control-label">Your
                                                                                        Company Name</label>
                                                                                    <input type="text"
                                                                                           name="CompanyName"
                                                                                           id="companyname"
                                                                                           ref="CompanyName"
                                                                                           className="form-control"
                                                                                           onBlur={(e) => this.inputChange(e)}
                                                                                           onChange={(e) => this.inputChange(e)}/>
                                                                                    <span id="error_companyname"
                                                                                          className="text-danger"/>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className="col-md-8 col-sm-12 col-xs-12">
                                                                                <div className="form-group">
                                                                                    <label className="control-label">Name
                                                                                        of Company actually performing
                                                                                        operations (if different from
                                                                                        your company):</label>
                                                                                    <input type="text"
                                                                                           name="OperatingCompany"
                                                                                           id="operations"
                                                                                           ref="OperatingCompany"
                                                                                           className="form-control"
                                                                                           onBlur={(e) => this.inputChange(e)}
                                                                                           onChange={(e) => this.inputChange(e)}/>
                                                                                    <span id="error_operations"
                                                                                          className="text-danger"/>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {/*/row*/}
                                                                        <div className="portlet"/>
                                                                        <div className="row">
                                                                            <div
                                                                                className="col-md-12 col-sm-12 col-xs-12">
                                                                                <h5><b>Contact information</b></h5>
                                                                            </div>
                                                                            <div
                                                                                className="col-md-6 col-sm-12 col-xs-12">
                                                                                <div className="form-group">
                                                                                    <label
                                                                                        className="control-label">Name</label>
                                                                                    <input type="text" id="name"
                                                                                           name="ContactName"
                                                                                           ref="ContactName"
                                                                                           className="form-control"
                                                                                           onBlur={(e) => this.inputChange(e)}
                                                                                           onChange={(e) => this.inputChange(e)}/>
                                                                                    <span id="error_name"
                                                                                          className="text-danger"/>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className="col-md-6 col-sm-12 col-xs-12">
                                                                                <div className="form-group">
                                                                                    <label
                                                                                        className="control-label">Position</label>
                                                                                    <input type="text" id="position"
                                                                                           name="ContactPosition"
                                                                                           ref="ContactPosition"
                                                                                           className="form-control"
                                                                                           onBlur={(e) => this.inputChange(e)}
                                                                                           onChange={(e) => this.inputChange(e)}/>
                                                                                    <span id="error_position"
                                                                                          className="text-danger"/>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div
                                                                                className="col-md-6 col-sm-12 col-xs-12">
                                                                                <div className="form-group">
                                                                                    <label
                                                                                        className="control-label">Address</label>
                                                                                    <input type="text" id="address"
                                                                                           name="MailingAddress"
                                                                                           ref="MailingAddress"
                                                                                           className="form-control"
                                                                                           onBlur={(e) => this.inputChange(e)}
                                                                                           onChange={(e) => this.inputChange(e)}/>
                                                                                    <span id="error_address"
                                                                                          className="text-danger"/>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className="col-md-6 col-sm-12 col-xs-12">
                                                                                <div className="form-group">
                                                                                    <label className="control-label">Phone
                                                                                        Number</label>
                                                                                    <input type="text" id="phonenumber"
                                                                                           name="ContactPhoneNumber"
                                                                                           ref="ContactPhoneNumber"
                                                                                           className="form-control"
                                                                                           onBlur={(e) => this.inputChange(e)}
                                                                                           onChange={(e) => this.inputChange(e)}/>
                                                                                    <span id="error_phonenumber"
                                                                                          className="text-danger"/>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {/*/row*/}
                                                                        <div className="row">
                                                                            <div
                                                                                className="col-md-6 col-sm-12 col-xs-12">
                                                                                <div className="form-group">
                                                                                    <label className="control-label">Fax
                                                                                        Number (if any)</label>
                                                                                    <input type="text"
                                                                                           name="ContactFaxNumber"
                                                                                           ref="ContactFaxNumber"
                                                                                           className="form-control"/>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className="col-md-6 col-sm-12 col-xs-12">
                                                                                <div className="form-group">
                                                                                    <label
                                                                                        className="control-label">Email</label>
                                                                                    <input type="text" id="email"
                                                                                           name="ContactEmail"
                                                                                           ref="ContactEmail"
                                                                                           className="form-control"
                                                                                           onBlur={(e) => this.inputChange(e)}
                                                                                           onChange={(e) => this.inputChange(e)}/>
                                                                                    <span id="error_email"
                                                                                          className="text-danger"/>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {/*/row*/}
                                                                        <div className="portlet"/>
                                                                        <div className="row">
                                                                            <div
                                                                                className="col-md-12 col-sm-12 col-xs-12">
                                                                                <h5><b>Project Details</b></h5></div>
                                                                            <div
                                                                                className="col-md-12 col-sm-12 col-xs-12">
                                                                                <div className="form-group">
                                                                                    <label className="control-label">Please
                                                                                        submit your drilling or
                                                                                        re-working plan in plain
                                                                                        English. Drilling locations need
                                                                                        to include
                                                                                        standard legal
                                                                                        descriptions.</label>
                                                                                    <span id="error_drilling"
                                                                                          className="text-danger"/>
                                                                                    <textarea name="ReworkingPlan"
                                                                                              id="drilling"
                                                                                              ref="ReworkingPlan"
                                                                                              className="form-control"
                                                                                              onBlur={(e) => this.inputChange(e)}
                                                                                              onChange={(e) => this.inputChange(e)}/>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {/*/row*/}
                                                                        <div className="row">
                                                                            <div
                                                                                className="col-md-12 col-sm-12 col-xs-12">
                                                                                <div className="form-group">
                                                                                    <label className="control-label">Please
                                                                                        give an honest assessment of
                                                                                        project risk</label>
                                                                                    <span id="error_honest"
                                                                                          className="text-danger"/>
                                                                                    <textarea name="ProjectRisk"
                                                                                              id="honest"
                                                                                              ref="ProjectRisk"
                                                                                              className="form-control"
                                                                                              onBlur={(e) => this.inputChange(e)}
                                                                                              onChange={(e) => this.inputChange(e)}/>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {/*/row*/}
                                                                        <div className="row">
                                                                            <div
                                                                                className="col-md-12 col-sm-12 col-xs-12">
                                                                                <div className="form-group">
                                                                                    <label className="control-label">Please
                                                                                        give your revenue projections in
                                                                                        plain English,
                                                                                        accounting for different
                                                                                        production scenarios and taxes.
                                                                                        Remember, the plan has
                                                                                        to be profitable in a low price
                                                                                        environment.</label>
                                                                                    <span id="error_revenue"
                                                                                          className="text-danger"/>
                                                                                    <textarea name="RevenueProjections"
                                                                                              id="revenue"
                                                                                              ref="RevenueProjections"
                                                                                              className="form-control"
                                                                                              onBlur={(e) => this.inputChange(e)}
                                                                                              onChange={(e) => this.inputChange(e)}/>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {/*/row*/}
                                                                        <div className="row">
                                                                            <div
                                                                                className="col-md-12 col-sm-12 col-xs-12">
                                                                                <div className="form-group">
                                                                                    <label className="control-label">How
                                                                                        much are you trying to raise?
                                                                                        What working interest and net
                                                                                        revenue interest are you willing
                                                                                        to give up?</label>
                                                                                    <span id="error_raise"
                                                                                          className="text-danger"/>
                                                                                    <textarea
                                                                                        name="WorkingInterestDescription"
                                                                                        id="raise"
                                                                                        ref="WorkingInterestDescription"
                                                                                        className="form-control"
                                                                                        onBlur={(e) => this.inputChange(e)}
                                                                                        onChange={(e) => this.inputChange(e)}/>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {/*/row*/}
                                                                        <div className="row">
                                                                            <div
                                                                                className="col-md-12 col-sm-12 col-xs-12">
                                                                                <div className="form-group">
                                                                                    <label className="control-label">Are
                                                                                        you prepared to submit Leases
                                                                                        and Title Work if we are
                                                                                        interested in this project?
                                                                                        You may submit those documents
                                                                                        now but are not required
                                                                                        to.</label>
                                                                                    <span id="error_leases"
                                                                                          className="text-danger"/>
                                                                                    <textarea name="PrepareLeaseTitle"
                                                                                              id="leases"
                                                                                              ref="PrepareLeaseTitle"
                                                                                              className="form-control"
                                                                                              onBlur={(e) => this.inputChange(e)}
                                                                                              onChange={(e) => this.inputChange(e)}/>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {/*/row*/}
                                                                        <div className="row">
                                                                            <div
                                                                                className="col-md-12 col-sm-12 col-xs-12">
                                                                                <div className="form-group">
                                                                                    <label className="control-label">Please
                                                                                        list all principals in your
                                                                                        company who would need to
                                                                                        undergo a standard criminal
                                                                                        and civil litigation background
                                                                                        check prior to completing a
                                                                                        deal. Is there any litigation or
                                                                                        background
                                                                                        information we should be aware
                                                                                        of?</label>
                                                                                    <span id="error_list"
                                                                                          className="text-danger"/>
                                                                                    <textarea name="PrincipalsList"
                                                                                              id="list"
                                                                                              ref="PrincipalsList"
                                                                                              className="form-control"
                                                                                              onBlur={(e) => this.inputChange(e)}
                                                                                              onChange={(e) => this.inputChange(e)}/>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {/*/row*/}
                                                                        <div className="row">
                                                                            <div
                                                                                className="col-md-12 col-sm-12 col-xs-12">
                                                                                <div className="form-group">

                                                                                    <label className="control-label">Permits
                                                                                        are not required at this point.
                                                                                        Do you have permits for the
                                                                                        above project(s)?</label>&nbsp;
                                                                                    <span id="error_permits"
                                                                                          className="text-danger"/>
                                                                                    <textarea name="DoesHavePermits"
                                                                                              id="permits"
                                                                                              ref="DoesHavePermits"
                                                                                              className="form-control"
                                                                                              onBlur={(e) => this.inputChange(e)}
                                                                                              onChange={(e) => this.inputChange(e)}/>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {/*/row*/}
                                                                        <div className="row">
                                                                            <div
                                                                                className="col-md-12 col-sm-12 col-xs-12">
                                                                                <div className="form-group">

                                                                                    <label className="control-label">How
                                                                                        did you hear about
                                                                                        EnergyFunders?</label>&nbsp;
                                                                                    <span id="error_hear"
                                                                                          className="text-danger"/>
                                                                                    <textarea name="EFsource" id="hear"
                                                                                              ref="EFsource"
                                                                                              className="form-control"
                                                                                              onBlur={(e) => this.inputChange(e)}
                                                                                              onChange={(e) => this.inputChange(e)}/>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {/*/row*/}
                                                                        <div className="portlet"/>
                                                                        <div className="row">
                                                                            <div
                                                                                className="col-md-12 col-sm-12 col-xs-12">
                                                                                <h5><b>Please include the following
                                                                                    documents:</b></h5>
                                                                            </div>
                                                                        </div>
                                                                        <div className="container">
                                                                            <div className="panel panel-default">
                                                                                <div className="panel-heading1 ph">
                                                                                    Pertinent Maps or Images
                                                                                </div>
                                                                                <div className="panel-body">
                                                                                    <div className="upload-btn-wrapper">
                                                                                        <button
                                                                                            className="fa fa-plus-circle btn btn-primary"> Add
                                                                                            files...
                                                                                        </button>
                                                                                        <input type="file" id="maps"
                                                                                               name="mapfiles"
                                                                                               onChange={this.onChangeFiles}
                                                                                               multiple={true}/>
                                                                                    </div>
                                                                                    <div className="upload-btn-wrapper">
                                                                                        <button type="button"
                                                                                                id="pertinentMaps"
                                                                                                className="fa fa-upload btn btn-primary"
                                                                                                onClick={(e) => {
                                                                                                    this.filesUpload(e)
                                                                                                }}> Start Upload
                                                                                        </button>
                                                                                    </div>
                                                                                    <div style={{fontSize: 11}}>
                                                                                        <em> *All uploaded files need to
                                                                                            be 15MB or less.</em></div>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                {
                                                                                    (() => {
                                                                                        if (Object.keys(this.state.pertinentMapsFiles).length) {
                                                                                            return (
                                                                                                <Preview
                                                                                                    data={this.state.pertinentMapsFiles}
                                                                                                    images={this.state.pertinentMapsImages}
                                                                                                    type="maps"
                                                                                                    deleteAction={this.onDeleteFile}/>
                                                                                            )
                                                                                        } else if (Object.keys(this.state.pertinentMaps).length && Object.keys(this.state.pertinentMapsImages).length) {
                                                                                            return (
                                                                                                <Preview
                                                                                                    data={this.state.pertinentMaps}
                                                                                                    images={this.state.pertinentMapsImages}
                                                                                                    type="maps"
                                                                                                    deleteAction={this.onDeleteFile}/>
                                                                                            )
                                                                                        }
                                                                                    })()
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="container">
                                                                            <div className="panel panel-default">
                                                                                <div className="panel-heading1 ph">
                                                                                    Your AFE (Drilling or Operations
                                                                                    Budget)
                                                                                </div>
                                                                                <div className="panel-body">
                                                                                    <div className="upload-btn-wrapper">
                                                                                        <button
                                                                                            className="fa fa-plus-circle btn btn-primary"> Add
                                                                                            files...
                                                                                        </button>
                                                                                        <input type="file" id="afe"
                                                                                               name="afefiles"
                                                                                               onChange={this.onChangeFiles}
                                                                                               multiple={true}/>
                                                                                    </div>
                                                                                    <div className="upload-btn-wrapper">
                                                                                        <button type="button"
                                                                                                id="afeOperations"
                                                                                                className="fa fa-upload btn btn-primary"
                                                                                                onClick={(e) => {
                                                                                                    this.filesUpload(e)
                                                                                                }}> Start Upload
                                                                                        </button>
                                                                                    </div>
                                                                                    <div style={{fontSize: 11}}>
                                                                                        <em> *All uploaded files need to
                                                                                            be 15MB or less.</em></div>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                {
                                                                                    (() => {
                                                                                        if (Object.keys(this.state.afeOperationsFiles).length) {
                                                                                            return (
                                                                                                <Preview
                                                                                                    data={this.state.afeOperationsFiles}
                                                                                                    images={this.state.afeOperationsImages}
                                                                                                    type="afe"
                                                                                                    deleteAction={this.onDeleteFile}/>
                                                                                            )
                                                                                        } else if (Object.keys(this.state.afeOperations).length && Object.keys(this.state.afeOperationsImages).length) {
                                                                                            return (
                                                                                                <Preview
                                                                                                    data={this.state.afeOperations}
                                                                                                    images={this.state.afeOperationsImages}
                                                                                                    type="afe"
                                                                                                    deleteAction={this.onDeleteFile}/>
                                                                                            )
                                                                                        }
                                                                                    })()
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="container">
                                                                            <div className="panel panel-default">
                                                                                <div className="panel-heading1 ph">
                                                                                    Please also include any additional
                                                                                    materials you would like us to have.
                                                                                </div>
                                                                                <div className="panel-body">
                                                                                    <div className="upload-btn-wrapper">
                                                                                        <button
                                                                                            className="fa fa-plus-circle btn btn-primary"> Add
                                                                                            files...
                                                                                        </button>
                                                                                        <input type="file" id="addmat"
                                                                                               name="addmatfiles"
                                                                                               onChange={this.onChangeFiles}
                                                                                               multiple={true}/>
                                                                                    </div>
                                                                                    <div className="upload-btn-wrapper">
                                                                                        <button type="button"
                                                                                                id="additionalMaterials"
                                                                                                className="fa fa-upload btn btn-primary"
                                                                                                onClick={(e) => {
                                                                                                    this.filesUpload(e)
                                                                                                }}> Start Upload
                                                                                        </button>
                                                                                    </div>
                                                                                    <div style={{fontSize: 11}}>
                                                                                        <em> *All uploaded files need to
                                                                                            be 15MB or less.</em></div>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                {
                                                                                    (() => {
                                                                                        if (Object.keys(this.state.additionalMaterialsFiles).length) {
                                                                                            return (
                                                                                                <Preview
                                                                                                    data={this.state.additionalMaterialsFiles}
                                                                                                    images={this.state.additionalMaterialsImages}
                                                                                                    type="addmat"
                                                                                                    deleteAction={this.onDeleteFile}/>
                                                                                            )
                                                                                        } else if (Object.keys(this.state.additionalMaterials).length && Object.keys(this.state.additionalMaterialsImages).length) {
                                                                                            return (
                                                                                                <Preview
                                                                                                    data={this.state.additionalMaterials}
                                                                                                    images={this.state.additionalMaterialsImages}
                                                                                                    type="addmat"
                                                                                                    deleteAction={this.onDeleteFile}/>
                                                                                            )
                                                                                        }
                                                                                    })()
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                                                        <div className="col-md-9"
                                                                             style={{width: '100%'}}>
                                                                            {(() => {
                                                                                switch (this.state.saved) {
                                                                                    case 1  :
                                                                                        return (<div>
                                                                                            <div
                                                                                                className="pull-left">
                                                                                                <input
                                                                                                    type="submit"
                                                                                                    name="submit"
                                                                                                    onClick={(e) => this.onsubmitcheck(e, 0)}
                                                                                                    defaultValue="Save Project"
                                                                                                    className="btn btn-success button-next"/>
                                                                                            </div>
                                                                                            <div
                                                                                                className="pull-right">
                                                                                                <input
                                                                                                    type="submit"
                                                                                                    name="submit"
                                                                                                    onClick={(e) => this.onsubmitcheck(e, 1)}
                                                                                                    defaultValue="Submit Project"
                                                                                                    className="btn btn-success button-next"/>
                                                                                            </div>
                                                                                        </div>);
                                                                                    case 0  :
                                                                                        return (<div>
                                                                                            <div
                                                                                                className="pull-left">
                                                                                                <input
                                                                                                    type="submit"
                                                                                                    name="submit"
                                                                                                    onClick={this.onSave}
                                                                                                    defaultValue="Save Project"
                                                                                                    className="btn btn-success button-next"/>
                                                                                            </div>
                                                                                            <div
                                                                                                className="pull-right">
                                                                                                <input
                                                                                                    type="submit"
                                                                                                    name="submit"
                                                                                                    onClick={this.onSubmit}
                                                                                                    defaultValue="Submit Project"
                                                                                                    className="btn button-next"/>
                                                                                            </div>
                                                                                        </div>);
                                                                                    default :
                                                                                        return (<div>
                                                                                            <div
                                                                                                className="pull-left">
                                                                                                <button
                                                                                                    type="button"
                                                                                                    onClick={this.onSave}
                                                                                                    className="btn btn-info button-next">
                                                                                                    Save Project
                                                                                                </button>
                                                                                            </div>
                                                                                            <div
                                                                                                className="pull-right">
                                                                                                <button
                                                                                                    type="button"
                                                                                                    onClick={this.onSubmit}
                                                                                                    className="btn btn-primary button-next">
                                                                                                    Submit Project
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>);
                                                                                }
                                                                            })()}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

const {object, bool} = PropTypes;

Adminaddproject_d.propTypes = {
    session: object.isRequired,
    authenticated: bool.isRequired,
    checked: bool.isRequired
};


const mapState = ({session}) => ({
    session: session.user,
    checked: session.checked,
    authenticated: session.authenticated
});

export default withRouter(connect(mapState)(Adminaddproject_d));

