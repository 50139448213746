import React from 'react';
import PropTypes from 'prop-types'
import BaseComponent from "../utils/BaseComponent";

class SingleLineInputComponent extends BaseComponent {
    render = () => {
        const {inputReference, inputName, maxInputLength, inputTitle, elementValue, placeholder} = this.props;
        const maxInputMessage = maxInputLength ? `(Characters left: ${this.state.charsLeft})` : null;

        return (
            <div className="form-group">
                {inputTitle &&
                <label htmlFor={inputName}
                       className="input-label">
                    {inputTitle}{this.getRequired()} {maxInputMessage}
                </label>}
                <input id={inputName}
                       onChange={this.onInputChange}
                       maxLength={maxInputLength}
                       className="form-control input"
                       ref={inputReference}
                       name={inputName}
                       type="text"
                       placeholder={placeholder}
                       value={elementValue}/>
                <span id={`error_${inputName}`} className="text-danger"/>
            </div>
        );
    };
}

SingleLineInputComponent.propTypes = {
    elementValue: PropTypes.string.isRequired,
    inputName: PropTypes.string.isRequired,
    inputReference: PropTypes.any.isRequired,
    inputTitle: PropTypes.string.isRequired,
    maxInputLength: PropTypes.number.isRequired,
    onInputUpdate: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    isRequired: PropTypes.bool,
};

SingleLineInputComponent.defaultProps = {
    elementValue: '',
    inputName: '',
    inputReference: () => null,
    inputTitle: '',
    maxInputLength: 20,
    onInputUpdate: () => null,
    placeholder: null,
    isRequired: false,
};

export default SingleLineInputComponent;
