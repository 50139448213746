import PropTypes from 'prop-types';
import React from 'react';

const HideComponent = (props) => {
    const { hide, children } = props;

    if (hide) return null;

    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    );
};

HideComponent.propTypes = {
    children: PropTypes.any.isRequired,
    hide: PropTypes.bool.isRequired,
};

HideComponent.defaultProps = {
    children: null,
    hide: false,
};

export default HideComponent;
