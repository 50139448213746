import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import React from 'react';
import { Link } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import moment from 'moment';
import CollapsiblePanelComponent from '../CollapsiblePanelComponent/CollapsiblePanelComponent';
import { formatMoney, getUUID } from '../../../utils/General';
import RequestService from "../../../api/RequestService";
import { withRouter } from "react-router-dom";
import InvestorEarningsChart from "../../investor/InvestorDashboardContainerComponent/InvestorEarningsChart";

class EarningTableComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			activePage: 1,
			itemsPerPage: props.itemsPerPage,
			totalItems: 0,
			selectedItemsList: [],
			pastRegDInvestments: [],
			projectDetails: [],
			showModal: false,
            selectedInvestorInvestment:[],
		};
		this.handlePageChange = this.handlePageChange.bind(this);
		this.handleChangeItemsPerPage =
			this.handleChangeItemsPerPage.bind(this);
	}
	componentDidMount() {
		this.getAllInvestments();
		

		const { itemsPerPage } = this.state;
		const { investorEarnings } = this.props;
		let page = 1;
		const prev = (page - 1) * itemsPerPage;
		const next = prev + itemsPerPage;
		this.setState({
			activePage: page,
			selectedItemsList: investorEarnings.slice(prev, next),
			totalItems: investorEarnings.length,
		});
	}
	componentDidUpdate(prevProps) {
		const { investorEarnings } = this.props;
		const { activePage } = this.state;
		const _investorEarningsList = JSON.stringify(
			prevProps.investorEarnings
		);
		const strInvestorEarningsList = JSON.stringify(investorEarnings);
		if (_investorEarningsList !== strInvestorEarningsList) {
			this.setState({ totalItems: investorEarnings.length }, () =>
				this.handlePageChange(activePage, false)
			);
		}
	}
	handlePageChange(page, scrollUp) {
		const { itemsPerPage } = this.state;
		const { investorEarnings } = this.props;
		const prev = (page - 1) * itemsPerPage;
		const next = prev + itemsPerPage;
		this.setState({
			activePage: page,
			selectedItemsList: investorEarnings.slice(prev, next),
		});
		if (scrollUp) {
			//document.getElementById('EarningTableComponent').scrollIntoView();
		}
	}
	handleChangeItemsPerPage() {
		this.setState(
			{
				itemsPerPage: Number(this.refs.selectItemsPerPage.value), // Must be typecast.
				activePage: 1,
			},
			() => this.handlePageChange(1, true)
		);
	}

	redirectToProjectDetailsPage = (ID) => {
	
		const { push } = this.props.history;

		const pushStateObj = {
			state: {
				id: ID,
				projectType: "PROJECT_REG_D",
			},
			pathname: "/app/project/details",
		};

		push(pushStateObj);
	};

	async getAllInvestments() {
		let categoryId = "all";
		const { data } = await RequestService.get(
			`/api/investments/by-category/${categoryId}`
		);
		if (data) {
			this.setState({
				...this.state,
				pastRegDInvestments: data.pastRegDInvestments,
			});
		}
		console.log("getAllInvestments state:", this.state);
	}
	async getProjectDetails(selectedID) {
        this.selectedInvestorInvestments(selectedID);
		
		let projectId = selectedID;
		let projectType = "PROJECT_REG_D";
		const URL = `/api/project/${projectId}/details/${projectType}`;
		const { data } = await RequestService.get(URL);

		if (data) {
			this.setState({
				...this.state,
				projectDetails: data.projectDetails,
				showModal: true,
			});
		}
		console.log("getProjectDetails state:", this.state);
	}

	selectedInvestorInvestments = (ID) => {
     
        const {investorInvestments} = this.props;
let investorInvestment = investorInvestments?.filter(item => item?.ProjectID_RegD === ID );
        this.setState({	...this.state, selectedInvestorInvestment:  investorInvestments?.find(item => item?.ProjectID_RegD === ID ) });
        console.log('investorInvestment',investorInvestment)
        console.log("investorInvestment state:", this.state);
 
 };
	closeModal = () => this.setState({ showModal: false });

	saveAndClose = () => this.setState({ showModal: false });

	earningsDetail = () => {
		return (
			<Modal show={this.state.showModal} onHide={this.closeModal} className="investor-modal">
				<Modal.Header closeButton>
					<Modal.Title></Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="row">
						<div className="col-sm-12 col-md-6 divider left">
							<h2 className="cursor-pointer" onClick={() =>this.redirectToProjectDetailsPage(this.state.projectDetails.projectID)}>{this.state.projectDetails?.projectName}</h2>
							<p>
								<img src={this.state.projectDetails?.image} className="w-100 cursor-pointer" onClick={() =>this.redirectToProjectDetailsPage(this.state.projectDetails.projectID)}  />
							</p>
							<p className="pt-3">
								{this.state.projectDetails?.dealCardDescription
									? this.state.projectDetails
											.dealCardDescription
									: "Description Not Available"}
							</p>
							<h3 className="pt-5">Projected Metrics</h3>
							<div className="d-flex">
								<div className="col-md-4 pl-0">
									<h5 className="font-weight-bold">ROI</h5>
									<p >
										{this.state.projectDetails?.roi}X
									</p>
								</div>
								<div className="col-md-4">
									<h5 className="font-weight-bold">IRR</h5>
									<p >
										{this.state.projectDetails?.irr}%
									</p>
								</div>
								<div className="col-md-4">
									<h5 className="font-weight-bold">PAYBACK</h5>
									<p>
										{" "}
										{this.state.projectDetails?.payback} MONTHS
									</p>
								</div>
							</div>
						</div>
						<div className="col-sm-12 col-md-6 right">
							<InvestorEarningsChart title='My Investment'
								//  investmentLabels={this.state.selectedInvestorInvestment[0]?.InvestmentAmount}
								// investmentAmounts={
								// 	this.state.selectedInvestorInvestment[0]
								// 		?.InvestmentAmount
								// }
								// cumulativeEarnings={
								// 	this.state.selectedInvestorInvestment[0]
								// 		?.EarningAmount
								// }
							/>
                            <div className="d-flex">
								<div className="col-md-4 pl-0">
									<h5 className="font-weight-bold">INVESTED</h5>
									<p >
										{this.state.selectedInvestorInvestment?.InvestmentAmount}
									</p>
								</div>
								<div className="col-md-4">
									<h5 className="font-weight-bold">EXPENSES</h5>
									<p >
										
									</p>
								</div>
								<div className="col-md-4">
									<h5 className="font-weight-bold">EARNINGS</h5>
									<p>
                                    {this.state.selectedInvestorInvestment?.EarningAmount}
									</p>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
			
			</Modal>
		);
	};

	render() {
		// const {
		// 	totalPaymentsRegD,
		// 	totalPaymentsRegCf,
		// 	role,
		
		// } = this.props;
		const { activePage, itemsPerPage, totalItems, selectedItemsList } =
			this.state;
	

		return (
			<div id="InvestmentsComponent" className="mt-4">
				<h2>{this.props?.title}</h2>
				{this.earningsDetail()}

				{/* <div className="col-xs-12">
                    <div className="pull-left">
                        <h4>
                            Regulation D Total: $
                            {formatMoney(totalPaymentsRegD)}
                        </h4>
                        <h4>
                            Regulation CF Total: $
                            {formatMoney(totalPaymentsRegCf)}
                        </h4>
                    </div>
					{(() => {
						if (role !== 'Admin') {
							return(
								<div className="pull-right">
									<Link
										className="btn btn-success small pull-right"
										to={'/app/Earnings2'}
									> View All
									</Link>
								</div>
							);
						}
					})()}
                </div>
                <hr /> */}
				<div className="col-xs-12 p-0">
					<fieldset>
						<div className="table-responsive">
							<table className="table table-striped jambo_table">
								<thead>
									<tr className="no-text-wrap">
										{/* {(() => {
											if (role === 'Admin') {
												return (<th>Payment ID</th>);
											}
										})()} */}
										<th>Date</th>
										<th>Investor</th>
										{/* <th>Type</th> */}
										<th>Fund</th>
										{/* <th>Project Entity</th> */}
										<th>Amount</th>
										{/* {(() => {
											if (role === 'Admin') {
												return (<th>Payment Method</th>);
											}
										})()} */}
									</tr>
								</thead>
								<tbody>
									{(() => {
										return selectedItemsList.map((p) => (
											<tr key={getUUID()}>
												{/* {(() => {
													if (role === 'Admin') {
														return (<td>{p.PaymentID}</td>);
													}
												})()} */}
												<td>
													{moment(
														p.EarningDate
													).format("MMM DD, YYYY")}
												</td>
												<td>{p.Entity}</td>
												{/* <td>
													{p.ProjectID_RegD === 0
														? "Reg CF"
														: "Reg D"}
												</td> */}
												<td
													// onClick={() =>
													// 	this.getProjectDetails(
													// 		p.ProjectID_RegD
													// 	)
													// }
													// className="highlight"
												>
													{p.ProjectID_RegD === 0
														? p.InvestmentName
														: p.ProjectName}
												</td>
												{/* <td>{p.CompanyEntity}</td> */}
												<td>
													$
													{formatMoney(
														p.EarningAmount
													)}
												</td>
												{/* {(() => {
													if (role === 'Admin') {
														return (<td>{p.PaymentMethod}</td>);
													}
												})()} */}
											</tr>
										));
									})()}
								</tbody>
							</table>
						</div>
					</fieldset>
				</div>
				<div className="col-xs-12">
					<div className="pull-right">
						<Pagination
							firstPageText={
								<i className="glyphicon glyphicon-chevron-left" />
							}
							lastPageText={
								<i className="glyphicon glyphicon-chevron-right" />
							}
							prevPageText={
								<i className="glyphicon glyphicon-menu-left" />
							}
							nextPageText={
								<i className="glyphicon glyphicon-menu-right" />
							}
							activePage={activePage}
							itemsCountPerPage={itemsPerPage}
							totalItemsCount={totalItems}
							onChange={this.handlePageChange}
						/>
						<div
							className="pull-left"
							style={{ margin: "0 10px 0 0" }}
						>
							<select
								ref="selectItemsPerPage"
								style={{
									margin: "20px 0",
									padding: "0 10px",
									height: 32,
								}}
								onChange={this.handleChangeItemsPerPage}
							>
								<option>5</option>
								<option>10</option>
								<option>20</option>
								<option>50</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
EarningTableComponent.propTypes = {
    investorEarnings: PropTypes.array.isRequired,
    totalPaymentsRegCf: PropTypes.any.isRequired,
    totalPaymentsRegD: PropTypes.any.isRequired,
    itemsPerPage: PropTypes.number.isRequired,
};
EarningTableComponent.defaultProps = {
    investorEarnings: [],
    totalPaymentsRegCf: 0,
    totalPaymentsRegD: 0,
    itemsPerPage: 10,
};

export default withRouter(EarningTableComponent);
