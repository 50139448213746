import React, { useEffect, useState } from "react";
import { formatMoney, Wildcat_Project_ID, Bitcoin_Project_ID } from "../../../utils/General";
import "./InvestorDashboard.css";
import { Modal } from 'react-bootstrap';

export default function EarningsDescription(props) {
	const investorInvestments = props.investorInvestments;
	const investorEarnings = props.investorEarnings;
	const selectedProject =props.selectedProject;
	const InvestmentAmount =investorInvestments?.filter(i => i?.Refunded == 0 && i?.Deleted == 0)?.map(item => item?.InvestmentAmount);
	const EarningAmounts = investorEarnings.map(item => item?.EarningAmount);
	const sumOfInvestorEarnings = EarningAmounts.map(i=> Number(i)).reduce((a, b) => a + b, 0);
	const sumOfInvestorInvestments = InvestmentAmount.map(i=> Number(i)).reduce((a, b) => a + b, 0);
const isAllprojects = selectedProject?.value ==="allprojects";
const [showModal, setShowModal] = useState();
const history = props.history;
	return (
		<div className="container">
			<Modal show={showModal}
				style={{ width:'fit-content', margin: "0 auto", border: 0}}
				className="bio-modal" onHide={()=>setShowModal(false)}>
						<Modal.Body className=" bg-dark-blue text-white" style={{border: 0, borderRadius: 5}}>
						<div className="position-absolute close-modal ">
        <button onClick={()=>setShowModal(false)}>&#x2715;</button>
      </div>
	  <div>
	  <div className="row" style={{listStyleType: 'none', justifyContent:"center", textAlign: 'center'}}>
  <li className="col-lg-6 col-md-12 pt-4">
    <div className="card ">
	<div className="col-lg-6"><img src="/img/investmentopportunities/wilcat-bg.png" style={{width: "-webkit-fill-available",
	height:"300px"}} /></div>
	<div className="col-lg-6"><h2 style={{width:"max-content"}}>Drilling Fund I LLC</h2>
	<div className="spacer text-left py-4">
											<li className="py-2" style={{width:"max-content"}}>
												<img src="/img/time.svg" />
												<span className="p-2">
													{" "}
													3 – 5 Year Targeted Fund
													Life
												</span>
											</li>
											<li className="py-2" style={{width:"max-content"}}>
												<img src="/img/piechart.svg" />
												<span className="p-2">
													Periodic Distributions
												</span>
											</li>
											<li className="py-2" style={{width:"max-content"}}>
												<img src="/img/risklevel.svg" />
												<span className="p-2">
												Moderate Risk Level
												</span>
											</li>
										</div>
	<button className="btn btn-primary btn-green text-uppercase py-4 m-0" 
	onClick={ () =>   history.push({
												pathname: '/app/invest',
												state: {
													ProjectType: 'RegD',
													ProjectID: Wildcat_Project_ID,
													
												}
											})}
											 >
							Start Investing Now
						</button></div></div>
  </li>
  <li className="col-lg-6 col-md-12 pt-4">
  <div className="card"><div className="col-lg-6"><img src="/img/investmentopportunities/bitcoin-bg.png" style={{width: "-webkit-fill-available",
	height:"300px"}}/></div>
	<div className="col-lg-6"><h2>Bitcoin Fund</h2>
	<div className="spacer text-left py-4">
	<li className="py-2" style={{width:"max-content"}}>
												<img src="/img/time.svg" />
												<span className="p-2">
													{" "}
													3 – 5 Year Targeted Fund
													Life
												</span>
											</li>
											<li className="py-2" style={{width:"max-content"}}>
												<img src="/img/piechart.svg" />
												<span className="p-2">
													Periodic Distributions
												</span>
											</li>
											<li className="py-2" style={{width:"max-content"}}>
												<img src="/img/risklevel.svg" />
												<span className="p-2">
												High Risk Level
												</span>
											</li>
										</div>
	<button className="btn btn-primary btn-green text-uppercase py-4 m-0" 
	onClick={ () =>   history.push({
												pathname: '/app/invest',
												state: {
													ProjectType: 'RegD',
													ProjectID: Bitcoin_Project_ID,
													
												}
											})}
											 >
							Start Investing Now
						</button></div></div>
  </li>
</div>
	  </div>
						</Modal.Body>
			</Modal>
			<div className="tile-wrap" style={{overflowX:'overlay'}}>
				<div className="col-md-12 p-0 pb-4 d-md-flex  align-items-center">
					<div className="col-md-9 col-sm-12 col-xs-12 mb-sm-4 card-earnings">
						<ul className="card header d-flex p-0 mb-0">
							<li className={`padzero ${isAllprojects ? "allPWidth" : "PWidth"}`}>
								<h2>Fund</h2></li>
							<li className='InvestWidth'><h2>Investment Amount</h2></li>
							<li className='InvestWidth'><h2>Earnings Amount</h2></li>
							{/* <li className='padzero'><h2>Type</h2></li> */}
							{/* <li><h2>GP/LP</h2></li> */}
							{/* <li><h2>Investor Entity</h2></li> */}
						</ul>
{isAllprojects ? <ul className="card d-flex p-0 mt-0">
							<li className={`padzero ${isAllprojects ? "allPWidth" : "PWidth"}`}>
							<h3>All Funds</h3>
							</li>

							<li className='InvestWidth'>
								{ sumOfInvestorInvestments ? <h3>
												${formatMoney(sumOfInvestorInvestments)}
											</h3> : "0"}
							</li>
							<li className='InvestWidth'>
								{ sumOfInvestorEarnings ? <h3>
												${formatMoney(sumOfInvestorEarnings)}
											</h3> : <h3>$0</h3>}
							</li>
							{/* <li></li> */}
							{/* <li></li> */}
							{/* <li></li> */}
						</ul> :  <div className="d-flex w-100 flex-column card-wrapper">{
							
							// investorInvestments.map(project => (

								<ul className="card d-flex p-0 mt-0 m-0 w-100">
									<li className={`${isAllprojects ? "allPWidth" : "PWidth"}`}><h3>{investorInvestments[0]?.ProjectName}</h3></li>
									<li className='InvestWidth'><h3>${formatMoney(sumOfInvestorInvestments)}</h3></li>
									<li className='InvestWidth'>
								{ sumOfInvestorEarnings ? <h3>
												${formatMoney(sumOfInvestorEarnings)}
											</h3> : <h3>$0</h3>}
							</li>
									{/* <li className='padzero'><h3>{investorInvestments[0]?.ProjectID_RegD ? "Reg D" : ""}</h3></li> */}
									{/* <li><h3>{project?.GpLp === 2 ? "LP" : "GP"}</h3></li> */}
									{/* <li><h3>{project?.Entity}</h3></li> */}
									</ul>
							// ))
							}</div>
					
						}
					
					</div>
					<div className="col-md-3 col-sm-12 col-xs-12 mb-sm-4 text-center text-md-right px-0">
						<button className="btn btn-primary btn-green text-uppercase px-5 py-4 m-0" onClick={() =>setShowModal(true)} >
							Add Funds
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
