// GENERAL
export const SET_SELECTED_PROJECT_UPDATE = "SET_SELECTED_PROJECT_UPDATE";
export const REQUEST_GET_PROJECT_UPDATE_BY_ID_SUCCESS = "REQUEST_GET_PROJECT_UPDATE_BY_ID_SUCCESS";

// REQUEST CONSTANTS
export const PROJECT_UPDATE_REQUEST_END = "PROJECT_UPDATE_REQUEST_END";
export const PROJECT_UPDATE_REQUEST_START = "PROJECT_UPDATE_REQUEST_START";
export const REQUEST_ALL_REG_D_UPDATES_SUCCESS = "REQUEST_ALL_REG_D_UPDATES_SUCCESS";
export const REQUEST_ALL_REG_CF_UPDATES_SUCCESS = "REQUEST_ALL_REG_CF_UPDATES_SUCCESS";

