import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from 'redux';

import {getUUID} from "../../../utils/General"
import * as Utils from "./utils/Utils" ;
import * as Projects from "../../../actions/ProjectActions";
import ProjectEditor from "./components/ProjectUpdateEditor";
import StorageService from "../../../utils/StorageService";
import * as Constants from "../../../utils/ApplicationConstants";
import ProjectUpdateViewer from "../../shared/projectUpdateViewerComponent/ProjectUpdateViewer";
import * as ProjectUpdateActions from "./actions/index";
import ProjectUpdateExternalTestEmail from "./components/ProjectUpdateExternalTestEmail";

const propTypes = {
    action: PropTypes.string,
    getAllProjects: PropTypes.func.isRequired,
    getNotificationById: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    notificationData: PropTypes.object,
    performNotificationUpdate: PropTypes.func.isRequired,
    projects: PropTypes.objectOf(
        PropTypes.shape({
            requestOnFlight: PropTypes.number,
            regCFProjects: PropTypes.array.isRequired,
            regDProjects: PropTypes.array.isRequired
        }).isRequired
    ).isRequired,
    projectType: PropTypes.oneOf([Constants.PROJECT_REG_D, Constants.PROJECT_REG_CF]),
    projectUpdateId: PropTypes.number,
    saveNewProjectUpdate: PropTypes.func.isRequired,
    sendTestEmail: PropTypes.func.isRequired,
    user: PropTypes.objectOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            marketing: PropTypes.string.isRequired,
        }).isRequired
    ).isRequired,
};

const defaultProps = {
    action: "",
    getAllProjects: () => "",
    getNotificationById: () => "",
    history: {},
    notificationData: {},
    performNotificationUpdate: () => "",
    projects: {
        requestOnFlight: 0,
        regCFProjects: [],
        regDProjects: [],
    },
    projectType: Constants.PROJECT_REG_D,
    projectUpdateId: 0,
    saveNewProjectUpdate: () => "",
    sendTestEmail: () => "",
    user: {
        id: 0,
        marketing: "",
    },
};

const mapStateToProps = (state) => {
    const {projectUpdate} = state;
    return {
        ...projectUpdate.selectedUpdated,
        user: state.session.user,
        projects: state.projects,
    };
};

class ProjectUpdateEditorContainer extends React.Component {
    constructor(props) {
        super(props);

        this.isUpdated = false;

        this.state = {
            action: null,
            updateType: -1,
            projectList: [],
            isModalOpen: false,
            externalEmail: "",
            coverImageURL: null,
            notificationData: {},
            isPrivateForEver: true,
            externalEmailList: [],
            daysAsPrivateUpdate: 1,
            openExternalTestEmailModal: false,

            // notification info
            parentProjectIdRegCF: -1,
            parentProjectIdRegD: -1,
            projectUpdateId: -1,
            selectedProject: -1,
            internalTitle: "",
            projectType: "",
            coverImage: null,
            smallText: "",
            longText: "",
            subject: "",
        };
    }

    openModal = () => {
        this.setState({isModalOpen: true});
    };

    closeModal = () => {
        this.setState({isModalOpen: false});
    };

    componentDidMount() {
        let {getAllProjects, getNotificationById, user, projectType, projectUpdateId, action} = this.props;
        getAllProjects(user);

        if (this.canRequestUpdateInfo(projectType, projectUpdateId, action)) {
            const storedData = StorageService.getStoredSelectedUpdateInfo() || {};
            projectUpdateId = storedData.projectUpdateId;
            projectType = storedData.projectType;
            action = storedData.action;
        }

        this.setState({action, projectType});
        getNotificationById(projectType, projectUpdateId, user);
        StorageService.storeSelectedUpdateInfo({projectType, projectUpdateId, action});
    }

    canRequestUpdateInfo(projectType, projectUpdateId, action) {
        return (projectType === null || projectUpdateId === -1) && action !== Constants.PROJECT_UPDATE_CREATE;
    }

    componentDidUpdate(prevProps, prevState) {
        const actualUpdateData = JSON.stringify(this.props.notificationData);
        const prevUpdateData = JSON.stringify(prevProps.notificationData);
        const actualProjectType = this.state.projectType;
        const prevProjectType = prevState.projectType;

        if (prevUpdateData !== actualUpdateData) {
            this.setNotificationData();
        }

        if (prevProjectType !== actualProjectType) {
            this.setProjectList();
        }

        if (this.state.projectList.length === 0 && !this.isUpdated &&
            (this.props.projects.regCFProjects.length !== 0 ||
                this.props.projects.regDProjects.length !== 0)) {
            this.isUpdated = true;
            this.setProjectList();
        }
    }

    setProjectList = () => {
        const {projectType} = this.state;
        if (Constants.PROJECT_REG_D === projectType)
            this.setState({projectList: this.props.projects.regDProjects});
        else if (Constants.PROJECT_REG_CF === projectType)
            this.setState({projectList: this.props.projects.regCFProjects});
    };

    setNotificationData = () => {
        const {notificationData} = this.props;
        const {DaysAsPrivateUpdate} = notificationData;

        let updateType = DaysAsPrivateUpdate;
        let daysAsPrivateUpdate = 1;
        let isPrivateForEver = true;
        if (updateType > 0) {
            daysAsPrivateUpdate = DaysAsPrivateUpdate;
            updateType = 0;
            isPrivateForEver = false;
        }

        this.setState({
            parentProjectIdRegCF: notificationData.ProjectID_RegCF,
            parentProjectIdRegD: notificationData.ProjectID_RegD,
            projectUpdateId: notificationData.NotificationID,
            coverImage: notificationData.CoverImage,
            smallText: notificationData.NotificationSmallText,
            longText: notificationData.NotificationText,
            subject: notificationData.Subject,
            internalTitle: notificationData.InternalTitle,
            updateType,
            isPrivateForEver,
            daysAsPrivateUpdate,
            notificationData,
        });
    };

    onFormInputChangeHandler = (event) => {
        const {name, value} = event.target;
        this.setState({[name]: value});
    };

    onFormCheckboxSelectedHandler = (event) => {
        const {name, checked} = event.target;
        this.setState({[name]: checked});
    };

    onEditorChangeHandler = (event, name) => {
        let content = event.editor.getData();
        this.setState({[name]: content});
    };

    onFileInputChangeHandler = (event) => {
        const {name, files} = event.target;
        const selectedFile = files[0];

        if (selectedFile)
            this.setState({[name]: selectedFile});
    };

    onSaveEditionClickHandler = () => {
        const {performNotificationUpdate, projectType, user} = this.props;
        const {
            smallText, longText, subject, notificationData, coverImage, parentProjectIdRegD,
            parentProjectIdRegCF, daysAsPrivateUpdate, updateType, isPrivateForEver, internalTitle
        } = this.state;
        const daysAsPrivate = Utils.getDaysAsPrivateUpdate(daysAsPrivateUpdate, updateType, isPrivateForEver);

        const requestPayload = {
            NotificationID: notificationData.NotificationID,
            Subject: subject,
            NotificationText: longText,
            daysAsPrivateUpdate: daysAsPrivate,
            NotificationSmallText: smallText,
            ProjectID: parentProjectIdRegD || parentProjectIdRegCF,
            InternalTitle: internalTitle,
        };

        const formData = new FormData();
        formData.append("object", JSON.stringify(requestPayload));
        formData.append("coverImage", coverImage);

        performNotificationUpdate(projectType, user, formData, this.goBack);
    };

    onSaveNewUpdate = () => {
        const {saveNewProjectUpdate, user, action} = this.props;
        const {
            selectedProject, smallText, longText, subject, projectType, coverImage,
            daysAsPrivateUpdate, updateType, isPrivateForEver, internalTitle,
            parentProjectIdRegD, parentProjectIdRegCF
        } = this.state;
        const daysAsPrivate = Utils.getDaysAsPrivateUpdate(daysAsPrivateUpdate, updateType, isPrivateForEver);

        let _selectedProject = selectedProject;
        if ((action === Constants.PROJECT_UPDATE_CLONE_AS_NEW) &&
            (_selectedProject === -1)) {
            _selectedProject = parentProjectIdRegD || parentProjectIdRegCF;
        }

        const reqPayload = {
            UserID: user.id,
            Subject: subject,
            ProjectID: _selectedProject,
            NotificationText: longText || "",
            daysAsPrivateUpdate: daysAsPrivate,
            NotificationSmallText: smallText,
            InternalTitle: internalTitle,
        };

        const formData = new FormData();
        formData.append("object", JSON.stringify(reqPayload));
        formData.append("coverImage", coverImage);

        saveNewProjectUpdate(projectType, user, formData, this.goBack);
    };

    onSendEmailTestClickHandler = () => {
        const {sendTestEmail, user} = this.props;
        const {smallText, longText, subject, projectList, coverImage, projectType, externalEmailList} = this.state;

        const project = projectList.find(this.isCorrectProject);
        const payload = {
            subject,
            longText,
            smallText,
            projectId: project.projectId,
            projectName: project.projectName,
            externalEmailList,
        };

        const formData = new FormData();
        formData.append("object", JSON.stringify(payload));
        formData.append("coverImage", coverImage);

        sendTestEmail(projectType, user, formData);
    };

    externalEmailModalManager = (openExternalTestEmailModal) => {
        this.setState({openExternalTestEmailModal});
    };

    sendExternalTestEmail = () => {
        this.onSendEmailTestClickHandler();
        this.closeExternalEmailModal();
    };

    addExternalEmailToList = (event) => {
        event.preventDefault();
        const emailList = [...this.state.externalEmailList];

        const obj = {
            key: getUUID(),
            email: this.state.externalEmail,
        };

        emailList.push(obj);

        this.setState({
            externalEmail: "",
            externalEmailList: emailList,
        });
    };

    removeEmilFromList = (selectedEmail) => {
        const emailList = this.state.externalEmailList
            .filter(email => email.key !== selectedEmail);
        this.setState({externalEmailList: emailList});
    };

    closeExternalEmailModal = () => {
        this.externalEmailModalManager(false);
        this.setState({
            externalEmailList: [],
            externalEmail: "",
        });
    };

    goBack = () => {
        const {history} = this.props;
        history.goBack();
    };

    isCorrectProject = (project) => {
        const {selectedProject, parentProjectIdRegCF, parentProjectIdRegD} = this.state;
        const {projectId} = project;
        return (
            projectId === parseInt(selectedProject) ||
            projectId === parentProjectIdRegCF ||
            projectId === parentProjectIdRegD
        );
    };

    getImageUrl = () => {
        const {coverImage} = this.state;
        const fileReader = new FileReader();
        fileReader.onloadend = () => {
            this.setState({coverImageURL: fileReader.result});
        };
        fileReader.readAsDataURL(coverImage);
    };

    render = () => {
        const {isModalOpen, subject, projectList, smallText, longText, coverImage, coverImageURL} = this.state;
        let _selectedProject = projectList.find(this.isCorrectProject);

        if (coverImage && (typeof coverImage === "object"))
            this.getImageUrl();

        const _ci = coverImage && (typeof coverImage === "string") ? coverImage : coverImageURL;

        return (
            <div>
                <ProjectEditor {...this.state}
                               onEditorChangeHandler={this.onEditorChangeHandler}
                               onChangeHandler={this.onFormInputChangeHandler}
                               onUpdate={this.onSaveEditionClickHandler}
                               onSave={this.onSaveNewUpdate}
                               onCancel={this.goBack}
                               onPreviewClickHandler={this.openModal}
                               onFileInputChange={this.onFileInputChangeHandler}
                               onSendTestEmailClick={this.onSendEmailTestClickHandler}
                               onCheckboxSelected={this.onFormCheckboxSelectedHandler}
                               openExternalEmailModal={this.externalEmailModalManager}/>

                <ProjectUpdateViewer showModal={isModalOpen}
                                     closeModal={this.closeModal}
                                     subject={subject}
                                     smallText={smallText}
                                     longText={longText}
                                     projectName={(_selectedProject || {}).projectName}
                                     creationDate={new Date()}
                                     canShowText={false}
                                     coverImage={_ci}/>

                <ProjectUpdateExternalTestEmail openModal={this.state.openExternalTestEmailModal}
                                                closeModal={this.closeExternalEmailModal}
                                                onChangeHandler={this.onFormInputChangeHandler}
                                                emailList={this.state.externalEmailList}
                                                externalEmail={this.state.externalEmail}
                                                addEmailToList={this.addExternalEmailToList}
                                                removeEmail={this.removeEmilFromList}
                                                sendEmailTest={this.sendExternalTestEmail}/>
            </div>
        );
    };
}

ProjectUpdateEditorContainer.propTypes = propTypes;
ProjectUpdateEditorContainer.defaultProps = defaultProps;

const mapDispatchToProps = (dispatch) => {
    const projectUpdateActions = bindActionCreators(ProjectUpdateActions, dispatch);
    const projectActions = bindActionCreators(Projects, dispatch);
    return {
        ...projectUpdateActions,
        ...projectActions,
    };
};

const connectedComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectUpdateEditorContainer);

export default withRouter(connectedComponent);
