import React, { Component } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Button, Modal, Panel } from 'react-bootstrap';
import store from '../../index';
import RequestService from '../../api/RequestService';
import NewEntityContainerComponent from '../shared/NewEntityContainerComponent/NewEntityContainerComponent';
import { getImpersonateID  } from "../../utils/General";
import NotificationService from '../../utils/NotificationService';
import {
    ENTITY_VERIFY_BUSINESS_AND_WITH_DOC,
    ENTITY_VERIFY_BUSINESS_WITH_DOC,
    ENTITY_VERIFY_CONTROLLER_WITH_DOC
} from '../../utils/ApplicationConstants';
import { checkValidPWChars, getHash } from '../../utils/General';


import qrcode from 'qrcode';
import { authenticator } from 'otplib';



class CreateNewEntity extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isNewEntityModalOpen: false,

            id: props.session.id,
            User: [],
            num: '',
			PWChangePanel: false,
			MFAChangePanel: false,
			QRCode: '',
			mfaPass: '',
            IsValid: false,
			isAccredited: false,
			numEntities: 0,

            EntityID: '',
            Entities: [],
            default_gp_lp_election: 2,

            ContactPrefSMS: false,

            showGPModal: false,
            showLPModal: false,

            legalFN: '',
            legalLN: '',

            selectedEntityType: '',
            selectedEntityStatus: 'verified',
            selectedEntityW9Status: 0,
            selectedEntityFilesUploaded: true,
			selectedEntityController: 'Self',
            isEntityVerificationModalOpen: false,
            selectedEntityBOVStatus: 'verified',

            verifyBusiness: false,
            verifyController: false,
            isFileVerificationModalOpen: false,
            entityDetails: {
                entityType: '',
                entityName: '',
                legalFirstName: '',
                legalLastName: '',
                address: '',
                city: '',
                state: '',
                zipCode: '',
                phone: '',
                country: '',
     
               
            },
        };
    }



    componentDidMount() {
        window.scrollTo(0, 0);

        store.subscribe(() => {
            const currentState = store.getState();

            this.setState({
                id: currentState.session.user.id,
            });
        });

        this.getInvestorProfile();
        this.getInvestorEntities();
    }



    componentDidUpdate(prevProps, prevState) {
        const { Entities } = this.state;
        const { Entities: _Entities } = prevState;

        if (getHash(Entities) !== getHash(_Entities)) {
            // this.onSelectEntity();
        }
    }



    verifyPersonalEntity = async () => {
        try {
            const { data } = await RequestService.get('/api/investor/entity/personal/verify');

            if (!data.verified) {
                this.toggleEntityVerificationModal();
                return;
            }

            this.toggleNewEntityModal();
        }
        catch (error) {
            console.log(error);
            NotificationService.error(
                'Error verifying the Personal entity',
                10000,
            );
        }
    };



    toggleNewEntityModal = () => {
        const { isNewEntityModalOpen } = this.state;

        this.setState({ isNewEntityModalOpen: !isNewEntityModalOpen });
    };



    toggleVerificationModal = (selectedEntityType) => {
        const { isVerifyEntityModalOpen } = this.state;

        if (selectedEntityType === 'Personal' && this.state.selectedEntityController === 'Self') {
            this.props.history.push({
                pathname: '/app/VerifyDwolla',
            });
        }
        else if (selectedEntityType === 'Personal' && this.state.selectedEntityController === 'FA') {
			this.props.history.push({
				pathname: '/app/VerifyDwolla',
				state: {
					EntityID: this.state.EntityID
				}
			});
			// this.props.history.push({
			// 	pathname: '/app/createNewUserEntity',
			// 	state: {
			// 		RegistrationStep: 1
			// 	}
			// });
		}

        else {
            this.setState({ isVerifyEntityModalOpen: !isVerifyEntityModalOpen });
        }
    };




    // onSelectEntity = async () => {
      
    //    for (let i = 0; i < this.state.Entities.length; i++) {
    //         if (this.state.Entities[i].EntityID === Number(this.refs.pro.value)) {
    //             this.refs.EN.value = this.state.Entities[i].EntityName;
    //             this.refs.ENType.value = this.state.Entities[i].EntityType;
    //             this.refs.legalFN.value = this.state.Entities[i].LegalFirstName;
    //             this.refs.legalLN.value = this.state.Entities[i].LegalLastName;
    //             this.refs.ADD.value = this.state.Entities[i].Address;
    //             this.refs.CI.value = this.state.Entities[i].City;
    //             this.refs.ST.value = this.state.Entities[i].State;
    //             this.refs.ZP.value = this.state.Entities[i].ZipCode;
    //             this.refs.CO.value = this.state.Entities[i].Country;
    //             this.refs.PH.value = this.state.Entities[i].Phone;

    //       await this.setState({ 
    //                 EntityID: this.refs.pro.value,
    //                 default_gp_lp_election: this.state.Entities[i].default_gp_lp_election,
    //                 selectedEntityStatus: this.state.Entities[i].status,
    //                 selectedEntityBOVStatus: this.state.Entities[i].DwollaBOVerificationStatus,
    //                 selectedEntityW9Status: this.state.Entities[i].w8n9formVerify,
    //                 selectedEntityId: Number(this.refs.pro.value),
    //                 selectedEntityType: this.state.Entities[i].EntityType,
    //                 selectedEntityFilesUploaded: this.state.Entities[i].filesUploaded,
	// 				selectedEntityController: this.state.Entities[i].EntityController,
    //                 ContactPrefSMS: this.state.Entities[i].ContactPrefSMS,
    //                 entityDetails:{...this.state.entityDetails,entityType:this.state.Entities[i].EntityType,
    //                     entityName:this.state.Entities[i].EntityName,legalFirstName:this.state.Entities[i].LegalFirstName,
    //                     legalLastName:this.state.Entities[i].LegalLastName,address:this.state.Entities[i].Address,
    //                     city:this.state.Entities[i].City, state:this.state.Entities[i].State,
    //                     zipCode:this.state.Entities[i].ZipCode, phone:this.state.Entities[i].Phone,
    //                     country:this.state.Entities[i].Country,
    //                 }
                 
    //             });

    //             break;
    //         }
    //         else {  // Selected 'nothing'... Reset values to default.
    //             await this.setState({
    //                 EntityID: this.refs.pro.value,
    //                 default_gp_lp_election: 2,
    //                 selectedEntityType: '',
    //                 selectedEntityStatus: 'verified',
    //                 selectedEntityBOVStatus: 'verified',
    //                 selectedEntityW9Status: 1,
    //                 selectedEntityId: Number(this.refs.pro.value),
    //                 selectedEntityFilesUploaded: true,
    //                 isEntityVerificationModalOpen: false,
    //                 ContactPrefSMS: false,
    //             }, () => {
	// 				this.refs.EN.value = '';
	// 				this.refs.ENType.value = '';
	// 				this.refs.legalFN.value = '';
	// 				this.refs.legalLN.value = '';
	// 				this.refs.ADD.value = '';
	// 				this.refs.CI.value = '';
	// 				this.refs.ST.value = '';
	// 				this.refs.ZP.value = '';
	// 				this.refs.CO.value = '';
	// 				this.refs.PH.value = '';
	// 			});
    //         }
    //     }
    // };




    getInvestorProfile = async () => {
        try {
            const impersonateID = await getImpersonateID();
            const { data } = await RequestService.get(`/api/getInvestorProfile?impersonateID=${impersonateID}`);

            if (data.code === 200) {
                this.setState({
                    User: data.rows[0],
                    legalFN: data.rows[0].LegalFirstName,
                    legalLN: data.rows[0].LegalLastName,
                });

                this.refs.FirstName.value = data.rows[0].FirstName;
                this.refs.LastName.value = data.rows[0].LastName;
                this.refs.Email.value = data.rows[0].Email;
                this.refs.GoogleAuthMFA.checked = (data.rows[0].mfaType === 1);

                if (data.rows[0].mfaType === 1) {
					const user = data.rows[0].Email;
					const service = 'EnergyFunders';
					const otpauth = authenticator.keyuri(user, service, data.rows[0].mfaPass);

					qrcode.toDataURL(otpauth, (err, imageUrl) => {
						if (err) {
							console.log('Error with QR');
							return;
						}

						this.setState({	QRCode: imageUrl });
					});
				}
            }
            else {
                alert('Could not retrieve your profile. Please try again later.');
            }
        }
        catch (err) {
            console.log(err);
        }
    };



    getInvestorEntities = async () => {
        try {
            const impersonateID = await getImpersonateID();
            const { data } = await RequestService.get(`/api/investor/profile/entities/${impersonateID}`);

            if (data.code === 200) {
            	let numEntities = 0;
            	data.rows.map(entity => {
            		if (entity.EntityID > 0 && entity.EntityController === 'Self')
            			numEntities++;
				});

                this.setState({
                    Entities: data.rows,
					isAccredited: data.isAccredited,
					numEntities: numEntities
                });
            }
            else {
                alert('Could not get list of entities. Please try again later.');
            }
        }
        catch (err) {
            console.log(err);
        }
    };





    toggleEntityVerificationModal = () => {
        const { isEntityVerificationModalOpen } = this.state;

        this.setState({ isEntityVerificationModalOpen: !isEntityVerificationModalOpen });
    };



    goToVerifyDwolla = () => {
        const { history } = this.props;

        this.toggleEntityVerificationModal();
        history.push({ pathname: '/app/VerifyDwolla' });
    };



    getDwollaFileVerificationData = async () => {
        const { selectedEntityId } = this.state;
        const url = `/api/investor/entity/${selectedEntityId}/missing-verification-files`;

        try {
            const { data } = await RequestService.get(url);

            this.openDwollaFileVerificationModal(data);
        }
        catch(error) {
            console.log('Error verifying missing documents', error);
            NotificationService.error(
                'Error verifying missing documents',
                10000,
            );
        }
    };



    openDwollaFileVerificationModal = (requiredDocs) => {
        let verifyController = false;
        let verifyBusiness = false;

        requiredDocs.forEach((key) => {
            if (key === ENTITY_VERIFY_BUSINESS_WITH_DOC) {
                verifyBusiness = true;
            }
            if (key === ENTITY_VERIFY_CONTROLLER_WITH_DOC) {
                verifyController = true;
            }
            if (key === ENTITY_VERIFY_BUSINESS_AND_WITH_DOC) {
                verifyController = true;
                verifyBusiness = true;
            }
        });

        this.setState({
            verifyBusiness,
            verifyController,
            isFileVerificationModalOpen: true,
        });
    };



    closeDwollaFileVerificationModal = () => {
        this.setState({
            verifyBusiness: false,
            verifyController: false,
            isFileVerificationModalOpen: false,
        });
    };






    render() {
        const {
            isNewEntityModalOpen, selectedEntityStatus, isEntityVerificationModalOpen, isFileVerificationModalOpen,
            isVerifyEntityModalOpen, selectedEntityId, selectedEntityType, verifyBusiness,
            verifyController, selectedEntityW9Status, /* selectedEntityFilesUploaded */
			isAccredited
        } = this.state;

        const showAddVerificationToEntity = (selectedEntityStatus === 'verified' || selectedEntityStatus === 'document');
        // const showAddVerificationFileToEntity = !(!selectedEntityFilesUploaded && selectedEntityStatus === 'document');
        const showAddVerificationFileToEntity = (selectedEntityStatus === 'document');    // May need to upload documents multiple times until 'verified' -- commented out above line.

        return (
            <>
          
        
                  
                     
                    {this.state.numEntities < 4 && ( 
                                 
                                        <div className="pull-right">
                                            <button
                                                className="px-5 py-4 m-0 btn btn-default upper-case-text btn-green text-white"
                                                onClick={this.verifyPersonalEntity}
                                                type="button"
                                            > Add Entity
                                            </button>
                                     </div>
                                  
                                )}
                 
             

                <NewEntityContainerComponent
                    isModalOpen={isNewEntityModalOpen}
                    onCloseModal={this.toggleNewEntityModal}
                    parentUpdateMethod={this.getInvestorEntities}
                    legalFN={this.state.legalFN}
                    legalLN={this.state.legalLN}
                />

                <NewEntityContainerComponent
                    stepNumber={3}
                    entityType={selectedEntityType}
                    createdEntityId={selectedEntityId}
                    isModalOpen={isVerifyEntityModalOpen}
                    onCloseModal={() => this.toggleVerificationModal(null)}
                    parentUpdateMethod={this.getInvestorEntities}
                    isVerification
                    dwollaStatus={selectedEntityStatus}
                    legalFN={this.state.legalFN}
                    legalLN={this.state.legalLN}
                    entityDetails ={this.state?.entityDetails}
                />

                <NewEntityContainerComponent
                    stepNumber={4}
                    entityType={selectedEntityType}
                    verifyBusiness={verifyBusiness}
                    createdEntityId={selectedEntityId}
                    verifyController={verifyController}
                    isModalOpen={isFileVerificationModalOpen}
                    parentUpdateMethod={this.getInvestorEntities}
                    onCloseModal={this.closeDwollaFileVerificationModal}
                    isVerification
                />

                <Modal
                    show={isEntityVerificationModalOpen}
                    onHide={this.toggleEntityVerificationModal}
                    style={{ width: '90%', margin: '0 auto' }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Welcome to EnergyFunders</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div>
                                <p>Before you can create a new entity, you need to complete the following steps:</p>
                                <br/>
                                <div className="col-md-2 nopadding imghide" style={{ paddingLeft: 0 }}/>
                                <div
                                    className="col-md-4"
                                    style={{
                                        padding: 20,
                                        textAlign: 'center',
                                    }}
                                >
                                    <p>
                                        <a
                                            href="javascript:"
                                            onClick={this.goToVerifyDwolla}
                                        >
                                            <span className="badge" style={{ fontSize: 40 }}>1</span>
                                        </a>
                                    </p>
                                    <p style={{ fontSize: 24 }}>
                                        <a
                                            href="javascript:"
                                            onClick={this.goToVerifyDwolla}
                                        >
                                            Verify Identity
                                        </a>
                                    </p>
                                    <p style={{ textAlign: 'justify' }}>
                                        This information helps us satisfy "Know Your Customer" (KYC) requirements.
                                    </p>
                                </div>
                                <div
                                    className="col-md-4"
                                    style={{
                                        padding: 20,
                                        textAlign: 'center',
                                    }}
                                >
                                    <p>
                                        <a
                                            href="javascript:"
                                            onClick={this.goToVerifyDwolla}
                                        >
                                            <span className="badge" style={{ fontSize: 40 }}>2</span>
                                        </a>
                                    </p>
                                    <p style={{ fontSize: 24 }}>
                                        <a
                                            href="javascript:"
                                            onClick={this.goToVerifyDwolla}
                                        >
                                            Link Bank Account
                                        </a>
                                    </p>
                                    <p style={{ textAlign: 'justify' }}>
                                        Linking your bank account allows you to use ACH to fund your investments and
                                        receive any distributions on the EnergyFunders platform.
                                    </p>
                                </div>
                                <div className="col-md-2 nopadding imghide" style={{ paddingLeft: 0 }}/>
                            </div>
                            <hr/>
                            <div style={{ textAlign: 'center' }}>
                                <input
                                    type="submit"
                                    className="btn btn-info"
                                    defaultValue="Set Up My Account"
                                    value="Set Up My Account"
                                    style={{
                                        width: 300,
                                        backgroundColor: '#5bb462',
                                    }}
                                    onClick={this.goToVerifyDwolla}
                                />
                                <br/>
                                <br/>
                                <a
                                    href="javascript:"
                                    onClick={() => this.toggleEntityVerificationModal()}
                                >
                                    Finish Later
                                </a>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

		
            </>
        );
    };
}


CreateNewEntity.propTypes = {
    history: PropTypes.object.isRequired,
    session: PropTypes.object.isRequired,
};

CreateNewEntity.defaultProps = {
    history: { push: () => null },
    session: {},
};

const mapState = ({ session }) => ({
    session: session.user,
});

export default withRouter(connect(mapState)(CreateNewEntity));
