
import HelloSign from 'hellosign-embedded';
import RequestService from '../api/RequestService';
import swal from 'sweetalert2';


const HelloSignW9 = async(props) => {
	let EntityID = props.EntityID;
	let SignatureId, SignatureRequestId = '';

	if (props.call === 'signW9Personal') {
		signW9Personal();
	}
	else if (props.call === 'signW9OtherPersonal') {
		signW9OtherPersonal();
	}
	else if (props.call === 'signW9Entity') {
		signW9Entity();
	}
	else if (props.call === 'signW8') {
		signW8();
	}

	// # End of Execution.



	async function signW8() {
		const { data } = await RequestService.post(`/api/signW8`);

		if (data) {
			SignatureId = data.signature_id;
			SignatureRequestId = data.signature_request_id;

			HelloSign.init(data.ClientID);

			HelloSign.open({
				url: data.URL,
				skipDomainVerification: data.skipDomainVerification,
				allowCancel: true,
				uxVersion: 2,
				messageListener: verifyForm
			});
		}
	}



	async function signW9Personal() {
		const { data } = await RequestService.post(`/api/signW9Personal`);

		if (data) {
			SignatureId = data.signature_id;
			SignatureRequestId = data.signature_request_id;

			HelloSign.init(data.ClientID);

			HelloSign.open({
				url: data.URL,
				skipDomainVerification: data.skipDomainVerification,
				allowCancel: true,
				uxVersion: 2,
				messageListener: verifyForm
			});
		}
	}



	async function signW9OtherPersonal() {
		let formTemp = [];
		formTemp.push(`EntityID=${encodeURIComponent(EntityID)}`);
		const formBody = formTemp.join("&");

		const { data } = await RequestService.post({
			url: `/api/signW9OtherPersonal`,
			data: formBody
		});

		if (data) {
			SignatureId = data.signature_id;
			SignatureRequestId = data.signature_request_id;

			HelloSign.init(data.ClientID);

			HelloSign.open({
				url: data.URL,
				skipDomainVerification: data.skipDomainVerification,
				allowCancel: true,
				uxVersion: 2,
				messageListener: verifyForm
			});
		}
	}



	async function signW9Entity() {
		const formTemp = [];
		formTemp.push(`EntityID=${encodeURIComponent(EntityID)}`);
		const formBody = formTemp.join('&');

		const { data } = await RequestService.post({
			url: `/api/signW9Entity`,
			data: formBody,
		});

		if (data) {
			SignatureId = data.signature_id;
			SignatureRequestId = data.signature_request_id;

			HelloSign.init(data.ClientID);

			HelloSign.open({
				url: data.URL,
				skipDomainVerification: data.skipDomainVerification,
				allowCancel: true,
				uxVersion: 2,
				messageListener: verifyForm
			});
		}
	}



	async function verifyForm(eventData) {
		if (eventData.event === HelloSign.EVENT_SIGNED) {
			swal({
				title: 'Please wait while we finalize the document.',
				text: 'This may take up to 30 seconds.',
				allowEscapeKey: false,
				allowOutsideClick: false,

				onBeforeOpen: () => {
					swal.showLoading()
				},
			});

			let formBody = [];
			formBody.push("EntityID=" + encodeURIComponent(EntityID));
			formBody.push("SignatureId=" + encodeURIComponent(SignatureId));
			formBody.push("SignatureRequestId=" + encodeURIComponent(SignatureRequestId));
			formBody.push("data=" + JSON.stringify(eventData));
			formBody = formBody.join("&");

			const { data } = await RequestService.post({
				url: '/api/signW8nW9Verify',
				data: formBody,
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/x-www-form-urlencoded'
				}
			});

			if (data.status === 'success' && data.check) {
				swal.close();

				let confirm = await swal({
					type: 'success',
					title: 'Thank you for signing your W9!',
					showConfirmButton: true,
					timer: 3000
				});

				if (confirm) {
					window.location = window.location;   // Force a reload to reset Bell Counter and Button.
				}
			}
			else {
				setTimeout(() => verifyForm(eventData), 5000);  // Check again after 5 second.
			}
		}
		else {
			swal({
				type: 'warning',
				title: 'You will need to complete a W9 before you can invest.',
				showConfirmButton: true
			});
		}
	}
};


export default HelloSignW9;
