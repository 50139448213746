import { combineReducers } from 'redux';
import { sessionReducer } from 'redux-react-session';

import projectStatusManager from '../components/admin/ProjectStatusContainerComponent/reducers/reducer';
import externalImages from '../components/shared/ExternalImageContainerComponent/reducers/reducer';
import allInvestments from '../components/shared/AllInvestmentsContainerComponent/reducers/reducers';
import projectUpdate from '../components/admin/ProjectUpdate/reducers/ProjectUpdateReducer';
import returnOfCapital from '../components/admin/RecordReturnOfCapital/reducers/reducer';
import projectDetails from '../components/shared/ProjectDetailsContainerComponent/reducers/reducer';
import projectEditor from '../components/shared/ProjectEditorContainerComponent/reducers/reducer';
import projects from './ProjecReducer';
import investorFiles from '../components/shared/InvestorFilesContainerComponent/reducers/reducer';
import investorMassFileUpload from '../components/admin/AllInvestorFilesContainerComponent.js/reducers/reducers';
import investorDashboard
    from '../components/investor/InvestorDashboardContainerComponent/reducers/reducer';
import newEntity from '../components/shared/NewEntityContainerComponent/reducers/reducer';
import investorEmailHistory from '../components/shared/EmailHistoryContainerComponent/reducers/reducer';
import hsEmails from '../components/admin/HubSpotEmailImportComponent/reducers/reducer';


const rootReducer = combineReducers({
    session: sessionReducer,
    investorMassFileUpload,
    projectStatusManager,
    investorEmailHistory,
    investorDashboard,
    returnOfCapital,
    projectDetails,
    allInvestments,
    externalImages,
    investorFiles,
    projectUpdate,
    projectEditor,
    newEntity,
    projects,
    hsEmails,
});

export default rootReducer;
