
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactLoaderSpinner from 'react-loader-spinner';
import moment from 'moment';
import { Bar, Line } from 'react-chartjs-2';

import RequestService from '../../api/RequestService';
import { formatMoney } from '../../utils/General';

import AddProductionData from '../admin/AddProductionData';



function ShowProductionData(props) {
	const [ProjectID] = useState(props.location?.state?.projectID || 0);
	const [isAdminRole, setIsAdminRole] = useState(false);
	const [projectName, setProjectName] = useState(null);
	const [productionData, setProductionData] = useState(null);
	const [productionDataChartInfo, setProductionDataChartInfo] = useState({});



	useEffect(() => {
		window.scrollTo(0, 0);

		if (ProjectID > 0) {
			getProductionData();

			if (props.location?.state?.role === 'SuperAdmin') {
				getUserRoles();
			}
		}
		else {
			window.location.href = '/app/Allinvestments';
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);	// [] Means componentDidMount (once).



	async function getProductionData() {
		const { data } = await RequestService.get(`/api/project/getProductionData/${ProjectID}`);
		const { data: chartData } = await RequestService.get(`/api/project/getProductionDataChartInfo/${ProjectID}`);

		setProjectName(data.projectName);
		setProductionData(data.results);
		setProductionDataChartInfo(chartData.reducedData);
	}



	async function getUserRoles() {
		const { data } = await RequestService.get(`/api/getUserRoles`);

		if (data.length > 0) {
			data.forEach(element => {
				if (element.Role === 'SuperAdmin') {
					setIsAdminRole(true);
				}
			});
		}
	}



	const getBarChartDataConfig = (labels, data, title) => ({
		labels,
		datasets: [{
			label: title,
			data,
			backgroundColor: 'rgba(126, 52, 153, .45)',
			borderColor: '#602a7b',
			pointBorderWidth: 2,
			beginAtZero: true,
		}],
	});



	const getChartOptions = (xAxesLabel, yAxesLabel, others = {}) => ({
		maintainAspectRatio: false,
		scales: {
			yAxes: [{
				scaleLabel: {
					display: true,
					labelString: yAxesLabel,
				},
				ticks: {
					suggestedMin: 0,
				},
			}],
			xAxes: [{
				scaleLabel: {
					display: true,
					labelString: xAxesLabel,
				},
			}],
		},
		...others,
	});



	const toolTipsConfig = {
		tooltips: {
			callbacks: {
				label(tooltipItem, data) {
					const { label } = data.datasets[0];

					return `${label}: $${tooltipItem.yLabel.toLocaleString()}`;
				},
			},
		},
	};



	if (projectName && productionData) {
		return (
			<div>
				<div className="container body">
					<div className="main_container">
						{/* page content */}
						<div className="right_col" role="main">
							<div className="page-title">
								<div className="title_left">
									<h3>Project Data for: {projectName}</h3>
								</div>
							</div>
							<div className="clearfix" />
							<div className="row">
								<div className="col-md-12 col-sm-12 col-xs-12">
									<div className="x_panel">
										<div className="x_title">
											{/*<h4 className='up'>Project Data</h4>*/}
											<div className="clearfix"/>
										</div>
										<div className="x_content">

											{isAdminRole && <AddProductionData
												projectID={ProjectID}
												projectName={projectName}
												getProductionData={getProductionData}
											/>}

											<div className="col-xs-12 col-md-6 margin-top-30px">
												<div className="panel panel-default">
													<Bar
														id="trk001"
														height={340}
														data={getBarChartDataConfig(
															productionDataChartInfo.dataLabels,
															productionDataChartInfo.dataAmounts,
															'Monthly Payout',
														)}
														options={getChartOptions(
															'Month and Year',
															'Monthly Payout',
															toolTipsConfig,
														)}
													/>
												</div>
											</div>
											<div className="col-xs-12 col-md-6 margin-top-30px">
												<div className="panel panel-default">
													<Bar
														id="trk001"
														height={340}
														data={getBarChartDataConfig(
															productionDataChartInfo.dataLabels,
															productionDataChartInfo.cumulativeAmounts,
															'Cumulative Payout',
														)}
														options={getChartOptions(
															'Month and Year',
															'Cumulative Payout',
															toolTipsConfig,
														)}
													/>
												</div>
											</div>

											<div id="datatable_wrapper" className="dataTables_wrapper form-inline dt-bootstrap no-footer">
												<div className="table-responsive">
													<table className="table table-striped jambo_table">
														<thead className="thead-inverse">
														<tr className="headings">
															<th className="column-title">Date</th>
															<th className="column-title">Total Payout</th>
															<th className="column-title">MCF Vol</th>
															<th className="column-title">BO Vol</th>
															<th className="column-title">Gas Price</th>
															<th className="column-title">Oil Price</th>
															<th className="column-title">Total OpEx</th>
															<th className="column-title">Fund Tax</th>
															<th className="column-title">Fund Escrow</th>
															<th className="column-title">Well Status</th>
														</tr>
														</thead>
														<tbody>
														{productionData.length > 0 ? productionData.map((data, i) => {
																return(
																	<tr key={i} className="even">
																		<td className=" ">{moment(data.MonthYear).format("MMM DD, YYYY")}</td>
																		<td className=" ">${formatMoney(data.TotalPayout)}</td>
																		<td className=" ">{data.GasMCF}</td>
																		<td className=" ">{data.OilBarrels}</td>
																		<td className=" ">${data.GasPrice}</td>
																		<td className=" ">${data.OilPrice}</td>
																		<td className=" ">${formatMoney(data.TotalOpEx)}</td>
																		<td className=" ">${formatMoney(data.FundTax)}</td>
																		<td className=" ">${formatMoney(data.FundEscrow)}</td>
																		<td className=" ">(BPO/APO from Calc)</td>
																	</tr>
																);
															}) :
															<tr className="even pointer">
																<td colSpan="14" className="text-center">No Data</td>
															</tr>
														}
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
	else {
		return (
			<ReactLoaderSpinner
				type="ThreeDots"
				color="#5bb462"
				height="100"
				width="100"
			/>
		);
	}
}



const mapState = ({session}) => ({
	session: session.user,
	checked: session.checked,
	authenticated: session.authenticated
});

export default withRouter(connect(mapState)(ShowProductionData));
