import React, { Component } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import PlacesAutocomplete, { geocodeByAddress, /*getLatLng*/ } from 'react-places-autocomplete';
import Icon from "./components/shared/IconComponent/IconComponent";


class LocationSearchInput extends Component {
	constructor(props) {
		super(props);

		this.state = {
			address: '',
			status: 'start'
		};
	}


	loadScript = (src) => {
		return new Promise(function(resolve, reject) {
			let script = document.createElement('script');
			script.src = src;
			script.addEventListener('load', function () {
				resolve();
			});
			script.addEventListener('error', function (e) {
				reject(e);
			});
			document.body.appendChild(script);
		})
	};


	doLoad = () => {
		let self = this;
		let my_script = this.loadScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyA6XN8SYUzQB3jwytnZhDapuimyCXes3Pw&libraries=places');
		my_script.then(function() {
			self.setState({'status': 'done'});
		}).catch(function() {
			self.setState({'status': 'error'});
		})
	};


	handleChange = (address) => {
		this.setState({ address });
	};


	handleSelect = (address) => {
		geocodeByAddress(address)
			.then(results => {
				// console.log('results[0] = ', JSON.stringify(results[0]));
				this.props.onSelectLocation(results[0]);

				document.getElementById('addressSearchDiv').setAttribute('style', 'display: none;');
				// document.getElementById('formattedAddress').setAttribute('style', 'font-size: 0.9em;');

				this.setState({
					address: results[0].formatted_address
				});

				// getLatLng(results[0])
			})
			// .then(latLng => console.log('Success', latLng))
			// .catch(error => console.error('Error', error));
	};


	render() {
		let self = this;
		if (self.state.status === 'start') {
			self.state.status = 'loading';
			setTimeout(function () {
				self.doLoad()
			}, 0);
		}

		return (
			<div>{self.state.status === 'done' &&
				<PlacesAutocomplete
					value={this.state.address}
					onChange={this.handleChange}
					onSelect={this.handleSelect}
				>
					{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
						<div id="addressSearchDiv">
							
							<div className="d-flex d-flex align-items-center">
							{/* <label htmlFor="formattedAddress">Address Search * </label>&nbsp; */}
						
						
							<input
								{...getInputProps({
									placeholder: 'Physical Address (No PO Boxes)',
									className: 'location-search-input verify-form-control form-control',
									id: 'formattedAddress',
									ref: 'formattedAddress',
									autoComplete: 'new-address'
								})} 
							/>
							<div style={{marginLeft:10}}>
							<OverlayTrigger 
							placement="top" 
							overlay={
								<Tooltip id="addressSearchTooltip">
									<div style={{padding: 5, fontSize: '1.2em'}}>If you don't see your full address in this search field, please select the closest one. The city, state, zipcode will appear, and you can then edit those fields.</div>
								</Tooltip>}>
							<a><Icon iconName="info-circle" extraClasses="card-info" /></a>
						</OverlayTrigger>
</div>
							</div>
							<div className="autocomplete-dropdown-container">
								{loading && <div>Loading...</div>}
								{suggestions.map(suggestion => {
									const className = suggestion.active
										? 'suggestion-item--active'
										: 'suggestion-item';
									// inline style for demonstration purpose
									const style = suggestion.active
										? { backgroundColor: '#fafafa', cursor: 'pointer' }
										: { backgroundColor: '#ffffff', cursor: 'pointer' };
									return (
										<div
											{...getSuggestionItemProps(suggestion, {
												className,
												style,
											})}
										>
											<span>{suggestion.description}</span>
										</div>
									);
								})}
							</div>
							<span id="error_formattedAddress" className="text-danger" />
							
						</div>
					)}
				</PlacesAutocomplete>
			}</div>
		);
	}
}

export default LocationSearchInput;
