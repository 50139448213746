import RequestService from '../../../../api/RequestService';
import NotificationService from '../../../../utils/NotificationService';

export const HS_EMAIL_REQ_SUCCESS = 'HS_EMAIL_REQ_SUCCESS';
export const HS_EMAIL_REQ_START = 'HS_EMAIL_REQ_START';
export const HS_EMAIL_REQ_END = 'HS_EMAIL_REQ_END';

const CLICK_BUTTON_MESSAGE = 'Click the \'Reload Email List\' to see the ones that where imported';
const start = () => ({ type: HS_EMAIL_REQ_START });
const end = () => ({ type: HS_EMAIL_REQ_END });
const emailReqSuccess = data => ({
    type: HS_EMAIL_REQ_SUCCESS,
    data,
});


export const getHSPublishedEmails = (offset, limit) => async (dispatch) => {
    const url = `/api/admin/hub-spot/email-start/${offset}/limit/${limit}`;

    try {
        dispatch(start());
        const { data } = await RequestService.get(url);

        delete data.limit;
        delete data.total;

        dispatch(end());
        dispatch(emailReqSuccess(data));
    } catch (error) {
        console.log('Error requesting the HS Email List: ', error);
        NotificationService.error(
            'Error requesting the HS Email List',
            10000,
        );
    }
};

export const importSelectedEmails = selectedEmailMap => async (dispatch) => {
    try {
        dispatch(start());
        await RequestService.post({
            url: '/api/admin/hub-spot/email/selected',
            data: selectedEmailMap,
        });

        dispatch(end());
        await NotificationService.success(
            `The selected the emails are being imported\n${CLICK_BUTTON_MESSAGE}`,
            10000,
        );
    } catch (error) {
        console.log(error);
        NotificationService.error(
            'Error importing emails from HubSpot to the platform',
            10000,
        );
    }
};

export const importAllEmails = () => async (dispatch) => {
    const url = '/api/admin/hub-spot/email/all';

    try {
        dispatch(start());
        await RequestService.post({
            url,
            data: {},
        });

        dispatch(end());
        await NotificationService.success(
            `The all the emails are being imported\n${CLICK_BUTTON_MESSAGE}`,
            10000,
        );
    } catch (error) {
        console.log('Error importing all emails: ERROR: ', error);
        NotificationService.error(
            'Error importing all emails',
            10000,
        );
    }
};
