import React, { Component } from 'react'
import { Modal } from 'react-bootstrap';
import SelectComponent from "../../../../shared/InputComponents/SelectComponent/SelectComponent";
import SingleLineInputComponent from "../../../../shared/InputComponents/SingleLineInputComponent/SingleLineInputComponent";
import Icon from '../../../../shared/IconComponent/IconComponent';
import RequestService from '../../../../../api/RequestService';
import { HTTP_ERR_BAD_REQUEST, HTTP_SUCCESS_CREATED } from '../../../../../utils/ApplicationConstants';
import NotificationService from '../../../../../utils/NotificationService';

export default class ReceiveOnlyEntityModal extends Component {
    constructor(props) {
        super(props);
        this.state={
            entityType:'',
            firstName:'',
            lastName:'',
            email:props.Email,
            businessName:'',
            investorId:props.investorId


        }
    }
    componentWillReceiveProps(props) {
        this.setState({
            email:props.Email,
            investorId:props.investorId,
         
          });

    }

 onInputChange =(event)=>{
    this.clearFieldError(event);
    this.setState({
        [event.target.name]: event.target.value,
     
      });
}

clearFieldError(event) {

    if (event.target.name === "firstName") {
      this.refs.firstName.classList.remove("signup-form-control-warning");
    }
    if (event.target.name === "lastName") {
      this.refs.lastName.classList.remove("signup-form-control-warning");
    }

 
  
  }


onSaveClickHandler = async(e)=>{
    let ret =true;
    e.preventDefault();

    if (
      this.refs.firstName.value.trim() === "" ||
      this.refs.lastName.value.trim() === ""  
   
    ) {
      if (this.refs.firstName.value.trim() === "") {
        this.refs.firstName.classList.add("signup-form-control-warning");
      }
      if (this.refs.lastName.value.trim() === "") {
        this.refs.lastName.classList.add("signup-form-control-warning");
      }
  
      ret =false;
    }

    const {firstName,lastName,email,businessName,investorId} =this.state;

    if(ret){
    let formBody = [];

     formBody.push("firstName=" + encodeURIComponent(firstName));
     formBody.push("lastName=" + encodeURIComponent(lastName));
     formBody.push("email=" + encodeURIComponent(email));
     formBody.push("businessName=" + encodeURIComponent(businessName));
     formBody.push("UserID=" + encodeURIComponent(investorId));

    
       formBody = formBody.join("&");
  
  const { status } =  await RequestService.post({
      url: '/api/createDwollaReceiveOnlyUser',
      data: formBody
  });


 if (status >= HTTP_ERR_BAD_REQUEST) {
    NotificationService.error("Error on createing dwolla receive only entity");
    const closeModal = this.props.onCloseModal;
    closeModal()
} else if (status === HTTP_SUCCESS_CREATED) {
    NotificationService.success("Receive-Only Entity Created");
    const closeModal = this.props.onCloseModal;
    closeModal()

    }
 }
}

  render() {
   
      const {isModalOpen,onCloseModal} =this.props;
      const {entityType,firstName,lastName,email,businessName} =this.state;
    return (
        <Modal
        className="no-margin"
        show={isModalOpen}
        onHide={onCloseModal}
    >
        <Modal.Header closeButton>
            <h3 className="heading-text capitalize-text">
                Add Receive-only Entity
            </h3>
        </Modal.Header>
        <Modal.Body>
        <div className="container">
			<div className="col-xs-12">
				<div className="col-xs-12 col-sm-4">
				
                        <div className="form-group mb-5">
                                    <label>Entity Type:</label>
                                    <input type="text"
                                         	name="entityType"
                                             ref="entityType"
                                             value ={entityType}
                                             className="signup-form-control form-control"
                                             onChange={this.onInputChange} 
                                             placeholder="Receive only" readOnly
                                 />
                                </div>
                            
				</div>
                <div className="col-xs-12 col-sm-4">
				    <div className="form-group mb-5">
                                    <label>First Name</label>
                                    <input type="text"
                                         	name="firstName"
                                             ref="firstName"
                                             value ={firstName}
                                             className="signup-form-control form-control"
                                             onChange={this.onInputChange} 
                                             placeholder="First Name"
                                 />
                                </div>
                            
				</div>
                <div className="col-xs-12 col-sm-4">
				    <div className="form-group mb-5">
                                    <label>Last Name</label>
                                    <input type="text"
                                         	name="lastName"
                                             ref="lastName"
                                             value ={lastName}
                                             className="signup-form-control form-control"
                                             onChange={this.onInputChange} 
                                             placeholder="Last Name"
                                 />
                                </div>
                            
				</div>
                <div className="col-xs-12 col-sm-4">
				    <div className="form-group mb-5 ">
                                    <label>Email</label>
                                    <input type="text"
                                         	name="email"
                                             ref="email"
                                             value ={email}
                                             className="signup-form-control form-control"
                                            //  onChange={this.onInputChange} 
                                             placeholder="Email" 
                                             readOnly
                                 />
                                </div>
                            
				</div>
                <div className="col-xs-12 col-sm-4">
				    <div className="form-group mb-5">
                                    <label>Business Name</label>
                                    <input type="text"
                                         	name="businessName"
                                             value ={businessName}
                                             className="signup-form-control form-control"
                                             onChange={this.onInputChange} 
                                             placeholder="Business Name"
                                 />
                                </div>
                            
				</div>
			</div>
		</div>
        </Modal.Body>
        <Modal.Footer>
            <div className="col-xs-12">
            <div className="pull-left">
            <button className="btn btn-primary btn-green capitalize-text"
                                        onClick={this.onSaveClickHandler}>
                                    save
                                </button>
                        <button
                            className="btn btn-danger capitalize-text"
                             type="button" onClick={onCloseModal}
                        >
                            <Icon
                                iconName="times"
                                extraClasses="white-text"
                            />{" "}
                            Cancel
                        </button>
                    </div>
          
            </div>
        </Modal.Footer>
    </Modal>
    )
  }
}
