import React from "react";

const ViewerHeaderComponent = () => {
    return (
        <tr>
            <td style={{padding: "20px 50px", border: "none"}}>
                <a href="https://www.energyfunders.com/"
                   target="_blank"
                   rel="noopener noreferrer"
                >
                    <img
                        src="https://cdn2.hubspot.net/hub/2935563/hubfs/Email%20Template%20Files/EF_Email_Logo.png"
                        alt="EF_Email_Logo"
                        width="245"
                        height="50"
                        style={{verticalAlign: "bottom"}}
                        className="CToWUd"/>
                </a>
            </td>
            <td className="visible-md visible-lg">
                <table>
                    <tbody>
                    <tr>
                        <td>
                            <a href="https://twitter.com/EnergyFunders"
                               target="_blank"
                               rel="noopener noreferrer"
                            >
                                <img
                                    src="https://cdn2.hubspot.net/hub/2935563/hubfs/Email%20Template%20Files/EF_Email_Social_Twitter.png"
                                    alt="Twitter"
                                    title="Twitter"
                                    style={{
                                        verticalAlign: "bottom",
                                        width: "30px!important",
                                        height: "30px!important"
                                    }}
                                    width="30"
                                    height="30"
                                    className="CToWUd"/>
                            </a>
                        </td>
                        <td>
                            <a href="https://www.facebook.com/EnergyFunders/"
                               target="_blank"
                               rel="noopener noreferrer"
                            >
                                <img
                                    src="https://cdn2.hubspot.net/hub/2935563/hubfs/Email%20Template%20Files/EF_Email_Social_FB.png"
                                    alt="Facebook"
                                    title="Facebook"
                                    style={{
                                        verticalAlign: "bottom",
                                        width: "30px!important",
                                        height: "30px!important"
                                    }}
                                    width="30"
                                    height="30"
                                    className="CToWUd"/>
                            </a>
                        </td>
                        <td>
                            <a href="https://www.linkedin.com/company/energyfunders/"
                               target="_blank"
                               rel="noopener noreferrer"
                            >
                                <img
                                    src="https://cdn2.hubspot.net/hub/2935563/hubfs/Email%20Template%20Files/EF_Email_Social_LinkedIn.png"
                                    alt="LinkedIn"
                                    title="LinkedIn"
                                    style={{
                                        verticalAlign: "bottom",
                                        width: "30px!important",
                                        height: "30px!important"
                                    }}
                                    width="30"
                                    height="30"
                                    className="CToWUd"/>
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </td>
        </tr>
    );
};

export default ViewerHeaderComponent;
