import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Collapse } from 'react-bootstrap';
import FileDetailsEditorComponent from '../../shared/FileDetailsEditorComponent/FileDetailsEditorComponent';
import * as InvestorActions from '../../shared/InvestorFilesContainerComponent/actions/actions';
import * as MassUploadActions from './actions/actions';
import Icon from '../../shared/IconComponent/IconComponent';
import FileSelectionViewer from './components/FileSelectionViewer';
import { getInputFileList, getUUID } from '../../../utils/General';
import InvestorFileViewerComponent
    from '../../shared/InvestorFIleViewerComponent/InvestorFileViewerComponent';
import FilterComponent from './components/FilterComponent';
import HideComponent from "../../shared/HideComponent/HideComponent";

class AllInvestorFilesContainerComponent extends React.Component {
    IS_ADMIN = true;

    constructor(props) {
        super(props);

        this.state = {
            fileId: getUUID(),
            selectedFiles: [],
            allInvestorFiles: [],
            resetMethod: () => null,
            allInvestorContractFiles : [],
            inContractTab: false
        };

        this.refFileInputEl = React.createRef();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const {
            getAllInvestorFileTypes, getAllProjectsInServer,
            userId, getAllInvestorFile,getAllContractFiles,allInvestorFiles
        } = this.props;

        getAllInvestorFileTypes(userId, this.IS_ADMIN);
        getAllProjectsInServer(userId, this.IS_ADMIN);
        getAllInvestorFile();
       getAllContractFiles();
        const { AllInvestorContractFilesList} = this.props;
        this.setState({ allInvestorFiles, allInvestorContractFiles: AllInvestorContractFilesList });
    }

    componentDidUpdate(prevProps) {
        const { allInvestorFiles, AllInvestorContractFilesList } = this.props;
        const _strProjectList = JSON.stringify(allInvestorFiles);
        const _strPrevProjectList = JSON.stringify(prevProps.allInvestorFiles);
        const _strContractFilesList = JSON.stringify(AllInvestorContractFilesList);
        const _strPrevContractFilesList = JSON.stringify(prevProps.AllInvestorContractFilesList);

        if (_strPrevProjectList !== _strProjectList) {
            this.setState({ allInvestorFiles }); // eslint-disable-line
        }
        if (_strContractFilesList !== _strPrevContractFilesList) {
            this.setState({  allInvestorContractFiles: AllInvestorContractFilesList }); // eslint-disable-line
        }
    }
    onTabChange = (e) => {
        if (e == "contractTab") {
            this.setState({ inContractTab: true });
        } else {
            this.setState({ inContractTab: false });
        }
    }

    onFilter = (filterKey, filterValue) => {
        const { resetMethod } = this.state;
        const { allInvestorFiles, AllInvestorContractFilesList } = this.props;
        const newList = allInvestorFiles.filter(item => item[filterKey]
            && item[filterKey].toString() === filterValue);

        const newContractList = AllInvestorContractFilesList.filter(item => item[filterKey]
            && item[filterKey].toString() === filterValue);
        // console.log("AllInvestorContractFilesList", AllInvestorContractFilesList)
        this.setState({ allInvestorFiles: newList });
        this.setState({ allInvestorContractFiles: newContractList })
        resetMethod(1);
    };

    onClearFilter = () => {
        const { allInvestorFiles ,AllInvestorContractFilesList} = this.props;

        this.setState({ allInvestorFiles });
        this.setState({  allInvestorContractFiles: AllInvestorContractFilesList });
    };

    setResetMethod = (resetMethod) => {
        this.setState({ resetMethod });
    };

    // FILE MANAGER
    onFileInputChange = (event) => {
        const { files } = event.target;
        const { selectedFiles } = this.state;
        const fileObjList = getInputFileList(files, selectedFiles)
            .map((item) => {
                item.key = getUUID(); // eslint-disable-line
                return item;
            });

        this.setState({ selectedFiles: fileObjList }, () => {
            this.refFileInputEl.current.value = null;
        });
    };

    onDeleteSelectedFile = (index) => {
        const { selectedFiles } = this.state;
        const listCopy = [...selectedFiles];

        listCopy.splice(index, 1);

        this.setState({ selectedFiles: listCopy });
    };

    onCancelFileUpload = () => {
        this.setState({
            selectedFiles: [],
            fileId: getUUID(),
        });
    };

    onUploadFilesToServer = (fileInformation) => {
        const { massFileUpload } = this.props;
        const { selectedFiles } = this.state;
        const formData = new FormData();

        formData.append('info', JSON.stringify(fileInformation));
        selectedFiles.forEach((item) => {
            formData.append('massFileUpload', item);
        });

        massFileUpload(formData, this.onCancelFileUpload);
    };

    onSetFileAsDeleted = (fileId, investorId) => {
        const { markFileAsDeleted } = this.props;

        markFileAsDeleted(investorId, fileId);
    };

    onUpdateFileInfo = (fileId, formData, investorId) => {
        const { updateInvestorFile } = this.props;

        updateInvestorFile(investorId, fileId, formData);
    };

    onDownloadFileFromS3 = (fileId, investorId) => {
        const { downloadFileFromServer } = this.props;

        downloadFileFromServer(investorId, fileId, this.IS_ADMIN);
    };

    // RENDER MANAGER
    render = () => {
        const {
            fileTypeList, regCfProjectList, regDProjectList,
            allProjects, getAllInvestorFile,AllInvestorContractFilesList,getAllContractFiles
        } = this.props;
        const { selectedFiles, fileId, allInvestorFiles, allInvestorContractFiles } = this.state;
// console.log("allInvestorContractFiles",allInvestorContractFiles);
        const isSelectingFile = (selectedFiles.length !== 0);
        const pageTitle = isSelectingFile ? 'Upload Files' : 'All Investor Documents';

        return (
            <div className="col-xs-12">
                <div className="pull-left">
                    <fieldset className="margin-top-30px">
                        <h2 className="capitalize-text">
                            {pageTitle}
                        </h2>
                    </fieldset>
                </div>
                <div style={{clear: 'both'}} />

                <HideComponent hide={isSelectingFile}>
                    <FilterComponent
                        allProjects={allProjects}
                        fileTypeList={fileTypeList}
                        onPerformFilter={this.onFilter}
                        onClearFiltering={this.onClearFilter}
                        inContractTab= {this.state.inContractTab}
                    />
                </HideComponent>

                <fieldset className="margin-top-30px">
                    <legend>
                        <div className="pull-right">
                            <button
                                className="btn btn-default upper-case-text"
                                onClick={ () => {getAllInvestorFile(); getAllContractFiles();}}
                                type="button"
                            >
                                <Icon iconName="refresh"/>
                                {' '}
                                Refresh Files List
                            </button>
                            <label
                                className="btn btn-default upper-case-text"
                                htmlFor="massFileUploadInput"
                            >
                                <Icon iconName="files-o"/>
                                {' '}
                                Bulk Select Files
                                <input
                                    multiple
                                    ref={this.refFileInputEl}
                                    type="file"
                                    className="no-display"
                                    id="massFileUploadInput"
                                    onChange={this.onFileInputChange}
                                />
                            </label>
                        </div>
                    </legend>

                    <Collapse in={isSelectingFile}>
                        <div>
                            <div className="col-xs-12">
                                <FileDetailsEditorComponent
                                    investorFileTypes={fileTypeList}
                                    onCancelFileEdit={this.onCancelFileUpload}
                                    onSaveChanges={this.onUploadFilesToServer}
                                    regCfProjectList={regCfProjectList}
                                    regDProjectList={regDProjectList}
                                    fileId={fileId}
                                    isMassUpload
                                />
                            </div>

                            <div className="col-xs-12">
                                <FileSelectionViewer
                                    selectedFileList={selectedFiles}
                                    onDeleteSelectedFile={this.onDeleteSelectedFile}
                                />
                            </div>
                        </div>
                    </Collapse>

                    <Collapse in={!isSelectingFile}>
                        <div>
                            <InvestorFileViewerComponent
                            contractFilesList={allInvestorContractFiles}
                            onTabChange={this.onTabChange}
                                allProjects={allProjects}
                                getAllContractFiles={getAllContractFiles}
                                fileTypeList={fileTypeList}
                                onDeleteFile={this.onSetFileAsDeleted}
                                onFileDownload={this.onDownloadFileFromS3}
                                projectFileList={allInvestorFiles}
                                updateInvestorFile={this.onUpdateFileInfo}
                                setResetMethod={this.setResetMethod}
                                showInvestorData
                                isAdmin
                            />
                        </div>
                    </Collapse>
                </fieldset>
            </div>
        );
    };
}

const mapStateToProp = (_state) => {
    const { investorFiles, session, investorMassFileUpload } = _state;
    const { fileTypeList, allProjects } = investorFiles;
    const { regCfProjectList, regDProjectList } = allProjects;
    const { id: userId } = session.user;

    return {
        userId,
        allProjects,
        fileTypeList,
        regDProjectList,
        regCfProjectList,
        ...investorMassFileUpload,
    };
};

const mapDispatchToProps = (_dispatch) => {
    const {
        getAllInvestorFileTypes, getAllProjectsInServer,
        markFileAsDeleted, downloadFileFromServer,
    } = bindActionCreators(InvestorActions, _dispatch);

    return {
        markFileAsDeleted,
        downloadFileFromServer,
        getAllProjectsInServer,
        getAllInvestorFileTypes,
        ...bindActionCreators(MassUploadActions, _dispatch),
    };
};

AllInvestorFilesContainerComponent.propTypes = {
    allInvestorFiles: PropTypes.array.isRequired,
    AllInvestorContractFilesList: PropTypes.array.isRequired,
    allProjects: PropTypes.object.isRequired,
    downloadFileFromServer: PropTypes.func.isRequired,
    fileTypeList: PropTypes.array.isRequired,
    getAllInvestorFile: PropTypes.func.isRequired,
    getAllContractFiles: PropTypes.func.isRequired,
    getAllInvestorFileTypes: PropTypes.func.isRequired,
    getAllProjectsInServer: PropTypes.func.isRequired,
    markFileAsDeleted: PropTypes.func.isRequired,
    massFileUpload: PropTypes.func.isRequired,
    regCfProjectList: PropTypes.array.isRequired,
    regDProjectList: PropTypes.array.isRequired,
    updateInvestorFile: PropTypes.func.isRequired,
    userId: PropTypes.number.isRequired,
};

AllInvestorFilesContainerComponent.defaultProps = {
    allInvestorFiles: [],
    allProjects: {},
    downloadFileFromServer: () => null,
    fileTypeList: [],
    AllInvestorContractFilesList: [],
    getAllInvestorFile: () => null,
    getAllContractFiles: () => null,
    getAllInvestorFileTypes: () => null,
    getAllProjectsInServer: () => null,
    markFileAsDeleted: () => null,
    massFileUpload: () => null,
    regCfProjectList: [],
    regDProjectList: [],
    userId: 0,
};

const connectedComponent = connect(
    mapStateToProp,
    mapDispatchToProps,
)(AllInvestorFilesContainerComponent);

export default connectedComponent;
