import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from './actions/actions';
import EmailHistoryViewerComponent from './components/EmailHistoryViewerComponent';
import SingleLineInputComponent from '../InputComponents/SingleLineInputComponent/SingleLineInputComponent';
import ProjectUpdateViewer from '../projectUpdateViewerComponent/ProjectUpdateViewer';
import DateInputComponent from '../InputComponents/DateInputComponent/DateInputComponent';
import { USER_INVESTOR } from '../../../utils/ApplicationConstants';
import SelectComponent from '../InputComponents/SelectComponent/SelectComponent';
import HideComponent from '../HideComponent/HideComponent';
import Icon from '../IconComponent/IconComponent';

class EmailHistoryContainerComponent extends React.Component {
    FILTER_BY_ELEMENT_LIST = [
        {
            id: 'userId',
            title: 'User ID',
        },
        {
            id: 'email',
            title: 'User email',
        },
        {
            id: 'userName',
            title: 'User Name',
        },
        {
            id: 'subject',
            title: 'Email subject',
        },
        {
            id: 'sentDate',
            title: 'Date sent',
        },
        {
            id: 'readDate',
            title: 'Date viewed',
        },
    ];

    constructor(props) {
        super(props);

        this.state = {
            setActivePage: () => null,
            selectedEmail: { reason: '' },
            isEmailViewOpen: false,
            filterResultList: [],
            emailSubjectList: [],
            filter: {
                filterBy: '',
                filterValue: '',
            },
        };
    }

    componentDidMount() {
        const {
            getInvestorEmailsForAdmin, getInvestorEmails,
            isAdmin, investorId,
        } = this.props;

        if (isAdmin && investorId) {
            getInvestorEmailsForAdmin(investorId);
        } else {
            getInvestorEmails();
        }
    }

    componentDidUpdate(prevProps) {
        window.scrollTo(0, 0);
        const { emailHistoryList } = this.props;
        const strEmailHistoryList = JSON.stringify(emailHistoryList);
        const _strEmailHistoryList = JSON.stringify(prevProps.emailHistoryList);

        if (strEmailHistoryList !== _strEmailHistoryList) {
            const subjects = emailHistoryList.map(item => item.subject);
            const newSet = [...new Set(subjects)].map(item => ({
                id: item,
                title: item,
            }));

            this.setState({ // eslint-disable-line
                emailSubjectList: newSet,
                filterResultList: emailHistoryList,
            });
        }
    }

    componentWillUnmount() {
        const { resetEmailList } = this.props;

        resetEmailList([]);
    }

    onFilterInputChange = ({ target }) => {
        const { filter: _filter } = this.state;
        const { name, value } = target;

        if (name === 'filterBy') {
            _filter.filterValue = '';
        }

        if (name === 'filterBy' && value === 'none') {
            this.onClearFilter(false);
        }

        _filter[name] = value;

        this.setState({ filter: _filter });
    };

    onViewEmail = (selectedEmail) => {
        const { updateViewStatus, isAdmin } = this.props;

        if (!selectedEmail.isRead && !isAdmin) {
            updateViewStatus(selectedEmail);
        }

        this.setState({
            selectedEmail,
            isEmailViewOpen: true,
        });
    };

    closeViewEmailModal = () => {
        this.setState({ isEmailViewOpen: false });
    };

    // FILTER MANAGER
    onFilter = () => {
        const { emailHistoryList } = this.props;
        const { filter, setActivePage } = this.state;
        const { filterBy, filterValue } = filter;

        const filterResultList = emailHistoryList
            .filter(item => !!item[filterBy])
            .filter((item) => {
                if (filterBy === 'userId') {
                    return item[filterBy].toString() === filterValue;
                }

                return item[filterBy].toString()
                    .toLowerCase()
                    .indexOf(filterValue.toLowerCase()) !== -1;
            });

        this.setState({ filterResultList }, () => setActivePage(1));
    };

    onClearFilter = (resetPage = true) => {
        const { setActivePage } = this.state;
        const { emailHistoryList } = this.props;
        const filterResultList = [...emailHistoryList];

        if (resetPage) setActivePage(1);

        this.setState({
            filterResultList,
            filter: {
                filterBy: '',
                filterValue: '',
            },
        });
    };

    onSetActivePage = method => this.setState({ setActivePage: method });

    isFilterButtonDisabled = () => {
        const { filter } = this.state;

        return Object.keys(filter)
            .map(k => filter[k])
            .map(i => (!i || i === ''))
            .some(b => b);
    };

    // RENDER MANAGER
    renderFilterOptions = () => {
        const { filter, emailSubjectList } = this.state;

        return (
            <fieldset className="col-xs-12">
                <br/>

                <div className="col-xs-12 col-sm-6">
                    <SelectComponent
                        name="filterBy"
                        value={filter.filterBy}
                        options={this.FILTER_BY_ELEMENT_LIST}
                        onChange={this.onFilterInputChange}
                        selectTitle="Filter by"
                        disableNone={false}
                    />
                </div>

                <HideComponent hide={filter.filterBy !== 'subject'}>
                    <div className="col-xs-12 col-sm-6">
                        <SelectComponent
                            name="filterValue"
                            value={filter.filterValue}
                            options={emailSubjectList}
                            onChange={this.onFilterInputChange}
                            selectTitle="Subjects to filter"
                        />
                    </div>
                </HideComponent>

                <HideComponent
                    hide={filter.filterBy === 'sentDate' || filter.filterBy === 'readDate' || filter.filterBy === 'subject'}
                >
                    <div className="col-xs-12 col-sm-6">
                        <SingleLineInputComponent
                            maxInputLength={null}
                            onInputUpdate={this.onFilterInputChange}
                            elementValue={filter.filterValue}
                            inputTitle="Filter value"
                            inputName="filterValue"
                        />
                    </div>
                </HideComponent>

                <HideComponent hide={filter.filterBy !== 'sentDate' && filter.filterBy !== 'readDate'}>
                    <div className="col-xs-12 col-sm-6">
                        <DateInputComponent
                            dateType="date"
                            inputName="filterValue"
                            inputTitle="Date to filter"
                            elementValue={filter.filterValue}
                            onInputChange={this.onFilterInputChange}
                        />
                    </div>
                </HideComponent>

                <div className="col-xs-12">
                    <div className="pull-right">
                        <button
                            className="btn btn-default capitalize-text"
                            disabled={this.isFilterButtonDisabled()}
                            onClick={this.onClearFilter}
                            type="button"
                        >
                            <Icon iconName="times"/>
                            {' '}
                            clear filter
                        </button>
                        <button
                            className="btn btn-primary capitalize-text"
                            disabled={this.isFilterButtonDisabled()}
                            onClick={this.onFilter}
                            type="button"
                        >
                            <Icon iconName="filter" extraClasses="white-text"/>
                            {' '}
                            filter
                        </button>
                    </div>
                </div>
            </fieldset>
        );
    };

    render = () => {
        const { selectedEmail, isEmailViewOpen, filterResultList } = this.state;
        const {
            isAdmin, adminTitle, showInvestorData, showFilterOptions,
        } = this.props;

        return (
            <div className={`container ${isAdmin && !adminTitle ? '' : 'margin-top-30px'}`}>

                <fieldset className="col-xs-12">
                    <HideComponent hide={isAdmin && !adminTitle}>
                        <h2 className="heading-text">{adminTitle || 'My Email History'}</h2>
                    </HideComponent>
                </fieldset>

                <br/>

                <HideComponent hide={!showFilterOptions}>
                    {this.renderFilterOptions()}
                </HideComponent>

                <fieldset className="col-xs-12">
                    <EmailHistoryViewerComponent
                        onViewEmail={this.onViewEmail}
                        showInvestorData={showInvestorData}
                        investorEmailHistory={filterResultList}
                        onSetParentActivePage={this.onSetActivePage}
                    />
                </fieldset>

                <ProjectUpdateViewer
                    showModal={isEmailViewOpen}
                    closeModal={this.closeViewEmailModal}
                    subject={selectedEmail.subject}
                    creationDate={selectedEmail.sentDate}
                    projectName={selectedEmail.reason.toUpperCase()}
                    coverImage="No file selected"
                    smallText={selectedEmail.body}
                    userType={USER_INVESTOR}
                    longText=""
                    canShowText
                />
            </div>
        );
    };
}

const mapStateToProps = _state => ({ ..._state.investorEmailHistory });

const mapDispatchToProps = _dispatch => ({ ...bindActionCreators(Actions, _dispatch) });

EmailHistoryContainerComponent.propTypes = {
    adminTitle: PropTypes.string,
    emailHistoryList: PropTypes.array.isRequired,
    getInvestorEmails: PropTypes.func.isRequired,
    getInvestorEmailsForAdmin: PropTypes.func,
    investorId: PropTypes.number,
    isAdmin: PropTypes.bool,
    resetEmailList: PropTypes.func.isRequired,
    showFilterOptions: PropTypes.bool,
    showInvestorData: PropTypes.bool,
    updateViewStatus: PropTypes.func.isRequired,
};

EmailHistoryContainerComponent.defaultProps = {
    adminTitle: null,
    emailHistoryList: [],
    getInvestorEmails: () => null,
    getInvestorEmailsForAdmin: () => null,
    investorId: 0,
    isAdmin: false,
    resetEmailList: () => null,
    showFilterOptions: false,
    showInvestorData: false,
    updateViewStatus: () => null,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EmailHistoryContainerComponent);
