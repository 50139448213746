import React, { Component } from 'react'
import { Button, Modal } from 'react-bootstrap';
import RequestService from '../../api/RequestService';
import { Bitcoin_Wallet_Verification_Code, getImpersonateID } from '../../utils/General'
import { validate, getAddressInfo } from 'bitcoin-address-validation';
import swal from 'sweetalert2';

const amountMatching = (wallet, amount1, amount2, tolFactor) => {
  const dbAmounts = [Number(wallet.BitcoinDepositAmount1), Number(wallet.BitcoinDepositAmount2)].sort((a,b) => a - b);
  const enteredAmounts = [Number(amount1), Number(amount2)].sort((a,b) => a - b);
  const smallAmountMatched = dbAmounts[0] * (1 - tolFactor) < enteredAmounts[0] && enteredAmounts[0] < dbAmounts[0] * (1 + tolFactor);
  const bigAmountMatched = dbAmounts[1] * (1 - tolFactor) < enteredAmounts[1] && enteredAmounts[1] < dbAmounts[1] * (1 + tolFactor);
  return smallAmountMatched && bigAmountMatched;
}

export default class BitcoinWallet extends Component {
  constructor(props) {
    super(props);

    this.state = {
      walletAddress1: '',
      walletAddress2: '',
      walletAmount1: '',
      walletAmount2: '',
      showWalletModal: false,
      addressMismatch: false,
      amountMismatch: false,
      walletAddressInvalid: false,
      selectedEntity: '',
      bitcoinWallet: [],

    }
  }
  componentDidMount() {

    this.getBitcoinWallet();
  }

  onInputchange = (event) => {
    this.clearFieldError(event);
    this.setState({
      [event.target.name]: event.target.value,
      amountMismatch: false,
    });
  }


  validateAddress = (event, compareBothAddress) => {
    const { walletAddress1 } = this.state;
    let walletAddress2 = event.target.value;
    let validateAddress = validate(event.target.value);
    let isaddressMismatch;
    if (validateAddress) {
      if (compareBothAddress) {
        isaddressMismatch = walletAddress1 === walletAddress2 ? false : true;
      }
      this.setState({
        walletAddressInvalid: false,
        addressMismatch: isaddressMismatch,

      });
    }
    else {
      this.setState({ walletAddressInvalid: true });


    }

  }

  clearFieldError(event) {

    if (event.target.name === "walletAddress1") {
      this.refs.walletAddress1.classList.remove("signup-form-control-warning");
    }
    if (event.target.name === "walletAddress2") {
      this.refs.walletAddress2.classList.remove("signup-form-control-warning");
    }
    if (event.target.name === "walletAmount1") {
      this.refs.walletAmount1.classList.remove("signup-form-control-warning");
    }
    if (event.target.name === "walletAmount2") {
      this.refs.walletAmount2.classList.remove("signup-form-control-warning");
    }

  }

  handleWalletModal = (entity) => {

    this.setState({ showWalletModal: true, selectedEntity: entity });
    console.log('selectedEntity', entity);
  }
  getBitcoinWallet = async () => {

    const impersonateID = await getImpersonateID();
    const { data } = await RequestService.get({
      url: `/api/getBitcoinWallet/${impersonateID}`,
    });

    if (data.code === 200) {

      this.setState({ bitcoinWallet: data.result })
    }
  }

  submitWalletInfoForm = async (e) => {
    let ret = true;
    e.preventDefault();

    if (
      this.refs.walletAddress1.value.trim() === "" ||
      this.refs.walletAddress2.value.trim() === ""

    ) {
      if (this.refs.walletAddress1.value.trim() === "") {
        this.refs.walletAddress1.classList.add("signup-form-control-warning");
      }
      if (this.refs.walletAddress2.value.trim() === "") {
        this.refs.walletAddress2.classList.add("signup-form-control-warning");
      }
      ret = false;
    }


    const { walletAddress1, walletAddress2, selectedEntity } = this.state;

    console.log({ ret });

    if (ret && walletAddress1 === walletAddress2) {
      let formBody = [];
      formBody.push("entityID=" + encodeURIComponent(selectedEntity?.EntityID));
      formBody.push("walletAddress=" + encodeURIComponent(walletAddress1));
      formBody.push("verificationStatus=" + encodeURIComponent(Bitcoin_Wallet_Verification_Code.inProgress));
      formBody = formBody.join("&");
      console.log("HELLO");

      const checkEntity = await RequestService.post({
        url: '/api/checkEntity',
        data: formBody
      })
      console.log(checkEntity);

      if (checkEntity.status == 200) {
        const data = await RequestService.post({
          url: '/api/createBitcoinWallet',
          data: formBody
        });
        console.log("CHECK ME $$$$$$$$");
        console.log(data);

        const updateAmount = await RequestService.post({
          url: '/api/updateAmount',
          data: formBody
        })

        console.log(updateAmount);

        if (data.status === 200 && updateAmount.status == 200) {
          this.getBitcoinWallet();
          this.setState({ showWalletModal: false })
        }
      }

      if (checkEntity.status == 208) {
        {
          swal(`Entity With Address Already Exists`,
            {
              className: "custom-swal",
            });
        }
        this.setState({ showWalletModal: false })
      }

    }
    // else{alert('walletaddress1 is empty')}
  }


  submitDepositMadeForm = async (e) => {
    let ret = true;
    e.preventDefault();

    if (
      this.refs.walletAmount1.value.trim() === "" ||
      this.refs.walletAmount2.value.trim() === ""

    ) {
      if (this.refs.walletAmount1.value.trim() === "") {
        this.refs.walletAmount1.classList.add("signup-form-control-warning");
      }
      if (this.refs.walletAmount2.value.trim() === "") {
        this.refs.walletAmount2.classList.add("signup-form-control-warning");
      }
      ret = false;
    }

    const { walletAmount1, walletAmount2, selectedEntity } = this.state;
    if (ret) {
      // console.log(bitcoinWallet);
      // let depositMadeData = bitcoinWallet.filter(item => item.BitcoinWalletVerificationStatus === 3);
      // let compareDepositAmount = depositMadeData?.filter(item => amountMatching(item, walletAmount1, walletAmount2, 0.05));
      let formBody = [];
      formBody.push("entityID=" + encodeURIComponent(selectedEntity?.EntityID));
      formBody.push("walletAmount1=" + encodeURIComponent(walletAmount1));
      formBody.push("walletAmount2=" + encodeURIComponent(walletAmount2));
      //  formBody.push("statusID=" + encodeURIComponent(Bitcoin_Wallet_Verification_Code.connected));
      // formBody.push("statusID=" + encodeURIComponent(Bitcoin_Wallet_Verification_Code.connected));      
      formBody = formBody.join("&");


      const { data } = await RequestService.post({
        url: '/api/checkBitcoinWalletAmount',
        data: formBody
      });
      if (data.code === 200) {
          this.getBitcoinWallet();
          this.setState({ showWalletModal: false })
      }
      else {
        this.setState({ amountMismatch: true })
      }
    }
  }

  render() {
    const { showWalletModal, walletAddress1, walletAddress2, walletAmount1, walletAmount2, addressMismatch, walletAddressInvalid, bitcoinWallet, selectedEntity, amountMismatch } = this.state;
    const { addbankAccountToEntities } = this.props;
    console.log('bitcoinWallet', this.state.bitcoinWallet);
    console.log('addbankAccountToEntities', addbankAccountToEntities);

    let mergedEntitiesWithBitcoinWallet = addbankAccountToEntities?.map(entity => {
      let bitcoinWallets = bitcoinWallet?.find(element => element.EntityID === entity.EntityID);
      return { ...entity, ...bitcoinWallets }

    })
    console.log('mergedEntitiesWithBitcoinWallet', mergedEntitiesWithBitcoinWallet);
    console.log('selectedEntity', selectedEntity);

    return (
      <div>
        <p style={{ paddingLeft: 3, paddingTop: 7 }}>EnergyFunders gives you the option to receive mined bitcoin directly into your own personal bitcoin wallet. We plan to make periodic distributions to this wallet address: </p>
        <table className="table m-0">


          <tr>
            <td><span className="">Entity</span></td>
            <td><span className="">Status</span></td>
          </tr>
          {mergedEntitiesWithBitcoinWallet.map((entity) => (
            <tr>
              <td>{entity.EntityName}
              </td>
              <td>

                <button className={`btn btn-default btn-link font-bold text-red cursor-pointer ${entity?.BitcoinWalletVerificationStatus === Bitcoin_Wallet_Verification_Code.connected ? "text-green" : ""}`} onClick={() => this.handleWalletModal(entity)} >
                  {entity.BitcoinWalletVerificationStatus == Bitcoin_Wallet_Verification_Code.inProgress ? "Click to Verify Micro Deposit Amounts" : (entity.BitcoinWalletVerificationStatus == Bitcoin_Wallet_Verification_Code.connected ? "Wallet : " + entity.BitcoinWalletAddress + " is Connected" : "Click to add Wallet Info")}
                </button>
              </td>
            </tr>
          ))}
        </table>
        <br></br><br></br>
        <p style={{ fontStyle: 'italic', fontSize: '11px' }}>* Do not provide us with your private key or recovery phrase. Please contact your wallet provider if you are not sure about where to find your public wallet address.   <br></br><br></br>
          * Once you provide us with your public wallet address, we will make two small deposits to your wallet. You will need to confirm those amounts to us (via this screen) before we can use the wallet address to distribute bitcoins to you. <br></br><br></br>
          * If we don't have your verified public wallet address on file, we will liquidate your bitcoins to USD and distribute that to you via ACH, wire or checks. Please refer to our official correspondence for liquidation details or contact our Investor relations team.  <br></br><br></br>
          * Some vendors also provide public bitcoin wallet addresses for one-time use only. Please check with your vendor if that's the case and make sure that your public wallet address stays valid on our platform.
        </p>
        <Modal
          show={showWalletModal}
          style={{ width: '90%', margin: '0 auto' }}
        >
          <Modal.Header>
            <Modal.Title>{(!selectedEntity?.BitcoinWalletVerificationStatus || selectedEntity?.BitcoinWalletVerificationStatus === Bitcoin_Wallet_Verification_Code.needsWalletInfo) ? "Please enter your public bitcoin wallet address" : selectedEntity?.BitcoinWalletVerificationStatus === Bitcoin_Wallet_Verification_Code.inProgress ? "Wallet Verification" : selectedEntity?.BitcoinWalletVerificationStatus === Bitcoin_Wallet_Verification_Code.depositMade ? "Please verify the deposited amounts" : selectedEntity?.BitcoinWalletVerificationStatus === Bitcoin_Wallet_Verification_Code.connected ? "Wallet connected" : ""}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="row m-0">
            {(!selectedEntity?.BitcoinWalletVerificationStatus || selectedEntity?.BitcoinWalletVerificationStatus === Bitcoin_Wallet_Verification_Code.needsWalletInfo) ?
            <>
            <div className="col-xs-12 mt-3">
          <div className="mb-4">
            <p>Please provide us with your public bitcoin wallet address using the textboxes below.</p>
            </div></div>
              <div className="col-xs-12 mt-3">
                <div className="form-group mb-4 pb-2">
                  <input
                    type="text"
                    value={walletAddress1}
                    name="walletAddress1"
                    ref="walletAddress1"
                    placeholder="Enter bitcoin wallet address"
                    className="signup-form-control form-control" onChange={(event) => this.onInputchange(event)} onBlur={(event) => this.validateAddress(event)}
                  />

                </div>
                <div className="form-group mb-4 pb-2">
                  <input
                    type="text"
                    value={walletAddress2}
                    name="walletAddress2"
                    ref="walletAddress2"
                    placeholder="Re-enter bitcoin wallet address"
                    className="signup-form-control form-control" onChange={(event) => this.onInputchange(event)} onBlur={(event) => this.validateAddress(event, true)}
                  />
                  {walletAddressInvalid && <span className="text-danger pt-2 d-block">Wallet address is invalid</span>}
                  {addressMismatch && <span className="text-danger pt-2 d-block">Wallet address is mismatch</span>}
                </div>
                <p style={{ fontStyle: 'italic', fontSize: '11px' }}><br></br>* Do not provide us with your private key or recovery phrase. Please contact your wallet provider if you are not sure about where to find your public wallet address.   <br></br><br></br>
                  * Once you provide us with your public wallet address, we will make two small deposits to your wallet. You will need to confirm those amounts to us (via this screen) before we can use the wallet address to distribute bitcoins to you. <br></br><br></br>
                  * If we don't have your verified public wallet address on file, we will liquidate your bitcoins to USD and distribute that to you via ACH, wire or checks. Please refer to our official correspondence for liquidation details or contact our Investor relations team.  <br></br><br></br>
                  * Some vendors also provide public bitcoin wallet addresses for one-time use only. Please check with your vendor if that's the case and make sure that your public wallet address stays valid on our platform.
                </p>
                <div>
                  <button
                    className="btn btn-primary border-0 py-3 px-5 btn-green"
                    type="button" onClick={(e) => this.submitWalletInfoForm(e)}

                  >Submit
                  </button>
                </div>
              </div></> : ""
            }
            {console.log({ selectedEntity, Bitcoin_Wallet_Verification_Code })}
            {selectedEntity.BitcoinWalletVerificationStatus == Bitcoin_Wallet_Verification_Code.inProgress &&
              <div className="col-xs-12 mt-3">
                <div className="mb-4">
                  <p>Please enter the small deposit amount you received in your wallet. It may take up to 24 hours for micro-deposit to appear in your wallet balance.</p>
                </div>
                {/* <p className="text-green font-bold "><span className="text-dark-blue">Wallet Address</span> : {`${selectedEntity?.BitcoinWalletAddress}`}</p> */}
              </div>}


            {selectedEntity?.BitcoinWalletVerificationStatus === Bitcoin_Wallet_Verification_Code.depositMade &&
              <div className="col-xs-12 mt-3">

                <div className="form-group mb-4 pb-2">
                  <input
                    type="text"
                    value={walletAmount1}
                    name="walletAmount1"
                    ref="walletAmount1"
                    placeholder="Enter Amount Ex(0.00000194)"
                    className="signup-form-control form-control" onChange={(event) => this.onInputchange(event, false)}
                  />

                </div>
                <div className="form-group mb-4 pb-2">
                  <input
                    type="text"
                    value={walletAmount2}
                    name="walletAmount2"
                    ref="walletAmount2"
                    placeholder="Re-enter Amount Ex(0.00000194)"
                    className="signup-form-control form-control" onChange={(event) => this.onInputchange(event, false)}
                  />
                  {amountMismatch && <span className="text-danger pt-4 d-block">Entered amount mismatch, Please retry</span>}
                </div>
                <p className="pb-4 text-green font-bold "><span className="text-dark-blue">Wallet Address</span> : {`${selectedEntity?.BitcoinWalletAddress}`}</p>
                <div>
                  <button
                    className="btn btn-primary border-0 py-3 px-5 btn-green"
                    type="button" onClick={(e) => this.submitDepositMadeForm(e)}

                  >Submit
                  </button>
                </div>
              </div>}
            {selectedEntity.BitcoinWalletVerificationStatus === Bitcoin_Wallet_Verification_Code.connected && <div className="col-xs-12 mt-3">
              <div className="mb-4">
                <p>Your wallet is connected</p>
              </div>
              <p className="text-green font-bold "><span className="text-dark-blue">Wallet Address</span> : {`${selectedEntity?.BitcoinWalletAddress}`}</p>
            </div>}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.setState({ showWalletModal: false })} >Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}
