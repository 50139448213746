import React, { Component } from 'react';
import RequestService from '../../../api/RequestService';


class Averagetime extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: null
        };
    }


    async componentDidMount() {
		window.scrollTo(0, 0);

        try {
            const { data } = await RequestService.get('/api/totalinvestments');

            this.setState({
                users: data.data
            });
        } catch (err) {
            console.log(err);
        }
    }


    render() {
        return(
            <div className="col-md-2 col-sm-4 col-xs-12 tile_stats_count">
                <span className="count_top"><i className="fa fa-clock-o"></i> Total Investments</span>
                <div className="count">{this.state.users}</div>
            </div>
        );
    }
}

export default Averagetime;
