import React, { Component } from 'react';
import swal from 'sweetalert2';
import store from '../../../index'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import decode from 'unescape';
import { Modal } from 'react-bootstrap';
import scrollToComponent from 'react-scroll-to-component';
import getDate from '../../../utils/ConvertDate'
import {USER_INVESTOR} from "../../../utils/ApplicationConstants";
import ProjectUpdateViewer from "../projectUpdateViewerComponent/ProjectUpdateViewer";
import RequestService from '../../../api/RequestService';


class SelectedDealD extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: props.session.id,
            ProjectInvestmentID: props.location.state.id,
            FundraiserID: 0,
            CurrentFunding: "",
			NumUniqueInvestors: "",
            ViewNotificationsD: [],
            EntityID: "",
            IsAccreditated: 0,
            investmentdocs: [],
            Deal: [],
            Notify: [],
            pwhash: props.session.marketing,
            isSignedCA: 0,
			showSignCA: false,
            showNotification: false,
            isLoaded: false,
            errorOnLoad: false
        };


        this.getNotificationd=this.getNotificationd.bind(this);
        this.getProject=this.getProject.bind(this);
        this.addToWatchlist=this.addToWatchlist.bind(this);
        this.onInvest=this.onInvest.bind(this);

        this.getFundriserId=this.getFundriserId.bind(this);

		this.checkSignedCA = this.checkSignedCA.bind(this);
		this.onSubmitSignCA = this.onSubmitSignCA.bind(this);
		this.handleShowSignCA = this.handleShowSignCA.bind(this);
        this.handleCloseSignCA = this.handleCloseSignCA.bind(this);
        this.handleCloseNotification = this.handleCloseNotification.bind(this);

        this.getinvestmentdocs=this.getinvestmentdocs.bind(this);

    }


    async getinvestmentdocs() {
        const { data } = await RequestService.get(`/api/getRegDDocs/${this.props.location.state.id}`);

        if (data.code === 200) {
            this.setState({
                investmentdocs: data.data,
                isLoaded: true,
                err: {},
                errorOnLoad: false,
            });
        }
    }


	handleCloseNotification() {
        this.setState({ showNotification: false });
    }


    handleShowSignCA() {
        this.setState({ showSignCA: true });
    }


	handleCloseSignCA() {
		this.setState({ showSignCA: false });
	}


	async getProjectNotificationByID(id, e) {
        try {
            const { data } = await RequestService.get(`/api/getProjectNotificationByID/${id}`);
            this.setState({
                ViewNotificationsD: data.notification[0],
                showNotification: !this.state.showNotification,
                isLoaded: true,
                err: {},
                errorOnLoad: false,
            });
        } catch (err) {
            console.log(err);
            this.setState({
                err: err,
                errorOnLoad: true
            });
        }
    }



    getFundriserId(){
        fetch('/api/getFunderIdD/'+this.state.ProjectInvestmentID+'/'+this.state.id+'/'+this.state.pwhash,{
            method:'get'
        })
            .then((response)=>{
                return response.json()
            })
            .then((data)=>{

                this.setState({
                    FundraiserID:data.data[0].UserID,
                    isLoaded:true,
                    err:{},
                    errorOnLoad:false,
                });
            })
            .catch((err)=>{
                this.setState({
                    err:err,
                    errorOnLoad:true
                })
            })
    }

    strToInt = (str) => {
        return parseInt(str.replace(/,/g, ''));
    };


    async onInvest() {
        let date1 = new Date();
        let date2 = new Date(this.state.Deal.DateInvestmentStart);
        let diff = date1.getTime() - date2.getTime();
        let days = Math.ceil(Math.abs(diff / (1000 * 60 * 60 * 24)));

        const _fundingGoalMax = this.strToInt(this.state.Deal.FundingGoalMax);
        const _currentFunding = this.strToInt(this.state.CurrentFunding);

        if ((_currentFunding >= _fundingGoalMax)) {
            swal("Investment Full", "", "info");
        }
        else if((days > parseInt(this.state.Deal.FundPeriod))) {
            swal("Funding Closed", "", "info");
        }
        else if(diff < 0) {
            swal(days + " Days Left To Start Investment", "", "info");
        }
        else {
            try {
                const { data } = await RequestService.get('/api/checkInfoBeforeInvesting');

                if (data.dwollaURL !== '' && data.dwollaStatus === 'verified' && data.FundingSourceVerified === 1 && data.status === 2) {
                    this.props.history.push({
                        pathname: '/app/invest',
                        state: { ProjectType: 'RegD', ProjectID: this.state.Deal.ProjectID }
                    });
                }
                else if (data.dwollaURL === '' || data.dwollaStatus !== 'verified') {
                    swal({
                        title: 'You need to be a verified investor first.',
                        text: "Do you want to do it now?",
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes'
                    }).then((result) => {
                        if (result.value) {
                            this.props.history.push({
                                pathname: '/app/VerifyDwolla',
                                state: { EntityID:this.state.EntityID }
                            })
                        }
                    });
                }
                else if (data.FundingSourceVerified !== 1) {
                    swal({
                        title: 'Please create/verify your Funding Source',
                        text: "Do you want to do it now?",
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes'
                    }).then((result) => {
                        if (result.value) {
                            this.props.history.push({
                                pathname: '/app/setupprofile3',
                                state: { EntityID:this.state.EntityID }
                            })
                        }
                    });
                }
                // else if (data.data[0].w8n9formVerify === 0) {
                //     swal({
                //         title: 'You must complete your W9.',
                //         text: "Do you want to do it now?",
                //         type: 'warning',
                //         showCancelButton: true,
                //         confirmButtonColor: '#3085d6',
                //         cancelButtonColor: '#d33',
                //         confirmButtonText: 'Yes'
                //     }).then((result) => {
                //         if (result.value) {
                //             this.props.history.push({
                //                 pathname: '/app/setupprofile3',
                //                 state: { EntityID:this.state.EntityID }
                //             })
                //         }
                //     });
                // }
                else if (data.days > 0) {
                    swal("Please wait "+ data.days +" more days to invest, or contact us.", "", 'info');
                }
            } catch (err) {
                console.log(err);
            }
        }
    }


    addToWatchlist() {
        let formBody = [];
        formBody.push("ProjectInvestmentID=" + encodeURIComponent(this.state.ProjectInvestmentID));

        formBody = formBody.join("&");
        fetch('/api/addtolistD/'+this.state.id+'/'+this.state.pwhash, {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: formBody
        })
            .then((response) => response.json())
            .then((status) => {
                if (status.code === 406) {
                    swal("Project Already in your watchlist", " ", "error");
                }
                else if(status.code === 200){
                    swal("Project Added to watchlist", " ", "success")
                }
                else {
                    swal("Error occured", " ", "error")
                }
            })
            .catch((err) => {
                swal("Error to Add", " ", "error");
            })
    }


    async getProject() {
        let {data, status} = await RequestService.get(`/api/getProjectInvestmentD/${this.state.ProjectInvestmentID}`);
        data = data.data;

        if (status === 200) {
            this.setState({
                Deal: Object.keys(await data[0]).reduce((current, initial) => {
                    let numFieldList = ['FundingGoalmin', 'FundingGoalMax', 'MinInvestment'];
                    let dateFieldList = ['DateInvestmentStart', 'DatePublished'];
                    if (numFieldList.includes(initial)) {
                        current[initial] = data[0][initial].toLocaleString('en-us');
                    }
                    else if (dateFieldList.includes(initial)) {
                        current[initial] = data[0][initial].replace(/-/g, '/');		// Convert - to / in dates. This is so that date formats are supported on Safari Browser!
                    }
                    else {
                        current[initial] = data[0][initial];
                    }
                    return current;
                }, {}),
                isLoaded: true,
                err: {},
                errorOnLoad: false,
            });
        }
    }


     getNotificationd(){
        fetch('/api/getnotificationdealD/'+this.state.ProjectInvestmentID+'/'+this.state.id+'/'+this.state.pwhash,{
            method:'get'
        })
            .then((response)=>{
                return response.json()
            })
            .then((data)=>{
                this.setState({
                    Notify:data,
                    isLoaded:true,
                    err:{},
                    errorOnLoad:false,
                });

            })
            .catch((err)=>{
                this.setState({
                    err:err,
                    errorOnLoad:true
                })
            })
    }


    // NEW RE-WRITTEN FUNCTIONS:

	async getRegDInvestmentStats() {
        try {
            const { data } = await RequestService.get(`/api/getRegDInvestmentStats/${this.props.location.state.id}`);

            if (data.code === 200) {
                let CurrentFunding = Math.round(data.CurrentFunding).toLocaleString('en-us');
                this.setState({
                    CurrentFunding: CurrentFunding,
                    NumUniqueInvestors: data.NumUniqueInvestors,
                    isLoaded: true,
                    err: {},
                    errorOnLoad: false
                });
            }
            else {
                // Could not get data.
            }
        } catch (err) {
            console.log(err);
        }
	}


	setStateAsync(state) {
		return new Promise((resolve) => {
			this.setState(state, resolve)
		});
	}


    async checkSignedCA() {
        try {
            const { data } = await RequestService.get(`/api/checkSignedCA/${this.state.ProjectInvestmentID}`);
            if (data.signed === 1) {
                this.setStateAsync({
                    showSignCA: false
                });
            }
            else {
                this.setStateAsync({
                    showSignCA: true
                });
            }
        } catch (err) {
            console.log(err);
        }
    }


    async onSubmitSignCA(e) {
        e.preventDefault();

        if (this.refs.signedName.value !== '') {
            let formBody = [];
            formBody.push(`ProjectID=${this.state.ProjectInvestmentID}`);
            formBody.push(`signedName=${this.refs.signedName.value}`);
            formBody = formBody.join("&");

            try {
                const { data } = await RequestService.post({
                    url: '/api/saveSignedCA',
                    data: formBody,
                });

                if (data.signed === 1) {
                    this.setStateAsync({
                        showSignCA: false
                    });
                }
            } catch (err) {
                console.log(err);
            }
        }
        else {
			swal("Please type your full name", "", "info");
        }
    }


	static handleScroll() {
		if(window.innerWidth >= 800) {
			let MainContainer = document.getElementById("mainContainer");
			let element = document.getElementById("fundingstatus");
			if (window.pageYOffset <= 200 && MainContainer.scrollHeight - 2500) {
				element.style.cssText += "position: relative;";
			}
			else {
				element.style.cssText += "position: fixed;";
			}
		}
	}


    async componentDidMount() {
		await this.setState({
			ProjectInvestmentID: this.props.location.state.id
		});

		store.subscribe(() => {
			let currentState = store.getState();
			this.setState({
				id: currentState.session.user.id
			});
		});

		window.scrollTo(0, 0);
        window.addEventListener('scroll', SelectedDealD.handleScroll);   // Used for smooth scrolling of anchor links, without leaving anchor in the URL.

        this.checkSignedCA();
		this.getRegDInvestmentStats();
		this.getFundriserId();

		this.getProject();
		this.getNotificationd();
		this.getinvestmentdocs();
    }


    componentWillUnmount() {
        window.removeEventListener('scroll', SelectedDealD.handleScroll);   // Remove event when leaving the page.
    }


	render() {
    	if (this.state.Deal.isPastProject === 0) {
    		document.getElementById('InvestButton').style.display = '';
		}

    	return(
            <div className="container body">
                <div className="main_container">
                    <div className="right_col" role="main">
                        <div className="Mainstep">
                            <div id="mainContainer" className="container">
                                <div className="js-offering-content-header ">
                                    <div className="row u-margin-bottom-plus-lg">
                                        <div className="col-sm-12 col-md-12 col-lg-12 hidden-xs">
                                            <hr />
                                        </div>
                                    </div>
                                    <br/>
                                    <div className=" col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="row">
                                            <div className=" col-sm-5 col-md-4 col-lg-3">
                                                <nav id="fundingstatus" className=" c-offering-detail-nav " style={{top:15}}>
                                                    <div className="js-offering-sidebar-nav">
                                                        <h4 className="t-uppercase u-margin-top-none" >
                                                            <b>${this.state.CurrentFunding}</b> Raised <b><br/>{this.state.NumUniqueInvestors}</b> Investors </h4>
                                                        <h4 className="t-weight-normal u-margin-none"> Funding Goal: { (() => { return(this.state.Deal.FundingGoalmin === this.state.Deal.FundingGoalMax ? `$${this.state.Deal.FundingGoalMax}` : `$${this.state.Deal.FundingGoalmin} to $${this.state.Deal.FundingGoalMax}`); } )() }</h4>
                                                    </div>
                                                    <hr />
                                                    <p>Minimum Investment: <b>${this.state.Deal.MinInvestment}</b></p>
                                                    <div onClick={this.onInvest} id="InvestButton" className="c-btn c-btn--orange c-btn__fixed-md" style={{width: '100%', display: 'none'}}> Invest </div>
                                                    <hr/>
                                                    <ul className="nav list-unstyled">
                                                        <li className="sidenav__item">
                                                            <a onClick={() => scrollToComponent(this.projectDescription, { offset: 0, align: 'top', duration: 1500})} style={{cursor: "pointer"}}>Project Description</a>
                                                        </li>
                                                        <li className="sidenav__item">
                                                            <a onClick={() => scrollToComponent(this.companyContactDetails, { offset: 0, align: 'top', duration: 1500})} style={{cursor: "pointer"}}>Company Contact Details</a>
                                                        </li>
                                                        <li className="sidenav__item">
                                                            <a onClick={() => scrollToComponent(this.companyDocuments, { offset: 0, align: 'top', duration: 1500})} style={{cursor: "pointer"}}>Company Documents</a>
                                                        </li>
                                                        <li className="sidenav__item">
                                                            <a onClick={() => scrollToComponent(this.notifications, { offset: 0, align: 'top', duration: 1500})} style={{cursor: "pointer"}}>Notifications</a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                            <div className="col-xs-12 col-sm-7 col-md-8 col-lg-9" style={{padding: '0 35px 0 25px'}}>
                                                <h3 className="c-tombstone__business-name t-weight-normal t-green">{this.state.Deal.ProjectName}</h3><br/>
                                                <div className="u-xs-left u-padding-bottom-plus-sm">
                                                    {(() => {
														return(
                                                            <div className="Container" dangerouslySetInnerHTML={{
																__html:
																	decode(this.state.Deal.Description)
															}}></div>);
													})()}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row u-margin-top-plus-lg">
                                            <div className="hidden-xs col-sm-5 col-md-4 col-lg-3"> </div>
                                            <div className="col-xs-12 col-sm-7 col-md-8 col-lg-9" style={{padding: '0 25px'}}>
                                                <div className="well">
                                                    <h3>Investment Terms</h3>
                                                    <p><strong>Operated By:&nbsp;</strong>{this.state.Deal.OperatedBy}</p>
                                                    <p><strong>Minimum Funding Goal:&nbsp;</strong>${this.state.Deal.FundingGoalmin}</p>
                                                    <p><strong>Maximum Funding Goal:&nbsp;</strong>${this.state.Deal.FundingGoalMax}</p>
                                                    <p><strong>Project Publish Date:&nbsp;</strong>{getDate.getDate(this.state.Deal.DatePublished)}</p>
                                                    <p><strong>Days Remaining:&nbsp;</strong>
														{(() => {
                                                            let date1 = new Date();
                                                            let date2 = new Date(this.state.Deal.DateInvestmentStart);
                                                            let diff = date1.getTime() - date2.getTime();
                                                            let days = Math.ceil(Math.abs(diff / (1000 * 60 * 60 * 24)));

                                                            let daysRemaining = 0;
                                                            if (days <= parseInt(this.state.Deal.FundPeriod)) {
                                                                daysRemaining = parseInt(this.state.Deal.FundPeriod) - days;
                                                                if (daysRemaining === 0) {
                                                                    return(<span>Closes Today!</span>);
                                                                }
                                                                else if (daysRemaining === 1) {
																	return(<span>{daysRemaining} day</span>);
                                                                }
                                                                else {
																	return(<span>{daysRemaining} days</span>);
                                                                }
                                                            }
                                                            else {
                                                                return(<span>Closed</span>);
                                                            }
														})()}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal fade" id="showShortUrl" tabIndex={-1} role="dialog" aria-labelledby="ShowShortUrl">
                                    <div className="modal-dialog modal-sm" role="document">
                                        <div className="modal-content">
                                            <div className="modal-body">
                                                <p>Copy the link, then click OK.</p>
                                                <form>
                                                    <div className="form-group">
                                                        <input className="js-auto-select-me form-control" type="text" defaultValue="https://mv1.vc/apex" />
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="modal-footer">
                                                <a className="t-neutral" href="javascript:void(0);" data-dismiss="modal">Cancel</a>
                                                <a className="u-margin-left-plus-md t-secondary" href="javascript:void(0);" data-dismiss="modal">OK</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="row">
                                        <hr />
                                        <div className="hidden-xs col-sm-5 col-md-4 col-lg-3"> </div>
                                        <div className="col-xs-12 col-sm-7 col-md-8 col-lg-9" style={{padding: '0 35px 0 25px'}}>
                                            <div className="js-offering-content-wrapper">
                                                <section ref={(section) => { this.projectDescription = section; }}>
                                                    <div id="project-description" className="c-content-section" style={{marginTop: '20px'}}>
                                                        <h3>project description</h3>
														{(() => {
                                                            return(
                                                                <div className="Container" dangerouslySetInnerHTML={{
                                                                    __html:
                                                                        decode(this.state.Deal.DetailedDescription)
                                                                }}></div>);
                                                        })()}
                                                    </div>
                                                </section>
                                                <section ref={(section) => { this.companyContactDetails = section; }}>
                                                    <div id="company-contact-details" className=" c-content-section">
                                                        <h3>company contact details</h3>
														{(() => {
                                                            return(
                                                                <div className="Container" dangerouslySetInnerHTML={{
                                                                    __html:
                                                                        decode(this.state.Deal.CompanyContactInfo)
                                                                }}></div>);
                                                        })()}
                                                    </div>
                                                </section>
                                                <section ref={(section) => { this.companyDocuments = section; }}>
                                                    <div id="offering_investment_docs" className=" c-content-section">
                                                        <h3>Investment Documents</h3>
                                                        <ul className="list-group c-offering-investment-docs">
                                                            {(() => {
                                                                if (this.state.investmentdocs.length > 0) {
                                                                    return (
                                                                        this.state.investmentdocs.map((note, i) => {
                                                                            const fileLocation = note.S3Location === '' || !note.S3Location ? note.Document : note.S3Location;

                                                                            if (note.S3Location === '') {
                                                                                return (
                                                                                    <li className="list-group-item c-offering-investment-docs__item">
                                                                                        <i className="fa fa-lg fa-fw fa-file-text"/>
                                                                                        {note.Document}
                                                                                    </li>
                                                                                )
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    <li className="list-group-item c-offering-investment-docs__item">
                                                                                        <a href={fileLocation} target="_blank" rel="noopener noreferrer">
                                                                                            <i className="fa fa-lg fa-fw fa-file-text"/>
                                                                                            {note.Document}
                                                                                        </a>
                                                                                    </li>
                                                                                )
                                                                            }
                                                                        })
                                                                    );
                                                                }
                                                                else {
                                                                    return (<div>No Documents</div>)
                                                                }
                                                            })()}
                                                        </ul>
                                                    </div>
                                                </section>
                                                <section ref={(section) => { this.notifications = section; }}>
                                                    <div id="comments" className="c-content-section">
                                                        <div id="endnotes" className=" u-xs-overflow-hidden  c-content-section">
                                                            <h3>Notifications</h3>
                                                            <table className="table table-striped jambo_table bulk_action" id="sample" style={{marginTop:5}}>
                                                                <thead>
                                                                <tr>
                                                                    <th className="" >Date</th>
                                                                    <th className="">Subject</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
																{(() =>{
                                                                    if(this.state.Notify) {
                                                                        return(
                                                                            this.state.Notify.map((note, i) => (
                                                                                <tr key={note.NotificationID} className="even pointer">
                                                                                    <td className=" ">{getDate.getDate(note.CreatedDate)}</td>
                                                                                    <td className=" "><a href="javascript:void(0);" onClick={(e) => this.getProjectNotificationByID(note.NotificationID, e)}>{note.Subject}</a></td>
                                                                                </tr>

                                                                            ))
                                                                        );
                                                                    }
                                                                })()}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </section>
                                                <ProjectUpdateViewer showModal={this.state.showNotification}
                                                                     closeModal={this.handleCloseNotification}
                                                                     subject={this.state.ViewNotificationsD.Subject}
                                                                     smallText={this.state.ViewNotificationsD.NotificationSmallText}
                                                                     longText={this.state.ViewNotificationsD.NotificationText}
                                                                     projectName={this.state.Deal.ProjectName}
                                                                     creationDate={this.state.ViewNotificationsD.CreatedDate}
                                                                     coverImage={this.state.ViewNotificationsD.CoverImage}
                                                                     userType={USER_INVESTOR}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <Modal show={this.state.showSignCA}>
                        <Modal.Header>
                            <Modal.Title>Confidentiality Agreement</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <strong>This agreement appears only once per project. Please read carefully and agree to continue.</strong><br /><br />
                                <div>
                                    The following page and files accessed on such page constitute Confidential Information, and any unauthorized disclosure or use of such Confidential Information may cause irreparable harm the parties which own such Confidential Information. By accessing this page and/or the files available for download, you agree to treat all documentary, image, data, and other Confidential Information confidentially and not to, and to cause any spouse or co-investor who accesses such information through or from you, not to disclose to others; use for any purpose other than to decide whether to make an investment in the project set forth on the page; use for any purpose that is detrimental or adverse to EnergyFunders, the operator, or other parties involved in the project set forth on the page; or otherwise disclose any such information.
                                    <br /><br />
                                    You further agree not make any copies of the Confidential Information other than for your personal files relating to your investment in the project listed on the page; directly or indirectly, attempt to reverse-engineer; decipher; use such Confidential Information in any way related to other projects, prospects, or properties; or take other similar actions with respect to the Confidential Information.
                                    <br /><br />
                                    The above restrictions on Confidential Information shall not apply to Confidential Information that: (i) is or becomes publicly known through no wrongful act of you; or (ii) is, at the time of disclosure already known to you without restriction on disclosure.
                                    <br /><br />
                                    The Confidential Information is typically received from project parties or other sources and is provided "AS IS" and without any express or implied representations or warranties regarding accuracy, operability, use or fitness for a particular purpose.
                                </div>
                                <hr/>
                                <div>
                                    Please Type Your Name Here:<br/>
                                    <input type="text" ref="signedName" style={{width: 200}}/>
                                    <br /><br />
                                    <input type="submit" className="btn btn-info" onClick={(e) => { this.onSubmitSignCA(e) }} defaultValue="I Agree" value="I Agree" />
                                    <br /><br />
                                    <input type="button" onClick={() => { this.props.history.push({ pathname: '/app/Allinvestments' }); }} defaultValue="Go Back" value="Go Back" />
                                </div>
                            </div>
                        </Modal.Body>
						{/* <Modal.Footer>
                            <Button onClick={this.handleCloseSignCA}>Close</Button>
                         </Modal.Footer> */}
                    </Modal>
                </div>
            </div>
		);
	}
}


const { object, bool } = PropTypes;

SelectedDealD.propTypes = {
    session: object.isRequired,
    authenticated: bool.isRequired,
    checked: bool.isRequired
};

const mapState = ({ session }) => ({
    session: session.user,
    checked: session.checked,
    authenticated: session.authenticated
});

export default withRouter(connect(mapState)(SelectedDealD));
