/**
 * Created by goutham on 9/13/2017.
 */
import React, {Component} from 'react';
import swal from 'sweetalert2';
import store from './index';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import RequestService from './api/RequestService';
// import Data from './Country-state-city';
let Data = [];


class Entityprofile extends Component {
    constructor(props){
        super(props);
        this.state = {
          form:{
              entitytype:-1,
              ename:'',
              eaddress:'',
              ecountry:-1,
              estate:-1,
              ecity:-1,
              ezipcode:'',
              edescription:'',
              ephone:'',
              mailaddress:'',
              mailcountry:'',
              mailstate:'',
              mailcity:'',
              mailzipcode:'',
            },
          UserName: "",
          Data: Data,
          States: [],
          Cities: [],
          SelectedCountry: "",
          SelectedState: "",
          SelectedCity: "",
          Address: "",
          ZipCode: "",
          checked: false,
          id: props.session.id,
          isLoaded: false,
          errorOnLoad: false
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.handlechange = this.handlechange.bind(this);
        this.handleStatechange = this.handleStatechange.bind(this);
        this.handleCitychange = this.handleCitychange.bind(this);
        this.onTextChange = this.onTextChange.bind(this); 
        this.fullFormValidation = this.fullFormValidation.bind(this);
        this.intialstate=this.intialstate.bind(this);
        this.checkChange = this.checkChange.bind(this);
    }
    
   async handleCheck() {
      await this.setState({
        Address:this.refs.eaddress.value,
        ZipCode:this.refs.ezipcode.value,
        checked: !(this.state.checked)
      })
      this.checkChange();
    }
    checkChange() {
      if (this.state.checked === true) {
        this.refs.mailaddress.value = this.state.Address;
        this.inputChange({}, 'mailaddress', this.state.Address);
        this.refs.mailcountry.value=this.state.SelectedCountry;
        this.inputChange({}, 'mailcountry', this.state.SelectedCountry);
        this.refs.mailstate.value=this.state.SelectedState;
        this.inputChange({}, 'mailstate', this.state.SelectedState);
        this.refs.mailcity.value=this.state.SelectedCity;
        this.inputChange({}, 'mailcity', this.state.SelectedCity);
        this.refs.mailzipcode.value=this.state.ZipCode;
        this.inputChange({}, 'mailzipcode', this.state.ZipCode);
      } else {
        this.refs.mailaddress.value="";
        this.inputChange({}, 'mailaddress', '');
        this.refs.mailcountry.value="";
        this.inputChange({}, 'mailcountry', '');
        this.refs.mailstate.value="";
        this.inputChange({}, 'mailstate', '');
        this.refs.mailcity.value="";
        this.inputChange({}, 'mailcity', '');
        this.refs.mailzipcode.value="";
        this.inputChange({}, 'mailzipcode', '');
      }
    }
    async handlechange(e){
      let property = e.target.id;
      let value = e.target.value;
      let error = '';
      let prev = await this.state.form;
      prev[property] = await value;
      prev['estate'] = -1;
      prev['ecity'] = -1;      
      this.setState({
        States:[],
        Cities:[],
        SelectedState: "",
        SelectedCity: "",
        form: prev
      })
      if(this.refs.country.value<0){
        let prev = await this.state.form;
        prev['estate'] = -1;
        prev['ecity'] = -1; 
        this.setState({
          States:[],
          Cities:[],
          SelectedState: "",
          SelectedCity: "",
          form: prev
        })
      }
      else {
        let array = [];
        var id = this.refs.country.value;
        let countryIndex = Data.Countries.countries.findIndex(x => x.id == id);
        Data.States.states.filter(getstates);
        function getstates(data){
          if(data.country_id==id){
            array.push(data)
          }
        }
        await this.setState({
          SelectedCountry:Data.Countries.countries[countryIndex].name,
          States:array
        })
        this.refs.state.value=-1
      }
      error = this.inputValidation(property, value); 
      document.getElementById('error_'+property).innerHTML = error;
      if (this.state.checked) {
        this.checkChange();
      }
    }

    async handleStatechange(e){
      let property = e.target.id;
      let value = e.target.value;
      let error = '';
      let prev = await this.state.form;
      prev[property] = await value;
      prev['ecity'] = -1;
      await this.setState({
        form: prev
      })
      this.setState({
        Cities: [],
        SelectedCity: ""
      })
      if(this.refs.state.value=="divtype2"){
        document.getElementById('divtype2').style.display = "block";
      }
      else if(this.refs.state.value<0){
        document.getElementById('divtype2').style.display = "none";
        let prev = await this.state.form;
        prev['ecity'] = -1;
        this.setState({
          Cities:[],
          SelectedCity: "",
          form: prev
        })
      }
      else {
        document.getElementById('divtype2').style.display = "none";
        let array = [];
        var id = this.refs.state.value;
        let stateIndex = Data.States.states.findIndex(x => x.id == id);
        Data.Cities.cities.filter(getCities);
        function getCities(data){
          if(data.state_id==id){
            array.push(data)
          }
        }
        await this.setState({
          SelectedState: Data.States.states[stateIndex].name,
          Cities:array
        })
        this.refs.city.value = -1
      }
      error = this.inputValidation(property, value); 
      document.getElementById('error_'+property).innerHTML = error;
      if (this.state.checked) {
        this.checkChange();
      }
    }
    async handleCitychange(e){
      let property = e.target.id;
      let value = e.target.value;
      let error = '';
      let prev = await this.state.form;
      prev[property] = await value;
      await this.setState({
        form: prev
      })
      if(this.refs.city.value=="divtype3"){
        document.getElementById('divtype3').style.display = "block";

      }
      else  if(this.refs.city.value<0){
        document.getElementById('divtype3').style.display = "none";
      }
      else{
        document.getElementById('divtype3').style.display = "none";
        await this.setState({
          SelectedCity:this.refs.city.value
          })
      }
      error = this.inputValidation(property, value); 
      document.getElementById('error_'+property).innerHTML = error;
      if (this.state.checked) {
        this.checkChange();
      }
    }
    async onTextChange(type,e) {
      let property = e.target.id;
      let value = e.target.value;
      let error = '';
      let prev = await this.state.form;
      prev[property] = await value;
      await this.setState({
        form: prev
      })
      switch (type) {
        case 'divtype2':
          this.setState({
            SelectedState: this.refs.textstate.value
          })
          break;
        case 'divtype3':
          this.setState({
            SelectedCity: this.refs.textcity.value
          })
          break;
        default:
          break;
      }
      error = this.inputValidation(property, value); 
      document.getElementById('error_'+property).innerHTML = error;
    }
    async intialstate() {
      let array = [];
      let countryIndex = Data.Countries.countries.findIndex(x => x.id == 231);
      Data.States.states.filter(getstates);
      function getstates(data){
        if(data.country_id==231){
          array.push(data)
        }
      }
      let prev = await this.state.form;
      prev['ecountry'] = 231;      
      await this.setState({
        SelectedCountry: Data.Countries.countries[countryIndex].name,       
        States:array,
        form: prev
      })
    }
   
    async onSubmit(e){
        e.preventDefault();
        let checkFields = 1;
        checkFields = this.fullFormValidation(); 
           if(checkFields) {
          console.log('Please check all fields');
        } else {
        var formBody = [];
        formBody.push("UserID=" + encodeURIComponent(this.state.id));
        formBody.push("Address=" + encodeURIComponent(this.refs.eaddress.value));
        formBody.push("City=" + encodeURIComponent(this.state.SelectedCity));
        formBody.push("State=" + encodeURIComponent(this.state.SelectedState));
        formBody.push("ZipCode=" + encodeURIComponent(this.refs.ezipcode.value));
        formBody.push("Country=" + encodeURIComponent(this.state.SelectedCountry));
        formBody.push("Phone=" + encodeURIComponent(this.refs.ephone.value));
        formBody.push("MailingAddress=" + encodeURIComponent(this.refs.mailaddress.value));
        formBody.push("MailingCity=" + encodeURIComponent(this.refs.mailcity.value));
        formBody.push("MailingState=" + encodeURIComponent(this.refs.mailstate.value));
        formBody.push("MailingZipCode=" + encodeURIComponent(this.refs.mailzipcode.value));
        formBody.push("MailingCountry=" + encodeURIComponent(this.refs.mailcountry.value));
        formBody.push("UserID=" + encodeURIComponent(this.state.id));
        formBody.push("EntityType=" + encodeURIComponent(this.refs.etype.value));
        formBody.push("EntityName=" + encodeURIComponent(this.refs.ename.value));
        formBody.push("EntityDescription=" + encodeURIComponent(this.refs.edescription.value));
        formBody.push("typeCheck=2");
        

        formBody = formBody.join("&");

        try {
            const { data: status } = await RequestService.post({
                url: '/api/addentities',
                data: formBody,
            });
            this.setState({
                EntityName: this.refs.ename.value,
                isLoaded: true,
                err: {},
                errorOnLoad: false,
            });
            if(status.code===200){
                swal("Entity Added","", "success")
                    .then((next)=>{
                    if(next){
                      this.props.history.push({
                        pathname: '/app/setupprofile2',
                        state: { EntityID:status.EntityID }
                      })
                    }
                })
            }
            else if(status.code===406){
                swal("Entity Name already Registered","", "error");
            }
        } catch (err) {
            console.log(err);
        }
    }
    }
     async inputChange(e,key='-1',val='-1') {
      let property = (key != '-1') ? (key) : (e.target.id);
      let value = (val != '-1') ? (val) : (e.target.value);
      let error = '';
      let prev = await this.state.form;
      prev[property] = await value;
      await this.setState({
        form : prev
      })
      error = this.inputValidation(property, value);    
      document.getElementById('error_'+property).innerHTML = error;
    }
   inputValidation(property, value) {
      let error = '';
      switch (property) {
        case 'mailzipcode':
        case 'ezipcode':
          let patt = new RegExp("[0-9]");
          if (value == '') {
            error = 'This field is Required';
          } else if (!patt.test(value)) {
            error = 'Only numbers allowed';
          }
          break;
        case 'ephone':
          patt = new RegExp("[0-9]");
          if (value == '') {
            error = 'This field is Required';
          } else if (!patt.test(value)) {
            error = 'Only numbers allowed';
          }
          break;        
        case 'ecountry':
          if (value < 0) {
            error = 'Please select Country';
          }
          break;
          case 'entitytype':
          if (value < 0) {
            error = 'Please select Type';
          }
          break;
        case 'estate':
          if (value < 0) {
            error = 'Please select State';
          }
          break;
        case 'ecity':
          if (value < 0) {
            error = 'Please select City';
          }
          break;
        default:
          if (value == '') {
            error = 'This field is Required';
          }
          break;
      }
      return error;
    }
   fullFormValidation() {
      let checkFields = 0;
      let object = this.state.form;
      for (const key in object) {
        if (object.hasOwnProperty(key)) {
          let error = this.inputValidation(key, object[key]);
          document.getElementById('error_' + key).innerHTML = error;
          if(error != '') {
            checkFields = 1;
          }          
        }
      }
      return checkFields;
    }

    componentWillMount(){
      window.scrollTo(0, 0);
        store.subscribe(() => {
            var currentState=store.getState();
            this.setState({
                id:currentState.session.user.id
            });
        });
        this.intialstate();

    }

    render() {
        return (
            <div className="container body">
            <div className="main_container">
             <div className="right_col" role="main">
                    <div className="Mainstep">
                        <div className="container">
           <div className="l-main">
        <div className="l-main-h">
         
          <section id="signup" className="l-section"> 
            <div className="l-subsection">
              <div className="l-subsection-h">
                <div className="l-subsection-hh g-html i-cf" style={{marginTop: 40}}>
                  <form id="investor_address" className="ef_form" method="post" action="#">
                    <div style={{maxWidth: 640, marginLeft: 30}}>
                      <h2>Create Entity</h2>
                     </div>
                    <div className="w-form contacts_form" style={{maxWidth: 'none'}}>{/* contacts_form */}
                      <hr />
                      <div className="g-cols ef_form_first_row">
                        <div className="col-md-6 col-sm-6 col-xs-12 ">
                        <div id="" className="g-form-row">
                            <div className="g-form-row-label">
                              <label htmlFor="etype" className="g-form-row-label-h">Entity Type </label>
                            </div>
                            <div className="g-form-row-field">
                              <i className="fa fa-check" />
                              <select id="entitytype" name="etype" ref="etype"  className="not_chosen" onBlur = {(e) => this.inputChange(e)} onChange={(e) => this.inputChange(e)}>
                                                <option value="-1">Select Entity Type</option>
                                                <option>Company</option>
                                                <option>Personal</option>
                              </select>
                              <span id="error_entitytype" className="text-danger" />
                            </div>
                            </div>
                          <div id="" className="g-form-row">
                            <div className="g-form-row-label">
                              <label htmlFor="ename" className="g-form-row-label-h">Entity Name *</label>
                            </div>
                            <div className="g-form-row-field">
                              <i className="fa fa-user" />
                              <input type="text" id="ename" name="ename" ref="ename"  onBlur = {(e) => this.inputChange(e)} onChange={(e) => this.inputChange(e)}/>
                              <span id="error_ename" className="text-danger" />
                            </div>
                          </div>
                          <div id="" className="g-form-row">
                            <div className="g-form-row-label">
                              <label htmlFor="eaddress" className="g-form-row-label-h"> Address *</label>
                            </div>
                            <div className="g-form-row-field">
                              <i className="fa fa-location-arrow" />
                              <input type="text" id="eaddress" name="eaddress" ref="eaddress" onBlur = {(e) => this.inputChange(e)} onChange={(e) => this.inputChange(e)} />
                              <span id="error_eaddress" className="text-danger" />
                            </div>
                          </div>
                          <div id="" className="g-form-row">
                            <div className="g-form-row-label">
                              <label htmlFor="ecountry" className="g-form-row-label-h"> Country *</label>
                            </div>
                            <div className="g-form-row-field">
                              <i className="fa fa-location-arrow" />
                                            <select id="ecountry" ref="country" name="country" onBlur={this.handlechange} onChange={this.handlechange}>
                                <option value={-1}>Select Country</option>
                                {
                                  this.state.Data.Countries.countries.map((data) => (
                                    <option key={data.id} value={data.id} style={{ color: "black" }} selected={data.id==231}>{data.name}</option>
                                  ))
                                }
                              </select>
                               <span id="error_ecountry" className="text-danger" />
                            </div>
                          </div>
                          
                          <div id="" className="g-form-row">
                            <div className="g-form-row-label">
                              <label htmlFor="estate" className="g-form-row-label-h"> State *</label>
                            </div>
                            <div className="g-form-row-field">
                              <i className="fa fa-location-arrow" />
                                            <select id="estate" ref="state" name="sState" onBlur={this.handleStatechange} onChange={this.handleStatechange}>
                                <option value={-1}>Select state/province</option>
                                {
                                  this.state.States.map((data) => (
                                    <option key={data.id} value={data.id} style={{ color: "black" }}>{data.name}</option>
                                  ))
                                }
                                <option value="divtype2">Other</option>
                              </select>
                               <span id="error_estate" className="text-danger" />
                              <div id="divtype2" className="g-form-row" style={{ display: 'none' }} >
                                <div className="g-form-row-label">
                                  <label htmlFor="" className="g-form-row-label-h" /><a id="specify_country"> Don't see your State listed? Specify it here.</a>
                                </div>
                                <div className="g-form-row-field">
                                  <i className="fa fa-location-arrow" />
                                  <input type="text" ref="textstate" placeholder="Enter State"  onBlur = {() => this.onTextChange('divtype2')} onChange={() => this.onTextChange('divtype2')} />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div id="" className="g-form-row">
                            <div className="g-form-row-label">
                              <label htmlFor="ecity" className="g-form-row-label-h"> City *</label>
                            </div>
                            <div className="g-form-row-field">
                              <i className="fa fa-location-arrow" />
                                            <select id="ecity" name="sCity" ref="city" style={{ color: "black" }} onBlur={this.handleCitychange} onChange={this.handleCitychange}>
                                <option value={-1}>Select City</option>
                                {
                                  this.state.Cities.map((data) => (
                                    <option key={data.id} value={data.name} style={{ color: "black" }}>{data.name}</option>
                                  ))
                                }
                                <option value="divtype3">Other</option>

                              </select>
                               <span id="error_ecity" className="text-danger" />
                              <div id="divtype3" className="g-form-row" style={{ display: 'none' }} >
                                <div className="g-form-row-label">
                                  <label htmlFor="" className="g-form-row-label-h" /><a id="specify_country"> Don't see your City listed? Specify it here.</a>
                                </div>
                                <div className="g-form-row-field">
                                  <i className="fa fa-location-arrow" />
                                  <input type="text" ref="textcity" placeholder="Enter City" onBlur = {() => this.onTextChange('divtype3')} onChange={() => this.onTextChange('divtype3')}/>
                                </div>

                              </div>
                            </div>
                          </div>
                           <div id="" className="g-form-row">
                            <div className="g-form-row-label">
                              <label htmlFor="ezipcode" className="g-form-row-label-h"> Zipcode *</label>
                            </div>
                            <div className="g-form-row-field">
                              <i className="fa fa-map-marker" />
                              <input type="text" id="ezipcode" name="ezipcode" ref="ezipcode"  onBlur = {(e) => this.inputChange(e)} onChange={(e) => this.inputChange(e)}/>
                              <span id="error_ezipcode" className="text-danger" />
                            </div>
                          </div>
                          
                          </div>

                        <div className="col-md-6 col-sm-6 col-xs-12 ">
                          <div id="" className="g-form-row">
                            <div className="g-form-row-label">
                              <label htmlFor="edescription" className="g-form-row-label-h">Entity Description</label>
                            </div>
                            <div className="g-form-row-field">
                              <i className="fa fa-book" />
                              <input type="text" id="edescription" name="edescription" ref="edescription" onBlur = {(e) => this.inputChange(e)} onChange={(e) => this.inputChange(e)}  />
                               <span id="error_edescription" className="text-danger" />
                             </div>
                          </div>
                          <div id="" className="g-form-row">
                            <div className="g-form-row-label">
                              <label htmlFor="ephone" className="g-form-row-label-h"> Phone *</label>
                            </div>
                            <div className="g-form-row-field">
                              <i className="fa fa-phone" />
                              <input type="text" id="ephone" name="ephone" ref="ephone" onBlur = {(e) => this.inputChange(e)} onChange={(e) => this.inputChange(e)} />
                              <span id="error_ephone" className="text-danger" />
                            </div>
                          </div>
                          <div id="" className="g-form-row">
                            <div className="g-form-row-label">
                                            <label htmlFor="mailaddress" className="g-form-row-label-h"> Mailing Address *</label><span style={{ float: "right" }}><input type="checkbox" onChange={this.handleCheck} defaultChecked={this.state.checked} /> Same as current Address?</span>
                            </div>
                            <div className="g-form-row-field">
                              <i className="fa fa-location-arrow" />
                              <input type="text" id="mailaddress" name="mailaddress" ref="mailaddress"  onBlur = {(e) => this.inputChange(e)} onChange={(e) => this.inputChange(e)} />
                              <span id="error_mailaddress" className="text-danger" />
                            </div>
                          </div>
                          <div id="" className="g-form-row">
                            <div className="g-form-row-label">
                              <label htmlFor="mailcountry" className="g-form-row-label-h">Mailing Country *</label>
                            </div>
                            <div className="g-form-row-field">
                              <i className="fa fa-location-arrow" />
                              <input type="text" id="mailcountry" name="mailcountry" ref="mailcountry" onBlur = {(e) => this.inputChange(e)} onChange={(e) => this.inputChange(e)}/>
                              <span id="error_mailcountry" className="text-danger" />
                            </div>
                          </div>                          
                          <div id="" className="g-form-row">
                            <div className="g-form-row-label">
                              <label htmlFor="mailstate" className="g-form-row-label-h"> Mailing State *</label>
                            </div>
                            <div className="g-form-row-field">
                              <i className="fa fa-location-arrow" />
                              <input type="text" id="mailstate" name="mailstate" ref="mailstate" onBlur = {(e) => this.inputChange(e)} onChange={(e) => this.inputChange(e)}  />
                              <span id="error_mailstate" className="text-danger" />
                            </div>
                          </div>
                          <div id="" className="g-form-row">
                            <div className="g-form-row-label">
                              <label htmlFor="mailcity" className="g-form-row-label-h"> Mailing City *</label>
                            </div>
                            <div className="g-form-row-field">
                              <i className="fa fa-location-arrow" />
                              <input type="text" id="mailcity" name="mailcity" ref="mailcity" onBlur = {(e) => this.inputChange(e)} onChange={(e) => this.inputChange(e)}/>
                              <span id="error_mailcity" className="text-danger" />
                            </div>
                          </div>
                          <div id="" className="g-form-row">
                            <div className="g-form-row-label">
                              <label htmlFor="mailzipcode" className="g-form-row-label-h">Mailing Zipcode *</label>
                            </div>
                            <div className="g-form-row-field">
                              <i className="fa fa-map-marker" />
                              <input type="text" id="mailzipcode" name="mailzipcode" ref="mailzipcode"  onBlur = {(e) => this.inputChange(e)} onChange={(e) => this.inputChange(e)} />
                              <span id="error_mailzipcode" className="text-danger" />
                            </div>
                          </div>
                          
                          
                         
                          
                        </div>
                      </div>
                      <div style={{display: 'none'}}>
                        <div id="inline_content" style={{padding: 10, background: '#fff'}}>
                          <p>
                          </p><div style={{color: 'white'}}>
                            Thank you for your interest.  Please enter the NAME OF YOUR COUNTRY in the State field.  As an international investor, you may sign up for an account and view the website, but you cannot invest at this time.  We are working hard to allow international investors to invest through EnergyFunders and will notify you once you are able to do so.  For any questions or comments, please email <a href="mailto:Info@energyfunders.com" className="mailto">Info@energyfunders.com</a>.
                          </div>
                          <p />
                        </div>
                      </div>
                      <hr />
                    </div>{/*end contacts_form */}
                    <div className="g-form-row">
                      <div className="g-form-row-label" />
                      <div className="g-form-row-field" style={{ marginRight: 30}}>
                        <button id="message_send" type="submit" value="Submit" name="submit" onClick={this.onSubmit} className="g-btn type_secondary"><span>Submit</span></button>
                         </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>)
    }
}
const { object, bool } = PropTypes;

Entityprofile.propTypes = {
    session: object.isRequired,
    authenticated: bool.isRequired,
    checked: bool.isRequired
};

const mapState = ({ session }) => ({
    session: session.user,
    checked: session.checked,
    authenticated: session.authenticated
});

export default withRouter(connect(mapState)(Entityprofile));