import PropTypes from 'prop-types'
import React from 'react';
import './CardStyles.css';
import CardImageComponent from "./CardImageComponent";
import CardDescriptionComponent from "./CardDescriptionComponent";
import CardFundProgress from "./CardFundProgress";
import { PROJECT_REG_CF, PROJECT_REG_D } from "../../../utils/ApplicationConstants";


const Card = (props) => {
    const {
        cardImage, recollected, goal, fundPeriod, projectName, companyName, description, irr, location,
        payback, roi, version, status, category, companyLogo, investmentStartDate, onSeeDetails, projectType,
        projectID, minGoal, isProjectDetails, onInvest, investorCount, isEditMode, onInputChange, allCategories,
        allStatuses, onCardImageChange, isRegCf, isPastProject
    } = props;

    return (
        <div className="card-main grid-main">
            <CardImageComponent imageUrl={cardImage}
                                onSeeDetails={onSeeDetails}
                                projectID={projectID}
                                projectType={projectType}
                                version={version}
                                isProjectDetails={isProjectDetails}
                                isEditMode={isEditMode}
                                onCardImageChange={onCardImageChange}/>

            <CardDescriptionComponent projectName={projectName}
                                      companyName={companyName}
                                      description={description}
                                      irr={irr}
                                      location={location}
                                      payback={payback}
                                      roi={roi}
                                      status={status}
                                      category={category}
                                      companyLogo={companyLogo}
                                      onSeeDetails={onSeeDetails}
                                      projectType={projectType}
                                      projectID={projectID}
                                      version={version}
                                      isProjectDetails={isProjectDetails}
                                      onInvest={onInvest}
                                      isEditMode={isEditMode}
                                      onInputChange={onInputChange}
                                      allCategories={allCategories}
                                      allStatuses={allStatuses}
                                      isRegCf={isRegCf}
									  isPastProject={isPastProject}
			/>

            <CardFundProgress amountRecollected={recollected}
                              fundPeriod={fundPeriod}
                              goalAmount={goal}
                              minGoalAmount={minGoal}
                              investmentStartDate={investmentStartDate}
                              addToWatchlist={props.addToWatchlist}
                              projectId={projectID}
                              projectType={projectType}
                              investorCount={investorCount}
                              isEditMode={isEditMode}
                              onInputChange={onInputChange}/>
        </div>
    );
};


Card.propTypes = {
	cardImage: PropTypes.string.isRequired,
	category: PropTypes.string.isRequired,
	companyLogo: PropTypes.string.isRequired,
	companyName: PropTypes.string.isRequired,
	fundPeriod: PropTypes.any.isRequired,
	description: PropTypes.string.isRequired,
	goal: PropTypes.number.isRequired,
	irr: PropTypes.any.isRequired,
	location: PropTypes.string.isRequired,
	payback: PropTypes.any.isRequired,
	projectName: PropTypes.string.isRequired,
	recollected: PropTypes.number.isRequired,
	roi: PropTypes.any.isRequired,
	status: PropTypes.string.isRequired,
	investmentStartDate: PropTypes.string.isRequired,
	projectType: PropTypes.oneOf([PROJECT_REG_D, PROJECT_REG_CF]),
	onSeeDetails: PropTypes.func.isRequired,
	projectID: PropTypes.number.isRequired,
	addToWatchlist: PropTypes.func.isRequired,
	minGoal: PropTypes.number.isRequired,
	version: PropTypes.number.isRequired,
	isProjectDetails: PropTypes.bool,
	onInvest: PropTypes.func.isRequired,
	investorCount: PropTypes.number.isRequired,
	isEditMode: PropTypes.bool.isRequired,
	onInputChange: PropTypes.func.isRequired,
	allCategories: PropTypes.array.isRequired,
	allStatuses: PropTypes.array.isRequired,
	onCardImageChange: PropTypes.func,
	isRegCf: PropTypes.bool,
};

Card.defaultProps = {
	cardImage: null,
	category: '',
	companyLogo: null,
	companyName: '',
	fundPeriod: 0,
	description: '',
	goal: 0,
	irr: 0,
	location: '',
	payback: 0,
	projectName: '',
	recollected: 0,
	roi: 0,
	status: null,
	investmentStartDate: null,
	projectType: PROJECT_REG_D,
	onSeeDetails: () => null,
	projectID: 0,
	addToWatchlist: () => null,
	minGoal: 0,
	version: 1,
	isProjectDetails: false,
	onInvest: () => null,
	investorCount: 0,
	isEditMode: false,
	onInputChange: () => null,
	allCategories: [],
	allStatuses: [],
	isRegCf: false,
	onCardImageChange: () => null,
};

export default Card;
