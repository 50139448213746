import React, { Component } from 'react';
import FooterCopyright from './FooterCopyright/FooterCopyright';


class FooterFirst extends Component {
    render() {
        if (this.props.isMarketplace) {
            return (
            	<React.Fragment>
					
					<FooterCopyright IsAccredited="0" />
				</React.Fragment>

            );
        }
        else {
            return (
            	<React.Fragment>
					
					<FooterCopyright IsAccredited="1" />
				</React.Fragment>
            );
        }
    }
}

export default FooterFirst;
