import initialState from '../../../../reducers/appInitialState';
import * as Actions from '../actions/actions';
import {updateRequestCounter} from "../../../../utils/General";

export default (state = initialState.projectEditor, action) => {
    const {plus, minus} = updateRequestCounter(state, 'PEV2OnFlightRequest');

    switch (action.type) {
        case Actions.PROJECT_EDITOR_V2_REQUEST_START:
            return {...state, PEV2OnFlightRequest: plus};
        case Actions.PROJECT_EDITOR_V2_REQUEST_END:
            return {...state, PEV2OnFlightRequest: minus};
        case Actions.PROJECT_EDITOR_V2_EXTRACT_DATA_SUCCESS:
            return {...state, PEV2OnFlightRequest: minus, ...action.data};
        case Actions.PROJECT_EDITOR_V2_EXTRACT_PARENTS_SUCCESS:
            return {...state, PEV2OnFlightRequest: minus, parentProjects: action.data};
        case Actions.PROJECT_EDITOR_V2_EXTRACT_HELLO_SIGN_SUCCESS:
            return {...state, PEV2OnFlightRequest: minus, helloSignTemplates: action.data};
        default:
            return {...state}
    }
}