import RequestService from '../../../../api/RequestService';

export const performStepDataLog = logData => async () => {
    const url = '/api/project/statistics/log';

    try {
        await RequestService.post({
            url,
            data: logData,
        });

        console.log('Step logged');
    } catch (error) {
        console.log('Error performing step log: ERROR: ', error);
    }
};