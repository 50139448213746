
import {HTTP_ERR_BAD_REQUEST, PROJECT_REG_CF, PROJECT_REG_D} from "../../../../utils/ApplicationConstants";
import NotificationService from "../../../../utils/NotificationService";
import RequestService from "../../../../api/RequestService";
import {getImpersonateID} from "../../../../utils/General"
export const ALL_INVESTMENTS_REQUEST_START = 'ALL_INVESTMENTS_REQUEST_START';
export const ALL_INVESTMENTS_REQUEST_SUCCESS = 'ALL_INVESTMENTS_REQUEST_SUCCESS';
export const ALL_INVESTMENTS_REQUEST_END = 'ALL_INVESTMENTS_REQUEST_END';
export const ALL_INVESTMENT_ALL_CATEGORIES = 'ALL_INVESTMENT_ALL_CATEGORIES';
export const CURRENT_VIEW = 'CURRENT_VIEW';
const MESSAGE_DISPLAY_TIME = 10000;
const allInvestmentsReqStart = () => ({type: ALL_INVESTMENTS_REQUEST_START});
const allInvestmentsReqSuccess = (data) => ({type: ALL_INVESTMENTS_REQUEST_SUCCESS, data});
const allInvestmentsReqEnd = () => ({type: ALL_INVESTMENTS_REQUEST_END});
const allInvestmentsAllCategories = (data) => ({type: ALL_INVESTMENT_ALL_CATEGORIES, data});




export const INVESTOR_DASHBOARD_START_REQ = 'INVESTOR_DASHBOARD_START_REQ';
export const INVESTOR_DASHBOARD_END_REQ = 'INVESTOR_DASHBOARD_END_REQ';
export const INVESTOR_DASHBOARD_INV_REQ_SUCCESS = 'INVESTOR_DASHBOARD_INV_REQ_SUCCESS';

const endReq = () => ({ type: INVESTOR_DASHBOARD_END_REQ });
const startReq = () => ({ type: INVESTOR_DASHBOARD_START_REQ });

const investorEarningsSuccessReq = data => ({
    type: INVESTOR_DASHBOARD_INV_REQ_SUCCESS,
    data,
});

export const setCurrentView =data => ({
    type:CURRENT_VIEW,
    data
})


 export function setAdminView(data){
 
    return  (dispatch) => {dispatch(setCurrentView(data))}

 }
export function getAllInvestments(categoryId) {
    return async (dispatch) => {
        dispatch(allInvestmentsReqStart());
        const response = await RequestService.get(`/api/investments/by-category/${categoryId}`);
        const {status, data} = response;

        if (status >= HTTP_ERR_BAD_REQUEST) {
            NotificationService.error('Error when extracting the data from the DB');
            dispatch(allInvestmentsReqEnd());
            return;
        }

        dispatch(allInvestmentsReqSuccess(data));
    };
}



export function incrementViewCount(userData, projectType, projectId) {
    return async () => {
        RequestService.get(`/api/updateViewCount/${(projectType === PROJECT_REG_CF ? 'RegCF' : 'RegD')}/${projectId}`);
    };
}



export function getAllProjectCategories() {
    return async (dispatch) => {
        dispatch(allInvestmentsReqStart());

        const {data, status} = await RequestService.get('/api/investments/all/categories');

        if (status >= HTTP_ERR_BAD_REQUEST) {
            NotificationService.error('Error when extracting the category list');
            dispatch(allInvestmentsReqEnd());
            return;
        }

        // console.log({data, status});

        dispatch(allInvestmentsAllCategories(data));
    };
}



export function addToWatchList(projectType, projectId, categoryId) {
    return async (dispatch) => {
        dispatch(allInvestmentsReqStart());

        const data = {
            regCfProjectId: 0,
            regDProjectId: 0
        };

        if (projectType === PROJECT_REG_D)
            data.regDProjectId = projectId;
        else if (projectType === PROJECT_REG_CF)
            data.regCfProjectId = projectId;

        const {status} = RequestService.post({
            url: '/api/user/watchlist',
            data,
        });

        if (status >= HTTP_ERR_BAD_REQUEST) {
            NotificationService.error("Error Adding project to Watchlist", 6000);
            dispatch(allInvestmentsReqEnd());
            return;
        }

        NotificationService.success(null, 3000);
        getAllInvestments(categoryId)(dispatch);
    };
}


export const getAllUserInvestments = () => async (dispatch) => {

    let responseObj = {
        investorInvestments: [],
        totalInvestmentRegD: 0,
        totalInvestmentRegCF: 0,
        TotalReturnAmountRegD: 0
    };
    try {
        dispatch(startReq());
        const impersonateID = await getImpersonateID();
        const { data } = await RequestService.get(`/api/investor/dashboard/investments?impersonateID=${impersonateID}`);

         responseObj = {
            investorInvestments: [],
            totalInvestmentRegD: 0,
            totalInvestmentRegCF: 0,
			TotalReturnAmountRegD: 0
        };

        if (data.code === 200) {
            responseObj = {
                investorInvestments: data.investorInvestments,
                totalInvestmentRegD: data.totalInvestmentRegD,
                totalInvestmentRegCF: data.totalInvestmentRegCF,
				TotalReturnAmountRegD: data.TotalReturnAmountRegD
            };

            dispatch(investorEarningsSuccessReq(responseObj));
        }
        else {
            dispatch(investorEarningsSuccessReq(responseObj));
        }
    } catch (err) {
        // console.log('ppp', 'Error extracting the investments of the user', err);
        NotificationService.error('Error requesting the investors information', MESSAGE_DISPLAY_TIME);
        dispatch(endReq());
    }
};

