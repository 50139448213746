import React, {Component} from 'react';
import swal from 'sweetalert';
import CKEditor from "react-ckeditor-component";
import {withRouter} from 'react-router-dom';
import '../node_modules/flatpickr/dist/themes/material_green.css';
import Flatpickr from 'react-flatpickr';
import {ContentState, convertToRaw, EditorState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import store from './index' /*added for block chain*/
import {connect} from 'react-redux'; /*added for block chain*/
import PropTypes from 'prop-types';
import DealCardDescriptionComponent
    from "./components/shared/dealCardDescriptionComponent/DealCardDescriptionComponent";
import moment from 'moment';
/*added for block chain*/
const CKEDITOR = window.CKEDITOR;

class Preview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: Object.values(props.data),
            images: (props.images) ? (props.images) : ({}),
            type: (props.type) ? (props.type) : '',
            isLoaded: false,
            errorOnLoad: false
        };
    }

    delete(e, i) {
        this.props.deleteAction(i, this.state.type);
    }

    render() {
        return (
            <div className='table-responsive' style={{marginBottom: '20px'}}>
                <table className="table table-sm table-bordered" style={{textAlign: 'center'}}>
                    <thead>
                    <tr>
                        <th style={{textAlign: 'center'}}>S.NO.</th>
                        <th style={{textAlign: 'center'}}>Name</th>
                        <th style={{textAlign: 'center'}}>File</th>
                        <th style={{textAlign: 'center'}}>Status</th>
                        <th style={{textAlign: 'center'}}>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        Object.values(this.props.data).map((file, i) => {

                            return (
                                <tr key={i}>
                                    <td style={{height: 60, lineHeight: '60px'}}>{i + 1}</td>
                                    <td style={{
                                        height: 60,
                                        lineHeight: '60px'
                                    }}>{(file.fieldName) ? (file.originalFilename) : (file.name)}</td>
                                    <td style={{height: 60, lineHeight: '60px'}}>{
                                        (() => {
                                            if (file.fieldName) {
                                                if (file.type == 'image/jpeg' || file.type == 'image/gif' || file.type == 'image/png') {
                                                    return (
                                                        <img
                                                            src={(!file.fieldName) ? ((this.state.images[i]) ? (this.state.images[i]) : ('/img/dummy.jpg')) : ((file.fieldName) ? (file.newPath) : ('/img/dummy.jpg'))}
                                                            width={100} height={50} alt=""/>
                                                    );
                                                } else {
                                                    return (
                                                        <img src="/img/dummy.jpg" width={100} height={50} alt=""/>
                                                    );
                                                }

                                            } else {
                                                return (
                                                    <img
                                                        src={(!file.fieldName) ? ((this.state.images[i]) ? (this.state.images[i]) : ('/img/dummy.jpg')) : ((file.fieldName) ? (file.newPath) : ('/img/dummy.jpg'))}
                                                        width={100} height={50} alt=""/>
                                                );
                                            }

                                        })()
                                    }</td>
                                    <td style={{
                                        height: 60,
                                        lineHeight: '60px'
                                    }}>{(!file.fieldName) ? 'Pending' : 'Uploaded'}</td>
                                    <td style={{height: 60, lineHeight: '60px'}}>
                                        {
                                            (() => {
                                                if (!file.fieldName) {
                                                    return (
                                                        <button type="button" className="btn btn-default btn-sm"
                                                                onClick={(e) => this.delete(e, i)}>
                                                            <span className="glyphicon glyphicon-trash"></span>
                                                        </button>
                                                    );
                                                }
                                            })()
                                        }

                                    </td>
                                </tr>
                            )
                        })
                    }

                    </tbody>
                </table>

            </div>
        );
    }
}

class EditProjectCF extends Component {
    constructor(props) {
        super(props);
        /* const html = '<p>Hey this <strong>editor</strong> rocks ??</p>';
        const contentBlock = htmlToDraft(html);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState); */

        this.state = {
            call: false,
            Users: [],
            form: {
                investment: '',
                companycontact: '',
                portal: '',
                goalmin: '',
                goalmax: '',
                minimuminvest: '',
                fundingperiod: '',
                pdate: '',
                sdate: '',
                issuername: '',
                security: '',
                sunitprice: '',
                totalsecurities: '',
                hellosign: '',
                project: '',
                description: '',
                dealCardDescription: '',
            },
            Deal: [],
            Deal1: [],
            HSDoclist: [],
            date: new Date(),
            startdate: new Date(),
            dealCard: {},
            dealCardFiles: {},
            dealCardImages: {},
            pertinentMaps: {},
            afeOperations: {},
            additionalMaterials: {},
            pertinentMapsFiles: {},
            afeOperationsFiles: {},
            additionalMaterialsFiles: {},
            pertinentMapsImages: {},
            afeOperationsImages: {},
            additionalMaterialsImages: {},
            ApproveProjectID: this.props.location.state.ProjectID,
            IsSaved: this.props.location.state.isSaved,
            isLoaded: false,
            errorOnLoad: false,
            editorState: EditorState.createEmpty(),
            editorState1: EditorState.createEmpty(),
            id: props.session.id /*added for block chain*/
        };
        this.getHellosignDocList = this.getHellosignDocList.bind(this);
        this.onPublish = this.onPublish.bind(this);
        this.getprojectInv = this.getprojectInv.bind(this);
        this.onChangeDescription = this.onChangeDescription.bind(this);
        this.onChangeproject = this.onChangeproject.bind(this);
        this.uploadImageCallBack = this.uploadImageCallBack.bind(this);
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
        this.onEditorStateChange1 = this.onEditorStateChange1.bind(this);
        this.fileUploading = this.fileUploading.bind(this);
        this.onChangeFiles = this.onChangeFiles.bind(this);
        this.filesUpload = this.filesUpload.bind(this);
        this.onDeleteFile = this.onDeleteFile.bind(this);

    }

    getImage(File) {
        return new Promise(function (resolve, reject) {
            var dataURL = '';
            var reader = new FileReader();
            reader.onload = function () {
                resolve(reader.result)
            };
            reader.readAsDataURL(File);
        });
    }

    onDeleteFile(i, type) {
        let data = {};
        switch (type) {
            case 'dealfiles':
                data = Object.values(this.state.dealCard).filter((item, j) => (i != j));
                break;
            case 'maps':
                data = Object.values(this.state.pertinentMaps).filter((item, j) => (i != j));
                break;
            case 'afe':
                data = Object.values(this.state.afeOperations).filter((item, j) => (i != j));
                break;
            case 'addmat':
                data = Object.values(this.state.additionalMaterials).filter((item, j) => (i != j));
                break;

            default:
                break;
        }
        let detail = {};
        if (data != undefined) {
            Object.assign(detail, data);
        }
        this.onChangeFiles({target: ''}, type, detail);
    }

    checkFileType(files, fileType) {
        return new Promise(function (resolve, reject) {
            let dat = {};
            switch (fileType) {
                case 'dealfiles':
                    dat = Object.values(files).filter(function (object) {
                        if ((object['type'] == "image/jpeg") || (object['type'] == "image/png") || (object['type'] == "image/gif")) {
                            return true;
                        } else {
                            console.log('Only images allowed');
                        }
                    });
                    resolve(Object.assign({}, dat));
                    break;
                case 'maps':
                    dat = Object.values(files).filter(function (object) {
                        if ((object['type'] == "application/pdf") || (object['type'] == "application/docx") || (object['type'] == "application/doc")) {
                            return true;
                        } else {
                            console.log('Only images allowed');
                        }
                    });
                    resolve(Object.assign({}, dat));
                    break;
                case 'afe':
                    dat = Object.values(files).filter(function (object) {
                        if ((object['type'] == "application/pdf") || (object['type'] == "application/docx") || (object['type'] == "application/doc") || (object['type'] == "application/txt")) {
                            return true;
                        } else {
                            console.log('Only Documents allowed');
                        }
                    });
                    resolve(Object.assign({}, dat));
                    break;
                case 'addmat':
                    dat = Object.values(files).filter(function (object) {
                        if ((object['type'] == "application/pdf") || (object['type'] == "application/docx") || (object['type'] == "application/doc") || (object['type'] == "application/txt") || (object['type'] == "image/jpeg") || (object['type'] == "image/png") || (object['type'] == "image/gif")) {
                            return true;
                        } else {
                            console.log('Not allowed this file type ' + object['type']);
                        }
                    });
                    resolve(Object.assign({}, dat));
                    break;
                default:
                    reject({});
                    break;
            }
        });
    }

    async onChangeFiles(e, type = '', records = '-1') {
        let file = await e.target;
        let fileType = await (type == '') ? file.id : type;
        let files = await (records == '-1') ? file.files : records;
        console.log(fileType);
        console.log(files);
        let data = {};
        this.checkFileType(files, fileType).then((files) => {
            for (let i = 0; i < Object.values(files).length; i++) {
                this.getImage(Object.values(files)[i]).then((image) => {
                    data[i] = image;
                    switch (fileType) {
                        case 'dealfiles':
                            this.setState({
                                dealCardImages: data,
                            });
                            break;
                        case 'maps':
                            this.setState({
                                pertinentMapsImages: data,
                            });
                            break;
                        case 'afe':
                            this.setState({
                                afeOperationsImages: data,
                            });
                            break;
                        case 'addmat':
                            this.setState({
                                additionalMaterialsImages: data,
                            });
                            break;
                        default:
                            console.log('Unknown error');
                            break;
                    }
                });
            }
        });

        switch (fileType) {
            case 'dealfiles':
                await this.checkFileType(files, fileType).then((data) => {
                    this.setState({
                        dealCardFiles: {},
                        dealCardImages: {},
                        dealCard: data,
                    });
                });

                break;
            case 'maps':
                await this.checkFileType(files, fileType).then((data) => {
                    this.setState({
                        pertinentMapsFiles: {},
                        pertinentMapsImages: {},
                        pertinentMaps: data,
                    });
                });

                break;
            case 'afe':
                await this.checkFileType(files, fileType).then((data) => {
                    this.setState({
                        afeOperationsFiles: {},
                        afeOperationsImages: {},
                        afeOperations: data,
                    });
                });
                break;
            case 'addmat':
                await this.checkFileType(files, fileType).then((data) => {
                    this.setState({
                        additionalMaterialsFiles: {},
                        additionalMaterialsImages: {},
                        additionalMaterials: data,
                    });
                });
                break;
            default:
                await console.log('Unknown error');
                break;
        }
    }

    async filesUpload(e) {
        e.preventDefault();
        let fileType = e.target.id;
        var obj = {};
        switch (fileType) {
            case 'dealCard':
                obj = await this.state.dealCard;
                break;
            case 'pertinentMaps':
                obj = await this.state.pertinentMaps;
                break;
            case 'afeOperations':
                obj = await this.state.afeOperations;
                break;
            case 'additionalMaterials':
                obj = await this.state.additionalMaterials;
                break;
            default:
                await console.log('file Upload check error');
                break;
        }
        if (!obj) {
            await alert('Please select files to upload');
            await console.log('Empty data');
        } else {
            console.log('checking...');
            this.fileUploading(obj, fileType);
        }
    }

    async fileUploading(file, fileType) {
        var formData = new FormData();
        var headers = Object.assign({},
            /* { 'content-type': 'application/json' }, */
            {'Content-Type': 'multipart/form-data'}
        );
        Object.keys(headers)
            .forEach(function (k) {
                if (k.toLowerCase() === 'content-type') delete headers[k]
            });
        console.log(file);
        for (var key in file) {
            if (file.hasOwnProperty(key)) {
                formData.append('data', file[key]);
            }
        }
        formData.append('type', 'regCF');

        fetch('/api/uploadRemote', {
            method: 'post',
            /* headers: {'content-type': 'multipart/form-data'}, */
            headers: headers,
            body: formData
        })
            .then((response) => response.json())
            .then((data) => {
                switch (fileType) {
                    case 'dealCard':
                        this.setState({
                            dealCardFiles: data
                        });
                        break;
                    case 'pertinentMaps':
                        this.setState({
                            pertinentMapsFiles: data
                        });
                        break;
                    case 'afeOperations':
                        this.setState({
                            afeOperationsFiles: data
                        });
                        break;
                    case 'additionalMaterials':
                        this.setState({
                            additionalMaterialsFiles: data
                        });
                        break;
                    default:
                        console.log('file Upload check error');
                        break;
                }
                console.log(data, "uploaded data");
            })
    }

    getHellosignDocList() {
        fetch('/api/getlist' + '/' + this.state.id + '/' + this.props.session.marketing, {
            method: 'get'
        })
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                this.setState({
                    HSDoclist: data.data.templates,
                    isLoaded: true,
                    err: {},
                    errorOnLoad: false,
                })
            })
            .catch((err) => {
                this.setState({
                    err: err,
                    errorOnLoad: true
                })
            })
    }

    onEditorStateChange(editorState) {
        let newContent = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        let prev = this.state.form;
        prev['project'] = newContent;
        document.getElementById('error_description').innerHTML = this.inputValidation("description", newContent);
        this.setState({
            editorState,
            project: newContent,
            form: prev
        });

    };

    onEditorStateChange1(editorState) {
        let newContent = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        this.setState({
            editorState1: editorState,
            description: newContent
        });

    };

    onChangeDescription(evt) {
        var newContentD = evt.editor.getData();
        this.setState({
            description: newContentD,
            form: {
                description: newContentD,
            }
        });
    }

    onChangeproject(evt) {
        const newProject = evt.editor.getData();
        this.setState({
            project: newProject,
            form: {
                project: newProject,
            }
        });
    }

    inputChange = async (e) => {
        let property = e.target.id;
        let value = e.target.value;
        let error = '';
        let prev = await this.state.form;
        prev[property] = await value;
        await this.setState({
            form: prev
        });
        error = this.inputValidation(property, value);
        document.getElementById('error_' + property).innerHTML = error;
    };

    inputValidation(property, value) {
        let error = '';
        switch (property) {
            case 'City':
                if (value < 0) {
                    error = 'Please select City';
                }
                break;
            default:
                if (value === '' || value < 0) {
                    error = 'This field is Required';
                }
                break;
        }
        return error;
    }

    fullFormValidation() {
        let checkFields = 0;
        let object = this.state.form;
        for (const key in object) {
            if (object.hasOwnProperty(key)) {
                let error = this.inputValidation(key, object[key]);
                document.getElementById('error_' + key).innerHTML = error;
                if (error != '') {
                    checkFields = 1;
                }
            }
        }
        return checkFields;
    }

    getprojectInv() {
        fetch('/api/getRegCF/' + (this.state.ApproveProjectID) + '/' + this.state.id + '/' + this.props.session.marketing, {
            method: 'get'
        })
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                let prev = this.state.form;
                prev['investment'] = data[0].InvestmentName;
                prev['companycontact'] = data[0].CompanyContactDetails;
                prev['portal'] = data[0].FundingPortalCarry;
                prev['goalmin'] = data[0].FundingGoalMin;
                prev['goalmax'] = data[0].FundingGoalMax;
                prev['minimuminvest'] = data[0].MinimumInvestment;
                prev['fundingperiod'] = data[0].FundingPeriod;
                prev['pdate'] = data[0].ProjectPublishDate;
                prev['sdate'] = data[0].InvestmentStartDate;
                prev['issuername'] = data[0].LegalIssuerName;
                prev['security'] = data[0].TypeOfSecurity;
                prev['sunitprice'] = data[0].SecurityUnitPrice;
                prev['totalsecurities'] = data[0].TotalNoOfSecurities;
                prev['hellosign'] = data[0].HelloSignTemplate;
                prev['project'] = data[0].ProjectSummary;
                prev['description'] = data[0].FormCfullDescription;
                prev['dealCardDescription'] = data[0].DealCardDescription;
                let html = data[0].ProjectSummary;
                let contentBlock = htmlToDraft(html);
                let contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                let editorState = EditorState.createWithContent(contentState);
                let html1 = data[0].FormCfullDescription;
                let contentBlock1 = htmlToDraft(html1);
                let contentState1 = ContentState.createFromBlockArray(contentBlock1.contentBlocks);
                let editorState1 = EditorState.createWithContent(contentState1);
                this.setState({
                    Deal1: data[0],
                    project: data[0].ProjectSummary,
                    description: data[0].FormCfullDescription,
                    editorState,
                    editorState1,
                    form: prev,
                    isLoaded: true,
                    err: {},
                    errorOnLoad: false,
                });
                // this.refs.ProjectSummary.value=this.state.Deal1.ProjectSummary;   
                // this.refs.FormCfullDescription.value=this.state.Deal1.FormCfullDescription;   
                this.refs.InvestmentName.value = this.state.Deal1.InvestmentName;
                this.refs.FundingGoalMax.value = this.state.Deal1.FundingGoalMax;
                this.refs.FundingPeriod.value = this.state.Deal1.FundingPeriod;
                this.refs.CompanyContactDetails.value = this.state.Deal1.CompanyContactDetails;
                this.refs.FundingPortalCarry.value = this.state.Deal1.FundingPortalCarry;
                this.refs.FundingGoalMin.value = this.state.Deal1.FundingGoalMin;
                this.refs.LegalIssuerName.value = this.state.Deal1.LegalIssuerName;
                this.refs.MinimumInvestment.value = this.state.Deal1.MinimumInvestment;
                this.refs.TypeOfSecurity.value = this.state.Deal1.TypeOfSecurity;
                this.refs.SecurityUnitPrice.value = this.state.Deal1.SecurityUnitPrice;
                this.refs.TotalNoOfSecurities.value = this.state.Deal1.TotalNoOfSecurities;
                this.refs.InternalNotes.value = this.state.Deal1.Units;
            })
            .catch((err) => {
                this.setState({
                    err: err,
                    errorOnLoad: true
                })

            })
    }


    componentWillMount() {
        window.scrollTo(0, 0);
        store.subscribe(() => {
            var currentState = store.getState();
            this.setState({
                id: currentState.session.user.id
            });
        });
        this.getprojectInv();
        this.getHellosignDocList();
    }

    uploadImageCallBack(file) {
        return new Promise(
            (resolve, reject) => {
                const xhr = new XMLHttpRequest();
                xhr.open('POST', '/api/uploadEditor');
                //xhr.setRequestHeader('Authorization', 'Client-ID XXXXX');
                const data = new FormData();
                data.append('image', file);
                xhr.send(data);
                xhr.addEventListener('load', () => {
                    const response = JSON.parse(xhr.responseText);
                    resolve(response);
                });
                xhr.addEventListener('error', () => {
                    const error = JSON.parse(xhr.responseText);
                    reject(error);
                });
            }
        );
    }

    onPublish(e) {
        e.preventDefault();
        let formBody = [];
        formBody.push("ParentProjectID=" + encodeURIComponent(this.state.ApproveProjectID));
        formBody.push("UserID=" + this.state.id);/*added for blockchain*/
        formBody.push("InvestmentName=" + encodeURIComponent(this.refs.InvestmentName.value));
        formBody.push("ProjectSummary=" + encodeURIComponent(this.state.project));
        formBody.push("FormCfullDescription=" + encodeURIComponent(this.state.description));
        formBody.push("CompanyContactDetails=" + encodeURIComponent(this.refs.CompanyContactDetails.value));
        formBody.push("FundingPortalCarry=" + encodeURIComponent(this.refs.FundingPortalCarry.value));
        formBody.push("FundingGoalMin=" + encodeURIComponent(this.refs.FundingGoalMin.value));
        formBody.push("FundingGoalMax=" + encodeURIComponent(this.refs.FundingGoalMax.value));
        formBody.push("MinimumInvestment=" + encodeURIComponent(this.refs.MinimumInvestment.value));
        formBody.push("FundingPeriod=" + encodeURIComponent(this.refs.FundingPeriod.value));
        formBody.push("ProjectPublishDate=" + encodeURIComponent(this.state.form.pdate));
        formBody.push("InvestmentStartDate=" + encodeURIComponent(this.state.form.sdate));
        formBody.push("LegalIssuerName=" + encodeURIComponent(this.refs.LegalIssuerName.value));
        formBody.push("TypeOfSecurity=" + encodeURIComponent(this.refs.TypeOfSecurity.value));
        formBody.push("SecurityUnitPrice=" + encodeURIComponent(this.refs.SecurityUnitPrice.value));
        formBody.push("TotalNoOfSecurities=" + encodeURIComponent(this.refs.TotalNoOfSecurities.value));
        formBody.push("HelloSignTemplate=" + encodeURIComponent(this.refs.HelloSignTemplate.value));
        formBody.push("dealCardImage=" + encodeURIComponent(((this.state.dealCardFiles.length) ? (this.state.dealCardFiles[0].newPath) : ('/img/image.jpg'))));
        formBody.push("IsSaved=" + encodeURIComponent(0));
        formBody.push("ProjectStatus=" + encodeURIComponent(""));
        formBody.push("EditProject=" + (1));
        formBody.push("DealCardDescription=" + encodeURIComponent(this.state.form.dealCardDescription));
        formBody = formBody.join("&");

        //do fetch call
        fetch('/api/updateProjectInvestment_RegCFsaved/' + (this.state.ApproveProjectID) + '/' + this.state.id + '/' + this.props.session.marketing, {
            method: 'post',
            headers: {
                'Accept': 'application/json; charset=UTF-8',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            body: formBody
        })
            .then((response) => response.json())
            .then((status) => {
                if (status.code === 200) {
                    swal("Project updated successfully", "", "success")
                        .then((value) => {
                            this.props.history.push("/app/EditProjects");
                        });
                } else if (status.code === 402) {
                    alert("Access Denied");
                } else if (status.code === 400) {
                    alert("Error occured");
                }
            })
            .catch((err) => {
                console.log(err);
                alert("Error: " + JSON.stringify(err))
            })
        //}
    }


    componentDidMount() {
        CKEDITOR.config.allowedContent = true;
        CKEDITOR.config.extraAllowedContent = 'iframe[*]';
        CKEDITOR.config.extraAllowedContent = 'iframe(*)';
    }


    render() {
        const {date, startdate} = this.state;
        return (
            <div className="container body">
                <div className="main_container">
                    <div className="right_col" role="main">
                        <div className="panel panel-primary">
                            <div className="panel-heading">
                                <h3 className="panel-title">Submit Your Project (not a binding offer) </h3>
                            </div>
                            <div className="panel-body">
                                <form>
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                        <div className="row">
                                            <div className="col-md-12 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <label>Investment Name *</label>
                                                    <input type="text" id="investment" ref="InvestmentName"
                                                           className="form-control" onBlur={(e) => this.inputChange(e)}
                                                           onChange={(e) => this.inputChange(e)}/>
                                                    <span id="error_investment" className="text-danger"/>
                                                </div>
                                            </div>
                                        </div>

                                        <DealCardDescriptionComponent maxCharCount={500}
                                                                      value={this.state.form.dealCardDescription}
                                                                      onInputChange={this.inputChange}/>

                                        <div className="row">
                                            <div className="col-md-12 col-sm-12 col-xs-12">
                                                <label className="control-label">Project Summary *</label>
                                                <span id="error_project" className="text-danger"/>
                                                <div className='editor_container' style={{
                                                    border: '1px solid #ccc',
                                                    padding: '10px',
                                                    maxHeight: '800px',
                                                    overflowY: 'scroll'
                                                }}>
                                                    <CKEditor id="shortdesc" activeClass="p10"
                                                              config={{allowedContent: true}}
                                                              content={this.state.project}
                                                              events={{"change": this.onChangeproject}}/>
                                                </div>


                                            </div>
                                            <div className="col-md-12 col-sm-12 col-xs-12">

                                                <label className="control-label">Form C Full Description *</label>
                                                <span id="error_description" className="text-danger"/>
                                                <div className='editor_container' style={{
                                                    border: '1px solid #ccc',
                                                    padding: '10px',
                                                    maxHeight: '800px',
                                                    overflowY: 'scroll'
                                                }}>
                                                    <CKEditor id="longdesc"
                                                              activeClass="p10"
                                                              config={{
                                                                  allowedContent: true,
                                                                  extraAllowedContent: 'iframe(*)'
                                                              }} content={this.state.description}
                                                              events={{"change": this.onChangeDescription}}/>
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <label className="control-label">Company Contact Details *</label>
                                                    <input type="text" id="companycontact" ref="CompanyContactDetails"
                                                           className="form-control" onBlur={(e) => this.inputChange(e)}
                                                           onChange={(e) => this.inputChange(e)}/>
                                                    <span id="error_companycontact" className="text-danger"/>
                                                </div>
                                            </div>
                                            <div className="col-md-12 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <label className="control-label">Funding Portal Carry % *</label>
                                                    <input type="text" id="portal" ref="FundingPortalCarry"
                                                           className="form-control" onBlur={(e) => this.inputChange(e)}
                                                           onChange={(e) => this.inputChange(e)}/>
                                                    <span id="error_portal" className="text-danger"/>
                                                </div>
                                            </div>
                                        </div>
                                        {/*/row*/}
                                        <div className="portlet"/>
                                        <div className="row">
                                            <div className="col-md-12 col-sm-12 col-xs-12"><h5><b>Contact
                                                information</b></h5></div>
                                            <div className="col-md-12 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <label className="control-label">Funding Goal Min *</label>
                                                    <input type="text" id="goalmin" ref="FundingGoalMin"
                                                           className="form-control" onBlur={(e) => this.inputChange(e)}
                                                           onChange={(e) => this.inputChange(e)}/>
                                                    <span id="error_goalmin" className="text-danger"/>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <label className="control-label">Funding Goal Max *</label>
                                                    <input type="text" id="goalmax" ref="FundingGoalMax"
                                                           className="form-control" onBlur={(e) => this.inputChange(e)}
                                                           onChange={(e) => this.inputChange(e)}/>
                                                    <span id="error_goalmax" className="text-danger"/>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <label className="control-label">Minimum Investment *</label>
                                                    <input type="text" id="minimuminvest" ref="MinimumInvestment"
                                                           className="form-control" onBlur={(e) => this.inputChange(e)}
                                                           onChange={(e) => this.inputChange(e)}/>
                                                    <span id="error_minimuminvest" className="text-danger"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <label className="control-label">Funding Period (minimum 21 days
                                                        open) *</label>
                                                    <input type="text" id="fundingperiod" ref="FundingPeriod"
                                                           className="form-control" onBlur={(e) => this.inputChange(e)}
                                                           onChange={(e) => this.inputChange(e)}/>
                                                    <span id="error_fundingperiod" className="text-danger"/>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <label className="control-label">Project Publish Date *</label>
                                                    <Flatpickr data-enable-time options={{enableSeconds: true}}
                                                               id="pdate" ref="ProjectPublishDate"
                                                               value={this.state.Deal1.ProjectPublishDate}
                                                               onChange={date => {
                                                                   const prev = this.state.form;
                                                                   prev['pdate'] = moment(date[0]).format('YYYY-MM-DD HH:mm:ssZ');
                                                                   this.setState({form: prev});
                                                               }}/>
                                                    <span id="error_pdate" className="text-danger"/>
                                                </div>
                                            </div>
                                        </div>
                                        {/*/row*/}
                                        <div className="row">
                                            <div className="col-md-6 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <label className="control-label">Investment Start Date *</label>
                                                    <Flatpickr data-enable-time options={{enableSeconds: true}}
                                                               id="sdate" ref="InvestmentStartDate"

                                                               value={this.state.Deal1.InvestmentStartDate}
                                                               onChange={date => {
                                                                   let prev = this.state.form;
                                                                   prev['sdate'] = moment(date[0]).format('YYYY-MM-DD HH:mm:ssZ');
                                                                   this.setState({form: prev})
                                                               }}/> <span id="error_sdate" className="text-danger"/>
                                                    <span id="error_sdate" className="text-danger"/>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <label className="control-label">Legal Issuer Name *</label>
                                                    <input type="text" id="issuername" ref="LegalIssuerName"
                                                           className="form-control" onBlur={(e) => this.inputChange(e)}
                                                           onChange={(e) => this.inputChange(e)}/>
                                                    <span id="error_issuername" className="text-danger"/>
                                                </div>
                                            </div>
                                        </div>
                                        {/*/row*/}
                                        <div className="portlet"/>
                                        <div className="row">
                                            <div className="col-md-12 col-sm-12 col-xs-12"><h5><b>Project Details</b>
                                            </h5></div>
                                            <div className="col-md-12 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <label className="control-label">Type of Security *</label>&nbsp;
                                                    <span id="error_security" className="text-danger"/>
                                                    <textarea ref="TypeOfSecurity" id="security"
                                                              className="form-control"
                                                              onBlur={(e) => this.inputChange(e)}
                                                              onChange={(e) => this.inputChange(e)}/>
                                                </div>
                                            </div>
                                        </div>
                                        {/*/row*/}
                                        <div className="row">
                                            <div className="col-md-12 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <label className="control-label">Security Unit Price *</label>&nbsp;
                                                    <span id="error_sunitprice" className="text-danger"/>
                                                    <textarea id="sunitprice" ref="SecurityUnitPrice"
                                                              className="form-control"
                                                              onBlur={(e) => this.inputChange(e)}
                                                              onChange={(e) => this.inputChange(e)}/>
                                                </div>
                                            </div>
                                        </div>
                                        {/*/row*/}
                                        <div className="row">
                                            <div className="col-md-12 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <label className="control-label">Total Number of Securities
                                                        *</label>&nbsp;
                                                    <span id="error_totalsecurities" className="text-danger"/>
                                                    <textarea ref="TotalNoOfSecurities" id="totalsecurities"
                                                              className="form-control"
                                                              onBlur={(e) => this.inputChange(e)}
                                                              onChange={(e) => this.inputChange(e)}/>
                                                </div>
                                            </div>
                                        </div>
                                        {/*/row*/}
                                        <div className="row">
                                            <div className="col-md-12 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <label className="control-label">HelloSign Template *</label>&nbsp;
                                                    <span id="error_hellosign" className="text-danger"/>
                                                    <select className="form-control" ref="HelloSignTemplate"
                                                            id="hellosign" onBlur={(e) => this.inputChange(e)}
                                                            onChange={(e) => this.inputChange(e)}>
                                                        <option value={-1}>Please select a HelloSign template for the
                                                            User To Sign
                                                        </option>
                                                        {
                                                            this.state.HSDoclist.map((Doc, i) => (
                                                                <option key={i}
                                                                        selected={Doc.template_id == this.state.Deal1.HelloSignTemplate}
                                                                        value={Doc.template_id}
                                                                        style={{color: 'black'}}>{Doc.title}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        {/*/row*/}
                                        <div className="container">
                                            <div className="panel panel-default">
                                                <div className="panel-heading1 ph">
                                                    Deal Card Image
                                                </div>
                                                <div className="panel-body">
                                                    <div className="upload-btn-wrapper">
                                                        <button className="fa fa-plus-circle btn btn-primary"> Add
                                                            files...
                                                        </button>
                                                        <input type="file" id="dealfiles" name="mapfiles"
                                                               onChange={this.onChangeFiles}/>
                                                    </div>
                                                    <div className="upload-btn-wrapper">
                                                        <button type="button" id="dealCard"
                                                                className="fa fa-upload btn btn-primary"
                                                                onClick={(e) => {
                                                                    this.filesUpload(e)
                                                                }}> Start Upload
                                                        </button>
                                                    </div>
                                                    <div style={{fontSize: 11}}><em> *All uploaded files need to be 15MB
                                                        or less.</em></div>
                                                </div>
                                            </div>
                                            <div>
                                                {
                                                    (() => {
                                                        if (Object.keys(this.state.dealCardFiles).length) {
                                                            return (
                                                                <Preview data={this.state.dealCardFiles}
                                                                         images={this.state.dealCardImages}
                                                                         type="dealfiles"
                                                                         deleteAction={this.onDeleteFile}/>
                                                            )
                                                        } else if (Object.keys(this.state.dealCard).length && Object.keys(this.state.dealCardImages).length) {
                                                            return (
                                                                <Preview data={this.state.dealCard}
                                                                         images={this.state.dealCardImages}
                                                                         type="dealfiles"
                                                                         deleteAction={this.onDeleteFile}/>
                                                            )
                                                        }
                                                    })()
                                                }
                                            </div>
                                        </div>
                                        <div className="container">
                                            <div className="panel panel-default">
                                                <div className="panel-heading1 ph">
                                                    Additional Form C Attachments
                                                </div>
                                                <div className="panel-body">
                                                    <div className="upload-btn-wrapper">
                                                        <span>Upload Due Dilligence Materials </span>
                                                        <button className="fa fa-plus-circle btn btn-primary"> Add
                                                            files...
                                                        </button>
                                                        <input type="file" id="maps" name="mapfiles"
                                                               onChange={this.onChangeFiles} multiple={true}/>
                                                    </div>
                                                    <div className="upload-btn-wrapper">
                                                        <button type="button" id="pertinentMaps"
                                                                className="fa fa-upload btn btn-primary"
                                                                onClick={(e) => {
                                                                    this.filesUpload(e)
                                                                }}> Start Upload
                                                        </button>
                                                    </div>
                                                    <div style={{fontSize: 11}}><em> *All uploaded files need to be 15MB
                                                        or less.</em></div>
                                                </div>
                                            </div>
                                            <div>
                                                {
                                                    (() => {
                                                        if (Object.keys(this.state.pertinentMapsFiles).length) {
                                                            return (
                                                                <Preview data={this.state.pertinentMapsFiles}
                                                                         images={this.state.pertinentMapsImages}
                                                                         type="maps" deleteAction={this.onDeleteFile}/>
                                                            )
                                                        } else if (Object.keys(this.state.pertinentMaps).length && Object.keys(this.state.pertinentMapsImages).length) {
                                                            return (
                                                                <Preview data={this.state.pertinentMaps}
                                                                         images={this.state.pertinentMapsImages}
                                                                         type="maps" deleteAction={this.onDeleteFile}/>
                                                            )
                                                        }
                                                    })()
                                                }
                                            </div>
                                        </div>
                                        <div className="container">
                                            <div className="panel panel-default">
                                                <div className="panel-heading1 ph">
                                                    Please also include any additional materials you would like us to
                                                    have.
                                                </div>
                                                <div className="panel-body">
                                                    <div className="upload-btn-wrapper">
                                                        <button className="fa fa-plus-circle btn btn-primary"> Add
                                                            files...
                                                        </button>
                                                        <input type="file" id="addmat" name="addmatfiles"
                                                               onChange={this.onChangeFiles} multiple={true}/>
                                                    </div>
                                                    <div className="upload-btn-wrapper">
                                                        <button type="button" id="additionalMaterials"
                                                                className="fa fa-upload btn btn-primary"
                                                                onClick={(e) => {
                                                                    this.filesUpload(e)
                                                                }}> Start Upload
                                                        </button>
                                                    </div>
                                                    <div style={{fontSize: 11}}><em> *All uploaded files need to be 15MB
                                                        or less.</em></div>
                                                </div>
                                            </div>
                                            <div>
                                                {
                                                    (() => {
                                                        if (Object.keys(this.state.additionalMaterialsFiles).length) {
                                                            return (
                                                                <Preview data={this.state.additionalMaterialsFiles}
                                                                         images={this.state.additionalMaterialsImages}
                                                                         type="addmat"
                                                                         deleteAction={this.onDeleteFile}/>
                                                            )
                                                        } else if (Object.keys(this.state.additionalMaterials).length && Object.keys(this.state.additionalMaterialsImages).length) {
                                                            return (
                                                                <Preview data={this.state.additionalMaterials}
                                                                         images={this.state.additionalMaterialsImages}
                                                                         type="addmat"
                                                                         deleteAction={this.onDeleteFile}/>
                                                            )
                                                        }
                                                    })()
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </form>
                                <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                    <button type="Submit" onClick={this.onPublish}
                                            className="btn btn-primary pull-right">Update Project
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const {object, bool} = PropTypes;

EditProjectCF.propTypes = {
    session: object.isRequired,
    authenticated: bool.isRequired,
    checked: bool.isRequired
};

const mapState = ({session}) => ({
    session: session.user,
    checked: session.checked,
    authenticated: session.authenticated
});

export default withRouter(connect(mapState)(EditProjectCF));