import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import {Modal} from "react-bootstrap";
import Handlebars from "handlebars/runtime";
import templates from '../../../templates.compiled';
import ViewerHeaderComponent from "./components/ViewerHeaderComponent";
import ViewerImageSectionComponent from "./components/ViewerImageSectionComponent";
import ViewerSubjectSectionComponent from "./components/ViewerSubjectSectionComponent";
import ViewerBodyComponent from "./components/ViewerBodyComponent";
import {USER_INVESTOR} from "../../../utils/ApplicationConstants";

const propTypes = {
    // modal management
    showModal: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,

    // project info
    subject: PropTypes.string.isRequired,
    smallText: PropTypes.string.isRequired,
    longText: PropTypes.string.isRequired,
    projectName: PropTypes.string.isRequired,
    creationDate: PropTypes.any.isRequired,
    canShowText: PropTypes.bool.isRequired,
    coverImage: PropTypes.string.isRequired,
    userType: PropTypes.string.isRequired,
};

const defaultProps = {
    // modal management
    showModal: false,
    closeModal: () => "",

    // project info
    subject: "",
    smallText: "",
    longText: "",
    projectName: "",
    creationDate: new Date(),
    canShowText: false,
    coverImage: "",
    userType: USER_INVESTOR
};

const ProjectUpdateViewer = (props) => {
    const {showModal, closeModal} = props;
    const {
        subject, smallText, longText, // projectName, -- Not using this 'alias' for Email Reason.
        creationDate, coverImage, userType
    } = props;
    const date = moment(creationDate).format("MMM DD, YYYY @ HH:mm");

    const closeButton = (
        <button className="btn btn-primary"
                type="button"
                style={{paddingRight: "60px", paddingLeft: "60px", marginTop: "16px"}}
                onClick={closeModal}>
            Close
        </button>
    );

    return (
        <Modal show={showModal}
               onHide={closeModal}
               className="translucid-dark-bg"
               style={{padding: "0 !important"}}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="dark-grey-text">
                        <span className="bold-text">Date:</span> {date}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{padding: 0}}>
                <div className="container"
                     style={{backgroundColor: "#ededed"}}>
                    <div className="col-xs-12 col-sm-12 col-md-1 col-lg-2 col-xl-3"/>
                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-8 col-xl-6"
                         style={{padding: 0}}>
                        <div className="table-responsive"
                             style={{margin: "15px 0px"}}>
                            <table style={{width: "100%", boxSizing: "border-box", backgroundColor: "#ffffff"}}
                                   cellPadding={0}
                                   cellSpacing={0}>
                                <tbody>
                                <ViewerHeaderComponent/>
                                <ViewerImageSectionComponent imageLocation={coverImage}/>
                                <ViewerSubjectSectionComponent projectName={''}
                                                               subject={subject}/>
                                <ViewerBodyComponent longText={longText}
                                                     smallText={smallText}
                                                     userType={userType}/>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <div className="modal-footer">
                <div className="center">
                    {closeButton}
                </div>
            </div>
        </Modal>
    );
};

ProjectUpdateViewer.propTypes = propTypes;
ProjectUpdateViewer.defaultProps = defaultProps;

export default ProjectUpdateViewer;
