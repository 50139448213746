import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import Pagination from 'react-js-pagination';
import { getHash, getUUID } from '../../../../utils/General';

class TrackingViewerComponent extends React.Component {
    ITEMS_PER_PAGE = 10;

    constructor(props) {
        super(props);

        this.state = {
            activePage: 1,
            pageItems: [],
        };
    }

    componentDidMount() {
        const { setOnPageChange } = this.props;
        const { activePage } = this.state;

        this.onPageChange(activePage);
        setOnPageChange(this.onPageChange);
    }

    componentDidUpdate(prevProps, prevState, snap) {
        const { activePage } = this.state;
        const { trackedEventsList } = this.props;
        const trackedEventsListHash = getHash(trackedEventsList);
        const _trackedEventsListHash = getHash(prevProps.trackedEventsList);

        if (trackedEventsListHash !== _trackedEventsListHash) {
            this.onPageChange(activePage);
        }
    }

    onPageChange = (activePage) => {
        const { trackedEventsList } = this.props;
        const start = (activePage - 1) * this.ITEMS_PER_PAGE;
        const end = (start + this.ITEMS_PER_PAGE);

        const pageItems = trackedEventsList.slice(start, end);

        this.setState({
            pageItems,
            activePage,
        });
    };

    // RENDER MANAGER
    renderTableElements = () => {
        const { openInvestorDetailsModal } = this.props;
        const { pageItems } = this.state;

        if (!pageItems || !pageItems.length) {
            return (
                <tr style={{ textAlign: 'center' }}>
                    <td colSpan={8}>No Tracking data for this investor</td>
                </tr>
            );
        }

        return pageItems.map((item) => {
            const obj = JSON.parse(item.jsonData);
            const keyValuePairs = Object.keys(obj)
                .map(key => `${key}=${obj[key]}`)
                .join(', ');

            return (
                <tr key={getUUID()}>
                    <td className="no-text-wrap">
                        {moment(item.createdDate)
                            .format('MMM DD, YYYY @ HH:mm')}
                    </td>
                    <td className="no-text-wrap">
                        <a
                            onClick={() => openInvestorDetailsModal(item.userId)}
                            style={{ cursor: 'pointer' }}
                        >
                            <div style={{ textAlign: 'left' }}>
                                {item.userName}
                                <br />
                                {item.email}
                                <br />
                                {item.userId}
                            </div>
                        </a>
                    </td>
                    <td className="no-text-wrap">{item.projectName}</td>
                    <td>{item.stepNumber}</td>
                    <td className="no-text-wrap capitalize-text">{item.eventName}</td>
                    <td>{keyValuePairs}</td>
                </tr>
            );
        });
    };

    render = () => {
        const { activePage } = this.state;
        const { trackedEventsList } = this.props;

        const totalItems = trackedEventsList.length;

        return (
            <div className="col-xs-12">
                <div className="table-responsive">
                    <table className="table table-hover table-striped">
                        <thead>
                            <tr>
                                <th className="no-text-wrap">Created Date</th>
                                <th className="no-text-wrap">Name / Email /UserID</th>
                                <th className="no-text-wrap">Project Name</th>
                                <th className="no-text-wrap">Step Number</th>
                                <th className="no-text-wrap">Event</th>
                                <th className="no-text-wrap">Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderTableElements()}
                        </tbody>
                    </table>
                </div>
                <div className="pull-right">
                    <Pagination
                        firstPageText={<i className="glyphicon glyphicon-chevron-left" />}
                        lastPageText={<i className="glyphicon glyphicon-chevron-right" />}
                        prevPageText={<i className="glyphicon glyphicon-menu-left" />}
                        nextPageText={<i className="glyphicon glyphicon-menu-right" />}
                        activePage={activePage}
                        itemsCountPerPage={this.ITEMS_PER_PAGE}
                        totalItemsCount={totalItems}
                        onChange={this.onPageChange}
                    />
                </div>
            </div>
        );
    };
}

TrackingViewerComponent.propTypes = {
    openInvestorDetailsModal: PropTypes.func.isRequired,
    setOnPageChange: PropTypes.func,
    trackedEventsList: PropTypes.array.isRequired,
};

TrackingViewerComponent.defaultProps = {
    openInvestorDetailsModal: () => null,
    setOnPageChange: () => null,
    trackedEventsList: [],
};

export default TrackingViewerComponent;
