import React from 'react';
import PropTypes from 'prop-types'
import Icon from '../../../../IconComponent/IconComponent';
import './PastInvestmentCardStyles.css'


const PastInvestmentCard = (props) => {
    const {
        cardImage, projectName, video, moneyRaised,
        numberOfInvestors, minGoal, maxGoal, status,
        openVideo, version
    } = props;

    const showVideoModal = () => {
        if (video)
            openVideo(video, projectName);
    };

    return (
        <div className="col-xs-12 col-sm-12 col-md-4 no-padding">
            <div className="past-investment-card">
                <div className="past-investment-card-image pointer"
                     style={{backgroundImage: `url(${cardImage})`}}
                     onClick={() => props.onSeeDetails(props.projectID, props.projectType, version)}>
                </div>
                <div className="past-investment-card-data">
                    <div className="past-investment-card-title pointer"
                         onClick={() => props.onSeeDetails(props.projectID, props.projectType, version)}>
                        {projectName}
                    </div>
                    <div className="past-investment-card-video-button upper-case-text"
                         onClick={showVideoModal}>
                        {video && <Icon iconName="play-circle-o" extraClasses="purple-text icon"/>}&ensp;
                        {video && <span>play video</span>}
                    </div>
                    <div className="heavy-text regular-text upper-case-text">
                        Total Raised: ${parseInt(moneyRaised).toLocaleString()} ({((moneyRaised / (minGoal > 0 ? minGoal : 1)) * 100).toFixed(0)}% Funded)
                    </div>
                    <hr/>
                    <div className="regular-text upper-case-text">
                        total investors: {numberOfInvestors}
                    </div>
                    <hr/>
                    <div className="regular-text upper-case-text">
                        funding goal: ${minGoal.toLocaleString()} to ${maxGoal.toLocaleString()}
                    </div>
                    <hr/>
                    <div className="past-investment-card-status">
                        <span><span className="purple-text heavy-text">Status:</span> {status}</span>
                    </div>
                </div>
                <div className="past-investment-card-footer upper-case-text"
                     onClick={() => props.onSeeDetails(props.projectID, props.projectType, version)}>
                    <span>see project details <Icon iconName="chevron-right" extraClasses="white-text"/></span>
                </div>
            </div>
        </div>
    );
};

PastInvestmentCard.propTypes = {
    cardImage: PropTypes.string.isRequired,
    maxGoal: PropTypes.number.isRequired,
    minGoal: PropTypes.number.isRequired,
    moneyRaised: PropTypes.number.isRequired,
    numberOfInvestors: PropTypes.number.isRequired,
    onSeeDetails: PropTypes.func.isRequired,
    openVideo: PropTypes.func.isRequired,
    projectID: PropTypes.number.isRequired,
    projectName: PropTypes.string.isRequired,
    projectType: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    version: PropTypes.number.isRequired,
    video: PropTypes.string.isRequired
};

PastInvestmentCard.defaultProps = {
    cardImage: '',
    maxGoal: 0,
    minGoal: 0,
    moneyRaised: 0,
    numberOfInvestors: 0,
    onSeeDetails: () => null,
    openVideo: () => null,
    projectID: 0,
    projectName: '',
    projectType: '',
    status: '',
    version: 1,
    video: ''
};

export default PastInvestmentCard;
