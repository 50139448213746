import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as IDActions from "./actions/actions";
import InvestorEarningsContainer from "../../shared/AllInvestmentsContainerComponent/InvestorEarningsContainer";
import  '../css/earnings.css'
import NewUserEarningsDashboard from "./NewUserEarningsDashboard";

class InvestorDashboardContainerComponent extends React.Component {
	
	ITEMS_PER_PAGE = 5;
	constructor(props) {
		super(props);
		this.state = {
            id:props.session.id,
			isAdmin:false,
		isLoading:false,
		};
	}
	componentDidMount() {
		const {
			getInvestorEarnings,
			investorId,
			getAllUserInvestments,
			getDashboardData,
		} = this.props;
		
		getAllUserInvestments();
		getDashboardData();
		this.getRoles();
		this.callInvestorEarnings();
	}
	componentWillUnmount() {
		const { resetDashboardData } = this.props;
		resetDashboardData();
	}

	getRoles(){
      
        fetch('/api/getrole/'+this.state.id+'/'+ +this.state.id+'/' +  this.props.session.marketing,{
            method:'get'
        })
            .then((response)=>{
                return response.json()
            })
            .then((data)=>{
            const roles = data.map(item => item.Role);
          
            if(roles.includes("SuperAdmin") || roles.includes("Admin")){
                this.setState({
                             
                    isAdmin: true,
               
                });
            }
          
            })
        
    }


		callInvestorEarnings =async() => {
		try {
		await this.props.getInvestorEarnings();
		let earnings =	await this.props.investorEarnings;

		await this.props.getAllUserInvestments();
		let investments =	await this.props.investorInvestments;

		//Show earnings tab if atleast investment exists
			if(earnings?.length > 0 || investments?.length > 0){
				this.setState({isLoading:false})
			}
			
				else{
					this.setState({isLoading:true})
				}

		}
		catch{

		}

		}


	render() {
		const {
			totalPaymentsRegD,
			totalPaymentsRegCF,
			investorEarnings,
			reducedEarnings,
			reducedInvestments,
			investorInvestments,
			getDashboardData
		} = this.props;


        const { earningLabels, earningsAmount, cumulativeTimeSeries: cumulativeEarningsTimeSeries, cumulativeEarningsAmount, distributionEarningChartData, cumulativeEarningsChartData } = reducedEarnings;
		
        const {
            investmentCounts, investmentAmounts, investmentLabels,
            cumulativeInvestmentCounts, cumulativeInvestmentAmounts,
            cumulativeTimeSeries: cumulativeInvestmentTimeSeries
        } = reducedInvestments;
	
		const cumulativeEarnings = earningsAmount
			.map((i) => i.toFixed(2))
			.map((i) => parseFloat(i));
		const EarningsBg ='/img/earnings-banner-bg.jpg'
		const {isAdmin,isLoading} = this.state;

		return (<> 
			{isLoading ? <NewUserEarningsDashboard/>:<div className="container invest-dashboard earnings" style={{backgroundImage:`url(${EarningsBg})`}}>
				{investorInvestments.length > 0 &&
					// investorEarnings.length > 0 && 
					(
						<InvestorEarningsContainer
							investmentLabels={earningLabels} ///
							investmentAmounts={earningsAmount}
							distributionEarningChartData={distributionEarningChartData}
							cumulativeEarningsChartData={cumulativeEarningsChartData}
							cumulativeEarnings={cumulativeEarnings}///
							investorEarnings={investorEarnings}
							totalPaymentsRegCf={totalPaymentsRegCF}
							totalPaymentsRegD={totalPaymentsRegD}
							itemsPerPage={this.ITEMS_PER_PAGE}
							investorInvestments={investorInvestments}
							cumulativeEarningsTimeSeries= {cumulativeEarningsTimeSeries}
							cumulativeInvestmentTimeSeries ={cumulativeInvestmentTimeSeries}
							getDashboardData ={getDashboardData}
							role="Investor"
						/>
					)}
			</div> }
			</>
		);
	}
}
InvestorDashboardContainerComponent.propTypes = {
	getAllUserInvestments: PropTypes.func.isRequired,
	resetDashboardData: PropTypes.func.isRequired,
	getInvestorEarnings: PropTypes.func.isRequired,
	getDashboardData: PropTypes.func.isRequired,
	investorEarnings: PropTypes.array.isRequired,
	investorId: PropTypes.number.isRequired,
	investorInvestments: PropTypes.array.isRequired,

	totalPaymentsRegD: PropTypes.any.isRequired,
	reducedEarnings: PropTypes.object.isRequired,
	reducedInvestments: PropTypes.object.isRequired,
};
InvestorDashboardContainerComponent.defaultProps = {
	getAllUserInvestments: () => null,
	resetDashboardData: () => null,
	getDashboardData: () => null,
	getInvestorEarnings: () => null,
	investorEarnings: [],
	investorId: 0,
	investorInvestments: [],
	totalPaymentsRegD: 0,
};
const mapStateToProps = (_state) => ({
	..._state.investorDashboard,
	investorId: _state.session.user.id,
	session: _state.session.user
});
const mapDispatchToProps = (_dispatch) => ({
	...bindActionCreators(IDActions, _dispatch),
});
const connectedComponent = connect(
	mapStateToProps,
	mapDispatchToProps
)(InvestorDashboardContainerComponent);
export default connectedComponent;
