import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from "redux";
import ProjectUpdateViewer from "../../shared/projectUpdateViewerComponent/ProjectUpdateViewer"

import {
    PROJECT_REG_CF,
    PROJECT_REG_D, PROJECT_UPDATE_CLONE_AS_NEW,
    PROJECT_UPDATE_CREATE,
    PROJECT_UPDATE_EDIT,
    USER_ADMIN,
} from "../../../utils/ApplicationConstants"
import Tabs from '../../shared/TabsComponent/index';
import Icon from "../../shared/IconComponent/IconComponent";
import * as Utils from "./utils/Utils";
import ProjectUpdateTable from './components/ProjectUpdateTable';
import * as ProjectUpdateActions from './actions/index';
import ProjectUpdateDisplayStatusEditor from "./components/ProjectUpdateDisplayStatusEditor";

const emptyFunc = () => null;

const mapStateToProps = (state) => {
    return {
        ...state.projectUpdate,
        user: state.session.user,
    };
};

class ProjectUpdateContainer extends React.Component {
    static propTypes = {
        approveProjectUpdate: PropTypes.func.isRequired,
        deleteProjectUpdate: PropTypes.func.isRequired,
        getAllProjectUpdates: PropTypes.func.isRequired,
        getProjectAndNotification: PropTypes.func.isRequired,
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
        }).isRequired,
        regDUpdateList: PropTypes.array.isRequired,
        regCFUpdateList: PropTypes.array.isRequired,
        rejectProjectUpdate: PropTypes.func.isRequired,
        restoredDeletedProjectUpdate: PropTypes.func.isRequired,
        setSelectedProjectUpdate: PropTypes.func.isRequired,
        updateDisplayStatus: PropTypes.func.isRequired,
        user: PropTypes.objectOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                marketing: PropTypes.string.isRequired,
            }).isRequired
        ).isRequired,
    };

    static defaultProps = {
        approveProjectUpdate: emptyFunc,
        deleteProjectUpdate: emptyFunc,
        getAllProjectUpdates: emptyFunc,
        getProjectAndNotification: emptyFunc,
        history: {
            push: emptyFunc,
        },
        regDUpdateList: [],
        regCFUpdateList: [],
        rejectProjectUpdate: emptyFunc,
        restoredDeletedProjectUpdate: emptyFunc,
        setSelectedProjectUpdate: emptyFunc,
        updateDisplayStatus: emptyFunc,
        user: {
            id: 0,
            marketing: "",
        },
    };

    TABS = [
        {
            title: "EnergyFunders Black (Reg D)",
            number: 0,
        },
        {
            title: "EnergyFunders Marketplace (Reg CF)",
            number: 1,
        }
    ];

    constructor(props) {
        super(props);

        this.state = {
            selectedTab: 0,
            isModalOpen: false,
            openTypeEditor: false,

            updateId: -1,
            updateSubject: "",
            daysAsPrivateUpdate: 0,
            isPrivateForEver: true,
            updateType: -1,
            projectType: PROJECT_REG_D,
        };
    };

    componentDidMount() {
        const {getAllProjectUpdates, user} = this.props;
        getAllProjectUpdates(user);
    };

    componentDidUpdate(prevProps) {
        const notificationDataPrev = JSON.stringify(prevProps.selectedUpdated.notificationData);
        const notificationDataActual = JSON.stringify(this.props.selectedUpdated.notificationData);

        if (notificationDataActual !== notificationDataPrev)
            this.setState({isModalOpen: true});
    }

    setSelectedTab = (selectedTab) => {
        this.setState({selectedTab});
    };

    onApprove = (projectType, projectID, subject, updateSmallText, notificationID) => {
        const {approveProjectUpdate, user} = this.props;
        const approvalData = {subject, projectID, notificationID, updateSmallText};

        approveProjectUpdate(projectType, approvalData, user);
    };

    onReject = (projectType, notificationID) => {
        const {rejectProjectUpdate, user} = this.props;
        rejectProjectUpdate(projectType, notificationID, user);
    };

    onEdit = (projectType, notificationID) => {
        const {history, setSelectedProjectUpdate} = this.props;
        const data = {
            projectUpdateId: notificationID,
            projectType: projectType,
            action: PROJECT_UPDATE_EDIT,
        };
        setSelectedProjectUpdate(data);
        history.push(`/app/AdminProjectUpdate/edit`);
    };

    onCloneAsNew = (projectType, notificationID) => {
        const {history, setSelectedProjectUpdate} = this.props;
        const data = {
            projectUpdateId: notificationID,
            projectType: projectType,
            action: PROJECT_UPDATE_CLONE_AS_NEW,
        };
        setSelectedProjectUpdate(data);
        history.push(`/app/AdminProjectUpdate/create`);
    };

    onCreateNewUpdate = () => {
        const {history, setSelectedProjectUpdate} = this.props;
        const selectionData = {
            projectUpdateId: -1,
            projectType: PROJECT_REG_D,
            action: PROJECT_UPDATE_CREATE,
        };
        setSelectedProjectUpdate(selectionData);
        history.push(`/app/AdminProjectUpdate/create`);
    };

    onDelete = (projectType, notificationID) => {
        const {deleteProjectUpdate, user} = this.props;
        deleteProjectUpdate(projectType, notificationID, user);
    };

    onRestore = (projectType, notificationID) => {
        const {restoredDeletedProjectUpdate, user} = this.props;
        restoredDeletedProjectUpdate(projectType, notificationID, user);
    };

    onViewProject = (projectType, notificationId) => {
        const {user, getProjectAndNotification} = this.props;
        getProjectAndNotification(projectType, notificationId, user);
    };

    onOpenUpdateTypeEditor = (updateId, updateSubject, daysAsPrivateUpdate, projectType) => {
        let isPrivateForEver = true;
        let updateType = daysAsPrivateUpdate;

        if (daysAsPrivateUpdate >= 1) {
            updateType = 0;
            isPrivateForEver = false;
        }

        this.setState({
            openTypeEditor: true,
            updateId,
            updateSubject,
            daysAsPrivateUpdate,
            isPrivateForEver,
            updateType,
            projectType,
        });
    };

    onCloseUpdateTypeEditor = () => {
        this.setState({openTypeEditor: false});
    };

    onCheckboxSelected = (event) => {
        const {name, checked} = event.target;
        this.setState({[name]: checked});
    };

    onChangeHandler = (event) => {
        const {name, value} = event.target;
        console.log(name);
        this.setState({[name]: parseInt(value)});
    };

    onUpdateDisplayStatus = () => {
        console.log("called");
        const {updateDisplayStatus, user} = this.props;
        const {daysAsPrivateUpdate, isPrivateForEver, updateId, updateType, projectType} = this.state;
        const daysAsPrivate = Utils.getDaysAsPrivateUpdate(daysAsPrivateUpdate, updateType, isPrivateForEver);

        const payload = {
            updateId,
            daysAsPrivate,
        };

        updateDisplayStatus(projectType, payload, user);
        this.onCloseUpdateTypeEditor();
    };

    closeModal = () => {
        this.setState({isModalOpen: false});
    };

    render = () => {
        const {
            selectedTab, daysAsPrivateUpdate, isPrivateForEver,
            updateType, updateSubject, updateId,
        } = this.state;
        const {notificationData} = this.props.selectedUpdated;

        return (
            <div className="container body">
                <div className="main_container">
                    <div className="to-right" style={{minHeight: 0}}>
                        <button className="btn btn-primary pull-right"
                                type="button"
                                onClick={this.onCreateNewUpdate}>
                            <span>New Update <Icon iconName="plus-square" extraClasses="white-text"/></span>
                        </button>
                        <h2 style={{paddingLeft: '10px', marginTop: '10px'}}>
                            Manage Project Updates
                        </h2>
                    </div>
                    <section>
                        <Tabs tabList={this.TABS}
                              defaultSelection={0}
                              setSelected={this.setSelectedTab}>
                            {selectedTab === 0 &&
                            <ProjectUpdateTable projectList={this.props.regDUpdateList}
                                                projectType={PROJECT_REG_D}
                                                onApproveClickHandler={this.onApprove}
                                                onDeleteClickHandler={this.onDelete}
                                                onSelectFileToEdit={this.onEdit}
                                                onRejectClickHandler={this.onReject}
                                                onRestoreDeletedClickHandler={this.onRestore}
                                                onViewClickHandler={this.onViewProject}
                                                onOpenUpdateTypeEditor={this.onOpenUpdateTypeEditor}
                                                onCloneAsNew={this.onCloneAsNew}/>
                            }
                            {selectedTab === 1 &&
                            <ProjectUpdateTable projectList={this.props.regCFUpdateList}
                                                projectType={PROJECT_REG_CF}
                                                onApproveClickHandler={this.onApprove}
                                                onDeleteClickHandler={this.onDelete}
                                                onSelectFileToEdit={this.onEdit}
                                                onRejectClickHandler={this.onReject}
                                                onRestoreDeletedClickHandler={this.onRestore}
                                                onViewClickHandler={this.onViewProject}
                                                onOpenUpdateTypeEditor={this.onOpenUpdateTypeEditor}
                                                onCloneAsNew={this.onCloneAsNew}/>
                            }
                        </Tabs>
                    </section>
                </div>

                <ProjectUpdateViewer longText={notificationData.NotificationText}
                                     smallText={notificationData.NotificationSmallText}
                                     subject={notificationData.Subject}
                                     creationDate={notificationData.CreatedDate}
                                     projectName={notificationData.ProjectName}
                                     closeModal={this.closeModal}
                                     showModal={this.state.isModalOpen}
                                     canShowText={true}
                                     coverImage={notificationData.CoverImage}
                                     userType={USER_ADMIN}/>

                <ProjectUpdateDisplayStatusEditor onCheckboxSelected={this.onCheckboxSelected}
                                                  daysAsPrivateUpdate={daysAsPrivateUpdate}
                                                  closeModal={this.onCloseUpdateTypeEditor}
                                                  onChangeHandler={this.onChangeHandler}
                                                  openModal={this.state.openTypeEditor}
                                                  subject={updateSubject}
                                                  updateId={updateId}
                                                  isPrivateForEver={isPrivateForEver}
                                                  updateType={updateType}
                                                  onSaveChanges={this.onUpdateDisplayStatus}/>
            </div>
        );
    };
}

const mapDispatchToProps = (dispatch) => {
    const projectUpdateActions = bindActionCreators(ProjectUpdateActions, dispatch);
    return {...projectUpdateActions}
};

const connectedComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectUpdateContainer);

export default withRouter(connectedComponent);
