import CKEditor from "react-ckeditor-component";
import React, { Component } from 'react'; 
import { withRouter } from 'react-router-dom';
import store from './index'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import swal from 'sweetalert2'


class ProjectNotificationCF extends Component {
    constructor(props) {
        super(props);
        this.state = {
          UserID:props.session.id,
          shortText:"",
          longText:"",
          id: this.props.location.state.id          
        };
      this.onChangeShort = this.onChangeShort.bind(this);
      this.onChangeLong = this.onChangeLong.bind(this);
      this.onSubmit = this.onSubmit.bind(this); 
      this.updateContent = this.updateContent.bind(this);       
    }

   onSubmit(e){
            //build form body

            e.preventDefault();

        var formBody = [];

        formBody.push("UserID=" + encodeURIComponent(this.state.UserID));
        formBody.push("ProjectID=" + encodeURIComponent(this.state.id));
        formBody.push("Subject=" + encodeURIComponent(this.refs.Subject.value));
        formBody.push("NotificationText=" + encodeURIComponent(this.state.longText));
        formBody.push("NotificationSmallText=" + encodeURIComponent(this.state.shortText));
       

        formBody = formBody.join("&");


     
        fetch('/api/projectnotificationCF'+'/'+this.state.id+'/'+this.props.session.marketing,{
                method:'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: formBody
            })
                .then((response)=>response.json())
                .then((status)=>{
                    if(status.code===200){
                       swal("Notification Added Successfuly","","success")
                            
                    }
                    else{
                        alert("error");
                    }
                   
                })
                .catch((err)=>{
                    alert("Error to notify: " + JSON.stringify(err))
                })
        }
        
    updateContent(stateItem,Content) {
      switch (stateItem) {
        case 'shortText':
          this.setState({
            shortText: Content,
          });
          break;

        case 'longText':
          this.setState({
            longText: Content,
          });
          break;
      
        default:
          break;
      }        
    }
     
    onChangeShort(e){
      this.updateContent('shortText',e.editor.getData());
    } 
    onChangeLong(e) {
      this.updateContent('longText', e.editor.getData());
    }  
  
    componentWillMount(){
      window.scrollTo(0, 0);
    } 
 
    render() {
        return (
          <div className="container body">
              <div className="main_container">
                  <div className="right_col back" role="main">
          <div className="portlet-title" style={{minHeight:1024}}>
          <div className="caption">
           Energyfunders - Notifications
          </div>

            <div>
            <h3><strong>Send a new notification to investment group</strong></h3>
            <h4>Subject:</h4>
            <input type="text" name="subject" id="subject" ref="Subject"/>
            </div>

              <div>
              <h4>Short Description (Included in Email):</h4>
            <CKEditor             
              activeClass="p10" refs="shortText"
              id='shortText'
              content={this.state.shortText} 
              events={{               
                "afterPaste": this.onChangeShort,
                "change": this.onChangeShort,
                "blur": this.onChangeShort
              }}
              />
              </div>
              <div>
              <h4>Full Description (Not Included in Email, Only Seen on Notification Page):</h4>
               <CKEditor 
            
              activeClass="p10" refs="longText"
              id='longText'
              content={this.state.longText} 
              events={{               
                "afterPaste": this.onChangeLong,
                "change": this.onChangeLong,
                "blur": this.onChangeLong
              }}
              />
              </div>
              <button className="btn btn-primary" onClick={this.onSubmit} onChange={this.onblur} style={{marginTop: 10}} >submit</button>
          </div>
          </div>
          </div>
          </div>
        )
    }
}


const { object, bool } = PropTypes;

ProjectNotificationCF.propTypes = {
session: object.isRequired,
authenticated: bool.isRequired,
checked: bool.isRequired
};

const mapState = ({ session }) => ({
session: session.user,
checked: session.checked,
authenticated: session.authenticated
});

export default withRouter(connect(mapState)(ProjectNotificationCF));