import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import App from './App';
// import registerServiceWorker from './registerServiceWorker';
import {unregister} from './registerServiceWorker';
import {Provider} from 'react-redux';
import {sessionService} from 'redux-react-session';
import initialState from './reducers/appInitialState';
import configureStore from './store/energyFundersStore';


const store = configureStore(initialState);
sessionService.initSessionService(store, {driver: 'COOKIES'});

ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>,
    document.getElementById('root')
);
// registerServiceWorker();
unregister();


export default store;
