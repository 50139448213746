import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EmailTableElementComponent from './components/EmailTableElementComponent';
import * as Actions from './actions/actions';
import { getHash } from '../../../utils/General';
import Icon from '../../shared/IconComponent/IconComponent';
import HideComponent from '../../shared/HideComponent/HideComponent';

class HubSpotEmailImportComponent extends React.Component {
    refLoadMoreButton = React.createRef();

    state = {
        limit: 100,
        offset: 0,
        hsEmailList: [],
        setAsEquals: false,
        selectedEmailsMap: {},
        loadingMoreElements: false,
    };

    componentDidMount() {
        const { getHSPublishedEmails } = this.props;
        const { limit, offset } = this.state;

        this.addHSEmailToList();
        getHSPublishedEmails(offset, limit);
    }

    componentDidUpdate(prevProps) {
        const { objects } = this.props;
        const _publishedEmails = getHash(prevProps.objects);
        const publishedEmails = getHash(objects);

        if (_publishedEmails !== publishedEmails) {
            this.addHSEmailToList();
        }
    }

    onImportSelectedEmails = () => {
        const { importSelectedEmails } = this.props;
        const { selectedEmailsMap } = this.state;

        importSelectedEmails(selectedEmailsMap);
    };

    onSelectEmail = (hsEmail) => {
        const hsEmailId = hsEmail.id;
        const { selectedEmailsMap } = this.state;
        const emailMapCopy = { ...selectedEmailsMap };
        const el = selectedEmailsMap[hsEmailId];

        if (!el) {
            emailMapCopy[hsEmailId] = hsEmail;
        } else if (el) {
            delete emailMapCopy[hsEmailId];
        }

        this.setState({ selectedEmailsMap: emailMapCopy });
    };

    onLoadMoreClickHandler = () => {
        const { limit } = this.state;
        const { offset, getHSPublishedEmails } = this.props;
        const nextOffset = (limit + offset);

        getHSPublishedEmails(nextOffset, limit);
        this.setState({
            loadingMoreElements: true,
            setAsEquals: false,
        });
    };

    addHSEmailToList = () => {
        const { objects } = this.props;
        const { hsEmailList, setAsEquals } = this.state;

        let _hsEmailList = null;

        if (setAsEquals) {
            _hsEmailList = hsEmailList;
        } else {
            _hsEmailList = [...hsEmailList, ...objects];
        }

        this.setState({
            loadingMoreElements: false,
            hsEmailList: _hsEmailList,
        });
    };

    updateEmailList = () => {
        const { limit } = this.state;
        const { offset, getHSPublishedEmails } = this.props;
        const nextLimit = parseInt((offset / limit).toString());

        getHSPublishedEmails(0, nextLimit);
        this.setState({
            loadingMoreElements: true,
            setAsEquals: true,
        });
    };

    hideAddMoreButton = () => {
        const { totalCount } = this.props;
        const { hsEmailList } = this.state;
        const emailListSize = hsEmailList.length;

        return (totalCount <= emailListSize);
    };

    isImportedEmail = (emailId) => {
        const { importedEmailList } = this.props;

        return (importedEmailList.indexOf(emailId.toString()) !== -1);
    };

    // RENDER MANAGER
    renderEmailTable = () => (
        <div className="_90vh-max-height">
            <div className="table-responsive">
                <table className="table table-striped table-hover">
                    <thead>
                        <tr className="no-text-wrap">
                            <th>Selected</th>
                            <th>Email ID</th>
                            <th>Published Date</th>
                            <th>Subject</th>
                            <th>Project Name</th>
                            <th>Campaign Name</th>
                            <th>Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderTableElements()}
                    </tbody>
                </table>
            </div>
        </div>
    );

    renderTableElements = () => {
        const { hsEmailList, selectedEmailsMap } = this.state;

        if (!hsEmailList || !hsEmailList.length) {
            return (
                <tr>
                    <td
                        colSpan={6}
                        style={{ textAlign: 'center' }}
                    >
                        No HubSpot emails available
                    </td>
                </tr>
            );
        }

        return hsEmailList
            .filter(item => !!item)
            .map(item => (
                <EmailTableElementComponent
                    key={getHash(item)}
                    hsEmail={item}
                    isImported={this.isImportedEmail(item.id)}
                    onSelectEmail={this.onSelectEmail}
                    selectedEmailsMap={selectedEmailsMap}
                />
            ));
    };

    render = () => {
        const { loadingMoreElements } = this.state;
        const { importAllEmails } = this.props;

        return (
            <div className="col-xs-12 margin-top-30px">
                <fieldset>
                    <legend className="col-xs-12">
                        <div className="pull-left">
                            <h1 className="heading-text">HubSpot Email Import</h1>
                        </div>
                        <div className="pull-right">
                            <button
                                className="btn btn-default capitalize-text"
                                onClick={this.updateEmailList}
                                type="button"
                            >
                                <Icon iconName="refresh" />
                                {' '}
                                Reload Email List
                            </button>

                            <button
                                className="btn btn-default capitalize-text"
                                onClick={importAllEmails}
                                type="button"
                            >
                                <Icon iconName="globe" />
                                {' '}
                                import all
                            </button>

                            <button
                                className="btn btn-default capitalize-text"
                                onClick={this.onImportSelectedEmails}
                                type="button"
                            >
                                <Icon iconName="check-square-o" />
                                {' '}
                                import selected
                            </button>
                        </div>
                    </legend>

                    <div className="col-xs-12">
                        {this.renderEmailTable()}
                    </div>

                    <HideComponent hide={this.hideAddMoreButton()}>
                        <div className="col-xs-12 margin-top-10px">
                            <div style={{ textAlign: 'center' }}>
                                <button
                                    className="btn btn-primary btn-large upper-case-text no-margin"
                                    onClick={this.onLoadMoreClickHandler}
                                    disabled={loadingMoreElements}
                                    ref={this.refLoadMoreButton}
                                    style={{ minWidth: 200, borderRadius: 5 }}
                                    type="button"
                                >
                                    <Icon
                                        iconName="plus"
                                        extraClasses={`${loadingMoreElements ? 'fa-spin fa-2x' : ''} white-text`}
                                    />
                                    {' '}
                                    <HideComponent hide={loadingMoreElements}>
                                        Load more
                                    </HideComponent>
                                </button>
                            </div>
                        </div>
                    </HideComponent>
                </fieldset>
            </div>
        );
    };
}

HubSpotEmailImportComponent.propTypes = {
    getHSPublishedEmails: PropTypes.func.isRequired,
    importAllEmails: PropTypes.func.isRequired,
    importSelectedEmails: PropTypes.func.isRequired,
    importedEmailList: PropTypes.array.isRequired,
    objects: PropTypes.array.isRequired,
    offset: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
};

HubSpotEmailImportComponent.defaultProps = {
    getHSPublishedEmails: () => null,
    importSelectedEmails: () => null,
    importedEmailList: [],
    objects: [],
    offset: 0,
    totalCount: 0,
};

const mapStateToProps = _state => ({ ..._state.hsEmails });
const mapDispatchToProps = _dispatch => ({ ...bindActionCreators(Actions, _dispatch) });

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(HubSpotEmailImportComponent);

export default connectedComponent;
