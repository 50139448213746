import PropTypes from 'prop-types'
import React from 'react';
import {Modal} from 'react-bootstrap';

const InvestmentVideoPlayer = (props) => {
    const {videoUrl, openVideo, closeVideo, title} = props;
    return (
        <Modal show={openVideo} onHide={closeVideo}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe className="embed-responsive-item"
                            src={videoUrl}>
                    </iframe>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-primary btn-lg pull-right"
                        onClick={closeVideo}>
                    Close
                </button>
            </Modal.Footer>
        </Modal>
    );
};

InvestmentVideoPlayer.propTypes = {
    closeVideo: PropTypes.func.isRequired,
    openVideo: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    videoUrl: PropTypes.string.isRequired
};

InvestmentVideoPlayer.defaultProps = {
    closeVideo: () => null,
    openVideo: false,
    title: '',
    videoUrl: ''
};

export default InvestmentVideoPlayer;
