import React from 'react';
import PropTypes from 'prop-types';

class DealCardDescriptionComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            charsLeft: props.maxCharCount
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value !== this.props.value) {
            this.setCharsLeft(this.props.value);
        }
    }

    setCharsLeft = (str) => {
        const {maxCharCount} = this.props;
        const result = (maxCharCount - str.length);

        this.setState({
            charsLeft: result,
        });
    };

    render = () => {
        const {maxCharCount, value} = this.props;
        const inputName = "dealCardDescription";

        return (
            <div className="form-group">
                <label htmlFor={inputName}>Deal Card Description * ({this.state.charsLeft} characters left)</label>
                <span id={`error_${inputName}`} className="text-danger"/>
                <div className='editor_container'
                     style={{
                         border: '1px solid #ccc',
                         padding: '10px',
                         maxHeight: '800px',
                         overflowY: 'scroll'
                     }}>
                    <textarea className="form-control"
                              id={inputName}
                              maxLength={maxCharCount}
                              onChange={this.props.onInputChange}
                              name={inputName}
                              value={value}
                              rows="5"/>
                </div>
            </div>
        );
    };
}

DealCardDescriptionComponent.propTypes = {
    maxCharCount: PropTypes.number.isRequired,
    onInputChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired
};

DealCardDescriptionComponent.defaultProps = {
    maxCharCount: 500,
    onInputChange: () => null,
    value: ''
};

export default DealCardDescriptionComponent;