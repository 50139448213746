import React, {Component} from 'react';
import {Collapse} from 'react-bootstrap';
import Icon from '../../IconComponent/IconComponent';


class MenuSection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: props.isOpen,
        }
    }



    render() {
        const {sectionTitle} = this.props;
        const icon = `chevron-${this.state.isOpen ? 'up' : 'down'}`;

        return (
            <section style={{width: "100%"}}>
                 {this.props.showIcon && <Icon iconName={icon} extraClasses="white-text pull-right" />}
                 {this.props.children}
                {/* <div className="list-group-item menuSidebar"
                     onClick={() => this.setState({isOpen: !this.state.isOpen})}
                     style={{color: "#fff", border: 'none', borderRadius: 0, backgroundColor: '#000'}}>
                    {sectionTitle}
                    {this.props.showIcon && <Icon iconName={icon} extraClasses="white-text pull-right" />}
                </div> */}
                {/* <Collapse in={this.state.isOpen}
                          unmountOnExit={true}
                          mountOnEnter={true}
                          appear={true}>
                    <div>
                        {this.props.children}
                    </div>
                </Collapse> */}
            </section>
        );
    };
}

export default MenuSection
