import * as Actions from '../actions/actions';
import appInitialState from '../../../../reducers/appInitialState';
import { updateRequestCounter } from '../../../../utils/General';

export default (state = appInitialState.hsEmails, action) => {
    const { minus, plus } = updateRequestCounter(state, 'HSEmailOnFlightReq');

    switch (action.type) {
    case Actions.HS_EMAIL_REQ_START:
        return {
            ...state,
            HSEmailOnFlightReq: plus,
        };
    case Actions.HS_EMAIL_REQ_END:
        return {
            ...state,
            HSEmailOnFlightReq: minus,
        };

    case Actions.HS_EMAIL_REQ_SUCCESS:
        return {
            ...state,
            ...action.data,
        };
    default:
        return { ...state };
    }
};
