import React, { Component } from 'react';
import swal from 'sweetalert';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RequestService from './api/RequestService';


class AddRole extends Component {
    constructor(props) {
        super(props);

        this.state = {
            Users: [],
            Roles: [],
            AllRoles: [],
            isLoaded: false,
            id: props.session.id,
            errorOnLoad: false
        };

        // console.log('props = ', props);

        this.getUserInfo = this.getUserInfo.bind(this);
        this.getAUsersRoles = this.getAUsersRoles.bind(this);
        this.getAllRoles = this.getAllRoles.bind(this);
        this.addRoletoUser=this.addRoletoUser.bind(this);
    }


	componentDidMount() {
		window.scrollTo(0, 0);
		this.getUserInfo();
		this.getAUsersRoles();
		this.getAllRoles();
	}


	async getUserInfo() {
		let { data } = await RequestService.get(`/api/getUserInfo/${this.props.location.state.RoleUserID}`);

		if (data) {
			this.setState({
				Users: data.rows[0]
			});
		}
		else {
			alert('Could not get user information.');
		}
	}


	async getAUsersRoles() {
		let { data } = await RequestService.get(`/api/getAUsersRoles/${this.props.location.state.RoleUserID}`);

		if (data) {
			this.setState({
				Roles: data
			});
		}
		else {
			alert('Could not get user roles.');
		}
	}


	async getAllRoles() {
		let { data } = await RequestService.get(`/api/allRoleTypes`);

		if (data) {
			let deduped = data.filter(function (UserID, i, arr) {
				return arr.indexOf(UserID) === i;
			});

			this.setState({
				AllRoles: deduped
			});
		}
		else {
			alert('Could not get all roles.');
		}
	}


    async addRoletoUser() {
        let formBody = [];
        formBody.push("role=" + encodeURIComponent(this.refs.role.value));
        formBody.push("AdminID=" + encodeURIComponent(this.state.id));
        formBody = formBody.join("&");

        let response = await fetch(`/api/addrole/${this.props.location.state.RoleUserID}/${this.state.id}/${this.props.session.marketing}`, {
            method:'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: formBody
		});

        let status = await response.json();

        if (status.code === 200) {
			swal("Role Added", "", "success")
				.then(() => {
					this.getAUsersRoles();
				});
		}
		else if (status.code === 406) {
			swal("Role Already Added", "", "error");
		}
		else if (status.code === 400) {
			swal("Error", "", "error");
		}
    }


    render() {
		return(
			<div className="container body">
				<div className="main_container">
					<div className="right_col" role="main" style={{minHeight: 3753}}>
						<div className>
							<div className="page-title">
								<div className="title_left">
									<h3>Assign Roles</h3>
								</div>
							</div>
							<div className="clearfix" />
							<div className="row">
								<div className="col-md-12 col-sm-12 col-xs-12">
									<div>
										<hr/>
										<h4>FirstName : {this.state.Users.FirstName}</h4>
										<hr/>
										<h4>LastName : {this.state.Users.LastName}</h4>
										<hr/>
										<h4>Email : {this.state.Users.Email}</h4>
										<hr/>
										<h4>Roles : {this.state.Roles.map((Role) =>
											<li key={Role.RoleID}>{Role.Role}</li>
										)}
										</h4>
										<hr/>
										<h4>Select new Role</h4>
										<select  className="col-xs-12 col-md-6 col-sm-6 form-control " ref="role" id="category" >
											{
												this.state.AllRoles.map((roles) => (
													<option key={roles.Role}>{roles.Role}</option>
												))
											}
										</select>
										<hr/>
										<button className="btn btn-primary" onClick={this.addRoletoUser} style={{marginTop:20}}> Add Role</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
    }
}


const { object, bool } = PropTypes;

AddRole.propTypes = {
    session: object.isRequired,
    authenticated: bool.isRequired,
    checked: bool.isRequired
};

const mapState = ({ session }) => ({
    session: session.user,
    checked: session.checked,
    authenticated: session.authenticated
});

export default withRouter(connect(mapState)(AddRole));