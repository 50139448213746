import React from 'react';

const _bgColor = {
  backgroundColor: '#FFFFFF',
};

export const ContainerComponent = (props) => {
  const classes = props.extraClasses || '';

  return (
    <div className={`container ${classes}`}
         style={_bgColor}
         ref={props.setRef}>
      {props.children}
    </div>
  );
};

export const ContainerFluidComponent = (props) => {
  const classes = props.extraClasses || '';

  return (
    <div className={`container-fluid ${classes}`}
         style={_bgColor}
         ref={props.setRef}>
      {props.children}
    </div>
  );
};
