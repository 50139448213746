import React, { useEffect, useState,Component } from "react";
import { FaList } from "react-icons/fa";
import { FaDollarSign } from "react-icons/fa";
import { FaBriefcase } from "react-icons/fa";
import "./InvestorDashboard.css";
import moment from "moment";
import { activeInvestorTab } from "./actions/actions";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";


class InvestmentDescription extends Component {
	constructor(props) {
		super(props);

		this.state = {
		};
	}

	handleFundsClick = () => {
        const {history} = this.props;
        history.push("/app/InvestmentOpportunities");;
    };
	render() {
	
		const {regInvestments,numberOfInvestments,totalReturns,wnVerified,ROI} = this.props;

	// const dispatch = useDispatch();
		// const gotoTransaction = () => {
		// 	dispatch(activeInvestorTab(2));
		// };
		// const gotoW9Form = () => {
		// 	dispatch(activeInvestorTab(5));
		// };
	const	 investmentProjects = numberOfInvestments.map(
			(pjt) => pjt.ProjectName
		);
		

		return (
			<div className="container">
			<div className="tile-wrap">
				{/* <div className="pull-left p-0">
						<h2 >Overview</h2>
						<p>
							As of {moment().format("MM/DD/YYYY h:mm A")}
						</p>
					</div>
					<div className="col-md-12">	
						{wnVerified && (
						<div className="my-5 text-center">
							<h3>
							You are required to fill out a W-9 before receiving payouts. <span onClick={gotoW9Form} className="text-underline cursor-pointer">Fill out now</span>
							</h3>
							
						</div>
					) }
					</div> */}
				<div className="col-md-12 w-100 p-3 py-4 d-md-flex p-md-5 pb-md-0 align-items-center">
					<div className="col-md-9 col-sm-9 col-xs-12 mb-sm-4">
						
					<ul className="card header d-flex p-0 mb-0">
							<li><p>Your funds</p></li>
							<li><p>Your total original investments</p></li>
							<li><p>Total Bitcoin owned</p></li>
						</ul>
						<ul className="card d-flex p-0 mt-0">
						
							<li>
								{investmentProjects?.map(fund => <h3>{fund}</h3>)}
							</li>

							<li>
					{regInvestments !== 'null' ? <h3>${regInvestments} </h3>
										: 0}
							</li>
							<li>
							
								</li>
							
						</ul>
					</div>
					<div className="col-md-3 col-sm-3 col-xs-12 mb-sm-4 text-center text-md-right"> 	
						<button className="btn btn-primary btn-green text-uppercase px-5 py-4"  onClick={this.handleFundsClick}>
							Add Funds
						</button>
					</div>
				</div>
			</div>
		</div>
		)
		
		}	
}



export default withRouter(InvestmentDescription);