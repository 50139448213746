import React, { Component } from 'react';
import swal from 'sweetalert';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import store from '../../index';
import { HTTP_SUCCESS } from '../../utils/ApplicationConstants';
import { getHash } from '../../utils/General';
import RequestService from '../../api/RequestService';
import Icon from '../shared/IconComponent/IconComponent';
import NotificationService from '../../utils/NotificationService';
import HideComponent from '../shared/HideComponent/HideComponent';
import Switch from "react-switch";
import CreateNewEntity from './CreateNewEntity';
import { getImpersonateID  } from "../../utils/General";


class MyBankAccounts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: props.session.id,
            SelectedData: [],
            Banks: [],
            BankData: [],
            FSstatus: false,
            isLoaded: false,
            errorOnLoad: false,
            selectedEntityId: 0,
            fundingSources:[],
            defaultBankChecked:true,
        };

        // this.onhandleChange = this.onhandleChange.bind(this);
        this.getInvestorDwollaBankAccounts = this.getInvestorDwollaBankAccounts.bind(this);
        this.removeFundingSource = this.removeFundingSource.bind(this);
        this.setDefault = this.setDefault.bind(this);
        this.goToPage = this.goToPage.bind(this);
    }



    componentDidMount() {
        window.scrollTo(0, 0);
        store.subscribe(() => {
            const currentState = store.getState();

            this.setState({
                id: currentState.session.user.id,
            });
        });

        this.getInvestorDwollaBankAccounts();
    }



    async getInvestorDwollaBankAccounts() {
        const impersonateID = await getImpersonateID();
        const { data } = await RequestService.get(`/api/getInvestorDwollaBankAccounts/${impersonateID}`);
        if (data.code === HTTP_SUCCESS) {
             const fundingSources = await  this.getInvestorFundingSource(data?.data);
            this.setState({
                Banks: data.data,
                fundingSources:fundingSources
            });
        }
    }

    async getInvestorFundingSource(entities) {
        const fundingSources =[];
               for (const entity of entities) {
                 const source = await this.performFundingSource(entity.EntityID);
                if(source.length > 0)  fundingSources.push({...source[0],EntityID:entity.EntityID,EntityName:entity.EntityName});
                    }     
                    return fundingSources;
                
         };
     
       
         async  performFundingSource(entityId) {
         
            try {
                const impersonateID = await getImpersonateID();
                     const { data: BankData } = await RequestService.post({
                         url: `/api/getInvestorFundingSourceData/${impersonateID}`,
                         data: {
                             entityId,
                         },
                     });
                 return BankData;
              } catch (e) {
                    
                  console.log(e)
                 }
            
         }

    async removeFundingSource(account) {
        const willDelete = await swal({
            title: 'Are you sure you want to remove this bank account?',
            icon: 'warning',
            buttons: ['Cancel', 'Yes'],
            dangerMode: true,
        });

        if (willDelete) {
            try {
                // const { selectedEntityId } = this.state;

                await RequestService.post({
                    url: `/api/removeInvestorFundingSource/${this.state.id}/${this.props.session.marketing}`,
                    data: {
                        fundingSourceId: account.fsId,
                        isDefault: account.isDefault,
                    },
                });

                NotificationService.success();
                this.getInvestorDwollaBankAccounts();
                // this.onhandleChange({ target: { value: selectedEntityId } });
            } catch (e) {
                console.log(e);
                NotificationService.error('Error Removing your Bank Account', 10000);
            }
        }
    };



    // async onhandleChange({ target }) {
    //     if (this.refs.fundingsource.value !== -1) {
    //         const entityId = target.value;

    //         try {
    //             const { data: BankData } = await RequestService.post({
    //                 url: `/api/getInvestorFundingSourceData`,
    //                 data: {
    //                     entityId,
    //                 },
    //             });

    //             const FSstatus = (BankData || BankData.length);

    //             this.setState({
    //                 FSstatus,
    //                 BankData,
    //                 selectedEntityId: entityId,
    //             });
    //         } catch (e) {
    //             this.setState({
    //                 BankData: [],
    //                 FSstatus: false,
    //             });
    //         }
    //     }
    // };



    async setDefault({ fsId,EntityID }) {
        const { selectedEntityId } = this.state;
        const url = `/api/investor/bank-account/${fsId}/default`;
        const notificationText = `
            For this entity, the default bank account is used as the main account
            to receive Distributions and Returns of Capital.
        `;

      try {
            const {value: setAsDefault} = await NotificationService.custom({
                title: 'Do you want this account to be the default?',
                text: notificationText,
                type: 'info',
                showCancelButton: true,
                confirmButtonColor: '#5bb462',
                cancelButtonColor: '#d6d6d6',
                confirmButtonText: 'Set as Default',
            });

            if (setAsDefault) {
                await RequestService.put({
                    url,
                    data: {
                        EntityID,
                    },
                });
                this.getInvestorDwollaBankAccounts();
                // this.onhandleChange({ target: { value: selectedEntityId } });
            }
        }
        catch (e) {
            console.log(e);
            NotificationService.error(
                'Error Setting the bank account as default',
                10000,
            );
        }
    
    };



	goToPage(page) {
		const { history } = this.props;

		history.push({
			pathname: page,
			state: {
				addMissingAccount: false,
			},
		});
	};



	// renderBankAccounts() {
    //     const { FSstatus, BankData } = this.state;

    //     if (!FSstatus) {
    //         return (<div>No Banks Found</div>);
    //     }

    //     const width150 = { width: 150 };

    //     return BankData.map(item => (
    //         <table className="table table-hover table-striped" key={getHash(item)}>
    //             <thead>
    //                 <tr>
    //                     <th
    //                         className="o usernavmenu"
    //                         style={{ color: 'white' }}
    //                         colSpan={2}
    //                     >
    //                     Bank Account Details
    //                     </th>
    //                 </tr>
    //             </thead>
    //             <tbody>
    //                 <tr>
    //                     <td className="text-right" style={width150}>Account Name:</td>
    //                     <td className="upper-case-text">{item.name}</td>
    //                 </tr>
    //                 <tr>
    //                     <td className="text-right" style={width150}>Bank Name:</td>
    //                     <td className="upper-case-text">{item.bankName}</td>
    //                 </tr>
    //                 <tr>
    //                     <td className="text-right" style={width150}>Account Number:</td>
    //                     <td className="upper-case-text">{item.AccountNumber}</td>
    //                 </tr>
    //                 <tr>
    //                     <td className="text-right" style={width150}>Account Type:</td>
    //                     <td className="capitalize-text">{item.bankAccountType}</td>
    //                 </tr>
    //                 <tr>
    //                     <td className="text-right" style={width150}>Verification Status:</td>
    //                     <td className="capitalize-text">{item.status}</td>
    //                 </tr>
    //                 <tr>
    //                     <td colSpan={2} style={{textAlign: 'left'}}>
	// 						<button
	// 							onClick={() => this.removeFundingSource(item)}
	// 							className="btn btn-danger upper-case-text"
	// 							ref="submitRemove"
	// 							type="button"
	// 						>
	// 							<Icon iconName="times" extraClasses="white-text" />
	// 							{' '}
	// 							Remove
	// 						</button>
	// 						<HideComponent hide={item.status === 'Unverified'}>
	// 							<button
	// 								className={`btn btn-${item.isDefault ? 'default' : 'primary'} upper-case-text`}
	// 								onClick={() => this.setDefault(item)}
	// 								disabled={item.status === 'Unverified'}
	// 								type="button"
	// 							>
	// 								<Icon iconName={item.isDefault ? 'bookmark' : 'check'} extraClasses={item.isDefault ? '' : 'white-text'} />
	// 								{' '}
	// 								{item.isDefault ? 'is' : 'Set as'}
	// 								{' '}
	// 								default
	// 							</button>
	// 						</HideComponent>
	// 						<HideComponent hide={item.status === 'Verified'}>
	// 							<button
	// 								className={`btn btn-primary upper-case-text`}
	// 								onClick={() => this.goToPage('/app/verifymicrodeposits')}
	// 								type="button"
	// 							>
	// 								<Icon iconName={'check'} extraClasses={'white-text'} />
	// 								{' Verify Micro Deposits'}
	// 							</button>
	// 						</HideComponent>
	// 					</td>
    //                 </tr>
    //             </tbody>
    //         </table>
    //     ));
    // };



    render() {
        const bankImg =	"/img/bank-img.png"
        const {fundingSources} = this.state;
        console.log('fundingSources fundingSources',fundingSources);
        return (
            <div className="container body">
                <div className="main_container manage-accounts">
                    <div className="right_col back" role="main">
                        <div style={{ background: 'white' }}>
                        <div className="col-xs-12 col-md-12">
						<h2 className="pull-left">Your Bank Accounts</h2>
                         <CreateNewEntity/>
                    	</div>
                        <div className="col-xs-12 col-md-12 ">
                    {fundingSources.length > 0? fundingSources.map((entity) => (
                    <div className='table-wrapper'>
                        <h3>{entity.EntityName}</h3>
                <table className="table">
	<tbody>
		<tr>
			<td rowspan="2" className="img-wrapper"><img src={bankImg} /></td>
			<td colspan="3"><h3>{('' + entity.name).slice(0, -4).replace(/./g, '*') + ('' + entity.name).slice(-4) }</h3></td>
			<td rowspan="2" className="text-center">
               <span><Switch onChange={()=> this.setDefault(entity)}  checked={entity?.isDefault} 	disabled={entity.status === 'Unverified'} />
               <h4 className="mb-2 mt-0 font-bold">Default Bank</h4></span> 
                <span className="cursor-pointer text-grey" onClick={() => this.removeFundingSource(entity)}>Remove Bank</span>
                <HideComponent hide={entity.status?.toLowerCase() === 'verified'}>
                    <button
                    className={`btn btn-primary upper-case-text`}
                    onClick={() => this.goToPage('/app/verifymicrodeposits')}
                    type="button"
                    >
                    <Icon iconName={'check'} extraClasses={'white-text'} />
                    {' Verify Micro Deposits'}
                    </button>
                </HideComponent>            </td>
		</tr>
		<tr>
        <td>Bank Name<span>{entity.bankName }</span>
                                
                            </td>
                            <td >Account Number<span>{entity.AccountNumber}</span>
                                
                            </td>
                            <td>Account Type<span>{entity.bankAccountType}</span>
                               </td>
		</tr>
	</tbody>
</table>

                    </div>
                )): <Link to="/app/setupprofile3" className='cursor-pointer font-bold'>
                Add New Bank Account
            </Link>}


    </div>

                            {/* <div className="col-md-12 col-xs-12 col-sm-12 ">
                                <div className="panel panel-primary" style={{ borderColor: '#5bb462' }}>
                                    <div className="panel-heading usernavmenu">
                                        <h3 className="panel-title">Manage Your Bank Accounts</h3>
                                    </div>
                                    <div className="panel-body">
                                        <div className="col-xs-12">
                                            <form>
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="form-group">
                                                        <label className="input-label">Select Your Entity *</label>
                                                        <select
                                                            className="form-control input"
                                                            onChange={this.onhandleChange}
                                                            name="state"
                                                            ref="fundingsource"
                                                            id="etype"
                                                        >
                                                            <option value={-1}>Select Entity</option>
                                                            {
                                                                this.state.Banks.map(data => (
                                                                    <option
                                                                        key={getHash(data)}
                                                                        value={data.EntityID}
																		style={{ color: data.IsDisabled ? 'grey' : 'black' }}
																		disabled={data.IsDisabled}
                                                                    >
                                                                        {data.EntityLabel}
                                                                    </option>
                                                                ))
                                                            }
                                                        </select>
                                                        <span id="error_etype" className="text-danger" />
                                                    </div>
                                                    <div />
                                                </div>
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="pull-right">
                                                        <button
                                                            onClick={() => this.goToPage('/app/setupprofile3')}
                                                            className="btn btn-default"
                                                            type="button"
                                                        >
                                                            <Icon iconName="plus" />
                                                            {' '}
                                                            Add New Bank Account
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                        <div className="col-xs-12">
                                            <div className="col-xs-12">
                                                {this.renderBankAccounts()}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const { object, bool } = PropTypes;

MyBankAccounts.propTypes = {
    session: object.isRequired,
    authenticated: bool.isRequired,
    checked: bool.isRequired,
};

const mapState = ({ session }) => ({
    session: session.user,
    checked: session.checked,
    authenticated: session.authenticated,
});

export default withRouter(connect(mapState)(MyBankAccounts));
