import React, { Component } from 'react';
import swal from 'sweetalert2';
import store from '../../index'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import getDate from '../../utils/ConvertDate'
import RequestService from '../../api/RequestService';

class Refund extends Component {
    constructor(props) {
        super(props);

        this.state = {
            User: [],
            Forgplp: [],
            id: props.session.id,
            num: "",
            RegDProjects: [],
            RegCFProjects: [],
            RegDinvestors: [],
            RegCFinvestors: [],
            show: false,
            show1: false,
            isLoaded:false,
            errorOnLoad:false
        };

        this.onIssueRefund=this.onIssueRefund.bind(this);

        this.onhandlechangeRegD=this.onhandlechangeRegD.bind(this);
        this.onhandlechangeRegCF=this.onhandlechangeRegCF.bind(this);
    }
    onAddEntity(){
        this.props.history.push("/app/setupprofile");
    }





	async onhandlechangeRegD() {
		await this.setState({
			ProjectID_RegD: this.refs.prod.value
		});

		if (this.refs.prod.value > 0) {
			this.getUserInvestmentsRegD();
		}
		else {
			await this.setState({
				RegDinvestors: [],
			});
		}
	}


	async onhandlechangeRegCF() {
		await this.setState({
			ProjectID_RegCF: this.refs.procf.value
		});

		if (this.refs.procf.value > 0) {
			this.getUserInvestmentsRegCF();
		}
		else {
			await this.setState({
				RegCFinvestors: []
			});
		}
	}


	async getUserInvestmentsRegD() {
        try {
            const { data } = await RequestService.get(`/api/getUserInvestments/regd/${this.state.ProjectID_RegD}`);
            
            if (data.code === 200) {
                this.setState({
                    RegDinvestors: data.data,
                    isLoaded: true,
                    err: {},
                    errorOnLoad: false,
                });
            }
            else {
                alert('Could not get list of User Investments.');
            }
        } catch (err) {
            console.log(err);
        }
    }


    async getUserInvestmentsRegCF() {
        try {
            const { data } = await RequestService.get(`/api/getUserInvestments/regcf/${this.state.ProjectID_RegCF}`);
            
            if (data.code === 200) {
                this.setState({
                    RegCFinvestors: data.data,
                    isLoaded: true,
                    err: {},
                    errorOnLoad: false,
                });
            }
            else {
                alert('Could not get list of User Investments.');
            }
        } catch (err) {
            console.log(err);
        }
    }



	async onIssueRefund(e, investmentID, UserID, invType) {
		e.preventDefault();

		this.refs['refundButton' + investmentID].setAttribute('disabled', 'disabled');
		this.refs['refundButton' + investmentID].innerHTML = 'Please Wait...';

		const {value: name} = await swal({
			title: 'Reason for refund',
			input: 'text',
			inputPlaceholder: 'Reason',
			showCancelButton: true,
			inputValidator: (value) => {
				return !value && 'You must have a reason!'
			}
		});

		if (name) {
			let formBody = [];

			formBody.push("InvestmentID=" + encodeURIComponent(investmentID));
			formBody.push("UserID=" + encodeURIComponent(UserID));
			formBody.push("Reason=" + encodeURIComponent(name));

            formBody = formBody.join("&");
            
            try {
                const { data } = await RequestService.post({
                    url: '/api/refundUserInvestment',
                    data: formBody,
                });
            
                if (data.code === 200) {
                    swal("Refund processed", "", "success")
                        .then((value) => {
                            if (invType === 'regd') {
                                this.getUserInvestmentsRegD();
                            }
                            else if (invType === 'regcf') {
                                this.getUserInvestmentsRegCF();
                            }
                        });
                }
                else {
                    swal('Refund processing failed.', '', 'info');
                }
            } catch (err) {
                console.log(err);
            }
		}
	}



	async getAllProjectInvestments() {
        try {
            const { data } = await RequestService.get('/api/getAllProjectInvestments');
            
            if (data.code === 200) {
                this.setState({
                    RegDProjects: data.reg_d,
                    RegCFProjects: data.reg_cf,
                    isLoaded: true,
                    err: {},
                    errorOnLoad: false,
                });
            }
            else {
                alert('Could not get list of Project Investments');
            }
        } catch (err) {
            console.log(err);
        }
	}


	componentDidMount(){
		window.scrollTo(0, 0);
		store.subscribe(() => {
			let currentState = store.getState();
			this.setState({
				id: currentState.session.user.id
			});
		});
		this.getAllProjectInvestments();
	}


    render() {
        return(
			<div className="container body">
				<div className="main_container">
					{/* page content */}
					<div className="right_col" role="main">
						<h4 className='up'>Refund investments</h4>
						<div className="col-md-12 col-sm-12 col-xs-12">
						</div>
						<div className="row">
							<div>
								<ul className="nav nav-pills nav-justified" style={{ padding: '0 10px', marginBottom: 20 }}>
									<li className="active"><a data-toggle="pill" href="#menu1">EnergyFunders Black (Reg D)</a></li>
									<li><a data-toggle="pill" href="#home">EnergyFunders Marketplace (Reg CF)</a></li>
								</ul>
								<div className="tab-content">
									<div id="home" className="tab-pane fade">
										<div className="col-md-12 col-sm-12 col-xs-12">
											<div className="x_panel">
												<div className="x_title">
													<div className="clearfix" />
													<label htmlFor="pjname">Select a Project</label>
													<select className="form-group" id="status" ref="procf" onChange={this.onhandlechangeRegCF} name="status">
														<option value={0}>Select a Regulation CF Project</option>
														{
															this.state.RegCFProjects.map((project) => (
																<option key={project.ProjectID} value={project.ProjectID}>{project.InvestmentName}</option>
															))
														}
													</select>
												</div>
												<div className="x_content">
													<div className="table-responsive">
														<table className="table table-striped jambo_table ">
															<thead className="thead-inverse">
															<tr className="headings">
																<th className="column-title">Investor Name</th>
																<th className="column-title">Investment Date</th>
																<th className="column-title">Investment Amount</th>
																<th className="column-title">Refund</th>
															</tr>
															</thead>
															{(() => {
																if (this.state.RegCFinvestors.length > 0) {
																	return (
																		<tbody>
																		{
																			this.state.RegCFinvestors.map((investment)=>(
																				<tr key={investment.UserinvestmentID} className="even pointer">
																					<td className=" ">{investment.FirstName+" "+investment.LastName}</td>
																					<td className=" ">{getDate.getDate(investment.InvestmentDate)}</td>
																					<td className=" ">${investment.InvestmentAmount}</td>
																					<td>
																						{(() => {
																							let sixtyDays = new Date().getTime() - (90 * 24 * 60 * 60 * 1000);
																							let timestamp = new Date(investment.InvestmentDate);

																							if (investment.Deleted > 0) {
																								return ('Deleted');
																							}
																							else if (investment.Refunded > 0) {
																								return ('Refunded');
																							}
																							else if (timestamp < sixtyDays) {
																								return ('Investment Final');
																							}
																							else {
																								return (<button id="submit" type="submit" ref={`refundButton${investment.UserInvestmentID}`} onClick={(e) => this.onIssueRefund(e, investment.UserInvestmentID, investment.UserID, 'regcf')} className="btn btn-primary center">Refund</button>);
																							}
																						})()}
																					</td>
																				</tr>
																			))
																		}
																		</tbody>
																	);
																}
																else {
																	return (
																		<tbody>
																		<tr  className="even pointer">
																			<td colSpan="5" className="text-center">No investments yet</td>
																		</tr>
																		</tbody>
																	);
																}
															})()}
														</table>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div id="menu1" className="tab-pane fade in active">
										<div className="col-md-12 col-sm-12 col-xs-12">
											<div className="x_panel">
												<div className="x_title">
													<div className="clearfix" />
													<label htmlFor="pjname">Select a Regulation D Project</label>
													<select className="form-group" id="status" ref="prod" onChange={this.onhandlechangeRegD} name="status">
														<option value={0}>Select a Project</option>
														{
															this.state.RegDProjects.map((project) => (
																<option key={project.ProjectID} value={project.ProjectID}>{project.ProjectName}</option>
															))
														}
													</select>
												</div>
												<div className="x_content">
													<div className="table-responsive">
														<table className="table table-striped jambo_table ">
															<thead className="thead-inverse">
															<tr className="headings">
																<th className="column-title">Investor Name</th>
																<th className="column-title">Investment Date</th>
																<th className="column-title">Investment Amount</th>
																<th className="column-title">Refund</th>
															</tr>
															</thead>
															{(() => {
																if (this.state.RegDinvestors.length > 0) {
																	return (
																		<tbody>
																		{
																			this.state.RegDinvestors.map((investment)=>(
																				<tr key={investment.UserinvestmentID} className="even pointer">
																					<td className=" ">{investment.FirstName+" "+investment.LastName}</td>
																					<td className=" ">{getDate.getDate(investment.InvestmentDate)}</td>
																					<td className=" ">${investment.InvestmentAmount}</td>
																					<td>
																						{(() => {
																							let sixtyDays = new Date().getTime() - (60 * 24 * 60 * 60 * 1000);
																							let twoYears = new Date().getTime() - (365 * 2 * 24 * 60 * 60 * 1000);
																							let timestamp = new Date(investment.InvestmentDate);

																							if (investment.Deleted > 0) {
																								return ('Deleted');
																							}
																							else if (investment.Refunded > 0) {
																								return ('Refunded');
																							}
																							else if (timestamp < twoYears) {
																								return ('Investment Final');
																							}
																							else {
																								return (<button id="submit" type="submit" ref={`refundButton${investment.UserInvestmentID}`} onClick={(e) => this.onIssueRefund(e, investment.UserInvestmentID, investment.UserID, 'regd')} className="btn btn-primary center">Refund</button>);
																							}
																						})()}
																					</td>
																				</tr>
																			))
																		}
																		</tbody>
																	);
																}
																else {
																	return (
																		<tbody>
																		<tr  className="even pointer">
																			<td colSpan="3" className="text-center">No investments yet</td>
																		</tr>
																		</tbody>
																	);
																}
															})()}
														</table>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
        );
    }
}


const { object, bool } = PropTypes;

Refund.propTypes = {
    session: object.isRequired,
    authenticated: bool.isRequired,
    checked: bool.isRequired
};

const mapState = ({ session }) => ({
    session: session.user,
    checked: session.checked,
    authenticated: session.authenticated
});

export default withRouter(connect(mapState)(Refund));
