import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
import {withRouter} from 'react-router-dom';
import RequestService from '../../../../api/RequestService';
// import HelloSignW9 from '../../../../utils/HelloSignW9';
import { getImpersonateID  } from "../../../../utils/General";
import swal from 'sweetalert2';
import { FiBell } from "react-icons/fi";

class UserNotifications extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: props.session.id,
            notificationCount: 0,
            EntityCountry: "",
            FundingSourceVerified: 0,
            EducationStatus: 0,
            DwollaCustomerVerified: 0,
            DwollaDocUploaded: -1,
            DwollaStatus: '',
            w8n9verify: 0,
            SignatureId: '',
            SignatureRequestId: '',
            DwollaCustomer: 0,
            EntityID: '',
            isLoaded: false,
            errorOnLoad: false,
            NotificationCount: 0,
            Microdeposits: 0,
            showVerifyModal: false,
            IsAccredited: 0
        };
    }



    // These fire only the first time the page is loaded, or on a force refresh (CTRL-R).
    async componentDidMount() {
        window.scrollTo(0, 0);
        this.getDetails();
        this.getEntity();
        this.getnotificationalerts();
        await this.checkDwollaStatus();
        await this.checkAccreditation();
        this.checkEducationStatus();
        this.checkFundingSource();
        this.checkMicroDepositStatus();

        if (this.state.DwollaStatus === '' && this.state.IsAccredited === 1) {
          //  this.setState({showVerifyModal: true});
            this.setState({showVerifyModal: false});
        }
    };



    // These fire on every page change, where the page is not the same.
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.setState({ NotificationCount: 0 });
            this.getEntity();
            this.getnotificationalerts();
            this.checkDwollaStatus();
            this.checkEducationStatus();
            this.checkFundingSource();
            this.checkMicroDepositStatus();
        }
    };



	getDetails() {
		fetch('/api/getusername/' + this.state.id + '/' + this.props.session.marketing, {
			method: 'get'
		})
			.then((response) => {
				return response.json()
			})
			.then((data) => {
				this.setState({
					FirstName: data[0].FirstName,
					LastName: data[0].LastName,
					isLoaded: true,
					err: {},
					errorOnLoad: false,
				});
			})
			.catch((err) => {
				this.setState({
					err: err,
					errorOnLoad: true
				})
			});
	};



	async getEntity() {
        
		try {
            const impersonateID = await getImpersonateID();
			const {data} = await RequestService.get(`/api/getAllInvestorEntities/${impersonateID}`);

			this.setState({
				EntityCountry: data[0].Country,
				EntityID: data[0].EntityID,
				w8n9verify: data[0].w8n9formVerify
			});
		}
		catch (err) {
			console.log(err);
		}
	};



	async getnotificationalerts() {
		try {
			const { data } = await RequestService.get('/api/getNotificationAlerts');

			if (data.code === 200) {
				let addCount = 0;

				if (data.rows[0].RegCFeducationStatus === 0) {
					addCount++;
				}

				// if (data.rows[0].w8n9formVerify === 0 && data.rows[0].DwollaVerificationStatus === 'verified') {
				//     addCount++;
				// }

				this.setState({
					notificationCount: this.state.notificationCount + addCount
				})
			}
		}
		catch (err) {
			console.log(err);
		}
	};



	async checkDwollaStatus() {
		try {
			const {data: status} = await RequestService.get('/api/getDwollaStatus');

			this.setState({
				DwollaStatus: status.data.dwollaStatus,
			});

			if (status.code === 200 && status.data.dwollaURL !== "" && status.data.dwollaStatus === 'verified') {
				this.setState({
					DwollaCustomerVerified: 1,
					NotificationCount: this.state.NotificationCount + 0,
					isLoaded: true,
					err: {},
					errorOnLoad: false,
				});
			}
			else if (status.code === 200 && status.data.dwollaURL !== "" && status.data.dwollaStatus === 'retry') {
				this.setState({
					DwollaCustomerVerified: 0,
					NotificationCount: this.state.NotificationCount + 1,
					isLoaded: true,
					err: {},
					errorOnLoad: false,
				});
			}
			else if (status.code === 200 && status.data.dwollaURL !== "" && status.data.dwollaStatus === 'document') {
				if (status.data.DocStatus === "") {
					this.setState({
						DwollaDocUploaded: 0,
						NotificationCount: this.state.NotificationCount + 1,
						isLoaded: true,
						err: {},
						errorOnLoad: false,
					});
				}
				else {   // document status == uploaded, or confirmed, or whatever...
					this.setState({
						DwollaDocUploaded: 1,
						NotificationCount: this.state.NotificationCount + 0,
						isLoaded: true,
						err: {},
						errorOnLoad: false,
					});
				}
			}
			else if (status.code === 200 && status.data.dwollaURL !== "" && status.data.dwollaStatus === 'suspended') {
				this.setState({
					DwollaCustomerVerified: 2,
					NotificationCount: this.state.NotificationCount + 0,
					isLoaded: true,
					err: {},
					errorOnLoad: false,
				});
			}
			else {
				this.setState({
					DwollaCustomerVerified: 0,
					NotificationCount: this.state.NotificationCount + 1,
					isLoaded: true,
					err: {},
					errorOnLoad: false
				});
			}
		}
		catch (err) {
			console.log(err);
		}
	};



	async checkEducationStatus() {
		try {
            const impersonateID = await getImpersonateID();

			const {data} = await RequestService.get(`/api/checkEducationStatus/${impersonateID}`);
			if (data.code === 200 && data.data === 1) {
				this.setState({
					EducationStatus: 1,
					isLoaded: true,
					err: {},
					errorOnLoad: false,
				});
			}
			else {
				this.setState({
					EducationStatus: 0,
					// NotificationCount: this.state.NotificationCount + 1,
					isLoaded: true,
					err: {},
					errorOnLoad: false,
				});
			}
		}
		catch (err) {
			console.log(err);
		}
	};



	async checkFundingSource() {
		try {
            const impersonateID = await getImpersonateID();

			const { data } = await RequestService.get(`/api/getFundingSourceStatus/${impersonateID}`);

			if (data.NeedFundingSource === 1 && this.state.DwollaCustomerVerified === 1) {
				this.setState({
					FundingSourceVerified: 0,
					NotificationCount: this.state.NotificationCount + 1,
					isLoaded: true,
					err: {},
					errorOnLoad: false,
				});
			}
			else {
				this.setState({
					FundingSourceVerified: 1,
					isLoaded: true,
					err: {},
					errorOnLoad: false,
				});
			}
		}
		catch (err) {
			console.log(err);
		}
	};



	async checkMicroDepositStatus() {
        const impersonateID = await getImpersonateID();

		const { data } = await RequestService.get(`/api/getMicroDepositStatus/${impersonateID}`);

		if (data && data.data > 0) {
			this.setState({
				Microdeposits: 1,
				NotificationCount: this.state.NotificationCount + 1,
			});
		}
		else {
			this.setState({
				Microdeposits: 0,
			});
		}
	};



	docUpload = () => {
        this.props.history.push("/app/UploadDwollaDocument");
    };



    onDwollaVerify = () => {
        this.props.history.push({
            pathname: '/app/VerifyDwolla',
            state: {
                currentDwollaStatus: this.state.DwollaStatus
            }
        });
    };



    closeVerifyModal = () => {
        this.setState({
            showVerifyModal: false
        });
    };



    testButton = async () => {
        let confirm = await swal({
            title: 'Please wait while we finalize the document.',

            allowEscapeKey: false,
            allowOutsideClick: false,

            showConfirmButton: true,

            // onBeforeOpen: () => {
            //     swal.showLoading()
            // },

            // timer: 3000
        });

        if (confirm) {
            window.location = window.location;
        }
        // setTimeout(() => swal.close(), 5000);
    };



    checkAccreditation = async () => {
        try {
            const impersonateID = await getImpersonateID();

            const {data} = await RequestService.get(`/api/checkAccreditation/${impersonateID}`);

			if (data.code === 200) {
				this.setState({
					IsAccredited: data.AccreditationStatus	// Not using WasAccredited
				});
			}
        }
        catch (err) {
            console.log(err);
        }
    };



    EduPage = () => {
        this.props.history.push({
            pathname: '/app/educationpage',
            state: {EntityID: this.state.EntityID}
        })
    };



    createFundingSource = () => {
        this.props.history.push({
            pathname: '/app/setupprofile3',
            state: {
                EntityID: this.state.EntityID,
                Source: 'notification',
                addMissingAccount: true,
            },
        });
    };



    VerifyMicroDeposits = () => {
        this.props.history.push({
            pathname: '/app/verifymicrodeposits',
        })
    };



    renderCompleteEducationPage = () => {
        // if (this.state.EducationStatus === 0) {
        //     return (
        //         <li onClick={this.EduPage}>
        //             <button className="btn-sm btn-default btn-dropdown full-width">
        //                 Complete Education Page
        //             </button>
        //         </li>
        //     );
        // }

        return null;
    };



    renderWFormMenuLink = () => {
        return null;

        // Not using this code currently.
        /*
        if (!this.state.w8n9verify && this.state.DwollaCustomerVerified === 1) {
            if (this.state.EntityCountry === 'United States') {
                return (
                    <li onClick={ async() => {
                        await HelloSignW9({call: 'signW9Personal', EntityID: this.state.EntityID});
                    } }>
                        <button className="btn-sm btn-default btn-dropdown full-width">Fill out W9 form</button>
                    </li>
                );
            }
            else {
                return (
                    <li onClick={ async() => {
                        await HelloSignW9({call: 'signW8', EntityID: this.state.EntityID});
                    } }>
                        <button className="btn-sm btn-default btn-dropdown full-width">Fill out W8 form</button>
                    </li>
                );
            }
        }

        return null;
        */
    };



    renderBecomeAverifiedInvestor = () => {
        if (this.state.DwollaCustomerVerified === 0 &&
            this.state.IsAccredited === 1 &&
            this.state.DwollaDocUploaded === -1) {

            let dwollaStatus = this.state.DwollaStatus;

            return (
                <li onClick={this.onDwollaVerify}>
                    <button className="btn-sm btn-default btn-dropdown full-width">
                        { dwollaStatus === 'retry' ? 'Retry Investor Verification' : 'Become a Verified Investor' }
                    </button>
                </li>
            );
        }

        return null;
    };



    renderAddBankAccount = () => {
        if (this.state.DwollaCustomerVerified === 1 &&
            this.state.FundingSourceVerified !== 1) {
            return (
                <li onClick={this.createFundingSource}>
                    <button className="btn-sm btn-default btn-dropdown full-width">Add Bank Account</button>
                </li>
            );
        }

        return null;
    };



    renderUploadDocumentVerification = () => {
        if (this.state.DwollaDocUploaded === 0) {
            return (
                <li onClick={this.docUpload}>
                    <button className="btn-sm btn-default btn-dropdown full-width">Upload Document Verification</button>
                </li>
            );
        }

        return null;
    };



    renderVerifyMicroDeposits = () => {
        if (this.state.Microdeposits === 1) {
            return (
                <li onClick={this.VerifyMicroDeposits}>
                    <button className="btn-sm btn-default btn-dropdown full-width">Verify Micro Deposits</button>
                </li>
            );
        }

        return null;
    };



    renderNotificationCount = () => {
        if (this.state.NotificationCount !== 0) {
            return (
                <span className="white-text notification">
                     {(this.state.NotificationCount) ? this.state.NotificationCount : ''}
                </span>
            );
        }

        return null;
    };

    emptyNotifications =() =>{
  
if(this.state.Microdeposits !== 1 && this.state.DwollaDocUploaded !== 0 && !(this.state.DwollaCustomerVerified === 1 &&
    this.state.FundingSourceVerified !== 1) && 
!(this.state.DwollaCustomerVerified === 0 &&
            this.state.IsAccredited === 1 &&
            this.state.DwollaDocUploaded === -1)){

        return (	<li className="text-grey p-2">
          Currently, there are no notifications.
        </li>)
      
    }

    }

    render() {
        return (
            <span className="white-text pull-right">
                <span className="dropdown">
                    <span className="btn-link dropdown-toggle"
                          data-toggle="dropdown"
                          style={{border: 'none', margin: 0, padding: 0}}>
                        <span>
                            {this.renderNotificationCount()}
                           <FiBell size ={22} color="#738189" />
                        </span>
                    </span>
                    <ul className="dropdown-menu pull-right">
                        {this.renderCompleteEducationPage()}
                        {this.renderWFormMenuLink()}
                        {this.renderBecomeAverifiedInvestor()}
                        {this.renderAddBankAccount()}
                        {this.renderUploadDocumentVerification()}
                        {this.renderVerifyMicroDeposits()}
                        {this.emptyNotifications()}
                        {/* <li onClick={this.testButton}>
                    <a className="btn-sm btn-default btn-dropdown">TEST BUTTON</a>
                    </li> */}
                    </ul>
                </span>

                <Modal show={this.state.showVerifyModal} onHide={this.closeVerifyModal}
                       style={{'width': '90%', 'margin': '0 auto'}}>
                    <Modal.Header closeButton>
                        <Modal.Title>Welcome to EnergyFunders</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div>
                                <p>Before you can invest, you need to complete the following steps:</p>
                                <br/>
                                <div className="col-md-2 nopadding imghide" style={{paddingLeft: 0}}>

                                </div>
                                <div className="col-md-4" style={{padding: 20, textAlign: 'center'}}>
                                    <p>
                                        <a href="javascript:" onClick={() => {
                                            this.closeVerifyModal();
                                            this.props.history.push({pathname: '/app/VerifyDwolla'});
                                        }}>
                                            <span className="badge" style={{fontSize: 40}}>1</span>
                                        </a>
                                    </p>
                                    <p style={{fontSize: 24}}>
                                        <a href="javascript:" onClick={() => {
                                            this.closeVerifyModal();
                                            this.props.history.push({pathname: '/app/VerifyDwolla'});
                                        }}>
                                            Verify Identity
                                        </a>
                                    </p>
                                    <p style={{textAlign: 'justify'}}>
                                        This information helps us satisfy "Know Your Customer" (KYC) requirements.
                                    </p>
                                </div>
                                <div className="col-md-4" style={{padding: 20, textAlign: 'center'}}>
                                    <p>
                                        <a href="javascript:" onClick={() => {
                                            this.closeVerifyModal();
                                            this.props.history.push({pathname: '/app/VerifyDwolla'});
                                        }}>
                                            <span className="badge" style={{fontSize: 40}}>2</span>
                                        </a>
                                    </p>
                                    <p style={{fontSize: 24}}>
                                        <a href="javascript:" onClick={() => {
                                            this.closeVerifyModal();
                                            this.props.history.push({pathname: '/app/VerifyDwolla'});
                                        }}>
                                            Link Bank Account
                                        </a>
                                    </p>
                                    <p style={{textAlign: 'justify'}}>
                                        Linking your bank account allows you to use ACH to fund your investments and
                                        receive any distributions on the EnergyFunders platform.
                                    </p>
                                </div>
                                <div className="col-md-2 nopadding imghide" style={{paddingLeft: 0}}>

                                </div>
                            </div>
                            <hr/>
                            <div style={{textAlign: 'center'}}>
                                <input type="submit"
                                       className="btn btn-info"
                                       defaultValue="Set Up My Account"
                                       value="Set Up My Account"
                                       style={{width: 300, backgroundColor: "#5bb462"}}
                                       onClick={() => {
                                           this.closeVerifyModal();
                                           this.props.history.push({pathname: '/app/VerifyDwolla'});
                                       }}/>
                                <br/><br/>
                                <a href="javascript:"
                                   onClick={() => this.closeVerifyModal()}>
                                    Finish Later
                                </a>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </span>
        );
    }
}


const {object, bool} = PropTypes;

UserNotifications.propTypes = {
    session: object.isRequired,
    authenticated: bool.isRequired,
    checked: bool.isRequired
};

const mapState = ({session}) => ({
    session: session.user,
    checked: session.checked,
    authenticated: session.authenticated
});

export default withRouter(connect(mapState)(UserNotifications));
