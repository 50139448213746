
/*
 * This Component is called from /src/components/shared/ShowProductionData.js
 * ONLY if state passed and user is verified SuperAdmin.
 */

import React, { useEffect, useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import swal from 'sweetalert2';

import HideComponent from '../shared/HideComponent/HideComponent';
import RequestService from '../../api/RequestService';
import { round2 } from '../../utils/General';
import DateInputComponent from '../shared/InputComponents/DateInputComponent/DateInputComponent';
import IntInputComponent from '../shared/InputComponents/IntInputComponent/IntInputComponent';



function AddProductionData(props) {
	const [ProjectID] = useState(props.projectID || 0);

	const [rowData, setRowData] = useState({});
	const [projectInfo, setProjectInfo] = useState({});
	const [dataUpdated, setDataUpdated] = useState(false);

	const formRef = useRef(null);



	useEffect(() => {
		getProjectInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);



	useEffect(() => {
		// This hook is to force a re-render on New Row Submitted.
		if (typeof props.getProductionData === 'function') {
			props.getProductionData();
		}

		setDataUpdated(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataUpdated]);




	async function getProjectInfo() {
		const { data } = await RequestService.get({
			url: `/api/project/details/${ProjectID}`
		});

		if (data.code === 200) {
			setProjectInfo(data.projectInfo)
		}
		else {
			alert('Could not get project details. Please try again.');
		}
	}



	function getDataFromInputs() {
		const GasMCF = formRef.current['GasMCF'].value.trim();
		const OilBarrels = formRef.current['OilBarrels'].value.trim();
		const FundTax = formRef.current['FundTax'].value.trim();
		const GasPrice = formRef.current['GasPrice'].value.trim();
		const OilPrice = formRef.current['OilPrice'].value.trim();
		const MonthYear = formRef.current['MonthYear'].value;
		const TotalOpEx = formRef.current['TotalOpEx'].value.trim();
		const FundEscrow = formRef.current['FundEscrow'].value.trim();

		const TotalPayout = formRef.current['TotalPayout'].value.trim();

		return { GasMCF, OilBarrels, FundTax, GasPrice, OilPrice, MonthYear, TotalOpEx, FundEscrow, TotalPayout };
	}



	function onInputChange(e) {
		const { name, value } = e.target;

		const { GasMCF, OilBarrels, FundTax, GasPrice, OilPrice, TotalOpEx, FundEscrow } = getDataFromInputs();
		let netRevenue;

		if (GasMCF && OilBarrels && FundTax && GasPrice && OilPrice && TotalOpEx) {
			let grossGasRev = GasMCF * GasPrice;
			let grossOilRev = OilBarrels * OilPrice;

			let netGasRev = grossGasRev * (1 - (projectInfo.GasTaxes / 100)) * (projectInfo.NetRevenueInterestBPO / 100);
			let netOilRev = grossOilRev * (1 - (projectInfo.OilTaxes / 100)) * (projectInfo.NetRevenueInterestBPO / 100);
			let netOpExCost = (grossGasRev + grossOilRev === 0 ? 0 : ((projectInfo.WorkingInterest / 100) * TotalOpEx));

			netRevenue = round2(netGasRev + netOilRev - netOpExCost - FundEscrow - FundTax);
			// netManagerRevenue = round2(netRevenue * (projectInfo.ManagerProfitShareBPO / 100));

			setRowData({ ...rowData, [name]: value, TotalPayout: netRevenue });	// We calculate the netRevenue as the TotalPayout.
		}
		else {
			setRowData({ ...rowData, [name]: value});
		}

		if (props.from === 'RecordEarnings') {
			props.onInputChange(e);
			props.setTotalPayout(netRevenue);
		}
	}



	async function saveRowData(e) {
		e.preventDefault();

		const { GasMCF, OilBarrels, FundTax, GasPrice, OilPrice, MonthYear, TotalOpEx, FundEscrow, TotalPayout } = getDataFromInputs();

		if (GasMCF !== '' && OilBarrels !== '' && FundTax !== '' && GasPrice !== '' && OilPrice !== ''
			&& TotalOpEx !== '' && FundEscrow !== '' && MonthYear && TotalPayout) {
			let formBody = [];
			formBody.push(`MonthYear=${encodeURIComponent(MonthYear)}`);
			formBody.push(`TotalPayout=${encodeURIComponent(TotalPayout)}`);
			formBody.push(`FundEscrow=${encodeURIComponent(FundEscrow)}`);
			formBody.push(`GasMCF=${encodeURIComponent(GasMCF)}`);
			formBody.push(`OilBarrels=${encodeURIComponent(OilBarrels)}`);
			formBody.push(`FundTax=${encodeURIComponent(FundTax)}`);
			formBody.push(`GasPrice=${encodeURIComponent(GasPrice)}`);
			formBody.push(`OilPrice=${encodeURIComponent(OilPrice)}`);
			formBody.push(`TotalOpEx=${encodeURIComponent(TotalOpEx)}`);
			formBody = formBody.join('&');

			const { data } = await RequestService.post({
				url: `/api/project/addProductionData/${ProjectID}`,
				data: formBody
			});

			if (data.code === 200) {
				swal({
					title: 'Data Saved',
					type: 'success',
				});

				setDataUpdated(true);
				setRowData({});
			}
			else {
				swal({
					title: 'There was an error',
					text: 'Data did not save.',
					type: 'warning',
				});
			}
		}
		else {
			swal({
				title: 'Please complete all fields',
				type: 'warning',
			})
		}
	}



	return (
		<div>
			<div className="container body">
				<div className="main_container">
					{/* page content */}
					<div className="right_col" role="main">
						<div className="clearfix" />
						<div className="row">
							<div className="col-md-12 col-sm-12 col-xs-12">
								<div className="x_panel">
									<div className="x_title">
										{/*<h4 className='up'>Project Data</h4>*/}
										<div className="clearfix"/>
									</div>
									<div className="x_content">
										<div>
											<h4>Enter New Row:</h4>
											<form autoComplete="off" ref={formRef}>
												<div className="clearfix">
													<div className="col-xs-6 col-md-3">
														<IntInputComponent
															inputName="GasMCF"
															inputTitle="Gas MCF Vol"
															elementValue={rowData.GasMCF}
															onInputUpdate={onInputChange}
														/>
													</div>
													<div className="col-xs-6 col-md-3">
														<IntInputComponent
															inputName="OilBarrels"
															inputTitle="Oil Vol"
															elementValue={rowData.OilBarrels}
															onInputUpdate={onInputChange}
														/>
													</div>
													<div className="col-xs-6 col-md-3">
														<IntInputComponent
															inputName="GasPrice"
															inputTitle="Gas Price"
															elementValue={rowData.GasPrice}
															onInputUpdate={onInputChange}
														/>
													</div>
													<div className="col-xs-6 col-md-3">
														<IntInputComponent
															inputName="OilPrice"
															inputTitle="Oil Price"
															elementValue={rowData.OilPrice}
															onInputUpdate={onInputChange}
														/>
													</div>
												</div>

												<div className="clearfix">
													<div className="col-xs-6 col-md-3">
														<DateInputComponent
															inputName="MonthYear"
															inputTitle="Month Year"
															dateType="date"
															elementValue={rowData.MonthYear}
															onInputChange={onInputChange}
														/>
													</div>
													<div className="col-xs-6 col-md-3">
														<IntInputComponent
															inputName="TotalOpEx"
															inputTitle="Total Op Ex"
															elementValue={rowData.TotalOpEx}
															onInputUpdate={onInputChange}
														/>
													</div>
													<div className="col-xs-6 col-md-3">
														<IntInputComponent
															inputName="FundTax"
															inputTitle="Fund Tax"
															elementValue={rowData.FundTax}
															onInputUpdate={onInputChange}
														/>
													</div>
													<div className="col-xs-6 col-md-3">
														<IntInputComponent
															inputName="FundEscrow"
															inputTitle="Fund Escrow"
															elementValue={rowData.FundEscrow}
															onInputUpdate={onInputChange}
														/>
													</div>
												</div>

												<div className="clearfix">
													<div className="col-xs-6 col-md-6">
														<IntInputComponent
															inputName="TotalPayout"
															inputTitle="Total Payout"
															inputSubtitle="(calculated)"
															isDisabled={true}
															elementValue={rowData.TotalPayout}
															onInputUpdate={onInputChange}
														/>
													</div>
												</div>

												<HideComponent hide={props.from === 'RecordEarnings'}>
													<div className="clearfix">
														<div className="col-xs-6 col-md-4">
															<button
																className="btn btn-success margin-top-10px"
																type="button"
																onClick={saveRowData}
															>
																Save Data
															</button>
														</div>
													</div>
												</HideComponent>
											</form>
										</div>

										<br />
										<br />
										<br />

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}



const mapState = ({session}) => ({
	session: session.user,
	checked: session.checked,
	authenticated: session.authenticated
});

export default withRouter(connect(mapState)(AddProductionData));
