import {
    PROJECT_UPDATE_REQUEST_END,
    PROJECT_UPDATE_REQUEST_START,
    REQUEST_ALL_REG_D_UPDATES_SUCCESS,
} from "./ProjectUpdateConstants";
import axios from "axios";
import {HTTP_ERR_BAD_REQUEST, HTTP_SUCCESS} from "../../../../utils/ApplicationConstants";
import NotificationService from "../../../../utils/NotificationService";

// action creators
export function startRegDHttpRequest() {
    return {type: PROJECT_UPDATE_REQUEST_START};
}

export function endRegDHttpRequest() {
    return {type: PROJECT_UPDATE_REQUEST_END};
}

export function getAllRegDUpdatesSuccess(data) {
    return {type: REQUEST_ALL_REG_D_UPDATES_SUCCESS, data};
}

// request creators
export function fetchAllRegDUpdates(userData) {
    return async (dispatch) => {
        dispatch(startRegDHttpRequest());
        const response = await axios.get(`/api/getnotifications/${userData.id}/${userData.marketing}`);
        const {data} = response;

        if (data.code >= HTTP_ERR_BAD_REQUEST) {
            const errorMessage = "Error in Reg D project update request";
            NotificationService.error(errorMessage);
            dispatch(endRegDHttpRequest(errorMessage));
            return;
        }

        if (data.code !== HTTP_SUCCESS) return;

        dispatch(getAllRegDUpdatesSuccess(data.data))
    }
}

export function storeNewProjectUpdateRegD(userData, reqPayload, redirect) {
    return async (dispatch) => {
        const URL = `/api/projectnotification/${userData.id}/${userData.marketing}`;
        const response = await axios.post(URL, reqPayload);
        const {data} = response;

        if (data.code >= HTTP_ERR_BAD_REQUEST) {
            const errorMessage = "Error in Reg D project creation request";
            NotificationService.error(errorMessage);
            dispatch(endRegDHttpRequest(errorMessage));
            return;
        }

        dispatch(endRegDHttpRequest());
        NotificationService.success("New Project Update created Successfully", 5000);
        redirect();
    }
}

