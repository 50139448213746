import axios from 'axios'


const getCookie = (name) => {
    const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
};

const getSession = () => {
    try {
        let userData = getCookie('USER_DATA') || '{}';
        userData = decodeURIComponent(userData);
        return JSON.parse(userData);
    } catch (e) {
        return {};
    }
};

const getHeaders = (headers) => {
    return {
       'Authorization': JSON.stringify(getSession()),
       ...headers
    };
};

const parseConfig = (_config) => {
    return typeof _config === 'object' ? _config : { url: _config }
};


/**
 * This is the main file for requests configuration and execution.
 * Each method () has a corresponding function
 */
class RequestService {
    constructor() {
        // todo: add security configuration to the application
    }

    static get(_config, otherConfig = {}) {
        const {url, params, headers} = parseConfig(_config);
        const options = {
            params,
            headers: {
                ...getHeaders(headers),
            },
            ...otherConfig,
        };

        return axios.get(url, options);
    }

    static post(_config) {
        const {url, params, headers, body, data, credential} = parseConfig(_config);
        const options = {
            params,
            ...credential,
            headers: {
                ...getHeaders(headers),
            },
        };

        return axios.post(url, body || data, options);
    }

    static put(_config) {
        const {url, params, headers, body, data, credential} = parseConfig(_config);
        const options = {
            params,
            ...credential,
            headers: {
                ...getHeaders(headers),
            },
        };

        return axios.put(url, body || data, options);
    }

	static patch(_config) {
		const {url, params, headers, body, data, credential} = parseConfig(_config);
		const options = {
			params,
			...credential,
			headers: {
				...getHeaders(headers),
			},
		};

		return axios.patch(url, body || data, options);
	}

    static delete(_config) {
        const {url, params, headers} = parseConfig(_config);
        const options = {
            params,
            headers: {
                ...getHeaders(headers),
            },
        };

        return axios.delete(url, options);
    }
}

export default RequestService;
