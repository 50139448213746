import React, { Component } from 'react';
import swal from 'sweetalert2';
import {
    Link
} from 'react-router-dom'
import { withRouter } from 'react-router-dom';
import store from './index'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import getDate from './utils/ConvertDate'

class Notifications extends Component {
    constructor(props){
        super(props);
        this.state={
            id:props.session.id,
            Notifications:[]
        };
        this.getNotifications=this.getNotifications.bind(this);
        this.onSubmit=this.onSubmit.bind(this);
    };

    onSubmit(id){
      this.props.history.push({
        pathname: '/app/AdminaddProject_D',
        state: { ProjectID: id }
      })
    }

    getNotifications(){
          fetch('/api/getnotifications'+'/'+this.state.id+'/'+this.props.session.marketing,{
            method:'get',
            headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
            },
        })
          .then((response)=>{
            return response.json()
          })
          .then((data)=>{
              if(data.code===200){
                this.setState({
                    Notifications:data.rows,
                    isLoaded:true,
                    err:{},
                    errorOnLoad:false,
                });
              }
              else{
                alert("Error getting notifications");
              }
          })
          .catch((err)=>{
              alert("Error getting notifications" + JSON.stringify(err))
          })
        }
      async componentWillMount(){
        window.scrollTo(0, 0);
        await this.getNotifications();
      }


  render() {
        return(
          <div className="container body">
          <div className="main_container">
        <div className="right_col" role="main" style={{minHeight: 3753}}>
          <div className>
            <div className="page-title">
              <div className="title_left">
                <h3>My Saved Projects</h3>
              </div>
            </div>
            <div className="clearfix" />
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="x_panel">
                  <div className="x_title">
                    <div className="clearfix"/>
                  </div>
                  <div className="x_content">
                      <div className="row">
                        <div className="col-sm-12">
                          <table className="table table-striped table-bordered dataTable no-footer">
                            <thead className="thead-inverse">
                              <tr className="headings">
                                <th className="column-title">Project Name</th>
                                <th className="column-title">Company Name</th>
                                <th className="column-title">Created Date </th>
                                <th className="column-title no-link last"><span className="nobr"></span>
                                </th>
                                <th className="bulk-actions" colSpan={7}>
                                  <a  className="antoo" style={{color: '#fff', fontWeight: 500}}>Bulk Actions ( <span className="action-cnt"> </span> ) <i className="fa fa-chevron-down" /></a>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.Notifications.map((project)=>(
                                    <tr key={project.ProjectID} className="even pointer">
                                      <td>{project.Subject}</td>
                                      <td>{project.NotificationSmallText}</td>
                                      <td>{project.NotificationText}</td>
                                       <td>{getDate.getDate(project.CreatedDate)}</td>
                                     <td> <button id="submit" type="submit" onClick={()=>this.onSubmit(project.ProjectID)} className="btn btn-primary center">Approve</button></td>
                                    </tr>
                                ))
                            }
                            </tbody>
                          </table>
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
             </div>
             </div>

        )
      }
    }


    const { object, bool } = PropTypes;

    Notifications.propTypes = {
    session: object.isRequired,
    authenticated: bool.isRequired,
    checked: bool.isRequired
};

const mapState = ({ session }) => ({
    session: session.user,
    checked: session.checked,
    authenticated: session.authenticated
});

export default withRouter(connect(mapState)(Notifications));
