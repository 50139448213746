import {
    PROJECT_UPDATE_REQUEST_END,
    PROJECT_UPDATE_REQUEST_START,
    REQUEST_ALL_REG_CF_UPDATES_SUCCESS
} from "./ProjectUpdateConstants";
import axios from "axios";
import {HTTP_ERR_BAD_REQUEST, HTTP_SUCCESS} from "../../../../utils/ApplicationConstants";
import NotificationService from "../../../../utils/NotificationService";


// action creators
export function startRegCFHttpRequest() {
    return {type: PROJECT_UPDATE_REQUEST_START};
}

export function endRegCFHttpRequest() {
    return {type: PROJECT_UPDATE_REQUEST_END};
}

export function getAllRegCFUpdatesSuccess(data) {
    return {type: REQUEST_ALL_REG_CF_UPDATES_SUCCESS, data};
}

// request creators
export function fetchAllRegCFUpdates(userData) {
    return async (dispatch) => {
        dispatch(startRegCFHttpRequest());
        // TODO: REVIEW THE CONTROLLER FOR THIS ENDPOINT BECAUSE IT IS FAILING AND IT SHOULDN'T
        const response = await axios.get(`/api/getnotificationscf/${userData.id}/${userData.marketing}`);
        const {data} = response;

        if (data.code >= HTTP_ERR_BAD_REQUEST) {
            const errorMessage = "Error in Reg CF project update request";
            NotificationService.error(errorMessage);
            dispatch(endRegCFHttpRequest(errorMessage));
            return;
        }

        const projectList = data.data;
        if (data.code !== HTTP_SUCCESS || !Array.isArray(projectList)) return;

        dispatch(getAllRegCFUpdatesSuccess(projectList));
    }
}

export function storeNewProjectUpdateRegCF(userData, reqPayload, redirect) {
    return async (dispatch) => {
        const URL = `/api/projectnotificationCF/${userData.id}/${userData.marketing}`;
        const response = await axios.post(URL, reqPayload);
        const {data} = response;

        if (data.code >= HTTP_ERR_BAD_REQUEST) {
            const errorMessage = "Error in Reg D project creation request";
            NotificationService.error(errorMessage);
            dispatch(endRegCFHttpRequest(errorMessage));
            return;
        }

        dispatch(endRegCFHttpRequest());
        NotificationService.success("New Project Update created Successfully", 5000);
        redirect();
    }
}
