import PropTypes from 'prop-types'
import React from 'react'
import { Modal } from 'react-bootstrap'
import { getUUID, formatMoney } from "../../../../utils/General";
import { PERFORM_RETURN_OF_CAPITAL, PROJECT_REG_D } from "../../../../utils/ApplicationConstants";
import { CSVLink } from "react-csv";


class RoCViewerModal extends React.Component {
    renderReturnList = () => {
        const {returnList} = this.props;

        if (!returnList || returnList.length === 0)
            return null;

        return returnList.map((roc, index) => (
            <tr className="capitalize-text"
                key={getUUID()}>
                <td>{index + 1}</td>
                <td>{roc.userId}</td>
                <td>{roc.firstName}</td>
                <td>{roc.lastName}</td>
                <td>{roc.entity}</td>
                <td>{roc.email}</td>
                <td>${formatMoney(roc.returnAmount)}</td>
                <td>{roc.paymentMethod}</td>
                <td>{roc.returnStatus === 0 ? 'unpaid' : 'paid'}</td>
            </tr>
        ));
    };



    renderTableElement() {
        return <div className="table-responsive">
            <table className="table table-striped table-hover">
                <thead>
                <tr className="capitalize-text">
                    <th/>
                    <th>User ID</th>
                    <th>first name</th>
                    <th>last name</th>
                    <th>entity</th>
                    <th>email</th>
                    <th>return amount</th>
                    <th>payment method</th>
                    <th>paid</th>
                </tr>
                </thead>
                <tbody>
                {this.renderReturnList()}
                </tbody>
            </table>
        </div>;
    }



    renderEmptyListMessage = () => {
        return (
            <div style={{
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center',
            }}>
                <h3>There are no investments for this project, or there was an error.  Contact Technical Support!</h3>
            </div>
        );
    };



    render() {
        const {
            open, closeModal, projectName, entityName, returnId,
            performReturnOfCapital, projectType, returnList, actionType, internalNotes
        } = this.props;

        const isReturnListEmpty = (!returnList || returnList.length === 0);
        const canPerformDistribution = (actionType === PERFORM_RETURN_OF_CAPITAL);
        const fileName = `${entityName}-${projectName}-ROC.csv`;

        return (
            <Modal onHide={closeModal}
                   className="no-padding"
                   show={open}>
                <Modal.Header closeButton>
                    <Modal.Title>{entityName !== '-' ? `${entityName} | ` : null}{projectName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

					<div className="table-responsive" style={{overflowX: 'visible'}}>
						<table
							className="table table-striped jambo_table bulk_action text-center">
							<thead>
							<tr>
								<th className="text-center">Return of Capital Notes</th>
							</tr>
							</thead>
							<tbody>
							<tr>
								<td>{internalNotes}</td>
							</tr>
							</tbody>
						</table>
					</div>

                    {!isReturnListEmpty &&
                    this.renderTableElement()}

                    {isReturnListEmpty &&
                    this.renderEmptyListMessage()}

                    {!isReturnListEmpty &&
                    !canPerformDistribution &&
                    <div className="row">
                        <CSVLink data={returnList} filename={fileName}>
                            <div className="btn btn-primary col-md-4 col-md-offset-4">
                                <span style={{color: "white"}}>Download As CSV</span>
                            </div>
                        </CSVLink>
                        <br/>
                    </div>}
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger capitalize-text pull-left"
                            onClick={closeModal}>
                        close
                    </button>
                    {canPerformDistribution &&
                    !isReturnListEmpty &&
                    <button className="btn btn-primary capitalize-text pull-left"
                            onClick={() => performReturnOfCapital(returnId, projectType)}>
                        perform distribution
                    </button>}
                </Modal.Footer>
            </Modal>
        );
    };
}


RoCViewerModal.propTypes = {
    actionType: PropTypes.string.isRequired,
    closeModal: PropTypes.func.isRequired,
    entityName: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    performReturnOfCapital: PropTypes.func.isRequired,
    projectName: PropTypes.string.isRequired,
    projectType: PropTypes.string.isRequired,
    returnId: PropTypes.number.isRequired,
    returnList: PropTypes.array.isRequired,
    internalNotes: PropTypes.string.isRequired
};


RoCViewerModal.defaultProps = {
    closeModal: () => null,
    entityName: '',
    open: false,
    performReturnOfCapital: () => null,
    projectName: '',
    projectType: PROJECT_REG_D,
    returnId: 0,
    returnList: [],
    internalNotes: ''
};


export default RoCViewerModal;
