import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../../../IconComponent/IconComponent';
import { PROJECT_REG_CF, PROJECT_REG_D } from '../../../../../utils/ApplicationConstants';

class SelectedFilesComponent extends React.Component {
    onInputChange = (event) => {
        const { name, value } = event.target;

        this.setState({ [name]: value });
    };

    onSelectFileToEdit = () => {
        const { file, onSelectFileToEdit } = this.props;

        onSelectFileToEdit(file);
    };

    onRemoveFile = () => {
        const { onRemoveFile, file } = this.props;

        onRemoveFile(file);
    };

    render = () => {
        const {
            file, mappedFileTypes, fileToEdit, isEditingFile,
            mappedRegDProject, mappedRegCFProjects,
        } = this.props;

        const isSelected = fileToEdit.key === file.key;
        const selectedClass = isSelected ? 'selected-table-row' : '';
        let selectedMap = { 0: 'undefined' };

        if (file.projectType === PROJECT_REG_D) {
            selectedMap = mappedRegDProject;
        } else if (file.projectType === PROJECT_REG_CF) {
            selectedMap = mappedRegCFProjects;
        }

        return (
            <tr className={selectedClass}>
                <td>{file.name}</td>
                <td>{file.docDescription}</td>
                <td>{selectedMap[file.selectedProjectId]}</td>
                <td>{mappedFileTypes[file.fileType]}</td>
                <td>{file.docYear}</td>
                <td>{file.docMonth}</td>
                <td>{file.displayNotes}</td>
                <td>{file.privateNotes}</td>
                <td>
                    <button
                        className="btn btn-success btn-sm capitalize-text"
                        onClick={this.onSelectFileToEdit}
                        disabled={isEditingFile}
                        type="button"
                    >
                        {(!isEditingFile || !isSelected) && (
                            <React.Fragment>
                                <Icon iconName="pencil" extraClasses="white-text"/>
                                {' edit'}
                            </React.Fragment>
                        )}

                        {(isEditingFile && isSelected) && (
                            <React.Fragment>
                                <Icon iconName="pencil" extraClasses="white-text"/>
                                {' editing'}
                            </React.Fragment>
                        )}
                    </button>
                    {!isSelected && (
                        <button
                            className="btn btn-danger btn-sm capitalize-text"
                            onClick={this.onRemoveFile}
                            type="button"
                        >
                            <Icon iconName="trash" extraClasses="white-text"/>
                            {' delete'}
                        </button>
                    )}
                </td>
            </tr>
        );
    };
}

SelectedFilesComponent.propTypes = {
    file: PropTypes.object.isRequired,
    fileToEdit: PropTypes.object.isRequired,
    isEditingFile: PropTypes.bool.isRequired,
    mappedFileTypes: PropTypes.object.isRequired,
    onRemoveFile: PropTypes.func.isRequired,
    onSelectFileToEdit: PropTypes.func.isRequired,
    mappedRegDProject: PropTypes.object.isRequired,
    mappedRegCFProjects: PropTypes.object.isRequired,
};

SelectedFilesComponent.defaultProps = {
    file: {},
    fileToEdit: {},
    isEditingFile: false,
    mappedFileTypes: {},
    onRemoveFile: () => null,
    onSelectFileToEdit: () => null,
    mappedRegDProject: {},
    mappedRegCFProjects: {},
};

export default SelectedFilesComponent;
