import imgIconDashboard from "./sidebar/dashboard.png"
import imgIconDashboardHover from "./sidebar/dashboard-h.png"
import imgIconEarnings from "./sidebar/earnings.svg"
import imgIconEarningsHover from "./sidebar/earnings-h.svg"
import imgIconDocument from './sidebar/document.svg'
import imgIconDocumentHover from './sidebar/document-h.svg'
import imgIconWallet from "./imgIconWallet.png"
import imgIconGraph from "./imgIconGraph.png"
import imgIconMoneyBag from "./imgIconMoneyBag.png"
import imgIconBank from "./sidebar/bank.svg"
import imgIconBankHover from "./sidebar/bank-h.svg"
import imgIconNotifications from "./imgIconNotifications.png"
import imgIconSettings from "./sidebar/settings.svg"
import imgIconProjectUpdates from "./sidebar/oilwell.svg"
import imgIconProjectUpdatesHover from "./sidebar/oilwell-h.svg"
import imgIconSettingsHover from "./sidebar/settings-h.svg"
import imgIconMessage from "./sidebar/message.svg"
import imgIconMessageHover from "./sidebar/message-h.svg"
import imgIconInvestmentOpportunities from "./sidebar/investments.svg"
import imgIconInvestmentOpportunitiesHover from "./sidebar/investments-h.svg"
import imgIconPeople from "./imgIconPeople.png";
import imgIconClock from './projectDetailIcons/imgIconClock.png';
import imgIconInsiderDeal from './projectDetailIcons/imgIconInsiderDeal.png';
import imgIconLowerRiskIcon from './projectDetailIcons/imgIconLowerRiskIcon.png';
import imgIconMedal from './projectDetailIcons/imgIconMedal.png';
import imgIconMoneySign from './projectDetailIcons/imgIconMoneySign.png';
import imgIconOilDerrick from './projectDetailIcons/imgIconOilDerrick.png';
import imgIconOilPrices from './projectDetailIcons/imgIconOilPrices.png';
import imgIconOpportunities from './projectDetailIcons/imgIconOpportunities.png';
import imgIconStackOfMoneyIcon from './projectDetailIcons/imgIconStackOfMoneyIcon.png';


export default {
    imgIconDashboard,
    imgIconWallet,
    imgIconGraph,
    imgIconMoneyBag,
    imgIconBank,
    imgIconNotifications,
    imgIconSettings,
    imgIconPeople,
    imgIconClock,
    imgIconInsiderDeal,
    imgIconLowerRiskIcon,
    imgIconMedal,
    imgIconMoneySign,
    imgIconOilDerrick,
    imgIconOilPrices,
    imgIconOpportunities,
    imgIconStackOfMoneyIcon,
    imgIconDashboardHover,
    imgIconEarnings,
    imgIconEarningsHover,
    imgIconBankHover,
    imgIconDocument,
    imgIconDocumentHover,
    imgIconSettings,
    imgIconSettingsHover,
    imgIconMessage,
    imgIconMessageHover,
    imgIconInvestmentOpportunities,
    imgIconInvestmentOpportunitiesHover,
    imgIconProjectUpdates,
    imgIconProjectUpdatesHover
}
