export default {
    session: {},
    projectUpdate: {
        regDUpdateList: [],
        regCFUpdateList: [],
        selectedUpdated: {
            notificationData: {},
            projectUpdateId: -1,
            projectType: null,
            action: null,
        },
        projectUpdateOnFlightReq: 0,
    },
    projects: {
        requestOnFlight: 0,
        regCFProjects: [],
        regDProjects: [],
    },
    externalImages: {
        onFlightRequest: 0,
        urlList: {
            files: [],
        },
    },
    returnOfCapital: {
        ROCOnLFightReq: 0,
        regDProjects: [],
        regCfProjects: [],
        regDReturnedCapital: [],
        regCfReturnedCapital: [],
        investorReturn: [],
    },
    allInvestments: {
        isAccreditedUser: false,
        activeRegCfInvestments: [],
        activeRegDInvestments: [],
        pastRegDInvestments: [],
        pastRegCfInvestments: [],
        projectCategories: [],
        allInvestmentsOnFlightReq: 0,
        isAdminView: false,
    },
    projectDetails: {
        projectDetailsOnFlightRequest: 0,
        projectHighlights: [],
        regCfCommentList: [],
        projectDetails: {},
        selectUpdate: {},
        projectTabs: [],
        isSigned: '_',
    },
    projectEditor: {
        PEV2OnFlightRequest: 0,
        projectTabs: [],
        projectDetails: {},
        parentProjects: [],
        projectHighlights: [],
        helloSignTemplates: [],
        projectCategories: [],
        projectStatuses: [],
    },
    projectStatusManager: {
        PSOnFlightReq: 0,
        projectStatusList: [],
    },
    investorFiles: {
        IFOnFlightRequest: 0,
        investorFilesList: [],
        investorContractFilesList: [],
        mappedFileTypes: {},
        fileTypeList: [],
        allProjects: {
            regDProjectList: [],
            regCfProjectList: [],
            mappedRegDProject: {},
            mappedRegCFProjects: {},
        },
        allProjectFiles: [],
    },
    investorMassFileUpload: {
        IDMUOnFlightRequest: 0,
        allInvestorFiles: [],
        AllInvestorContractFilesList:[],
    },
    investorDashboard: {
        IDOnFlightRequest: 0,
        investorEarnings: [],
        totalPaymentsRegD: 0,
        totalPaymentsRegCF: 0,
        investorInvestments: [],
        totalInvestmentRegD: 0,
        totalInvestmentRegCF: 0,
        reducedEarnings: {
            earningLabels: [],
            earningsAmount: [],
            cumulativeTimeSeries: [],
            cumulativeEarningsAmount: 0.0
        },
        reducedInvestments: {
            investmentLabels: [],
            investmentCounts: [],
            investmentAmounts: [],
            cumulativeInvestmentCounts: [],
            cumulativeInvestmentAmounts: [],
            cumulativeTimeSeries: []
        },
    },
    newEntity: {
        NEOnFlightRequest: 0,
        businessCategories: [],
        mappedClassifications: {},
    },
    investorEmailHistory: {
        EMHOnFlightRequest: 0,
        emailHistoryList: [],
    },
    hsEmails: {
        HSEmailOnFlightReq: 0,
        offset: 0,
        objects: [],
        totalCount: 0,
        importedEmailList: [],
    },
    activeTab: 0,
    w9Verified: false,
    EntityID: null,
};
