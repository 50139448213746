import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import Icon from "../../../shared/IconComponent/IconComponent";
import {PROJECT_REG_CF, PROJECT_REG_D} from "../../../../utils/ApplicationConstants";

const propTypes = {
    project: PropTypes.object.isRequired,
    projectType: PropTypes.oneOf([PROJECT_REG_CF, PROJECT_REG_D]),
    onApproveClickHandler: PropTypes.func.isRequired,
    onCloneAsNew: PropTypes.func.isRequired,
    onRejectClickHandler: PropTypes.func.isRequired,
    onEditClickHandler: PropTypes.func.isRequired,
    onDeleteClickHandler: PropTypes.func.isRequired,
    onOpenUpdateTypeEditor: PropTypes.func.isRequired,
    onRestoreDeletedClickHandler: PropTypes.func.isRequired,
    onViewClickHandler: PropTypes.func.isRequired,
};

const emptyMethod = () => null;
const defaultProps = {
    project: {},
    projectType: PROJECT_REG_D,
    onApproveClickHandler: emptyMethod,
    onCloneAsNew: emptyMethod,
    onRejectClickHandler: emptyMethod,
    onEditClickHandler: emptyMethod,
    onDeleteClickHandler: emptyMethod,
    onRestoreDeletedClickHandler: emptyMethod,
    onOpenUpdateTypeEditor: emptyMethod,
    onViewClickHandler: emptyMethod,
};

class ProjectUpdateTableElement extends React.Component {
    buttonStyle = {
        border: "none",
        margin: 0,
        padding: "0px 10px",
    };

    getStatus = (approvalCode) => {
        if (approvalCode === 1) {
            return "Approved and sent email";
        } else if (approvalCode === 2) {
            return "Rejected";
        } else {
            return "Pending";
        }
    };

    onApprove = () => {
        const {onApproveClickHandler, projectType, project} = this.props;
        const {Subject, NotificationSmallText, NotificationID} = project;
        const projectID = PROJECT_REG_D === projectType ? project.ProjectID_RegD : project.ProjectID_RegCF;
        onApproveClickHandler(projectType, projectID, Subject, NotificationSmallText, NotificationID)
    };

    onReject = () => {
        const {onRejectClickHandler, projectType, project} = this.props;
        onRejectClickHandler(projectType, project.NotificationID)
    };

    onEdit = () => {
        const {onEditClickHandler, projectType, project} = this.props;
        onEditClickHandler(projectType, project.NotificationID)
    };

    onCloneAsNew = () => {
        const {onCloneAsNew, projectType, project} = this.props;
        onCloneAsNew(projectType, project.NotificationID)
    };

    onDelete = () => {
        const {onDeleteClickHandler, projectType, project} = this.props;
        onDeleteClickHandler(projectType, project.NotificationID)
    };

    onRestore = () => {
        const {onRestoreDeletedClickHandler, projectType, project} = this.props;
        onRestoreDeletedClickHandler(projectType, project.NotificationID)
    };

    onView = () => {
        const {projectType, onViewClickHandler, project} = this.props;
        onViewClickHandler(projectType, project.NotificationID);
    };

    renderDeleted = () => {
        return (
            <div className="dropdown">
                <button className="btn-sm btn-info dropdown-toggle no-text-wrap full-width"
                        data-toggle="dropdown"
                        type="button"
                        style={this.buttonStyle}>
                    <span>Deleted <Icon iconName="caret-down" extraClasses="white-text"/></span>
                </button>
                <ul className="dropdown-menu">
                    <li onClick={this.onRestore}>
                        <a className="btn-sm btn-primary white-text btn-dropdown">Restore</a>
                    </li>
                </ul>
            </div>
        );
    };

    renderRejectedButton = () => {
        return (
            <div className="dropdown">
                <button className="btn-sm btn-warning dropdown-toggle no-text-wrap full-width"
                        data-toggle="dropdown"
                        type="button"
                        style={this.buttonStyle}>
                    <span>Rejected <Icon iconName="caret-down" extraClasses="white-text"/></span>
                </button>
                <ul className="dropdown-menu">
                    <li onClick={this.onCloneAsNew}>
                        <a className="btn-sm btn-default btn-dropdown">Clone as new</a>
                    </li>
                </ul>
            </div>
        );
    };

    onOpenTypeEditor = () => {
        const {project, projectType} = this.props;
        const {Subject, NotificationID, DaysAsPrivateUpdate} = project;
        this.props.onOpenUpdateTypeEditor(NotificationID, Subject, DaysAsPrivateUpdate, projectType);
    };

    renderDefaultOptions = () => {
        return (
            <div className="dropdown">
                <button className="btn-sm btn-primary dropdown-toggle no-text-wrap full-width"
                        data-toggle="dropdown"
                        type="button"
                        style={this.buttonStyle}>
                    <span>Actions <Icon iconName="caret-down" extraClasses="white-text"/></span>
                </button>
                <ul className="dropdown-menu">
                    <li onClick={this.onApprove}>
                        <a className="btn-sm btn-default btn-dropdown">Approve</a>
                    </li>
                    <li onClick={this.onCloneAsNew}>
                        <a className="btn-sm btn-default btn-dropdown">Clone as new</a>
                    </li>
                    <li onClick={this.onReject}>
                        <a className="btn-sm btn-default btn-dropdown">Reject</a>
                    </li>
                    <li onClick={this.onEdit}>
                        <a className="btn-sm btn-default btn-dropdown">Edit</a>
                    </li>
                    <li onClick={this.onDelete}>
                        <a className="btn-sm btn-default btn-dropdown">Delete</a>
                    </li>
                </ul>
            </div>
        );
    };

    renderApprovedButton = () => {
        return (
            <div className="dropdown">
                <button className="btn-sm btn-success dropdown-toggle no-text-wrap full-width"
                        data-toggle="dropdown"
                        type="button"
                        style={this.buttonStyle}>
                    <span>Approved <Icon iconName="caret-down" extraClasses="white-text"/></span>
                </button>
                <ul className="dropdown-menu">
                    <li onClick={this.onCloneAsNew}>
                        <a className="btn-sm btn-default btn-dropdown">Clone as new</a>
                    </li>
                    <li onClick={this.onOpenTypeEditor}>
                        <a className="btn-sm btn-default btn-dropdown">Change Display Status</a>
                    </li>
                    <li onClick={this.onView}>
                        <a className="btn-sm btn-default btn-dropdown">See Email preview</a>
                    </li>
                </ul>
            </div>
        );
    };

    renderActionButtons = () => {
        const {Approved, nDelete} = this.props.project;
        let el;

        if (Approved === 1) {
            el = this.renderApprovedButton();
        } else if (Approved === 2) {
            el = this.renderRejectedButton();
        } else if (nDelete === 1) {
            el = this.renderDeleted();
        } else {
            el = this.renderDefaultOptions();
        }

        return el;
    };

    renderDisplayStatus = (status) => {
        if (status === -1) {
            return "Public";
        } else if (status === 0) {
            return "Always Private";
        } else {
            return `Private For ${status} Days`
        }
    };

    render = () => {
        const {project} = this.props;

        return (
            <tr>
                <td className="no-text-wrap"
                    onClick={this.onView}
                    style={{cursor: "pointer"}}>
                    {project.ProjectName}
                </td>
                <td className="no-text-wrap">
                    {project.InternalTitle}
                </td>
                <td className="no-text-wrap">
                    {project.Entity}
                </td>
                <td className="no-text-wrap">
                    {project.FirstName}
                </td>
                <td className="no-text-wrap">
                    {moment(project.CreatedDate).format("YYYY-MM-DD HH:mm:ss")}
                </td>
                <td>{project.Subject}</td>
                <td className="no-text-wrap">
                    {this.getStatus(project.Approved)}
                </td>
                <td className="no-text-wrap">
                    {this.renderDisplayStatus(project.DaysAsPrivateUpdate)}
                </td>
                <td className="text-center">
                    {this.renderActionButtons()}
                </td>
            </tr>
        );
    };
}

ProjectUpdateTableElement.propTypes = propTypes;
ProjectUpdateTableElement.defaultProps = defaultProps;

export default ProjectUpdateTableElement;
