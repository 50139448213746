/**
 * Created by goutham on 9/13/2017.
 */
import React, {Component} from 'react'
import swal from 'sweetalert2';
import RequestService from './api/RequestService';

class Entity extends Component {
    constructor(props){
        super(props);
        this.state={
            id:this.props.id,
            isLoaded:false,
            errorOnLoad:false
        }
        this.onSubmit=this.onSubmit.bind(this);
    }
    componentWillMount(){
        window.scrollTo(0, 0);
    }

    async onSubmit(e){
        e.preventDefault();

        var formBody = [];
        formBody.push("UserID=" + encodeURIComponent(this.state.id));
        formBody.push("EntityType=" + encodeURIComponent(this.refs.etype.value));
        formBody.push("EntityName=" + encodeURIComponent(this.refs.ename.value));
        formBody.push("Address=" + encodeURIComponent(this.refs.eaddress.value));
        formBody.push("City=" + encodeURIComponent(this.refs.ecity.value));
        formBody.push("State=" + encodeURIComponent(this.refs.estate.value));
        formBody.push("ZipCode=" + encodeURIComponent(this.refs.ezipcode.value));
        formBody.push("Country=" + encodeURIComponent(this.refs.ecountry.value));
        formBody.push("Phone=" + encodeURIComponent(this.refs.ephone.value));
        formBody.push("EntityDescription=" + encodeURIComponent(this.refs.edescription.value));
        formBody.push("MailingAddress=" + encodeURIComponent(this.refs.mailaddress.value));
        formBody.push("MailingCity=" + encodeURIComponent(this.refs.mailcity.value));
        formBody.push("MailingState=" + encodeURIComponent(this.refs.mailstate.value));
        formBody.push("MailingZipCode=" + encodeURIComponent(this.refs.mailzipcode.value));
        formBody.push("MailingCountry=" + encodeURIComponent(this.refs.mailcountry.value));

        formBody = formBody.join("&");

        try {
            const { data: status } = await RequestService.post({
                url: '/api/addentities',
                data: formBody
            });
            if(status.code===200){
                swal({
                    title: 'New Entity Added Successfully',
                    text: "Do You Want To Become An Accredited Investor?",
                    type: 'success',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    confirmButtonClass: 'btn btn-success',
                    cancelButtonClass: 'btn btn-danger',
                    buttonsStyling: false
                }).then((result) => {
                    if (result.value) {
                        window.location="/AccreditedEntity/"
                        // result.dismiss can be 'cancel', 'overlay',
                    // 'close', and 'timer'
                    } else if (result.dismiss === 'cancel') {
                        swal(
                            'Cancelled',
                            'Yo are now a Non-Accredited Investor',
                            'error'
                        );
                    }
                });
            }
        } catch (err) {
            console.log(err);
        }
    }
    render(){
        return(
            <div>
                <div className="col-md-3">
                </div>
                <div className="col-md-6 color">
                    <div className="panel panel-primary">
                        <h1 className="signup">Entity Form</h1>
                        <div className="panel-body">
                            <form name="myform" method="post">
                                <div className="form-group">
                                    <label htmlFor="etype">Entity Type</label>
                                    <select name="etype" id="etype" ref="etype" className="form-control">
                                        <option>Select Entity Type</option>
                                        <option>Company</option>
                                        <option>Personal</option>
                                    </select>
                                    <span id="error_etype" className="text-danger" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="ename">Entity Name *</label>
                                    <input id="ename" name="ename" className="form-control" type="text" ref="ename" />
                                    <span id="error_ename" className="text-danger" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="eaddress">Address *</label>
                                    <input id="eaddress" name="eaddress" className="form-control" type="text" ref="eaddress" />
                                    <span id="error_eaddress" className="text-danger" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="ecity">City *</label>
                                    <input id="ecity" name="ecity" className="form-control" type="text" ref="ecity" />
                                    <span id="error_ecity" className="text-danger" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="estate">State *</label>
                                    <input id="estate" name="estate" className="form-control" type="text" ref="estate" />
                                    <span id="error_estate" className="text-danger" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="ezipcode">Zipcode *</label>
                                    <input id="ezipcode" name="ezipcode" className="form-control" type="text" ref="ezipcode" />
                                    <span id="error_ezipcode" className="text-danger" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="ecountry">Country *</label>
                                    <input id="ecountry" name="ecountry" className="form-control" type="text" ref="ecountry" />
                                    <span id="error_ecountry" className="text-danger" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="ephone">Phone *</label>
                                    <input id="ephone" name="ephone" className="form-control" type="text" ref="ephone" />
                                    <span id="error_ephone" className="text-danger" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="disc">Entity Description</label>
                                    <textarea ref="edescription" className="form-control" rows={3} cols={50} defaultValue={""} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="mailaddress">Mailing Address *</label>
                                    <input id="mailaddress" name="mailaddress" className="form-control" type="text" ref="mailaddress" />
                                    <span id="error_mailaddress" className="text-danger" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="mailcity">Mailing City *</label>
                                    <input id="mailcity" name="mailcity" className="form-control" type="text" ref="mailcity" />
                                    <span id="error_mailcity" className="text-danger" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="mailstate">Mailing State *</label>
                                    <input id="mailstate" name="mailstate" className="form-control" type="text" ref="mailstate" />
                                    <span id="error_mailstate" className="text-danger" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="mailzipcode">Mailing Zipcode*</label>
                                    <input id="mailzipcode" name="mailzipcode" className="form-control" type="text" ref="mailzipcode" />
                                    <span id="error_mailzipcode" className="text-danger" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="mailcountry">Mailing Country *</label>
                                    <input id="mailcountry" name="mailcountry" className="form-control" type="text" ref="mailcountry" />
                                    <span id="error_mailcountry" className="text-danger" />
                                </div>
                                <button id="submit" type="submit" onClick={this.onSubmit} className="btn btn-primary center">Submit</button>
                                <div className="clearfix" />
                                <div className="separator">
                                    <div className="clearfix" />
                                    <br />
                                </div>
                            </form></div>
                    </div>
                </div>
                <div className="col-md-3">
                </div>
            </div>
        )
    }
}

export default Entity;
