import React, { Component } from 'react';
import swal from 'sweetalert';
import Pagination from 'react-js-pagination';
import store from '../../index'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
    Modal,
    ModalHeader,
    ModalTitle,
    ModalClose,
    ModalBody,
    ModalFooter
  } from 'react-bootstrap';
import getDate from '../../utils/ConvertDate'
import RequestService from '../../api/RequestService';

class OperaterContent extends Component {
    constructor(props){
        super(props);
        this.state={
            Users:[],
            firstname:[],
            projectcf:[],
            projectd:[],
            projectinvcf:[],
            projectinvd:[],
            Records:[],
            id:props.session.id,
            // pageNumber: 1,
            // totalCount: 0,
            // perPage: 5,
            // activePage: 1,
            // allProjects: [],
            // totalProjects:[],
            pageNumbermain: 1,
            totalCountmain: 0,
            perPagemain: 10,
            activePagemain: 1,
            isLoaded:false,
            errorOnLoad:false,
            show: false
        };
        this.getUserRole=this.getUserRole.bind(this);
        this.userProfile=this.userProfile.bind(this);
        this.userProfile1=this.userProfile1.bind(this);
        this.userProfile2=this.userProfile2.bind(this);
        this.userProfile3=this.userProfile3.bind(this);
        this.userProfile4=this.userProfile4.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handlePageChangemain = this.handlePageChangemain.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }
    componentWillMount(){
        window.scrollTo(0, 0);
    }
    handleClose() {
        this.setState({ show: false });
      }
    getUserRole(){
        fetch('/api/getUserRole/'+this.state.id+'/'+this.props.session.marketing,{
            method:'get'
        })
            .then((response)=>{
                return response.json()
            })
            .then((data)=>{
                  this.setState({
                    Users:data.slice(0,10),
                    isLoaded:true,
                    err:{},
                    errorOnLoad:false,
                    allProjects: data,
                    totalProjects:data,
                    pageNumbermain: 1,
                    totalCountmain: data.length,
                    perPagemain: 10,
                    activePagemain: 1,

                });
            })
            .catch((err)=>{
                this.setState({
                    err:err,
                    errorOnLoad:true
                })

            })
    }


	async userProfile(id) {
        try {
            const { data } = await RequestService.get(`/api/getInvestorProfile/${id}`);
            // const { data } = await RequestService.get('/api/getInvestorProfile');
            
            if (data.code === 200) {
                this.setState({
                    firstname: data.investorProfile,
                    isLoaded: true,
                    err: {},
                    errorOnLoad: false,
                    show: !this.state.show,
                });
    
                this.userProfile1(id);
                this.userProfile2(id);
                this.userProfile3(id);
                this.userProfile4(id);
            }
        } catch (err) {
            console.log(err);
        }
	}

    userProfile1(id){
        fetch('/api/projectregcf/'+id+'/'+this.props.session.marketing,{
            method:'get'
        })
            .then((response)=>{
                return response.json()

            })
            .then((data)=>{
                if(data.code===200)
                {
                     this.setState({
                    //     Records: data.rows,
                    //     projectcf:data.rows.slice(0,5),
                    //     pageNumber: 1,
                    //     totalCount: data.rows.length,
                    //     perPage: 5,
                    //     activePage: 1,

                        projectcf:data.rows,
                        isLoaded:true,
                        err:{},
                        errorOnLoad:false,
                    });
                }
                else {
                    this.setState({
                        projectcf:[],
                        isLoaded:true,
                        err:{},
                        errorOnLoad:false,
                    });

                }

            })
            .catch((err)=>{
                this.setState({
                    err:err,
                    errorOnLoad:true
                })

            })
    }
    userProfile2(id){
        fetch('/api/projectregd/'+id+'/'+this.props.session.marketing,{
            method:'get'
        })
            .then((response)=>{
                return response.json()

            })
            .then((data)=>{
                if(data.code===200)
                {
                    this.setState({
                        // Records: data.rows,
                        // projectd:data.rows.slice(0,5),
                        // pageNumber: 1,
                        // totalCount: data.rows.length,
                        // perPage: 5,
                        // activePage: 1,
                        projectd:data.rows,
                        isLoaded:true,
                        err:{},
                        errorOnLoad:false,
                    });
                }
                else {
                    this.setState({
                        projectd:[],
                        isLoaded:true,
                        err:{},
                        errorOnLoad:false,
                    });

                }

            })
            .catch((err)=>{
                this.setState({
                    err:err,
                    errorOnLoad:true
                })

            })
    }

    userProfile3(id){
        fetch('/api/projectinvregcf/'+id+'/'+this.props.session.marketing,{
            method:'get'
        })
            .then((response)=>{
                return response.json()

            })
            .then((data)=>{
                if(data.code===200)
                {
                    this.setState({
                        // Records: data.rows,
                        // projectinvcf:data.rows.slice(0,5),
                        // pageNumber: 1,
                        // totalCount: data.rows.length,
                        // perPage: 5,
                        // activePage: 1,
                        projectinvcf:data.rows,
                        isLoaded:true,
                        err:{},
                        errorOnLoad:false,
                    });
                }
                else {
                    this.setState({
                        projectinvcf:[],
                        isLoaded:true,
                        err:{},
                        errorOnLoad:false,
                    });

                }

            })
            .catch((err)=>{
                this.setState({
                    err:err,
                    errorOnLoad:true
                })

            })
    }

    userProfile4(id){
        fetch('/api/projectinvregd/'+id+'/'+this.props.session.marketing,{
            method:'get'
        })
            .then((response)=>{
                return response.json()

            })
            .then((data)=>{
                if(data.code===200)
                {
                    this.setState({
                        // Records: data.rows,
                        // projectinvd:data.rows.slice(0,5),
                        // pageNumber: 1,
                        // totalCount: data.rows.length,
                        // perPage: 5,
                        // activePage: 1,
                        projectinvd:data.rows,
                        isLoaded:true,
                        err:{},
                        errorOnLoad:false,
                    });
                }
                else {
                    this.setState({
                        projectinvd:[],
                        isLoaded:true,
                        err:{},
                        errorOnLoad:false,
                    });

                }

            })
            .catch((err)=>{
                this.setState({
                    err:err,
                    errorOnLoad:true
                })

            })
    }



    handlePageChange(pageNumber) {
        var prev = ((pageNumber)-1)*5;
        var next = prev+5;
        this.setState({
            activePage: pageNumber,
            Entities: this.state.Records.slice(prev, next)
        });

    }

    handlePageChangemain(pageNumber) {
        var prev = ((pageNumber)-1)*10;
        var next = prev+10;
        this.setState({
            activePagemain: pageNumber,
            Users: this.state.allProjects.slice(prev, next)
        });

    }
    getDat(toSearch, objects) {
        return new Promise((resolve, reject) => {
            let dat = objects.filter(function (object) {
                let searchStatus = 0;
                for (const key in object) {
                    if (object.hasOwnProperty(key)) {
                        var patt = new RegExp(toSearch);
                        if (patt.test(object[key])) {
                            searchStatus = 1;
                        }
                    }
                }
                if (searchStatus) {
                    return true;
                }
            });
            resolve(dat);
        });
    }

    handleSearchChange(e) {
        let toSearch = e.target.value;
        if (toSearch == "" || toSearch == undefined) {
            this.setState({
                allProjects: this.state.totalProjects,
                Users: this.state.totalProjects.slice(0, 10),
                pageNumbermain: 1,
                totalCountmain: this.state.totalProjects.length,
                perPagemain: 10,
                activePagemain: 1
            })
        } else {
            let objects = this.state.totalProjects;
            this.getDat(toSearch, objects).then((dat) => {
                this.setState({
                    allProjects: dat,
                    Users: dat.slice(0, 10),
                    pageNumbermain: 1,
                    totalCountmain: dat.length,
                    perPagemain: 10,
                    activePagemain: 1
                })
            }
            );
        }

    }


    componentDidMount(){
        this.getUserRole();
    }
    render() {
        return (


            <div className="container body">
                <div className="main_container">
                    {/* page content */}
                    <div className="right_col" role="main">
                        <div className='' >

                            <div className="clearfix" />
                            <div className="row">
                                <div className="clearfix" />
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <div className="x_panel">
                                        <div className="x_title">
                                            <h4 className='up col-md-8'>FundRaisers</h4>


                                                    <div className="col-md-4">
                                                       <input className="pull-right" type="text" onChange={this.handleSearchChange} placeholder="Search here" />
                                                     </div>

                                            <div className="clearfix" />
                                        </div>
                                        <div className="x_content">
                                            <div className="table-responsive">
                                                <table className="table table-striped jambo_table ">
                                                    <thead className="thead-inverse">
                                                    <tr className="headings">
                                                        <th className="column-title">User ID</th>
                                                        <th className="column-title">First Name</th>
                                                        <th className="column-title">Last Name </th>
                                                        <th className="column-title">Email</th>
                                                        <th className="column-title">Country</th>
                                                        <th className="column-title">Created Date</th>
                                                    </tr>
                                                    </thead>
                                                    {(() => {
                                                    switch (this.state.Users.length<1) {
                                                    case false: return (
                                                    <tbody>
                                                    {
                                                        this.state.Users.map((user, i) => (
                                                            <tr key={i} className="even pointer">
                                                                <td className=" "><a href="javascript:void(0);" onClick={() => this.userProfile(user.UserID) && this.userProfile1(user.UserID)}>{user.UserID}</a></td>
                                                                <td className=" "><a href="javascript:void(0);" onClick={() => this.userProfile(user.UserID) && this.userProfile1(user.UserID)}>{user.FirstName}</a></td>
                                                                <td className=" "><a href="javascript:void(0);" onClick={() => this.userProfile(user.UserID) && this.userProfile1(user.UserID)}>{user.LastName}</a></td>
                                                                <td className=" "><a href="javascript:void(0);" onClick={() => this.userProfile(user.UserID) && this.userProfile1(user.UserID)}>{user.Email}</a></td>
                                                                <td className=" ">{user.Country}</td>
                                                                <td className=" ">{getDate.getDate(user.CreatedDate)}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                    </tbody>
                                                    );
                                                    case true: return (
                                                        <tbody>
                                                          <tr  className="even pointer">
                                                            <td colSpan="6" className="text-center">Nothing to show</td>
                                                          </tr>
                                                        </tbody>
                                                              );
                                                            }
                                                 })()}
                                                </table>
                                                 <div className="pull-right">
                                                <Pagination
                                                    firstPageText={<i className='glyphicon glyphicon-chevron-left' />}
                                                    lastPageText={<i className='glyphicon glyphicon-chevron-right' />}
                                                    prevPageText={<i className='glyphicon glyphicon-menu-left' />}
                                                    nextPageText={<i className='glyphicon glyphicon-menu-right' />}
                                                    activePage={this.state.activePagemain}
                                                    itemsCountPerPage={this.state.perPagemain}
                                                    totalItemsCount={this.state.totalCountmain}
                                                    onChange={this.handlePageChangemain}
                                                />
                                            </div>

                                            </div>
                                        </div>
                                    </div>




                                        <Modal show={this.state.show} onHide={this.handleClose}>
                                        <Modal.Header closeButton>
                                            <Modal.Title></Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="container" >
                                                    <div className="row">

                                                        <div className="">
                                                            <div className="panel-body">
                                                                <div className="row">
                                                                    <div>
                                                                        <div className="col-md-12 col-sm-12 col-xs-12">

                                                                            <div className="x_panel">

                                                                                <div className="x_title">
                                                                                    <h4>Profile Info</h4>

                                                                                    <div className="clearfix" />
                                                                                </div>
                                                                                <div className="x_content" id="target">
                                                                                <div className="table-responsive">
                                                                                    <table className="table table-striped jambo_table bulk_action">
                                                                                        <thead>
                                                                                        <tr>

                                                                                            <th>User</th>
                                                                                            <th>Details </th>

                                                                                        </tr>
                                                                                        </thead>
                                                                                        {(() => {
                                                                                        switch (this.state.firstname.length<1) {
                                                                                        case false: return (
                                                                                        <tbody>

                                                                                        <tr className="even pointer">
                                                                                            <td className=" ">First Name:</td>
                                                                                            <td className=" ">{this.state.firstname.FirstName}</td>
                                                                                        </tr>
                                                                                        <tr className="even pointer">
                                                                                            <td className=" ">Last Name:</td>
                                                                                            <td className=" ">{this.state.firstname.LastName}</td>
                                                                                        </tr>
                                                                                        <tr className="even pointer">
                                                                                            <td className=" ">Date of birth:</td>
                                                                                            <td className=" ">{this.state.firstname.DOB}</td>
                                                                                        </tr>
                                                                                        <tr className="even pointer">
                                                                                            <td className=" ">Email:</td>
                                                                                            <td className=" ">{this.state.firstname.Email}</td>
                                                                                        </tr>
                                                                                        <tr className="even pointer">
                                                                                            <td className=" ">Country:</td>
                                                                                            <td className=" ">{this.state.firstname.Country}</td>
                                                                                        </tr>
                                                                                        <tr className="even pointer">
                                                                                            <td className=" ">Created Date:</td>
                                                                                            <td className=" ">{getDate.getDate(this.state.firstname.CreatedDate)}</td>
                                                                                        </tr>
                                                                                        <tr className="even pointer">
                                                                                            <td className=" ">Waiting Period Details:</td>
                                                                                            <td className=" ">{this.state.firstname.WaitingPeriodof30days}days left in wait period.</td>
                                                                                        </tr>
                                                                                        <tr className="even pointer">
                                                                                            <td className=" ">Notes:</td>
                                                                                            <td className=" ">{this.state.firstname.VisibleNotes}</td>
                                                                                        </tr>
                                                                                        </tbody>
                                                                                        );
                                                                                        case true: return (
                                                                                            <tbody>
                                                                                            <tr  className="even pointer">
                                                                                                <td colSpan="4" className="text-center">Nothing to Show</td>
                                                                                            </tr>
                                                                                            </tbody>
                                                                                        );
                                                                                        }
                                                                                        })()}
                                                                                    </table>
                                                                                </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-md-6 col-sm-6 col-xs-12">
                                                                            <ul className="nav nav-pills nav-justified" style={{ padding: '0 10px', marginBottom: 20 }}>
                                                                                <li className="active"><a data-toggle="pill" href="#home">Regulation CF</a></li>
                                                                                <li><a data-toggle="pill" href="#home1">Regulation D</a></li>
                                                                            </ul>
                                                                            <div className="tab-content">
                                                                                <div id="home" className="tab-pane fade in active">
                                                                                        <div className="x_panel">
                                                                                        <div className="x_title">
                                                                                            <h4>Submitted Projects</h4>

                                                                                            <div className="clearfix" />
                                                                                        </div>
                                                                                        <div className="x_content" >
                                                                                            <div className="table-responsive">

                                                                                                <table className="table table-striped jambo_table bulk_action ">
                                                                                                    <thead>
                                                                                                    <tr>
                                                                                                        <th>Project Name</th>
                                                                                                        <th>Company Name</th>
                                                                                                        <th>Created Date</th>
                                                                                                        <th>Project Status</th>
                                                                                                    </tr>
                                                                                                    </thead>
                                                                                                    {(() => {
                                                                                                    switch (this.state.projectcf.length<1) {
                                                                                                    case false: return (
                                                                                                    <tbody>
                                                                                                    {
                                                                                                        this.state.projectcf.map((user)=>(
                                                                                                            <tr key={user.ProjectID} className="even pointer">
                                                                                                            <td className=" ">{user.ProjectName}</td>
                                                                                                            <td className=" ">{user.CompanyName}</td>
                                                                                                            <td className=" ">{getDate.getDate(user.CreatedDate)}</td>
                                                                                                            {(() => {
                                                                                                                if (user.ProjectStatus == 1) {
                                                                                                                    return (<td>Approved</td>);
                                                                                                                } else {
                                                                                                                    return (<td>Pending</td>);
                                                                                                                }
                                                                                                            })()}
                                                                                                            {/* <td className=" ">{user.ProjectStatus}</td> */}
                                                                                                            </tr>
                                                                                                        ))
                                                                                                    }
                                                                                                    </tbody>
                                                                                                    );
                                                                                                    case true: return (
                                                                                                        <tbody>
                                                                                                        <tr  className="even pointer">
                                                                                                            <td colSpan="4" className="text-center">Nothing to Show</td>
                                                                                                        </tr>
                                                                                                        </tbody>
                                                                                                    );
                                                                                                    }
                                                                                                    })()}
                                                                                                </table>
                                                                                            </div>


                                                                                            {/* <div className="pull-right">
                                                                                                <Pagination
                                                                                                    firstPageText={<i className='glyphicon glyphicon-chevron-left' />}
                                                                                                    lastPageText={<i className='glyphicon glyphicon-chevron-right' />}
                                                                                                    prevPageText={<i className='glyphicon glyphicon-menu-left' />}
                                                                                                    nextPageText={<i className='glyphicon glyphicon-menu-right' />}
                                                                                                    activePage={this.state.activePage}
                                                                                                    itemsCountPerPage={this.state.perPage}
                                                                                                    totalItemsCount={this.state.totalCount}
                                                                                                    onChange={this.handlePageChange}
                                                                                                />
                                                                                            </div> */}

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div id="home1" className="tab-pane fade">
                                                                                    <div className="x_panel">
                                                                                        <div className="x_title">
                                                                                            <h4>Submitted Projects</h4>

                                                                                            <div className="clearfix" />
                                                                                        </div>
                                                                                        <div className="x_content" >
                                                                                            <div className="table-responsive">

                                                                                                <table className="table table-striped jambo_table bulk_action ">
                                                                                                    <thead>
                                                                                                    <tr>
                                                                                                        <th>Project Name</th>
                                                                                                        <th>Company Name</th>
                                                                                                        <th>Created Date</th>
                                                                                                        <th>Project Status</th>
                                                                                                    </tr>
                                                                                                    </thead>
                                                                                                    {(() => {
                                                                                                    switch (this.state.projectd.length<1) {
                                                                                                    case false: return (
                                                                                                    <tbody>
                                                                                                    {
                                                                                                        this.state.projectd.map((user)=>(
                                                                                                            <tr key={user.ProjectID} className="even pointer">
                                                                                                            <td className=" ">{user.ProjectName}</td>
                                                                                                            <td className=" ">{user.CompanyName}</td>
                                                                                                            <td className=" ">{getDate.getDate(user.CreatedDate)}</td>
                                                                                                            {(() => {
                                                                                                                if (user.ProjectStatus == 1) {
                                                                                                                    return (<td>Approved</td>);
                                                                                                                } else {
                                                                                                                    return (<td>Pending</td>);
                                                                                                                }
                                                                                                            })()}
                                                                                                            {/* <td className=" ">{user.ProjectStatus}</td> */}
                                                                                                            </tr>
                                                                                                        ))
                                                                                                    }
                                                                                                    </tbody>
                                                                                                    );
                                                                                                    case true: return (
                                                                                                        <tbody>
                                                                                                        <tr  className="even pointer">
                                                                                                            <td colSpan="4" className="text-center">Nothing to Show</td>
                                                                                                        </tr>
                                                                                                        </tbody>
                                                                                                    );
                                                                                                    }
                                                                                                    })()}
                                                                                                </table>
                                                                                            </div>


                                                                                            {/* <div className="pull-right">
                                                                                                <Pagination
                                                                                                    firstPageText={<i className='glyphicon glyphicon-chevron-left' />}
                                                                                                    lastPageText={<i className='glyphicon glyphicon-chevron-right' />}
                                                                                                    prevPageText={<i className='glyphicon glyphicon-menu-left' />}
                                                                                                    nextPageText={<i className='glyphicon glyphicon-menu-right' />}
                                                                                                    activePage={this.state.activePage}
                                                                                                    itemsCountPerPage={this.state.perPage}
                                                                                                    totalItemsCount={this.state.totalCount}
                                                                                                    onChange={this.handlePageChange}
                                                                                                />
                                                                                            </div> */}

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-md-6 col-sm-6 col-xs-12">
                                                                            <ul className="nav nav-pills nav-justified" style={{ padding: '0 10px', marginBottom: 20 }}>
                                                                                <li className="active"><a data-toggle="pill" href="#menu">Regulation CF</a></li>
                                                                                <li><a data-toggle="pill" href="#menu1">Regulation D</a></li>
                                                                            </ul>
                                                                            <div className="tab-content">
                                                                                <div id="menu" className="tab-pane fade in active">
                                                                                    <div className="x_panel">
                                                                                        <div className="x_title">
                                                                                            <h4>projectinvestments_regcf</h4>

                                                                                            <div className="clearfix" />
                                                                                        </div>
                                                                                        <div className="x_content" >
                                                                                            <div className="table-responsive">

                                                                                                <table className="table table-striped jambo_table bulk_action ">
                                                                                                    <thead>
                                                                                                    <tr>
                                                                                                        <th>Investment Name</th>
                                                                                                        <th>FundingGoal Max</th>
                                                                                                        <th>Funding Period</th>
                                                                                                        <th>LegalIssuer Name</th>
                                                                                                    </tr>
                                                                                                    </thead>
                                                                                                    {(() => {
                                                                                                    switch (this.state.projectinvcf.length<1) {
                                                                                                    case false: return (
                                                                                                    <tbody>
                                                                                                    {
                                                                                                        this.state.projectinvcf.map((user)=>(
                                                                                                            <tr key={user.ProjectInvestmentID} className="even pointer">
                                                                                                            <td className=" ">{user.InvestmentName}</td>
                                                                                                            <td className=" ">{user.FundingGoalMax}</td>
                                                                                                            <td className=" ">{user.FundingPeriod}</td>
                                                                                                            <td className=" ">{user.LegalIssuerName}</td>
                                                                                                            </tr>
                                                                                                        ))
                                                                                                    }
                                                                                                    </tbody>
                                                                                                    );
                                                                                                    case true: return (
                                                                                                        <tbody>
                                                                                                        <tr  className="even pointer">
                                                                                                            <td colSpan="4" className="text-center">Nothing to Show</td>
                                                                                                        </tr>
                                                                                                        </tbody>
                                                                                                    );
                                                                                                    }
                                                                                                    })()}
                                                                                                </table>
                                                                                            </div>


                                                                                            {/* <div className="pull-right">
                                                                                                <Pagination
                                                                                                    firstPageText={<i className='glyphicon glyphicon-chevron-left' />}
                                                                                                    lastPageText={<i className='glyphicon glyphicon-chevron-right' />}
                                                                                                    prevPageText={<i className='glyphicon glyphicon-menu-left' />}
                                                                                                    nextPageText={<i className='glyphicon glyphicon-menu-right' />}
                                                                                                    activePage={this.state.activePage}
                                                                                                    itemsCountPerPage={this.state.perPage}
                                                                                                    totalItemsCount={this.state.totalCount}
                                                                                                    onChange={this.handlePageChange}
                                                                                                />
                                                                                            </div> */}

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div id="menu1" className="tab-pane fade">
                                                                                    <div className="x_panel">
                                                                                        <div className="x_title">
                                                                                            <h4>projectinvestments_regd</h4>

                                                                                            <div className="clearfix" />
                                                                                        </div>
                                                                                        <div className="x_content" >
                                                                                            <div className="table-responsive">

                                                                                                <table className="table table-striped jambo_table bulk_action ">
                                                                                                    <thead>
                                                                                                    <tr>
                                                                                                        <th>Project Name</th>
                                                                                                        <th>FundingGoal Max</th>
                                                                                                        <th>Fund Period</th>
                                                                                                        <th>Entity</th>
                                                                                                    </tr>
                                                                                                    </thead>
                                                                                                    {(() => {
                                                                                                    switch (this.state.projectinvcf.length<1) {
                                                                                                    case false: return (
                                                                                                    <tbody>
                                                                                                    {
                                                                                                        this.state.projectinvd.map((user)=>(
                                                                                                            <tr key={user.ProjectInvestmentID} className="even pointer">
                                                                                                            <td className=" ">{user.ProjectName}</td>
                                                                                                            <td className=" ">{user.FundingGoalMax}</td>
                                                                                                            <td className=" ">{user.FundPeriod}</td>
                                                                                                            <td className=" ">{user.Entity}</td>
                                                                                                            </tr>
                                                                                                        ))
                                                                                                    }
                                                                                                    </tbody>
                                                                                                    );
                                                                                                    case true: return (
                                                                                                        <tbody>
                                                                                                        <tr  className="even pointer">
                                                                                                            <td colSpan="4" className="text-center">Nothing to Show</td>
                                                                                                        </tr>
                                                                                                        </tbody>
                                                                                                    );
                                                                                                    }
                                                                                                    })()}
                                                                                                </table>
                                                                                            </div>


                                                                                            {/* <div className="pull-right">
                                                                                                <Pagination
                                                                                                    firstPageText={<i className='glyphicon glyphicon-chevron-left' />}
                                                                                                    lastPageText={<i className='glyphicon glyphicon-chevron-right' />}
                                                                                                    prevPageText={<i className='glyphicon glyphicon-menu-left' />}
                                                                                                    nextPageText={<i className='glyphicon glyphicon-menu-right' />}
                                                                                                    activePage={this.state.activePage}
                                                                                                    itemsCountPerPage={this.state.perPage}
                                                                                                    totalItemsCount={this.state.totalCount}
                                                                                                    onChange={this.handlePageChange}
                                                                                                />
                                                                                            </div> */}

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
</Modal.Body>
<Modal.Footer>

</Modal.Footer>
</Modal>




                                </div>

                            </div>
                        </div>
                    </div>




                </div>
            </div>

        );
    }
}
const { object, bool } = PropTypes;

OperaterContent.propTypes = {
    session: object.isRequired,
    authenticated: bool.isRequired,
    checked: bool.isRequired
};

const mapState = ({ session }) => ({
    session: session.user,
    checked: session.checked,
    authenticated: session.authenticated
});

export default withRouter(connect(mapState)(OperaterContent));
