import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import OverviewOfferingsHeader from "./components/OfferingsTitle/OverviewOfferingsTitle";
import { bindActionCreators } from "redux";
import * as AllInvestmentActions from "./actions/actions";
import RequestService from "../../../api/RequestService";
import { formatMoney, getStatusFromCode, ROI,getImpersonateID } from "../../../utils/General";
import store from "../../../index";
import {
	PROJECT_REG_CF,
	PROJECT_REG_D,
	PROJECT_REG_ALL,
	ALL_PROJECTS,
} from "../../../utils/ApplicationConstants";
import InvestmentDescription from "../../investor/InvestorDashboardContainerComponent/InvestmentDescription";
import InvestmentChart from "../../investor/InvestorDashboardContainerComponent/InvestmentChart";
import ProjectsChart from "../../investor/InvestorDashboardContainerComponent/ProjectsChart";
import InvestmentComponent from "../../shared/investmentsComponent/InvestmentComponent";
import DistributionTableComponent from "../DistributionTableComponent/DistributionTableComponent";
class InvestorOverviewContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			ROI: 0,
			activeOfferingsChecked: true,
			pastOfferingsChecked: true,
			selectedProjectType: PROJECT_REG_D,
			selectedProjectCategory: "all",
			id: props.user.id,
			users: [],
			totalProjects: [],
			allProjects_cf: [],
			pageNumber: 1,
			totalCount: 0,
			perPage: 10,
			activePage: 1,
			allProjectsD: [],
			usersD: [],
			pageNumberD: 1,
			totalCountD: 0,
			perPageD: 10,
			activePageD: 1,
			docsD: [],
			docsCF: [],
			tia_d: 0,
			tia_cf: 0,
			troc_d: 0,
			troc_cf: 0,
			modalTitle: "",
			docDataForModal: [],
			showDocsModal: false,
			showDocFrameModal: false,
			docFrameURL: "",
			modalDocTitle: "",
			selectedProject: ALL_PROJECTS,
			totalProjects: [],
			investorInvestments: [],
			ITEMS_PER_PAGE :3,
		};
	
	}
	componentDidMount() {
		store.subscribe(() => {
			let currentState = store.getState();
			this.setState({
				id: currentState.session.user.id,
				investorInvestments:
					currentState.investorDashboard.investorInvestments,
			});
			this.allProjectsList();
		});
		this.getMyInvestments();
		const { getAllInvestments, getAllProjectCategories } = this.props;
		getAllInvestments(this.state.selectedProjectCategory);
		getAllProjectCategories();
		const { investorId, getAllUserInvestments } = this.props;
		getAllUserInvestments(investorId);
	}
	async getMyInvestments() {
		const impersonateID = await getImpersonateID();
const { data } = await RequestService.get(`/api/myInvestments/${impersonateID}`);
		if (data.code === 200) {
			this.setState({
				users: data.reg_cf.slice(0, 10),
				allProjects_cf: data.reg_cf,
				docsCF: data.docsCF,
				tia_d: data.tia_d,
				tia_cf: data.tia_cf,
				troc_d: data.troc_d,
				troc_cf: data.troc_cf,
				totalCount: data.reg_cf.length,
				allProjectsD: data.reg_d,
				usersD: data.reg_d.slice(0, 10),
				docsD: data.docsD,
				pageNumberD: 1,
				totalCountD: data.reg_d.length,
				activePageD: 1,
			});
			
		}
	}
	allProjectsList = () => {
		let totalProjects = this.state.investorInvestments.map((item) => {
			return { value: item.ProjectID_RegD, label: item.ProjectName };
		});
		const uniqueTotalProjects = [
			...new Map(
				totalProjects?.map((item) => [item.value, item])
			).values(),
		];
		this.setState({
			...this.state,
			totalProjects: uniqueTotalProjects,
		});
	};
	selectedProject = (ID) => {
		const { investorInvestments } = this.props;
		this.setState({
			...this.state,
			investorInvestments: investorInvestments?.filter(
				(item) => item?.ProjectID_RegD === ID
			),
		});
	};

	onProjectTypeChanged = (project) => {
		this.selectedProject(project?.value);
		this.setState({ selectedProject: project });
	};
	incrementViewCount = (projectId, projectType) => {
		const { incrementViewCount, user } = this.props;
		incrementViewCount(user, projectType, projectId);
	};
	onSeeDetailsClickHandler = (projectId, projectType, version) => {
		if (version === 1) this.redirectToDealPage(projectType, projectId);
		else if (version === 2)
			this.redirectToProjectDetailsPage(projectType, projectId);
	};
	redirectToProjectDetailsPage = (projectType, projectId) => {
		const { push } = this.props.history;
		const pushStateObj = {
			state: {
				id: projectId,
				projectType: this.state.selectedProjectType,
			},
			pathname: "/app/project/details",
		};
		this.incrementViewCount(projectId, projectType);
		push(pushStateObj);
	};
	redirectToDealPage = (projectType, projectId) => {
		const { push } = this.props.history;
		const obj = {
			state: {
				id: projectId,
				projectType: this.state.selectedProjectType,
			},
		};
		if (projectType === PROJECT_REG_D) obj.pathname = "/app/deal";
		else if (projectType === PROJECT_REG_CF) obj.pathname = "/app/dealCF";
		this.incrementViewCount(projectId, projectType);
		push(obj);
	};
	onProjectCategorySelected = (projectCategoryId) => {
		const { getAllInvestments } = this.props;
		this.setState({ selectedProjectCategory: projectCategoryId });
		getAllInvestments(projectCategoryId);
	};

	renderRegDProjects(activeOffering, pastOfferings) {
		const {wnVerified,
			investorInvestments,
			totalInvestmentRegD,
			totalInvestmentRegCF,
			TotalReturnAmountRegD,
		} = this.props;
		const {
			activeRegDInvestments,
			pastRegDInvestments,
			isAccreditedUser,
			wasAccreditedUser,
			showPastInvestments,
			loadingComplete,
		} = this.props;
		const {
			investmentLabels,
			investmentAmounts,
			cumulativeEarnings,
			cumulativeEarningsTimeSeries,
			cumulativeInvestmentTimeSeries,
			cumulativeEarningsAmount
		} = this.props;
		const{allProjectsD,ITEMS_PER_PAGE} = this.state;
		let regDInvestments = `${formatMoney(
			this.state.tia_d - this.state.troc_d
		)}`;
		let investment = Number(this.state.tia_d - this.state.troc_d);
		let totalReturnsRegD = formatMoney(Number(cumulativeEarningsAmount));
		let ROIAmount = `${formatMoney(
			ROI(investment, Number(cumulativeEarningsAmount))
		)}`;
		if (
			this.state.selectedProjectType === PROJECT_REG_D &&
			(isAccreditedUser || wasAccreditedUser)
		) {
		
			return (
				<div>
					<InvestmentDescription wnVerified ={wnVerified}
						numberOfInvestments={allProjectsD}
						regInvestments={regDInvestments}
						totalReturns={totalReturnsRegD}
						ROI={ROIAmount}
					/>
						<div className="container px-md-5 mb-5">
						<div className="col-md-7 col-xs-12">
						<div className="white-bg p-3 rounded-top">
						<InvestmentChart
						investmentLabels={investmentLabels}
						cumulativeEarnings={cumulativeEarnings}
						cumulativeEarningsTimeSeries={cumulativeEarningsTimeSeries}
						cumulativeInvestmentTimeSeries={cumulativeInvestmentTimeSeries}
						numberOfInvestments={allProjectsD}
					/>
								</div>
							</div>
							<div className="col-md-5 col-xs-12">
						<div className="white-bg p-3 rounded-top row m-0">
						{/* <h3>Projects</h3> */}
						{/* <ProjectsChart numberOfInvestments ={allProjectsD}/> */}
						<DistributionTableComponent
						investorInvestmentsList={allProjectsD}
						itemsPerPage={ITEMS_PER_PAGE}
						role="Investor"
						title="Original Investment"
					/>
					</div>
					</div>

						</div>
				
					{/* <InvestmentComponent
						investmentList={this.state.investorInvestments}
						totalInvestmentRegD={totalInvestmentRegD}
						totalInvestmentRegCF={totalInvestmentRegCF}
						TotalReturnAmountRegD={TotalReturnAmountRegD}
						itemsPerPage={this.ITEMS_PER_PAGE}
						role="Investor"
					/> */}
				</div>
			);
		}
		return null;
	}
	render() {
		const { pastOfferingsChecked, activeOfferingsChecked } = this.state;
		const { isAccreditedUser, wasAccreditedUser } = this.props;
		return (
			<div className="container filter-wrap">
				{/* <div className="pull-right">
					<OverviewOfferingsHeader
						totalProjects={this.state.totalProjects}
						selectedProject={this.state.selectedProject}
						onProjectTypeClickHandler={this.onProjectTypeChanged}
						activeOfferingsChecked={activeOfferingsChecked}
						pastOfferingsChecked={pastOfferingsChecked}
						isAccreditedUser={isAccreditedUser}
						wasAccreditedUser={wasAccreditedUser}
						selectedProjectType={this.state.selectedProjectType}
						projectCategoryList={this.props.projectCategories}
						projectCategoryOnClickHandler={
							this.onProjectCategorySelected
						}
						selectedProjectCategory={
							this.state.selectedProjectCategory
						}
					/>
				</div> */}
				{this.renderRegDProjects(
					activeOfferingsChecked,
					pastOfferingsChecked
				)}
			</div>
		);
	}
}
InvestorOverviewContainer.propTypes = {
	activeRegCfInvestments: PropTypes.array.isRequired,
	activeRegDInvestments: PropTypes.array.isRequired,
	addToWatchList: PropTypes.func.isRequired,
	getAllInvestments: PropTypes.func.isRequired,
	getAllProjectCategories: PropTypes.func.isRequired,
	history: PropTypes.any,
	incrementViewCount: PropTypes.func.isRequired,
	isAccreditedUser: PropTypes.bool.isRequired,
	pastRegCfInvestments: PropTypes.array.isRequired,
	pastRegDInvestments: PropTypes.array.isRequired,
	projectCategories: PropTypes.array.isRequired,
	showPastInvestments: PropTypes.bool.isRequired,
	user: PropTypes.any,
	investorId: PropTypes.number.isRequired,
	investorInvestments: PropTypes.array.isRequired,
	totalInvestmentRegCF: PropTypes.any.isRequired,
	totalInvestmentRegD: PropTypes.any.isRequired,
	totalPaymentsRegCF: PropTypes.any.isRequired,
	totalPaymentsRegD: PropTypes.any.isRequired,
	getAllUserInvestments: PropTypes.func.isRequired,
	cumulativeEarningsTimeSeries: PropTypes.object.isRequired,
	cumulativeInvestmentTimeSeries: PropTypes.object.isRequired,
	cumulativeEarningsAmount: PropTypes.number.isRequired,
	wnVerified: PropTypes.bool.isRequired,
};
InvestorOverviewContainer.defaultProps = {
	showPastInvestments: true,
	getAllUserInvestments: () => null,
	wnVerified:0
};
const mapDispatchToProps = (dispatch) => {
	const allInvestmentActions = bindActionCreators(
		AllInvestmentActions,
		dispatch
	);
	return { ...allInvestmentActions };
};
const mapStateToProps = (_state) => {
	return {
		..._state.investorDashboard,
		..._state.reducedEarnings,
		investorId: _state.session.user.id,
		..._state.allInvestments,
		user: _state.session.user,
		location: _state.location,
		projectCategories: _state.allInvestments.projectCategories,
	};
};
const connectedComponent = connect(
	mapStateToProps,
	mapDispatchToProps
)(InvestorOverviewContainer);
export default withRouter(connectedComponent);
