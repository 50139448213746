import PropTypes from 'prop-types'
import React from 'react';
import SingleLineInputComponent from '../../InputComponents/SingleLineInputComponent/SingleLineInputComponent';
import Icon from "../../IconComponent/IconComponent";

class SelectedFilesTableElement extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isEditable: false,
            fileDescription: '',
        };
    }

    onDescriptionChange = (event) => {
        const {value: fileDescription} = event.target;
        this.setState({fileDescription});
    };

    toggleDescriptionEdit = () => {
        let {isEditable} = this.state;
        isEditable = !isEditable;

        this.setState({isEditable});
    };

    onCancelEdit = () => {
        this.setState({fileDescription: ''});
        this.toggleDescriptionEdit();
    };

    onUpdates = () => {
        const {file} = this.props;
        const {fileDescription} = this.state;
        this.props.onDescriptionUpdate(file, fileDescription);
        this.onCancelEdit();
    };

    renderEditorButtons = () => {
        const {isEditable} = this.state;

        if (isEditable)
            return (
                <div>
                    <button className="btn btn-primary btn-sm capitalize-text no-margin"
                            onClick={this.onUpdates}
                            type="button">
                        <Icon iconName="floppy-o" extraClasses="white-text"/> Save Desc
                    </button>

                    <button
                        className="btn btn-danger btn-sm capitalize-text no-margin"
                        onClick={this.onCancelEdit}
                        type="button">
                        <Icon iconName="times" extraClasses="white-text"/> Cancel
                    </button>
                </div>
            );

        return null;
    };

    renderMainActionButtons = (file) => {
        const {isEditable} = this.state;

        if (!isEditable)
            return (
                <div>
                    <button className="btn btn-warning btn-sm capitalize-text no-margin"
                            onClick={() => this.props.onRemoveFile(file)}
                            type="button">
                        <Icon iconName="trash" extraClasses="white-text"/> remove
                    </button>

                    <button className="btn btn-success btn-sm capitalize-text no-margin"
                            onClick={this.toggleDescriptionEdit}
                            type="button">
                        <Icon iconName="pencil" extraClasses="white-text"/> edit description
                    </button>
                </div>
            );

        return null;
    };

    render = () => {
        const {file} = this.props;
        const {isEditable, fileDescription} = this.state;

        return (
            <tr key={file}>
                <td>{file.name}</td>
                <td>
                    {!isEditable && file.description}
                    {isEditable &&
                    <SingleLineInputComponent elementValue={fileDescription}
                                              maxInputLength={255}
                                              placeholder="File description"
                                              onInputUpdate={this.onDescriptionChange}/>}
                </td>
                <td>
                    {this.renderMainActionButtons(file)}
                    {this.renderEditorButtons()}
                </td>
            </tr>
        );
    };
}

SelectedFilesTableElement.propTypes = {
    file: PropTypes.object.isRequired,
    onDescriptionUpdate: PropTypes.func.isRequired,
    onRemoveFile: PropTypes.func.isRequired
}

SelectedFilesTableElement.defaultProps = {
    file: {},
    onDescriptionUpdate: () => null,
    onRemoveFile: () => null
}

export default SelectedFilesTableElement
