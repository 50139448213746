import React from 'react';
import moment from 'moment'
import PropTypes from 'prop-types'
import { getUUID, formatMoney } from "../../../../utils/General";
import Pagination from 'react-js-pagination';
import { PROJECT_REG_D } from "../../../../utils/ApplicationConstants";
import Icon from "../../../shared/IconComponent/IconComponent";


class DistributeTableComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            paginationActivePage: 1,
            paginationItemsPerPage: 10,
            paginationSelectedPageData: [],
        };
    }



    componentDidMount() {
        this.handlePageChange(this.state.paginationActivePage);
    }



    componentDidUpdate(prevProps) {
        const _projectList = JSON.stringify(prevProps.projectList);
        const projectList = JSON.stringify(this.props.projectList);

        if (_projectList !== projectList) {
            this.handlePageChange(this.state.paginationActivePage);
        }
    }



    onDistributeCapitalClickHandler = (roc, projectType) => {
        this.props.onDistributeClicked(roc.returnId, roc.projectName, roc.entityName, projectType);
    };



    onViewDistributedReturnedCapitalClickHandler = (roc, projectType) => {
        this.props.onViewDistributedResult(roc.returnId, roc.projectName, roc.entityName, projectType);
    };



    handlePageChange = (pageNumber) => {
        const {paginationItemsPerPage} = this.state;
        const start = ((pageNumber - 1) * paginationItemsPerPage);
        const end = (start + paginationItemsPerPage);
        const paginationSelectedPageData = this.props.projectList.slice(start, end);

        this.setState({
            paginationActivePage: pageNumber,
            paginationSelectedPageData,
        });
    };



    renderReturnDetails = (isDistributed) => {
        return isDistributed === 0 ? 'not distributed' : 'distributed';
    };



    renderProjectList = () => {
        const {projectType} = this.props;
        const {paginationSelectedPageData} = this.state;

        return paginationSelectedPageData.map(rc => (
            <tr key={getUUID()}>
                <td className="capitalize-text">{rc.returnId}</td>
                <td>{moment(rc.date).format('MMM DD, YYYY')}<br/>{moment(rc.date).format('hh:mm A')}</td>
                <td className="capitalize-text"><strong>{rc.projectName}</strong><br/><br/><em>Notes:</em> {rc.InternalNotes}</td>
                <td>{rc.entityName}</td>
                <td>${formatMoney(rc.returnedAmount)}</td>
                <td className="capitalize-text">
                    {this.renderReturnDetails(rc.isDistributed)}
                </td>
                <td>
                    {rc.isDistributed === 0 &&
                    <button className="btn-sm btn-primary dropdown-toggle no-text-wrap full-width"
                            style={{border: 'none', margin: 0, padding: '0px 10px', maxWidth: 130}}
                            onClick={() => this.onDistributeCapitalClickHandler(rc, projectType)}
                            type="button">
                        <span className="capitalize-text">Distribute Capital</span>
                    </button>}
                    {rc.isDistributed > 0 &&
                    <button className="btn-sm btn-success dropdown-toggle no-text-wrap full-width"
                            style={{border: 'none', margin: 0, padding: '0px 10px', maxWidth: 130}}
                            onClick={() => this.onViewDistributedReturnedCapitalClickHandler(rc, projectType)}
                            type="button">
                        <span className="capitalize-text">View Distribution</span>
                    </button>}
                </td>
            </tr>
        ));
    };



    render = () => {
        return (
            <div className="table-responsive">
                <table className="table table-striped table-success table-hover">
                    <thead>
                    <tr className="capitalize-text">
                        <th>ROC ID</th>
                        <th>Date</th>
						<th>Project Name / Notes</th>
                        <th>Entity</th>
                        <th>Returned Amount</th>
                        <th>Details</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.renderProjectList()}
                    </tbody>
                </table>
                <div className="pull-right">
                    <Pagination
                        prevPageText={<Icon iconName="chevron-left"/>}
                        firstPageText={<Icon iconName="arrow-left"/>}
                        nextPageText={<Icon iconName="chevron-right"/>}
                        lastPageText={<Icon iconName="arrow-right"/>}
                        activePage={this.state.paginationActivePage}
                        itemsCountPerPage={this.state.paginationItemsPerPage}
                        totalItemsCount={this.props.projectList.length}
                        onChange={this.handlePageChange}/>
                </div>
            </div>
        );
    }
}



DistributeTableComponent.propTypes = {
    onDistributeClicked: PropTypes.func.isRequired,
    onViewDistributedResult: PropTypes.func.isRequired,
    projectList: PropTypes.array.isRequired,
    projectType: PropTypes.string.isRequired
};

DistributeTableComponent.defaultProps = {
    onDistributeClicked: () => null,
    onViewDistributedResult: () => null,
    projectList: [],
    projectType: PROJECT_REG_D
};

export default DistributeTableComponent;
