import PropTypes from 'prop-types';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Icon from '../IconComponent/IconComponent';

class InfoComponent extends React.Component {
    constructor(props) {
        super(props);

        this.refOverlay = React.createRef();
    }

    onOverlayClickHandler = () => this.refOverlay.current.handleToggle();

    render = () => {
        const { children } = this.props;
        const projectedMetricsTooltip = (
            <Tooltip>
                {children}
            </Tooltip>
        );

        return (
            <OverlayTrigger
                placement="bottom"
                ref={this.refOverlay}
                overlay={projectedMetricsTooltip}
                onClick={this.onOverlayClickHandler}
            >
                <a><Icon iconName="info-circle" extraClasses="card-info" /></a>
            </OverlayTrigger>
        );
    };
}

InfoComponent.propTypes = {
    children: PropTypes.any.isRequired,
};

export default InfoComponent;
