import React, { useState } from 'react';
import PropTypes from "prop-types";
import ImgIcon from './img'

const Icon = (props) => {


    let {iconName,iconHoverName, extraClasses, onClick, className, id} = props;
    extraClasses = extraClasses || '';

    const classes = `fa fa-${iconName} ${extraClasses} ${className}`;

// debugger;

    if (iconName.startsWith('imgIcon')) {
     return (
        <img className={`image-icon ${extraClasses} ${className}`}
                    id={id}
                    src={ImgIcon[iconName]}  
                 
             />
                    );

    }

    return <i className={classes} id={id} onClick={onClick}/>
};

Icon.propTypes = {
    iconName: PropTypes.string.isRequired,
    id: PropTypes.string,
    extraClasses: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
};

Icon.defaultProps = {
    iconName: 'plus',
    id: '',
    extraClasses: '',
    className: '',
    onClick: () => null,
};

export default Icon;
