import PropTypes from 'prop-types';
import moment from 'moment';
import React from 'react';
import { getHash } from '../../../../utils/General';

class EmailTableElementComponent extends React.Component {
    isSelectedEmail = () => {
        const { selectedEmailsMap, hsEmail } = this.props;

        return !!selectedEmailsMap[hsEmail.id];
    };

    onSelectEmail = () => {
        const { hsEmail, onSelectEmail } = this.props;

        onSelectEmail(hsEmail);
    };

    render = () => {
        const { hsEmail, isImported } = this.props;
        const emailHash = getHash(hsEmail);

        const checkInput = (
            <div className="form-group no-margin no-padding">
                <div className="checkbox no-margin no-padding">
                    <input
                        id={emailHash}
                        type="checkbox"
                        checked={this.isSelectedEmail()}
                        onChange={this.onSelectEmail}
                        className="offerings-header-checkbox"
                    />
                    <label htmlFor={emailHash} />
                </div>
            </div>
        );
        const imported = <div>Imported</div>;
        const el = isImported ? imported : checkInput;
        const purpleBackground = { backgroundColor: 'rgba(81,41,101,0.26)' };
        const style = isImported ? purpleBackground : null;

        return (
            <tr
                className="no-text-wrap"
                style={style}
            >
                <td>{el}</td>
                <td>{hsEmail.id}</td>
                <td>
                    {moment(hsEmail.publishedAt)
                        .format('MMM DD, YYYY @ HH:mm:ss')}
                </td>
                <td>{hsEmail.subject}</td>
                <td>{hsEmail.projectName}</td>
                <td>{hsEmail.campaignName}</td>
                <td>{hsEmail.name}</td>
            </tr>
        );
    };
}

EmailTableElementComponent.propTypes = {
    hsEmail: PropTypes.object.isRequired,
    isImported: PropTypes.bool.isRequired,
    onSelectEmail: PropTypes.func.isRequired,
    selectedEmailsMap: PropTypes.object.isRequired,
};

EmailTableElementComponent.defaultProps = {
    hsEmail: {},
    isImported: false,
    onSelectEmail: () => null,
    selectedEmailsMap: {},
};

export default EmailTableElementComponent;
