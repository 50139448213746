  import React, { Component } from 'react';
  class Admincommunication extends Component {
    componentWillMount(){
      window.scrollTo(0, 0);
  }
  render() {
    return(

      <div className="container body">
        <div className="main_container">
      <div className="right_col" role="main" style={{minHeight: 3753}}>
        <div className>
          <div className="page-title">
            <div className="title_left">
              <h3>Communication</h3>
            </div>
          </div>
          <div className="clearfix" />
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="x_panel">
                <div className="x_title">
              
                  <div className="clearfix" />
                </div>
                <div className="x_content">
                  <div id="datatable_wrapper" className="dataTables_wrapper form-inline dt-bootstrap no-footer">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="dataTables_length" id="datatable_length">
                          <label>
                            Show 
                            <select name="datatable_length" aria-controls="datatable" className="form-control input-sm">
                              <option value={10}>10</option>
                              <option value={25}>25</option>
                              <option value={50}>50</option>
                              <option value={100}>100</option>
                            </select>
                           &nbsp;&nbsp;Per Page
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <table id="datatable" className="table table-striped table-bordered dataTable no-footer" role="grid" aria-describedby="datatable_info">
                          <thead>
                            <tr role="row">
                              <th className="sorting_asc" tabIndex={0} aria-controls="datatable" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="Name: activate to sort column descending" style={{width: 115}}>Date</th>
                              <th className="sorting" tabIndex={0} aria-controls="datatable" rowSpan={1} colSpan={1} aria-label="Position: activate to sort column ascending" style={{width: 193}}>Project</th>
                              <th className="sorting" tabIndex={0} aria-controls="datatable" rowSpan={1} colSpan={1} aria-label="Office: activate to sort column ascending" style={{width: 82}}>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                            <td>2017.29.07 03:45</td>
                            <td>Hagler SW 3 Well Turnkey Project-2017</td>
                            <td>Success</td>
                          </tr>
                           <tr>
                            <td>2017.29.07 03:45</td>
                            <td>Hagler SW 3 Well Turnkey Project-2017</td>
                            <td>Success</td>
                          </tr>
                           <tr>
                            <td>2017.29.07 03:45</td>
                            <td>Hagler SW 3 Well Turnkey Project-2017</td>
                            <td>Success</td>
                          </tr>
                           <tr>
                            <td>2017.29.07 03:45</td>
                            <td>Hagler SW 3 Well Turnkey Project-2017</td>
                            <td>Success</td>
                          </tr>
                           <tr>
                            <td>2017.29.07 03:45</td>
                            <td>Hagler SW 3 Well Turnkey Project-2017</td>
                            <td>Success</td>
                          </tr>
                           <tr>
                            <td>2017.29.07 03:45</td>
                            <td>Hagler SW 3 Well Turnkey Project-2017</td>
                            <td>Success</td>
                          </tr>
                            <tr>
                            <td>2017.29.07 03:45</td>
                            <td>Hagler SW 3 Well Turnkey Project-2017</td>
                            <td>Success</td>
                          </tr>
                         <tr>
                            <td>2017.29.07 03:45</td>
                            <td>Hagler SW 3 Well Turnkey Project-2017</td>
                            <td>Success</td>
                          </tr>
                            
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-5">
                        <div className="dataTables_info" id="datatable_info" role="status" aria-live="polite">Showing 1 to 10 of 57 entries</div>
                      </div>
                      <div className="col-sm-7">
                        <div className="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
                          <ul className="pagination">
                            <li className="paginate_button previous disabled" id="datatable_previous"><a href="" aria-controls="datatable" data-dt-idx={0} tabIndex={0}>Previous</a></li>
                            <li className="paginate_button active"><a href="" aria-controls="datatable" data-dt-idx={1} tabIndex={0}>1</a></li>
                            <li className="paginate_button "><a href="" aria-controls="datatable" data-dt-idx={2} tabIndex={0}>2</a></li>
                            <li className="paginate_button "><a href="" aria-controls="datatable" data-dt-idx={3} tabIndex={0}>3</a></li>
                            <li className="paginate_button "><a href="" aria-controls="datatable" data-dt-idx={4} tabIndex={0}>4</a></li>
                            <li className="paginate_button "><a href="" aria-controls="datatable" data-dt-idx={5} tabIndex={0}>5</a></li>
                            <li className="paginate_button "><a href="" aria-controls="datatable" data-dt-idx={6} tabIndex={0}>6</a></li>
                            <li className="paginate_button next" id="datatable_next"><a href="" aria-controls="datatable" data-dt-idx={7} tabIndex={0}>Next</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
           </div>
           </div>
           </div>
           </div>
        
    );
      }
      }

  export default Admincommunication;