/*
 * This is the "Investor Menu" - "My Investments" Page.
 */

import React, { Component } from 'react';
import swal from 'sweetalert2';
import store from '../../index'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Modal, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import getDate from '../../utils/ConvertDate';
import RequestService from '../../api/RequestService';
import Icon from '../shared/IconComponent/IconComponent';
import { formatMoney,getImpersonateID } from '../../utils/General';


class Investments extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: props.session.id,
			users: [],
			totalProjects: [],
			allProjects: [],

			pageNumber: 1,
			totalCount: 0,
			perPage: 10,
			activePage: 1,
			allProjectsD: [],
			usersD: [],
			pageNumberD: 1,
			totalCountD: 0,
			perPageD: 10,
			activePageD: 1,
			docsD: [],
			docsCF: [],
			tia_d: 0,
			tia_cf: 0,
			troc_d: 0,
			troc_cf: 0,

			modalTitle: '',
			docDataForModal: [],
			showDocsModal: false,
			showDocFrameModal: false,
			docFrameURL: '',
			modalDocTitle: '',
		};

		this.getMyInvestments = this.getMyInvestments.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.handlePageChangeD = this.handlePageChangeD.bind(this);
		this.checkAccreditation = this.checkAccreditation.bind(this);
		this.onRefund = this.onRefund.bind(this);
		this.showDocsD = this.showDocsD.bind(this);
		this.closeDocsD = this.closeDocsD.bind(this);
		this.showDocFrame = this.showDocFrame.bind(this);
		this.closeDocFrame = this.closeDocFrame.bind(this);
	}



	componentDidMount() {
		window.scrollTo(0, 0);

		store.subscribe(() => {
			let currentState = store.getState();
			this.setState({
				id: currentState.session.user.id
			});
		});

		this.getMyInvestments();
		this.checkAccreditation();
	}



	onRefund(Type, UserInvestmentID, ID) {
		let formBody = [];
		formBody.push("ProjectType=" + encodeURIComponent(Type));
		formBody.push("UserInvestmentID=" + encodeURIComponent(UserInvestmentID));
		formBody.push("ProjectID=" + encodeURIComponent(ID));
		formBody = formBody.join("&");

		fetch('/api/sendRefundMail/' + this.state.id + '/' + this.props.session.marketing, {
			method:'post',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/x-www-form-urlencoded'
			},
			body: formBody
		})
			.then((response) => {
				return response.json()
			})
			.then((data) => {
				if (data.code === 200) {
					swal("Refund Request Sent.", "We will get back to you shortly.", "success")
						.then(this.props.history.push('/app/UserNotifications'))
				}
				else if (data.code === 400) {
					swal("Request could not be sent. Please Call Us.", "", "error")
						.then(this.props.history.push('/app/UserNotifications'))
				}
			})
			.catch((err) => {
				console.log(err);
			})
	}



	handlePageChange(pageNumber) {
		let prev = ((pageNumber) - 1) * 10;
		let next = prev + 10;

		this.setState({
			activePage: pageNumber,
			users: this.state.allProjects.slice(prev, next)
		});
	}



	handlePageChangeD(pageNumber) {
		let prev = ((pageNumber) - 1) * 10;
		let next = prev + 10;

		this.setState({
			activePageD: pageNumber,
			usersD: this.state.allProjectsD.slice(prev, next)
		});
	}



	async getMyInvestments() {
		const impersonateID = await getImpersonateID();
		const { data } = await RequestService.get(`/api/myInvestments/${impersonateID}`);

		if (data.code === 200) {
			this.setState({
				users: data.reg_cf.slice(0, 10),
				allProjects: data.reg_cf,
				docsCF: data.docsCF,
				tia_d: data.tia_d,
				tia_cf: data.tia_cf,
				troc_d: data.troc_d,
				troc_cf: data.troc_cf,

				pageNumber: 1,
				totalCount: data.reg_cf.length,
				perPage: 10,
				activePage: 1,

				allProjectsD: data.reg_d,
				usersD: data.reg_d.slice(0, 10),
				docsD: data.docsD,
				pageNumberD: 1,
				totalCountD: data.reg_d.length,
				perPageD: 10,
				activePageD: 1,
			});
		}
	}



	async checkAccreditation() {
		try {
			const impersonateID = await getImpersonateID();

			const { data } = await RequestService.get(`/api/checkAccreditation/${getImpersonateID}`);

			if (data.code === 200) {
				this.setState({
					IsAccredited: data.AccreditationStatus,
					WasAccredited: data.PriorAccredStatus
				});
			}
			else {
				// console.log(data);
			}
		}
		catch (err) {
			console.log(err);
		}
	}



	async showDocsD(project) {
		const { data } = await RequestService.get(`/api/getProjectInvestmentDocs2/RegD/${project.ProjectID_RegD}`);

		if (data.code === 200) {
			this.setState({
				modalTitle: project.ProjectName,
				docDataForModal: data.docs,
				showDocsModal: true
			});
		}
	}



	async closeDocsD() {
		this.setState({
			modalTitle: '',
			docDataForModal: [],
			showDocsModal: false
		});
	}



	showDocFrame(url, docTitle) {
		swal({
			title: 'Loading, Please Wait...',
			timer: 3000,
			allowOutsideClick: false,
			onOpen: () => {
				swal.showLoading();
			}
		});

		this.setState({
			docFrameURL: url,
			modalDocTitle: docTitle,
			showDocFrameModal: true,
		});
	}



	closeDocFrame() {
		this.setState({
			showDocFrameModal: false,
		});
	}



	iframeOnLoad() {
		swal.close();
		document.getElementById('googleDocFrameID').style.height = (window.innerHeight * 0.75) + 'px';
	}


	CustomPopover() {
		return (
			<div
				style={{
					position: 'absolute',
					backgroundColor: '#333333',
					color: '#ffffff',
					boxShadow: '0 5px 10px rgba(0, 0, 0, 0.2)',
					border: '1px solid #CCC',
					borderRadius: 3,
					padding: 10,
					zIndex: 99999
				}}
			>
				{`$${formatMoney(this.state.tia_d)} (Original Investment) - $${formatMoney(this.state.troc_d)} (Returned Capital)`}
			</div>
		);
	}


	renderRegD() {
		return (
			<div className="col-md-12 col-sm-12 col-xs-12">
				<div className="x_panel">
					<div className="x_title">
						<h4 className='up'>Total Investments: ${formatMoney(this.state.tia_d - this.state.troc_d)} &nbsp;
							{this.state.troc_d > 0 ?
								<OverlayTrigger placement="top" overlay={this.CustomPopover()}>
									<a><Icon iconName="info-circle" extraClasses="card-info" /></a>
								</OverlayTrigger> : ''
							}
						</h4>
						<div className="clearfix" />
					</div>
					<div className="x_content">
						<div id="datatable_wrapper" className="dataTables_wrapper form-inline dt-bootstrap no-footer">
							<div className="row">
								<div className="col-sm-12">
									<table className="table table-striped jambo_table">
										<thead className="thead-inverse">
										<tr className="headings">
											<th className="column-title">Date</th>
											<th className="column-title">My Entity</th>
											<th className="column-title">Project</th>
											<th className="column-title">Project Entity</th>
											<th className="column-title">Amount</th>
											<th className="column-title">GP/LP</th>
											{/*<th className="column-title">Investment Status</th>*/}
											{/*<th className="column-title">Payment Status</th>*/}
											<th className="column-title">Actions</th>
										</tr>
										</thead>
										{(() => {
											if (this.state.usersD.length > 0) {
												console.log('this.state.usersD', this.state.usersD);
												return (
													<tbody>
													{this.state.usersD.map((user) => {
														let InvAmt = user.InvestmentAmount * 100 / 100;
														let RetText = '';
														let RetText2 = '';
														let ShowProductionText = <><br />
															<a className="pointer t-weight-normal" onClick={() => this.props.history.push({
																pathname: '/app/project/ProductionData',
																state: { projectID: (user.ParentProjectID > 0 ? user.ParentProjectID : user.ProjectID), role: 'Investor' }
															})}>
																Show Monthly Production Data
															</a></>;

														if (user.ReturnAmount > 0) {
															InvAmt = (user.InvestmentAmount - user.ReturnAmount);

															RetText = <span style={{fontStyle: 'italic', fontWeight: 'normal', display: 'inherit'}}>
																		There were one or more Returns of Capital issued.
																	  </span>;

															RetText2 =
																<OverlayTrigger
																	placement="top"
																	overlay={
																		<Tooltip id={String(user.UserInvestmentID)}>
																			<div style={{
																				padding: 3,
																				fontSize: '1.0em'
																			}}>{`$${formatMoney(user.InvestmentAmount)} (Original Investment) - 
																				 $${formatMoney(user.ReturnAmount)} (Returned Capital)`}</div>
																		</Tooltip>}>
																	<a><Icon iconName="info-circle" extraClasses="card-info" /></a>
																</OverlayTrigger>;
														}

														return (
																<tr style={{ textDecoration: user.Deleted === 1 || user.Refunded === 1 ? 'line-through' : '', fontWeight: 'bold' }}>
																	<td>{getDate.getDate(user.InvestmentDate)}</td>
																	<td>{user.Entity}</td>
																	<td>{user.ProjectName}{RetText}</td>
																	<td>{user.piEntity}</td>
																	<td>${formatMoney(InvAmt)}<br/>{RetText2}</td>
																	<td>{user.GpLp === 2 ? 'LP' : 'GP'}</td>
																	{/*<td>{user.dwollastatus}</td>*/}
																	{/*<td>{user.PaymentStatus}</td>*/}
																	<td>
																		<a className="btn btn-primary" href={user.ContractFilePath} download>Download</a>
																		{/*{ShowProductionText}*/}
																		<br />
																		{this.state.docsD.some(proj => proj.ProjectID_RegD === user.ProjectID_RegD) ? <a href="javascript:" onClick={ () => this.showDocsD(user) }>View Project Data</a> : ''}
																	</td>
																</tr>
															)
														}
													)}
													</tbody>
												);
											}
											else {
												return (
													<tbody>
													<tr className="even pointer">
														<td colSpan="7" className="text-center">Nothing to Show</td>
													</tr>
													</tbody>
												);
											}
										})()}
									</table>

									<div className="pull-right">
										<Pagination
											firstPageText={<i className='glyphicon glyphicon-chevron-left' />}
											lastPageText={<i className='glyphicon glyphicon-chevron-right' />}
											prevPageText={<i className='glyphicon glyphicon-menu-left' />}
											nextPageText={<i className='glyphicon glyphicon-menu-right' />}
											activePage={this.state.activePageD}
											itemsCountPerPage={this.state.perPageD}
											totalItemsCount={this.state.totalCountD}
											onChange={this.handlePageChangeD}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}



	renderRegCF() {
		return (
			<div className="col-md-12 col-sm-12 col-xs-12">
				<div className="x_panel">
					<div className="x_title">
						<h4 className='up'>Total Investments: ${formatMoney(this.state.tia_cf)}</h4>
						{this.state.troc_cf > 0 ? <h4>Total Return of Capital: ${formatMoney(this.state.troc_cf)}</h4> : ''}
						<div className="clearfix" />
					</div>
					<div className="x_content">
						<div id="datatable_wrapper"
							 className="dataTables_wrapper form-inline dt-bootstrap no-footer">
							<div className="row">
								<div className="col-sm-12">
									<table className="table table-striped jambo_table">
										<thead className="thead-inverse">
										<tr className="headings">
											<th className="column-title">Investment Date</th>
											<th className="column-title">Project Name</th>
											<th className="column-title">Entity</th>
											{/*<th className="column-title">Docs</th>*/}
											<th className="column-title">Investment Amount</th>
											{/*<th className="column-title">Investment Status</th>*/}
											{/*<th className="column-title">Payment Status</th>*/}
											<th className="column-title">Funding Goal</th>
											<th className="column-title">Investment Contract</th>
										</tr>
										</thead>
										{(() => {
											if (this.state.users.length > 0) {
												return (
													<tbody>
													{this.state.users.map((user) => (
															<tr style={{ textDecoration: user.Deleted === 1 || user.Refunded === 1 ? 'line-through' : '' }}>
																<td>{getDate.getDate(user.InvestmentDate)}</td>
																<td>{user.InvestmentName}</td>
																<td>{user.Entity}</td>
																{/*<td>Docs Links</td>*/}
																<td>${user.InvestmentAmount}</td>
																{/*<td>{user.dwollastatus}</td>*/}
																{/*<td>{user.PaymentStatus}</td>*/}
																<td>{user.FundingGoalMax}</td>
																<td><a className="btn btn-primary" href={user.ContractFilePath} download>Download</a>
																	{(() => {
																		let date1 = new Date();
																		let date2 = new Date(user.InvestmentDate);
																		let diff = date1.getTime() - date2.getTime();
																		if(diff > 3) {
																			return (
																				<div>
																					<br/>
																					<a className="btn btn-primary" download onClick={() => this.onRefund("RegCF", user.UserInvestmentID, user.ProjectID)}>Cancel</a>
																				</div>
																			)}
																	})()}
																</td>
															</tr>
														)
													)}
													</tbody>
												);
											}
											else {
												return (
													<tbody>
													<tr className="even pointer">
														<td colSpan="6" className="text-center">Nothing to Show</td>
													</tr>
													</tbody>
												);
											}
										})()}
									</table>

									<div className="pull-right">
										<Pagination
											firstPageText={<i className='glyphicon glyphicon-chevron-left' />}
											lastPageText={<i className='glyphicon glyphicon-chevron-right' />}
											prevPageText={<i className='glyphicon glyphicon-menu-left' />}
											nextPageText={<i className='glyphicon glyphicon-menu-right' />}
											activePage={this.state.activePage}
											itemsCountPerPage={this.state.perPage}
											totalItemsCount={this.state.totalCount}
											onChange={this.handlePageChange}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}



	render() {
		return (
			<div className="container body">
				<div className="main_container">
					<div className="right_col" role="main" style={{minHeight: 3753}}>
						<div className>
							<div className="page-title">
								<div className="title_left">
									<h3>My Investments</h3>
								</div>
							</div>
							<div className="clearfix" />
							<div className="row">
								{(() => {
									if (this.state.IsAccredited === 1 || this.state.WasAccredited) {
										return (
											<div>
												<ul className="nav nav-pills nav-justified" style={{ padding: '0 10px', marginBottom: 20 }}>
													<li className="active"><a data-toggle="pill" href="#menu1">EnergyFunders Black (Reg D)</a></li>
													<li><a data-toggle="pill" href="#home">EnergyFunders Marketplace (Reg CF)</a></li>
												</ul>

												<div className="tab-content">
													<div id="home" className="tab-pane fade">
														{this.renderRegCF()}
													</div>
													<div id="menu1" className="tab-pane fade in active">
														{this.renderRegD()}
													</div>
												</div>

											</div>
										)
									}
									else {
										return (
											<div>
												{this.renderRegCF()}
											</div>
										)
									}
								})()}
							</div>
						</div>
					</div>

					<Modal
						show={this.state.showDocsModal}
						onHide={this.closeDocsD}
						style={{
							width: '90%',
							margin: '0 auto',
						}}
					>
						<Modal.Header>
							<Modal.Title>{this.state.modalTitle} Docs</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div>
								<div id="datatable_wrapper" className="dataTables_wrapper form-inline dt-bootstrap no-footer">
									<div className="row">
										<div className="col-xs-12">
											<table
												className="table table-striped dataTable no-footer">
												<thead>
												<tr className="headings">
													<th className="column-title">Doc Type</th>
												</tr>
												</thead>
												{(() => {
													return (
														<tbody>
														{this.state.docDataForModal.map((doc) => (
																<tr>
																	<td><a href="javascript:" onClick={() => this.showDocFrame(doc.DocURL, doc.DocType)}>{doc.DocType}</a></td>
																</tr>
															)
														)}
														</tbody>
													);
												})()}
											</table>
										</div>
									</div>
								</div>
							</div>
						</Modal.Body>
						<Modal.Footer>
							<Button className="btn btn-info left" onClick={this.closeDocsD}>Close</Button>
						</Modal.Footer>
					</Modal>

					<Modal
						show={this.state.showDocFrameModal}
						onHide={this.closeDocFrame}
						style={{
							width: '95%',
							margin: '0 auto',
						}}
					>
						<Modal.Header>
							<Modal.Title>{this.state.modalDocTitle}</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div>
								<iframe id="googleDocFrameID" src={this.state.docFrameURL} style={{border: 'none', width: '100%', height: '100%'}} onLoad={this.iframeOnLoad} />
							</div>
						</Modal.Body>
						<Modal.Footer>
							<Button className="btn btn-info left" onClick={this.closeDocFrame}>Close</Button>
						</Modal.Footer>
					</Modal>

				</div>
			</div>
		);
	}
}


const { object, bool } = PropTypes;

Investments.propTypes = {
	session: object.isRequired,
	authenticated: bool.isRequired,
	checked: bool.isRequired
};

const mapState = ({ session }) => ({
	session: session.user,
	checked: session.checked,
	authenticated: session.authenticated
});

export default withRouter(connect(mapState)(Investments));
