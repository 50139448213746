import PropTypes from 'prop-types';
import moment from 'moment';
import React from 'react';
import { Modal } from 'react-bootstrap';

import Icon from '../../../shared/IconComponent/IconComponent';
import { getUUID } from '../../../../utils/General';
import RequestService from '../../../../api/RequestService';
import NotificationService from '../../../../utils/NotificationService';

class SignedCAViewerContainerComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: true,
            signedCATrackList: [],
        };
    }

    componentDidMount() {
        this.getSignedCAList();
    }

    getSignedCAList = async () => {
        const { investorId } = this.props;
        const url = `/api/admin/investor/${investorId}/signed-ca/all`;

        try {
            const { data: signedCATrackList } = await RequestService.get(url);

            this.setState({ signedCATrackList });
        } catch (error) {
            console.log('Error extracting the Signed CA Tracking list: ERROR: ', error);
            NotificationService.error('Error extracting the Signed CA Tracking list', 10000);
        }
    };

    onCloseModal = () => {
        const { onCloseModal } = this.props;

        this.setState({ isModalOpen: false },
            () => setTimeout(onCloseModal, 500));
    };

    // RENDER MANAGER
    renderTableElements = () => {
        const { signedCATrackList } = this.state;

        if (!signedCATrackList || !signedCATrackList.length) {
            return (
                <tr style={{ textAlign: 'center' }}>
                    <td colSpan={6}>No Signed CA Projects</td>
                </tr>
            );
        }

        return signedCATrackList.map(item => (
            <tr key={getUUID()}>
                <td>{moment(item.signedDate).format('MMM DD, YYYY HH:mm')}</td>
                <td>{item.projectName}</td>
                <td>{item.signedName}</td>
                <td>{item.city}</td>
                <td>{item.state}</td>
                <td>{item.country}</td>
            </tr>
        ));
    };

    renderTable = () => (
        <div className="table-responsive">
            <table className="table table-striped table-hover">
                <thead>
                    <tr>
                        <th>Signed Date</th>
                        <th>Project Name</th>
                        <th>Signed Name</th>
                        <th>City</th>
                        <th>State</th>
                        <th>Country</th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderTableElements()}
                </tbody>
            </table>
        </div>
    );

    render = () => {
        const { isModalOpen } = this.state;

        return (
            <Modal
                show={isModalOpen}
                onHide={this.onCloseModal}
            >
                <Modal.Header closeButton>
                    <div className="col-xs-12">
                        <h3 className="heading-text">Signed CA Tracking</h3>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="col-xs-12">
                            {this.renderTable()}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="col-xs-12">
                        <button
                            className="btn btn-primary"
                            onClick={this.onCloseModal}
                            type="button"
                        >
                            <Icon iconName="times" extraClasses="white-text" />
                            {' '}
                            Close
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    };
}

SignedCAViewerContainerComponent.propTypes = {
    investorId: PropTypes.number.isRequired,
    onCloseModal: PropTypes.func.isRequired,
};

SignedCAViewerContainerComponent.defaultProps = {
    investorId: 0,
    onCloseModal: () => null,
};

export default SignedCAViewerContainerComponent;
